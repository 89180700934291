import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

import { AutocompleteTipoNegociacaoCartaoComponent } from './autocomplete-tipo-negociacao-cartao.component';

@NgModule({
    declarations: [AutocompleteTipoNegociacaoCartaoComponent],
    imports: [CommonModule, FormsModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule, MatSelectModule],
    exports: [AutocompleteTipoNegociacaoCartaoComponent],
})
export class AutocompleteTipoNegociacaoCartaoModule {}
