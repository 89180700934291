import { Component, Input, OnInit, ViewContainerRef } from '@angular/core';
import { Parceiro } from 'src/app/global-interfaces/parceiro-interface';
import { PortalServiceService } from 'src/app/services/portal-service.service';
import { DatePipe } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { SpinnerComponent } from 'src/app/global-components/spinner/spinner.component';
import { SankhyaServiceService } from 'src/app/services/sankhya-service.service';

@Component({
    selector: 'app-relatorio-cheque-aberto',
    templateUrl: './relatorio-cheque-aberto.component.html',
    styleUrls: ['./relatorio-cheque-aberto.component.scss', '../relatorios.component.scss'],
})
export class RelatorioChequeAbertoComponent implements OnInit {
    constructor(
        private portalService: PortalServiceService,
        private datePipe: DatePipe,
        private snackBar: MatSnackBar,
        public overlay: Overlay,
        public viewContainerRef: ViewContainerRef,
        private sankhyaService: SankhyaServiceService
    ) {
        this.clientesRelatorio = [];
    }

    ngOnInit() {}

    @Input() restUrl: string;

    clienteSelecionado: Parceiro = this.getParceiroVazio();
    clientesRelatorio: Parceiro[];
    ate: any;
    preDatado = true;
    devolvido = true;

    inserirCliente() {
        this.clientesRelatorio.push(this.clienteSelecionado);

        this.clienteSelecionado = Object.assign({ RAZAOSOCIAL: null, CODPARC: null } as Parceiro);
    }

    deleteCliente(index: number) {
        this.clientesRelatorio.splice(index, 1);
    }

    gerarPdf() {
        let listaClientes = '';

        this.clientesRelatorio.map(cliente => {
            listaClientes = listaClientes === '' ? `${cliente.CODPARC}` : `${listaClientes},${cliente.CODPARC}`;
        });

        // const param = {
        //   ate: (this.datePipe.transform(this.ate, 'dd/MM/yyyy') === null) ? ''
        //   : this.datePipe.transform(this.ate, 'dd/MM/yyyy'),
        //   clientes: listaClientes,
        //   preDatado: this.preDatado,
        //   devolvido: this.devolvido
        // };

        const param = {
            DTCORTE: this.datePipe.transform(this.ate, 'dd/MM/yyyy') === null ? '' : this.datePipe.transform(this.ate, 'dd/MM/yyyy'),
            LISTACLIENTES: listaClientes,
            PREDATADO: this.preDatado,
            DEVOLVIDO: this.devolvido,
        };

        // Mostrando o spinner na tela
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

        // this.portalService.imprimirChequesAberto(param);

        /*
        this.sankhyaService.getPermissaoUsu().subscribe((retorno: any) => {

            const ativo = retorno[0].ATIVO;

            if (ativo === 'S') {

                this.portalService.imprimirGeral({ params: param, url: this.restUrl + '.pdf' });
            } else {

                location.reload();
            }
        }, (error) => {

                location.reload();
            }); */
        this.portalService.imprimirGeral({ params: param, url: this.restUrl + '.pdf' });
        // Removendo o spinner da tela
        overlayRef.dispose();
    }

    private getParceiroVazio(): Parceiro {
        return {
            CODPARC: 0,
            NOMEPARC: '',
            RAZAOSOCIAL: '',
            CODVEND: 0,
            TIPO: '',
            NOMEEND: '',
            NUMEND: 0,
            COMPLEMENTO: '',
            NOMEBAI: '',
            NOMECID: '',
            CEP: '',
            UF: '',
            PAISEND: '',
            TELEFONE: '',
            EMAIL: '',
            IDENTINSCESTAD: '',
            CGCCPF: '',
            ATIVO: '',
            LIMCRED: 0,
            CLIENTE: '',
            FORNECEDOR: '',
            VENDEDOR: '',
            TRANSPORTADORA: '',
            BLOQUEAR: '',
            ADSITUACAOPORTAL: '',
        };
    }

    emitirClienteSelecionado(cliente: Parceiro) {
        this.clienteSelecionado = { ...cliente };
        this.inserirCliente()
    }

    clearClienteAutocomplete() {
        this.clienteSelecionado = this.getParceiroVazio();
    }
}
