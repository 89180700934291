import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-apoio-operacional',
    templateUrl: './apoio-operacional.component.html',
    styleUrls: ['./apoio-operacional.component.scss']
})
export class ApoioOperacionalComponent implements OnInit {
    constructor() { }

    ngOnInit() {
    }

}
