import { Overlay } from '@angular/cdk/overlay';
import { Component, ElementRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import Chart from 'chart.js/auto';

import { PortalJpaService } from '../services/portal-jpa.service';
import { AutenticacaoService } from '../services/autenticacao.service';
import { MatAutocompleteTrigger, MatSelect, MatSnackBar } from '@angular/material';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { Estatistica } from './local-interfaces/estatistica-interface';

@Component({
    selector: 'app-graficos',
    templateUrl: './graficos.component.html',
    styleUrls: ['./graficos.component.scss'],
})
export class GraficosComponent implements OnInit {

    constructor(
        private jpaService: PortalJpaService,
        public overlay: Overlay,
        public viewContainerRef: ViewContainerRef,
        private auth: AutenticacaoService,
        private snack: MatSnackBar,
    ) {
        this.repsFiltrados = this.repsCtrl.valueChanges.pipe(
            startWith(''),
            map(rep => rep ? this.filtraReps(rep) : this.listaRepresentante.slice())
        );
    }

    //@ts-ignore
    @ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;
    
    //@ts-ignore
    @ViewChild('inputRep') inputRep!: ElementRef;

    //@ts-ignore
    @ViewChild('selectPeri') selectPeri!: MatSelect;

    // Objeto com o periodo em formato de datas, para ser enviado ao backend
    datasPeriodo = this.periodoParaDatas(0);

    // Anos dos periodos
    anoAtual: string = '';
    anoAnterior: string = '';
    diaAtual: string = '';
    mesAtualExtenso: String = '';

    // Representante e o sujeito da busca e codRep do usuario
    representante: number;
    representanteNome: string;
    codRep: number = this.auth.getCodRep();
    listaRepresentante = [];

    // Chart JS
    canvas: any;
    ctx: any;
    chartFaturamento: any;
    chartFaturamentoEvolucao: any;
    chartClientes: any;
    chartProdutos: any;
    chartProdutosEvolucao: any;
    chartFaturamentoDiario: any;
    chartProdutosDiario: any;
    legendMargin: any =  {
        id: 'legendMargin',
        beforeInit: function (chart, legend, options) {
            const fitValue = chart.legend.fit;

            chart.legend.fit = function () {
                fitValue.bind(chart.legend)();
                return (this.height += 20);
            };
        },
    };

    // Periodos da busca
    periodos = ['3 meses', '6 meses', '9 meses', '12 meses'];
    periodo = '3 meses';

    // Titulos para evolucao
    totalProdRoi = '0';
    percentualRoi = '0';
    totalFatMesRoi = '0';
    totalProMesRoi = '0';
    totalFaturamentoAtual = '0';
    totalFaturamentoAnterior = '0';
    totalFatMesAtual = '0';
    totalFatMesAnterior = '0';
    totalProdAtual = '0';
    totalProdAnterior = '0';
    totalProMesAtual = '0';
    totalProMesAnterior = '0';
    fatDiaAnterior = '0';
    prodDiaAnterior = '0';
    colorState;
    colorStateProd;
    colorStateFatMes;
    colorStateProMes;
    selectedOption;

    //Formatacao mobile/desktop
    device = '';
    device_1 = '';
    angulo = 0;

    // Listas que contem os respectivos dados como cada item
    periodoAnteriorFaturamento: number[] = [];
    periodoAtualFaturamento: number[] = [];
    mesAtualFaturamento: number[] = [];
    mesAnteriorFaturamento: number[] = [];
    periodoAnteriorProduto: number[] = [];
    periodoAtualProduto: number[] = [];
    mesAtualProduto: number[] = [];
    mesAnteriorProduto: number[] = [];

    // Clientes da carteira
    atendidos: number = 0;
    naoAtendidos: number = 0;
    totalClientes: number = 0;

    // tabIndex = 0;

    // Visualizacao da pagina
    showOvl: boolean = false;
    showPeriodo: boolean = false;
    showFaturamento: string = '';
    showProduto: string = 'none';
    mostraPagina: boolean = false;
    periodoAnteriorZerado: boolean = false;
    indiceGrupo: number = 0;

    // Busca de representante
    repsCtrl = new FormControl();
    repsFiltrados;

    mesesExtenso = [
        'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
    ];

    ngOnInit(): void {
        // Se o representante for administrativo, libera selecao de vendedores
        if (this.codRep != 0) {
            this.representante = this.auth.getCodRep();
            this.showPeriodo = true;
            this.selectedOption = this.periodo;
            this.periodo = this.periodos[0];
            this.buscaDados().catch((e: any) => this.snack.open(
                'Não foi possível gerar os gráficos para este representante', 
                'Ok', 
                {duration: 9000})
            ).finally(() => {
                this.showOvl = false;
                this.mostraPagina = true;
                this.indiceGrupo = 0;
            });
        } else {
            this.jpaService.getListaRepresentanteGrafico().subscribe((res: any) => {
                this.listaRepresentante = res.representantes;
                this.listaRepresentante.sort((a, b) => a.CODVEND - b.CODVEND);
            });
        }

        // Ajusta componentes conforme resolucao do dispositivo
        if (window.matchMedia('(max-width: 912px)').matches) {
            this.device = '300';
            this.device_1 = '525';
            this.angulo = 45;
        } else {
            this.device = '170';
            this.device_1 = '500';
            this.angulo = 0;
        }
    }

    // Funcao que busca os dados e atualiza os graficos
    async buscaDados() {
        
        // Retorna para a aba de faturamento
        this.indiceGrupo = 1;

        // Mostra o Overlay e esconde os outros componentes da pagina
        this.mostraPagina = false;
        this.showOvl = true;

        // Deleta os graficos existentes
        if (this.chartFaturamento) {
            this.chartFaturamento.destroy();
            this.chartFaturamentoEvolucao.destroy();
            this.chartProdutos.destroy();
            this.chartProdutosEvolucao.destroy();
            this.chartFaturamentoDiario.destroy();
            this.chartProdutosDiario.destroy();
        }

        // Limpa as listas dos dados
        this.periodoAnteriorFaturamento = [];
        this.periodoAnteriorProduto = [];
        this.periodoAtualFaturamento = [];
        this.periodoAtualProduto = [];
        this.mesAtualFaturamento = [];
        this.mesAtualProduto = [];
        this.periodoAnteriorZerado = false;
        
        // Realiza a busca dos dados do parceiro
        this.datasPeriodo = this.periodoParaDatas(Number(this.periodo.split(' ')[0]));
        
        const buscaDados: any = await this.jpaService.getEstatisticasGraficoPorRepresentante(
            this.representante, 
            this.datasPeriodo.inicio, 
            this.datasPeriodo.fim,
            this.datasPeriodo.inicioAnterior, 
            this.datasPeriodo.fimAnterior).toPromise();

        const buscaDadosClientes: any = buscaDados.response.clientes;
        const buscaDadosAnterior: any = buscaDados.response.anterior;
        const buscaDadosAtuais: any = buscaDados.response.atual;

        // Mapeia os anos
        try {
            this.anoAtual = this.datasPeriodo.fim.getFullYear().toString();
            this.anoAnterior = this.datasPeriodo.fimAnterior.getFullYear().toString();
        } catch {
            this.anoAtual = 'Inexistente';
            this.anoAnterior = 'Inexistente';
        }

        // Mapeia os clientes atendidos
        this.atendidos = 0;
        this.naoAtendidos = 0;
        this.totalClientes = 0;
        try {
            this.atendidos = buscaDadosClientes.atendidos[0];
        } catch (error) {
            this.atendidos = 0;
        }
        try {
            this.naoAtendidos = buscaDadosClientes.natendidos[0];
        } catch (error) {
            this.naoAtendidos = 0;
        }
        this.totalClientes = this.atendidos + this.naoAtendidos;

        // Organiza os resultados da busca em um objeto mesAno: dados x dia
        let mesAnoAnterior = this.organizaDadosMesAno(buscaDadosAnterior, this.datasPeriodo.inicioAnterior, this.datasPeriodo.fimAnterior);
        let mesAnoAtual = this.organizaDadosMesAno(buscaDadosAtuais, this.datasPeriodo.inicio, this.datasPeriodo.fim);

        // Organiza os dados x mes do periodo atual
        this.mesanoParaArr(mesAnoAtual, this.datasPeriodo.inicio, this.datasPeriodo.fim).map((mes: Estatistica[]) => {
            let fat = 0;
            let prod = 0;
            
            for (let dia of mes) {
                fat += dia.vlrTotal;
                prod += dia.totalProd;
            }
            this.periodoAtualFaturamento.push(fat);
            this.periodoAtualProduto.push(prod);
        });

        // Organiza os dados x mes do periodo anterior
        this.mesanoParaArr(mesAnoAnterior, this.datasPeriodo.inicioAnterior, this.datasPeriodo.fimAnterior).map((mes: Estatistica[]) => {
            let fat = 0;
            let prod = 0;
            
            for (let dia of mes) {
                fat += dia.vlrTotal;
                prod += dia.totalProd;
            }
            this.periodoAnteriorFaturamento.push(fat);
            this.periodoAnteriorProduto.push(prod);
        });

        const ultimoDia = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate();

        // Organiza os dados x dia do periodo atual
        let dadosDiariosAtual = mesAnoAtual[Number(`${new Date().getMonth() + 1}${new Date().getFullYear()}`)];
        [this.mesAtualFaturamento, this.mesAtualProduto] = this.mesanoArrDiario(dadosDiariosAtual, new Date().getDate());

        // Organiza os dados x dia do periodo anterior
        let dadosDiariosAnterior = mesAnoAnterior[Number(`${new Date().getMonth() + 1}${new Date().getFullYear() - 1}`)];
        [this.mesAnteriorFaturamento, this.mesAnteriorProduto] = this.mesanoArrDiario(dadosDiariosAnterior, ultimoDia);

        // Calcula legendas de meses
        let mesesLeg = this.getMesesPeriodoFormatadosExtenso(this.periodo.split(' ')[0]);

        // Calcula legendas de dias
        let diasLeg = this.diasMesString(new Date(), ultimoDia);

        // Mes atual
        this.diaAtual = `${new Date().getDate()}/${new Date().getMonth() + 1}`;
        this.mesAtualExtenso = this.mesesExtenso[new Date().getMonth()];

        if (window.matchMedia('(max-width: 912px)').matches) {
            this.mesAtualExtenso = this.mesAtualExtenso[0] + this.mesAtualExtenso[1] + this.mesAtualExtenso[2];

            document.getElementById('dia_produto').setAttribute('height', '400');
            document.getElementById('line_evol_soma').setAttribute('height', '400');
            document.getElementById('line_produto').setAttribute('height', '400');
            document.getElementById('dia_faturamento').setAttribute('height', '400');
            document.getElementById('line_soma').setAttribute('height', '400');
            document.getElementById('line_faturamento').setAttribute('height', '400');
        }

        // Formata o faturamento atual que aparecera na tooltip
        this.totalFaturamentoAtual = this.somaArray(this.periodoAtualFaturamento)
            .toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
            })
            .replace('R$', '')
            .replace(',00', '');

        // Formata o faturamento anterior que aparecera na tooltip
        this.totalFaturamentoAnterior = this.somaArray(this.periodoAnteriorFaturamento)
            .toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
            })
            .replace('R$', '')
            .replace(',00', '');

        this.totalFatMesAtual = this.mesAtualFaturamento[this.mesAtualFaturamento.length - 1].toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            })
            .replace('R$', '')
            .replace(',00', '');

        this.totalFatMesAnterior = this.mesAnteriorFaturamento[this.mesAnteriorFaturamento.length - 1].toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            })
            .replace('R$', '')
            .replace(',00', '');

        // Formata o produto atual que aparecera na tooltip
        this.totalProdAtual = this.somaArray(this.periodoAtualProduto)
            .toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
            })
            .replace('R$', '')
            .replace(',00', '');

        this.totalProMesAtual = this.mesAtualProduto[this.mesAtualProduto.length - 1].toString();
        
        this.totalProMesAnterior = this.mesAnteriorProduto[this.mesAnteriorProduto.length - 1].toString();

        this.fatDiaAnterior = this.mesAnteriorFaturamento[new Date().getDate() - 1].toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        })
        .replace('R$', '')
        .replace(',00', '');

        this.prodDiaAnterior = this.mesAnteriorProduto[new Date().getDate() - 1].toString();

        // Formata o produto anterior que aparecera na tooltip
        this.totalProdAnterior = this.somaArray(this.periodoAnteriorProduto)
            .toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
            })
            .replace('R$', '')
            .replace(',00', '');

        // Calcula e formata o percentual de evolucao no faturamento
        this.percentualRoi = this.roi(this.somaArray(this.periodoAtualFaturamento), this.somaArray(this.periodoAnteriorFaturamento)).toLocaleString(undefined, {
            style: 'percent',
            minimumFractionDigits: 2,
        });

        // Calcula e formata o percentual de evolucao no produto
        this.totalProdRoi = this.roi(this.somaArray(this.periodoAtualProduto), this.somaArray(this.periodoAnteriorProduto)).toLocaleString(undefined, {
            style: 'percent',
            minimumFractionDigits: 2,
        });

        this.totalFatMesRoi = this.roi(
            this.mesAtualFaturamento[this.mesAtualFaturamento.length - 1].toString(), 
            this.mesAnteriorFaturamento[this.mesAtualFaturamento.length - 1].toString()).toLocaleString(undefined, {
                style: 'percent',
                minimumFractionDigits: 2,
            });

        this.totalProMesRoi = this.roi(
            this.mesAtualProduto[this.mesAtualProduto.length - 1].toString(), 
            this.mesAnteriorProduto[this.mesAtualProduto.length - 1].toString()).toLocaleString(undefined, {
                style: 'percent',
                minimumFractionDigits: 2,
            });

        // Caso nao tenha dados do periodo anterior, nao exibe roi e esconde a reta
        if (Number(this.totalProdAnterior) === 0) this.periodoAnteriorZerado = true;

        // Resgata as cores dos roi
        this.colorState = this.percentualRoi.indexOf('-');
        this.colorStateProd = this.totalProdRoi.indexOf('-');
        
        this.colorStateFatMes = this.totalFatMesRoi.indexOf('-');
        this.colorStateProMes = this.totalProMesRoi.indexOf('-');

        // Gera o grafico de faturamento x mes
        this.canvas = document.getElementById('line_faturamento');
        this.ctx = this.canvas.getContext('2d');
        this.canvas.style.height = this.device;
        this.canvas.style.display = this.showFaturamento;

        this.chartFaturamento = new Chart(this.ctx, {
            type: 'bar',
            height: 6000,
            data: {
                labels: mesesLeg,
                datasets: [
                    {
                        label: 'Fat ' + this.anoAtual,
                        data: this.periodoAtualFaturamento,
                        backgroundColor: 'rgba(54, 162, 235, 1)',
                        borderColor: 'rgba(54, 162, 235, 1)',
                        borderWidth: 2,
                        // tension: 0.4,
                    },
                    {
                        label: 'Fat ' + this.anoAnterior,
                        data: this.periodoAnteriorFaturamento,
                        backgroundColor: 'rgba(255, 99, 132, 0.3)',
                        borderColor: 'rgba(255, 99, 132, 0.3)',
                        borderWidth: 2,
                        // tension: 0.4,
                        hidden: (this.periodoAnteriorZerado)
                    },
                ],
            },
            options: {
                plugins: {
                    legend: {
                        display: true,

                        position: 'top',
                        align: 'start',
                    },
                    tooltip: {
                        callbacks: {
                            label(getContext) {
                                return getContext.parsed.y.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
                            },
                            //@ts-ignore
                            beforeTitle: function (tooltipItem, data) {
                                let splitedDate = tooltipItem[0].label.split('/');
                                let yearBefore = splitedDate[0] + '/' + (+splitedDate[1] - 1);

                                return 'Faturamento: ';
                            },
                        },
                    },
                    title: {
                        display: true,
                        position: 'bottom',
                        align: 'start',
                        text: this.representanteNome
                    }
                },
                interaction: {
                    mode: 'nearest',
                    axis: 'x',
                    intersect: false,
                },
                scales: {
                    y: {
                        ticks: {
                            //@ts-ignore
                            fontColor: 'rgba(0,0,0,0.5)',
                            fontStyle: 'bold',
                            beginAtZero: true,
                            maxTicksLimit: 10,
                            callback: function (value, index, values) {
                                let tamanho = value.toString().length;
                                const si = [
                                    { value: 1, symbol: '' },
                                    { value: 1e3, symbol: 'K' },
                                    { value: 1e6, symbol: 'M' },
                                    { value: 1e9, symbol: 'B' },
                                    { value: 1e12, symbol: 'T' },
                                    { value: 1e15, symbol: 'P' },
                                    { value: 1e18, symbol: 'E' },
                                ];
                                const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
                                let i;
                                for (i = si.length - 1; i > 0; i--) {
                                    if (+value >= si[i].value) {
                                        break;
                                    }
                                }
                                return (+value / si[i].value).toFixed(tamanho).replace(rx, '$1') + si[i].symbol;
                            },
                        },
                        grid: {
                            borderDash: [10, 10],
                        }
                    },
                    x: {
                        gridLines: {
                            zeroLineColor: 'transparent',
                        },
                        ticks: {
                            //@ts-ignore
                            fontColor: 'rgba(0,0,0,0.5)',
                            fontStyle: 'bold',
                            min: 0,
                            maxTicksLimit: 10,
                            maxRotation: this.angulo,
                            autoSkip: true,
                        },
                        grid: {
                            borderDash: [10, 10],
                        },
                    },
                },
                elements: {
                    point: {
                        radius: 0,
                    },
                },
            },
            plugins: [this.legendMargin],
        });

        // Gera o grafico de soma do faturamento x mes
        this.canvas = document.getElementById('line_soma');
        //@ts-ignore
        this.ctx = this.canvas.getContext('2d');
        this.canvas.style.height = this.device_1;
        this.canvas.style.display = this.showFaturamento;

        this.chartFaturamentoEvolucao = new Chart(this.ctx, {
            type: 'line',
            data: {
                labels: mesesLeg,
                datasets: [
                    {
                        label: 'Fat ' + this.anoAtual,
                        data: this.financialEvolution(this.periodoAtualFaturamento),
                        backgroundColor: 'rgba(54, 162, 235, 1)',
                        borderColor: 'rgba(54, 162, 235, 1)',
                        borderWidth: 2,
                        tension: 0.4,
                    },
                    {
                        label: 'Fat ' + this.anoAnterior,
                        data: this.financialEvolution(this.periodoAnteriorFaturamento),
                        backgroundColor: 'rgba(255, 99, 132, 0.3)',
                        borderColor: 'rgba(255, 99, 132, 0.3)',
                        borderWidth: 2,
                        tension: 0.4,
                        hidden: (this.periodoAnteriorZerado)
                    },
                ],
            },
            options: {
                plugins: {
                    //@ts-ignore
                    maintainAspectRatio: false,
                    responsive: true,
                    display: true,
                    legend: {
                        position: 'top',
                        align: 'start',
                    },
                    tooltip: {
                        callbacks: {
                            label(getContext) {
                                return getContext.parsed.y.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
                            },
                            //@ts-ignore
                            beforeTitle: function (tooltipItem, data) {
                                return 'Faturamento acumulado:';
                            },
                        },
                    },
                    title: {
                        display: true,
                        position: 'bottom',
                        align: 'start',
                        text: this.representanteNome
                    }
                },
                interaction: {
                    mode: 'nearest',
                    axis: 'x',
                    intersect: false,
                },
                scales: {
                    y: {
                        ticks: {
                            //@ts-ignore
                            fontColor: 'rgba(0,0,0,0.5)',
                            fontStyle: 'bold',
                            beginAtZero: true,
                            maxTicksLimit: 10,
                            callback: function (value, index, values) {
                                let tamanho = value.toString().length;
                                const si = [
                                    { value: 1, symbol: '' },
                                    { value: 1e3, symbol: 'K' },
                                    { value: 1e6, symbol: 'M' },
                                    { value: 1e9, symbol: 'B' },
                                    { value: 1e12, symbol: 'T' },
                                    { value: 1e15, symbol: 'P' },
                                    { value: 1e18, symbol: 'E' },
                                ];
                                const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
                                let i;
                                for (i = si.length - 1; i > 0; i--) {
                                    if (+value >= si[i].value) {
                                        break;
                                    }
                                }
                                return (+value / si[i].value).toFixed(tamanho).replace(rx, '$1') + si[i].symbol;
                            },
                        },
                        grid: {
                            borderDash: [10, 10],
                        },
                    },
                    x: {
                        gridLines: {
                            zeroLineColor: 'transparent',
                        },
                        ticks: {
                            //@ts-ignore
                            fontColor: 'rgba(0,0,0,0.5)',
                            fontStyle: 'bold',
                            min: 0,
                            maxTicksLimit: 10,
                            maxRotation: this.angulo,
                            autoSkip: true,
                        },
                        grid: {
                            borderDash: [10, 10],
                        },
                    },
                },
                elements: {
                    point: {
                        radius: 0,
                    },
                },
            },
            plugins: [this.legendMargin],
        });

        // Gera o grafico de faturamento x dia
        this.canvas = document.getElementById('dia_faturamento');
        this.ctx = this.canvas.getContext('2d');
        this.canvas.style.height = this.device;
        this.canvas.style.display = this.showFaturamento;

        this.chartFaturamentoDiario = new Chart(this.ctx, {
            type: 'line',
            data: {
                labels: diasLeg,
                datasets: [
                    {
                        label: 'Fat ' + this.mesAtualExtenso + '/' + this.anoAtual,
                        data: this.mesAtualFaturamento,
                        backgroundColor: 'rgba(54, 162, 235, 1)',
                        borderColor: 'rgba(54, 162, 235, 1)',
                        borderWidth: 2,
                        tension: 0,
                        pointRadius: 2
                    },
                    {
                        label: 'Fat ' + this.mesAtualExtenso + '/' + this.anoAnterior,
                        data: this.mesAnteriorFaturamento,
                        backgroundColor: 'rgba(255, 99, 132, 0.3)',
                        borderColor: 'rgba(255, 99, 132, 0.3)',
                        borderWidth: 2,
                        tension: 0,
                        pointRadius: 2,
                        hidden: (this.periodoAnteriorZerado)
                    },
                ],
            },
            options: {
                plugins: {
                    legend: {
                        display: true,

                        position: 'top',
                        align: 'start',
                    },
                    tooltip: {
                        callbacks: {
                            label(getContext) {
                                return getContext.parsed.y.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
                            },
                            //@ts-ignore
                            beforeTitle: function (tooltipItem, data) {
                                let splitedDate = tooltipItem[0].label.split('/');
                                let yearBefore = splitedDate[0] + '/' + (+splitedDate[1] - 1);

                                return 'Faturamento: ';
                            },
                        },
                    },
                    title: {
                        display: true,
                        position: 'bottom',
                        align: 'start',
                        text: this.representanteNome
                    }
                },
                interaction: {
                    mode: 'nearest',
                    axis: 'x',
                    intersect: false,
                },
                scales: {
                    y: {
                        ticks: {
                            //@ts-ignore
                            fontColor: 'rgba(0,0,0,0.5)',
                            fontStyle: 'bold',
                            beginAtZero: true,
                            maxTicksLimit: 10,
                            callback: function (value, index, values) {
                                let tamanho = value.toString().length;
                                const si = [
                                    { value: 1, symbol: '' },
                                    { value: 1e3, symbol: 'K' },
                                    { value: 1e6, symbol: 'M' },
                                    { value: 1e9, symbol: 'B' },
                                    { value: 1e12, symbol: 'T' },
                                    { value: 1e15, symbol: 'P' },
                                    { value: 1e18, symbol: 'E' },
                                ];
                                const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
                                let i;
                                for (i = si.length - 1; i > 0; i--) {
                                    if (+value >= si[i].value) {
                                        break;
                                    }
                                }
                                return (+value / si[i].value).toFixed(tamanho).replace(rx, '$1') + si[i].symbol;
                            },
                        },
                        grid: {
                            borderDash: [10, 10],
                        }
                    },
                    x: {
                        gridLines: {
                            zeroLineColor: 'transparent',
                        },
                        ticks: {
                            //@ts-ignore
                            fontColor: 'rgba(0,0,0,0.5)',
                            fontStyle: 'bold',
                            min: 0,
                            maxTicksLimit: 10,
                            maxRotation: this.angulo,
                            autoSkip: true,
                        },
                        grid: {
                            borderDash: [10, 10],
                        },
                    },
                },
                elements: {
                    point: {
                        radius: 0,
                    },
                },
            },
            plugins: [this.legendMargin],
        });

        // Gera o grafico de produtos x mes
        this.canvas = document.getElementById('line_produto');
        //@ts-ignore
        this.ctx = this.canvas.getContext('2d');
        this.canvas.style.height = this.device;
        this.canvas.style.display = this.showProduto;

        this.chartProdutos = new Chart(this.ctx, {
            type: 'bar',
            data: {
                labels: mesesLeg,
                datasets: [
                    {
                        label: 'Prod ' + this.anoAtual,
                        data: this.periodoAtualProduto,
                        backgroundColor: 'rgba(54, 162, 235, 1)',
                        borderColor: 'rgba(54, 162, 235, 1)',
                        borderWidth: 2,
                        // tension: 0.4,
                    },
                    {
                        label: 'Prod ' + this.anoAnterior,
                        data: this.periodoAnteriorProduto,
                        backgroundColor: 'rgba(255, 99, 132, 0.3)',
                        borderColor: 'rgba(255, 99, 132, 0.3)',
                        borderWidth: 2,
                        // tension: 0.4,
                        hidden: (this.periodoAnteriorZerado)
                    },
                ],
            },
            options: {
                plugins: {
                    legend: {
                        display: true,

                        position: 'top',
                        align: 'start',
                    },
                    tooltip: {
                        callbacks: {
                            //@ts-ignore
                            beforeTitle: function (tooltipItem, data) {
                                return 'Produtos do mês: ';
                            },
                        },
                    },
                    title: {
                        display: true,
                        position: 'bottom',
                        align: 'start',
                        text: this.representanteNome
                    }
                },
                interaction: {
                    mode: 'nearest',
                    axis: 'x',
                    intersect: false,
                },
                scales: {
                    y: {
                        ticks: {
                            //@ts-ignore
                            fontColor: 'rgba(0,0,0,0.5)',
                            fontStyle: 'bold',
                            beginAtZero: true,
                            maxTicksLimit: 10,
                            callback: function (value, index, values) {
                                let tamanho = value.toString().length;
                                const si = [
                                    { value: 1, symbol: '' },
                                    { value: 1e3, symbol: 'K' },
                                    { value: 1e6, symbol: 'M' },
                                    { value: 1e9, symbol: 'B' },
                                    { value: 1e12, symbol: 'T' },
                                    { value: 1e15, symbol: 'P' },
                                    { value: 1e18, symbol: 'E' },
                                ];
                                const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
                                let i;
                                for (i = si.length - 1; i > 0; i--) {
                                    if (+value >= si[i].value) {
                                        break;
                                    }
                                }
                                return (+value / si[i].value).toFixed(tamanho).replace(rx, '$1') + si[i].symbol;
                            },
                        },
                        grid: {
                            borderDash: [10, 10],
                        },
                    },
                    x: {
                        gridLines: {
                            zeroLineColor: 'transparent',
                        },
                        ticks: {
                            //@ts-ignore
                            fontColor: 'rgba(0,0,0,0.5)',
                            fontStyle: 'bold',
                            min: 0,
                            maxTicksLimit: 10,
                            maxRotation: this.angulo,
                            autoSkip: true,
                        },
                        grid: {
                            borderDash: [10, 10],
                        },
                    },
                },
                elements: {
                    point: {
                        radius: 0,
                    },
                },
            },
            plugins: [this.legendMargin],
        });

        // Gera o grafico de soma dos produtos x mes
        this.canvas = document.getElementById('line_evol_soma');
        //@ts-ignore
        this.ctx = this.canvas.getContext('2d');
        this.canvas.style.height = this.device_1;
        this.canvas.style.display = this.showProduto;

        this.chartProdutosEvolucao = new Chart(this.ctx, {
            type: 'line',
            data: {
                labels: mesesLeg,
                datasets: [
                    {
                        label: 'Prod ' + this.anoAtual,
                        data: this.financialEvolution(this.periodoAtualProduto),
                        backgroundColor: 'rgba(54, 162, 235, 1)',
                        borderColor: 'rgba(54, 162, 235, 1)',
                        borderWidth: 2,
                        tension: 0.4,
                    },
                    {
                        label: 'Prod ' + this.anoAnterior,
                        data: this.financialEvolution(this.periodoAnteriorProduto),
                        backgroundColor: 'rgba(255, 99, 132, 0.3)',
                        borderColor: 'rgba(255, 99, 132, 0.3)',
                        borderWidth: 2,
                        tension: 0.4,
                        hidden: (this.periodoAnteriorZerado)
                    },
                ],
            },
            options: {
                plugins: {
                    //@ts-ignore
                    maintainAspectRatio: false,
                    responsive: true,
                    display: true,
                    legend: {
                        position: 'top',
                        align: 'start',
                    },
                    tooltip: {
                        callbacks: {
                            //@ts-ignore
                            beforeTitle: function (tooltipItem, data) {
                                return 'Produtos acumulados:';
                            },
                        },
                    },
                    title: {
                        display: true,
                        position: 'bottom',
                        align: 'start',
                        text: this.representanteNome
                    }
                },
                interaction: {
                    mode: 'nearest',
                    axis: 'x',
                    intersect: false,
                },
                scales: {
                    y: {
                        ticks: {
                            //@ts-ignore
                            fontColor: 'rgba(0,0,0,0.5)',
                            fontStyle: 'bold',
                            beginAtZero: true,
                            maxTicksLimit: 10,
                            callback: function (value, index, values) {
                                let tamanho = value.toString().length;
                                const si = [
                                    { value: 1, symbol: '' },
                                    { value: 1e3, symbol: 'K' },
                                    { value: 1e6, symbol: 'M' },
                                    { value: 1e9, symbol: 'B' },
                                    { value: 1e12, symbol: 'T' },
                                    { value: 1e15, symbol: 'P' },
                                    { value: 1e18, symbol: 'E' },
                                ];
                                const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
                                let i;
                                for (i = si.length - 1; i > 0; i--) {
                                    if (+value >= si[i].value) {
                                        break;
                                    }
                                }
                                return (+value / si[i].value).toFixed(tamanho).replace(rx, '$1') + si[i].symbol;
                            },
                        },
                        grid: {
                            borderDash: [10, 10],
                        },
                    },
                    x: {
                        gridLines: {
                            zeroLineColor: 'transparent',
                        },
                        ticks: {
                            //@ts-ignore
                            fontColor: 'rgba(0,0,0,0.5)',
                            fontStyle: 'bold',
                            min: 0,
                            maxTicksLimit: 10,
                            maxRotation: this.angulo,
                            autoSkip: true,
                        },
                        grid: {
                            borderDash: [10, 10],
                        },
                    },
                },
                elements: {
                    point: {
                        radius: 0,
                    },
                },
            },
            plugins: [this.legendMargin],
        });

        // Gera o grafico de produto x dia
        this.canvas = document.getElementById('dia_produto');
        this.ctx = this.canvas.getContext('2d');
        this.canvas.style.height = this.device;
        this.canvas.style.display = this.showProduto;

        this.chartProdutosDiario = new Chart(this.ctx, {
            type: 'line',
            data: {
                labels: diasLeg,
                datasets: [
                    {
                        label: 'Prod ' + this.mesAtualExtenso + '/' + this.anoAtual,
                        data: this.mesAtualProduto,
                        backgroundColor: 'rgba(54, 162, 235, 1)',
                        borderColor: 'rgba(54, 162, 235, 1)',
                        borderWidth: 2,
                        tension: 0,
                        pointRadius: 2
                    },
                    {
                        label: 'Prod ' + this.mesAtualExtenso + '/' + this.anoAnterior,
                        data: this.mesAnteriorProduto,
                        backgroundColor: 'rgba(255, 99, 132, 0.3)',
                        borderColor: 'rgba(255, 99, 132, 0.3)',
                        borderWidth: 2,
                        tension: 0,
                        pointRadius: 2,
                        hidden: (this.periodoAnteriorZerado)
                    },
                ],
            },
            options: {
                plugins: {
                    legend: {
                        display: true,

                        position: 'top',
                        align: 'start',
                    },
                    tooltip: {
                        callbacks: {
                            //@ts-ignore
                            beforeTitle: function (tooltipItem, data) {
                                return 'Produtos acumulados:';
                            },
                        },
                    },
                    title: {
                        display: true,
                        position: 'bottom',
                        align: 'start',
                        text: this.representanteNome
                    }
                },
                interaction: {
                    mode: 'nearest',
                    axis: 'x',
                    intersect: false,
                },
                scales: {
                    y: {
                        ticks: {
                            //@ts-ignore
                            fontColor: 'rgba(0,0,0,0.5)',
                            fontStyle: 'bold',
                            beginAtZero: true,
                            maxTicksLimit: 10,
                            callback: function (value, index, values) {
                                let tamanho = value.toString().length;
                                const si = [
                                    { value: 1, symbol: '' },
                                    { value: 1e3, symbol: 'K' },
                                    { value: 1e6, symbol: 'M' },
                                    { value: 1e9, symbol: 'B' },
                                    { value: 1e12, symbol: 'T' },
                                    { value: 1e15, symbol: 'P' },
                                    { value: 1e18, symbol: 'E' },
                                ];
                                const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
                                let i;
                                for (i = si.length - 1; i > 0; i--) {
                                    if (+value >= si[i].value) {
                                        break;
                                    }
                                }
                                return (+value / si[i].value).toFixed(tamanho).replace(rx, '$1') + si[i].symbol;
                            },
                        },
                        grid: {
                            borderDash: [10, 10],
                        }
                    },
                    x: {
                        gridLines: {
                            zeroLineColor: 'transparent',
                        },
                        ticks: {
                            //@ts-ignore
                            fontColor: 'rgba(0,0,0,0.5)',
                            fontStyle: 'bold',
                            min: 0,
                            maxTicksLimit: 10,
                            maxRotation: this.angulo,
                            autoSkip: true,
                        },
                        grid: {
                            borderDash: [10, 10],
                        },
                    },
                },
                elements: {
                    point: {
                        radius: 0,
                    },
                },
            },
            plugins: [this.legendMargin],
        });
    }

    // Alterna entre os graficos de faturamento e de produto
    chartChange(chart) {
        if (chart.index == 0) {
            this.showFaturamento = '';
            this.showProduto = 'none';
        } else {
            this.showFaturamento = 'none';
            this.showProduto = '';
        }
    }

    // Formata porcentagem para barra da carteira de clientes
    percentage(partialValue, totalValue) {
        return (100 * partialValue) / totalValue;
    }

    // Soma todos os valores dentro de um array
    somaArray = data => {
        let result = data.reduce((a, b) => +a + +b, 0);
        return result;
    };

    // Calcula o percentual de variacao
    roi = (dataPreviousYear, dataCurrentYear) => {
        return ((dataCurrentYear - dataPreviousYear) / dataCurrentYear) * -1;
    };

    // Calcula a evolucao com base no ano anterior
    financialEvolution = dataPreviousYear => {
        let result = [];

        for (let index = 0; index < dataPreviousYear.length; index++) {
            if (index == 0) {
                result.push(+dataPreviousYear[index]);
            } else {
                result.push(result[index - 1] + +dataPreviousYear[index]);
            }
        }
        return result;
    };

    // Seleciona o representante
    selecionaRepresentante(rep) {
        if (!rep) {
            if (this.autocompleteTrigger.panelOpen) {
                const opcao = this.autocompleteTrigger.activeOption;
                if (this.autocompleteTrigger.activeOption) {
                    rep = this.listaRepresentante.find(rep => rep.APELIDO.toUpperCase() === opcao.value);
                } else {
                    const val = this.repsCtrl.value.toUpperCase();
                    rep = this.listaRepresentante.find(rep => rep.APELIDO.toUpperCase().includes(val));
                }
            }
        }

        if (rep) {
            this.representanteNome = rep.APELIDO;
            this.representante = rep.CODVEND;
            this.selectedOption = '';
            this.showPeriodo = true;
            this.repsCtrl.setValue(this.representanteNome);
        }

        this.autocompleteTrigger.closePanel();
        
        setTimeout(() => {
            this.inputRep.nativeElement.blur();
        }, 0);
    }

    // Retorna os meses formatados como string para legenda
    getMesesPeriodoFormatados(period) {
        let month = new Date().getMonth();
        let year = new Date().getFullYear();
        let months = [];
        for (let i = 0; i < +period; i++) {
            if (month === 0) {
                month = 12;
                year--;
            }
            months.push(month + '/' + year);
            month--;
        }
        return months.reverse();
    }

    // Com base no periodo, retorna em extenso os meses (p/ legenda)
    getMesesPeriodoFormatadosExtenso(period) {
        let month = new Date().getMonth();
        let year = new Date().getFullYear();
        let months = [];
        for (let i = 0; i < +period; i++) {
            if (month === 0) {
                month = 12;
                year--;
            }
            // months.push(month + '/' + year);
            months.push(this.mesesExtenso[month - 1])
            month--;
        }
        return months.reverse();
    }

    // Altera o periodo, que ja chama a busca
    selecionaPeriodo(periodo) {
        if (periodo) {
            this.periodo = periodo;
        } else {
            this.periodo = this.selectPeri.value;
            this.selectPeri.close();
        }

        this.buscaDados()
        .catch((e: any) => {
            console.log(e)
            this.snack.open(
                'Não foi possível gerar os gráficos para este representante', 
                'Ok', 
                {duration: 9000})
        })
        .finally(() => {
            this.showOvl = false;
            this.mostraPagina = true;
            this.indiceGrupo = 0;
        });
    }

    // Permite selecionar o periodo com o teclado
    onKeyDown(event) {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.selecionaPeriodo(null);
        }
    }

    // Converte periodo para o formato de datas que sera enviado na requisicao
    periodoParaDatas(periodo: number) {
        const fim = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1);
        const inicio = new Date((new Date().setMonth(new Date().getMonth() - periodo)));
        inicio.setDate(1);

        const fimAnterior = new Date(new Date().getFullYear() - 1, new Date().getMonth() + 1, 1);
        const inicioAnterior = new Date((new Date().setMonth(new Date().getMonth() - periodo - 12)));
        inicioAnterior.setDate(1);
        
        return { 
            inicio: inicio, 
            fim: fim,
            inicioAnterior: inicioAnterior, 
            fimAnterior: fimAnterior
        };
    }

    limitaDados(dados: any[], meses: any[]) {
        if (dados.length != meses.length) {
            return dados.slice(dados.length - meses.length, dados.length);
        } else return dados;
    }

    // Retorna um array com a lista dos representantes filtrados conforme a busca
    filtraReps(value: any) {
        if (typeof value !== 'string' || value.trim() === '') {
            return this.listaRepresentante.slice();
        }
        const valFiltro = value.toUpperCase();

        return this.listaRepresentante.filter(rep => (rep.CODVEND + ' - ' + rep.APELIDO.toUpperCase()).includes(valFiltro));
    }

    // Inicia a busca sem nenhum valor
    focaReps() {
        this.repsCtrl.setValue('');
    }

    // Com base no periodo, retorna objeto mesAno: dados, dados sendo os produtos e os faturamentos por dia
    organizaDadosMesAno(dados: any, dtInicio: Date, dtFim: Date): {[key: number]: Estatistica[]} {
        let data = new Date(dtInicio);
        let meses = {};
        
        for (let i = 0; i < this.mesesEntreDatas(dtInicio, dtFim) + 1; i++) {
            let mes = data.getMonth() + 1;
            let ano = data.getFullYear();
            let mesano = Number(`${mes}${ano}`);
            meses[mesano] = dados.filter((dado: any) => dado.mesAno === mesano);
            data.setMonth(data.getMonth() + 1);
        }
        return meses;
    }

    // Retorna a quantidade de meses entre duas datas
    mesesEntreDatas(inicio: Date, fim: Date): number {
        const inicioAno = inicio.getFullYear();
        const fimAno = fim.getFullYear();
        const inicioMes = inicio.getMonth();
        const fimMes = fim.getMonth();

        return (fimAno - inicioAno) * 12 + (fimMes - inicioMes);
    }

    // Resgata do mesAno um array contendo apenas os dados, usado para alimentar os graficos
    mesanoParaArr(mesano: {[key: number]: Estatistica[]}, dtInicio: Date, dtFim: Date) {
        let data = new Date(dtInicio);
        let mesanoPeriodo = [];
        let arrayRes = [];
        
        for (let i = 0; i < this.mesesEntreDatas(dtInicio, dtFim) + 1; i++) {
            let mes = data.getMonth() + 1;
            let ano = data.getFullYear();
            let mesano = Number(`${mes}${ano}`);
            mesanoPeriodo.push(mesano);
            data.setMonth(data.getMonth() + 1);
        }
        mesanoPeriodo.pop();
        for (let mes of mesanoPeriodo) {
            arrayRes.push(mesano[mes]);
        }
        return arrayRes;
    }

    // Retorna os dias do mes formatados para legenda
    diasMesString(data: Date, diaLimite: number) {
        let dias = [];
        let mes = data.getMonth() + 1;
        data.setDate(1);
        
        while (data.getMonth() === mes - 1 && data.getDate() <= diaLimite) {
            // dias.push(`${data.getDate().toString().padStart(2, '0')}/${mes.toString().padStart(2, '0')}`);
            dias.push(`${data.getDate().toString().padStart(2, '0')}`);
            data.setDate(data.getDate() + 1);
        }
        return dias;
    }
    
    // Retorna os dias do mes em formato de numero, seguindo a ordem do tipo Date
    diasMesNumber(data: Date, diaLimite: number) {
        let dias = [];
        let mes = data.getMonth();
        data.setDate(1);
        
        while (data.getMonth() === mes && data.getDate() <= diaLimite) {
            dias.push(data.getDate());
            data.setDate(data.getDate() + 1);
        }
        return dias;
    }

    // Formata os valores do array de dados populado com as Estatisticas do dia
    mesanoArrDiario(dadosDiarios, diaLimite: number) {
        let fatMes = [];
        let proMes = [];

        for (let diaMes of this.diasMesNumber(new Date(), diaLimite)) {
            let dado = dadosDiarios.find((dia: Estatistica) => new Date(dia.dataNota).getDate() === diaMes);
            
            let vlr;
            let pro;
            
            const ultimo = fatMes.length - 1;
            const fatUlt = fatMes[ultimo] ? fatMes[ultimo] : 0;
            const proUlt = proMes[ultimo] ? proMes[ultimo] : 0;
            
            if (dado) {
                vlr = dado.vlrTotal + fatUlt;
                pro = dado.totalProd + proUlt;
            } else {
                vlr = fatUlt;
                pro = proUlt;
                if (!vlr) {
                    vlr = 0;
                    pro = 0;
                }
            }
            fatMes.push(vlr);
            proMes.push(pro);
        }

        return [fatMes, proMes];
    }
}
