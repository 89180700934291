import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Parceiro } from '../global-interfaces/parceiro-interface';
import { throwError } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { ProdutoUnificado } from '../global-interfaces/produto-interface';
import { CabecalhoPedido, ItensPedido, TipoPedido } from '../global-interfaces/pedido-interface';
import { TipoNegociacao } from '../global-interfaces/tipo-negociacao-interface';
import { Config } from '../global-interfaces/config-interface';
import { PedidosPesquisa } from '../global-interfaces/pedidos-pesquisa-interface';
import { NumeroPO } from '../criar-pedido/local-interfaces/numero-po-interface';
import { Projeto } from '../global-interfaces/projeto-interface';
import { SegurancaRelatorios } from '../global-interfaces/seguranca-interface';
import { OcorrenciasCancelamento } from '../global-interfaces/ocorrencias-cancelamento-interface';
import { TitulosParceiros } from '../global-interfaces/titulos-parceiros-interface';
import { PedidosConsultaCliente } from '../consulta-clientes/local-interfaces/pedidos-consulta-cliente-interface';
import { ProdutoTabelaPreco } from '../global-interfaces/produto-tabela-preco-interface';
import { PedidosSituacao, PedidosUsuario } from '../programacao-operacional/local-interfaces/programacao-operacional-interface';

import { version } from 'src/../package.json';
import { portalEuro, sankhya, portal } from 'src/assets/config.json';
import { AutenticacaoService } from './autenticacao.service';

@Injectable({
    providedIn: 'root',
})
export class SankhyaServiceService {
    constructor(
        private http: HttpClient,
        private auth: AutenticacaoService
    ) {
        if (portalEuro.ambiente === 'producao' || portalEuro.ambiente === 'homolog') {
            this.urlSankhya = sankhya.url.remoto;
        } else if (portalEuro.ambiente === 'localhost') {
            this.urlSankhya = sankhya.url.local;
        } else if (portalEuro.ambiente === 'localIp') {
            this.urlSankhya = sankhya.url.localIp;
        }

        // No envio de pedido pela notificação não estava inicializando esta variável sem entrar na tela de pedidos.
        this.enviarPedidoUrl = sankhya.pedidos.enviarPedido.url;
    }

    config = 'assets/config.json?p=' + Date.now();
    urlSankhya: string;
    parceirosUrl = '';
    transportadoraUrl = '';
    produtosUrl = '';
    produtosProgramadoUrl = '';
    transportadorasUrl = '';
    enviarPedidoUrl = '';
    enviarPedidoPreviewUrl = '';
    consultarPedidoUrl = '';
    tipoNegociacaoUrl = '';

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            version,
        }),
    };

    body = {
        userName: this.auth.getUserName(),
        token: 'soifhw903rnosld',
        codRep: this.auth.getCodRep(),
        serviceName: '',
        param: [
            {
                paramName: '',
                paramValue: '',
            },
        ],
    };

    bodyNota = {
        userName: this.auth.getUserName(),
        token: '',
        codRep: 0,
        serviceName: '',
        pedido: {},
        itens: {},
    };

    bodyAprovacao = {
        userName: this.auth.getUserName(),
        token: 'oisdjhog',
        codRep: 0,
        serviceName: 'aprovaPedido',
        tipLib: '',
        codOcorCan: 0,
        enviaEmail: false,
        observacao: '',
        params: {},
    };

    bodyBook = {
        userName: this.auth.getUserName(),
        token: 'soifhw903rnosld',
        codRep: this.auth.getCodRep(),
        serviceName: '',
        projetos: [] as number[],
        produtos: [],
        param: [
            {
                paramName: '',
                paramValue: '',
            },
        ],
    };

    private _nomeEmpresas: any[] = [];
    get nomeEmpresas(): any {
        return this._nomeEmpresas;
    }

    private _listaEmpresas: any[] = [];
    get listaEmpresas(): any[] {
        return this._listaEmpresas;
    }

    configListaEmp(retorno: any) {
        let tmpLista = [];
        this._nomeEmpresas = retorno.empresas;
        for (var i = 0; i < this.nomeEmpresas.length; i++) {
            if (retorno.response[0].LISTEMP.split(',').includes(this.nomeEmpresas[i].CODEMP)) {
                tmpLista.push(this.nomeEmpresas[i].CODEMP);
            }
        }
        this._listaEmpresas = tmpLista.sort();
    }

    getConfigUrl() {
        return this.http.get(this.config);
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError('Something bad happened; please try again later.');
    }

    getProdutoAutocomplete(
        query: string,
        buscaEstoque?: boolean,
        tipoEstoque: number = TipoPedido.PRONTAENTREGA,
        codProj?: number | number[],
        codEmp?: number
    ) {
        let urlAutocomplete = '';
        this.body.serviceName = 'Autocomplete';
        this.body.userName = this.auth.getUserName();
        this.body.codRep = Number(this.auth.getCodRep());
        this.body.param = [
            {
                paramName: 'query',
                paramValue: query,
            },
        ];

        this.body.param.push({
            paramName: 'consultaEstoque',
            paramValue: buscaEstoque ? 'SIM' : 'NAO',
        });

        urlAutocomplete = tipoEstoque === 1 ? this.produtosUrl : this.produtosProgramadoUrl;

        if (tipoEstoque === 1 && codEmp) {
            this.body.param.push({
                paramName: 'codEmp',
                paramValue: codEmp.toString(),
            });
        }

        if (tipoEstoque === 2) {
            this.body.param.push({
                paramName: 'projeto',
                paramValue: codProj as any,
            });
        }

        return this.http.post(this.urlSankhya + urlAutocomplete, this.body, this.httpOptions).pipe(map(ret => ret as ProdutoUnificado[]));
    }

    getProdutoGeralAutocomplete(query: string) {
        this.body.serviceName = 'Autocomplete';
        this.body.userName = this.auth.getUserName();
        this.body.codRep = Number(this.auth.getCodRep());
        this.body.param = [
            {
                paramName: 'query',
                paramValue: query,
            },
        ];

        return this.getConfigUrl().pipe(
            concatMap((res: Config) =>
                this.http
                    .post(this.urlSankhya + res.sankhya.produtos.produtoGeral.url, this.body, this.httpOptions)
                    .pipe(map(ret => ret as ProdutoUnificado[]))
            )
        );
    }

    getParceiroAutocomplete(query: string, tipoAutocomplete: string) {
        this.body = {
            userName: this.auth.getUserName(),
            token: '',
            codRep: 0,
            serviceName: '',
            param: [
                {
                    paramName: '',
                    paramValue: '',
                },
            ],
        };

        this.body.serviceName = 'Autocomplete';
        this.body.param[0].paramName = 'query';
        this.body.param[0].paramValue = query;
        this.body.codRep = Number(this.auth.getCodRep());

        let url = '';

        if (tipoAutocomplete === 'transportadora') {
            url = this.transportadoraUrl;
        } else {
            url = this.parceirosUrl;
        }

        return this.http.post(this.urlSankhya + url, this.body, this.httpOptions).pipe(map(ret => ret as Parceiro[]));
    }

    getParceiroByCodParc(codParc: number) {
        const body = {
            userName: this.auth.getUserName(),
            codRep: this.auth.getCodRep(),
            token: '',
            serviceName: 'getClientesById',
            param: [
                {
                    paramName: 'query',
                    paramValue: codParc.toString(),
                },
            ],
        };
        return this.http.post(this.urlSankhya + sankhya.parceiros.clientes.url, body, this.httpOptions).pipe(map(ret => ret as Parceiro[]));
    }

    sendPedido(cabecalho: CabecalhoPedido, itens: ItensPedido) {
        this.bodyNota.serviceName = 'sendPedido';
        this.bodyNota.pedido = cabecalho;
        this.bodyNota.itens = itens;
        this.bodyNota.codRep = Number(this.auth.getCodRep());
        this.bodyNota.userName = this.auth.getUserName();

        return this.http.post(this.urlSankhya + this.enviarPedidoUrl, this.bodyNota, this.httpOptions);
    }

    sendPreview(cabecalho: CabecalhoPedido, itens: ItensPedido) {
        this.bodyNota.serviceName = 'sendPedido';
        this.bodyNota.pedido = cabecalho;
        this.bodyNota.itens = itens;
        this.bodyNota.codRep = Number(this.auth.getCodRep());
        this.bodyNota.userName = this.auth.getUserName();

        return this.http.post(this.urlSankhya + this.enviarPedidoPreviewUrl, this.bodyNota, this.httpOptions);
    }

    getTipoNegociacaoAutocomplete(query: string) {
        this.body.serviceName = query === 'cartao' ? 'getOpcoesCartao' : 'Autocomplete';
        this.body.param[0].paramName = query === 'cartao' ? 'tipo' : 'query';
        this.body.param[0].paramValue = query === 'cartao' ? 'cartao' : query;
        this.body.codRep = Number(this.auth.getCodRep());
        this.body.userName = this.auth.getUserName();

        return this.http
            .post(this.urlSankhya + this.tipoNegociacaoUrl, this.body, this.httpOptions)
            .pipe(map(ret => ret as TipoNegociacao[]));
    }

    // getPedidosPesquisa(numeroInicial: number, numeroFinal: number, busca = '') {
    //     this.body.serviceName = 'pedidosPesquisa';
    //     this.body.codRep = Number(localStorage.getItem('codRep'));
    //     this.body.userName = localStorage.getItem('nomeAbrev');

    //     this.body.param = [];

    //     this.body.param.push(
    //         {
    //             paramName: 'numeroInicial',
    //             paramValue: numeroInicial.toString(),
    //         },
    //         {
    //             paramName: 'numeroFinal',
    //             paramValue: numeroFinal.toString(),
    //         },
    //         {
    //             paramName: 'busca',
    //             paramValue: busca.toUpperCase(),
    //         }
    //     );

    //     const configLoaded = this.http.get(this.config);
    //     const consultaPedido = configLoaded.pipe(
    //         concatMap((res: Config) =>
    //             this.http.post(this.urlSankhya + res.sankhya.pedidos.consultarPedido.url, this.body, this.httpOptions)
    //         )
    //     );

    //     return consultaPedido.pipe(map(ret => ret as PedidosPesquisa[]));
    // }

    getPedidosPesquisaCliente(dadosBusca: any) {
        const body = {
            userName: this.auth.getUserName(),
            token: 'soifhw903rnosld',
            codRep: Number(this.auth.getCodRep()),
            serviceName: 'pedidosPesquisaCliente',
            param: [
                { paramName: 'codParc', paramValue: dadosBusca.parceiroCliente.CODPARC },
                { paramName: 'coligados', paramValue: dadosBusca.coligados.toString() },
                { paramName: 'historico', paramValue: dadosBusca.historico },
                { paramName: 'listaStatus', paramValue: dadosBusca.listaStatus },
                { paramName: 'numeroInicial', paramValue: dadosBusca.numeroInicial },
                { paramName: 'numeroFinal', paramValue: dadosBusca.numeroFinal },
                { paramName: 'prod', paramValue: dadosBusca.prod },
            ],
        };

        const configLoaded = this.http.get(this.config);
        const consultaPedido = configLoaded.pipe(
            concatMap((res: Config) => this.http.post(this.urlSankhya + res.sankhya.pedidos.consultarPedido.url, body, this.httpOptions))
        );

        return consultaPedido.pipe(map(ret => ret as PedidosConsultaCliente[]));
    }

    getPedidosOperacional(numeroInicial: number, numeroFinal: number, status: number) {
        this.body.serviceName = 'pedidosOperacional';
        this.body.codRep = Number(this.auth.getCodRep());
        this.body.userName = this.auth.getUserName();

        this.body.param = [];

        this.body.param.push(
            {
                paramName: 'numeroInicial',
                paramValue: numeroInicial.toString(),
            },
            {
                paramName: 'numeroFinal',
                paramValue: numeroFinal.toString(),
            },
            {
                paramName: 'status',
                paramValue: status.toString(),
            }
        );

        const configLoaded = this.http.get(this.config);
        const consultaPedido = configLoaded.pipe(
            concatMap((res: Config) =>
                this.http.post(this.urlSankhya + res.sankhya.pedidos.consultarPedido.url, this.body, this.httpOptions)
            )
        );

        return consultaPedido.pipe(map(ret => ret as PedidosPesquisa[]));
    }

    getProgramacaoOperacional() {
        this.body.serviceName = '';
        this.body.codRep = Number(this.auth.getCodRep());
        this.body.userName = this.auth.getUserName();
        this.body.param = [];

        const configLoaded = this.http.get(this.config);
        const consultaPedido = configLoaded.pipe(
            concatMap((res: Config) => {
                return this.http.post(this.urlSankhya + res.sankhya.pedidos.programacaoOperacional.url, this.body, this.httpOptions);
            })
        );

        return consultaPedido.pipe(map(ret => ret as PedidosPesquisa[]));
    }

    // getPedidosUsuario() {
    //     this.body.serviceName = '';
    //     this.body.codRep = Number(localStorage.getItem('codRep'));
    //     this.body.userName = localStorage.getItem('nomeAbrev');
    //     this.body.param = [];

    //     const configLoaded = this.http.get(this.config);
    //     const consultaPedido = configLoaded.pipe(
    //         concatMap((res: Config) => {
    //             return this.http.post(this.urlSankhya + res.sankhya.pedidos.pedidosUsuario.url, this.body, this.httpOptions);
    //         })
    //     );

    //     return consultaPedido.pipe(map(ret => ret as PedidosUsuario[]));
    // }

    // getPedidosSituacao() {
    //     this.body.serviceName = '';
    //     this.body.codRep = Number(localStorage.getItem('codRep'));
    //     this.body.userName = localStorage.getItem('nomeAbrev');
    //     this.body.param = [];

    //     const configLoaded = this.http.get(this.config);
    //     const consultaPedido = configLoaded.pipe(
    //         concatMap((res: Config) => {
    //             return this.http.post(this.urlSankhya + res.sankhya.pedidos.pedidosSituacao.url, this.body, this.httpOptions);
    //         })
    //     );

    //     return consultaPedido.pipe(map(ret => ret as PedidosSituacao[]));
    // }

    // getPedidosMovimentacao() {
    //     this.body.serviceName = '';
    //     this.body.codRep = Number(localStorage.getItem('codRep'));
    //     this.body.userName = localStorage.getItem('nomeAbrev');
    //     this.body.param = [];

    //     const configLoaded = this.http.get(this.config);
    //     const consultaPedido = configLoaded.pipe(
    //         concatMap((res: Config) => {
    //             return this.http.post(this.urlSankhya + res.sankhya.pedidos.pedidosMovimentacao.url, this.body, this.httpOptions);
    //         })
    //     );

    //     return consultaPedido.pipe(map(ret => ret as PedidosSituacao[]));
    // }

    // getPedidosLiberacao() {
    //     this.body.serviceName = 'getPedidosLiberacao';
    //     this.body.userName = localStorage.getItem('nomeAbrev');
    //     this.body.codRep = Number(localStorage.getItem('codRep'));
    //     this.body.token = ' aohoighwrg';

    //     const configLoaded = this.http.get(this.config);
    //     const consultaPedidosLiberacao = configLoaded.pipe(
    //         concatMap((res: Config) =>
    //             this.http.post(this.urlSankhya + res.sankhya.pedidos.pedidosLiberacao.url, this.body, this.httpOptions)
    //         )
    //     );

    //     return consultaPedidosLiberacao.pipe(map(ret => ret as PedidosPesquisa[]));
    // }

    aprovaReprovaProposta(
        tipLib: string,
        pedido: PedidosPesquisa,
        origemAprovacao: string,
        codOcorCan?: number,
        enviaEmail?: boolean,
        observacao?: string
    ) {
        const liberar = tipLib !== 'R' ? 'S' : 'N';
        const msgAprovacao =
            liberar === 'S'
                ? tipLib + ';;Liberado pelo Portal Eurotextil;0'
                : tipLib + ';' + pedido.OBSERVACAO + ';' + pedido.OBSERVACAOPED + ';' + codOcorCan + ';' + enviaEmail;

        const itens = [];

        // pedido.LIBERACAO.forEach(lib => {
        //     itens.push({
        //         nuChave: pedido.NUNOTA,
        //         evento: lib.EVENTO,
        //         nucli: 0,
        //         seqCascata: 0,
        //         sequencia: lib.SEQUENCIA,
        //         tabela: lib.TABELA,
        //     });
        // });

        this.bodyAprovacao.userName = this.auth.getUserName();
        this.bodyAprovacao.codRep = Number(this.auth.getCodRep());
        this.bodyAprovacao.token = 'oisdjhog';
        this.bodyAprovacao.serviceName = origemAprovacao;
        this.bodyAprovacao.tipLib = tipLib;
        this.bodyAprovacao.codOcorCan = codOcorCan;
        this.bodyAprovacao.enviaEmail = !!enviaEmail;
        this.bodyAprovacao.observacao = observacao;
        this.bodyAprovacao.params = {
            usuario: 3,
            liberar,
            obsLib: msgAprovacao,
            vlrLib: 0,
            compensarNotaAutomaticamente: 'false',
            confirmarNotaAutomaticamente: 'false',
            nuNota: pedido.NUNOTA,
            item: itens,
        };

        // Carrega o arquivo config
        const configLoaded = this.http.get(this.config);
        let confAprovacao: Config;
        let retorno: any;

        /*
         * Faz o concatMap das requisicoes necessarias
         * 1) Recusa o Pedido
         * 2) Atribui o codigo da Ocorrencia
         * 3) Atualiza a Observacao da Proposta com o Codigoda Ocorrencia
         *
         * Obs.: Foi necessario fazer dessa forma para nao haver conflito com os Updates no Sankhya, sendo
         *       assim as requisicoes sao executadas em fila somente quando a ultima for concluida
         */
        const aprovacaoPedido = configLoaded.pipe(
            map((conf: Config) => (confAprovacao = conf)),
            concatMap(() =>
                this.http
                    .post(
                        this.urlSankhya + confAprovacao.sankhya.pedidos.aprovacao.aprovaReprovaProposta.url,
                        this.bodyAprovacao,
                        this.httpOptions
                    )
                    .pipe(map(res => (retorno = res)))
            )
        );

        return aprovacaoPedido.pipe(map(ret => ret));
    }

    aprovaPedidoLiberacao(
        tipLib: string,
        pedido: PedidosPesquisa,
        origemAprovacao: string,
        codOcorCan?: number,
        enviaEmail?: boolean,
        observacao?: string
    ) {
        const liberar = tipLib !== 'R' ? 'S' : 'N';
        const msgAprovacao =
            liberar === 'S'
                ? tipLib + ';;Liberado pelo Portal Eurotextil;0'
                : tipLib + ';' + pedido.OBSERVACAO + ';' + pedido.OBSERVACAOPED + ';' + codOcorCan + ';' + enviaEmail;

        const itens = [];

        pedido.LIBERACAO.forEach(lib => {
            itens.push({
                nuChave: pedido.NUNOTA,
                evento: lib.EVENTO,
                nucli: 0,
                seqCascata: 0,
                sequencia: lib.SEQUENCIA,
                tabela: lib.TABELA,
            });
        });

        this.bodyAprovacao.userName = this.auth.getUserName();
        this.bodyAprovacao.codRep = Number(this.auth.getCodRep());
        this.bodyAprovacao.token = 'oisdjhog';
        this.bodyAprovacao.serviceName = origemAprovacao;
        this.bodyAprovacao.tipLib = tipLib;
        this.bodyAprovacao.codOcorCan = codOcorCan;
        this.bodyAprovacao.enviaEmail = !!enviaEmail;
        this.bodyAprovacao.observacao = observacao;
        this.bodyAprovacao.params = {
            usuario: 3,
            liberar,
            obsLib: msgAprovacao,
            vlrLib: 0,
            compensarNotaAutomaticamente: 'false',
            confirmarNotaAutomaticamente: 'false',
            nuNota: pedido.NUNOTA,
            item: itens,
        };

        /**
         * Carrega o arquivo config
         */
        const configLoaded = this.http.get(this.config);
        let confAprovacao: Config;
        let retorno: any;

        /**
         * Faz o concatMap das requisicoes necessarias
         * 1) Recusa o Pedido
         * 2) Atribui o codigo da Ocorrencia
         * 3) Atualiza a Observacao da Proposta com o Codigoda Ocorrencia
         *
         * Obs.: Foi necessario fazer dessa forma para nao haver conflito com os Updates no Sankhya, sendo
         *       assim as requisicoes sao executadas em fila somente quando a ultima for concluida
         */
        const aprovacaoPedido = configLoaded.pipe(
            map((conf: Config) => (confAprovacao = conf)),
            concatMap(() =>
                this.http
                    .post(this.urlSankhya + confAprovacao.sankhya.pedidos.aprovacao.aprovacao.url, this.bodyAprovacao, this.httpOptions)
                    .pipe(map(res => (retorno = res)))
            )
        );

        return aprovacaoPedido.pipe(map(ret => ret));
    }

    // getPojetosAtivos() {
    //     this.body.userName = localStorage.getItem('nomeAbrev');
    //     this.body.codRep = Number(localStorage.getItem('codRep'));

    //     return this.http
    //         .get(this.config)
    //         .pipe(concatMap((res: Config) => this.http.post(this.urlSankhya + res.sankhya.projetosAtivos.url, this.body, this.httpOptions)))
    //         .pipe(
    //             map(ret => {
    //                 const tmp = (ret as NumeroPO[]).map(po => {
    //                     // Foi necessário remapear o valor pois po.CODPROJ estava vindo como string e na interface é number
    //                     return {
    //                         CODPROJ: +po.CODPROJ,
    //                         ABREVIATURA: po.ABREVIATURA,
    //                     };
    //                 });
    //                 return tmp as NumeroPO[];
    //             })
    //         );
    // }

    getPojetosAtivosPorEmpresa(codEmp: Number, tipoPedido?: string) {
        this.body.userName = this.auth.getUserName();
        this.body.codRep = Number(this.auth.getCodRep());
        this.body.param = [{ paramName: 'codEmp', paramValue: String(codEmp) }];

        return this.http
            .get(this.config)
            .pipe(
                concatMap((res: Config) =>
                    this.http.post(this.urlSankhya + res.sankhya.projetosAtivosPorEmpresa.url, this.body, this.httpOptions)
                )
            )
            .pipe(
                map(ret => {
                    const tmp = (ret as NumeroPO[]).map(po => {
                        // Foi necessário remapear o valor pois po.CODPROJ estava vindo como string e na interface é number
                        return {
                            CODPROJ: +po.CODPROJ,
                            ABREVIATURA: po.ABREVIATURA,
                        };
                    });
                    return tmp as NumeroPO[];
                })
            );
    }

    getProgramacaoSemanal(codEmp: Number) {
        this.body.userName = this.auth.getUserName();
        this.body.codRep = Number(this.auth.getCodRep());
        this.body.param = [{ paramName: 'codEmp', paramValue: String(codEmp) }];

        return this.http
            .get(this.config)
            .pipe(
                concatMap((res: Config) =>
                    this.http.post(this.urlSankhya + res.sankhya.programacaoSemanal.url, this.body, this.httpOptions)
                )
            )
            .pipe(
                map(ret => {
                    const tmp = (ret as NumeroPO[]).map(po => {
                        // Foi necessário remapear o valor pois po.CODPROJ estava vindo como string e na interface é number
                        return {
                            CODPROJ: +po.CODPROJ,
                            ABREVIATURA: po.ABREVIATURA,
                            ADCODUNIPRO: +po.ADCODUNIPRO,
                            DESCRPROD: po.DESCRPROD,
                        };
                    });
                    return tmp as NumeroPO[];
                })
            );
    }

    getProjetosAutocomplete(query: string, codEmp: number) {
        this.body.serviceName = 'Autocomplete';
        this.body.userName = this.auth.getUserName();
        this.body.codRep = Number(this.auth.getCodRep());

        this.body.param = [
            {
                paramName: 'query',
                paramValue: query,
            },
            {
                paramName: 'codEmp',
                paramValue: String(codEmp),
            },
        ];
        const loadConfig = this.http.get(this.config);
        const projetos = loadConfig.pipe(
            concatMap((res: Config) => this.http.post(this.urlSankhya + res.sankhya.projetosAutocomplete.url, this.body, this.httpOptions))
        );

        return projetos.pipe(map(ret => ret as Projeto[]));
    }

    getSegurancaRelatorios() {
        this.body.serviceName = 'getSegurancaRelatorios';
        this.body.userName = this.auth.getUserName();
        this.body.codRep = Number(this.auth.getCodRep());

        const loadConfig = this.http.get(this.config);
        const segRel = loadConfig.pipe(
            concatMap((res: Config) => this.http.post(this.urlSankhya + res.sankhya.seguranca.relatorios.url, this.body, this.httpOptions))
        );

        return segRel.pipe(map(ret => ret as SegurancaRelatorios[]));
    }

    getOcorrenciasCancelamento() {
        this.body.serviceName = 'getOcorrenciasCancelamento';
        this.body.userName = this.auth.getUserName();
        this.body.codRep = Number(this.auth.getCodRep());

        this.body.param = [];

        const loadConfig = this.http.get(this.config);
        const ocorrencias = loadConfig.pipe(
            concatMap((res: Config) =>
                this.http.post(this.urlSankhya + res.sankhya.ocorrencias.ocorrencias.url, this.body, this.httpOptions)
            )
        );

        return ocorrencias.pipe(map(ret => ret as OcorrenciasCancelamento[]));
    }

    enviarEmailCadCli(bodyRequest: any, arquivoAnexo: any) {
        let formData = new FormData();
        formData.append('formCliente', JSON.stringify(bodyRequest.formCliente));

        arquivoAnexo.forEach(element => {
            formData.append('anexos', element);
        });

        const loadConfig = this.http.get(this.config);
        const emailCadCli = loadConfig.pipe(
            concatMap((res: Config) => this.http.post(this.urlSankhya + res.sankhya.cadastroCliente.enviarEmail.url, formData))
        );

        return emailCadCli.pipe(map(ret => ret as any));
    }

    // getOcorrenciaCancelamentoPedido(nuNota: number) {
    //     this.body.serviceName = 'getOcorrenciaCancelamentoPedido';
    //     this.body.codRep = Number(localStorage.getItem('codRep'));

    //     this.body.param = [
    //         {
    //             paramName: 'nuNota',
    //             paramValue: nuNota.toString(),
    //         },
    //     ];

    //     const loadConfig = this.http.get(this.config);
    //     const ocorrenciaPedido = loadConfig.pipe(
    //         concatMap((res: Config) => this.http.post(this.urlSankhya + res.sankhya.ocorrencias.pedido.url, this.body, this.httpOptions))
    //     );

    //     return ocorrenciaPedido.pipe(map(ret => ret as OcorrenciasCancelamento[]));
    // }

    loadConfigSemRecarregar(tipo: string) {
        switch (tipo) {
            case 'itemRelatorio': {
                this.produtosUrl = sankhya.produtos.prontaEntrega.url;
                this.produtosProgramadoUrl = sankhya.produtos.programado.url;
                break;
            }
        }
    }

    loadConfig(tipo: string) {
        switch (tipo) {
            case 'cliente': {
                this.getConfigUrl().subscribe((retorno: Config) => (this.parceirosUrl = retorno.sankhya.parceiros.clientes.url));
                break;
            }
            case 'clienteRemessa': {
                this.getConfigUrl().subscribe((retorno: Config) => (this.parceirosUrl = retorno.sankhya.parceiros.clientes.url));
                break;
            }
            case 'bloqueioCliente': {
                this.getConfigUrl().subscribe((retorno: Config) => (this.parceirosUrl = retorno.sankhya.parceiros.clientes.url));
                break;
            }
            case 'transportadora': {
                this.getConfigUrl().subscribe(
                    (retorno: Config) => (this.transportadoraUrl = retorno.sankhya.parceiros.transportadoras.url)
                );
                break;
            }
            case 'item': {
                this.getConfigUrl().subscribe((retorno: Config) => (this.produtosUrl = retorno.sankhya.produtos.prontaEntrega.url));
                this.getConfigUrl().subscribe((retorno: Config) => (this.produtosProgramadoUrl = retorno.sankhya.produtos.programado.url));
                break;
            }
            case 'itemRelatorio': {
                this.getConfigUrl().subscribe((retorno: Config) => (this.produtosUrl = retorno.sankhya.produtos.prontaEntrega.url));
                this.getConfigUrl().subscribe((retorno: Config) => (this.produtosProgramadoUrl = retorno.sankhya.produtos.programado.url));
                break;
            }
            case 'itemGeral': {
                this.getConfigUrl().subscribe((retorno: Config) => (this.produtosUrl = retorno.sankhya.produtos.produtoGeral.url));
                break;
            }
            case 'pedido': {
                this.getConfigUrl().subscribe((retorno: Config) => {
                    this.enviarPedidoUrl = retorno.sankhya.pedidos.enviarPedido.url;
                    this.consultarPedidoUrl = retorno.sankhya.pedidos.consultarPedido.url;
                    this.enviarPedidoPreviewUrl = retorno.sankhya.pedidos.enviarPedidoPreview.url;
                });
                break;
            }
            case 'tipoNegociacao': {
                this.getConfigUrl().subscribe((retorno: Config) => {
                    this.tipoNegociacaoUrl = retorno.sankhya.tipoNegociacao.url;
                });
                break;
            }
            default:
                break;
        }
    }

    getTitulosCliente(parceiro: Parceiro, dataIni: any, dataFim: any, coligados: boolean, origem?: string) {
        const bodyTitulo = {
            userName: this.auth.getUserName(),
            token: 'soifhw903rnosld',
            codRep: Number(this.auth.getCodRep()),
            serviceName: 'getTitulosCliente',
            param: [
                { paramName: 'codParc', paramValue: parceiro.CODPARC.toString() },
                { paramName: 'dtVencIni', paramValue: dataIni },
                { paramName: 'dtVencFim', paramValue: dataFim },
                { paramName: 'coligados', paramValue: coligados.toString() },
                { paramName: 'origem', paramValue: origem ? origem : '' },
            ],
        };

        const loadConfig = this.http.get(this.config);

        const segundaViaBoletos = loadConfig.pipe(
            concatMap((res: Config) => {
                return this.http.post(this.urlSankhya + res.sankhya.parceiros.clientes.titulos.url, bodyTitulo, this.httpOptions);
            })
        );

        return segundaViaBoletos.pipe(map(ret => ret as TitulosParceiros[]));
    }

    emailSegundaViaBoleto(emailCliente: string, titulos: TitulosParceiros[]) {
        const bodyTitulo = {
            serviceName: 'segundaViaBoleto',
            userName: this.auth.getUserName(),
            codRep: Number(this.auth.getCodRep()),
            token: 'jsohrpoi',
            emailCliente,
            titulos,
        };

        const loadConfig = this.http.get(this.config);

        const enviarEmail = loadConfig.pipe(
            concatMap((res: Config) => {
                return this.http.post(this.urlSankhya + res.sankhya.parceiros.clientes.segundaViaBoleto.url, bodyTitulo, this.httpOptions);
            })
        );

        return enviarEmail.pipe(map(ret => ret as any));
    }

    segundaViaBoletoSankhya(emailCliente: string, titulos: TitulosParceiros[], enviaEmail: boolean, razaoSocial: string) {
        const bodyTitulo = {
            serviceName: 'segundaViaBoleto',
            userName: this.auth.getUserName(),
            codRep: Number(this.auth.getCodRep()),
            token: 'jsohrpoi',
            emailCliente,
            titulos,
            enviaEmail,
            razaoSocial,
        };
        const loadConfig = this.http.get(this.config);
        const segundaVia = loadConfig.pipe(
            concatMap((res: Config) => {
                return this.http.post(
                    this.urlSankhya + res.sankhya.parceiros.clientes.segundaViaBoletoSankhyaW.url,
                    bodyTitulo,
                    this.httpOptions
                );
            })
        );

        return segundaVia.pipe(map(ret => ret as any));
    }

    // Este método busca 30 produtos da tabela de preço de acordo com a paginação
    buscaTabelaPreco(numeroInicial: number, numeroFinal: number) {
        const bodyBuscaTabela = {
            serviceName: 'buscaTabelaPreco',
            userName: this.auth.getUserName(),
            codRep: this.auth.getCodRep(),
            token: 'jsohrpoi',
            param: [
                { paramName: 'numeroInicial', paramValue: numeroInicial },
                { paramName: 'numeroFinal', paramValue: numeroFinal },
            ],
        };

        const loadConfig = this.http.get(this.config);
        const buscar = loadConfig.pipe(
            concatMap((res: Config) => {
                return this.http.post(
                    this.urlSankhya + res.sankhya.produtos.tabelaPreco.buscarProdutos.url,
                    bodyBuscaTabela,
                    this.httpOptions
                );
            })
        );

        return buscar.pipe(map(ret => ret as any));
    }

    // Este método busca apenas um produto de tabela de preço, selecionado através do autocomplete
    buscarProdutoTabelaPreco(produto: ProdutoUnificado) {
        const bodyBuscaProdutoTabela = {
            serviceName: 'buscaProdutoTabelaPreco',
            userName: this.auth.getUserName(),
            codRep: this.auth.getCodRep(),
            token: 'jsohrpoi',
            param: [{ paramName: 'ADCODUNIPRO', paramValue: produto.ADCODUNIPRO }],
        };

        const loadConfig = this.http.get(this.config);
        const buscar = loadConfig.pipe(
            concatMap((res: Config) => {
                return this.http.post(
                    this.urlSankhya + res.sankhya.produtos.tabelaPreco.buscarProduto.url,
                    bodyBuscaProdutoTabela,
                    this.httpOptions
                );
            })
        );

        return buscar.pipe(map(ret => ret as any));
    }

    atualizarProdutoTabelaPreco({ adCodUniPro, vlrVendaAlt, codProds, nuTab, dtVigor }) {
        const bodyAtualizaProdutoTabela = {
            serviceName: 'atualizaProdutoTabelaPreco',
            userName: this.auth.getUserName(),
            codRep: this.auth.getCodRep(),
            token: 'jsohrpoi',
            param: [
                { paramName: 'ADCODUNIPRO', paramValue: adCodUniPro },
                { paramName: 'VLRVENDAALT', paramValue: vlrVendaAlt },
                { paramName: 'CODPRODS', paramValue: codProds },
                { paramName: 'NUTAB', paramValue: nuTab },
                { paramName: 'DTVIGOR', paramValue: dtVigor },
            ],
        };

        const loadConfig = this.http.get(this.config);
        const atualizar = loadConfig.pipe(
            concatMap((res: Config) => {
                return this.http.post(
                    this.urlSankhya + res.sankhya.produtos.tabelaPreco.atualizarProduto.url,
                    bodyAtualizaProdutoTabela,
                    this.httpOptions
                );
            })
        );

        return atualizar.pipe(map(ret => ret as any));
    }

    atualizarProdutosTabelaPreco(produtosAlterados: ProdutoTabelaPreco[]) {
        const produtos = produtosAlterados.map(produto => {
            return {
                ADCODUNIPRO: produto.ADCODUNIPRO,
                VLRVENDAALT: produto.VLRVENDAALT,
                CODPRODS: produto.CODPRODS,
                NUTAB: produto.NUTAB,
                DTVIGOR: produto.DTVIGOR,
            };
        });

        const bodyAtualizaProdutosTabela = {
            serviceName: 'atualizaProdutosTabelaPreco',
            userName: this.auth.getUserName(),
            codRep: this.auth.getCodRep(),
            token: 'jsohrpoi',
            param: [],
            produtos,
        };

        const loadConfig = this.http.get(this.config);
        const atualizar = loadConfig.pipe(
            concatMap((res: Config) => {
                return this.http.post(
                    this.urlSankhya + res.sankhya.produtos.tabelaPreco.atualizarProdutos.url,
                    bodyAtualizaProdutosTabela,
                    this.httpOptions
                );
            })
        );

        return atualizar.pipe(map(ret => ret as any));
    }

    // fecharPedido(nuNota: number, codParc: number) {
    //     // Preencher o body da classe com os valores que faltam
    //     this.body = {
    //         ...this.body,
    //         serviceName: 'fecharPedido',
    //         param: [
    //             { paramName: 'nuNota', paramValue: nuNota.toString() },
    //             { paramName: 'codParc', paramValue: codParc.toString() },
    //         ],
    //     };

    //     return this.http.post(this.urlSankhya + sankhya.pedidos.fecharPedido.url, this.body, this.httpOptions);
    // }

    setSituacaoPortal(parceiro: Parceiro, coligados: number) {
        this.body = {
            ...this.body,
            serviceName: 'setParceiroSituacaoPortal',
            param: [
                { paramName: 'codParc', paramValue: parceiro.CODPARC.toString() },
                { paramName: 'adSituacaoPortal', paramValue: parceiro.ADSITUACAOPORTAL },
                { paramName: 'coligados', paramValue: coligados.toString() },
            ],
        };

        const url = this.urlSankhya + sankhya.parceiros.clientes.situacaoPortal.url;

        return this.http.post(url, this.body, this.httpOptions);
    }

    // getMedidaPilotagem() {
    //     const url = this.urlSankhya + sankhya.pedidos.medidaPilotagem.url;

    //     return this.http.post(url, this.body, this.httpOptions);
    // }

    getParamPedidos() {
        this.body.userName = this.auth.getUserName();
        this.body.codRep = this.auth.getCodRep();

        this.body.param = [
            {
                paramName: 'NOMEABREV',
                paramValue: this.auth.getUserName(),
            },
        ];

        const url = this.urlSankhya + sankhya.pedidos.paramPedidos.url;

        return this.http.post(url, this.body, this.httpOptions);
    }

    getRamoAtividade() {
        const url = this.urlSankhya + sankhya.cadastroCliente.ramoAtividade.url;

        return this.http.get(url, this.httpOptions);
    }

    getImagensProduto(produtos: ProdutoUnificado[], tipo: string, projeto: string = '0') {
        this.bodyBook.serviceName = 'Autocomplete';
        this.bodyBook.userName = this.auth.getUserName();
        this.bodyBook.codRep = Number(this.auth.getCodRep());
        this.bodyBook.produtos = new Array();
        this.bodyBook.projetos = [Number(projeto)];

        this.bodyBook.param = [
            {
                paramName: 'tipo',
                paramValue: tipo,
            },
        ];

        produtos.forEach(produto => {
            const tmpProduto = {
                ADCODUNIPRO: produto.ADCODUNIPRO,
                DESCRPROD: produto.DESCRPROD,
                CONTROLE: [],
                CODVOL: produto.CODVOL,
            };

            produto.CONTROLE.forEach(itemCor => tmpProduto.CONTROLE.push({ CODCOR: itemCor.CODCOR, ESTOQUE: itemCor.ESTOQUE }));

            this.bodyBook.produtos.push(tmpProduto);
        });

        const url = this.urlSankhya + sankhya.produtos.buscarImagens.url;

        return this.http.post(url, this.bodyBook, this.httpOptions);
    }

    getListaColigadas(CODPARC: number, TIPO: string) {
        const bodyColigadas = {
            userName: this.auth.getUserName(),
            token: 'soifhw903rnosld',
            codRep: Number(this.auth.getCodRep()),
            serviceName: 'getListaColigadas',
            param: [
                { paramName: 'codParc', paramValue: CODPARC.toString() },
                { paramName: 'tipo', paramValue: TIPO.toString() },
            ],
        };

        const loadConfig = this.http.get(this.config);

        const empColigadas = loadConfig.pipe(
            concatMap((res: Config) => {
                return this.http.post(this.urlSankhya + res.sankhya.parceiros.clientes.coligadas.url, bodyColigadas, this.httpOptions);
            })
        );

        return empColigadas.pipe(map(ret => ret as Parceiro[]));
    }

    gerarFaixaPed(nuNotaIni: any, nuNotaFin: any, codProj: any, exibeTot: any, codEmp: any) {
        const bodyRequest = {
            userName: this.auth.getUserName(),
            token: 'soifhw903rnosld',
            codRep: '0',
            serviceName: '',
            param: [
                { paramName: 'NUNOTAINI', paramValue: nuNotaIni.toString() },
                { paramName: 'NUNOTAFIN', paramValue: nuNotaFin.toString() },
                { paramName: 'CODPROJ', paramValue: codProj.toString() },
                { paramName: 'EXIBETOT', paramValue: exibeTot.toString() },
                { paramName: 'CODEMP', paramValue: codEmp.toString() },
            ],
        };

        return this.http.post(this.urlSankhya + sankhya.pedidos.pedidosFaixa.url, bodyRequest, this.httpOptions);
    }

    getFaixaPedidoTamanho(fileName) {
        const body = {
            userName: this.auth.getUserName(),
            token: 'soifhw903rnosld',
            codRep: this.auth.getCodRep(),
            serviceName: '',
            param: [
                {
                    paramName: 'FILENAME',
                    paramValue: fileName,
                },
            ],
        };

        return this.http.post(this.urlSankhya + sankhya.pedidos.pedidosFaixa.url + '/tamanho', body, this.httpOptions);
    }

    getProdutoPedidoResumido(ADCODUNIPRO: number, DATAINI: string, DATAFIN: string) {
        this.body.serviceName = '';
        this.body.codRep = Number(this.auth.getCodRep());
        this.body.userName = this.auth.getUserName();

        this.body.param = [];

        this.body.param.push(
            {
                paramName: 'ADCODUNIPRO',
                paramValue: ADCODUNIPRO.toString(),
            },
            {
                paramName: 'DTINICIAL',
                paramValue: DATAINI,
            },
            {
                paramName: 'DTFINAL',
                paramValue: DATAFIN,
            }
        );

        return this.http.post(this.urlSankhya + sankhya.produtos.consultarProdutoResumo.url, this.body, this.httpOptions);
    }

    getProdutoPedidoDetalhado(ADCODUNIPRO: number, DATAINI: string, DATAFIN: string, CODPARC: number) {
        this.body.serviceName = '';
        this.body.codRep = Number(this.auth.getCodRep());
        this.body.userName = this.auth.getUserName();

        this.body.param = [];

        this.body.param.push(
            {
                paramName: 'ADCODUNIPRO',
                paramValue: ADCODUNIPRO.toString(),
            },
            {
                paramName: 'DTINICIAL',
                paramValue: DATAINI,
            },
            {
                paramName: 'DTFINAL',
                paramValue: DATAFIN,
            },
            {
                paramName: 'CODPARC',
                paramValue: CODPARC.toString(),
            }
        );

        return this.http.post(this.urlSankhya + sankhya.produtos.consultarProdutoDetalhe.url, this.body, this.httpOptions);
    }

    getDadosCopiaPedido(nuNotaProposta: number) {
        this.body.serviceName = '';
        this.body.codRep = Number(this.auth.getCodRep());
        this.body.userName = this.auth.getUserName();

        this.body.param = [
            {
                paramName: 'NUNOTAPROPOSTA',
                paramValue: nuNotaProposta.toString(),
            },
        ];

        return this.http.get(this.config).pipe(
            concatMap((res: Config) => {
                //console.log('Url chamada: ', this.urlSankhya + res.sankhya.pedidos.copiaPedido.url);
                return this.http.post(this.urlSankhya + res.sankhya.pedidos.copiaPedido.url, this.body, this.httpOptions);
            })
        );
    }

    getInfoDevolucao(CODREP: number, CODPARC: number) {
        this.body.param = [
            { paramName: 'CODREP', paramValue: CODREP.toString() },
            { paramName: 'CODPARC', paramValue: CODPARC.toString() },
        ];

        const url = this.urlSankhya + sankhya.cadastroDevolucao.infoDevolucao.url;

        return this.http.post(url, this.body, this.httpOptions);
    }

    getProdutosNotaFiscal(NUMNOTA: number, CODEMP: number, NUNOTAPEDIDO?: number) {
        this.body.param = [
            { paramName: 'NUMNOTA', paramValue: NUMNOTA.toString() },
            { paramName: 'CODEMP', paramValue: CODEMP.toString() },
            { paramName: 'NUNOTAPEDIDO', paramValue: NUNOTAPEDIDO.toString() },
        ];

        const url = this.urlSankhya + sankhya.cadastroDevolucao.produtosNotaFiscal.url;

        return this.http.post(url, this.body, this.httpOptions);
    }

    inserirCadDev(formCadDev: any, arquivoAnexo: any) {
        const itensForm = formCadDev.itens.map(item => {
            return {
                ...item,
                codProd: +item.codProd,
                medida: +item.medida,
                seqRolo: +item.seqRolo,
                vlrUnit: +item.vlrUnit,
                vlrTot: +item.vlrTot,
                vlrTotDev: +item.vlrTotDev,
            };
        });

        const formDev = {
            ...formCadDev,
            codParc: +formCadDev.codParc,
            codVend: +formCadDev.codVend,
            numNota: +formCadDev.numNota,
            vlrNota: +formCadDev.vlrNota,
            codMotivo: +formCadDev.codMotivo,
            codParcRem: +formCadDev.codParcRem,
            nuNota: +formCadDev.nuNota,
            codEmp: +formCadDev.codEmp,
            itens: itensForm,
            userName: this.auth.getUserName(),
        };

        let formDevolucao = new FormData();
        formDevolucao.append('formDevolucao', JSON.stringify(formDev));

        arquivoAnexo.forEach(element => {
            formDevolucao.append('anexos', element);
        });

        const url = this.urlSankhya + sankhya.cadastroDevolucao.inserirDevolucao.url;

        return this.http.post(url, formDevolucao).pipe(map(ret => ret as any));
    }

    enviarMensagemDev(formMsg: any, arquivoAnexo?: any) {
        let formMsgDev = new FormData();
        formMsgDev.append('formMsgDev', JSON.stringify(formMsg));

        arquivoAnexo.forEach(element => {
            formMsgDev.append('anexos', element);
        });

        const url = this.urlSankhya + sankhya.consultaDevolucao.enviarMensagemDev.url;

        return this.http.post(url, formMsgDev).pipe(map(ret => ret as any));
    }

    // getDevolucoes(numeroInicial: number, numeroFinal: number, busca: string, emAberto?: boolean) {
    //     let codRep = parseInt(localStorage.getItem('codRep'), 10);

    //     this.body.param = [
    //         { paramName: 'CODREP', paramValue: codRep.toString() },
    //         { paramName: 'BUSCA', paramValue: busca },
    //         { paramName: 'PAGINI', paramValue: numeroInicial.toString() },
    //         { paramName: 'PAGFIN', paramValue: numeroFinal.toString() },
    //         { paramName: 'EMABERTO', paramValue: emAberto.toString() },
    //     ];

    //     const url = this.urlSankhya + sankhya.consultaDevolucao.devolucoes.url;

    //     return this.http.post(url, this.body, this.httpOptions);
    // }

    getDevolucoesAprovacao() {
        const url = this.urlSankhya + sankhya.aprovacaoDevolucao.devolucoesAprovacao.url;

        return this.http.post(url, this.body, this.httpOptions);
    }

    // getProdutosDevolucao(devId: string) {
    //     this.body.param = [{ paramName: 'DEVID', paramValue: devId.toString() }];

    //     const url = this.urlSankhya + sankhya.consultaDevolucao.produtosDevolucao.url;

    //     return this.http.post(url, this.body, this.httpOptions);
    // }

    // getMensagensDevolucao(devId: string) {
    //     this.body.param = [{ paramName: 'DEVID', paramValue: devId.toString() }];

    //     const url = this.urlSankhya + sankhya.consultaDevolucao.mensagensDevolucao.url;

    //     return this.http.post(url, this.body, this.httpOptions);
    // }

    aprovaReprovaDevolucao(status: string, result: any, devId: number) {
        const bodyDev = {
            devId: devId,
            motivoRecusa: result.motivo,
            motivoCancel: result.motivoCancel,
            vlrDesc: result.vlrDesc,
            usuAprovReprov: this.auth.getUserName(),
            userName: this.auth.getUserName(),
            obsAprovReprov: result.obs,
            tipoDevAprov: result.tipoAprovDev,
            status: status,
        };

        const url = this.urlSankhya + sankhya.aprovacaoDevolucao.aprovaReprovaDevolucao.url;

        return this.http.post(url, bodyDev, this.httpOptions);
    }

    exibirAnexosDev(devId: number) {
        this.body.param = [{ paramName: 'DEVID', paramValue: devId.toString() }];

        const url = this.urlSankhya + sankhya.consultaDevolucao.consultaAnexos.url;

        return this.http.post(url, this.body, this.httpOptions);
    }

    getMotivosDevolucao() {
        const url = this.urlSankhya + sankhya.cadastroDevolucao.consultaMotivos.url;

        return this.http.post(url, this.body, this.httpOptions);
    }

    getPdfNotaFiscal(param: any, tipo: string) {
        if (tipo === 'NUMNOTA') {
            this.body.param = [
                { paramName: 'PARAM', paramValue: param.NUMNOTA.toString() },
                { paramName: 'TIPO', paramValue: tipo.toString() },
                { paramName: 'CODEMP', paramValue: param.CODEMP.toString() },
            ];
        }

        if (tipo === 'NUNOTAPROPOSTA') {
            this.body.param = [
                { paramName: 'PARAM', paramValue: param.pedido.NUNOTA.toString() },
                { paramName: 'TIPO', paramValue: tipo.toString() },
                { paramName: 'CODEMP', paramValue: param.pedido.CODEMP.toString() },
                { paramName: 'REMESSA', paramValue: param.remessa.toString() },
                { paramName: 'CODPARC', paramValue: param.pedido.CODPARC.toString() },
            ];
        }

        const url = this.urlSankhya + sankhya.consultaDevolucao.pdfNotaFiscal.url;

        return this.http.post(url, this.body, this.httpOptions);
    }

    getPermissaoUsu() {
        const url = this.urlSankhya + sankhya.seguranca.permissaoUsuario.url;

        return this.http.post(url, this.body, this.httpOptions);
    }

    getConsultaCnpj(cnpj: string) {
        this.body.param = [{ paramName: 'CNPJ', paramValue: cnpj.toString() }];

        const url = this.urlSankhya + sankhya.cadastroCliente.consultaCnpj.url;

        return this.http.post(url, this.body, this.httpOptions);
    }

    verificaCnpjExistente(cnpj: string) {
        this.body.param = [{ paramName: 'CNPJ', paramValue: cnpj.toString() }];

        const url = this.urlSankhya + sankhya.cadastroCliente.verificaCnpj.url;

        return this.http.post(url, this.body, this.httpOptions);
    }

    getDetalhesProduto(codprod: any) {
        this.body.param = [{ paramName: 'CODPROD', paramValue: String(codprod) }];

        const url = this.urlSankhya + sankhya.ProdutoByCodprod.getProdutos.url;

        return this.http.post(url, this.body, this.httpOptions);
    }

    getCadastroClientes(numeroInicial: number, numeroFinal: number, busca: string) {
        let codRep = this.auth.getCodRep();

        this.body.param = [
            { paramName: 'CODREP', paramValue: codRep.toString() },
            { paramName: 'BUSCA', paramValue: busca },
            { paramName: 'PAGINI', paramValue: numeroInicial.toString() },
            { paramName: 'PAGFIN', paramValue: numeroFinal.toString() },
        ];

        const url = this.urlSankhya + sankhya.consultaCadastroCliente.consultaCadastro.url;

        return this.http.post(url, this.body, this.httpOptions);
    }

    getModoDelavagem(codUniPro: any) {
        this.body.param = [{ paramName: 'CODUNIPRO', paramValue: String(codUniPro) }];

        const url = this.urlSankhya + sankhya.ModoDelavagem.getModoDelavagem.url;

        return this.http.post(url, this.body, this.httpOptions);
    }

    reprovaFinanceiro(pedido: any) {
        this.body.param = [
            { paramName: 'NUNOTAPED', paramValue: pedido.NUNOTAPED },
            { paramName: 'OBSLIB', paramValue: pedido.OBSLIB },
        ];

        const url = this.urlSankhya + sankhya.pedidos.aprovacao.reprovacaoFinanceiro.url;

        return this.http.post(url, this.body, this.httpOptions);
    }

    verificaPedidoLiberado(nuNotaPed: number) {
        this.body.param = [{ paramName: 'NUNOTAPED', paramValue: nuNotaPed.toString() }];

        const url = this.urlSankhya + sankhya.pedidos.aprovacao.verificaAprovacao.url;

        return this.http.post(url, this.body, this.httpOptions);
    }

    atualizaGestorAprovFin(codGestor: number, nuNotaPed: number) {
        this.body.param = [
            { paramName: 'CODGESTOR', paramValue: codGestor.toString() },
            { paramName: 'NUNOTAPED', paramValue: nuNotaPed.toString() },
        ];

        const url = this.urlSankhya + sankhya.pedidos.aprovacao.atualizaGestorAprovacao.url;

        return this.http.post(url, this.body, this.httpOptions);
    }

    getProgramaçãoSemanal() {
        const url = portal.muralAvisos.url;

        const configLoaded = this.http.get(this.config);
        const consulta = configLoaded.pipe(concatMap((res: Config) => this.http.get(url, {})));

        return consulta;
    }

    getEstatisticasGraficoUsuario(value) {
        this.body.serviceName = '';
        this.body.codRep = value;

        this.body.param = [];

        const configLoaded = this.http.get(this.config);
        const consulta = configLoaded.pipe(
            concatMap((res: Config) =>
                this.http.post(this.urlSankhya + res.sankhya.graficos.estatisticaRepresentante.url, this.body, this.httpOptions)
            )
        );

        return consulta;
    }

    getEstatisticasGrafico() {
        this.body.serviceName = '';
        this.body.codRep = Number(this.auth.getCodRep());
        this.body.userName = this.auth.getUserName();
        this.body.param = [];

        const configLoaded = this.http.get(this.config);
        const consulta = configLoaded.pipe(
            concatMap((res: Config) =>
                this.http.post(this.urlSankhya + res.sankhya.graficos.estatisticaRepresentante.url, this.body, this.httpOptions)
            )
        );

        return consulta;
    }
} // class SankhyaServiceService
