import { Component, Input, OnInit, ViewContainerRef } from '@angular/core';
import { PortalServiceService } from '../../services/portal-service.service';
import { Parceiro } from 'src/app/global-interfaces/parceiro-interface';
import { DatePipe } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { SpinnerComponent } from 'src/app/global-components/spinner/spinner.component';
import { SankhyaServiceService } from 'src/app/services/sankhya-service.service';
import { AutenticacaoService } from 'src/app/services/autenticacao.service';

@Component({
    selector: 'app-relatorio-titulos',
    templateUrl: './relatorio-titulos.component.html',
    styleUrls: ['./relatorio-titulos.component.scss', '../relatorios.component.scss'],
})
export class RelatorioTitulosComponent implements OnInit {
    constructor(
        private portalService: PortalServiceService,
        public datePipe: DatePipe,
        private snackBar: MatSnackBar,
        public overlay: Overlay,
        public viewContainerRef: ViewContainerRef,
        private sankhyaService: SankhyaServiceService,
        private auth: AutenticacaoService
    ) {
        this.isTodos = true;
        this.clientesRelatorio = [];
        this.isMatriz = false;
        this.isQuebraRepre = false;
        this.isVencidos = true;
        this.isAvencer = true;
        this.isResumido = false;
        this.enableFaixaRep = this.auth.getCodRep() === 0 ? true : false;
        this.codRepIni = this.auth.getCodRep() === 0 ? 0 : Number(this.auth.getCodRep());
        this.codRepFim = this.auth.getCodRep() === 0 ? 9999999 : Number(this.auth.getCodRep());
    }

    ngOnInit() {}

    @Input() restUrl: string;

    isTodos: boolean;
    dataIni: any;
    dataFim: string;
    isMatriz: boolean;
    isQuebraRepre: boolean;
    codRepIni: number;
    codRepFim: number;
    enableFaixaRep: boolean;
    isVencidos: boolean;
    isAvencer: boolean;
    isResumido: boolean;

    clienteSelecionado: Parceiro = this.getParceiroVazio();
    clientesRelatorio: Parceiro[];

    gerarPdf() {
        if (!this.isTodos && this.clientesRelatorio.length === 0) {
            this.snackBar.open('Informe os Clientes no campo ou ative a opção "Todos"!', 'Fechar', {
                verticalPosition: 'top',
                horizontalPosition: 'end',
                duration: 5000,
            });
        } else {
            let listaClientes = '';

            this.clientesRelatorio.map(cliente => {
                listaClientes = listaClientes === '' ? `${cliente.CODPARC}` : `${listaClientes},${cliente.CODPARC}`;
            });

            const dataParam = {
                DTVENCINI:
                    this.datePipe.transform(this.dataIni, 'dd/MM/yyyy') === null ? '' : this.datePipe.transform(this.dataIni, 'dd/MM/yyyy'),
                DTVENCFIM:
                    this.datePipe.transform(this.dataFim, 'dd/MM/yyyy') === null ? '' : this.datePipe.transform(this.dataFim, 'dd/MM/yyyy'),
                LISTACLIENTES: listaClientes,
                MATRIZ: this.isMatriz ? 'S' : 'N',
                QUEBRAREPRE: this.isQuebraRepre,
                CODVENDINI: this.codRepIni,
                CODVENDFIM: this.codRepFim,
                VENCIDO: this.isVencidos,
                AVENCER: this.isAvencer,
                RES: this.isResumido,
                USULOGIN: this.auth.getUserName(),
            };

            // Mostrando o spinner na tela
            const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

            this.portalService.imprimirGeral({ params: dataParam, url: this.restUrl + '.pdf' });

            // Removendo o spinner da tela
            overlayRef.dispose();
        }
    }

    toggleTodos() {
        this.isTodos = !this.isTodos;

        if (this.isTodos) {
            this.toggleMatriz(false);
        }
    }

    inserirCliente() {
        this.clientesRelatorio.push(this.clienteSelecionado);

        this.clienteSelecionado = Object.assign({ RAZAOSOCIAL: null, CODPARC: null } as Parceiro);
    }

    deleteCliente(index: number) {
        this.clientesRelatorio.splice(index, 1);
    }

    toggleMatriz(check?: boolean) {
        this.isMatriz = check !== undefined ? false : !this.isMatriz;
    }

    private getParceiroVazio(): Parceiro {
        return {
            CODPARC: 0,
            NOMEPARC: '',
            RAZAOSOCIAL: '',
            CODVEND: 0,
            TIPO: '',
            NOMEEND: '',
            NUMEND: 0,
            COMPLEMENTO: '',
            NOMEBAI: '',
            NOMECID: '',
            CEP: '',
            UF: '',
            PAISEND: '',
            TELEFONE: '',
            EMAIL: '',
            IDENTINSCESTAD: '',
            CGCCPF: '',
            ATIVO: '',
            LIMCRED: 0,
            CLIENTE: '',
            FORNECEDOR: '',
            VENDEDOR: '',
            TRANSPORTADORA: '',
            BLOQUEAR: '',
            ADSITUACAOPORTAL: '',
        };
    }

    emitirClienteSelecionado(cliente: Parceiro) {
        this.clienteSelecionado = { ...cliente };
        this.inserirCliente();
    }

    clearClienteAutocomplete() {
        this.clienteSelecionado = this.getParceiroVazio();
    }
}
