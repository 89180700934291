import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { TransfereService } from 'src/app/services/transfere-dado.service';

@Component({
  selector: 'app-paginacao',
  templateUrl: './paginacao.component.html',
  styleUrls: ['./paginacao.component.scss']
})
export class PaginacaoComponent implements OnInit {

  // O controle da paginação é feito baseado sempre no atual primeiro índice da página (1, 4, 7, 10, ...)
  primeiroIndicePagina: number;

  // A variável abaixo é para destacar a página que está selecionada. Só recebe os valores 1, 2 e 3
  indiceAtual: number;

  // Numero de registros por Pagina
  numRegistros: number;

  // Definindo a quem a paginação está servindo (relação de pedidos, relação de clientes, ...)
  @Output() paginacaoChange = new EventEmitter<{numeroInicial: number, numeroFinal: number}>();

  constructor(public TransfereService:TransfereService) {
    this.primeiroIndicePagina = 1;
    this.indiceAtual = 1;
    this.numRegistros = 30;

  }

  ngOnInit() {
    this.TransfereService.setData(this.primeiroIndicePagina)
  }

  // Incrementar/decrementar paginação
  paginar(botao: string, indicePagina: number, indiceBotao: number) {
    
    const numeroInicial = (indicePagina * this.numRegistros) - (this.numRegistros - 1);
    const numeroFinal = indicePagina * this.numRegistros;

    switch (botao) {
      case 'back':
        this.primeiroIndicePagina -= 3;
        this.TransfereService.setData(this.primeiroIndicePagina)
        this.paginacaoChange.emit({numeroInicial: (this.primeiroIndicePagina * this.numRegistros) - (this.numRegistros - 1),
          numeroFinal: this.primeiroIndicePagina * this.numRegistros});
        this.indiceAtual = 1;
        break;

      case 'indice':
        this.TransfereService.setData(indicePagina)
        this.paginacaoChange.emit({numeroInicial, numeroFinal});
        this.indiceAtual = indiceBotao;
        break;

      case 'next':
        this.primeiroIndicePagina += 3;
        this.TransfereService.setData(this.primeiroIndicePagina)
        this.paginacaoChange.emit({numeroInicial: (this.primeiroIndicePagina * this.numRegistros) - (this.numRegistros - 1),
          numeroFinal: this.primeiroIndicePagina * this.numRegistros});
        this.indiceAtual = 1;
        break;

      default: break;
    }
  }

}
