import { Component, OnInit, ViewChild, ElementRef, ViewContainerRef } from '@angular/core';
import { ComponentPortal } from '@angular/cdk/portal';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { Router } from '@angular/router';
import { Md5 } from 'ts-md5';

import { AutenticacaoService } from '../services/autenticacao.service';
import { BottomSheetComponent } from '../global-components/bottom-sheet/bottom-sheet.component';
import { CabecalhoPedido, TipoOperacao, TipoMoeda, TipoPedido, ItensPedido, CifFob } from '../global-interfaces/pedido-interface';
import { CorDesenho } from './criar-pedido-itens-pedido/local-interfaces/criar-pedido-itens-pedido.model';
import { CriarPedidoItensPedidoComponent } from './criar-pedido-itens-pedido/criar-pedido-itens-pedido.component';
import { DialogComponent } from 'src/app/global-components/dialog/dialog.component';
import { DialogBasicComponent } from 'src/app/global-components/dialog-basic/dialog-basic.component';
import { IntegrarPedido } from './local-interfaces/integrar-pedido-interface';
import { NumeroPO } from './local-interfaces/numero-po-interface';
import { Parceiro } from '../global-interfaces/parceiro-interface';
import { ParcelasTipoNegociacao, TipoNegociacao, SubTipo } from '../global-interfaces/tipo-negociacao-interface';
import { PortalServiceService } from '../services/portal-service.service';
import { ProdutoUnificado } from '../global-interfaces/produto-interface';
import { SankhyaServiceService } from '../services/sankhya-service.service';
import { SpinnerComponent } from '../global-components/spinner/spinner.component';

import { NotificacaoService } from 'src/app/global-components/notificacao/services/notificacao.service';
import { BottomSheetComissaoComponent } from './local-components/bottom-sheet-comissao/bottom-sheet-comissao.component';
import { PortalJpaService } from '../services/portal-jpa.service';
import { BottomSheetJuntarPedidoComponent } from './local-components/bottom-sheet-juntar-pedido/bottom-sheet-juntar-pedido.component';
import { ValorEmAberto } from '../consulta-clientes/local-interfaces/valor-em-aberto';

@Component({
    selector: 'app-criar-pedido',
    templateUrl: './criar-pedido.component.html',
    styleUrls: ['./criar-pedido.component.scss'],
})
export class CriarPedidoComponent implements OnInit {
    // Métodos internos
    constructor(
        // private app: AppComponent,
        private auth: AutenticacaoService,
        private bottomSheet: MatBottomSheet,
        private dialog: MatDialog,
        private portalServices: PortalServiceService,
        private sankhyaService: SankhyaServiceService,
        private router: Router,
        private portalJpaService: PortalJpaService,
        public overlay: Overlay,
        public viewContainerRef: ViewContainerRef,
        private notificacaoService: NotificacaoService
    ) {
        this.auth.autenticar();

        // Se o APP entrou nesta rota com o  extras.state.copiarPedido setado, deve ser copiado os dados do pedido indicado.
        const nav = this.router.getCurrentNavigation();
        if (nav && nav.extras && nav.extras.state && nav.extras.state.copiarPedido) {
            this.copiarPedido = true;
            this.pedidoOriginal = JSON.parse(nav.extras.state.copiarPedido);
        }
    }

    // Dados do cabeçalho do pedido
    cabecalhoPedido: CabecalhoPedido;
    // Cod. empresa selecionada para faturar
    codEmp: number = 0;
    disableEmp: boolean = false;

    itensPedido: ItensPedido;

    // Condição de Pagamento
    parcelasTipoNegociacao: ParcelasTipoNegociacao[];

    parceiroCliente: Parceiro;
    parceiroClienteRemessa: Parceiro;
    tipoNegociacao: TipoNegociacao;
    moedaReal: number;
    moedaDolar: number;
    prontaEntrega: number;
    programado: number;
    cif: string;
    fob: string;
    debugMode: boolean;

    quantidadeItens: number;

    projetos: NumeroPO[];

    @ViewChild('numeroPO', { static: true }) numeroPO: ElementRef;
    @ViewChild(CriarPedidoItensPedidoComponent, { static: true }) childItensPedido: CriarPedidoItensPedidoComponent;

    isSendButtonActive: boolean;

    parceiroTransportadora: Parceiro;

    ttCorDesenho: CorDesenho[];
    produtosUnificados: ProdutoUnificado[];
    produtoSelecionado: ProdutoUnificado;

    tipoRemessa: string;

    isRetirarMercadoria: boolean;

    isPilotagem: boolean = false;
    medidaPilotagem: number;
    listaEmpresas: number[] = [];
    nomeEmpresas: any;

    // Variável amarrada ao componente switch que habilita e desabilita cartão
    isCartao: boolean;

    // Variável amarrada ao componente switch que habilita e desabilita Blu
    isBlu: boolean;

    // Variável amarrada ao componente switch que habilita e desabilita Bordero
    isBordero: boolean;

    // Este vetor vai armazenar todas as opções de pagamento por cartão (7 e 8)
    opcoesCartao: TipoNegociacao[];

    // A variável abaixo é uma cópia de opcoesCartao que será filtrada à medida que o usuário seleciona as opções de cartão
    opcoesCartaoFiltro: TipoNegociacao[];

    // Variáveis que armazenam o valor do ENUM (atualmente, 7 e 8)
    cartaoCredito: number;
    cartaoDebito: number;

    // Variável que armazena qual tipo de cartão foi selecionado (crédito ou débito)
    tipoCartaoSelecionado: number;

    // Esta variável armazenará a bandeira que o usuário selecionará
    bandeiraSelecionada: string;

    // Esta variavel indica se deve copiar o pedido de pedido enviado como parâmetro da rota de navegação
    copiarPedido: boolean = false;
    pedidoOriginal: any;

    comTot: number = 0;
    codTabPreco: number = 0;

    autocompleteParcelaEnabled = false;

    pgtoBordero = false;

    representantesBordero: number[] = [
        31, // BRUNO
        68, // KLEBER
        0 // Administrativos
    ]

    ngOnInit() {
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

        this.inicializarVariavel();
        this.sankhyaService.loadConfig('pedido');
        this.sankhyaService.getParamPedidos().subscribe(
            (retorno: any) => {
                if (retorno.serviceResponse === 'OK') {
                    this.codTabPreco = retorno.response[0].ADCODTABPRECO;
                    this.medidaPilotagem = Number.parseFloat(retorno.response[0].MEDIDAPILOTAGEM);
                    this.nomeEmpresas = retorno.empresas;

                    for (var i = 0; i < this.nomeEmpresas.length; i++) {
                        if (retorno.response[0].LISTEMP.split(',').includes(this.nomeEmpresas[i].CODEMP)) {
                            this.listaEmpresas.push(this.nomeEmpresas[i].CODEMP);
                        }
                    }

                    this.listaEmpresas.sort();
                    this.cabecalhoPedido.CODEMP = !this.listaEmpresas ? 1 : +this.listaEmpresas[0];
                    this.codEmp = this.cabecalhoPedido.CODEMP;
                } else {
                    this.bottomSheet
                        .open(BottomSheetComponent, {
                            data: {
                                param: 'integrarPedido',
                                mensagem: retorno,
                            },
                            panelClass: 'bottom-sheet',
                        })
                        .afterDismissed()
                        .subscribe(p => {});
                }

                overlayRef.dispose();
            },
            error => {
                if (error.status == 401) {
                    // Tratado pelo InterceptorHttp401.
                    return;
                }

                this.bottomSheet.open(BottomSheetComponent, {
                    data: {
                        param: 'integrarPedido',
                        mensagem: error,
                    },
                    panelClass: 'bottom-sheet',
                });
                overlayRef.dispose();
            }
        );

        this.debugMode = false;

        this.isSendButtonActive = false;
        this.setCodTipOper();

        this.projetos = [];

        this.produtoSelecionado = {
            ADCODUNIPRO: null,
            DESCRPROD: null,
            CONTROLE: [],
            CODVOL: '',
            PESOBRUTO: null,
            PESOLIQ: null,
            QTDNEG: null,
            ADPERCTOLPEDCOM: null,
            ADMULTIVEN: null,
            VLRVENDA: null,
        };

        this.tipoRemessa = null;
        this.isRetirarMercadoria = false;

        // No construtor foi definido se deveria copiar o pedido, ação invocada no OnInit para não travar a entrada na tela.
        if (this.copiarPedido) this.realizarCopiaPedido();

        this.pgtoBordero = this.representantesBordero.includes(this.auth.getCodRep());
    }

    getNomeEmp(codEmp: number) {
        let ret: any = this.nomeEmpresas.find(emp => +emp.CODEMP === +codEmp);
        return ret.NOMEFANTASIA;
    }

    btnMaisDetalhes(value: string) {
        let param: string;
        let dados: any;
        let vlrEmAberto: ValorEmAberto = { VLREMABERTO: 0, VLRPEDIDOS: 0, VLRTITULOS: 0, VLRTRADEMASTER: 0, CLASSIFICACAO: ' ' };
        let exibirCampo = false;

        if (value === 'cliente') {
            if (this.cabecalhoPedido.CODPARC === null || this.cabecalhoPedido.CODPARC === 0) {
                this.portalServices.openSnackbar('Informe o cliente!');
                return false;
            }

            const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);
            this.portalJpaService.getValorEmAberto(this.parceiroCliente.CODPARC).subscribe(
                (res: any) => {
                    vlrEmAberto = res.response;
                    param = 'infoCliente';
                    dados = this.parceiroCliente;
                    exibirCampo = true;

                    this.bottomSheet.open(BottomSheetComponent, {
                        data: { param, dados, vlrEmAberto, exibirCampo },
                        panelClass: 'bottom-sheet',
                    });

                    overlayRef.dispose();
                },
                erro => {
                    overlayRef.dispose();
                    console.log(erro);
                }
            );
        }

        if (value === 'clienteRemessa') {
            this.cabecalhoPedido.ADPARCDESTCTOORD = this.parceiroClienteRemessa.CODPARC;
            if (this.cabecalhoPedido.ADPARCDESTCTOORD === null || this.cabecalhoPedido.ADPARCDESTCTOORD === 0) {
                this.portalServices.openSnackbar('Informe o cliente remessa!');
                return false;
            }

            param = 'infoCliente';
            dados = this.parceiroClienteRemessa;
        }

        if (value === 'transportadora') {
            if (this.cabecalhoPedido.ADTRANSPREDESPACHO === null || this.cabecalhoPedido.ADTRANSPREDESPACHO === 0) {
                this.portalServices.openSnackbar('Informe a transportadora!');
                return false;
            }

            param = 'infoTransportadora';
            dados = this.parceiroTransportadora;
        }

        if (value != 'cliente') {
            this.bottomSheet.open(BottomSheetComponent, {
                data: { param, dados, exibirCampo },
                panelClass: 'bottom-sheet',
            });
        }
    }

    toggleTipoPedido(event: any) {
        if (this.quantidadeItens > 0 || this.produtoSelecionado.DESCRPROD !== null) {
            location.reload();
            return false;
        }

        this.setCodTipOper();

        // Se for pronta entrega, desabilitar o select dos PO's. Se não, carregar os PO's e popular o select
        if (event.value === 1) {
            this.cabecalhoPedido.CODPROJ = 0;
        } else {
            if (this.isPilotagem) {
                this.portalServices.openSnackbar('Pilotagem somente permitido para Pronta Entrega');
                setTimeout(() => {
                    this.cabecalhoPedido.TIPOPEDIDO = 1;
                    this.cabecalhoPedido.CODTIPOPER = 1000;
                }, 0);

                event.preventDefault();
                return false;
            }

            this.sankhyaService.getPojetosAtivosPorEmpresa(this.cabecalhoPedido.CODEMP).subscribe((retorno: NumeroPO[]) => {
                this.projetos = retorno;
            });
        }
    }

    selecionaEmpresa(codEmp: any) {
        this.cabecalhoPedido.CODPROJ = 0;
        this.sankhyaService.getPojetosAtivosPorEmpresa(codEmp).subscribe((retorno: NumeroPO[]) => {
            this.projetos = retorno;
        });
        this.codEmp = codEmp;
        if (this.isPilotagem) {
            this.togglePilotagem();
        }
    }

    togglePilotagem() {
        const setPilotagemFalse = (valor: boolean) => {
            setTimeout(() => {
                this.isPilotagem = valor;
                this.cabecalhoPedido.ADPILOTAGEM = valor ? 'S' : 'N';
                this.childItensPedido.setIsPilotagem(this.isPilotagem);
            }, 0);
        };

        if (this.codEmp == 3) {
            this.portalServices.openSnackbar('Pilotagem não permitida para empresa 3 - Eurotextil Filial PE');
            setPilotagemFalse(false);
            this.clearTipoNegociacaoAutocomplete();
            this.cabecalhoPedido.ADOBSPED = '';
            if (this.parceiroCliente.ADAVISTA == 'S') {
                this.emitirTipoNegociacaoSelecionada({ CODTIPVENDA: 2, DESCRTIPVENDA: 'ANTECIPADO', SUBTIPOVENDA: '1' });
            }
            return false;
        }

        if (this.isPilotagem) {
            this.cabecalhoPedido.ADPILOTAGEM = 'S';
        } else {
            this.cabecalhoPedido.ADPILOTAGEM = 'N';
        }

        if (this.cabecalhoPedido.TIPOPEDIDO == 2 && this.isPilotagem) {
            this.portalServices.openSnackbar('Pilotagem somente permitido para Pronta Entrega');
            setPilotagemFalse(false);
            return;
        }

        this.childItensPedido.setIsPilotagem(this.isPilotagem);

        if (this.itensPedido.ITEM.length > 0) {
            this.openDialog('precoCheio', { pergunta: 'Eliminar itens?' }).subscribe(res => {
                if (res) {
                    this.itensPedido = {
                        ...this.itensPedido,
                        ITEM: [],
                    };
                } else {
                    setPilotagemFalse(!this.isPilotagem);
                    this.clearTipoNegociacaoAutocomplete();
                    this.cabecalhoPedido = {
                        ...this.cabecalhoPedido,
                        ADOBSPED: '',
                        CODTIPVENDA: this.tipoNegociacao.CODTIPVENDA,
                    };
                    // this.childItensPedido.setIsPilotagem(this.isPilotagem);
                }
            });
        }

        // this.childItensPedido.setIsPilotagem(this.isPilotagem);
        if (this.isPilotagem) {
            setTimeout(() => {
                // this.isRetirarMercadoria = false;
                this.cabecalhoPedido.CODMOEDA = 0;
                this.isCartao = false;
                this.isBlu = false;
                this.isBordero = false;
            }, 0);

            if (this.cabecalhoPedido.TIPOPEDIDO == 2) {
                this.portalServices.openSnackbar('Pilotagem somente permitido para Pronta Entrega');
                setPilotagemFalse(false);
            } else {
                if (this.parceiroCliente.ADCLASSIFICACAO !== 'E' || !this.parceiroCliente) {
                    this.tipoNegociacao = { CODTIPVENDA: 177, DESCRTIPVENDA: '30', SUBTIPOVENDA: '3' };
                } else {
                    this.tipoNegociacao = { CODTIPVENDA: 1209, DESCRTIPVENDA: 'TRADEMASTER - 30', SUBTIPOVENDA: '3' };
                }

                this.cabecalhoPedido = {
                    ...this.cabecalhoPedido,
                    ADOBSPED: 'PILOTAGEM',
                    CODTIPVENDA: this.tipoNegociacao.CODTIPVENDA,
                };
            }

            this.autocompleteParcelaEnabled = false;
        } else {
            this.clearTipoNegociacaoAutocomplete();
            this.autocompleteParcelaEnabled = true;
            this.cabecalhoPedido = {
                ...this.cabecalhoPedido,
                ADOBSPED: '',
                CODTIPVENDA: this.tipoNegociacao.CODTIPVENDA,
            };
        }

        if (this.parceiroCliente.ADAVISTA == 'S' && !this.isPilotagem) {
            this.emitirTipoNegociacaoSelecionada({ CODTIPVENDA: 2, DESCRTIPVENDA: 'ANTECIPADO', SUBTIPOVENDA: '1' });
            this.autocompleteParcelaEnabled = false;
        }
    }

    clearTipoNegociacaoAutocomplete() {
        this.tipoNegociacao = { CODTIPVENDA: 0, DESCRTIPVENDA: '', SUBTIPOVENDA: '0' };
    }

    selecionaProjeto() {
        if (this.quantidadeItens > 0 || this.produtoSelecionado.ADCODUNIPRO !== null) {
            location.reload();
        }
    }

    toggleClienteRemessa(isChecked: boolean) {
        if (!isChecked) {
            this.tipoRemessa = null;
            this.parceiroClienteRemessa = this.parceiroVazio();
            this.cabecalhoPedido.ADPARCDESTCTOORD = 0;
        }
    }

    toggleTipoMoeda(event: any) {
        if (this.quantidadeItens > 0) {
            const reset: boolean = confirm('Esta alteração descarta todos os itens inseridos. Deseja continuar?');

            if (reset) {
                this.itensPedido = {
                    ...this.itensPedido,
                    ITEM: [],
                };
            }
        }
        this.setCodTipOper();
    }

    toggleSendButton() {
        this.isSendButtonActive = !this.isSendButtonActive;
    }

    setCodTipOper(): void {
        if (this.cabecalhoPedido.CODMOEDA === 0 && this.cabecalhoPedido.TIPOPEDIDO === 1) {
            this.cabecalhoPedido.CODTIPOPER = TipoOperacao.PRONTAENTREGA;
        }

        if (this.cabecalhoPedido.CODMOEDA === 0 && this.cabecalhoPedido.TIPOPEDIDO === 2) {
            this.cabecalhoPedido.CODTIPOPER = TipoOperacao.PROGRAMADO;
        }

        if (this.cabecalhoPedido.CODMOEDA === 1 && this.cabecalhoPedido.TIPOPEDIDO === 1) {
            this.cabecalhoPedido.CODTIPOPER = TipoOperacao.PRONTAENTREGADOLAR;
        }

        if (this.cabecalhoPedido.CODMOEDA === 1 && this.cabecalhoPedido.TIPOPEDIDO === 2) {
            this.cabecalhoPedido.CODTIPOPER = TipoOperacao.PROGRAMADODOLAR;
        }
    }

    // clearClienteAutocomplete() {
    //     this.cabecalhoPedido = { ...this.cabecalhoPedido, CODPARC: 0 };

    //     this.parceiroCliente = this.parceiroVazio();
    // }

    parceiroVazio() {
        return {
            CODPARC: 0,
            NOMEPARC: '',
            RAZAOSOCIAL: '',
            CODVEND: 0,
            TIPO: '',
            NOMEEND: '',
            NUMEND: 0,
            COMPLEMENTO: '',
            NOMEBAI: '',
            NOMECID: '',
            CEP: '',
            UF: '',
            PAISEND: '',
            TELEFONE: '',
            EMAIL: '',
            IDENTINSCESTAD: '',
            CGCCPF: '',
            ATIVO: '',
            LIMCRED: 0,
            CLIENTE: '',
            FORNECEDOR: '',
            VENDEDOR: '',
            TRANSPORTADORA: '',
            BLOQUEAR: '',
            ADAVISTA: '',
        };
    }

    clearTranspAutoComplete() {
        this.cabecalhoPedido = { ...this.cabecalhoPedido, ADTRANSPREDESPACHO: 0 };
        this.parceiroTransportadora = this.parceiroVazio();
    }

    setCodParc(event: number) {
        this.cabecalhoPedido.CODPARC = event;
        this.cabecalhoPedido.CODPARCDEST = event;
    }

    setCodVendedor() {
        //this.validaTipoNegociacao();
        this.cabecalhoPedido.CODVEND = this.cabecalhoPedido.CODPARC === 0 ? 0 : this.parceiroCliente.CODVEND;

        // Cliente Selecionado
        if (this.parceiroCliente && (this.parceiroCliente.ATIVO == 'N' || this.parceiroCliente.ADSITUACAOPORTAL == 'BA')) {
            this.erroCliente('Entre em contato com o setor de comercial para revisar o cadastro deste cliente.').subscribe(result => {
                this.parceiroCliente = this.parceiroVazio();
            });
        }
    }

    erroCliente(msgErro: string) {
        return this.bottomSheet
            .open(BottomSheetComponent, {
                data: {
                    param: 'criarPedido',
                    mensagem: msgErro,
                },
                panelClass: 'bottom-sheet',
            })
            .afterDismissed();
    }

    setTransportadora() {
        this.cabecalhoPedido.ADTRANSPREDESPACHO = this.parceiroTransportadora.CODPARC;
    }

    validaTipoNegociacao() {
        if (
            this.parceiroCliente.ADAVISTA == 'S' &&
            this.tipoNegociacao.SUBTIPOVENDA != '1' &&
            this.tipoNegociacao.SUBTIPOVENDA != '2' &&
            !this.isCartao &&
            !this.isPilotagem &&
            this.tipoNegociacao.ADOPERACREDITO != 'B'
        ) {
            this.erroCliente('Entre em contato com o setor de comercial para revisar o cadastro deste cliente.').subscribe(result => {
                this.parceiroCliente = this.parceiroVazio();
                this.clearTipoNegociacaoAutocomplete;
            });
        }
    }

    zerarTipoNegociacao() {
        this.cabecalhoPedido.CODTIPVENDA = 0;
        this.tipoNegociacao = { CODTIPVENDA: 0, DESCRTIPVENDA: '', SUBTIPOVENDA: '' };
    }

    toggleBlu() {
        this.selecionaProjeto();
        this.zerarTipoNegociacao();
        this.cabecalhoPedido.TIPOPEDIDO = 1;
        this.cabecalhoPedido.CODPROJ = 0;
        this.cabecalhoPedido.CODTIPOPER = 1000;

        // if (!this.isBlu && this.parceiroCliente && this.parceiroCliente.ADAVISTA == 'S') {
        //     this.emitirTipoNegociacaoSelecionada({ CODTIPVENDA: 2, DESCRTIPVENDA: 'ANTECIPADO', SUBTIPOVENDA: '1' });
        // }

        if (this.isBlu) {
            this.isCartao = false;
            this.isBordero = false;
            this.cabecalhoPedido.CODTIPVENDA = 1205;
            this.emitirTipoNegociacaoSelecionada({ CODTIPVENDA: 1205, DESCRTIPVENDA: 'BLU', SUBTIPOVENDA: '3', ADOPERACREDITO: 'B' });
            this.autocompleteParcelaEnabled = false;
        } else {
            if (this.parceiroCliente && this.parceiroCliente.ADAVISTA !== 'S') this.autocompleteParcelaEnabled = true;
            this.toggleCartao();
        }
    }

    toggleCartao() {
        this.selecionaProjeto();
        this.zerarTipoNegociacao();
        this.cabecalhoPedido.TIPOPEDIDO = 1;
        this.cabecalhoPedido.CODPROJ = 0;
        this.cabecalhoPedido.CODTIPOPER = 1000;
        if (this.isCartao){
            this.isBlu = false;
            this.isBordero = false;
        } 
        if (!this.isCartao && this.parceiroCliente && this.parceiroCliente.ADAVISTA == 'S') {
            this.emitirTipoNegociacaoSelecionada({ CODTIPVENDA: 2, DESCRTIPVENDA: 'ANTECIPADO', SUBTIPOVENDA: '1' });
        }

        // this.sankhyaService.getTipoNegociacaoAutocomplete('cartao').subscribe(
        //     res => {
        //         this.opcoesCartao = res;
        //         this.opcoesCartaoFiltro = res;
        //     },

        //     erro => {
        //         console.log(erro);
        //     }
        // );
    }

    toggleBordero(){
        this.selecionaProjeto();
        this.zerarTipoNegociacao();
        
        if (this.isBordero){
            this.isBlu = false;
            this.isCartao = false;
            //CODTIPVENDA DE PRODUÇÃO: 1350
            this.emitirTipoNegociacaoSelecionada({ CODTIPVENDA: 1350, DESCRTIPVENDA: 'BORDERÔ', SUBTIPOVENDA: '4' });
            this.autocompleteParcelaEnabled = false;
        } else {
            if (!this.isBordero && this.parceiroCliente && this.parceiroCliente.ADAVISTA == 'S') {
                this.emitirTipoNegociacaoSelecionada({ CODTIPVENDA: 2, DESCRTIPVENDA: 'ANTECIPADO', SUBTIPOVENDA: '1' });
                this.autocompleteParcelaEnabled = false;
            } else {
                this.autocompleteParcelaEnabled = true;
            }
        }

    }

    // Este método é usado quando o usuário seleciona "DÉBITO" ou "CRÉDITO" depois de marcar pagamento em cartão
    filtraOpcoesCartaoByTipoCartao() {
        this.zerarTipoNegociacao();

        // Igualar o filtro à base completa;
        this.opcoesCartaoFiltro = this.opcoesCartao;

        // Filtrando as opções de acordo com a seleção de débito ou crédito
        this.opcoesCartaoFiltro = this.opcoesCartao.filter(
            opcaoCartao => opcaoCartao.SUBTIPOVENDA === this.tipoCartaoSelecionado.toString()
        );
    }

    getBandeirasDebitoByTipoCartao(): TipoNegociacao[] {
        let resultado: TipoNegociacao[] = [];

        resultado = this.opcoesCartaoFiltro.filter(opcao => opcao.SUBTIPOVENDA === this.cartaoDebito.toString());
        return resultado;
    }

    getBandeirasCreditoByTipoCartao(): string[] {
        let resultado: string[];

        // ...eliminar as bandeiras duplicadas
        resultado = this.opcoesCartaoFiltro.map(opcao => opcao.DESCRTIPVENDA.split('|')[0]);

        // Esta solução abaixo foi encontrada em: https://medium.com/dailyjs/how-to-remove-array-duplicates-in-es6-5daa8789641c
        const opcoesUnicas = new Set(resultado);
        resultado = [...opcoesUnicas];

        return resultado;
    }

    getParcelasByBandeira(): TipoNegociacao[] {
        let resultado: TipoNegociacao[] = [];

        // Percorrer pelas opções e filtrar as parcelas de acordo com a bandeira selecionada
        resultado = this.opcoesCartaoFiltro.filter(opcaoCartao => {
            if (opcaoCartao.DESCRTIPVENDA.split('|')[0] === this.bandeiraSelecionada) {
                return opcaoCartao.DESCRTIPVENDA.split('|')[1];
            }
        });

        return resultado;
    }

    openDialog(tipo: string, params?: any) {
        const dialogRef = this.dialog.open(DialogComponent, {
            width: '300px',
            data: { tipo, params },
        });

        return dialogRef.afterClosed();
    }

    async integrarPedido() {
        // Validando cliente
        if (this.cabecalhoPedido.CODPARC === 0 || this.cabecalhoPedido.CODPARCDEST === 0) {
            this.portalServices.openSnackbar('Informe o cliente!');
            return false;
        }

        // Validando se Remessa é verdade e se cliente remessa foi preenchido
        if (this.cabecalhoPedido.ADREMESSA && this.cabecalhoPedido.ADPARCDESTCTOORD === 0) {
            this.portalServices.openSnackbar('Informe o cliente remessa ou desmarque a Venda Triangular!');
            return false;
        }

        if (this.cabecalhoPedido.ADPARCDESTCTOORD === this.cabecalhoPedido.CODPARC) {
            this.portalServices.openSnackbar('Cliente e cliente remessa não podem ser os mesmos!');
            return false;
        }

        // Validando se existe condição de pagamento
        if (this.cabecalhoPedido.CODTIPVENDA === 0 || this.cabecalhoPedido.CODTIPVENDA === null) {
            this.portalServices.openSnackbar('Informe a condição de pagamento!');
            return false;
        }

        // Validando se o cliente marcou como pedido Programado, mas não informou o número do projeto
        if (this.cabecalhoPedido.TIPOPEDIDO === 2 && this.cabecalhoPedido.CODPROJ === 0) {
            this.portalServices.openSnackbar('Informe o número do PO ou marque a opção "Pronta Entrega"!');
            this.numeroPO.nativeElement.focus();
            return false;
        }

        if (
            this.parceiroCliente.ADAVISTA == 'S' &&
            this.tipoNegociacao.SUBTIPOVENDA != '1' &&
            this.tipoNegociacao.SUBTIPOVENDA != '2' &&
            !this.isCartao &&
            !this.isPilotagem &&
            this.tipoNegociacao.ADOPERACREDITO != 'B'
        ) {
            this.validaTipoNegociacao();
            return false;
        }

        // validando se o cliente está bloqueado PE ou PO
        if (
            (this.parceiroCliente.ADSITUACAOPORTAL === 'BPE' && this.cabecalhoPedido.TIPOPEDIDO == 1) ||
            (this.parceiroCliente.ADSITUACAOPORTAL === 'BPO' && this.cabecalhoPedido.TIPOPEDIDO == 2)
        ) {
            this.erroCliente('Entre em contato com o setor de comercial para revisar o cadastro deste cliente.').subscribe(result => {});
            return false;
        }

        // Validando se existem itens no pedido
        if (this.itensPedido.ITEM.length === 0) {
            this.portalServices.openSnackbar('Insira itens em seu pedido!');
            return false;
        }

        // Validando se a observação possui a palavra PILOTAGEM quando o pedido for desse tipo
        if (this.isPilotagem && !this.cabecalhoPedido.ADOBSPED.includes('PILOTAGEM')) {
            if (this.cabecalhoPedido.ADOBSPED) {
                this.cabecalhoPedido.ADOBSPED = 'PILOTAGEM - ' + this.cabecalhoPedido.ADOBSPED;
            } else {
                this.cabecalhoPedido.ADOBSPED = 'PILOTAGEM';
            }
        }

        // Validando as observações de acordo com o tipo de remessa que foi escolhida
        if (this.tipoRemessa !== null) {
            // Definindo a informação do tipo de remessa
            const infoTipoRemessa = this.tipoRemessa === '1' ? 'Tipo de Remessa: Industrialização' : 'Tipo de Remessa: Conta e Ordem';
            // Se o usuário não digitou nenhuma observação, apenas inserir a info de tipo remessa. Senão, concatenar com um hífen
            if (this.cabecalhoPedido.ADOBSPED === '') {
                this.cabecalhoPedido = { ...this.cabecalhoPedido, ADOBSPED: infoTipoRemessa };
            } else {
                this.cabecalhoPedido = { ...this.cabecalhoPedido, ADOBSPED: `${infoTipoRemessa} - ${this.cabecalhoPedido.ADOBSPED}` };
            }
        }

        // Concatenar a informacao 'Retirar mercadoria na Eurotextil' caso a flag respectiva estiver marcada
        if (this.isRetirarMercadoria) {
            this.cabecalhoPedido = {
                ...this.cabecalhoPedido,
                ADOBSPED: `RETIRAR MERCADORIA NA EUROTEXTIL - ${this.cabecalhoPedido.ADOBSPED}`,
            };
        }

        let temIpi = false;

        this.itensPedido.ITEM.forEach(element => {
            temIpi = element.TEMIPIVENDA == 'S' ? true : temIpi;
        });

        if (temIpi) {
            if (this.cabecalhoPedido.ADOBSPED) {
                this.cabecalhoPedido.ADOBSPED = this.cabecalhoPedido.ADOBSPED + ' - POSSUI IPI';
            } else {
                this.cabecalhoPedido.ADOBSPED = 'POSSUI IPI';
            }
        }

        this.itensPedido.ITEM.forEach(element => {
            element.ADEXCECAOPRECO = element.PRECOORIGINAL === element.VLRUNIT || this.cabecalhoPedido.CODMOEDA > 0 ? 'N' : 'S';
        });

        await this.calculaComissao(false);

        // Mostrando o spinner na tela
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

        this.sankhyaService.sendPedido(this.cabecalhoPedido, this.itensPedido).subscribe(
            (res: any) => {
                if (res.serviceResponse === 'ERROR') {
                    if (res.exibeBadge) {
                    }

                    this.bottomSheet
                        .open(BottomSheetComponent, {
                            data: {
                                param: 'integrarPedido',
                                mensagem: res,
                            },
                            panelClass: 'bottom-sheet',
                        })
                        .afterDismissed()
                        .subscribe(() => {
                            location.reload();
                        });
                    this.inicializarVariavel();
                    this.notificacaoService.atualizarNotificacoes();
                } else {
                    this.portalServices.openSnackbar(res.response);
                    location.reload();
                }
            },
            erro => {
                this.bottomSheet.open(BottomSheetComponent, {
                    data: {
                        param: 'integrarPedido',
                        mensagem: erro,
                    },
                    panelClass: 'bottom-sheet',
                });

                this.inicializarVariavel();

                // Removendo o spinner da tela
                overlayRef.dispose();
            },
            () => {
                // Removendo o spinner da tela
                overlayRef.dispose();
            }
        );
    }

    preVisualizar() {
        // Instanciando a variável que possui as configurações do spinner
        const config = new OverlayConfig();

        // Atribuindo as features do spinner
        config.positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically();

        // Definindo que o spinner ficará em uma camada acima da aplicação
        config.hasBackdrop = true;

        // Criando a camada com as configurações e atribuindo a uma constante
        const overlayRef = this.overlay.create(config);

        // Mostrando o spinner na tela
        overlayRef.attach(new ComponentPortal(SpinnerComponent, this.viewContainerRef));

        this.sankhyaService.sendPreview(this.cabecalhoPedido, this.itensPedido).subscribe(
            (res: IntegrarPedido) => {
                if (res.serviceResponse === 'ERROR') {
                    this.bottomSheet.open(BottomSheetComponent, {
                        data: {
                            param: 'integrarPedido',
                            mensagem: res,
                        },
                        panelClass: 'bottom-sheet',
                    });
                } else {
                    this.portalServices.openSnackbar(res.response);
                    this.portalServices.previewProposta(res.NUNOTA);
                }
            },
            erro => {
                this.bottomSheet.open(BottomSheetComponent, {
                    data: {
                        param: 'integrarPedido',
                        mensagem: erro,
                    },
                    panelClass: 'bottom-sheet',
                });

                // Removendo o spinner da tela
                overlayRef.dispose();
            },
            () => {
                // Removendo o spinner da tela
                overlayRef.dispose();
            }
        );
    }

    inicializarVariavel(): void {
        // Variáveis que iniciam com um valor padrão
        this.moedaReal = TipoMoeda.REAL;
        this.moedaDolar = TipoMoeda.DOLAR;
        this.prontaEntrega = TipoPedido.PRONTAENTREGA;
        this.programado = TipoPedido.PROGRAMADO;
        this.cif = CifFob.CIF;
        this.fob = CifFob.FOB;
        this.quantidadeItens = 0;
        this.isCartao = false;
        this.tipoCartaoSelecionado = 0;
        this.opcoesCartao = [];
        this.opcoesCartaoFiltro = [];
        this.cartaoCredito = SubTipo.CREDITO;
        this.cartaoDebito = SubTipo.DEBITO;
        this.bandeiraSelecionada = '';
        this.isBlu = false;
        this.isBordero = false;

        // Cabeçalho Pedido
        const seedFrase = this.auth.getUserName() + localStorage.getItem('ultimaNotificacao') + Date.now().toString();
        const ADHASHID = new Md5().appendStr(seedFrase).end().toString();
        this.cabecalhoPedido = {
            NUMNOTA: 0,
            NUREM: 0,
            DTFATUR: '',
            CODPARC: 0,
            CODTIPOPER: TipoOperacao.PRONTAENTREGA,
            TIPMOV: 'P',
            CODVEND: 0,
            CODMOEDA: TipoMoeda.REAL,
            OBSERVACAO: '',
            TIPFRETE: 'N',
            CIFFOB: this.cif,
            VLRNOTA: 0,
            ORDEMCARGA: 0,
            SEQCARGA: 0,
            QTDVOL: 0,
            APROVADO: 'N',
            STATUSNOTA: '',
            CODUSU: 0,
            CODPARCDEST: 0,
            CODPROJ: 0,
            PESO: 0,
            PESOBRUTO: 0,
            CODCIDORIGEM: 0,
            CODCIDDESTINO: 0,
            ADREMESSA: false,
            ADPARCDESTCTOORD: 0,
            TIPOPEDIDO: TipoPedido.PRONTAENTREGA,
            ADORIGPEDV: 'P',
            ADOBSPED: '',
            CODTIPVENDA: 0,
            ADTRANSPREDESPACHO: 0,
            ADPILOTAGEM: 'N',
            ADHASHID,
        };

        // Itens Pedido
        this.itensPedido = {
            ITEM: [],
        };

        // Condição de Pagamento
        this.parcelasTipoNegociacao = [];

        // Cliente
        this.parceiroCliente = this.parceiroVazio();

        // Cliente Remessa
        this.parceiroClienteRemessa = this.parceiroVazio();

        // Tipo de Negociacao
        this.clearTipoNegociacaoAutocomplete();

        this.clearTranspAutoComplete();

        this.produtosUnificados = [];
        this.ttCorDesenho = [];
    }

    toggleRetirarMercadoria(isChecked: boolean) {
        if (isChecked) {
            this.clearTranspAutoComplete();
        }
    }

    enableRedesp(): boolean {
        return !this.isPilotagem && !this.isRetirarMercadoria;
    }

    realizarCopiaPedido(): void {
        const nuNotaProposta = +this.pedidoOriginal.NUNOTA;
        this.sankhyaService.getDadosCopiaPedido(nuNotaProposta).subscribe((datas: any) => {
            const tmpStr = JSON.stringify(datas).replace(/\"\<NULL\>\"/g, 'null');
            const data = JSON.parse(tmpStr);

            // Atribui vazio a observação se não existir
            data.AD_OBSPED = '';

            // Autocomplete Cliente
            if (data && data.parceiro && +data.parceiro.CODPARC > 0) {
                this.parceiroCliente = {
                    CODPARC: +data.parceiro.CODPARC,
                    CODVEND: +data.parceiro.CODVEND,
                    NUMEND: data.parceiro.NUMEND ? +data.parceiro.NUMEND : 0,
                    LIMCRED: data.parceiro.LIMCRED ? +data.parceiro.LIMCRED : 0,
                    NOMEPARC: data.parceiro.NOMEPARC,
                    RAZAOSOCIAL: data.parceiro.RAZAOSOCIAL,
                    TIPO: data.parceiro.TIPOEND,
                    NOMEEND: data.parceiro.NOMEEND,
                    COMPLEMENTO: data.parceiro.COMPLEMENTO,
                    NOMEBAI: data.parceiro.NOMEBAI,
                    NOMECID: data.parceiro.NOMECID,
                    TELEFONE: data.parceiro.TELEFONE,
                    EMAIL: data.parceiro.EMAIL,
                    IDENTINSCESTAD: data.parceiro.IDENTINSCESTAD,
                    CGCCPF: data.parceiro.CGC_CPF,
                    ATIVO: data.parceiro.ATIVO,
                    CLIENTE: data.parceiro.CLIENTE,
                    FORNECEDOR: data.parceiro.FORNECEDOR,
                    VENDEDOR: data.parceiro.VENDEDOR,
                    TRANSPORTADORA: data.parceiro.TRANSPORTADORA,
                    BLOQUEAR: data.parceiro.BLOQUEAR,
                    ADAVISTA: data.parceiro.ADAVISTA,
                };

                this.cabecalhoPedido.CODPARC = +data.parceiro.CODPARC;
                this.cabecalhoPedido.CODVEND = this.cabecalhoPedido.CODPARC === 0 ? 0 : this.parceiroCliente.CODVEND;
                this.cabecalhoPedido.CODPARCDEST = +data.CODPARCDEST;

                if (this.parceiroCliente.ADAVISTA === 'S') {
                    this.portalServices.openSnackbar('Cliente somente à vista ou antecipado!');
                    this.clearTipoNegociacaoAutocomplete();
                    this.emitirTipoNegociacaoSelecionada({ CODTIPVENDA: 2, DESCRTIPVENDA: 'ANTECIPADO', SUBTIPOVENDA: '1' });
                    this.autocompleteParcelaEnabled = false;
                } else {
                    this.emitirTipoNegociacaoSelecionada({ CODTIPVENDA: 0, DESCRTIPVENDA: '', SUBTIPOVENDA: '' });
                    this.autocompleteParcelaEnabled = true;
                }
            }

            // HTML Select Empresa que está vendendo
            this.cabecalhoPedido.CODEMP = +data.CODEMP;
            this.codEmp = this.cabecalhoPedido.CODEMP;

            // Togle Retirar na Euro
            this.isRetirarMercadoria = data.AD_OBSPED.includes('RETIRAR MERCADORIA NA EUROTEXTIL');

            // Togle Pilotagem
            this.cabecalhoPedido.ADPILOTAGEM = data.AD_PILOTAGEM;
            this.isPilotagem = this.cabecalhoPedido.ADPILOTAGEM === 'S';

            //Togle Venda Triangular
            if (data.AD_REMESSA === 'S') {
                this.cabecalhoPedido.ADREMESSA = true;
                this.tipoRemessa = null;

                if (data.AD_OBSPED.includes('TIPO DE REMESSA: INDUSTRIALIZA')) {
                    this.tipoRemessa = '1';
                }
                if (data.AD_OBSPED.includes('TIPO DE REMESSA: CONTA E ORDEM')) {
                    this.tipoRemessa = '2';
                }
            }

            // Moeda Real / Dolar
            this.cabecalhoPedido.CODMOEDA = +data.CODMOEDA;

            // Condição de pagamento
            if (data.CODTIPVENDA != '<NULL>') {
                this.cabecalhoPedido.CODTIPVENDA = +data.CODTIPVENDA;
                this.tipoNegociacao = {
                    CODTIPVENDA: +data.tipoVenda.CODTIPVENDA,
                    DESCRTIPVENDA: data.tipoVenda.DESCRTIPVENDA,
                    SUBTIPOVENDA: data.tipoVenda.SUBTIPOVENDA,
                };
                const tmpSubTipo = +data.tipoVenda.SUBTIPOVENDA;

                if ([7, 8].includes(tmpSubTipo)) {
                    this.isCartao = true;
                    if (tmpSubTipo == 7) {
                        // Credito

                        this.tipoCartaoSelecionado = this.cartaoCredito;
                    } else if (tmpSubTipo == 8) {
                        // Debito

                        this.tipoCartaoSelecionado = this.cartaoDebito;
                    }
                    const tmpBandeira = this.tipoNegociacao.DESCRTIPVENDA.split(' ');
                    this.bandeiraSelecionada = tmpBandeira[1];

                    // this.sankhyaService.getTipoNegociacaoAutocomplete('cartao').subscribe(
                    //     res => {
                    //         this.opcoesCartao = res;
                    //         this.opcoesCartaoFiltro = this.opcoesCartao
                    //             .filter(opcaoCartao => +opcaoCartao.SUBTIPOVENDA == tmpSubTipo)
                    //             .map(item => {
                    //                 return {
                    //                     CODTIPVENDA: +item.CODTIPVENDA,
                    //                     DESCRTIPVENDA: item.DESCRTIPVENDA,
                    //                     SUBTIPOVENDA: item.SUBTIPOVENDA,
                    //                 };
                    //             });
                    //     },
                    //     erro => {
                    //         console.log(erro);
                    //     }
                    // );
                } else {
                    // Não é cartão
                    this.validaTipoNegociacao();
                }
            }

            // Tipo de pedido
            if (+data.CODPROJ > 1 && data.AD_DSPVEN === 'S') {
                // 2 - Programado
                this.cabecalhoPedido.TIPOPEDIDO = 2;
                this.cabecalhoPedido.CODPROJ = +data.CODPROJ;

                // Obtém a lista de projetos para atualizar na tela
                this.sankhyaService.getPojetosAtivosPorEmpresa(this.cabecalhoPedido.CODEMP).subscribe((retorno: NumeroPO[]) => {
                    this.projetos = retorno;
                });
            } else {
                // 1 - Pronta Entrega
                this.cabecalhoPedido.TIPOPEDIDO = 1;
                this.cabecalhoPedido.CODPROJ = 0;
            }

            // Transportadora Redespacho
            if (data.AD_TRANSPREDESPACHO != '<NULL>' && data.parceiroRedespacho && +data.AD_TRANSPREDESPACHO > 0) {
                this.parceiroTransportadora = {
                    CODPARC: +data.parceiroRedespacho.CODPARC,
                    CODVEND: +data.parceiroRedespacho.CODVEND,
                    NUMEND: data.parceiroRedespacho.NUMEND ? +data.parceiroRedespacho.NUMEND : 0,
                    LIMCRED: data.parceiroRedespacho.LIMCRED ? +data.parceiroRedespacho.LIMCRED : 0,
                    NOMEPARC: data.parceiroRedespacho.NOMEPARC,
                    RAZAOSOCIAL: data.parceiroRedespacho.RAZAOSOCIAL,
                    TIPO: data.parceiroRedespacho.TIPOEND,
                    NOMEEND: data.parceiroRedespacho.NOMEEND,
                    COMPLEMENTO: data.parceiroRedespacho.COMPLEMENTO,
                    NOMEBAI: data.parceiroRedespacho.NOMEBAI,
                    NOMECID: data.parceiroRedespacho.NOMECID,
                    TELEFONE: data.parceiroRedespacho.TELEFONE,
                    EMAIL: data.parceiroRedespacho.EMAIL,
                    IDENTINSCESTAD: data.parceiroRedespacho.IDENTINSCESTAD,
                    CGCCPF: data.parceiroRedespacho.CGC_CPF,
                    ATIVO: data.parceiroRedespacho.ATIVO,
                    CLIENTE: data.parceiroRedespacho.CLIENTE,
                    FORNECEDOR: data.parceiroRedespacho.FORNECEDOR,
                    VENDEDOR: data.parceiroRedespacho.VENDEDOR,
                    TRANSPORTADORA: data.parceiroRedespacho.TRANSPORTADORA,
                    BLOQUEAR: data.parceiroRedespacho.BLOQUEAR,
                };
                this.cabecalhoPedido.ADTRANSPREDESPACHO = +data.AD_TRANSPREDESPACHO;
            }

            this.setCodTipOper();

            this.dialog
                .open(DialogBasicComponent, {
                    width: '400px',
                    data: {
                        titulo: 'Confira os dados do Pedido',
                        linhas: [
                            '*** Atenção ***',
                            'É responsabilidade do representante conferir todo os campos do pedido.',
                            data.AD_REMESSA === 'S' ? '** Cliente remessa precisa ser preenchido.' : '',
                            +data.CODPROJ > 0 && data.AD_DSPVEN === 'N' ? '** Projeto copiado não disponível pra venda.' : '',
                        ],
                        botoes: [
                            {
                                texto: 'Ok',
                                retorno: true,
                                color: 'primary',
                            },
                        ],
                    },
                })
                .afterClosed()
                .subscribe(result => {});
        });
    }

    async getVlrComPercCom(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.portalJpaService.getComissaoPedido(params).subscribe(
                res => {
                    resolve(res);
                },
                erro => {
                    reject(erro);
                }
            );
        });
    }

    async calculaComissao(exibeBottonSheet: boolean) {
        if (this.cabecalhoPedido && (!this.cabecalhoPedido.CODPARC || !this.cabecalhoPedido.CODTIPVENDA)) {
            this.portalServices.openSnackbar('Para calcular a comissão, é necessário preencher o cliente e a condição de pagamento!');
            return false;
        }
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);
        this.comTot = 0;

        for (const item of this.itensPedido.ITEM) {
            item.ADCOMVLR = 0;
            item.ADCOMPERC = 0;

            let params = {
                precoDigitado: item.VLRUNIT,
                precoTabela: item.ADVLRUNITTAB,
                vlrTot: item.VLRUNIT * item.QTDNEG,
                codParc: this.cabecalhoPedido.CODPARC,
                tipNeg: this.cabecalhoPedido.CODTIPVENDA,
                codEmp: this.cabecalhoPedido.CODEMP,
                adCodUniPro: item.ADCODUNIPRO,
                controle: item.CONTROLE,
            };

            let ret: any = {};
            try {
                ret = await this.getVlrComPercCom(params);
                const arrRet = ret.response.split(';');

                item.ADCOMVLR = +arrRet[0].replace(',', '.');
                item.ADCOMPERC = +arrRet[1].replace(',', '.');
                this.cabecalhoPedido.ADREGRACOM = arrRet[2].replace(',', '.');

                this.comTot = Math.trunc((this.comTot + item.ADCOMVLR) * 100) / 100;
            } catch (error) {
                console.log(error);
            }
        }

        this.cabecalhoPedido.ADCODTABCOM = 1;
        this.cabecalhoPedido.ADNUTABCOM = 2;

        overlayRef.dispose();

        if (exibeBottonSheet) {
            this.bottomSheet.open(BottomSheetComissaoComponent, { data: { itens: this.itensPedido.ITEM, total: this.comTot } });
        }
    }

    enviar() {
        let data = {
            codParc: this.cabecalhoPedido.CODPARC,
            codEmp: this.cabecalhoPedido.CODEMP,
            codProj: this.cabecalhoPedido.CODPROJ,
            codTipVenda: this.cabecalhoPedido.CODTIPVENDA,
            itens: this.itensPedido.ITEM.map(({ ADCODUNIPRO, VLRUNIT }) => ({ NUNOTA: 0, ADCODUNIPRO, VLRUNIT })),
            adRemessa: this.cabecalhoPedido.ADREMESSA ? 'S' : 'N',
            codParcDest: this.cabecalhoPedido.CODPARCDEST,
        };

        this.bottomSheet
            .open(BottomSheetJuntarPedidoComponent, { data: data })
            .afterDismissed()
            .subscribe((ret: any) => {
                if (ret) {
                    switch (ret.opcao) {
                        case 'confirmar': {
                            if (ret.pedidoJuntar) {
                                this.cabecalhoPedido.ADPEDIDOJUNTAR = ret.pedidoJuntar;
                                // if (this.cabecalhoPedido.ADOBSPED) {
                                //     this.cabecalhoPedido.ADOBSPED =
                                //         this.cabecalhoPedido.ADOBSPED + ' - JUNTAR PEDIDO [' + ret.pedidoJuntar + ']';
                                // } else {
                                //     this.cabecalhoPedido.ADOBSPED = 'JUNTAR PEDIDO [' + ret.pedidoJuntar + ']';
                                // }
                            }

                            ret.aConfirmar ? (this.cabecalhoPedido.ADACONFIRMAR = ret.aConfirmar) : false;

                            this.integrarPedido();
                            break;
                        }

                        case 'preVisualizar': {
                            this.preVisualizar();
                            break;
                        }

                        default:
                            break;
                    }
                }
            });
    }

    private getParceiroVazio(): Parceiro {
        return {
            CODPARC: 0,
            NOMEPARC: '',
            RAZAOSOCIAL: '',
            CODVEND: 0,
            TIPO: '',
            NOMEEND: '',
            NUMEND: 0,
            COMPLEMENTO: '',
            NOMEBAI: '',
            NOMECID: '',
            CEP: '',
            UF: '',
            PAISEND: '',
            TELEFONE: '',
            EMAIL: '',
            IDENTINSCESTAD: '',
            CGCCPF: '',
            ATIVO: '',
            LIMCRED: 0,
            CLIENTE: '',
            FORNECEDOR: '',
            VENDEDOR: '',
            TRANSPORTADORA: '',
            BLOQUEAR: '',
            ADSITUACAOPORTAL: '',
        };
    }

    emitirClienteSelecionado(cliente: Parceiro) {
        this.isBlu = false;
        this.isCartao = false;
        this.isBordero = false;
        this.emitirTipoNegociacaoSelecionada(null);
        if (!cliente) return;

        if (cliente.ATIVO == 'N' || cliente.ADSITUACAOPORTAL == 'BA') {
            this.erroCliente('Entre em contato com o setor de comercial para revisar o cadastro deste cliente.').subscribe(result => {
                this.parceiroCliente = this.parceiroVazio();
            });
        } else {
            this.parceiroCliente = { ...cliente };
            this.cabecalhoPedido.CODPARC = this.parceiroCliente.CODPARC;
            this.cabecalhoPedido.CODPARCDEST = this.parceiroCliente.CODPARC;
            this.setCodVendedor();

            this.isBlu = false;

            if (cliente.ADAVISTA == 'S' && !this.isPilotagem) {
                this.emitirTipoNegociacaoSelecionada({ CODTIPVENDA: 2, DESCRTIPVENDA: 'ANTECIPADO', SUBTIPOVENDA: '1' });
                this.autocompleteParcelaEnabled = false;
            } else {
                this.emitirTipoNegociacaoSelecionada(null);

                this.autocompleteParcelaEnabled = true;

                if (this.isPilotagem) {
                    if (this.parceiroCliente.ADCLASSIFICACAO === 'E') {
                        this.tipoNegociacao = { CODTIPVENDA: 1209, DESCRTIPVENDA: 'TRADEMASTER - 30', SUBTIPOVENDA: '3' };
                    } else {
                        this.tipoNegociacao = { CODTIPVENDA: 177, DESCRTIPVENDA: '30', SUBTIPOVENDA: '3' };
                    }

                    this.autocompleteParcelaEnabled = false;
                }
            }
        }
    }

    emitirClienteRemessaSelecionado(cliente: Parceiro) {
        if (!cliente || cliente.CODPARC === this.parceiroCliente.CODPARC) {
            this.parceiroClienteRemessa = this.getParceiroVazio();
            this.cabecalhoPedido.ADPARCDESTCTOORD = 0;
            if (cliente && cliente.CODPARC === this.parceiroCliente.CODPARC && this.parceiroCliente.CODPARC > 0) {
                this.portalServices.openSnackbar('O cliente do pedido não pode ser o mesmo da Remessa.');
            }
        } else {
            this.parceiroClienteRemessa = { ...cliente };
            this.cabecalhoPedido.ADPARCDESTCTOORD = cliente.CODPARC;
            this.parceiroClienteRemessa.CGCCPF = this.parceiroClienteRemessa.CGCCPF.replace(
                /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                '$1.$2.$3/$4-$5'
            );
        }
    }

    emitirTipoNegociacaoSelecionada(tipoNegociacao: TipoNegociacao) {
        if (!tipoNegociacao) {
            this.tipoNegociacao = { CODTIPVENDA: 0, DESCRTIPVENDA: '', SUBTIPOVENDA: '' };
        } else {
            this.tipoNegociacao = { ...tipoNegociacao };
            this.cabecalhoPedido.CODTIPVENDA = tipoNegociacao.CODTIPVENDA;

            if (this.isCartao) {
                const tmpBandeira = this.tipoNegociacao.DESCRTIPVENDA.split('|');
                this.tipoCartaoSelecionado = tmpBandeira[1].toUpperCase() == 'DÉBITO' ? 0 : 1;
                this.bandeiraSelecionada = tmpBandeira[0];
            }
        }
    }

    emitirTransportadoraSelecionada(transportadora: Parceiro) {
        if (!transportadora || transportadora.ATIVO == 'N') {
            this.parceiroTransportadora = this.getParceiroVazio();
            if (transportadora && transportadora.ATIVO == 'N') {
                this.portalServices.openSnackbar('Entre em contato com o setor de Cadastro para revisar o cadastro desta transportadora.');
            }
        } else {
            this.parceiroTransportadora = { ...transportadora };
            this.setTransportadora();
        }
    }

    clearClienteAutocomplete() {
        this.parceiroCliente = this.getParceiroVazio();
        this.autocompleteParcelaEnabled = false;
        this.tipoNegociacao = { CODTIPVENDA: 0, DESCRTIPVENDA: '', SUBTIPOVENDA: '' };
        this.isBlu = false;
        this.isCartao = false;
        this.isBordero = false;
    }
}
