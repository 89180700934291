export interface PedidosStatus {
  DESCRICAO: string;
  VALUE: number;
}

export enum TipoStatusPedido {
  PROPOSTA = 1,
  PROPOSTAAPROVADA = 2,
  CONFIRMADO = 3,
  LIBERADOSEPARAR = 4,
  SEPARADO = 5,
  FATURADO = 6,
  RECUSADO = 7
}
