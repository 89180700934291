import { Overlay } from '@angular/cdk/overlay';
import { Component, Input, OnInit, Output, EventEmitter, ViewContainerRef } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { PortalJpaService } from 'src/app/services/portal-jpa.service';
import { SpinnerComponent } from '../spinner/spinner.component';

@Component({
    selector: 'app-autocomplete-produtos-sem-filtro',
    templateUrl: './autocomplete-produtos-sem-filtro.component.html',
    styleUrls: ['./autocomplete-produtos-sem-filtro.component.scss'],
})
export class AutocompleteProdutosSemFiltroComponent implements OnInit {
    constructor(private portalJpaService: PortalJpaService, public overlay: Overlay, public viewContainerRef: ViewContainerRef) {}

    myControl = new FormControl();
    options: any[] = [];
    filteredOptions: Observable<string[]>;

    @Output()
    produtoSelecionado = new EventEmitter<any>();

    @Input()
    tela;

    ngOnInit() {
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

        this.portalJpaService.autocompleteProdutoSemFiltro(this.tela).subscribe(
            (res: any) => {
                this.options = res.response.map((produto: any) => {
                    return { ADCODUNIPRO: produto.codUniPro, DESCRPROD: produto.descrProd };
                });

                overlayRef.dispose();
            },
            err => {
                console.log(err);
                overlayRef.dispose();
            }
        );

        this.myControl.setValidators(Validators.required);
        this.myControl.setValue(null);
        this.myControl.markAsTouched();

        this.filteredOptions = this.myControl.valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value))
        );
    }

    private _filter(value: string): string[] {
        return this.options.filter(
            option =>
                option['DESCRPROD'].toLowerCase().includes(value) ||
                option['DESCRPROD'].includes(value) ||
                String(option['ADCODUNIPRO']).includes(value)
        );
    }

    emitEvent() {
        const produto = this.options.find(produto => produto.DESCRPROD.includes(this.myControl.value));
        this.produtoSelecionado.emit(produto);
    }

    clearControl() {
        this.myControl.setValue(null);
        this.produtoSelecionado.emit(null);
    }
}
