import { Component, OnInit, Inject, ViewContainerRef } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { TipoAprovacao, PedidosPesquisa } from 'src/app/global-interfaces/pedidos-pesquisa-interface';
import { SankhyaServiceService } from '../../services/sankhya-service.service';
import { OverlayConfig, Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { SpinnerComponent } from '../spinner/spinner.component';
import { DialogComponent } from '../dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PortalJpaService } from 'src/app/services/portal-jpa.service';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

@Component({
    selector: 'app-bottom-sheet',
    templateUrl: './bottom-sheet.component.html',
    styleUrls: ['./bottom-sheet.component.scss'],
})
export class BottomSheetComponent implements OnInit {
    tipoAprovacao = {
        aprovar: TipoAprovacao.APROVAR,
        william: TipoAprovacao.WILLIAM,
        pendente: TipoAprovacao.PENDENTE,
        recusar: TipoAprovacao.RECUSAR,
    };

    displayedColumns: string[] = ['produto', 'valor de tabela', 'valor digitado', 'desconto'];

    motivoRecusa: number;
    enviaEmail: boolean;
    observacao: string;
    comissao: number;
    produtos: any;
    tempoInatividade: number;
    tempoRestante: string;
    limpaEstoque: boolean;
    todosEst: boolean;
    todosSaldo: boolean;
    listaProdutos$: Observable<string[]>;
    nomeArtigo: string[];
    isHeaderBold: boolean = true;
    cutWordVariable: number = 0;

    constructor(
        public bottomSheetRef: MatBottomSheetRef<any>,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
        private sankhyaService: SankhyaServiceService,
        public overlay: Overlay,
        public viewContainerRef: ViewContainerRef,
        private snackBar: MatSnackBar,
        private dialog: MatDialog,
        private portalJpaService: PortalJpaService
    ) {}

    ngOnInit() {
        window.innerWidth <= 768 ? (this.cutWordVariable = 20) : (this.cutWordVariable = 100);
        this.enviaEmail = true;
        // Caso seja recusa de pedido, acrescentar o atributo OBSERVACOES ao objeto
        if (this.data.param === 'recusaPedido') {
            this.data = { ...this.data, pedido: { ...this.data.pedido, OBSERVACAO: '' } };
        }
        if (this.data.param === 'inserirParametros') {
            this.produtos = this.data.produtos;
            this.data.limpEst == 'S' ? (this.limpaEstoque = true) : (this.limpaEstoque = false);

            this.produtos.find(a => a.LIMPEST == 'N') ? (this.todosEst = false) : (this.todosEst = true);
            this.produtos.find(a => a.LIMPASALDO == 'N') ? (this.todosSaldo = false) : (this.todosSaldo = true);

        }
        if (this.data.param === 'liberacoes') {
            this.listaProdutos$ = this.portalJpaService
                .getInformacoesDesconto(this.data.pedido.NUNOTA);

            this.portalJpaService.getListaProdutos().subscribe(data => {
                this.nomeArtigo = data['response'];
            });
        }
    }

    tratarValorNegativo(valor) {
        let changedValue = parseFloat(valor) < 0 ? valor.replace('-', '+').replace('.',',') : valor.replace('.',',');
        return changedValue;
    }

    // Bottom Sheet de Liberações
    acaoLiberacao(tipo: string, pedLiberacao: PedidosPesquisa) {
        // console.log(tipo, pedLiberacao);

        // Caso o pedido seja recusado, abrir popup para o usuário escrever o motivo da recusa
        if (tipo === 'R') {
            this.openDialog('liberacoes', { acao: 'recusado' }).subscribe((response: any) => {
                if (response.retorno) {
                    // Preenchendo o atributo OBSERVACAO com o motivo da recusa
                    pedLiberacao = {
                        ...pedLiberacao,
                        OBSERVACAO: response.params.motivo,
                    };

                    this.concluiAcaoLiberacao(tipo, pedLiberacao);
                }
            });
        } else {
            this.concluiAcaoLiberacao(tipo, pedLiberacao);
        }
    }

    concluiAcaoLiberacao(tipo: string, pedLiberacao: PedidosPesquisa) {
        // Mostrando o spinner na tela
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

        this.sankhyaService.aprovaPedidoLiberacao(tipo, pedLiberacao, 'aprovaProposta').subscribe(() => {
            overlayRef.dispose();
        });
    }

    recusarPedido(acao: string) {
        // Objeto de retorno
        let retorno: { confirmaRecusa: boolean; ocor: number; pedido: PedidosPesquisa; enviaEmail: boolean };

        // Se o usuário clicar em confirmar sem escolher nenhuma opção, orientá-lo a escolher uma opção
        if (acao === 'confirmar' && this.motivoRecusa === undefined) {
            this.openSnackbar('Informe o motivo da recusa!');
        } else {
            // Senão...

            // Montar o objeto de retorno com os dados
            retorno =
                acao === 'confirmar'
                    ? {
                          confirmaRecusa: true,
                          ocor: this.motivoRecusa,
                          pedido: this.data.pedido,
                          enviaEmail: this.enviaEmail,
                      }
                    : {
                          confirmaRecusa: false,
                          ocor: null,
                          pedido: this.data.pedido,
                          enviaEmail: this.enviaEmail,
                      };

            this.bottomSheetRef.dismiss(retorno);
        }
    }

    openDialog(tipo: string, params?: any) {
        const dialogRef = this.dialog.open(DialogComponent, {
            width: '300px',
            data: { tipo, params },
        });

        return dialogRef.afterClosed();
    }

    openSnackbar(mensagem: string) {
        this.snackBar.open(mensagem, 'Fechar', {
            verticalPosition: 'top',
            horizontalPosition: 'end',
            duration: 7000,
        });
    }

    inserirParametros(acao: string) {
        this.comissao = this.comissao ? this.comissao : 0;
        let existeLimpaSaldo = false;

        this.produtos.filter( e => {
            e.LIMPASALDO == 'S' ? existeLimpaSaldo = true : false;
        })

        let limpEst: string = '';
        let limpaSaldo: string = '';
        this.limpaEstoque ? (limpEst = 'S') : (limpEst = 'N');
        existeLimpaSaldo ? (limpaSaldo = 'S') : (limpaSaldo = 'N');

        if (acao === 'confirmar') {
            const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

            this.portalJpaService
                .inserirParametrosProposta(this.data.pedido.NUNOTA, this.observacao, this.comissao, this.produtos, limpEst, limpaSaldo)
                .subscribe(
                    (ret: string) => {
                        let textoSnackBar = '';

                        if (!ret.includes('ERRO')) {
                            overlayRef.dispose();
                            this.openSnackbar('Parâmetros inseridos no pedido com sucesso!');
                        } else {
                            if (ret.includes('COM_EXISTE_ERRO')) {
                                overlayRef.dispose();
                                textoSnackBar = 'Não foi possível inserir comissão para este pedido. Já existe um registro cadastrado. ';
                            }
                            if (ret.includes('COM_ERRO')) {
                                overlayRef.dispose();
                                textoSnackBar = 'Ocorreu um erro na inserção da comissão! ';
                            }
                            if (ret.includes('OBS_ERRO')) {
                                overlayRef.dispose();
                                textoSnackBar = textoSnackBar + 'Ocorreu um erro na inserção da observação! ';
                            }
                            if (ret.includes('LIMP_ERRO')) {
                                overlayRef.dispose();
                                textoSnackBar = textoSnackBar + 'Ocorreu um erro na marcação de limpa estoque! ';
                            }
                            if (ret.includes('APROVADA_ERRO')) {
                                overlayRef.dispose();
                                textoSnackBar = 'Não foi possível inserir os parâmetros pois a proposta já foi aprovada! ';
                            }
                            overlayRef.dispose();
                            this.openSnackbar(textoSnackBar + 'Se o erro persistir, entre em contato com o TI');
                        }
                    },
                    error => {
                        overlayRef.dispose();
                        this.openSnackbar('Ocorreu um problema na comunicação com o servidor! Se o erro persistir, contacte o TI.');
                    }
                );
        }
        this.bottomSheetRef.dismiss();
    }

    marcarItem(i: number, event: any, op: string) {
        
        if (op == 'estoque') {
            if (!event.checked) {
                this.todosEst = false;
                this.limpaEstoque = false;
            }

            if (i === -1) {
                event.checked ? this.todosSaldo = false : false;
                this.produtos.forEach(produto => {
                    produto.LIMPEST = event.checked ? 'S' : 'N';
                    event.checked ? produto.LIMPASALDO = 'N' : false;
                    event.checked ? this.todosEst = true : this.todosEst = false;
                });
            } else {
                this.produtos[i].LIMPEST = event.checked ? 'S' : 'N';
                event.checked ? (this.produtos[i].LIMPASALDO = 'N') && (this.todosSaldo = false): false;

                this.produtos.find(a => a.LIMPEST == 'N') ? (this.todosEst = false) : (this.todosEst = true);
            }
        }

        if (op == 'saldo') {
            if (!event.checked) {
                this.todosSaldo = false;
            }

            if (i === -1) {
                this.limpaEstoque = false
                event.checked ? (this.todosEst = false) : false;
                this.produtos.forEach(produto => {
                    produto.LIMPASALDO = event.checked ? 'S' : 'N';
                    event.checked ? produto.LIMPEST = 'N' : false;
                    this.todosSaldo = event.checked ? true : false;
                });
            } else {
                this.produtos[i].LIMPASALDO = event.checked ? 'S' : 'N';
                event.checked ? (this.produtos[i].LIMPEST = 'N') && (this.todosEst = false) : false;

                this.produtos.find(a => a.LIMPASALDO == 'N') ? (this.todosSaldo = false) : (this.todosSaldo = true);
            }
        }
    }


    verificaComissao() {
        if (isNaN(Number(this.comissao))) {
            this.comissao = null;
        }
    }

    selectRestart() {
        this.bottomSheetRef.dismiss('restartCountdown');
    }

    selectLogout(logout) {
        this.bottomSheetRef.dismiss(logout);
    }

    eventoContador(e: Event) {
        this.tempoRestante = this.formatTempoRestante(e['left']);
        if (e['action'] == 'done') {
            this.selectLogout('logoutTimeout');
        }
    }

    formatTempoRestante = (tempo: number) => {
        if (tempo > 0) {
            let sec = Math.trunc(tempo / 1000);
            let min = 0;
            let hora = 0;

            if (sec >= 60) {
                min = Math.trunc(sec / 60);
                sec -= min * 60;

                if (min >= 60) {
                    hora = Math.trunc(min / 60);
                    min -= hora * 60;
                    return `${('0' + hora).slice(-2)}:${('0' + min).slice(-2)}:${('0' + sec).slice(-2)}`;
                }
            }

            return `${('0' + min).slice(-2)}:${('0' + sec).slice(-2)}`;
        }
    };
}
