import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-criar-pedido-observacoes',
  templateUrl: './criar-pedido-observacoes.component.html',
  styleUrls: ['./criar-pedido-observacoes.component.scss']
})
export class CriarPedidoObservacoesComponent implements OnInit {
  @Input() observacao: string;
  @Output() observacaoChange = new EventEmitter<string>();

  tamanhoMaximo: number;

  constructor() { }

  ngOnInit() {
    this.tamanhoMaximo = 1000;
    this.observacao = '';
  }

  insereObservacoes(event: any) {
    this.observacao = event.target.value;
    this.observacaoChange.emit(this.observacao);
  }

}
