import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
export class ValidacoesService {

    constructor() { }

}

export function ValidateCNPJ(control: AbstractControl): { [key: string]: any } | null {

    const tmpCnpj = control.value.replace(/[^\d]+/g, '');

    if (tmpCnpj == '' || tmpCnpj == '00000000000000') return { 'phoneNumberInvalid': true };

    if (tmpCnpj.length != 14) return { 'phoneNumberInvalid': true };

    var tamanho = tmpCnpj.length - 2
    var numeros = tmpCnpj.substring(0, tamanho);
    var digitos = tmpCnpj.substring(tamanho);
    var soma = 0;
    var pos = tamanho - 7;
    for (var i = tamanho; i >= 1; i--) {
        soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
        if (pos < 2)
            pos = 9;
    }
    var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

    if (resultado != parseInt(digitos.charAt(0))) {
        return { 'phoneNumberInvalid': true };
    }

    tamanho = tamanho + 1;
    numeros = tmpCnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (i = tamanho; i >= 1; i--) {
        soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
        if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

    if (resultado != parseInt(digitos.charAt(1))) {
        return { 'phoneNumberInvalid': true };
    }
    return null;
}
