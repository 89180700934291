import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { SpinnerComponent } from '../global-components/spinner/spinner.component';
import { SankhyaServiceService } from '../services/sankhya-service.service';
import { ComponentPortal } from '@angular/cdk/portal';
import { ConsultaCadastroCliente } from './local-interfaces/consulta-cadastro-cliente.interface';
import { Paginas, RangePaginas } from '../global-interfaces/pedidos-pesquisa-interface';
import { Router } from '@angular/router';

@Component({
    selector: 'app-consulta-cadastro-clientes',
    templateUrl: './consulta-cadastro-clientes.component.html',
    styleUrls: ['./consulta-cadastro-clientes.component.scss'],
})
export class ConsultaCadastroClientesComponent implements OnInit {
    constructor(
        public overlay: Overlay,
        private sankhyaService: SankhyaServiceService,
        public viewContainerRef: ViewContainerRef,
        private router: Router
    ) {
        this.paginas = {
            numeroInicial: RangePaginas.numeroInicial,
            numeroRegistros: RangePaginas.numeroRegistros,
            numeroFinal: RangePaginas.numeroRegistros,
        };
    }

    ngOnInit() {
        this.carregarCadastrosClientes();
    }

    cadastros: ConsultaCadastroCliente[];
    paginas: Paginas;
    busca: string;
    pesquisa: string;

    carregarCadastrosClientes() {
        this.busca = !this.pesquisa ? 'T' : this.pesquisa;

        this.busca = this.busca.toUpperCase();

        // Mostrando o spinner na tela
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

        this.sankhyaService.getCadastroClientes(this.paginas.numeroInicial, this.paginas.numeroFinal, this.busca).subscribe(
            (res: any) => {
                if (res.serviceResponse === 'OK') {
                    this.cadastros = res.response;
                } else {
                    console.log('Erro = ', res);
                }

                overlayRef.dispose();
            },
            erro => {
                console.log('Erro = ', erro);
                overlayRef.dispose();
            }
        );

        overlayRef.dispose();
    }

    refresh() {
        location.reload();
    }

    paginar(event: any) {
        this.paginas = event;
        this.carregarCadastrosClientes();
    }

    copiaCadastro(cadastro) {
        this.router.navigateByUrl('/cadastrar-cliente', { state: { copiarCadastro: JSON.stringify({ cadastro }) } });
    }
}
