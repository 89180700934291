import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { PortalServiceService } from '../services/portal-service.service';
import { DialogComponent } from '../global-components/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { SankhyaServiceService } from '../services/sankhya-service.service';
import { ProdutoTabelaPreco } from '../global-interfaces/produto-tabela-preco-interface';
import { ProdutoUnificado } from '../global-interfaces/produto-interface';
import { OverlayConfig, Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { SpinnerComponent } from '../global-components/spinner/spinner.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BottomSheetComponent } from '../global-components/bottom-sheet/bottom-sheet.component';

@Component({
  selector: 'app-tabela-de-preco',
  templateUrl: './tabela-de-preco.component.html',
  styleUrls: ['./tabela-de-preco.component.scss']
})
export class TabelaDePrecoComponent implements OnInit {

  // Produtos que compõem a tabela de preço
  produtos: ProdutoTabelaPreco[];

  produtoSelecionado: ProdutoUnificado;

  fxHideProdutos: boolean[];

  constructor(
    private portalService: PortalServiceService,
    public dialog: MatDialog,
    private sankhyaService: SankhyaServiceService,
    public overlay: Overlay,
    public viewContainerRef: ViewContainerRef,
    private bottomSheet: MatBottomSheet
  ) {
    this.calculaColunasTabela();
    this.produtos = [];
  }

  ngOnInit() {
    this.buscarProdutos(1, 30);
  }

  buscarProdutos(numeroInicial: number, numeroFinal: number) {

    // Mostrando o spinner na tela
    const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

    this.produtos = [];

    this.sankhyaService.buscaTabelaPreco(numeroInicial, numeroFinal).subscribe(
      (res: {serviceResponse: string, response: string, param: ProdutoTabelaPreco[]}) => {

        if (res.serviceResponse === 'ERROR') {
          // BottomSheet
          const param = 'atualizaTabelaDePreco';

          this.bottomSheet.open(BottomSheetComponent, {
            data: { param, erro: res },
            panelClass: 'bottom-sheet'
          });

        } else {
          /*
          ** O VLRVENDAALT precisa ser declarado para que o atributo disable do botão de salvar
          ** funcione corretamente. Para isso, o loop abaixo faz a conversão de ambos VLRVENDA e VLRVENDAALT
          ** para ficarem como números.
          */
          res.param = res.param.map(produto =>
            produto = { ...produto, VLRVENDA: Number(produto.VLRVENDA), VLRVENDAALT: Number(produto.VLRVENDA), SPINNERATIVO: false }
          );

          this.produtos = res.param;

        }
        // Removendo o spinner da tela
        overlayRef.dispose();
      }
    );
  }

  // Este método é chamado quando um produto é selecionado no autocomplete
  emitirProdutoSelecionado() {

    // Mostrando o spinner na tela
    const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

    this.sankhyaService.buscarProdutoTabelaPreco(this.produtoSelecionado)
      .subscribe((res: {serviceResponse: string, response: string, params: ProdutoTabelaPreco[]}) => {

        if (res.serviceResponse === 'ERROR') {
          // BottomSheet
          const param = 'atualizaTabelaDePreco';

          this.bottomSheet.open(BottomSheetComponent, {
            data: { param, erro: res },
            panelClass: 'bottom-sheet'
          });
        } else {
          // VLRVENDA e VLRVENDAALT precisam ser do tipo number e SPINNER precisa ser false
          res.params[0] = {
            ...res.params[0],
            VLRVENDA: Number(res.params[0].VLRVENDA),
            VLRVENDAALT: Number(res.params[0].VLRVENDA),
            SPINNERATIVO: false
          };

          this.produtos = [];
          this.produtos.push(res.params[0]);

        }
        // Removendo o spinner da tela
        overlayRef.dispose();
      });
  }

  removerProduto(produto: ProdutoTabelaPreco) {

    // Se o usuário quiser deletar o produto, ele precisa confirmar que realmente quer deletar
    this.openDialog('removerProdutoTabelaPreco', produto).subscribe(result => {
      if (result) {
        const indice = this.produtos.findIndex(item => produto.ADCODUNIPRO === item.ADCODUNIPRO);
        this.produtos.splice(indice, 1);
      }
    });
  }

  salvarProduto(produto: ProdutoTabelaPreco) {

    if (produto.VLRVENDAALT === null) {
      this.portalService.openSnackbar('Formato de preço inválido!');
      return false;
    }

    // Consertar o número caso o usuário insira mais de duas casas decimais
    produto = { ...produto, VLRVENDAALT: parseFloat(produto.VLRVENDAALT.toFixed(2)) };

    console.log(`Valor digitado: ${produto.VLRVENDAALT}`);
    const parametros = {
      adCodUniPro: produto.ADCODUNIPRO,
      vlrVendaAlt: produto.VLRVENDAALT,
      codProds: produto.CODPRODS,
      nuTab: produto.NUTAB,
      dtVigor: produto.DTVIGOR
    };

    const index = this.produtos.findIndex(produtoLoop => produtoLoop.ADCODUNIPRO === produto.ADCODUNIPRO);

    this.produtos[index] = {...this.produtos[index], SPINNERATIVO: true};

    this.sankhyaService.atualizarProdutoTabelaPreco(parametros)
      .subscribe((res: {serviceResponse: string, response: string, param: []}) => {

        if (res.serviceResponse === 'ERROR') {
          // BottomSheet
          const param = 'atualizaTabelaDePreco';

          this.bottomSheet.open(BottomSheetComponent, {
            data: { param, erro: res },
            panelClass: 'bottom-sheet'
          });

          // Se a atualizar obtiver sucesso, VLRVENDA precisa ser atualizado com o valor de VLRVENDAALT e o spinner retirado
          this.produtos[index] = { ...this.produtos[index], SPINNERATIVO: false };
        } else {
          // Se a atualizar obtiver sucesso, VLRVENDA precisa ser atualizado com o valor de VLRVENDAALT e o spinner retirado
          this.produtos[index] = { ...this.produtos[index], VLRVENDA: produto.VLRVENDAALT, SPINNERATIVO: false };
        }
      });
  }

  salvarTodosProdutos() {
    const produtosAlterados: ProdutoTabelaPreco[] = this.produtos.filter(produto => produto.VLRVENDA !== produto.VLRVENDAALT);

    // Mostrando o spinner na tela
    const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

    this.sankhyaService.atualizarProdutosTabelaPreco(produtosAlterados)
      .subscribe(res => {

        if (res.serviceResponse === 'ERROR') {
          // BottomSheet
          const param = 'atualizaTabelaDePreco';

          this.bottomSheet.open(BottomSheetComponent, {
            data: { param, erro: res },
            panelClass: 'bottom-sheet'
          });
        } else {
          // Percorrer por produtos alterados para atualizar o VLRVENDA de produtos
          produtosAlterados.map(produtoAlterado => {
            const index = this.produtos.findIndex(produto => produtoAlterado.ADCODUNIPRO === produto.ADCODUNIPRO);

            this.produtos[index] = {...this.produtos[index], VLRVENDA: produtoAlterado.VLRVENDAALT};
          });
        }


        // Removendo o spinner da tela
        overlayRef.dispose();
      });
  }

  calculaColunasTabela() {
    const larguraTela = window.innerWidth;

    // Configurando tabela
    if (larguraTela < 600) {
      this.fxHideProdutos = [true, false, false, false, false, true, true];
    } else if (larguraTela < 800) {
      this.fxHideProdutos = [true, true, false, false, false, true, true];
    } else if (larguraTela < 900) {
      this.fxHideProdutos = [true, true, true, false, true, true, true];
    } else {
      this.fxHideProdutos = [true, true, true, true, true, true, true];
    }
  }

  openDialog(tipo: string, params?: any) {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '300px',
      data: { tipo, params }
    });

    return dialogRef.afterClosed();
  }

  paginar(event: {numeroInicial: number, numeroFinal: number}) {
    const {numeroInicial, numeroFinal} = event;
    this.buscarProdutos(numeroInicial, numeroFinal);
  }

  habilitaSalvarTodos() {
    return this.produtos.some(produto => produto.VLRVENDA !== produto.VLRVENDAALT);
  }
}
