import { Overlay } from '@angular/cdk/overlay';
import { Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import Chart from 'chart.js/auto';
import { SpinnerComponent } from 'src/app/global-components/spinner/spinner.component';
import { PortalJpaService } from 'src/app/services/portal-jpa.service';
import { DadosChamados, UltimosChamados } from './local-interfaces/dados-chamados';
import { GraficoDiario } from './local-interfaces/grafico';
import { finalize } from 'rxjs/operators';
import { Response } from 'src/app/global-interfaces/response';


//import { setInterval } from 'timers';

@Component({
  selector: 'app-grafico-chamados-diario',
  templateUrl: './grafico-chamados-diario.component.html',
  styleUrls: ['./grafico-chamados-diario.component.scss']
})
export class GraficoChamadosDiarioComponent implements OnInit, OnDestroy {

  constructor(
    private portalJpaService: PortalJpaService,
    public overlay: Overlay,
    public viewContainerRef: ViewContainerRef,
    private ngZone: NgZone
  ) { }

  mostra: boolean = true;

  //Gráfico Linha Diário
  horarios: string[] = ['6h', '7h', '8h', '9h', '10h', '11h', '12h', '13h', '14h', '15h', '16h', '17h', '18h', '19h'];
  abertos: number[] = [];
  fechados: number [] = [];
  colors: string [] = ['#830219','#26213f'];
  labelsLinha: string[] = ['Abertos', 'Fechados'];
  dados: number[][] = [this.abertos, this.fechados];
  
  //Gráfico Linha Semana
  dataSem: string[] = [];
  abertosSem: number[] = [];
  fechadosSem: number [] = [];
  colorsSem: string [] = ['#830219','#26213f'];
  labelsLinhaSem: string[] = ['Abertos', 'Fechados'];
  dadosSem: number[][] = [this.abertosSem, this.fechadosSem];

  //Gráfico Linha Mes
  dataMes: string[] = [];
  abertosMes: number[] = [];
  fechadosMes: number [] = [];
  colorsMes: string [] = ['#830219','#0d8302'];
  labelsLinhaMes: string[] = ['Abertos', 'Fechados'];
  dadosMes: number[][] = [this.abertosMes, this.fechadosMes];

  //Gráfico Barra Usuarios
  usuariosTi :string []= [];
  atribuidosBarra: number[] = [];
  emAndamentoBarra: number[] = [];
  pendentesBarra: number[] = [];
  colorsBarra: string [] = ['#9e3f00','#086600', '#a09000'];
  labelsBarra: string[] = ['Atribuídos', 'Em Andamento', 'Pendentes'];
  dadosBarra: number[][] = [this.atribuidosBarra, this.emAndamentoBarra, this.pendentesBarra];

  //Gráfico Barra Setores Mensal
  setoresMensal :string [] = [];
  abertosSetoresMensal: number[] = [];
  fechadosSetoresMensal: number[] = [];
  colorsSetoresMensal: string [] = ['#830219','#26213f'];
  labelsSetoresMensal: string[] = ['Abertos', 'Fechados',];
  dadosSetoresMensal: number[][] = [this.abertosSetoresMensal, this.fechadosSetoresMensal];

   //Gráfico Barra Setores Semanal
   setoresSemanal :string [] = [];
   abertosSetoresSemanal: number[] = [];
   fechadosSetoresSemanal: number[] = [];
   colorsSetoresSemanal: string [] = ['#830219','#26213f'];
   labelsSetoresSemanal: string[] = ['Abertos', 'Fechados',];
   dadosSetoresSemanal: number[][] = [this.abertosSetoresSemanal, this.fechadosSetoresSemanal];
  
  //Gráfico Coluna Times
  fechadosTimes: number [] = [];
  abertosTimes: number [] = [];
  labelTimes: string[] = ['Sistemas', 'Infraestrutura', 'Desenvolvimento'];
  colorsTimes: string [] = ['#9e3f00', '#086600', '#a09000', '#26213f'];
  labelsColunaTimes: string[] = ['Atribuídos', 'Em Andamento', 'Pendentes', 'Fechados-dia'];
  dadosTimes: number[][] = [this.abertosTimes, this.fechadosTimes];

  //Gráfico Coluna Ano
  fechadosAno: number [] = [];
  abertosAno: number [] = [];
  labelMeses: string[] = [];
  colorsAno: string [] = ['#830219','#26213f'];
  labelsColunaAno: string[] = ['Abertos', 'Fechados'];
  dadosAno: number[][] = [this.abertosAno, this.fechadosAno];

  //Variável para setar intervalo do loop
  loopInterval : any;
  atualizacaoInterval : any;

  //Variáveis Gráficos
  linhaDiario!: any; 
  linhaSemana!: any; 
  linhaMes!: any; 
  barraUsuarios!: any;
  barraSetoresMensal!: any;
  barraSetoresSemanal!: any;
  colunaTimes!: any;
  colunaAnos!: any;

  todosGraficos: any = [
    this.linhaDiario,
    this.linhaSemana,
    this.barraUsuarios,
    this.barraSetoresMensal,
    this.barraSetoresSemanal,
    this.colunaTimes,
    this.colunaAnos
  ]

  //Configurações Padrões Gráficos
  labelsLegendas = {
      color: '#000000',
      font: {
        weight: 'bolder'
      }
    }
  

  //Series Gráficos
  seriesLinhaDiario = [];
  seriesLinhaSemanal = [];
  seriesLinhaMensal = [];
  seriesBarraUsuario = [];
  seriesBarraSetoresMensal = [];
  seriesBarraSetoresSemanal = [];
  seriesColunaTimes = [];
  seriesColunaAnos = [];

  //Tabela
  dadosChamados: DadosChamados;
  ultimosAbertos: UltimosChamados[] = [];
  ultimosFechados: UltimosChamados[] = [];
  graficoDiario: GraficoDiario [] = [];

  @ViewChild ('card1', {static: false}) private card1: ElementRef;
  @ViewChild ('card2', {static: false}) private card2: ElementRef;

  //Colunas Card Dados Chamados
  displayedColumns: string[] = ['abertosDia', 'fechadosDia', 'emAndamento', 'pendenteCliente', 'abertosSem', 'fechadosSem'];

  //Colunas Ultimos Chamados Abertos e Fechados
  colunaChamados: string[] = ['numChamado', 'descCategoria', 'descUsuario', 'descChamado'];

  ngOnInit() {

    //Gerar dias do mês corrente.
    let ano: number = new Date().getFullYear();
    let mes: number = new Date().getMonth();
    let ultimoDiaMes = new Date(ano, mes+1, 0).getDate();
    let i: number;

    let mesesAno = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];

    for (i = 1; i <= ultimoDiaMes; i++) {
      this.dataMes.push(i.toString());
    }

    const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);
    this.portalJpaService.getDadosChamados()
    .pipe( finalize(()=>{
      setTimeout(()=>{
        let view = window.document.getElementById('card1');
        view.scrollIntoView({ behavior: "smooth", block: "end"})
      }, 100);
  
    }))
    .subscribe((res: Response) => {     

      this.dadosChamados = res.response.dadosChamados;
      this.ultimosAbertos = res.response.ultimosAbertos;
      this.ultimosFechados = res.response.ultimosFechados;
      res.response.graficoBarrasUsuarios.forEach(e => {
        this.usuariosTi.push(e.NOME);
      });

      this.populaGraficoLinha(res.response.graficoLinhasDiario, this.seriesLinhaDiario, this.linhaDiario);
      this.populaGraficoLinha(res.response.graficoLinhasSemanal, this.seriesLinhaSemanal, this.linhaSemana);
      this.populaGraficoLinha(res.response.graficoLinhasMensal, this.seriesLinhaMensal, this.linhaMes);
      this.populaGraficoBarra(res.response.graficoBarrasUsuarios, this.seriesBarraUsuario, this.barraUsuarios);
      this.populaGraficoBarra(res.response.graficoBarrasSetoresMensal, this.seriesBarraSetoresMensal, this.barraSetoresMensal);
      this.populaGraficoBarra(res.response.graficoBarrasSetoresSemanal, this.seriesBarraSetoresSemanal, this.barraSetoresSemanal);
      this.populaGraficoColuna(res.response.graficoColunasTimes, this.seriesColunaTimes, this.colunaTimes);
      this.populaGraficoColuna(res.response.graficoColunasAno, this.seriesColunaAnos, this.colunaAnos);

      overlayRef.dispose();

    },
    error => {
      console.error(error);
      overlayRef.dispose();
    });

    //GRAFICO LINHA DIA
    this.linhaDiario = new Chart ('line_chamados_diarios', {
      type: 'line',
      data: {
        labels: this.horarios,
        datasets: this.seriesLinhaDiario
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'bottom',
            labels: this.labelsLegendas,
          },
          title: {
            display: true,
            text: 'Chamados Diários',
            font:{
              size: 24,
              
            },
            color: '#000000',
          },
        },
        scales:{
          x:{
            grid: {
              display: true,
              drawTicks: true,
              tickColor: 'black',
              lineWidth: .08,
              tickWidth: 1,
              color: '#000000',
              borderColor: '#000000'
            },
            ticks:{
              color: '#00000',
              font:{
                weight: 'bolder'
              }
            }
          },
          y:{
            grid:{
              drawTicks: true,
              tickColor: 'black',
              lineWidth: 2,
              tickWidth: 1,
              display: true,
              borderColor: '#000000'
            },
            ticks:{
              stepSize: 2,
              color: '#00000',
              font:{
                weight: 'bolder'
              }
            },
            beginAtZero: true
          }
        }
      },
    });

    //GRAFICO LINHA SEMANA
    this.linhaSemana = new Chart ('line_chamados_semanal', {
      type: 'line',
      data: {
        labels: this.dataSem,
        datasets: this.seriesLinhaSemanal
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'bottom',
            labels: {
              color: '#000000',
              font: {
                weight: 'bolder'
              }
            }
          },
          title: {
            display: true,
            text: 'Chamados 7 dias',
            font:{
              size: 24,
              
            },
            color: '#000000',
          },
        },
        scales:{
          x:{
            grid: {
              display: true,
              drawTicks: true,
              tickColor: 'black',
              lineWidth: .08,
              tickWidth: 1,
              color: '#000000',
              borderColor: '#000000'
            },
            ticks:{
              color: '#00000',
              font:{
                weight: 'bolder'
              }
            }
          },
          y: {
            grid:{
              drawTicks: true,
              tickColor: 'black',
              lineWidth: 2,
              tickWidth: 1,
              display: true,
              borderColor: '#000000'
            },
            ticks:{
              stepSize: 2,
              color: '#00000',
              font:{
                weight: 'bolder'
              }
            },
            beginAtZero: true
          }
        }
      } 
    })

    //GRAFICO LINHA MEN
    this.linhaMes = new Chart ('line_chamados_mensal', {
      type: 'line',
      //plugins: [ChartDataLabels],
      data: {
        labels: this.dataMes,
        datasets: this.seriesLinhaMensal
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'bottom',
            labels: this.labelsLegendas
          },
          title: {
            display: true,
            text: `Chamados ${mesesAno[new Date().getMonth()]}`,
            font:{
              size: 24,
              
            },
            color: '#000000',
          }
        },
        scales:{
          x:{
            grid: {
              display: true,
              drawTicks: true,
              tickColor: 'black',
              lineWidth: .08,
              tickWidth: 1,
              color: '#000000',
              borderColor: '#000000'
            },
            ticks:{
              stepSize: 1,
              color: '#00000',
              font:{
                weight: 'bolder'
              },
            },
            beginAtZero: true,
          },
          y:{
            grid:{
              drawTicks: true,
              tickColor: 'black',
              lineWidth: 2,
              tickWidth: 1,
              display: true,
              borderColor: '#000000'
            },
            ticks:{
              stepSize: 2,
              color: '#00000',
              font:{
                weight: 'bolder'
              }
            },
            beginAtZero: true
          }
        }
      },
    })
      
    //GRÁFICO BARRA TÉCNICOS
    this.barraUsuarios = new Chart ('barra_chamados_usuarios', {
      type: 'bar',
      data: {
        labels: this.usuariosTi,
        datasets: this.seriesBarraUsuario
      },
      options: {
        indexAxis: 'y',
        scales: {
          yAxes: {
            stacked: true,
            beginAtZero: true,
            grid:{
              display: false,
              lineWidth: .08,
              color: '#00000',
              borderColor: '#000000'
            },
            ticks:{
              color: '#00000',
              font:{
                weight: 'bolder'
              }
            }
          },
          xAxes: {
            stacked: true,
            beginAtZero: true,
            grid:{
              display: true,
              borderColor: '#000000',
              drawTicks: true,
              tickWidth: 1,
              tickColor: '#000000'
            },
            ticks:{
              stepSize: 5,
              color: '#00000',
              font:{
                weight: 'bolder'
              }
            }
          }
        },
        plugins: {
          legend: {
            position: 'bottom',
            labels: this.labelsLegendas
          },
          title: {
            display: true,
            text: 'Por Técnico',
            font: {
              size: 18
            },
            color: '#000000',
          }
        },
        responsive: true
      },
    })

    //GRÁFICO BARRA SETORES MES
    this.barraSetoresMensal = new Chart ('barra_chamados_setores_mes', {
      type: 'bar',
      data: {
        labels: [],
        datasets: []
      },
      options: {
        indexAxis: 'y',
        scales: {
          yAxes: {
            stacked: false,
            beginAtZero: true,
            grid:{
              display: false,
              lineWidth: .08,
              color: '#00000',
              borderColor: '#000000'
            },
            ticks:{
              color: '#00000',
              font:{
                weight: 'bolder'
              }
            }
          },
          xAxes: {
            stacked: false,
            beginAtZero: true,
            grid:{
              display: true,
              borderColor: '#000000',
              drawTicks: true,
              tickWidth: 1,
              tickColor: '#000000'
            },
            ticks:{
              stepSize: 5,
              color: '#00000',
              font:{
                weight: 'bolder'
              }
            }
          }
        },
        plugins: {
          legend: {
            position: 'bottom',
            labels: this.labelsLegendas
          },
          title: {
            display: true,
            text: `Setores ${mesesAno[new Date().getMonth()]}`,
            font:{
              size: 18,
              
            },
            color: '#000000',
          }
        },
        responsive: true
      },
    })

    //GRÁFICO BARRA SETORES SEMANA
    this.barraSetoresSemanal = new Chart ('barra_chamados_setores_semana', {
      type: 'bar',
      data: {
        labels: [],
        datasets: []
      },
      options: {
        indexAxis: 'y',
        scales: {
          yAxes: {  
            stacked: false,
            beginAtZero: true,
            grid:{
              display: false,
              lineWidth: .08,
              color: '#00000',
              borderColor: '#000000'
            },
            ticks:{
              color: '#00000',
              font:{
                weight: 'bolder'
              }
            }
          },
          xAxes: {
            stacked: false,
            beginAtZero: true,
            grid:{
              display: true,
              borderColor: '#000000',
              drawTicks: true,
              tickWidth: 1,
              tickColor: '#000000'
            },
            ticks:{
              stepSize: 5,
              color: '#00000',
              font:{
                weight: 'bolder'
              }
            }
          }
        },
        plugins: {
          legend: {
            position: 'bottom',
            labels: this.labelsLegendas
          },
          title: {
            display: true,
            text: 'Setores 7 dias',
            font:{
              size: 18,
              
            },
            color: '#000000',
          }
        },
        responsive: true
      },
    })

    //GRÁFICO COLUNA TIMES
    this.colunaTimes = new Chart('coluna_times', {
      type: 'bar',
      data: {
        labels: this.labelTimes,
        datasets: []
      },
      options: {
        responsive: true,
        scales: {
          yAxes: {
            stacked: false,
            beginAtZero: true,
            grid:{
              display: true,
              lineWidth: 0.2,
              color: '#00000',
              borderColor: '#000000',
              drawTicks: true,
              tickWidth: 1,
              tickColor: '#000000'
            },
            ticks:{
              stepSize: 2,
              color: '#00000',
              font:{
                weight: 'bolder'
              }
            }
          },
          xAxes: {
            stacked: false,
            beginAtZero: true,
            grid:{
              display: false,
              borderColor: '#000000'
            },
            ticks:{
              color: '#00000',
              font:{
                weight: 'bolder'
              }
            }
          }
        },
        plugins: {
          legend: {
            position: 'bottom',
            labels: this.labelsLegendas
          },
          title: {
            display: true,
            text: 'Por Times',
            font:{
              size: 18,
            },
            color: '#000000',
          }
        }
      },
    });

    //GRAFICO COLUNA ANO
    this.colunaAnos = new Chart('coluna_ano', {
      type: 'bar',
      data: 
      { 
        labels: this.labelMeses,
        datasets: []
    } ,
      options: {
        scales: {
          x: {
            stacked: false,
            grid:{
              display: false,
              borderColor: '#000000'
            },
            ticks:{
              color: '#00000',
              font:{
                weight: 'bolder'
              }
            }
          },
          y: {
            stacked: false,
            grid:{
              display: true,
              lineWidth: 0.2,
              color: '#00000',
              borderColor: '#000000',
              drawTicks: true,
              tickWidth: 1,
              tickColor: '#000000'
            },
            ticks:{
              stepSize: 50,
              color: '#00000',
              font:{
                weight: 'bolder'
              }
            }
          }
        },
        plugins: {
          legend: {
            position: 'bottom',
            labels: this.labelsLegendas
          },
          title: {
            display: true,
            text: `${new Date().getFullYear()}`,
            font:{
              size: 18,
              
            },
            color: '#000000',
          },
        }
      }
    });

    this.loopRequisições();
    
    this.atualizaGraficos();
    
  }

  ngOnDestroy() {
    clearInterval(this.loopInterval);
    clearInterval(this.atualizacaoInterval);
  }

  atualizaGraficos(){
    let view;
    this.atualizacaoInterval = setInterval(() => {
      
      if(this.mostra){
        this.mostra = false;
        view = window.document.getElementById('card2');
      } else{
        this.mostra = true;
        view = window.document.getElementById('card1');
      }
      view.scrollIntoView({ behavior: "smooth", block: "end"})

    }, 1 * 30 * 1000)
  }

  loopRequisições(){
    this.ngZone.runOutsideAngular(() => {
      this.loopInterval = setInterval(()=> {
        this.ngZone.run(() => {
          
          this.portalJpaService.getDadosChamados().subscribe((res: Response) => {    

            this.dadosChamados = res.response.dadosChamados;
            this.ultimosAbertos = res.response.ultimosAbertos;
            this.ultimosFechados = res.response.ultimosFechados;

            this.populaGraficoLinha(res.response.graficoLinhasDiario, this.seriesLinhaDiario, this.linhaDiario);
            this.populaGraficoLinha(res.response.graficoLinhasSemanal, this.seriesLinhaSemanal, this.linhaSemana);
            this.populaGraficoLinha(res.response.graficoLinhasMensal, this.seriesLinhaMensal, this.linhaMes);
            this.populaGraficoBarra(res.response.graficoBarrasUsuarios, this.seriesBarraUsuario, this.barraUsuarios);
            this.populaGraficoBarra(res.response.graficoBarrasSetoresMensal, this.seriesBarraSetoresMensal, this.barraSetoresMensal);
            this.populaGraficoColuna(res.response.graficoColunasTimes, this.seriesColunaTimes, this.colunaTimes);
          },
          error => {
            console.error(error);
          });
        });
        }, 1 * 60 * 1000);
    });
  }

  populaGraficoColuna(grafico: any, serie: any, tipoGrafico: any){

    this.seriesColunaTimes = [];
    
    let abertos = [];
    let fechados = [];
    let emAndamento = [];
    let atribuidos = [];
    let pendentes = [];
    let dados = [];
    let labels = [];
    let color = [];
    let label = [];
    let larguraBarra;

    switch (tipoGrafico){
      case this.colunaTimes:
        atribuidos = [grafico[0].SISATRIBUIDOS, grafico[0].INFRAATRIBUIDOS, grafico[0].DEVATRIBUIDOS];
        emAndamento = [grafico[0].SISEMANDAMENTO, grafico[0].INFRAEMANDAMENTO, grafico[0].DEVEMANDAMENTO];
        pendentes = [grafico[0].SISPENDENTE, grafico[0].INFRAPENDENTE, grafico[0].DEVPENDENTE];
        fechados = [grafico[0].SISFECHADO, grafico[0].INFRAFECHADO, grafico[0].DEVFECHADO];
        dados = [atribuidos, emAndamento, pendentes, fechados];
        labels = this.labelsColunaTimes;
        color = this.colorsTimes;
        label = this.labelTimes;
        larguraBarra = 35;
      break;

      case this.colunaAnos:
      this.labelMeses = []  
      grafico.forEach(e => {
          abertos.push(e.TOTALABERTOS);
          fechados.push(e.TOTALFECHADOS);
          this.labelMeses.push(e.DATA);
        });
        dados = [abertos, fechados];
        labels = this.labelsColunaAno;
        color = this.colorsAno;
        label = this.labelMeses;
        larguraBarra = 20;
      break;
    }

    labels.forEach((e,index) => {
      serie.push(
        {
          label: e,
          data: dados[index],
          backgroundColor: color[index],
          barPercentage: 1,
          barThickness: larguraBarra,
          maxBarThickness: larguraBarra-2
        }
      )
    })

    tipoGrafico.data.datasets = serie;
    tipoGrafico.data.labels = label;
    tipoGrafico.update();

  }

  populaGraficoBarra(grafico: any, serie: any, tipoGrafico: any){
    serie = [];
    let dados;
    let labels;
    let labelEixoY;
    let cores;

    switch (tipoGrafico)
    {
      case this.barraUsuarios:
        this.atribuidosBarra = [];
        this.emAndamentoBarra = [];
        this.pendentesBarra = [];  

        grafico.forEach(e => {
          this.atribuidosBarra.push(e.ATRIBUIDO);
          this.emAndamentoBarra.push(e.EMANDAMENTO);
          this.pendentesBarra.push(e.PENDENTE);
        });

        dados = [this.atribuidosBarra, this.emAndamentoBarra, this.pendentesBarra];
        labels = this.labelsBarra;
        cores = this.colorsBarra
        labelEixoY = this.usuariosTi;
      break;

      case this.barraSetoresMensal:
        this.abertosSetoresMensal = [];
        this.fechadosSetoresMensal = [];
        this.setoresMensal = [];
        
        grafico.forEach(e => {
          this.abertosSetoresMensal.push(e.ABERTOS);
          this.fechadosSetoresMensal.push(e.FECHADOS);
          this.setoresMensal.push(e.SETOR);
        });

        dados = [this.abertosSetoresMensal, this.fechadosSetoresMensal];

        labels = this.labelsSetoresMensal;
        cores = this.colorsSetoresMensal;
        labelEixoY = this.setoresMensal;
      break;

      case this.barraSetoresSemanal:
        this.abertosSetoresSemanal = [];
        this.fechadosSetoresSemanal = [];
        this.setoresSemanal = [];
        
        grafico.forEach(e => {
          this.abertosSetoresSemanal.push(e.ABERTOS);
          this.fechadosSetoresSemanal.push(e.FECHADOS);
          this.setoresSemanal.push(e.SETOR);
        });

        dados = [this.abertosSetoresSemanal, this.fechadosSetoresSemanal];

        labels = this.labelsSetoresSemanal;
        cores = this.colorsSetoresSemanal;
        labelEixoY = this.setoresSemanal;
      break;
    }

    labels.forEach((e, index)=>{
      serie.push(
        {
          label: e,
          data: dados[index],
          backgroundColor: cores[index],
          barPercentage: 1,
          categoryPercentage: 1,
          barThickness: 25,
          maxBarThickness: 23
        }
      )
    })
 
    tipoGrafico.data.labels = labelEixoY;
    tipoGrafico.data.datasets = serie;
    tipoGrafico.update();

  }

  //TODO: ajustar para popular também gráfico mensal.
  populaGraficoLinha(grafico: any, serie: any, tipoGrafico: any){
    this.abertos = [];
    this.fechados = [];
    let dias = [];
    let semana = false;
    serie = [];

    grafico.forEach(e => {
      if("DATA" in e){
        dias.push(e.DATA);
        semana = true;
      }
      this.abertos.push(e.TOTALABERTOS);
      this.fechados.push(e.TOTALFECHADOS);
    });

    if(semana){
      tipoGrafico.data.labels = dias;
      this.linhaMes.data.labels = this.dataMes;
    }

    this.dados = [this.abertos, this.fechados];

    this.labelsLinha.forEach((e, index) => {
      serie.push(
        {
          label: e,
          data: this.dados[index],
          fill: false,
          borderWidth: 2,
          borderColor: this.colors[index],
          backgroundColor: this.colors[index],
          pointBackgroundColor: this.colors[index],
        }
      )
    });

    //this.linhaDiario.data.labels = this.horarios;
    tipoGrafico.data.datasets = serie;
    this.linhaMes.data.datasets = serie;
    tipoGrafico.update(); //SETAR UPDATE EM OUTRO SETINTERVAL?

  }

}

  