import { MediaMatcher } from '@angular/cdk/layout';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Component, OnInit, ViewContainerRef, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef, MatSnackBar } from '@angular/material';
import { SpinnerComponent } from '../global-components/spinner/spinner.component';
import { PortalJpaService } from '../services/portal-jpa.service';
import { PortalServiceService } from '../services/portal-service.service';
import { SankhyaServiceService } from '../services/sankhya-service.service';
import { BottomSheetPedidosFinanceiroComponent } from './local-components/bottom-sheet-pedidos-financeiro/bottom-sheet-pedidos-financeiro.component';
import { PedidosFinanceiro } from './local-interfaces/pedidos-financeiro-interface';

@Component({
    selector: 'app-pedidos-financeiro',
    templateUrl: './pedidos-financeiro.component.html',
    styleUrls: ['./pedidos-financeiro.component.scss'],
})
export class PedidosFinanceiroComponent implements OnInit, OnDestroy {
    constructor(
        public overlay: Overlay,
        private bottomSheet: MatBottomSheet,
        public viewContainerRef: ViewContainerRef,
        public portalJpaService: PortalJpaService,
        public portalService: PortalServiceService,
        private sankhyaService: SankhyaServiceService,
        private snackBar: MatSnackBar,
        changeDetectorRef: ChangeDetectorRef,
        mediaMatcher: MediaMatcher
    ) {
        this.isPo = false;
        this.projeto = 0;

        this.mediaSS = mediaMatcher.matchMedia('(max-width: 800px)');
        this.mobileQueryListener = () => changeDetectorRef.detectChanges();
        this.mediaSS.addEventListener<'change'>('change', this.mobileQueryListener);
    }

    ngOnInit() {
        this.atualizar();
    }

    ngOnDestroy(): void {
        this.mediaSS.removeEventListener<'change'>('change', this.mobileQueryListener);
    }

    pedidos: PedidosFinanceiro[];
    projeto: number;
    isPo: boolean;

    mediaSS: MediaQueryList;
    private mobileQueryListener: () => void;

    listaColunas = ['IMP', 'PEDIDO', 'PROJETO', 'EMP', 'RAZAOSOCIAL', 'DATA', 'VLRNOTA', 'REP', 'COND', 'OBS', 'ACOES'];

    atualizar() {
        if (!this.projeto) {
            this.projeto = 0;
        }
        this.pedidos = [];
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);
        this.portalJpaService.getPedidosFinanceiro(this.projeto).subscribe(
            (res: any) => {
                if (res.serviceResponse == 'OK') {
                    res.response.map((pedido: PedidosFinanceiro) => {
                        if (pedido.RAZAOSOCIAL.length > 32 && this.mediaSS.matches) {
                            pedido.RAZAOSOCIAL = pedido.RAZAOSOCIAL.slice(0, 32) + '...';
                        }
                        this.pedidos = res.response;
                    });
                } else {
                    console.log(res.response);
                }

                overlayRef.dispose();
            },
            erro => {
                console.log(erro);
                overlayRef.dispose();
            }
        );
    }

    imprimirPedido(nuNota: number) {
        this.portalService.imprimirPedido(nuNota);
    }

    imprimirFicha(codParc: number) {
        let restUrl = 'https://portal.eurotextil.com.br/jasperserver_web02/rest_v2/reports/reports/Portal/Ficha_Cliente';
        return this.portalService.imprimirGeral({ params: { PARCEIRO: codParc }, url: restUrl + '.pdf' });
    }

    openBottomSheet(tipo: string, pedido: PedidosFinanceiro) {
        const bt: MatBottomSheetRef = this.bottomSheet.open(BottomSheetPedidosFinanceiroComponent, { data: { tipo: tipo } });
        bt.afterDismissed().subscribe(res => {
            if (res && res.tipo != 'C') {
                this.sankhyaService.verificaPedidoLiberado(pedido.NUNOTAPED).subscribe((ret: any) => {
                    if (ret.response) {
                        this.openSnackbar('Pedido já foi aprovado, reprovado ou cancelado pelo comercial. Por favor, atualize a página!');
                        return false;
                    }
                    if (res.tipo === 'A') {
                        let codGestor = res.aprov;
                        let pedidoLib = {
                            NUNOTA: pedido.NUNOTA,
                            RAZAOSOCIAL: '',
                            CGCCPF: '',
                            DTNEG: '',
                            TIPOPEDIDO: '',
                            STATUS: '',
                            CODPARC: pedido.CODPARC,
                            OBSERVACAO: '',
                            OBSERVACAOPED: '',
                            DHLIB: '',
                            LIBERACAO: [
                                {
                                    EVENTO: 1002,
                                    DESCRICAO: '',
                                    DHSOLICIT: '',
                                    VLRLIMITE: 0,
                                    VLRATUAL: 0,
                                    OBSERVACAO: '',
                                    TABELA: 'TGFCAB',
                                    SEQUENCIA: 0,
                                },
                            ],
                        };
                        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);
                        let obsPedAndNunota = res.observacao ? '[' + pedido.NUNOTA + ' - ' + res.observacao + ']' : '';
                        this.sankhyaService.aprovaPedidoLiberacao('A', pedidoLib, 'aprovaPedido', 0, false, obsPedAndNunota).subscribe(
                            () => {
                                if (codGestor) {
                                    this.sankhyaService.atualizaGestorAprovFin(codGestor, pedido.NUNOTAPED).subscribe(() => {});
                                }
                                overlayRef.dispose();
                                this.openSnackbar('Pedido APROVADO!');
                                setTimeout(() => {
                                    location.reload();
                                }, 3000);
                            },
                            erro => {
                                overlayRef.dispose();
                                console.log(erro);
                                this.openSnackbar('Erro na aprovação do pedido! Informe ao setor de TI!');
                            }
                        );
                    }
                    if (res.tipo === 'R') {
                        let pedidoLib = {
                            NUNOTAPED: pedido.NUNOTAPED,
                            OBSLIB: res.motivo,
                        };
                        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);
                        this.sankhyaService.reprovaFinanceiro(pedidoLib).subscribe(
                            () => {
                                overlayRef.dispose();
                                this.openSnackbar('Pedido REPROVADO!');
                                setTimeout(() => { location.reload() }, 4000);
                            },
                            erro => {
                                overlayRef.dispose();
                                this.openSnackbar('Erro na reprovação do pedido! Informe ao setor de TI!');
                                console.log(erro);
                            }
                        );
                    }
                });
            }
        });
    }

    openSnackbar(message: string) {
        this.snackBar.open(message, 'Fechar', {
            horizontalPosition: 'end',
            verticalPosition: 'top',
            duration: 4000,
            panelClass: 'success-dialog',
        });
    }
}
