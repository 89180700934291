import { Overlay } from '@angular/cdk/overlay';
import { SpinnerComponent } from 'src/app/global-components/spinner/spinner.component';
import { Component, OnInit, ViewContainerRef, NgZone } from '@angular/core';
import { AutenticacaoService } from 'src/app/services/autenticacao.service';
import { PortalJpaService } from 'src/app/services/portal-jpa.service';
import { ProgramacaoOperacionalFilialEs } from './local-interfaces/programacao-operacional-interface';

@Component({
    selector: 'app-programacao-operacional-filial-es',
    templateUrl: './programacao-operacional-filial-es.component.html',
    styleUrls: ['./programacao-operacional-filial-es.component.scss'],
})
export class ProgramacaoOperacionalFilialEsComponent implements OnInit {
        constructor(
            private auth: AutenticacaoService,
            public viewContainerRef: ViewContainerRef,
            private portalJpaService: PortalJpaService,
            public overlay: Overlay,
            private ngZone: NgZone
        ) {
            this.auth.autenticar();
        }
    
        ngOnInit() {
            const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);
    
            this.portalJpaService.getProgramacaoOperacional(2).subscribe((ret: any) => {
                this.programacoes = ret;
                this.programacoes.map((pedido: ProgramacaoOperacionalFilialEs) => {
                    const dateString = pedido.PREV.toString();
                    const dateParts = dateString.match(/(\d+)/g); // Extrai os componentes numéricos da data
    
                    const day = parseInt(dateParts[0], 10);
                    const month = parseInt(dateParts[1], 10) - 1; // Subtrai 1 para o mês
                    const year = parseInt(dateParts[2], 10);
                    const hours = parseInt(dateParts[3], 10);
                    const minutes = parseInt(dateParts[4], 10);
                    const seconds = parseInt(dateParts[5], 10);
    
                    const date = new Date(year, month, day, hours, minutes, seconds);
                    pedido.PREV = date;
                });
                overlayRef.dispose();
            });
            this.atualizaProgramacoes();
        }
        programacoes: ProgramacaoOperacionalFilialEs[];
    
        atualizaProgramacoes() {
            this.ngZone.runOutsideAngular(() => {
                setInterval(() => {
                    this.ngZone.run(() => {
                        this.portalJpaService.getProgramacaoOperacional(2).subscribe((ret: any) => {
                            this.programacoes = ret;
                            this.programacoes.map((pedido: ProgramacaoOperacionalFilialEs) => {
                                const dateString = pedido.PREV.toString();
                                const dateParts = dateString.match(/(\d+)/g); // Extrai os componentes numéricos da data
    
                                const day = parseInt(dateParts[0], 10);
                                const month = parseInt(dateParts[1], 10) - 1; // Subtrai 1 para o mês
                                const year = parseInt(dateParts[2], 10);
                                const hours = parseInt(dateParts[3], 10);
                                const minutes = parseInt(dateParts[4], 10);
                                const seconds = parseInt(dateParts[5], 10);
    
                                const date = new Date(year, month, day, hours, minutes, seconds);
                                pedido.PREV = date;
                            });
                        });
                    });
                }, 6 * 60 * 1000); //6 minutos
            });
        }
    
        retornaClasse(pedido: ProgramacaoOperacionalFilialEs) {
            const dtEntrada = pedido.DTNEG;
            // Obtém a data atual
            const dataAtual = new Date();
    
            // Obtém a data do dia anterior
            const dataAnterior = new Date(dataAtual);
            dataAnterior.setDate(dataAtual.getDate() - 1);
    
            // Obtém a data do anteontem
            const anteontem = new Date(dataAtual);
            anteontem.setDate(dataAtual.getDate() - 2);
    
            if (dtEntrada) {
                return 'bk-green';
            }
    
            // Verifica se a data é do dia anterior
            if (
                pedido.PREV.getDate() === dataAnterior.getDate() &&
                pedido.PREV.getMonth() === dataAnterior.getMonth() &&
                pedido.PREV.getFullYear() === dataAnterior.getFullYear()
            ) {
                return 'bk-yellow';
            }
    
            // Verifica se a data é anterior ao dia anterior
            if (pedido.PREV < dataAnterior) {
                return 'bk-red';
            }
    
            // Se não for nenhum dos casos acima, não faz nada
            return null;
        }
    }
    
