import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { TransfereServiceDialog } from 'src/app/services/transfere-dado-dialog.service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { PortalJpaService } from '../../services/portal-jpa.service';

@Component({
  selector: 'app-selecionar-modo',
  templateUrl: './selecionar-modo.component.html',
  styleUrls: ['./selecionar-modo.component.scss']
})
export class SelecionarModoComponent implements OnInit {
  tipo: any
  field: any
  myControl = new FormControl('');
  options: string[] = [];
  filteredOptions: Observable<string[]>;
  selectedValue: any;

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private bottomSheetRef: MatBottomSheetRef<SelecionarModoComponent>,
    private TransfereServiceDialog: TransfereServiceDialog,
    private PortalJpaService: PortalJpaService) { }

  ngOnInit() {
    this.PortalJpaService.getListaProdutos().subscribe(data => {
      this.options = data['response']
    })
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
  }

  private _filter(value: string): string[] {
    return this.options.filter(option => option['descrProd'].toLowerCase().includes(value) || option['descrProd'].includes(value) || String(option['codUniPro']).includes(value));
  }
  tipoDeOperacao(tipo: any) {
    if (tipo === 'qr') {
      this.bottomSheetRef.dismiss(tipo)
    }
    this.tipo = tipo
  }
  changeValue(field: any) {
    this.selectedValue = field
  }
  close() {
    this.bottomSheetRef.dismiss(this.selectedValue)
  }
}
