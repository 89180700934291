import { Parceiro } from 'src/app/global-interfaces/parceiro-interface';
import { PortalServiceService } from './../../services/portal-service.service';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Component, Input, OnInit, ViewContainerRef } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { SpinnerComponent } from 'src/app/global-components/spinner/spinner.component';

@Component({
    selector: 'app-relatorio-ficha-cliente-novo',
    templateUrl: './relatorio-ficha-cliente-novo.component.html',
    styleUrls: ['./relatorio-ficha-cliente-novo.component.scss'],
})
export class RelatorioFichaClienteNovoComponent implements OnInit {
    constructor(
        private portalService: PortalServiceService,
        private snackBar: MatSnackBar,
        public overlay: Overlay,
        public viewContainerRef: ViewContainerRef
    ) {}

    ngOnInit() {}

    @Input() restUrl: string;

    clientesRelatorio: Parceiro;
    clienteSelecionado: Parceiro = this.getParceiroVazio();
    individual: boolean = false;

    gerarPdf() {
        if (this.clienteSelecionado === undefined) {
            this.snackBar.open('Informe o Cliente no campo!', 'Fechar', {
                verticalPosition: 'top',
                horizontalPosition: 'end',
                duration: 5000,
            });
            return;
        } else {
            // Instanciando a variável que possui as configurações do spinner
            const config = new OverlayConfig();

            // Atribuindo as features do spinner
            config.positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically();

            // Definindo que o spinner ficará em uma camada acima da aplicação
            config.hasBackdrop = true;

            // Criando a camada com as configurações e atribuindo a uma constante
            const overlayRef = this.overlay.create(config);

            // Mostrando o spinner na tela
            overlayRef.attach(new ComponentPortal(SpinnerComponent, this.viewContainerRef));

            this.portalService.imprimirGeral({
                params: { PARCEIRO: this.clientesRelatorio.CODPARC, COLIGADA: this.individual },
                url: this.restUrl + '.pdf',
            });
            // Removendo o spinner da tela
            overlayRef.dispose();
        }
    }

    inserirCliente() {
        this.clientesRelatorio = Object.assign(this.clienteSelecionado);
    }

    private getParceiroVazio(): Parceiro {
        return {
            CODPARC: 0,
            NOMEPARC: '',
            RAZAOSOCIAL: '',
            CODVEND: 0,
            TIPO: '',
            NOMEEND: '',
            NUMEND: 0,
            COMPLEMENTO: '',
            NOMEBAI: '',
            NOMECID: '',
            CEP: '',
            UF: '',
            PAISEND: '',
            TELEFONE: '',
            EMAIL: '',
            IDENTINSCESTAD: '',
            CGCCPF: '',
            ATIVO: '',
            LIMCRED: 0,
            CLIENTE: '',
            FORNECEDOR: '',
            VENDEDOR: '',
            TRANSPORTADORA: '',
            BLOQUEAR: '',
            ADSITUACAOPORTAL: '',
        };
    }

    emitirClienteSelecionado(cliente: Parceiro) {
        this.clienteSelecionado = { ...cliente };
        this.inserirCliente();
    }

    clearClienteAutocomplete() {
        this.clienteSelecionado = this.getParceiroVazio();
    }
}
