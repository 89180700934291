import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { ProdutoUnificado } from '../global-interfaces/produto-interface';
import { AutenticacaoService } from '../services/autenticacao.service';
import { SankhyaServiceService } from '../services/sankhya-service.service';
import { SegurancaRelatorios } from '../global-interfaces/seguranca-interface';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BottomSheetComponent } from '../global-components/bottom-sheet/bottom-sheet.component';
import { AppComponent } from '../app.component';
import { SpinnerComponent } from '../global-components/spinner/spinner.component';
import { Overlay } from '@angular/cdk/overlay';
import { PortalJpaService } from '../services/portal-jpa.service';

@Component({
    selector: 'app-relatorios',
    templateUrl: './relatorios.component.html',
    styleUrls: ['./relatorios.component.scss'],
})
export class RelatoriosComponent implements OnInit {
    constructor(
        private auth: AutenticacaoService,
        private sankhyaService: SankhyaServiceService,
        private bottomSheet: MatBottomSheet,
        private app: AppComponent,
        private portalJPAService: PortalJpaService,
        public overlay: Overlay,
        public viewContainerRef: ViewContainerRef
    ) {
        this.auth.autenticar();

        this.sankhyaService.getSegurancaRelatorios().subscribe((res: any) => {
            if (res.serviceResponse) {
                const param = 'carregarPedidos';
                const erro = res;
                this.bottomSheet.open(BottomSheetComponent, { data: { param, erro }, panelClass: 'bottom-sheet' });

                if (res.exibeBadge) {
                    this.app.setError(res.response);
                }
            } else {
                this.relatorios = res as SegurancaRelatorios[];

                // Ordenando por ordem alfabetica
                this.relatorios.sort((a, b) => a.RELTITULO.localeCompare(b.RELTITULO));
            }
        });
    }

    listaGrupoProd = [];

    ngOnInit() {
        this.sankhyaService.getParamPedidos().subscribe((retorno: any) => {
            if (retorno.serviceResponse === 'OK') {
                this.sankhyaService.configListaEmp(retorno);
            }
        });

        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);
        this.portalJPAService.getGrupoProdUsu().subscribe(
            (res: any) => {
                this.listaGrupoProd = res.response.map((grupo: any) => {
                    return { ...grupo, isAtivo: true };
                });
                overlayRef.dispose();
            },
            (error: any) => {
                console.log(error);
                overlayRef.dispose();
            }
        );
    }

    relatorios: SegurancaRelatorios[];
}
