import { portalEuro } from 'src/assets/config.json';
import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatBottomSheet, MatSnackBar } from '@angular/material';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { PortalJpaService } from '../services/portal-jpa.service';
import { MediaMatcher } from '@angular/cdk/layout';
import { Parceiro } from '../global-interfaces/parceiro-interface';
import { BottomSheetComponent } from '../global-components/bottom-sheet/bottom-sheet.component';
import { SpinnerComponent } from '../global-components/spinner/spinner.component';
import { Overlay } from '@angular/cdk/overlay';
import { Representante } from '../global-interfaces/representante-interface';
import { ProdutoUnificado } from '../global-interfaces/produto-interface';
import { Devolucoes } from './local-interfaces/devolucao-interface';
import { Pagina } from '../global-interfaces/pagina-interface';
import { Paginas } from '../global-interfaces/pedidos-pesquisa-interface';
import { PortalServiceService } from '../services/portal-service.service';
import { SankhyaServiceService } from '../services/sankhya-service.service';
import { BottomSheetAnexosDevComponent } from './local-components/bottom-sheet-anexos-dev/bottom-sheet-anexos-dev.component';
import { AnexosDevolucao } from './local-interfaces/anexos-devolucao-interface';
import { BottomSheetProdutosDevComponent } from './local-components/bottom-sheet-produtos-dev/bottom-sheet-produtos-dev.component';
import { MensagensDevolucao } from './local-interfaces/mensagens-devolucao-interface';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { BottomSheetAprovacaoDevolucaoComponent } from './local-components/bottom-sheet-aprovacao-devolucao/bottom-sheet-aprovacao-devolucao.component';
import { ProdutosDevolucao } from './local-interfaces/produtos-devolucao-interface';
import { BottomSheetGrupoDeProdutoComponent } from '../global-components/bottom-sheet-grupo-de-produto/bottom-sheet-grupo-de-produto.component';
import { AutenticacaoService } from '../services/autenticacao.service';

@Component({
    selector: 'app-devolucao-consulta',
    templateUrl: './devolucao-consulta.component.html',
    styleUrls: ['./devolucao-consulta.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: 0 })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class DevolucaoConsultaComponent implements OnInit, OnDestroy {
    constructor(
        private sankhyaService: SankhyaServiceService,
        changeDetectorRef: ChangeDetectorRef,
        mediaMatcher: MediaMatcher,
        public portalJpaService: PortalJpaService,
        private formBuilder: FormBuilder,
        private bottomSheet: MatBottomSheet,
        public overlay: Overlay,
        public viewContainerRef: ViewContainerRef,
        private portalService: PortalServiceService,
        private snackBar: MatSnackBar,
        private http: HttpClient,
        public _d: DomSanitizer,
        private auth: AutenticacaoService
    ) {
        this.mediaSS = mediaMatcher.matchMedia('(max-width: 800px)');
        this.mobileQueryListener = () => changeDetectorRef.detectChanges();
        this.mediaSS.addEventListener<'change'>('change', this.mobileQueryListener);

        this.codRep = +auth.getCodRep();
    }

    listaGrupoProd = [];

    ngOnInit() {
        if (this.codRep !== 0) {
            this.tipoFiltro = this.tipoFiltro.filter(item => item.valor !== 'VEN');
        }

        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

        this.portalJpaService.getPermiteAprovDev().subscribe(
            (res: any) => {
                if (res.response === 'S') {
                    this.usuarioAprovar = true;
                }

                overlayRef.dispose();
            },
            (error: any) => {
                this.bottomSheet.open(BottomSheetComponent, {
                    data: {
                        param: 'consultaDevolucao',
                        mensagem: error,
                    },
                    panelClass: 'bottom-sheet',
                });

                overlayRef.dispose();
            }
        );

        this.portalJpaService.getGrupoProdUsu().subscribe(
            (res: any) => {
                this.listaGrupoProd = res.response.map((grupo: any) => {
                    return { ...grupo, isAtivo: true };
                });

                this.carregaDevolucoes();
            },
            (error: any) => {
                console.error(error);
            }
        );

        this.http.get('assets/documento.png', { responseType: 'blob' }).subscribe(img => {
            this.imgDoc = img;
        });
        this.http.get('assets/video.png', { responseType: 'blob' }).subscribe(img => {
            this.imgVid = img;
        });
    }

    ngOnDestroy(): void {
        this.mediaSS.removeEventListener<'change'>('change', this.mobileQueryListener);
    }

    mediaSS: MediaQueryList;
    private mobileQueryListener: () => void;

    devolucoes: Devolucoes[];

    devolucoesPaginadas = [];
    paginas: Paginas;
    paginaCorrente = 1;

    codRep: number;
    listaRepresentantesDev: Representante[] = [];

    formFiltros = this.formBuilder.group({
        filtroSelecionado: [null, Validators.required],
        busca: [null],
        periodoIni: [null],
        periodoFin: [null],
        checkPeriodo: [false],
    });

    tipoFiltro = [
        { descricao: 'Todos', valor: 'TDS' },
        { descricao: 'Todos em aberto', valor: 'TDA' },
        { descricao: 'Dt. emissão', valor: 'DTE' },
        { descricao: 'Dt. abertura', valor: 'DTA' },
        { descricao: 'Nr. do processo', valor: 'ID' },
        { descricao: 'NF', valor: 'NF' },
        { descricao: 'Vendedor', valor: 'VEN' },
        { descricao: 'Cliente', valor: 'CLI' },
        { descricao: 'Tipo devolução', valor: 'TD' },
        { descricao: 'Tipo aprovação', valor: 'TA' },
        { descricao: 'Status', valor: 'ST' },
        { descricao: 'Produto', valor: 'PRO' },
    ];

    QtdDias = [
        { valor: 7, descricao: 'Últimos 7 dias' },
        { valor: 15, descricao: 'Últimos 15 dias' },
        { valor: 30, descricao: 'Últimos 30 dias' },
        { valor: 60, descricao: 'Últimos 60 dias' },
        { valor: 90, descricao: 'Últimos 90 dias' },
        { valor: 120, descricao: 'Últimos 120 dias' },
    ];

    parceiroCliente: Parceiro = this.getParceiroVazio();

    tipoDevolucao = [
        { valor: 'DEV', descricao: 'Devolução' },
        { valor: 'DES', descricao: 'Desconto' },
        { valor: 'SIN', descricao: 'Sinistro' },
    ];

    tipoAprovDev = [
        { valor: 'DEV', descricao: 'Devolução' },
        { valor: 'DES', descricao: 'Desconto' },
    ];

    listaStatus = [
        { valor: 'APROVADO - AGUARDANDO NF DEVOLUCAO', descricao: 'Aprovado - Aguardando NF Devolução' },
        { valor: 'APROVADO - CONFERIDO', descricao: 'Aprovado - Conferido' },
        { valor: 'APROVADO - DESCONTO', descricao: 'Aprovado - Desconto' },
        { valor: 'APROVADO - EM CONFERENCIA', descricao: 'Aprovado - Em conferência' },
        { valor: 'APROVADO - EM TRANSPORTE', descricao: 'Aprovado - Em Transporte' },
        { valor: 'APROVADO - FINALIZADO', descricao: 'Aprovado - Finalizado' },
        { valor: 'CANCELADO', descricao: 'Cancelado' },
        { valor: 'CANCELADO POR FALTA DE RETORNO', descricao: 'Cancelado Por Falta De Retorno' },
        { valor: 'EM ABERTO', descricao: 'Em Aberto' },
        { valor: 'EM ANALISE - PENDENTE EURO', descricao: 'Em análise - Pendente Euro' },
        { valor: 'EM ANALISE - PENDENTE REPRESENTANTE', descricao: 'Em análise - Pendente Representante' },
        { valor: 'RECUSADO', descricao: 'Recusado' },
        { valor: 'REPROVADO', descricao: 'Reprovado' },
        { valor: 'SINISTRO', descricao: 'Sinistro' },
    ];

    tela = 'DEV';

    anexosSrc: AnexosDevolucao[];
    urlAnexos = this.portalJpaService.getUrlAmbiente() + portalEuro.resources.portal.devolucoes.getAnexosDevolucao;

    mensagens: MensagensDevolucao[] = [];
    loadMensagens = false;
    novaMensagem = '';
    arquivoAnexo = [];
    imgsrc = [];
    imgDoc: any;
    imgVid: any;
    @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
    desabilitaBtn = false;

    usuarioAprovar = false;
    produtosDevolucao: ProdutosDevolucao[];

    devolColumns = ['PROCESSO', 'INFO', 'STATUS'];
    expandedElement: string | null;

    verificaParamDevolucoes() {
        if (
            this.formFiltros.invalid ||
            (this.formFiltros.controls.filtroSelecionado.value == 'PRO' && this.formFiltros.controls.busca.value == null)
        ) {
            return;
        }

        let filtroSelecionado = this.formFiltros.controls.filtroSelecionado.value;

        if (filtroSelecionado === 'DTE' && this.formFiltros.controls.periodoFin.value) {
            filtroSelecionado = 'DTEP';
        }

        if (filtroSelecionado === 'DTA' && this.formFiltros.controls.periodoFin.value) {
            filtroSelecionado = 'DTAP';
        }

        if (!this.formFiltros.controls.busca.value && this.formFiltros.controls.filtroSelecionado.value !== 'TDS') {
            return;
        }

        this.carregaDevolucoes(filtroSelecionado);
    }

    carregaDevolucoes(filtroSelecionado?: String) {
        let filtro = this.formFiltros.controls.filtroSelecionado.value;

        if (filtroSelecionado) {
            filtro = filtroSelecionado;
        }

        if (filtro === 'TDS') {
            filtro = null;
        }

        let periodoIni = null;
        let periodoFin = null;

        if (this.formFiltros.controls.periodoIni.value && this.formFiltros.controls.periodoFin.value) {
            if (this.formFiltros.controls.periodoIni.value > this.formFiltros.controls.periodoFin.value) {
                this.openSnackbar('Data inicial maior que a data final!');
                return;
            }

            periodoIni = this.formFiltros.controls.periodoIni.value.toLocaleDateString();
            periodoFin = this.formFiltros.controls.periodoFin.value.toLocaleDateString();
        }

        let grpProd = '';

        this.listaGrupoProd.forEach((grupo: any) => {
            if (grpProd === '' && grupo.isAtivo) {
                grpProd = '' + grupo.CODGRUPOPROD;
            } else if (grpProd !== '' && grupo.isAtivo) {
                grpProd = `${grpProd}, ${grupo.CODGRUPOPROD}`;
            }
        });

        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

        this.portalJpaService.getDevolucoes(filtro, this.formFiltros.controls.busca.value, periodoIni, periodoFin, grpProd).subscribe(
            (res: any) => {
                if (res.serviceResponse === 'OK') {
                    res.response.map((devolucao: Devolucoes) => {
                        if (devolucao.RAZAOSOCIAL.length >= 30 && this.mediaSS.matches) {
                            devolucao.RAZAOSOCIAL = devolucao.RAZAOSOCIAL.slice(0, 30) + '...';
                        }

                        if (devolucao.RAZAOSOCIAL.length >= 35 && !this.mediaSS.matches) {
                            devolucao.RAZAOSOCIAL = devolucao.RAZAOSOCIAL.slice(0, 35) + '...';
                        }
                    });

                    this.devolucoesPaginadas = res.response;
                    this.devolucoes = this.devolucoesPaginadas.slice(0, 30);
                    this.paginaCorrente = 1;
                }

                overlayRef.dispose();
            },
            err => {
                this.bottomSheet.open(BottomSheetComponent, {
                    data: {
                        param: 'consultaDevolucao',
                        mensagem: err,
                    },
                    panelClass: 'bottom-sheet',
                });

                overlayRef.dispose();
            }
        );
    }

    selecionaFiltro() {
        this.formFiltros.controls.checkPeriodo.setValue(false);
        this.checkBoxPeriodo();
        this.clearClienteAutocomplete();

        if (this.formFiltros.controls.filtroSelecionado.value !== 'DTE' || this.formFiltros.controls.filtroSelecionado.value !== 'DTA') {
            this.formFiltros.controls.busca.setValue(null);
        }

        if (
            (this.formFiltros.controls.filtroSelecionado.value === 'DTE' || this.formFiltros.controls.filtroSelecionado.value === 'DTA') &&
            this.formFiltros.controls.busca.value === null
        ) {
            this.formFiltros.controls.busca.setValue(7);
        }

        if (this.formFiltros.controls.filtroSelecionado.value === 'VEN') {
            const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

            this.portalJpaService.getListaRepresentantesDev().subscribe(
                (res: any) => {
                    if (res.serviceResponse === 'OK') {
                        this.listaRepresentantesDev = res.response;
                    }

                    overlayRef.dispose();
                },
                err => {
                    this.bottomSheet.open(BottomSheetComponent, {
                        data: {
                            param: 'consultaDevolucao',
                            mensagem: err,
                        },
                        panelClass: 'bottom-sheet',
                    });

                    overlayRef.dispose();
                }
            );
        }

        if (this.formFiltros.controls.filtroSelecionado.value === 'TDA') {
            this.carregaDevolucoes();
        }

        if (this.formFiltros.controls.filtroSelecionado.value === 'ID' || this.formFiltros.controls.filtroSelecionado.value === 'NF') {
            for (let i = 0; i < this.listaGrupoProd.length; i++) {
                this.listaGrupoProd[i].isAtivo = true;
            }
        }
    }

    checkBoxPeriodo() {
        if (this.formFiltros.controls.checkPeriodo.value) {
            this.formFiltros.controls.busca.disable();
            this.setDatas();
        } else {
            this.formFiltros.controls.busca.enable();
            this.formFiltros.controls.periodoIni.setValue(null);
            this.formFiltros.controls.periodoFin.setValue(null);
        }
    }

    setDatas() {
        const date = new Date();
        date.setMonth(date.getMonth() - 1);
        this.formFiltros.controls.periodoIni.setValue(date);
        this.formFiltros.controls.periodoFin.setValue(new Date());
    }

    private getParceiroVazio(): Parceiro {
        return {
            CODPARC: 0,
            NOMEPARC: '',
            RAZAOSOCIAL: '',
            CODVEND: 0,
            TIPO: '',
            NOMEEND: '',
            NUMEND: 0,
            COMPLEMENTO: '',
            NOMEBAI: '',
            NOMECID: '',
            CEP: '',
            UF: '',
            PAISEND: '',
            TELEFONE: '',
            EMAIL: '',
            IDENTINSCESTAD: '',
            CGCCPF: '',
            ATIVO: '',
            LIMCRED: 0,
            CLIENTE: '',
            FORNECEDOR: '',
            VENDEDOR: '',
            TRANSPORTADORA: '',
            BLOQUEAR: '',
            ADSITUACAOPORTAL: '',
        };
    }

    emitirClienteSelecionado(cliente: Parceiro) {
        this.parceiroCliente = { ...cliente };
        this.formFiltros.controls.busca.setValue(cliente.CODPARC);
    }

    clearClienteAutocomplete() {
        this.parceiroCliente = this.getParceiroVazio();
    }

    emitirProduto(produto: ProdutoUnificado) {
        if (produto) {
            this.formFiltros.controls.busca.setValue(produto.DESCRPROD);
        } else {
            this.formFiltros.controls.busca.setValue(null);
        }
    }

    paginar(event: Pagina) {
        this.devolucoes = this.devolucoesPaginadas.slice(event.startIndex, event.endIndex + 1);
    }

    openSnackbar(message: string) {
        this.snackBar.open(message, 'Fechar', {
            horizontalPosition: 'end',
            verticalPosition: 'top',
            duration: 5000,
        });
    }

    imprimirRomaneio(numNota: number, codEmp: number) {
        this.portalService.imprimirRomaneioDevolucao(numNota, codEmp);
    }

    imprimirResumo(devId: number) {
        this.portalService.imprimirDevolucao(devId);
    }

    imprimirNf(devolucao: Devolucoes) {
        // Mostrando o spinner na tela
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

        this.sankhyaService.getPdfNotaFiscal(devolucao, 'NUMNOTA').subscribe(
            (res: any) => {
                if (res.serviceResponse === 'OK') {
                    let pdfNotaFiscal = res.pdfNotaFiscal;

                    const byteCharacters = atob(pdfNotaFiscal.PDF);
                    const byteNumbers = new Array(new TextEncoder().encode(pdfNotaFiscal.PDF).length);

                    for (let i = 0; i < new TextEncoder().encode(pdfNotaFiscal.PDF).length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }

                    const byteArray = new Uint8Array(byteNumbers);
                    const blob = new Blob([byteArray], { type: 'application/pdf' });
                    const url = URL.createObjectURL(blob);

                    window.open(url, '_blank');
                } else {
                    this.openSnackbar('Falha ao carregar anexos. Tente novamente!');
                }

                overlayRef.dispose();
            },
            erro => {
                this.openSnackbar('Falha ao carregar anexos!');
                console.error(erro);

                overlayRef.dispose();
            }
        );
    }

    exibeAnexos(devId: number) {
        this.anexosSrc = [];

        // Mostrando o spinner na tela
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

        this.portalJpaService.exibirAnexosDev(devId).subscribe(
            (res: any) => {
                if (res.serviceResponse === 'OK') {
                    res.response.map((anexo: any) => {
                        if (anexo.NOMEARQ.length >= 15 && this.mediaSS.matches) {
                            anexo.NOMEARQ = anexo.NOMEARQ.slice(0, 15) + '...';
                        }

                        if (anexo.NOMEARQ.length >= 25 && !this.mediaSS.matches) {
                            anexo.NOMEARQ = anexo.NOMEARQ.slice(0, 25) + '...';
                        }
                    });

                    this.anexosSrc = res.response;

                    this.anexosSrc.forEach((anexo, index) => {
                        if (anexo.TIPOARQ.includes('image/')) {
                            this.anexosSrc[index].URL = this.urlAnexos + anexo.ANEXOID.toString();
                        } else if (anexo.TIPOARQ.includes('video/')) {
                            this.anexosSrc[index].URL = 'assets/video.png';
                        } else {
                            this.anexosSrc[index].URL = 'assets/documento.png';
                        }
                    });

                    this.bottomSheet.open(BottomSheetAnexosDevComponent, { data: { anexos: this.anexosSrc } });
                } else {
                    this.openSnackbar('Falha ao carregar anexos. Tente novamente!');
                }

                overlayRef.dispose();
            },
            erro => {
                this.openSnackbar('Falha ao carregar anexos!');
                console.error(erro);

                overlayRef.dispose();
            }
        );
    }

    exibeProdutosDev(devId: number) {
        // Mostrando o spinner na tela
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

        this.portalJpaService.getProdutosDev(devId).subscribe(
            (res: any) => {
                if (res.serviceResponse === 'OK') {
                    this.produtosDevolucao = res.response;

                    res.response.map((produto: any) => {
                        produto.DESCRPROD;

                        if (produto.DESCRPROD.length >= 25 && this.mediaSS.matches) {
                            produto.DESCRPROD = produto.DESCRPROD.slice(0, 25) + '...';
                        }

                        if (produto.DESCRPROD.length >= 35 && !this.mediaSS.matches) {
                            produto.DESCRPROD = produto.DESCRPROD.slice(0, 35) + '...';
                        }
                    });

                    this.bottomSheet.open(BottomSheetProdutosDevComponent, {
                        data: { produtos: res.response },
                    });
                }
                overlayRef.dispose();
            },
            erro => {
                this.openSnackbar('Falha ao carregar os produtos!');
                console.error(erro);

                overlayRef.dispose();
            }
        );
    }

    carregaMensagens(devId: number) {
        if (!this.expandedElement && !this.mediaSS.matches) {
            return false;
        }

        this.mensagens = [];
        this.loadMensagens = true;
        this.portalJpaService.getMensagensDev(devId).subscribe(
            (res: any) => {
                if (res.serviceResponse === 'OK' && res.response.length > 0) {
                    let nomeUsu = res.response[0].NOMEUSU;

                    for (let i = 0; i < res.response.length; i++) {
                        if (i != 0) {
                            if (res.response[i].NOMEUSU === nomeUsu) {
                                res.response[i].NOMEUSU = '';
                            } else {
                                nomeUsu = res.response[i].NOMEUSU;
                            }
                        }
                    }
                }

                this.mensagens = res.response;
                this.loadMensagens = false;
                this.desabilitaBtn = false;
            },
            err => {
                this.openSnackbar('Falha ao carregar as mensagens!');
                console.error(err);
                this.loadMensagens = false;
            }
        );
    }

    onFileSelected(event: any) {
        event.preventDefault();

        if (this.arquivoAnexo.length >= 4) {
            this.openSnackbar('O limite de 4 anexos por mensagem foi atingido!');
            return false;
        }

        if (!event.srcElement.files[0]) {
            return false;
        }

        let anexo = event.srcElement.files[0];

        if (anexo && anexo.size > 0) {
            if (anexo.size < 5000000) {
                let img: any;
                if (anexo.type.includes('image/')) {
                    img = anexo;
                } else if (anexo.type.includes('video/')) {
                    img = this.imgVid;
                } else {
                    img = this.imgDoc;
                }

                if (img) {
                    this.arquivoAnexo.push(anexo);
                    this.imgsrc.push(window.URL.createObjectURL(img));
                } else {
                    this.openSnackbar('Tipo de arquivo não suportado!');
                }
            } else {
                this.openSnackbar('Arquivo não pode ser maior que 5MB!');
            }
        }
    }

    excluiAnexo(event: any, i: number) {
        this.arquivoAnexo.splice(i, 1);
        this.imgsrc.splice(i, 1);

        this.fileInput.nativeElement.value = null;
    }

    enviarMensagem(devId: any) {
        if (!this.novaMensagem) {
            this.openSnackbar('Digite uma mensagem!');
            return false;
        }

        const formMsgDev = new FormGroup({
            devId: new FormControl(devId),
            msg: new FormControl(this.novaMensagem),
            nomeAbrev: new FormControl(this.auth.getUserName()),
        });

        this.desabilitaBtn = true;

        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

        this.sankhyaService.enviarMensagemDev(formMsgDev.value, this.arquivoAnexo).subscribe(
            (res: any) => {
                if (res.serviceResponse === 'ERROR') {
                    this.openSnackbar('Falha ao enviar mensagem. Tente novamente!');
                } else {
                    this.novaMensagem = '';
                    this.imgsrc = [];
                    this.arquivoAnexo = [];
                    this.openSnackbar('Mensagem enviada com sucesso!');

                    this.carregaMensagens(devId);
                }

                overlayRef.dispose();
            },
            erro => {
                this.openSnackbar('Falha no envio da mensagem!');
                console.error(erro);
                overlayRef.dispose();
            }
        );
    }

    acaoDevolucao(param: string, devId: number, status?: any, percSug?: number) {
        const resultado = this.devolucoes.find(devolucao => devolucao.DEVID == devId).VLRTOTDEV;

        if (param === 'A') {
            this.bottomSheet
                .open(BottomSheetAprovacaoDevolucaoComponent, {
                    data: { tipo: 'aprovaDevolucao', vlrDesc: resultado, percSug: percSug },
                })
                .afterDismissed()
                .subscribe(result => {
                    if (result) {
                        this.concluiAcaoDevolucao('FA', result, devId);
                    }
                });
        } else if (param === 'R') {
            this.bottomSheet
                .open(BottomSheetAprovacaoDevolucaoComponent, { data: { tipo: 'reprovaDevolucao' } })
                .afterDismissed()
                .subscribe(result => {
                    if (result) {
                        this.concluiAcaoDevolucao('FR', result, devId);
                    }
                });
        } else if (param === 'C') {
            this.bottomSheet
                .open(BottomSheetAprovacaoDevolucaoComponent, { data: { tipo: 'cancelaDevolucao' } })
                .afterDismissed()
                .subscribe(result => {
                    if (result) {
                        this.concluiAcaoDevolucao(result.acao, result, devId);
                    }
                });
        }
    }

    concluiAcaoDevolucao(status: string, result: any, devId: number) {
        this.sankhyaService.aprovaReprovaDevolucao(status, result, devId).subscribe(
            (res: any) => {
                if (res.serviceResponse === 'OK') {
                    if (res.permiteAprovDev == 'S') {
                        this.portalService.openSnackbar('Processo de devolução finalizado com sucesso!');
                        this.carregaDevolucoes();
                    } else {
                        this.bottomSheet.open(BottomSheetComponent, {
                            data: {
                                param: 'limiteAprovacao',
                                mensagem: 'Limite de aprovação/reprovação/cancelamento de devolução insuficiente',
                            },
                            panelClass: 'bottom-sheet',
                        });
                    }
                } else {
                    this.bottomSheet.open(BottomSheetComponent, {
                        data: {
                            param: 'consultaDevolucao',
                            mensagem: res,
                        },
                        panelClass: 'bottom-sheet',
                    });
                }
            },
            error => {
                this.bottomSheet.open(BottomSheetComponent, {
                    data: {
                        param: 'consultaDevolucao',
                        mensagem: error,
                    },
                    panelClass: 'bottom-sheet',
                });
            }
        );
    }

    selecionaGrupoProd(event: any) {
        event.preventDefault();
        this.bottomSheet.open(BottomSheetGrupoDeProdutoComponent, { disableClose: true, data: this.listaGrupoProd });
    }
}
