import { ChangeDetectorRef, Component, Inject, OnInit, ViewContainerRef } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef, MatDialog } from '@angular/material';
import { Cobranca } from '../../local-interfaces/cobranca-interface';
import { FormControl, Validators } from '@angular/forms';
import { PortalJpaService } from 'src/app/services/portal-jpa.service';
import { Response } from 'src/app/global-interfaces/response';
import { ClipBoardService } from 'src/app/services/clip-board.service';
import { PortalServiceService } from 'src/app/services/portal-service.service';
import { DialogBasicComponent } from 'src/app/global-components/dialog-basic/dialog-basic.component';
import { DialogOcorrenciasComponent } from '../dialog-ocorrencias/dialog-ocorrencias.component';
import { PedidoCobranca } from '../../local-interfaces/pedido-cobranca-interface';
import { DialogLiberacaoComponent } from '../dialog-liberacao/dialog-liberacao.component';

@Component({
    selector: 'app-bottom-sheet-cobranca',
    templateUrl: './bottom-sheet-cobranca.component.html',
    styleUrls: ['./bottom-sheet-cobranca.component.scss'],
})
export class BottomSheetCobrancaComponent implements OnInit {
    constructor(
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
        private bottomSheetRef: MatBottomSheetRef<BottomSheetCobrancaComponent>,
        private portalJpaService: PortalJpaService,
        public changeDetectorRef: ChangeDetectorRef,
        private clipboardService: ClipBoardService,
        private portalService: PortalServiceService,
        private dialog: MatDialog
    ) {}

    ngOnInit() {
        this.cobrancas = this.data.cobrancas;
        this.pedido = this.data.pedido;

        this.cobrancas.push(this.objCobranca);

        if (this.pedido.CODSTATUS === 3 || this.pedido.CODSTATUS === 32) {
            this.maxValorCobranca = Number((this.pedido.VLRNOTA * 0.8).toFixed(2));
        } else {
            this.maxValorCobranca = this.pedido.VLRNOTA;
        }

        if (this.pedido.CODSTATUS !== 51 && this.pedido.CODSTATUS !== 32 && this.pedido.CODSTATUS !== 5) {
            this.disableBtn = true;
            this.disableLib = true;
            this.valorCobranca.disable();
        } else {
            this.setaValorMax();
            this.validaBotoes();
        }
    }

    cobrancas: Cobranca[] = [];
    objCobranca: Cobranca = {
        IDCOBAPI: null,
        NUNOTAPROPOSTA: null,
        NUNOTAPEDIDO: null,
        NUNOTAPROPOSTAJ: null,
        NUNOTANFE: null,
        USULOGIN: null,
        APIFORN: null,
        APITIPO: null,
        APIFORMA: null,
        MAXQTDPAR: 0,
        VLRCOBRAR: 0,
        DHINCLUSAO: null,
        DHEXPIRAR: null,
        STATUS: null,
        URL1: null,
        APITIPOPAGO: null,
        APIQTDPAR: null,
        APIVLRPAGO: null,
        DHPAGAMENTO: null,
        FINGERADO: null,
    };

    listaColunasDinamicas = ['VLRCOBRAR', 'MAXQTDPAR', 'DHINCLUSAO', 'DHEXPIRAR', 'STATUS', 'ACOES'];

    valorCobranca = new FormControl(0, [Validators.required, Validators.min(1)]);

    maxValorCobranca = 0;
    vlrRestante = 0;

    loadCobrancas = false;

    disableBtn = false;

    disableLib = false;

    pedido: PedidoCobranca;

    setaValorMax() {
        this.vlrRestante = this.pedido.VLRNOTA;

        if ([3, 32].includes(this.pedido.CODSTATUS)) {
            this.maxValorCobranca = Number((this.pedido.VLRNOTA * 0.8).toFixed(2));
        } else {
            this.maxValorCobranca = this.pedido.VLRNOTA;
        }

        const statusValidos: String[] = ['ATIVO', 'SUCESSO', 'CRIANDO', 'OCORRÊNCIA', 'CANCELADO'];

        this.cobrancas.forEach((cobranca: Cobranca) => {
            if (statusValidos.includes(cobranca.STATUS)) {
                this.maxValorCobranca = Number((this.maxValorCobranca - cobranca.VLRCOBRAR).toFixed(2));
                this.vlrRestante = Number((this.vlrRestante - cobranca.VLRCOBRAR).toFixed(2));
            }
        });

        this.valorCobranca.setValue(this.maxValorCobranca);

        if (this.maxValorCobranca > 0) {
            this.valorCobranca.enable();
            this.disableBtn = false;
            this.disableLib = false;
        } else {
            this.valorCobranca.setValue(0);
            if ([5, 51].includes(this.pedido.CODSTATUS)) {
                this.disableBtn = true;
                this.disableLib = true;
                this.valorCobranca.disable();
            }
        }
    }

    validaBotoes() {
        const isCobrancaValida = (cobranca: Cobranca, statusValidos: String[]) => {
            return cobranca.APIFORMA !== 'MAQUINA' && statusValidos.includes(cobranca.STATUS);
        };

        const statusEtapa1 = this.cobrancas.some((cobranca: Cobranca) =>
            isCobrancaValida(cobranca, ['ATIVO', 'CRIANDO', 'CANCELADO', 'OCORRÊNCIA'])
        );

        const statusEtapa2 = this.cobrancas.some((cobranca: Cobranca) =>
            isCobrancaValida(cobranca, ['ATIVO', 'SUCESSO', 'CRIANDO', 'CANCELADO', 'OCORRÊNCIA'])
        );

        if (this.cobrancas.length > 1) {
            if (this.cobrancas.some((cobranca: Cobranca) => cobranca.APIFORMA === 'MAQUINA')) {
                this.disableBtn = true;
                this.valorCobranca.disable();
                this.disableLib = false;
            } else if ((this.pedido.CODSTATUS === 32 && statusEtapa1) || ([5, 51].includes(this.pedido.CODSTATUS) && statusEtapa2)) {
                this.disableLib = true;
            }
        }

        let total = 0;

        this.cobrancas.forEach((cobranca: Cobranca) => {
            if (cobranca.STATUS === 'SUCESSO') {
                total += cobranca.VLRCOBRAR;
            }
        });

        if (total >= this.pedido.VLRNOTA) {
            this.disableBtn = true;
            this.disableLib = true;
        }
    }

    fechar() {
        this.bottomSheetRef.dismiss();
    }

    formataNumero() {
        if (this.valorCobranca.value > this.vlrRestante) this.valorCobranca.setValue(this.vlrRestante);
        if (this.valorCobranca.value) {
            const isInt = this.valorCobranca.value.toString().split('.');

            if (isInt[1]) {
                if (isInt[1].length > 2) this.valorCobranca.setValue(Number(this.valorCobranca.value.toFixed(2)));
            }
        }
    }

    criarCobranca() {
        if (
            this.valorCobranca.value === 0 ||
            this.valorCobranca.value < 0 ||
            !this.valorCobranca.value ||
            this.valorCobranca.value > this.vlrRestante
        )
            return false;

        this.dialog
            .open(DialogBasicComponent, {
                width: '400px',
                data: {
                    titulo: 'Confirmar Ação',
                    linhas: [
                        '*** Atenção ***',
                        `Tem certeza que deseja criar um link de pagamento para o pedido ${this.pedido.NUNOTAPROPOSTA}?`,
                    ],
                    botoes: [
                        { texto: 'Sim', retorno: true, color: 'primary' },
                        { texto: 'Não', retorno: false, color: 'accent' },
                    ],
                },
            })
            .afterClosed()
            .subscribe(
                (res: boolean) => {
                    if (res) {
                        this.loadCobrancas = true;
                        this.changeDetectorRef.detectChanges();

                        this.portalJpaService
                            .criarCobranca(
                                this.pedido.NUNOTAPROPOSTA,
                                this.pedido.NUNOTAPEDIDO,
                                'GETNET',
                                'GETPAY',
                                'LINK',
                                this.pedido.QTDPARC,
                                this.valorCobranca.value,
                                '',
                                '',
                                ''
                            )
                            .subscribe(
                                (res: Response) => {
                                    this.portalService.openSnackbar(res.response);
                                },
                                error => {
                                    console.error(error);
                                    this.loadCobrancas = false;
                                    this.changeDetectorRef.detectChanges();
                                },
                                () => {
                                    this.portalJpaService.getListaCobrancas(this.pedido.NUNOTAPROPOSTA).subscribe((res: Response) => {
                                        this.cobrancas = res.response;
                                        this.cobrancas.push(this.objCobranca);
                                        this.setaValorMax();
                                        this.validaBotoes();
                                        this.loadCobrancas = false;
                                        this.changeDetectorRef.detectChanges();
                                    });
                                }
                            );
                    }
                },
                error => {
                    console.error(error);
                }
            );
    }

    cancelarCobranca(cobranca: Cobranca) {
        this.dialog
            .open(DialogBasicComponent, {
                width: '400px',
                data: {
                    titulo: 'Confirmar Ação',
                    linhas: ['*** Atenção ***', `Tem certeza que deseja cancelar este link de pagamento ?`],
                    botoes: [
                        { texto: 'Sim', retorno: true, color: 'primary' },
                        { texto: 'Não', retorno: false, color: 'accent' },
                    ],
                },
            })
            .afterClosed()
            .subscribe((res: boolean) => {
                if (res) {
                    if (cobranca.STATUS !== 'ATIVO' && cobranca.STATUS !== 'CRIANDO' && cobranca.STATUS !== 'OCORRÊNCIA') return;

                    this.loadCobrancas = true;
                    this.changeDetectorRef.detectChanges();

                    this.portalJpaService.cancelarCobranca(cobranca.IDCOBAPI).subscribe(
                        (res: Response) => {
                            this.portalService.openSnackbar(res.response);
                        },
                        error => {
                            console.error(error);
                            this.loadCobrancas = false;
                            this.changeDetectorRef.detectChanges();
                        },
                        () => {
                            this.portalJpaService.getListaCobrancas(this.pedido.NUNOTAPROPOSTA).subscribe((res: Response) => {
                                this.cobrancas = res.response;
                                this.cobrancas.push(this.objCobranca);
                                this.setaValorMax();
                                this.validaBotoes();
                                this.loadCobrancas = false;
                                this.changeDetectorRef.detectChanges();
                            });
                        }
                    );
                }
            });
    }

    copiarTexto(link: string) {
        this.clipboardService.copyToClipboard(link).subscribe({
            next: () => {
                this.portalService.openSnackbar('Texto copiado para a área de transferência!');
            },
            error: err => {
                console.error('Erro ao copiar o texto: ', err);
                this.portalService.openSnackbar('Erro ao copiar o texto!');
            },
        });
    }

    exibeListaOcorrencias(cobranca: Cobranca) {
        this.dialog.open(DialogOcorrenciasComponent, { data: { ocorrencias: cobranca.OCORRENCIAS } });
    }

    retornaClasse(cobranca: Cobranca) {
        let classe = '';

        if (cobranca.STATUS === 'ATIVO' || cobranca.STATUS === 'CRIANDO' || cobranca.STATUS === 'OCORRÊNCIA') classe = 'bk-yellow';

        return classe;
    }

    libSeparacao() {
        const vlrRes = this.maxValorCobranca;
        this.dialog
            .open(DialogLiberacaoComponent, { disableClose: true, data: { pedido: this.pedido, vlrRestante: vlrRes } })
            .afterClosed()
            .subscribe((dialogRes: any) => {
                if (dialogRes.acao && (this.pedido.CODSTATUS === 3 || this.pedido.CODSTATUS === 32)) {
                    this.loadCobrancas = true;
                    this.changeDetectorRef.detectChanges();

                    this.portalJpaService.liberarAntecipacao(this.pedido.NUNOTAPEDIDO, dialogRes.codUsuLib).subscribe(
                        (res: Response) => {
                            this.portalService.openSnackbar(res.response);
                            this.loadCobrancas = false;
                            this.changeDetectorRef.detectChanges();
                            location.reload();
                        },
                        error => {
                            console.error(error);
                            this.loadCobrancas = false;
                            this.changeDetectorRef.detectChanges();
                        }
                    );
                }

                if (dialogRes.acao && (this.pedido.CODSTATUS === 5 || this.pedido.CODSTATUS === 51)) {
                    this.loadCobrancas = true;
                    this.changeDetectorRef.detectChanges();
                    this.portalJpaService
                        .criarCobranca(
                            this.pedido.NUNOTAPROPOSTA,
                            this.pedido.NUNOTAPEDIDO,
                            'GETNET',
                            'GETPAY',
                            'MAQUINA',
                            dialogRes.form.get('qtdParcelas').value,
                            dialogRes.form.get('valor').value,
                            dialogRes.form.get('bandeira').value,
                            dialogRes.form.get('dtPag').value.toLocaleDateString(),
                            dialogRes.form.get('NSU').value
                        )
                        .subscribe(
                            (res: Response) => {
                                this.portalService.openSnackbar(res.response);
                            },
                            error => {
                                console.error(error);
                            },
                            () => {
                                this.portalJpaService.getListaCobrancas(this.pedido.NUNOTAPROPOSTA).subscribe((res: Response) => {
                                    this.cobrancas = res.response;
                                    this.cobrancas.push(this.objCobranca);
                                    this.setaValorMax();
                                    this.validaBotoes();
                                    this.loadCobrancas = false;
                                    this.changeDetectorRef.detectChanges();
                                });
                            }
                        );
                }
            });
    }
}
