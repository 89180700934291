import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { Parceiro } from '../global-interfaces/parceiro-interface';
import { Bancos, TitulosParceiros } from '../global-interfaces/titulos-parceiros-interface';
import { SankhyaServiceService } from '../services/sankhya-service.service';
import { DatePipe } from '@angular/common';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { SpinnerComponent } from '../global-components/spinner/spinner.component';
import { PortalServiceService } from '../services/portal-service.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BottomSheetComponent } from '../global-components/bottom-sheet/bottom-sheet.component';

@Component({
    selector: 'app-segunda-via-boleto-snk',
    templateUrl: './segunda-via-boleto-snk.component.html',
    styleUrls: ['./segunda-via-boleto-snk.component.scss'],
})
export class SegundaViaBoletoComponentSNK implements OnInit {
    parceiroCliente: Parceiro;
    coligados: boolean;
    emailCliente: string;
    dataIni: string;
    dataFim: string;
    titulosParceiro: TitulosParceiros[];
    titulosSelecionadosEmail: TitulosParceiros[];
    fxHideTabelaTitulos: boolean[];
    enviaEmail: boolean;
    preVisualizaBoleto: boolean;
    filtroBancos: Bancos[];

    constructor(
        private sankhyaService: SankhyaServiceService,
        private datePipe: DatePipe,
        public overlay: Overlay,
        public viewContainerRef: ViewContainerRef,
        private portalService: PortalServiceService,
        private bottomSheet: MatBottomSheet
    ) {
        this.inicializarVariavel();
        this.calculaColunasTabela();
    }

    ngOnInit() {}

    buscaBoletos() {
        // Um cliente precisa ser selecionado
        if (this.parceiroCliente.CODPARC === 0) {
            this.portalService.openSnackbar('Informe o cliente!');
            return false;
        }

        // Se em algum momento as datas virou null, ela precisa voltar para string vazia;
        if (this.dataIni === null) {
            this.dataIni = '';
        }

        if (this.dataFim === null) {
            this.dataFim = '';
        }

        // A data inicial não pode ser posterior à data final
        let dataIni: any;
        let dataFim: any;

        dataIni = new Date(this.dataIni);
        dataFim = new Date(this.dataFim);

        if (dataIni > dataFim) {
            this.portalService.openSnackbar('O Vencimento Inicial é maior que o Vencimento Final!');
            return false;
        }

        // Formatar data para o padrão brasileiro
        dataIni = this.dataIni ? this.datePipe.transform(this.dataIni, 'dd/MM/yyyy') : '';
        dataFim = this.dataFim ? this.datePipe.transform(this.dataFim, 'dd/MM/yyyy') : '';

        // Instanciando a variável que possui as configurações do spinner
        const config = new OverlayConfig();

        // Atribuindo as features do spinner
        config.positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically();

        // Definindo que o spinner ficará em uma camada acima da aplicação
        config.hasBackdrop = true;

        // Criando a camada com as configurações e atribuindo a uma constante
        const overlayRef = this.overlay.create(config);

        // Mostrando o spinner na tela
        overlayRef.attach(new ComponentPortal(SpinnerComponent, this.viewContainerRef));

        this.sankhyaService.getTitulosCliente(this.parceiroCliente, dataIni, dataFim, this.coligados).subscribe(
            (res: TitulosParceiros[]) => {
                // Percorrer titulos que estão em Banco (não Carteira) por response e atribuir ENVIAREMAIL para false
                res = res.filter(tit => tit.CODBCO < 900).map(titulo => (titulo = { ...titulo, ENVIAREMAIL: false }));

                this.titulosParceiro = res;

                this.calculaColunasTabela();
                this.filtrarBancos();

                // Removendo o spinner da tela
                overlayRef.dispose();
            },

            erro => {
                // Removendo o spinner da tela
                overlayRef.dispose();

                const param = 'segundaViaBoletos';

                this.bottomSheet.open(BottomSheetComponent, {
                    data: { param, erro },
                    panelClass: 'bottom-sheet',
                });
            }
        );
    }

    enviarEmail() {
        // O e-mail precisa ter um "@"
        if (!this.emailCliente.includes('@') && this.enviaEmail) {
            this.portalService.openSnackbar('O e-mail informado é inválido!');
            return false;
        }

        // Pelo menos um título precisa ser selecionado (ENVIAREMAIL atribuido a true)
        const existeTituloSelecionado = this.titulosParceiro.find(tituloParceiro => tituloParceiro.ENVIAREMAIL);

        // Se nenhum título for selecionado...
        if (!existeTituloSelecionado) {
            this.portalService.openSnackbar('Selecione um título para enviar o e-mail!');
            return false;
        }

        // Percorrer pelos títulos do parceiro e recuperar apenas aqueles onde ENVIAREMAIL === true
        this.titulosSelecionadosEmail = this.titulosParceiro.filter(tituloParceiro => tituloParceiro.ENVIAREMAIL);

        // Se houver títulos de Bancos diferentes selecionados
        const isTitulosBancosDif = this.titulosSelecionadosEmail.filter(titulo => {
            return this.titulosSelecionadosEmail.find(tit => tit.CODBCO !== titulo.CODBCO);
        });

        if (isTitulosBancosDif.length > 0) {
            this.portalService.openSnackbar('Somente é permitido gerar Boleto do mesmo Banco. Selecione Títulos do mesmo Banco.');
            return false;
        }

        // Instanciando a variável que possui as configurações do spinner
        const config = new OverlayConfig();

        // Atribuindo as features do spinner
        config.positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically();

        // Definindo que o spinner ficará em uma camada acima da aplicação
        config.hasBackdrop = true;

        // Criando a camada com as configurações e atribuindo a uma constante
        const overlayRef = this.overlay.create(config);

        // Mostrando o spinner na tela
        overlayRef.attach(new ComponentPortal(SpinnerComponent, this.viewContainerRef));

        // Fazer a requisição ao backend
        this.sankhyaService
            .segundaViaBoletoSankhya(this.emailCliente, this.titulosSelecionadosEmail, this.enviaEmail, this.parceiroCliente.RAZAOSOCIAL)
            .subscribe(
                res => {
                    // Removendo o spinner da tela
                    overlayRef.dispose();

                    this.portalService.openSnackbar('Boleto processado com sucesso!');

                    if (!this.preVisualizaBoleto) return;
                    const byteCharacters = atob(res.pdf);
                    const byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    const blob = new Blob([byteArray], { type: 'application/pdf' });
                    const url = URL.createObjectURL(blob);
                    window.open(url, '_blank');
                },
                erro => {
                    // Removendo o spinner da tela
                    overlayRef.dispose();

                    const param = 'envioEmailSegundaVia';

                    this.bottomSheet.open(BottomSheetComponent, {
                        data: { param, erro },
                        panelClass: 'bottom-sheet',
                    });
                }
            );
    }

    selecionaTodosBoletos(event: any, codBco) {
        // Percorrer pelo vetor de titulos e colocar tudo TRUE
        this.titulosParceiro.filter(tit => tit.CODBCO === codBco).map(tituloParceiro => (tituloParceiro.ENVIAREMAIL = event.checked));
    }

    filtrarBancos() {
        let filtroTmp: Bancos[] = [];
        filtroTmp = this.titulosParceiro.map(titulo => Object.assign({ CODBCO: titulo.CODBCO, NOMEBCO: titulo.NOMEBCO }));

        this.filtroBancos = filtroTmp.filter((banco, index, array) => {
            return array.findIndex(t => t.CODBCO == banco.CODBCO) == index;
        });
    }

    inicializarVariavel() {
        // Cliente
        this.parceiroCliente = {
            CODPARC: 0,
            NOMEPARC: '',
            RAZAOSOCIAL: '',
            CODVEND: 0,
            TIPO: '',
            NOMEEND: '',
            NUMEND: 0,
            COMPLEMENTO: '',
            NOMEBAI: '',
            NOMECID: '',
            TELEFONE: '',
            EMAIL: '',
            IDENTINSCESTAD: '',
            CGCCPF: '',
            ATIVO: '',
            LIMCRED: 0,
            CLIENTE: '',
            FORNECEDOR: '',
            VENDEDOR: '',
            TRANSPORTADORA: '',
            BLOQUEAR: '',
        };

        this.emailCliente = '';
        this.dataIni = '';
        this.dataFim = '';
        this.titulosParceiro = [];
        this.titulosSelecionadosEmail = [];
        this.coligados = false;
        this.enviaEmail = true;
        this.preVisualizaBoleto = false;
        this.filtroBancos = [];
    }

    calculaColunasTabela() {
        const larguraTela = window.innerWidth;

        if (larguraTela < 600) {
            this.fxHideTabelaTitulos = [true, true, false, false, false, false, true, true, false];
            return false;
        }

        if (larguraTela < 900) {
            this.fxHideTabelaTitulos = [true, true, false, true, true, false, true, true, true];
            return false;
        }

        this.fxHideTabelaTitulos = [true, true, true, true, true, true, true, true, true];
    }
}
