import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { OcorrenciaCobranca } from '../../local-interfaces/cobranca-interface';

@Component({
    selector: 'app-dialog-ocorrencias',
    templateUrl: './dialog-ocorrencias.component.html',
    styleUrls: ['./dialog-ocorrencias.component.scss'],
})
export class DialogOcorrenciasComponent implements OnInit {
    constructor(public dialogRef: MatDialogRef<DialogOcorrenciasComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

    ngOnInit() {
        this.ocorrencias = this.data.ocorrencias;
    }

    ocorrencias: OcorrenciaCobranca[] = [];

    listaColunasDinamicas = ['APIQTDPAR', 'APITIPOPAGO', 'BANDEIRA', 'DHINCLUSAO', 'MSGERRO1', 'STATUS'];
    listaColunasDinamicasNomes = ['Qtd. Parcelas', 'Tip. Pagamento', 'Bandeira', 'Dt/Hr. Inc', 'Obs', 'Status'];

    fechar() {
        this.dialogRef.close();
    }
}
