import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Component, AfterViewInit, Inject, ElementRef, ViewChild } from '@angular/core';

@Component({
    selector: 'app-bottom-sheet-download',
    templateUrl: './bottom-sheet-download.component.html',
    styleUrls: ['./bottom-sheet-download.component.scss'],
})
export class BottomSheetDownloadComponent implements AfterViewInit {
    constructor(
        private sanitizer: DomSanitizer,
        public bottomSheetRef: MatBottomSheetRef<any>,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
    ) {
        this.urlSegura = this.sanitizer.bypassSecurityTrustResourceUrl(data.url);
    }
    urlSegura: SafeResourceUrl;

    @ViewChild('a', { static: false }) a: ElementRef;

    ngAfterViewInit() {
        this.a.nativeElement.click();
        this.bottomSheetRef.dismiss();
    }
}
