import { Component, OnInit, AfterViewInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Overlay } from '@angular/cdk/overlay';
import { concatMap } from 'rxjs/operators';
import { NgxGalleryAnimation } from 'ngx-gallery';

import { AppComponent } from '../app.component';
import { SpinnerComponent } from '../global-components/spinner/spinner.component';
import { DialogComponent } from '../global-components/dialog/dialog.component';
import { ProdutoUnificado } from '../global-interfaces/produto-interface';
import { ImagemItem } from '../global-interfaces/imagem-item-interface';
import { NumeroPO } from '../criar-pedido/local-interfaces/numero-po-interface';
import { DialogCompartilharComponent } from './local-components/dialog-compartilhar/dialog-compartilhar.component';
import { SankhyaServiceService } from '../services/sankhya-service.service';
import { PortalJpaService } from '../services/portal-jpa.service';
import { PortalServiceService } from '../services/portal-service.service';
import { MatBottomSheet } from '@angular/material';
import { BottomSheetEditarListaImgComponent } from './local-components/bottom-sheet-editar-lista-img/bottom-sheet-editar-lista-img.component';
import { AutenticacaoService } from '../services/autenticacao.service';

@Component({
    selector: 'app-galeria-imagens',
    templateUrl: './galeria-imagens.component.html',
    styleUrls: ['./galeria-imagens.component.scss'],
})
export class GaleriaImagensComponent implements OnInit, AfterViewInit {
    constructor(
        private app: AppComponent,
        private dialog: MatDialog,
        public overlay: Overlay,
        private route: ActivatedRoute,
        private router: Router,
        private sankhyaService: SankhyaServiceService,
        public viewContainerRef: ViewContainerRef,
        private portalJpaService: PortalJpaService,
        private portalService: PortalServiceService,
        private bottomsheet: MatBottomSheet,
        private auth: AutenticacaoService
    ) {
        this.esvaziarImagens();

        // Se o APP entrou nesta rota com o  extras.state.parametros setado, Este valor deve ser usado na pesquisa
        const nav = this.router.getCurrentNavigation();
        if (nav && nav.extras && nav.extras.state && nav.extras.state.parametros) {
            this.parametrosRota = JSON.parse(nav.extras.state.parametros);
        }

        this.codRep = +auth.getCodRep();
    }

    ngOnInit() {
        this.route.queryParams.subscribe(item => {
            if (item.refresh && +item.refresh > 0) {
                // query param refresh esta sendo usado quando precisa recarregar a rota
                const nav = this.router.getCurrentNavigation();
                if (nav && nav.extras && nav.extras.state && nav.extras.state.parametros) {
                    this.parametrosRota = JSON.parse(nav.extras.state.parametros);
                    this.inicializaComParametrosRota();
                }
            }
        });

        this.sankhyaService.getParamPedidos().subscribe((res: any) => {
            if (res.serviceResponse === 'OK') {
                this.sankhyaService.configListaEmp(res);

                this.listaEmpresas = this.sankhyaService.listaEmpresas;
                this.nomeEmpresas = this.sankhyaService.nomeEmpresas;
                this.codEmp = !this.listaEmpresas ? 1 : +this.listaEmpresas[0];
                this.carregaProjetos();
            }
        });
    }

    ngAfterViewInit(): void {
        this.inicializaComParametrosRota();
    }

    tipoPedido: string;

    // Atributo que vai armazenar o produto com suas URLs dos endereços das imagens
    imagens: ImagemItem[];

    // Atributo que vai receber o produto selecionado
    produtoSelecionado: ProdutoUnificado;
    produtosPO: ProdutoUnificado[];

    // Atributo que guarda as configurações da galeria
    galleryOptions: any;

    // Atributo que guarda o PO selecionado no select
    projetoSelecionado: NumeroPO;

    parametrosRota: any;
    projetos: NumeroPO[];
    imgUrls = [];
    value: any;

    listaEmpresas: number[] = [];
    nomeEmpresas: any;
    codEmp: number;

    //Código do projeto
    codproj: any;

    //Nome do arquivo gerado pelo jaspersoft
    nameReport: String;

    //Variavel para armazenar estado do botão "GerarBook"

    gerarBook: boolean = true;

    codRep: number;

    botaoGerarBookDisable = false;

    inicializaComParametrosRota() {
        if (this.parametrosRota && this.parametrosRota.aba) {
            if (this.parametrosRota.aba === 'pronta entrega') {
                this.sankhyaService.loadConfigSemRecarregar('itemRelatorio');
                this.sankhyaService.getProdutoAutocomplete(this.parametrosRota.codUniPro.toString(), true, 1, 0).subscribe(
                    (res: ProdutoUnificado[]) => {
                        this.produtoSelecionado = res[0];
                        this.exibirImagens('pe', null);
                    },
                    error => {
                        console.log('Erro getProdutoAutocomplete no inicializaComParametrosRota');
                    }
                );
            }
        }
    }

    exibirImagens(tipoPedido: string, params: any) {
        this.tipoPedido = tipoPedido;

        // Mostrando o spinner na tela
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

        // Esvaziando o objeto
        this.esvaziarImagens();

        this.gerarBook = true;

        if (tipoPedido === 'pe') {
            // Buscar as imagens no servidor...
            this.sankhyaService.getImagensProduto([this.produtoSelecionado], 'pe').subscribe(
                (response: any) => {
                    // Caso a imagem não possua imagens, mostrar dialog na tela
                    if (!response.sucesso) {
                        // Removendo o spinner da tela
                        overlayRef.dispose();

                        // Mostrar a caixa de diálogo para o usuário
                        this.dialog.open(DialogComponent, {
                            width: '300px',
                            data: { tipo: 'itemSemImagens' },
                        });
                        return;
                    }

                    // Quebrar o objeto response
                    const { ADCODUNIPRO, DESCRPROD, urls } = response.itens;

                    // Produto que não tem imagem não aparece
                    if (Array.isArray(urls) && urls.length === 0) {
                        // Removendo o spinner da tela
                        overlayRef.dispose();

                        // Mostrar a caixa de diálogo para o usuário
                        this.dialog.open(DialogComponent, {
                            width: '300px',
                            data: { tipo: 'itemSemImagens' },
                        });
                        return;
                    }
                    // Ordenar as imagens pelo CONTROLE
                    urls.sort((a, b) => {
                        if (a.description < b.description) return -1;
                        if (a.description > b.description) return +1;
                        return 0;
                    });

                    // Atribuir imagens com o código e a descrição do produto
                    this.imagens.push({ ADCODUNIPRO, DESCRPROD, urls: [] });

                    // Populando as URL's
                    urls.forEach((url: any, index: number) => {
                        const configuracoes = [
                            {
                                width: '300px',
                                height: '200px',
                                thumbnailsColumns: 4,
                                imageAnimation: NgxGalleryAnimation.Slide,
                                startIndex: index,
                                thumbnails: false,
                                imageArrows: false,
                                previewCloseOnClick: true,
                                previewCloseOnEsc: true,
                                previewKeyboardNavigation: true,
                                previewAnimation: true,
                                // previewDownload: true,
                                previewZoom: true,
                                imageDescription: true,
                            },
                            // Telas menores que 330px
                            {
                                breakpoint: 330,
                                width: '100%',
                                imagePercent: 80,
                                thumbnailsPercent: 20,
                                thumbnailsMargin: 20,
                                thumbnailMargin: 20,
                            },
                        ];

                        // Populando a variável que vai ser usada pelo ngx-gallery
                        // this.galleryOptions.push(configuracoes);
                        url.ngxOptions = configuracoes;
                        // Populando imagens com as urls
                        this.imagens[0].urls.push(url);
                    });

                    // Populando imagens com as urls
                    // this.imagens[0].urls = response.itens.urls;

                    // Removendo o spinner da tela
                    overlayRef.dispose();
                },
                erro => {
                    console.log('retorno api getImagensProduto PE', erro);

                    // Removendo o spinner da tela
                    overlayRef.dispose();
                }
            );
        } else {
            // const projeto = params.CODPROJ;
            const projeto = params;
            this.codproj = params;
            const getProduto = this.sankhyaService.getProdutoAutocomplete('0', true, 2, projeto);
            // const buscaImagensPO = getProduto.pipe(concatMap(response => this.sankhyaService.getImagensProduto(response, 'po', projeto)));
            const buscaImagensPO = getProduto.pipe(
                concatMap(response => {
                    this.produtosPO = response;
                    return this.sankhyaService.getImagensProduto(response, 'po', projeto);
                })
            );

            buscaImagensPO.subscribe(
                (responseImagens: any) => {
                    if (!responseImagens.sucesso) {
                        // Removendo o spinner da tela
                        overlayRef.dispose();

                        // Mostrar a caixa de diálogo para o usuário
                        this.dialog.open(DialogComponent, {
                            width: '300px',
                            data: { tipo: 'itemSemImagens' },
                        });
                        return;
                    }

                    responseImagens.itens.forEach((responseImagem: ImagemItem, indexImagem: number) => {
                        // Quebrar o objeto response
                        const { ADCODUNIPRO, DESCRPROD, urls } = responseImagem;

                        // Produto que não tem imagem não aparece
                        if (Array.isArray(urls) && urls.length === 0) {
                            // Removendo o spinner da tela
                            overlayRef.dispose();

                            // Mostrar a caixa de diálogo para o usuário
                            this.dialog.open(DialogComponent, {
                                width: '300px',
                                data: { tipo: 'itemSemImagens' },
                            });
                            return;
                        }
                        // Ordenar as imagens pelo CONTROLE
                        urls.sort((a, b) => {
                            if (a.description < b.description) return -1;
                            if (a.description > b.description) return +1;
                            return 0;
                        });

                        // Atribuir imagens com o código e a descrição do produto
                        this.imagens.push({ ADCODUNIPRO, DESCRPROD, urls: [] });

                        // Populando as URL's
                        responseImagem.urls.forEach((url: any, indexUrl: number) => {
                            const configuracoes = [
                                {
                                    width: '300px',
                                    height: '200px',
                                    thumbnailsColumns: 4,
                                    imageAnimation: NgxGalleryAnimation.Slide,
                                    startIndex: indexUrl,
                                    thumbnails: false,
                                    imageArrows: false,
                                    previewCloseOnClick: true,
                                    previewCloseOnEsc: true,
                                    previewKeyboardNavigation: true,
                                    previewAnimation: true,
                                    previewDownload: true,
                                    previewZoom: true,
                                    imageDescription: true,
                                },
                                // Telas menores que 330px
                                {
                                    breakpoint: 330,
                                    width: '100%',
                                    imagePercent: 80,
                                    thumbnailsPercent: 20,
                                    thumbnailsMargin: 20,
                                    thumbnailMargin: 20,
                                },
                            ];

                            // Populando a variável que vai ser usada pelo ngx-gallery
                            url.ngxOptions = configuracoes;

                            // Populando imagens com as urls
                            this.imagens[indexImagem].urls.push(url);
                        });
                    });

                    // Removendo o spinner da tela
                    overlayRef.dispose();
                },

                erro => {
                    console.log('retorno api getImagensProduto PO', erro);
                    // Removendo o spinner da tela
                    overlayRef.dispose();
                }
            );
        }
    }

    toggleAbreImagens(acao: string) {
        acao === 'abriu' ? this.app.setGaleriaAberta(true) : this.app.setGaleriaAberta(false);
    }

    esvaziarImagens() {
        this.imagens = [];
        this.galleryOptions = [];
    }

    clearProdutoSelecionado() {
        // Esvaziando o objeto selecionado
        this.produtoSelecionado = {
            ADCODUNIPRO: 0,
            DESCRPROD: '',
            CONTROLE: [],
            CODVOL: '',
            PESOBRUTO: 0,
            PESOLIQ: 0,
            QTDNEG: 0,
            ADPERCTOLPEDCOM: 0,
            ADMULTIVEN: 0,
            VLRVENDA: 0,
            VLRVENDAALT: 0,
        };

        // Esvaziar as imagens da tela
        this.esvaziarImagens();
    }

    openDialog() {
        const dialogRef = this.dialog.open(DialogCompartilharComponent, {
            disableClose: true,
            minWidth: '400px',
            minHeight: '200px',
            data: this.imgUrls,
        });

        return dialogRef.afterClosed();
    }

    enviaImage() {
        this.imgUrls = [];
        this.imagens[0].urls.forEach(element => {
            this.imgUrls.push({ image: element.big, descr: element.description });
        });
        this.openDialog();
    }

    getNomeEmp(codEmp: number) {
        let ret: any = this.nomeEmpresas.find(emp => emp.CODEMP === codEmp);
        return ret.NOMEFANTASIA;
    }

    carregaProjetos() {
        this.sankhyaService.getPojetosAtivosPorEmpresa(this.codEmp).subscribe((retorno: NumeroPO[]) => {
            this.projetos = retorno;
        });
    }

    insertImageUrls(tipoBook: string, adCodUniProSelecionado: string, listaBottomSheet?: ImagemItem[]) {
        let lista = this.imagens;

        if (listaBottomSheet) {
            lista = listaBottomSheet;
        }

        this.botaoGerarBookDisable = true;

        // Mostrando o spinner na tela
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

        try {
            if (tipoBook == 'pe') {
                let emp = ['Matriz', 'Filial ES', 'Filial PE', 'Filial SC'];
                lista[0].urls.forEach(element => {
                    const splitDescription = element.description.split('-');
                    let saldo: number = element.saldo
                        ? parseFloat(element.saldo.toString().replace(',', '.'))
                        : parseFloat(splitDescription[splitDescription.length - 1].split('/')[0].trim());
                    // if (splitDescription.length > 0) {
                    //     saldo = parseFloat(splitDescription[splitDescription.length - 1].split('/')[0].trim());
                    // }
                    this.imgUrls.push({
                        image: element.big.replace(
                            'https://portal.eurotextil.com.br/images/IMAGENS%20PORTAL/Pronta%20Entrega/',
                            'D:/Portal/Dropbox/eurotextil/images/IMAGENS PORTAL/Pronta Entrega/'
                        ),
                        descr:
                            splitDescription[0] +
                            ' - ' +
                            splitDescription[1] +
                            ' - ' +
                            this.formataSaldo(saldo) +
                            '/' +
                            this.produtoSelecionado.CODVOL,
                        codunipro: lista[0].ADCODUNIPRO,
                        codproj: null,
                        saldo,
                        emp: emp[this.codEmp - 1].replace(' ', '%20'),
                        usu: this.auth.getUserName(),
                    });
                });
                this.portalJpaService.insertBookUrl(this.imgUrls, this.produtoSelecionado.ADCODUNIPRO).subscribe(
                    (value: any) => {
                        const url = `https://portal.eurotextil.com.br/images/bookdinamico/${value.hash}/${value.fileName}`;
                        let contador = 0;
                        let checkPoint = 0;
                        const verificaTam = setInterval(() => {
                            this.portalJpaService.getFaixaPedidoTamanho(value.hash + '/' + value.fileName).subscribe((resFileSize: any) => {
                                contador++;
                                if (resFileSize.fileSize == false) {
                                    if (contador > 11) {
                                        // Cada passagem neste check point equivale aproximadamente 30 segundos com timeout 2 mil milesecs
                                        contador = 0;
                                        checkPoint++;
                                        this.portalService.openSnackbar('Aguarde, gerando o Book dinamico! ' + checkPoint, 3000, 'bottom');
                                    }
                                } else if (resFileSize.fileSize == true) {
                                    clearInterval(verificaTam);
                                    this.botaoGerarBookDisable = false;

                                    setTimeout(() => {
                                        // Adia a abertura do Link para que não apresente "Erro Falha ao carregar documento PDF."
                                        window.open(url, '_blank');
                                        overlayRef.dispose();
                                    }, 1000);
                                }
                                if (checkPoint > 6) {
                                    // aproximadamente 2min e 30sec
                                    this.botaoGerarBookDisable = false;
                                    overlayRef.dispose();
                                    this.portalService.openSnackbar(
                                        'Ultrapassou o tempo de geração do Book dinamico! ' + checkPoint,
                                        3000,
                                        'bottom'
                                    );
                                    clearInterval(verificaTam);
                                }
                            });
                        }, 2000);
                    },
                    (err: any) => {
                        console.log('Erro na geração do book', err);
                        this.portalService.openSnackbar('Erro na geração do book: ' + err.message, 6000, 'top');
                        this.botaoGerarBookDisable = false;
                        overlayRef.dispose();
                    }
                );
            } else {
                lista.forEach(element => {
                    if (adCodUniProSelecionado && element['ADCODUNIPRO'] == adCodUniProSelecionado) {
                        const produtoPOSelecionado = this.produtosPO.find(
                            (prod: ProdutoUnificado) => (prod.ADCODUNIPRO = Number(adCodUniProSelecionado))
                        );
                        element['urls'].forEach(url => {
                            let saldo = 0.0;
                            const splitDescription = url.description.split('-');
                            if (splitDescription.length > 0) {
                                saldo = parseFloat(splitDescription[splitDescription.length - 1].split('/')[0].trim());
                            }

                            this.imgUrls.push({
                                image: url.big.replace(
                                    'https://portal.eurotextil.com.br/images/IMAGENS%20PORTAL/Programado/',
                                    'D:/Portal/Dropbox/eurotextil/images/IMAGENS PORTAL/Programado/'
                                ),
                                descr: splitDescription[0] + ' - ' + this.formataSaldo(saldo) + '/' + produtoPOSelecionado.CODVOL,
                                codunipro: element['ADCODUNIPRO'],
                                codproj: this.codproj,
                                saldo,
                                usu: this.auth.getUserName(),
                            });
                        });
                    }
                });

                const codProj: any = this.projetoSelecionado;
                this.portalJpaService.insertBookUrl(this.imgUrls, +adCodUniProSelecionado, codProj).subscribe(
                    (value: any) => {
                        const url = `https://portal.eurotextil.com.br/images/bookdinamico/${value.hash}/${value.fileName}`;

                        let contador = 0;
                        let checkPoint = 0;
                        const verificaTam = setInterval(() => {
                            this.nameReport = value['fileName'];
                            this.portalJpaService.getFaixaPedidoTamanho(value.hash + '/' + value.fileName).subscribe((resFileSize: any) => {
                                contador++;
                                if (resFileSize.fileSize == false) {
                                    if (contador > 11) {
                                        contador = 0;
                                        checkPoint++;
                                        this.portalService.openSnackbar('Aguarde, gerando o Book dinamico! ' + checkPoint, 3000, 'bottom');
                                    }
                                } else if (resFileSize.fileSize == true) {
                                    clearInterval(verificaTam);
                                    this.botaoGerarBookDisable = false;

                                    setTimeout(() => {
                                        // Adia a abertura do Link para que não apresente "Erro Falha ao carregar documento PDF."
                                        window.open(url, '_blank');
                                        overlayRef.dispose();
                                    }, 1000);
                                }
                                if (checkPoint > 6) {
                                    // aproximadamente 2min e 30sec
                                    this.botaoGerarBookDisable = false;
                                    overlayRef.dispose();
                                    this.portalService.openSnackbar(
                                        'Ultrapassou o tempo de geração do Book dinamico! ' + checkPoint,
                                        3000,
                                        'bottom'
                                    );
                                    clearInterval(verificaTam);
                                }
                            });
                        }, 8000);
                    },
                    (err: any) => {
                        console.log('Erro na geração do book', err);
                        this.portalService.openSnackbar('Erro na geração do book: ' + err.message, 6000, 'top');
                        this.botaoGerarBookDisable = false;
                        overlayRef.dispose();
                    }
                );
            }
        } catch (err) {
            console.log('Erro na geração do book', err);
            this.portalService.openSnackbar('Erro na geração do book: ' + err.message, 6000, 'top');
            this.botaoGerarBookDisable = false;
            overlayRef.dispose();
        }

        this.imgUrls = [];
    }

    formater(value: any) {
        // let options = { style: 'currency', currency: 'BRL', minimumFractionDigits: 2, maximumFractionDigits: 3 };
        let formatNumber = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 3,
        });
        //@ts-ignore
        console.log(formatNumber);
        return formatNumber.format(value).replace('R$', '');
    }

    editaListaImagens() {
        this.bottomsheet
            .open(BottomSheetEditarListaImgComponent, {
                disableClose: true,
                data: { listaImagens: JSON.parse(JSON.stringify(this.imagens)) },
            })
            .afterDismissed()
            .subscribe(
                (res: any) => {
                    if (res && res[0].urls.length > 0) {
                        this.insertImageUrls('pe', '', res);
                    }
                },
                (err: any) => {
                    console.log('Erro no bottom sheet: ', err);
                    this.portalService.openSnackbar('Erro no bottom sheet' + err.message, 6000, 'top');
                }
            );
    }

    formataSaldo(saldo: number): string {
        const saldoSplit = saldo.toString().split(/[,.]/);
        if (saldoSplit.length > 1) {
            return saldoSplit[0] + ',' + saldoSplit[1][0];
        } else return saldoSplit[0] + ',0';
    }
}
