import { BottomSheetRegrasDescontoComponent } from './local-components/bottom-sheet-regras-desconto/bottom-sheet-regras-desconto.component';
import { MediaMatcher } from '@angular/cdk/layout';
import { Overlay } from '@angular/cdk/overlay';
import { formatDate } from '@angular/common';
import { Component, OnInit, ViewContainerRef, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { AppComponent } from '../app.component';
import { BottomSheetComponent } from '../global-components/bottom-sheet/bottom-sheet.component';
import { DialogComponent } from '../global-components/dialog/dialog.component';
import { SpinnerComponent } from '../global-components/spinner/spinner.component';
import { OcorrenciasCancelamento } from '../global-interfaces/ocorrencias-cancelamento-interface';
import { PedidosPesquisa, TipoAprovacao } from '../global-interfaces/pedidos-pesquisa-interface';
import { AutenticacaoService } from '../services/autenticacao.service';
import { PortalJpaService } from '../services/portal-jpa.service';
import { PortalServiceService } from '../services/portal-service.service';
import { SankhyaServiceService } from '../services/sankhya-service.service';
import { itensPedidoProposta } from './local-interfaces/itens-pedido-proposta-interface';

@Component({
    selector: 'app-liberacoes',
    templateUrl: './liberacoes.component.html',
    styleUrls: ['./liberacoes.component.scss'],
})
export class LiberacoesComponent implements OnInit, OnDestroy {
    constructor(
        private bottomSheet: MatBottomSheet,
        private auth: AutenticacaoService,
        private sankhyaService: SankhyaServiceService,
        public overlay: Overlay,
        public viewContainerRef: ViewContainerRef,
        private dialog: MatDialog,
        private snackBar: MatSnackBar,
        private portalService: PortalServiceService,
        private app: AppComponent,
        private portalJpaServices: PortalJpaService,
        changeDetectorRef: ChangeDetectorRef,
        mediaMatcher: MediaMatcher
    ) {
        this.auth.autenticar();

        this.mediaSS = mediaMatcher.matchMedia('(max-width: 800px)');
        this.mobileQueryListener = () => changeDetectorRef.detectChanges();
        this.mediaSS.addEventListener<'change'>('change', this.mobileQueryListener);
    }

    ngOnInit() {
        this.loadPedidosLiberacao();
    }

    ngOnDestroy(): void {
        this.mediaSS.removeEventListener<'change'>('change', this.mobileQueryListener);
    }

    pedidos: PedidosPesquisa[];
    itensPedidoProposta: itensPedidoProposta[];

    tipoAprovacao = {
        aprovar: TipoAprovacao.APROVAR,
        william: TipoAprovacao.WILLIAM,
        pendente: TipoAprovacao.PENDENTE,
        recusar: TipoAprovacao.RECUSAR,
    };

    limpaEstoque: string = '';
    limpaSaldo: string = '';

    mediaSS: MediaQueryList;
    private mobileQueryListener: () => void;

    listaColunas = ['IMP', 'EMP', 'PEDIDO', 'CLIENTE', 'DATA', 'TIPOPEDIDO', 'ACOES'];

    openBottomSheet(param: string, index: number, params?: any): void {
        let data: any;

        switch (param) {
            case 'recusaPedido': {
                data = {
                    param,
                    pedido: this.pedidos[index],
                    params,
                };
                break;
            }

            case 'erroLiberacao': {
                data = {
                    param,
                    pedido: this.pedidos[index],
                    mensagem: params,
                };
                break;
            }

            case 'inserirParametros': {
                data = {
                    param,
                    pedido: this.pedidos[index],
                    produtos: params,
                    limpEst: this.limpaEstoque,
                    limpaSaldo: this.limpaSaldo
                };
                break;
            }

            default:
                break;
        }

        const matBottomShettRef: MatBottomSheetRef = this.bottomSheet.open(BottomSheetComponent, {
            data,
            panelClass: 'bottom-sheet',
        });

        matBottomShettRef.afterDismissed().subscribe(response => {
            // Vetor de condicionais para ser testado
            const condicionais = [param === 'recusaPedido', response !== undefined, response && response.confirmaRecusa];

            // Dar sequência na recusa apenas se o usuário escolheu o motivo da recusa e clicou em "Confirmar"
            if (!condicionais.includes(false)) {
                this.concluiAcaoLiberacao('R', response.pedido, response.ocor, response.enviaEmail);
            }

            // this.loadPedidosLiberacao();
        });
    }

    openRegraDesconto(pedido: PedidosPesquisa) {
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

        this.portalJpaServices.getRegrasPeido(pedido.NUNOTA).subscribe((res: any) => {
            if (res.serviceResponse === 'OK') {
                let regras = res.response;

                this.portalJpaServices.getInformacoesDesconto(pedido.NUNOTA).subscribe(res => {
                    this.bottomSheet.open(BottomSheetRegrasDescontoComponent, {
                        data: { regras: regras, prodDesc: res, pedido: pedido },
                        panelClass: 'custom-bottom-sheet',
                    });

                    overlayRef.dispose();
                });
            }
        });
    }

    imprimir(pedido: PedidosPesquisa) {
        this.portalService.imprimirPedido(pedido.NUNOTA);
    }

    loadPedidosLiberacao() {
        // Mostrando o spinner na tela
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

        this.pedidos = [];

        this.portalJpaServices.getPedidosLiberacao().subscribe(
            (res: any) => {
                if (res.serviceResponse === 'OK') {
                    res.response.map(pedido => {
                        pedido.DTNEG = new Date(String(pedido.DTNEG).replace(' ', 'T'));

                        if (pedido.RAZAOSOCIAL.length > 22 && this.mediaSS.matches) {
                            pedido.RAZAOSOCIAL = pedido.RAZAOSOCIAL.slice(0, 22) + '...';
                        }
                    });

                    this.pedidos = res.response;
                } else {
                    this.openBottomSheet('erroLiberacao', 0, res.response);
                }

                overlayRef.dispose();
            },
            error => {
                console.log(error);
                overlayRef.dispose();
            }
        );
    }

    /*
     ** O método abaixo é disparado apenas em portáteis.
     ** Para o método disparado em telas grandes, verificar o componente bottomsheet
     */
    acaoLiberacao(tipo: string, pedLiberacao: PedidosPesquisa, index: number) {
        // Caso o pedido seja recusado, abrir popup para o usuário escrever o motivo da recusa
        if (tipo === 'R') {
            let ocorrenciasCancelamento: OcorrenciasCancelamento[];

            this.sankhyaService.getOcorrenciasCancelamento().subscribe(ocor => {
                ocorrenciasCancelamento = ocor;
                this.openBottomSheet('recusaPedido', index, ocorrenciasCancelamento);
            });
        } else if (tipo === 'W') {
            // Este é o usuário logado
            const usuario = this.auth.getUserName().toLowerCase();

            // A variável abaixo será a sigla que será cadastrada no banco
            let siglaAprovacao: string;

            // Esta variável vai guardar a letra inicial do usuário logado
            let letraInicial: string;

            // O usuário que selecionou a opção "W" é dos usuários abaixo?
            if (usuario === 'paulod' || usuario === 'elnatar' || usuario === 'williams' || usuario === 'master') {
                // Pegar a inicial do usuário (usuário "master" será registrado como se fosse Paulo)
                letraInicial = usuario === 'paulod' || usuario === 'master' ? 'P' : usuario[0].toUpperCase();

                // Se o usuário for "williams", então será "W", senão "W" + letra inicial
                usuario === 'williams' ? (siglaAprovacao = 'W') : (siglaAprovacao = 'W' + letraInicial);

                // Concluir com a liberação
                this.concluiAcaoLiberacao(siglaAprovacao, pedLiberacao);
            } else {
                // Pegar a inicial do usuário
                letraInicial = usuario[0].toUpperCase();

                // é necessário verificar qual assinatura acompanhará a assinatura do usuário logado
                this.openDialog('aprovacaoW').subscribe(result => {
                    if (result) {
                        // A sigla será result + letra inicial
                        letraInicial = result + letraInicial;
                        this.concluiAcaoLiberacao(letraInicial, pedLiberacao);
                    }
                });
            }
        } else {
            this.concluiAcaoLiberacao(tipo, pedLiberacao);
        }
    }

    concluiAcaoLiberacao(tipo: string, pedLiberacao: PedidosPesquisa, codOcorCan?: number, enviaEmail?: boolean) {
        // Mostrando o spinner na tela
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

        this.sankhyaService.aprovaReprovaProposta(tipo, pedLiberacao, 'aprovaProposta', codOcorCan, enviaEmail).subscribe(
            (retorno: any) => {
                overlayRef.dispose();

                if (retorno && retorno.serviceResponse && retorno.serviceResponse === 'OK') {
                    const mensagem = `${tipo === 'R' ? 'Recusa' : 'Aprovação'} realizada com sucesso!`;
                    this.openSnackbar(mensagem);
                    //this.loadPedidosLiberacao();
                } else {
                    this.openBottomSheet('erroLiberacao', 0, retorno.response);

                    if (retorno.exibeBadge) {
                        this.app.setError(retorno.response);
                    }
                }

                // this.loadPedidosLiberacao();
            },
            erro => {
                overlayRef.dispose();
                console.log(erro);
                this.openBottomSheet('erroLiberacao', 0, erro.message);
            }
        );
    }

    openSnackbar(mensagem: string) {
        this.snackBar.open(mensagem, 'Fechar', {
            verticalPosition: 'top',
            horizontalPosition: 'end',
            duration: 5000,
        });
    }

    openDialog(tipo: string, params?: any) {
        const dialogRef = this.dialog.open(DialogComponent, {
            width: '450px',
            data: { tipo, params },
        });

        return dialogRef.afterClosed();
    }

    acaoParametros(index: number) {
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

        this.portalJpaServices.getItensPedidoProposta(this.pedidos[index].NUNOTA).subscribe((ret: any) => {
            this.itensPedidoProposta = ret.response;
            this.limpaEstoque = ret.res;

            overlayRef.dispose();
            this.openBottomSheet('inserirParametros', index, this.itensPedidoProposta);
        });
    }
}
