import { Component, OnInit, ElementRef, ViewChild, ViewContainerRef } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AutenticacaoService } from '../services/autenticacao.service';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { SpinnerComponent } from '../global-components/spinner/spinner.component';
import { ComponentPortal } from '@angular/cdk/portal';
import { Md5 } from 'ts-md5/dist/md5';
import { AppComponent } from '../app.component';
import { PortalServiceService } from '../services/portal-service.service';
import { MatBottomSheetModule } from '@angular/material';
import { JwtHelperService } from '@auth0/angular-jwt';

const helper = new JwtHelperService();

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
    usuario: string;
    senha: string;
    logomarca: string = 'assets/logooficial-fundobranco-web.png';

    @ViewChild('userInput', { static: true }) userInput: ElementRef;
    @ViewChild('password', { static: true }) password: ElementRef;

    constructor(
        private snackbar: MatSnackBar,
        private router: Router,
        private auth: AutenticacaoService,
        public overlay: Overlay,
        public viewContainerRef: ViewContainerRef,
        private app: AppComponent,
        private portalService: PortalServiceService,
        private bottomSheet: MatBottomSheetModule
    ) {
        this.usuario = '';
        this.senha = '';
    }

    ngOnInit() {
        if (localStorage.getItem('token')) {
            this.router.navigateByUrl('/');
        }

        window.location.origin == 'http://portal.eurotextil.com.br' && (window.location.href = 'https://portal.eurotextil.com.br/');

        this.userInput.nativeElement.focus();
        this.portalService.getConfigUrl().subscribe((config: any) => {
            this.logomarca = config.imagens.url + '/novalogo/logooficial-fundobranco-web.png';
        });
    }

    changeType() {
        let inputEl = this.password.nativeElement;
        inputEl.type = inputEl.type === 'password' ? 'text' : 'password';
    }

    login() {
        const condicionais = [this.usuario === '', this.senha === ''];

        if (condicionais.includes(true)) {
            this.openSnackbar('Informe usuário e senha!');
            return false;
        }

        this.usuario = this.usuario.trim();

        const md5 = new Md5();
        const senhaCript = md5.appendStr(this.senha).end().toString();

        // Mostrando o spinner na tela
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

        this.portalService.login(this.usuario, senhaCript).subscribe(
            (res: any) => {
                if (res.status) {
                    // Adicionando token
                    //localStorage.setItem('nomeAbrev', this.usuario);

                    // Decodificando o JWT
                    const token = helper.decodeToken(res.authorization);
                    localStorage.setItem('token', res.authorization);
                    localStorage.setItem('refreshToken', res['refresh-authorization']);
                    localStorage.setItem('eurosession', res.eurosession);

                    if (Date.now() > new Date(res.dtExpSenha).getTime() || res.trocarSenha === 'S') {
                        overlayRef.dispose();
                        this.router.navigateByUrl('/alterar-senha');
                    } else {
                        const rotas = res.rotas.map(rota => {
                            return {
                                ...rota,
                                isAtivo: false,
                            };
                        });

                        this.auth.setIsLogged(true, this.usuario);

                        // Atualiza as variaveis instanciadas no componente de autorizacao
                        this.auth.setCodRep();
                        this.auth.setUserName();

                        this.app.setRotas(rotas);
                        this.app.rotaAtual = '/';
                        // this.app.handleTimer('resetar');
                        this.app.setIntervaloOperacional(parseFloat(res.intervaloOperacional));

                        // Removendo o spinner da tela
                        overlayRef.dispose();

                        this.router.navigateByUrl('/');

                        this.app.setUsuarioLogado();

                        // Inicia contador de inatividade
                        this.app.getTokenInatividade();
                    }
                } else {
                    this.auth.setIsLogged(false);
                    this.openSnackbar(res.mensagem);

                    // Removendo o spinner da tela
                    overlayRef.dispose();
                }
            },
            (res: any) => {
                this.auth.setIsLogged(false);
                this.openSnackbar(res.mensagem);

                // Removendo o spinner da tela
                overlayRef.dispose();
            }
        );
    }

    openSnackbar(mensagem: string) {
        this.snackbar.open(mensagem, 'Fechar', {
            horizontalPosition: 'end',
            verticalPosition: 'top',
            duration: 5000,
        });
    }

    keypress(event: any) {
        if (event.charCode === 13) {
            this.login();
        }
    }

    esqueciSenha() {
        this.router.navigateByUrl('/esqueci-senha');
    }
}
