import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material';
import { MatSnackBar } from '@angular/material';

@Component({
    selector: 'app-bottom-sheet-pendencia-coordenador',
    templateUrl: './bottom-sheet-pendencia-coordenador.component.html',
    styleUrls: ['./bottom-sheet-pendencia-coordenador.component.scss'],
})
export class BottomSheetPendenciaCoordenadorComponent implements OnInit {
    constructor(
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
        private bottomSheetRef: MatBottomSheetRef<any>,
        private snackBar: MatSnackBar
    ) {}

    parecerCoord: String;
    vlrCredito: string | number;

    ngOnInit() {}

    openSnackbar(message: string) {
        this.snackBar.open(message, 'Fechar', {
            horizontalPosition: 'end',
            verticalPosition: 'top',
            duration: 2000,
        });
    }

    fechar() {
        this.bottomSheetRef.dismiss();
    }

    enviarParecer(retorno: string) {
        if (retorno) {
            if (!this.parecerCoord || !this.vlrCredito) {
                this.openSnackbar('Preencha o valor de crédito e parecer!');
                return;
            } else {
                this.bottomSheetRef.dismiss({
                    vlrCredito: this.vlrCredito,
                    parecerCoord: this.parecerCoord,
                    tipo: retorno,
                });
            }
        } else {
            this.bottomSheetRef.dismiss();
        }
    }

    naoEnviarParecer(retorno: string) {
        if (retorno) {
            this.bottomSheetRef.dismiss({
                tipo: retorno,
            });
        } else {
            this.bottomSheetRef.dismiss();
        }
    }

    formataBlur() {
        if (this.vlrCredito !== null && this.vlrCredito !== undefined && this.vlrCredito !== '' && !isNaN(Number(this.vlrCredito))) {
            this.vlrCredito = parseFloat(this.vlrCredito.toString()).toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                useGrouping: false,
            });
        } else {
            this.vlrCredito = '';
        }
    }

    formataFocus() {
        if (this.vlrCredito !== null && this.vlrCredito !== undefined) {
            this.vlrCredito = this.vlrCredito.toString().replace(',00', '');
        }
    }

    formataKeyUp() {
        if (isNaN(Number(this.vlrCredito))) {
            this.vlrCredito = '';
        }
    }
}
