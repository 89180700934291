import { Component, ElementRef, Inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material';

@Component({
    selector: 'app-bottom-sheet-editar-lista-img',
    templateUrl: './bottom-sheet-editar-lista-img.component.html',
    styleUrls: ['./bottom-sheet-editar-lista-img.component.scss'],
})
export class BottomSheetEditarListaImgComponent implements OnInit {
    constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any, private bottomSheetRef: MatBottomSheetRef<any>) {}

    @ViewChildren('inputSaldo') inputSaldo!: QueryList<ElementRef<HTMLInputElement>>;

    retornaImg: boolean[] = [];
    saldosMax: number[] = [];

    imgs = [];
    imgsBkp = [];

    todasImg: boolean = true;
    parteImg: boolean = false;

    inputAtual: string = '';

    medida: string = '';

    ngOnInit() {
        this.imgs = this.data.listaImagens;
        this.imgsBkp = this.data.listaImagens;

        const regex = /.* - (.*?)(?:\/|$)/;
        for (let i = 0; i < this.imgs[0].urls.length; i++) {
            const saldo = this.imgs[0].urls[i].description.match(regex)[1];
            this.imgs[0].urls[i].saldo = saldo.replace('.', ',');
            this.imgs[0].urls[i].description = this.imgs[0].urls[i].description.replace('.', ',');
            this.saldosMax.push(saldo);
            this.retornaImg.push(true);
        }

        this.medida = this.imgs[0].urls[0].description.split('/')[1];
    }

    sliceItem(index: number) {
        this.imgs[0].urls.splice(index, 1);
    }

    fechar(acao: string) {
        if (acao === 'conf' && this.imgs[0].urls.length > 0) {
            this.imgs[0].urls = this.imgs[0].urls.filter((ret: boolean, index: number) => this.retornaImg[index]);
            this.bottomSheetRef.dismiss(this.imgs);
        } else {
            this.bottomSheetRef.dismiss();
        }
    }

    marcaProd(checked, index) {
        this.retornaImg[index] = checked;
    }

    marcaTudo(checked) {
        for (let i = 0; i < this.retornaImg.length; i++) this.retornaImg[i] = checked;
    }

    verificaImgs() {
        return this.retornaImg.filter((ret: boolean) => ret).length;
    }

    onInput(index: number) {
        if (this.imgs[0].urls[index].saldo) {
            // Resgata o saldo inserido e remove qualquer caracter nao numerico
            let novoSaldo = this.imgs[0].urls[index].saldo.replace(/[^0-9,]/g, '');

            const saldoSplit = novoSaldo.split(',');

            if (saldoSplit.length > 1) {
                novoSaldo = saldoSplit[0] + ',' + (saldoSplit[1].slice(0, 1) ? saldoSplit[1].slice(0, 1) : '');
            }

            if (parseFloat(novoSaldo.replace(',', '.')) > this.saldosMax[index])
                novoSaldo = this.saldosMax[index].toString().replace('.', ',');

            // Salva o saldo
            this.imgs[0].urls[index].saldo = novoSaldo;

            if (!novoSaldo || [null, undefined, ''].includes(novoSaldo) || parseFloat(novoSaldo.replace(',', '.')) == 0) {
                this.retornaImg[index] = false;
            } else this.retornaImg[index] = true;
        } else this.retornaImg[index] = false;
    }

    onBlur(index: number) {
        if (['0', undefined, null, ''].includes(this.inputSaldo.toArray()[index].nativeElement.value)) {
            this.imgs[0].urls[index].saldo = 0;
            this.retornaImg[index] = false;
        } else {
            this.retornaImg[index] = true;
            this.inputSaldo.toArray()[index].nativeElement.value = this.formataSaldo(
                this.inputSaldo.toArray()[index].nativeElement.value
            ).toString();
        }
    }

    somatorioSaldo(): string {
        let soma = 0;
        this.imgs[0].urls.map((img: any, index) => {
            if (this.retornaImg[index] && img.saldo) soma += parseFloat(img.saldo);
        });
        return this.formataSaldo(soma.toString());
    }

    formataSaldo(saldo: string): string {
        if (saldo && saldo != '0') {
            const saldoSplit = saldo.toString().split(/[,.]/);
            return saldoSplit[0] + (saldoSplit[1] ? ',' + saldoSplit[1][0] : ',0');
        } else return '0,0';
    }
}
