import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { EMPTY, Observable } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { BottomSheetComponent } from '../global-components/bottom-sheet/bottom-sheet.component';
import { AutenticacaoService } from '../services/autenticacao.service';
import { PortalServiceService } from '../services/portal-service.service';
import { SankhyaServiceService } from '../services/sankhya-service.service';

import { version } from '../../../package.json';

@Injectable()
export class InterceptorHeaders implements HttpInterceptor {
    constructor(
        private router: Router,
        private bottomSheet: MatBottomSheet,
        private auth: AutenticacaoService,
        private sankhyaService: SankhyaServiceService,
        private portalService: PortalServiceService
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Requisição para Login não faz tratamento nenhum.
        if (req.url.match('/resources/login')) {
            return next.handle(req);
        }

        // Requisição para Consulta no ViaCep não faz tratamento nenhum.
        if (req.url.match('viacep.com.br')) {
            return next.handle(req);
        }

        // Requisição para recarregar Asset, não faz tratamento nenhum.
        if (req.url.match('assets/config.json')) {
            return next.handle(req);
        }
        
        // Se não tiver o token no localstorage, desconecta o usuario
        // if (!localStorage.getItem('token') && !req.url.match('/trocarSenha') && !req.url.match('/esqueciSenha')) {
        if (!localStorage.getItem('token') && !req.url.match('/esqueciSenha')) {
            this.auth.setIsLogged(false);
            this.auth.desconectaUsu();
            return EMPTY;
        }
        
        // Trata requisição de Relatorio e caso o usuário não esteja ativo, o request do relatório será anulado
        if (req.url.includes('jasper')) {
            return this.sankhyaService.getPermissaoUsu().pipe(
                mergeMap(res => {
                    if (res[0].ATIVO === 'N') {
                        location.reload();
                        return EMPTY;
                    } else this.portalService.openSnackbar('O relatório será aberto em uma nova janela.');
                    return next.handle(req);
                })
            );
        }

        const jwt = new JwtHelperService();

        // if (jwt.isTokenExpired(localStorage.getItem('token')) && !req.url.match('/trocarSenha') && !req.url.match('/esqueciSenha')) {
        if (jwt.isTokenExpired(localStorage.getItem('token')) && !req.url.match('/esqueciSenha')) {
            this.openBottomSheet('Sessão expirada!');
            this.auth.desconectaUsu();

            return EMPTY;
        }
        
        // Marca para fazer o refresh do token
        if (
            !['true', 'restarting'].includes(localStorage.getItem('refresh')) &&
            !req.url.match('/notificacoes/ativas') &&
            !req.url.match('/refreshSession')
        ) {
            localStorage.setItem('refresh', 'true');
        }

        const tmp: any = { version };
        if (localStorage.getItem('eurosession')) {
            tmp.eurosession = localStorage.getItem('eurosession');
        }
        
        if (localStorage.getItem('token')) {
            tmp.authorization = `Bearer ${localStorage.getItem('token')}`;
        }
        
        req = req.clone({
            setHeaders: {
                ...tmp,
            },
        });

        return next.handle(req);
    }

    openBottomSheet(mensagem: string) {
        this.bottomSheet.open(BottomSheetComponent, {
            data: {
                param: 'interceptor',
                mensagem: {
                    message: mensagem,
                    titulo: 'Sua sessão expirou, faça login novamente!',
                },
            },
            panelClass: 'bottom-sheet',
        });
    }
}
