import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, mergeMap, startWith, switchMap, tap } from 'rxjs/operators';

import { Parceiro } from 'src/app/global-interfaces/parceiro-interface';
import { PortalJpaService } from 'src/app/services/portal-jpa.service';

@Component({
    selector: 'app-autocomplete-cliente-pedido',
    templateUrl: './autocomplete-cliente-pedido.component.html',
    styleUrls: ['./autocomplete-cliente-pedido.component.scss'],
})
export class AutocompleteClientePedidoComponent implements OnInit, OnChanges {
    @Input()
    clienteAtual: Parceiro;

    @Input()
    autocompleteEnabled: boolean = true;

    @Input()
    origem = '';

    @Output()
    clienteSelecionado = new EventEmitter<Parceiro>();

    clienteFormControl = new FormControl('');
    listaClientesFilter: Observable<Parceiro[]> = new Observable<Parceiro[]>();
    apiErrorMessage: string = '';

    constructor(private jpaService: PortalJpaService) {}

    ngOnInit(): void {
        if (this.clienteAtual) {
            this.clienteFormControl.setValue(this.clienteAtual);
        }

        //inicializar o listaClientesFilter
        this.listaClientesFilter = this.clienteFormControl.valueChanges.pipe(
            debounceTime(1200),
            switchMap((value: string | Parceiro) => {
                this.apiErrorMessage = '';
                if (!value || (typeof value === 'string' && value.length < 1)) {
                    if (typeof value === 'string' && value.length < 1) {
                        this.handleClienteSelecionado(undefined);
                    }
                    return of([]);
                }

                if (typeof value === 'object') {
                    if (!value.RAZAOSOCIAL) {
                        return of([]);
                    }
                    if (value.CODPARC == this.clienteAtual.CODPARC) {
                        // evita uma pesquisa na api para buscar o mesmo cliente.
                        return of([{ ...this.clienteAtual }]);
                    }
                }

                const filtro = typeof value === 'string' ? value.toUpperCase() : value.RAZAOSOCIAL;

                return this.jpaService.autocompleteClientePedido(filtro, this.origem).pipe(
                    tap((res: any) => {
                        if (res && res.serviceResponse == 'ERROR') {
                            this.clienteFormControl.setErrors({ errorCtrl: true });
                            this.apiErrorMessage = res.response;
                        }
                        if (res && Array.isArray(res.response) && res.response < 1) {
                            this.clienteFormControl.setErrors({ errorCtrl: true });
                            this.apiErrorMessage = 'Filtro de pesquisa não encontrado';
                        }
                    }),
                    catchError(error => {
                        console.log('Erro na api:', error);
                        this.clienteFormControl.setErrors({ errorCtrl: true });
                        this.apiErrorMessage = error.message;

                        return of([]);
                    })
                );
            }),
            mergeMap((res: any): Observable<Parceiro[]> => {
                if (res.serviceResponse && res.serviceResponse == 'OK') {
                    const lista: Parceiro[] = res.response;

                    lista.map((parceiro: Parceiro) => {
                        if (parceiro && parceiro.CGCCPF) {
                            parceiro.CGCCPF = parceiro.CGCCPF.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');
                        }
                    });

                    return of(lista);
                }
                return of([] as Parceiro[]);
            })
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.clienteAtual) {
            this.clienteFormControl.setValue(this.clienteAtual);
        }

        if (changes.autocompleteEnabled) {
            if (changes.autocompleteEnabled.currentValue) {
                this.clienteFormControl.enable();
            } else {
                this.clienteFormControl.disable();
            }
        }

        if (changes.clienteAtual) {
            if (
                changes.clienteAtual.currentValue.CODPARC == 0 &&
                typeof this.clienteFormControl.value === 'object' &&
                this.clienteFormControl.value.CODPARC > 0
            ) {
                // O valor atual é um cliente vazio, mas o controle tem um objeto cliente, limpar o controle
                this.clienteFormControl.reset('');
            }
        }
    }

    getDescricaoCliente(cliente: Parceiro): string {
        return cliente && cliente.RAZAOSOCIAL ? cliente.RAZAOSOCIAL.toString() : '';
    }

    handleClienteSelecionado(cliente: Parceiro) {
        this.clienteSelecionado.emit(cliente);
    }

    handleClose() {
        if (typeof this.clienteFormControl.value != 'string') {
            return;
        }
        // Limpa o campo se nada foi selecionado.
        this.clienteFormControl.reset('');
    }
}
