import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { SpinnerComponent } from '../global-components/spinner/spinner.component';
import { PortalServiceService } from '../services/portal-service.service';
import { Md5 } from 'ts-md5/dist/md5';
import { AutenticacaoService } from '../services/autenticacao.service';

@Component({
  selector: 'app-pedidos-legado',
  templateUrl: './pedidos-legado.component.html',
  styleUrls: ['./pedidos-legado.component.scss']
})

export class PedidosLegadoComponent implements OnInit {

    constructor(
        public overlay: Overlay,
        public viewContainerRef: ViewContainerRef,
        private portalService: PortalServiceService,
        private auth: AutenticacaoService
    ) {
        this.carregarPedidos(1);
        this.pedidos = [];
    }

    ngOnInit() {}

    public pedidos: any;

    carregarPedidos(numeroInicial: number) {
        this.pedidos = [];

        // // Instanciando a variável que possui as configurações do spinner
        // const config = new OverlayConfig();

        // // Atribuindo as features do spinner
        // config.positionStrategy = this.overlay.position()
        //   .global()
        //   .centerHorizontally()
        //   .centerVertically();

        // // Definindo que o spinner ficará em uma camada acima da aplicação
        // config.hasBackdrop = true;

        // // Criando a camada com as configurações e atribuindo a uma constante
        // const overlayRef = this.overlay.create(config);

        // // Mostrando o spinner na tela
        // overlayRef.attach(new ComponentPortal(SpinnerComponent, this.viewContainerRef));

        const sessionId = this.auth.getUserName();

        this.portalService.carregaPedidosLegado('all', numeroInicial, sessionId)
            .subscribe(
                (response) => {
                    this.pedidos = response[1];
                },

                // (erro) => {

                //   // BottomSheet
                //   const param = 'carregarPedidos';

                //   this.bottomSheet.open(BottomSheetComponent, {
                //     data: { param, erro },
                //     panelClass: 'bottom-sheet'
                //   });

                //   // Removendo o spinner da tela
                //   overlayRef.dispose();
                // },

                () => {
                    // Removendo o spinner da tela
                    // overlayRef.dispose();
                }
            );
    }

    paginar(event: any) {
        const { numeroInicial, numeroFinal } = event;
        this.carregarPedidos(numeroInicial);
    }

    printPedidos(nrPedido: string) {

        // Instanciando a variável que possui as configurações do spinner
        const config = new OverlayConfig();

        // Atribuindo as features do spinner
        config.positionStrategy = this.overlay.position()
            .global()
            .centerHorizontally()
            .centerVertically();

        // Definindo que o spinner ficará em uma camada acima da aplicação
        config.hasBackdrop = true;

        // Criando a camada com as configurações e atribuindo a uma constante
        const overlayRef = this.overlay.create(config);

        // Mostrando o spinner na tela
        overlayRef.attach(new ComponentPortal(SpinnerComponent, this.viewContainerRef));

        let tipoMoeda = 0;
        let aprovado = 0;
        let aprovador = '';
        let iSituacao = 0;
        const sessionId = this.auth.getUserName();

        this.portalService.buscaInfoPedidosLegado(nrPedido)
            .subscribe(
                res => {
                    tipoMoeda = res[0].tipo_moeda;
                    aprovado = res[0].aprovado;
                    aprovador = res[0].aprovador;
                    iSituacao = res[0].i_situacao;

                    const md5 = new Md5();
                    const hash = md5.appendStr(nrPedido + tipoMoeda).end().toString();

                    overlayRef.dispose();
                    this.portalService.getConfigUrl().subscribe((config: any) => {

                        console.log('config.portal.pedidosLegado.imprimirPedido2', config.portal.pedidosLegado.imprimirPedido2)

                        window.open(config.portal.pedidosLegado.imprimirPedido2 +
                            '?nr_pedido=' + nrPedido +
                            '&tipo_moeda=' + tipoMoeda +
                            '&chave=' + hash +
                            '&sessionId=' + sessionId +
                            '&aprovador=' + aprovador +
                            '&aprovado=' + aprovado +
                            '&i_situacao=' + iSituacao +
                            '&tipo_pdf=1'
                            , '_system');
                    })
                },  erro => console.table(erro)
        );
    }
}
