import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MatTabGroup } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { DialogQrCodeComponent } from './dialog-qrcode/dialog-qrcode.component';
import { SankhyaServiceService } from '../services/sankhya-service.service';
import { DomSanitizer } from '@angular/platform-browser';
import { PortalServiceService } from 'src/app/services/portal-service.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { SelecionarModoComponent } from './selecionar-modo/selecionar-modo.component';
import { PortalJpaService } from '../services/portal-jpa.service';
import { SpinnerComponent } from '../global-components/spinner/spinner.component';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

@Component({
    selector: 'app-qr-code',
    templateUrl: './qr-code.component.html',
    styleUrls: ['./qr-code.component.scss'],
    animations: [
        trigger('popOverState', [
            state(
                'show',
                style({
                    transform: 'translateX(0%)',
                })
            ),
            state(
                'hide',
                style({
                    transform: 'translateX(200%)',
                })
            ),
            transition('show => hide', animate('400ms  0.2ms ease-in-out')),
            transition('hide => show', animate('400ms  0.2ms ease-in-out')),
        ]),
    ],
})
export class QrCodeComponent implements OnInit {
    constructor(
        private bottomSheet: MatBottomSheet,
        private dialog: MatDialog,
        private PortalJpaService: PortalJpaService,
        private portalService: PortalServiceService,
        private sanitizer: DomSanitizer,
        private sankhyaService: SankhyaServiceService,
        public overlay: Overlay,
        public viewContainerRef: ViewContainerRef
    ) {}

    @ViewChild('tabGroup', { static: false }) tab: MatTabGroup;

    public selectedIndexBinding = 0;

    show = false;
    emp = ['Matriz', 'Filial ES', 'Filial PE', 'Filial SC'];

    label = '';

    get stateName() {
        return this.show ? 'show' : 'hide';
    }

    togle() {
        this.show = !this.show;
    }

    codEmpActual: any;
    unidadeMedida: any;
    img: any = [];
    codproj: any;
    descricaoDoProduto: any;
    fichaTecnica: String[] = [];
    briefing: String[] = [];
    codigoProjeto: any = [];
    codigoProduto;
    po: any[] = [];
    pe: any[] = [];
    allData: any[] = [];
    style: any;
    imgUrls: any[] = [];
    urlParam: any;
    dataFormata: any[] = [];

    produtoSelecionadoPe: any = {
        ADCODUNIPRO: 0,
        DESCRPROD: '',
        CONTROLE: [],
        CODVOL: '',
        PESOBRUTO: 0,
        PESOLIQ: 0,
        QTDNEG: 0,
        ADPERCTOLPEDCOM: 0,
        ADMULTIVEN: 0,
        VLRVENDA: 0,
        VLRVENDAALT: 0,
    };

    produtoSelecionadoPo: any = {
        ADCODUNIPRO: 0,
        DESCRPROD: '',
        CONTROLE: [],
        CODVOL: '',
        PESOBRUTO: 0,
        PESOLIQ: 0,
        QTDNEG: 0,
        ADPERCTOLPEDCOM: 0,
        ADMULTIVEN: 0,
        VLRVENDA: 0,
        VLRVENDAALT: 0,
    };

    ngOnInit() {
        const bottomSheetRef = this.bottomSheet.open(SelecionarModoComponent);
        bottomSheetRef.afterDismissed().subscribe(dataFromChild => {
            if (dataFromChild === 'qr') {
                this.openDialog().subscribe(res => {
                    if (res !== undefined) {
                        this.produtoSelecionadoPe.ADCODUNIPRO = res;
                        this.produtoSelecionadoPo.ADCODUNIPRO = res;
                        this.getModoDelavagem(res);
                        this.getProduto(res);
                    }
                });
            } else {
                if (dataFromChild !== undefined) {
                    const qrResultString = dataFromChild;
                    this.produtoSelecionadoPe.ADCODUNIPRO = qrResultString;
                    this.produtoSelecionadoPo.ADCODUNIPRO = qrResultString;
                    this.getModoDelavagem(qrResultString);
                    this.getProduto(qrResultString);
                }
            }
        });
    }

    options = { style: 'currency', currency: 'BRL', minimumFractionDigits: 2, maximumFractionDigits: 3 };
    formatNumber = new Intl.NumberFormat('pt-BR', this.options);

    getProduto(data: any) {
        // Mostrando o spinner na tela
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

        this.sankhyaService.getDetalhesProduto(data).subscribe(retorno => {
            let descrProd: String[] = [];
            let undMedida: String[] = [];
            let briffing: String[] = [];
            let ficha: String[] = [];
            let pe: any[] = [];
            let po: any[] = [];

            retorno['PE'].forEach((element, i) => {
                element.TIPO = 'PE';
                descrProd.push(element['DESCRPROD']);
                pe.push(Number(element['SALDO']));
                undMedida.push(String(element['CODVOL']));
                briffing.push(String(element['AD_BRIEFING']));
                ficha.push(String(element['CARACTERISTICAS']));
            });

            retorno['PO'].forEach((element, i) => {
                element.TIPO = 'PO';
                descrProd.push(element['DESCRPROD']);
                this.codigoProjeto.push(Number(element['CODPROJ']));
                po.push(Number(element['SALDO']));
                undMedida.push(String(element['CODVOL']));
                briffing.push(String(element['AD_BRIEFING']));
                ficha.push(String(element['CARACTERISTICAS']));
            });

            this.descricaoDoProduto = [...new Set(descrProd)];
            this.unidadeMedida = [...new Set(undMedida)];
            this.briefing = [...new Set(briffing)];
            this.fichaTecnica = [...new Set(ficha)];

            this.pe = pe.reduce((a, b) => {
                return a + b;
            }, 0);

            this.po = po.reduce((a, b) => {
                return a + b;
            }, 0);

            this.produtoSelecionadoPe.CONTROLE = [...new Set(retorno['PE'])];
            this.produtoSelecionadoPo.CONTROLE = [...new Set(retorno['PO'])];

            this.codproj = [...new Set(this.codigoProjeto)];

            this.sankhyaService.getImagensProduto([this.produtoSelecionadoPe], 'pe').subscribe(res => {
                try {
                    res['itens'].urls.forEach(element => {
                        let codcor = element.small.slice(-13, -4);
                        this.produtoSelecionadoPe.CONTROLE.forEach((el, i) => {
                            if (el.CODCOR === codcor) {
                                this.produtoSelecionadoPe.CONTROLE[i].URL = element.small;
                            }
                        });
                    });
                } catch (e) {
                    console.log('Não possui imagem Pronta entrega');
                }
            });

            this.codproj.forEach(el => {
                this.sankhyaService.getImagensProduto([this.produtoSelecionadoPo], 'po', el).subscribe(res => {
                    try {
                        res['itens'][0].urls.forEach(element => {
                            let codcor = element.small.slice(-13, -4);
                            this.produtoSelecionadoPo.CONTROLE.forEach((el, i) => {
                                if (el.CODCOR === codcor) {
                                    this.produtoSelecionadoPo.CONTROLE[i].URL = element.small;
                                }
                            });
                        });
                    } catch (e) {
                        console.log('Não possui imagem Programada');
                    }
                });
            });
            this.codigoProduto = data;
            // Removendo o spinner da tela
            overlayRef.dispose();
        });

        this.codigoProjeto = [];
        this.dataFormata = [];
        this.codproj = [];
    }

    openQrCodeCamera() {
        const bottomSheetRef = this.bottomSheet.open(SelecionarModoComponent);
        bottomSheetRef.afterDismissed().subscribe(dataFromChild => {
            if (dataFromChild === 'qr') {
                this.openDialog().subscribe(res => {
                    if (res !== undefined) {
                        this.produtoSelecionadoPe.ADCODUNIPRO = res;
                        this.produtoSelecionadoPo.ADCODUNIPRO = res;
                        this.getModoDelavagem(res);
                        this.getProduto(res);
                    }
                });
            } else {
                if (dataFromChild !== undefined) {
                    const qrResultString = dataFromChild;
                    this.produtoSelecionadoPe.ADCODUNIPRO = qrResultString;
                    this.produtoSelecionadoPo.ADCODUNIPRO = qrResultString;
                    this.getModoDelavagem(qrResultString);
                    this.getProduto(qrResultString);
                }
            }
        });
    }

    open(param: any) {
        if (param !== undefined) {
            this.style = this.sanitizer.bypassSecurityTrustStyle('display: block');
            this.urlParam = this.sanitizer.bypassSecurityTrustResourceUrl(param);
        } else {
            this.portalService.openSnackbar('Esta cor ainda não possui imagem.');
        }
    }

    close() {
        this.style = this.sanitizer.bypassSecurityTrustStyle('display: none');
    }

    openDialog() {
        const dialogRef = this.dialog.open(DialogQrCodeComponent, {
            width: '250px',
            height: '250px',
            data: '',
        });

        return dialogRef.afterClosed();
    }

    getModoDelavagem(codUniPro) {
        this.img = [];
        this.sankhyaService.getModoDelavagem(codUniPro).subscribe(x => {
            let data = x['pictograma'][0];
            if (data['IMAGE'] != false) {
                this.img.push('<img src="data:image/png;base64,' + data['IMAGE'] + '" width="50" height="50">');
            }
            if (data['IMAGE01'] != false) {
                this.img.push('<img src="data:image/png;base64,' + data['IMAGE01'] + '" width="50" height="50">');
            }
            if (data['IMAGE02'] != false) {
                this.img.push('<img src="data:image/png;base64,' + data['IMAGE02'] + '" width="50" height="50">');
            }
            if (data['IMAGE03'] != false) {
                this.img.push('<img src="data:image/png;base64,' + data['IMAGE03'] + '" width="50" height="50">');
            }
            if (data['IMAGE04'] != false) {
                this.img.push('<img src="data:image/png;base64,' + data['IMAGE04'] + '" width="50" height="50">');
            }
            if (data['IMAGE05'] != false) {
                this.img.push('<img src="data:image/png;base64,' + data['IMAGE05'] + '" width="50" height="50">');
            }
            if (data['IMAGE06'] != false) {
                this.img.push('<img src="data:image/png;base64,' + data['IMAGE06'] + '" width="50" height="50">');
            }
            if (data['IMAGE07'] != false) {
                this.img.push('<img src="data:image/png;base64,' + data['IMAGE07'] + '" width="50" height="50">');
            }
        });
    }

    selectedPO(event: any) {
        this.label = event;
    }

    generateBook() {
        // Mostrando o spinner na tela
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

        this.imgUrls = [];
        if (this.selectedIndexBinding > 3) {
            let filteredPhotoimages = this.produtoSelecionadoPo.CONTROLE.filter(value => {
                return value.URL != undefined;
            });
            filteredPhotoimages.forEach(element => {
                if (element.TIPO == 'PO' && element.CODPROJ == this.label) {
                    this.imgUrls.push({
                        image: String(element.URL).replace(
                            'https://portal.eurotextil.com.br/images/IMAGENS%20PORTAL/Programado/',
                            'D:/Portal/Dropbox/eurotextil/images/IMAGENS PORTAL/Programado/'
                        ),
                        descr: `${element.CODCOR + ' - ' + element.SALDO + '/' + element.CODVOL}`,
                        codunipro: this.codigoProduto,
                        codproj: this.codproj[0],
                        saldo: element.SALDO,
                    });
                }
            });
        } else {
            let filteredPhotoimages = this.produtoSelecionadoPe.CONTROLE.filter(value => {
                return value.URL != undefined;
            });
            filteredPhotoimages.forEach(element => {
                if (element.TIPO == 'PE' && element.CODEMP - 1 == this.selectedIndexBinding) {
                    this.imgUrls.push({
                        image: String(element.URL).replace(
                            'https://portal.eurotextil.com.br/images/IMAGENS%20PORTAL/Pronta%20Entrega/',
                            'D:/Portal/Dropbox/eurotextil/images/IMAGENS PORTAL/Pronta Entrega/'
                        ),
                        descr: `${element.CODCOR + ' - ' + element.SALDO + '/' + element.CODVOL}`,
                        codunipro: this.codigoProduto,
                        codproj: null,
                        saldo: element.SALDO,
                        emp: this.emp[element.CODEMP - 1],
                    });
                }
            });
        }

        if (this.imgUrls.length > 0) {
            const adCodUniPro: number = +this.codigoProduto;
            let codProj = 0;
            if (this.selectedIndexBinding > 3) {
                codProj = this.imgUrls[0].codproj;
            }

            this.PortalJpaService.insertBookUrl(this.imgUrls, adCodUniPro, codProj).subscribe((value: any) => {
                const url = `https://portal.eurotextil.com.br/images/bookdinamico/${value.hash}/${value.fileName}`;

                let contador = 0;
                let checkPoint = 0;
                const verificaTam = setInterval(() => {
                    this.PortalJpaService.getFaixaPedidoTamanho(value.hash + '/' + value.fileName).subscribe((resFileSize: any) => {
                        contador++;
                        if (resFileSize.fileSize) {
                            clearInterval(verificaTam);
                            window.open(url, '_blank');
                            overlayRef.dispose();
                        } else {
                            if (contador > 11) {
                                contador = 0;
                                checkPoint++;
                                this.portalService.openSnackbar('Aguarde, gerando o Book dinamico! ' + checkPoint, 3000, 'bottom');
                            }
                        }
                        if (checkPoint > 6) {
                            // aproximadamente 2min e 30sec
                            overlayRef.dispose();
                            this.portalService.openSnackbar(
                                'Ultrapassou o tempo de geração do Book dinamico! ' + checkPoint,
                                3000,
                                'bottom'
                            );
                            clearInterval(verificaTam);
                        }
                    });
                }, 2000);
            });
        } else {
            overlayRef.dispose();
        }
    }

    formater(value: any) {
        //@ts-ignore
        return this.formatNumber.format(String(value)).replace('R$', '');
    }
}
