import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { SankhyaServiceService } from '../services/sankhya-service.service';
import { DatePipe } from '@angular/common';
import { RangePaginas, Paginas, PedidosPesquisa } from '../global-interfaces/pedidos-pesquisa-interface';
import { PortalServiceService } from '../services/portal-service.service';
import { forkJoin } from 'rxjs';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { SpinnerComponent } from '../global-components/spinner/spinner.component';
import { ProdutoUnificado } from '../global-interfaces/produto-interface';

@Component({
    selector: 'app-consulta-produtos',
    templateUrl: './consulta-produtos.component.html',
    styleUrls: ['./consulta-produtos.component.scss'],
})
export class ConsultaProdutosComponent implements OnInit {
    constructor(
        private sankhyaService: SankhyaServiceService,
        private datePipe: DatePipe,
        private portalService: PortalServiceService,
        public overlay: Overlay,
        public viewContainerRef: ViewContainerRef
    ) {}

    ngOnInit(): void {
        this.produtoSelecionado = {
            ADCODUNIPRO: null,
            DESCRPROD: null,
            CONTROLE: [],
            CODVOL: '',
            PESOBRUTO: null,
            PESOLIQ: null,
            QTDNEG: null,
            ADPERCTOLPEDCOM: null,
            ADMULTIVEN: null,
            VLRVENDA: null,
        };

        this.dtInicial = null;
        this.dtFinal = null;
        this.abaSelecionada = 0;
    }

    dtInicial: string;
    dtFinal: string;
    resumo: any;
    detalhe: any;
    abaSelecionada: number;
    produtoSelecionado: ProdutoUnificado;

    limparVariaveis() {
        this.produtoSelecionado = {
            ADCODUNIPRO: null,
            DESCRPROD: null,
            CONTROLE: [],
            CODVOL: '',
            PESOBRUTO: null,
            PESOLIQ: null,
            QTDNEG: null,
            ADPERCTOLPEDCOM: null,
            ADMULTIVEN: null,
            VLRVENDA: null,
        };

        this.dtInicial = null;
        this.dtFinal = null;
        this.abaSelecionada = 0;
        this.resumo = null;
        this.detalhe = null;
    }

    consultarProdutoPedidoResumido() {
        if (!this.produtoSelecionado || !this.produtoSelecionado.ADCODUNIPRO || !this.dtInicial || !this.dtFinal) {
            this.portalService.openSnackbar('Preencha todos os campos.');
            return;
        }
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

        this.sankhyaService
            .getProdutoPedidoResumido(
                this.produtoSelecionado.ADCODUNIPRO,
                this.datePipe.transform(this.dtInicial, 'dd/MM/yyyy'),
                this.datePipe.transform(this.dtFinal, 'dd/MM/yyyy')
            )
            .subscribe(
                (res: any) => {
                    this.resumo = res.resumo;
                    overlayRef.dispose();
                },
                error => {
                    console.log('erro = ', error);
                    overlayRef.dispose();
                }
            );
    }

    exibirDetalhe(codparc: number) {
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

        this.sankhyaService
            .getProdutoPedidoDetalhado(
                this.produtoSelecionado.ADCODUNIPRO,
                this.datePipe.transform(this.dtInicial, 'dd/MM/yyyy'),
                this.datePipe.transform(this.dtFinal, 'dd/MM/yyyy'),
                codparc
            )
            .subscribe(
                (res: any) => {
                    this.detalhe = res.detalhe;
                    this.abaSelecionada = 1;
                    overlayRef.dispose();
                },
                error => {
                    console.log('erro = ', error);
                    overlayRef.dispose();
                }
            );
    }

    imprimir(nuNota: number) {
        this.portalService.imprimirPedido(nuNota);
    }
}
