import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material';

@Component({
    selector: 'app-bottom-sheet-produtos-dev',
    templateUrl: './bottom-sheet-produtos-dev.component.html',
    styleUrls: ['./bottom-sheet-produtos-dev.component.scss'],
})
export class BottomSheetProdutosDevComponent implements OnInit, OnDestroy {
    constructor(
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
        private bottomSheetRef: MatBottomSheetRef<any>,
        changeDetectorRef: ChangeDetectorRef,
        mediaMatcher: MediaMatcher
    ) {
        this.mediaSS = mediaMatcher.matchMedia('(max-width: 800px)');
        this.mobileQueryListener = () => changeDetectorRef.detectChanges();
        this.mediaSS.addEventListener<'change'>('change', this.mobileQueryListener);
    }

    ngOnInit() {}

    ngOnDestroy(): void {
        this.mediaSS.removeEventListener<'change'>('change', this.mobileQueryListener);
    }

    mediaSS: MediaQueryList;
    private mobileQueryListener: () => void;

    prodColumns = ['CODUNIPRO', 'DESCRPROD', 'CONTROLE', 'MEDIDA', 'VLRUNIT', 'VLRTOT', 'QTDDEV', 'VLRTOTDEV', 'CODVOL', 'SEQROLO']

    fechar() {
        this.bottomSheetRef.dismiss();
    }
}
