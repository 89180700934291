import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs/internal/Observable';
import { debounceTime, distinctUntilChanged, startWith, switchMap } from 'rxjs/operators';
import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';

import { PortalJpaService } from '../services/portal-jpa.service';
import * as saveAs from 'file-saver';

@Component({
    selector: 'app-consulta-serasa',
    templateUrl: './consulta-serasa.component.html',
    styleUrls: ['./consulta-serasa.component.scss'],
})
export class ConsultaSerasaComponent implements OnInit {
    listaSerasa$: Observable<any[]>;
    listaParceiroFiltrada: Observable<string[]>;
    listaSolicitanteFiltrada: Observable<string[]>;
    serasaRelatorio: Observable<string[]>;
    downloading = false;

    myForm = new FormGroup({
        listaParceiroControl: new FormControl(''),
        listaSolicitanteControl: new FormControl(''),
        dataInicialControl: new FormControl(''),
        dataFinalControl: new FormControl(''),
    });

    listaSolicitante: any[] = [
        { nome: 'AMANDA ALVES DE LUNA', codigo: 109 },
        { nome: 'SUELLEN PAULINO DE OLIVEIRA', codigo: 29 },
        { nome: 'CARLA FERNANDA RHODEN', codigo: 260 },
        { nome: 'PRISCILLA APRIGIO DE OLIVEIRA', codigo: 113 },
    ];

    constructor(private portalJpaService: PortalJpaService, private http: HttpClient) {
        this.listaSerasa$ = this.portalJpaService.getListaSerasa('', '', '', '');
    }

    displayedColumns: string[] = [
        'acao',
        'Cod. Consulta Serasa',
        'Dt. Solicitação',
        'Ult. Consuta',
        'Cod. Portal Eurotextil',
        'Razão Social',
        'CNPJ',
        'Dt. Requisição Serasa',
        'Nome Completo',
    ];

    ngOnInit() {
        this.listaParceiroFiltrada = this.myForm.get('listaParceiroControl').valueChanges.pipe(
            startWith(''),
            debounceTime(1000),
            distinctUntilChanged(),
            switchMap(value => {
                return this.portalJpaService.getListaParceiro(value);
            })
        );
    }

    mandaParametro = () => {
        this.listaSerasa$ = this.portalJpaService.getListaSerasa(
            String(this.myForm.get('listaParceiroControl').value).split(' - ')[0],
            this.myForm.get('listaSolicitanteControl').value,
            this.myForm.get('dataInicialControl').value.toLocaleDateString(),
            this.myForm.get('dataFinalControl').value.toLocaleDateString()
        );
        this.limpaFiltro();
    };

    limpaFiltro = () => {
        this.myForm.get('listaParceiroControl').setValue('');
        this.myForm.get('listaSolicitanteControl').setValue('');
        this.myForm.get('dataInicialControl').setValue('');
        this.myForm.get('dataFinalControl').setValue('');
    };

    geraSerasa = serasaID => {
        let downloading = true;
        this.portalJpaService.geraSerasaRelatorio(serasaID).subscribe(
            event => {
                if (event.type === HttpEventType.Response) {
                    saveAs(event.body, 'FichaParceiroSerasa.pdf');
                    downloading = false;
                }
            },
            error => {
                console.error(error);
                downloading = false;
            }
        );
    };
}
