import { Contato } from './local-interfaces/contato.interface';
import { PortalJpaService } from 'src/app/services/portal-jpa.service';
import { PedidoPrazo } from './local-interfaces/pedido-prazo.interface';
import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { Parceiro } from '../global-interfaces/parceiro-interface';
import { CheckboxStatusPedido } from './local-interfaces/checkbox-status-pedido-interface';
import { SankhyaServiceService } from '../services/sankhya-service.service';
import { DatePipe } from '@angular/common';
import { RangePaginas, Paginas } from '../global-interfaces/pedidos-pesquisa-interface';
import { PortalServiceService } from '../services/portal-service.service';
import { forkJoin } from 'rxjs';
import { TitulosParceiros } from '../global-interfaces/titulos-parceiros-interface';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { SpinnerComponent } from '../global-components/spinner/spinner.component';
import { ProdutoUnificado } from '../global-interfaces/produto-interface';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatTableDataSource } from '@angular/material';
import { Response } from '../global-interfaces/response';
import { ValorEmAberto } from './local-interfaces/valor-em-aberto';

@Component({
    selector: 'app-consulta-clientes',
    templateUrl: './consulta-clientes.component.html',
    styleUrls: ['./consulta-clientes.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: 0 })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class ConsultaClientesComponent implements OnInit {
    constructor(
        private sankhyaService: SankhyaServiceService,
        private datePipe: DatePipe,
        private portalService: PortalServiceService,
        public overlay: Overlay,
        public viewContainerRef: ViewContainerRef,
        private portalJpaService: PortalJpaService
    ) {
        this.inicializarVariaveis();
        this.calculaColunasTabelas();

        // Colunas que irão aparecer na Mat Table pedidos
        this.pedColumns = ['BOTAO', 'EMPRESA', 'RAZAOSOCIAL', 'NUMNOTA', 'PEDIDOS', 'TIPO', 'DTNEG', 'STATUS'];
        // Colunas que irão aparecer na Mat Table duplicatas
        this.dupColumns = ['CODEMP', 'NOME', 'NUMNOTA', 'DESDOBRAMENTO', 'DTNEGDUP', 'DTVENC', 'VLRDESDOB'];
        // Colunas que irão aparecer na Mat table pedidos prazo
        this.prazoColumns = ['CODPROD', 'DESCR', 'DTULTCOMP', 'QTD', 'QTDANO', 'QTDPED'];
    }

    parceiroCliente: Parceiro;

    coligados: boolean;
    empColigadas: boolean;
    listaColigadas: Parceiro[];

    // Mat table

    pedColumns: String[] = []; // Coluna de pedidos
    dupColumns: string[] = []; // Coluna de duplicatas
    prazoColumns: string[] = []; // coluna de Pedidos prazo

    expandedElement: string;

    //

    pedidos = new MatTableDataSource();
    checkboxStatusPedidos: CheckboxStatusPedido[];

    duplicatas: TitulosParceiros[];
    duplicatasDataInicio: string;
    duplicatasDataFim: string;

    fxHidePedidos: boolean[];
    fxHideDuplicatas: boolean[];

    paginas: Paginas;

    produtoSelecionado: ProdutoUnificado;
    prod: string = '';

    pedidosPrazo: PedidoPrazo[] = [];
    pedidosFiltro: PedidoPrazo[] = [];

    contatos: Contato[] = [];
    contatosCliente: boolean = false;

    vlraberto: ValorEmAberto;

    ngOnInit() {
        this.produtoSelecionado = {
            ADCODUNIPRO: null,
            DESCRPROD: null,
            CONTROLE: [],
            CODVOL: '',
            PESOBRUTO: null,
            PESOLIQ: null,
            QTDNEG: null,
            ADPERCTOLPEDCOM: null,
            ADMULTIVEN: null,
            VLRVENDA: null,
        };

        this.vlraberto = {
            VLREMABERTO: 0,
            VLRPEDIDOS: 0,
            VLRTITULOS: 0,
            VLRTRADEMASTER: 0,
            CLASSIFICACAO: '',
        };
    }

    calculaPrazo(prazo: number) {
        //let pattern = /(\d{2})\/(\d{2})\/(\d{4})/; mascara para converter data
        this.pedidosFiltro = [];

        for (let i = 0; i < this.pedidosPrazo.length; i++) {
            let data = new Date(this.pedidosPrazo[i].DTNEG);
            let sysdate = new Date();

            sysdate.setHours(0, 0, 0, 0);
            sysdate.setDate(sysdate.getDate() - prazo);

            if (data <= sysdate) {
                this.pedidosFiltro.push(this.pedidosPrazo[i]);
            }
        }
    }

    consultarCliente() {
        this.prod = this.produtoSelecionado && this.produtoSelecionado.ADCODUNIPRO ? String(this.produtoSelecionado.ADCODUNIPRO) : '';

        // Pelo menos um checkbox de status do pedido precisa estar marcado...
        const condicionais = this.checkboxStatusPedidos.map(checkbox => checkbox.isMarcado);

        if (!condicionais.includes(true)) {
            this.portalService.openSnackbar('Informe pelo menos um status de pedido!');
            return false;
        }

        // Recuperar quais são os status de pedido estão marcados como TRUE
        const checkboxesMarcados = this.checkboxStatusPedidos.filter(checkbox => checkbox.isMarcado);

        let stringStatus = '';

        checkboxesMarcados.map((checkbox, index) => {
            if (checkbox) {
                // Se for o último índice do loop, não inserir ", " na string, evitando "1, 2, 3, 4, 5" de ficar "1, 2, 3, 4, 5, "
                index === checkboxesMarcados.length - 1
                    ? (stringStatus += `${checkbox.CODSTATUS}`)
                    : (stringStatus += `${checkbox.CODSTATUS}, `);
            }
        });

        const dadosBusca = {
            parceiroCliente: this.parceiroCliente,
            coligados: this.coligados,
            empColigadas: this.empColigadas,
            listaStatus: stringStatus,
            numeroInicial: this.paginas.numeroInicial,
            numeroFinal: this.paginas.numeroFinal,
            prod: this.prod,
        };

        // Mostrando o spinner na tela
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

        const getPedidosPesquisaCliente = this.sankhyaService.getPedidosPesquisaCliente(dadosBusca);
        const getTitulosCliente = this.sankhyaService.getTitulosCliente(
            this.parceiroCliente,
            '',
            '',
            dadosBusca.coligados,
            'consultaClientes'
        );
        const getValorEmAberto = this.portalJpaService.getValorEmAberto(this.parceiroCliente.CODPARC);

        this.consultaPedidoPrazo(this.parceiroCliente.CODPARC);

        forkJoin([getPedidosPesquisaCliente, getTitulosCliente, getValorEmAberto]).subscribe(
            res => {
                this.pedidos.data = res[0];
                this.duplicatas = res[1];

                // Consulta os pedidos com as informações do grupo
                this.consultarPedidos();

                // Consulta o valor em aberto do cliente (titulos em aberto + pedidos liberados pelo financeiro mas não faturados)
                const res2: Response = res[2];
                this.vlraberto = res2.response;

                // Removendo o spinner da tela
                overlayRef.dispose();
            },
            erro => {
                // Removendo o spinner da tela
                overlayRef.dispose();

                console.log(erro);
            }
        );
    }

    consultarPedidos() {
        this.prod = this.produtoSelecionado && this.produtoSelecionado.ADCODUNIPRO ? String(this.produtoSelecionado.ADCODUNIPRO) : '';

        // Pelo menos um checkbox de status do pedido precisa estar marcado...
        const condicionais = this.checkboxStatusPedidos.map(checkbox => checkbox.isMarcado);

        if (!condicionais.includes(true)) {
            this.portalService.openSnackbar('Informe pelo menos um status de pedido!');
            return false;
        }

        // Recuperar quais são os status de pedido estão marcados como TRUE
        const checkboxesMarcados = this.checkboxStatusPedidos.filter(checkbox => checkbox.isMarcado);

        let stringStatus = '';

        checkboxesMarcados.map((checkbox, index) => {
            if (checkbox) {
                // Se for o último índice do loop, não inserir ", " na string, evitando "1, 2, 3, 4, 5" de ficar "1, 2, 3, 4, 5, "
                index === checkboxesMarcados.length - 1
                    ? (stringStatus += `${checkbox.CODSTATUS}`)
                    : (stringStatus += `${checkbox.CODSTATUS}, `);
            }
        });

        const dadosBusca = {
            parceiroCliente: this.parceiroCliente,
            coligados: this.coligados,
            empColigadas: this.empColigadas,
            listaStatus: stringStatus,
            numeroInicial: this.paginas.numeroInicial,
            numeroFinal: this.paginas.numeroFinal,
            prod: this.prod,
        };

        // Mostrando o spinner na tela
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

        this.pedidos = new MatTableDataSource();

        this.sankhyaService.getPedidosPesquisaCliente(dadosBusca).subscribe(
            res => {
                this.pedidos.data = res;

                // Removendo o spinner da tela
                overlayRef.dispose();
            },
            erro => {
                // Removendo o spinner da tela
                overlayRef.dispose();

                console.log(erro);
            }
        );
    }

    consultarDuplicatas() {
        // Mostrando o spinner na tela
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

        // Formatando data de início das duplicatas
        const duplicatasDataInicio =
            this.datePipe.transform(this.duplicatasDataInicio, 'dd/MM/yyyy') === null
                ? ''
                : this.datePipe.transform(this.duplicatasDataInicio, 'dd/MM/yyyy');

        // Formatando data fim das duplicatas
        const duplicatasDataFim =
            this.datePipe.transform(this.duplicatasDataFim, 'dd/MM/yyyy') === null
                ? ''
                : this.datePipe.transform(this.duplicatasDataFim, 'dd/MM/yyyy');

        // A data de início não pode ser superior à data fim
        if (duplicatasDataInicio > duplicatasDataFim) {
            this.portalService.openSnackbar('Data início é superior à data fim na aba Duplicatas!');
            return false;
        }

        // Fazer a requisição
        this.sankhyaService
            .getTitulosCliente(this.parceiroCliente, duplicatasDataInicio, duplicatasDataFim, this.coligados, 'consultaClientes')
            .subscribe(
                res => {
                    this.duplicatas = res;

                    // Removendo o spinner da tela
                    overlayRef.dispose();
                },

                erro => {
                    // Removendo o spinner da tela
                    overlayRef.dispose();

                    console.log(erro);
                }
            );
    }

    paginar(event: any) {
        this.paginas = event;
        this.consultarPedidos();
    }

    imprimir(pedido: number) {
        this.portalService.imprimirPedido(pedido);
    }

    // Resetando todas as variáveis para seus estados iniciais
    inicializarVariaveis(): void {
        // Zerando cliente
        this.parceiroCliente = {
            CODPARC: 0,
            NOMEPARC: '',
            RAZAOSOCIAL: '',
            CODVEND: 0,
            TIPO: '',
            NOMEEND: '',
            NUMEND: 0,
            COMPLEMENTO: '',
            NOMEBAI: '',
            NOMECID: '',
            CEP: '',
            UF: '',
            PAISEND: '',
            TELEFONE: '',
            EMAIL: '',
            IDENTINSCESTAD: '',
            CGCCPF: '',
            ATIVO: '',
            LIMCRED: 0,
            CLIENTE: '',
            FORNECEDOR: '',
            VENDEDOR: '',
            TRANSPORTADORA: '',
            BLOQUEAR: '',
            CODTIPPARC: 0,
            APELIDO: '',
        };

        // Resetando a lista de contatos e de empresas do grupo
        this.coligados = false;
        this.empColigadas = false;
        this.listaColigadas = [];
        this.contatosCliente = false;
        this.contatos = [];
        // Configurando todos os checkboxes como marcado
        this.checkboxStatusPedidos = [
            { CODSTATUS: 1, STATUS: 'PROPOSTA', isMarcado: true },
            { CODSTATUS: 2, STATUS: 'PROPOSTA APROVADA', isMarcado: true },
            { CODSTATUS: 3, STATUS: 'CONFIRMADO COMERCIAL', isMarcado: true },
            { CODSTATUS: 31, STATUS: 'CONFIRMADO FINANCEIRO', isMarcado: true },
            { CODSTATUS: 32, STATUS: 'AGUARDANDO ANTECIPACAO', isMarcado: true },
            { CODSTATUS: 4, STATUS: 'LIBERADO SEPARAR', isMarcado: true },
            { CODSTATUS: 41, STATUS: 'EM SEPARACAO', isMarcado: true },
            { CODSTATUS: 42, STATUS: 'LIBERADO CONFERIR', isMarcado: true },
            { CODSTATUS: 43, STATUS: 'EM CONFERENCIA', isMarcado: true },
            { CODSTATUS: 5, STATUS: 'SEPARADO', isMarcado: true },
            { CODSTATUS: 51, STATUS: 'AGUARDANDO PAGAMENTO', isMarcado: true },
            { CODSTATUS: 52, STATUS: 'LIBERADO FATURAR', isMarcado: true },
            { CODSTATUS: 6, STATUS: 'FATURADO', isMarcado: true },
            { CODSTATUS: 7, STATUS: 'CANCELADO', isMarcado: true },
        ];

        // Zerando os pedidos
        this.pedidos.data = [];

        // Zerando as datas início e fim
        this.duplicatasDataInicio = '';
        this.duplicatasDataFim = '';

        // Resetando a paginação

        this.paginas = {
            numeroInicial: RangePaginas.numeroInicial,
            numeroRegistros: RangePaginas.numeroRegistros,
            numeroFinal: RangePaginas.numeroRegistros,
        };
    }

    calculaColunasTabelas() {
        const larguraTela = window.innerWidth;

        // Configurando tabela de pedidos
        if (larguraTela < 600) {
            this.fxHidePedidos = [true, true, false, true, false, false, false, false, false, false, false];
        } else if (larguraTela < 800) {
            this.fxHidePedidos = [true, true, true, true, false, false, false, false, false, false, false];
        } else if (larguraTela < 1200) {
            this.fxHidePedidos = [true, true, true, true, true, true, false, false, false, false, false];
        } else {
            this.fxHidePedidos = [true, true, true, true, true, true, true, true, true, true];
        }

        // Configurando tabela de duplicatas
        if (larguraTela < 700) {
            this.fxHideDuplicatas = [true, true, false, false, false, false, false, false, true, false];
        } else if (larguraTela < 900) {
            this.fxHideDuplicatas = [true, true, false, false, false, true, true, false, true, true];
        } else if (larguraTela < 1400) {
            this.fxHideDuplicatas = [true, true, true, true, false, true, true, false, true, true];
        } else {
            this.fxHideDuplicatas = [true, true, true, true, true, true, true, true, true, true];
        }
    }

    listarColigadas() {
        if (this.empColigadas) {
            // Mostrando o spinner na tela
            const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

            this.sankhyaService.getListaColigadas(this.parceiroCliente.CODPARC, 'consulta').subscribe(
                (res: any) => {
                    this.listaColigadas = res.param;

                    // Removendo o spinner da tela
                    overlayRef.dispose();
                },

                erro => {
                    // Removendo o spinner da tela
                    overlayRef.dispose();
                }
            );
        }
    }

    selecionarCliente() {
        // Resetando a lista de contatos e de empresas do grupo
        this.empColigadas = false;
        this.listaColigadas = [];
        this.produtoSelecionado = null;
        this.contatosCliente = false;
        this.contatos = [];

        this.consultarCliente();
        this.consultarPedidos();
        this.consultaPedidoPrazo(this.parceiroCliente.CODPARC);
    }

    limparVariaveis() {
        this.produtoSelecionado = null;
        this.prod = '';
    }

    consultaPedidoPrazo(codParc: number) {
        this.portalJpaService.getPedidosPrazo(codParc, String(this.coligados)).subscribe((response: any) => {
            this.pedidosPrazo = response.response;
            this.calculaPrazo(90);
        });
    }

    consultaContatoCliente() {
        if (this.contatosCliente) {
            const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

            this.portalJpaService.getContatosCliente(this.parceiroCliente.CODPARC).subscribe(
                (response: any) => {
                    this.contatos = response.response;
                    overlayRef.dispose();
                },
                error => {
                    console.log(error);
                    overlayRef.dispose();
                }
            );
        }
    }

    private getParceiroVazio(): Parceiro {
        return {
            CODPARC: 0,
            NOMEPARC: '',
            RAZAOSOCIAL: '',
            CODVEND: 0,
            TIPO: '',
            NOMEEND: '',
            NUMEND: 0,
            COMPLEMENTO: '',
            NOMEBAI: '',
            NOMECID: '',
            CEP: '',
            UF: '',
            PAISEND: '',
            TELEFONE: '',
            EMAIL: '',
            IDENTINSCESTAD: '',
            CGCCPF: '',
            ATIVO: '',
            LIMCRED: 0,
            CLIENTE: '',
            FORNECEDOR: '',
            VENDEDOR: '',
            TRANSPORTADORA: '',
            BLOQUEAR: '',
            ADSITUACAOPORTAL: '',
        };
    }

    emitirClienteSelecionado(cliente: Parceiro) {
        this.parceiroCliente = { ...cliente };
        this.selecionarCliente();
    }

    clearClienteAutocomplete() {
        this.parceiroCliente = this.getParceiroVazio();
    }
}
