import { portalEuro } from 'src/assets/config.json';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material';
import { PortalJpaService } from 'src/app/services/portal-jpa.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-bottom-sheet-anexos-dev',
    templateUrl: './bottom-sheet-anexos-dev.component.html',
    styleUrls: ['./bottom-sheet-anexos-dev.component.scss'],
})
export class BottomSheetAnexosDevComponent implements OnInit {
    constructor(
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
        private bottomSheetRef: MatBottomSheetRef<any>,
        private portalJpaService: PortalJpaService,
        public _d: DomSanitizer
    ) {}

    ngOnInit() {}

    urlAnexos = this.portalJpaService.getUrlAmbiente() + portalEuro.resources.portal.devolucoes.getAnexosDevolucao;

    abrirAnexo(anexoId: number) {
        fetch(this.urlAnexos + anexoId)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Erro ao fazer o download do anexo');
                }

                const contentType = response.headers.get('content-type');
                if (contentType && contentType.includes('video')) {
                    return response.blob();
                } else {
                    window.open(this.urlAnexos + anexoId, '_blank');
                    return null; // Retorna null para evitar que o fluxo continue para o próximo then
                }
            })
            .then(blob => {
                if (blob) {
                    // Verifica se o blob não é null (ou seja, se não era uma imagem)
                    // Criar um URL temporário para o blob
                    let blobUrl = window.URL.createObjectURL(blob);

                    // Criar um elemento <a> para iniciar o download
                    let link = document.createElement('a');
                    link.href = blobUrl;
                    link.download = `${anexoId}.mp4`; // Defina o nome do arquivo desejado
                    link.style.display = 'none';

                    // Adicionar o elemento <a> ao DOM e simular um clique para iniciar o download
                    document.body.appendChild(link);
                    link.click();

                    // Limpar o URL do blob após o download iniciar
                    window.URL.revokeObjectURL(blobUrl);

                    // Remover o elemento <a> do DOM após o download iniciar
                    document.body.removeChild(link);
                }
            })
            .catch(error => {
                console.error('Erro ao abrir anexo:', error);
                // Trate o erro aqui conforme necessário
            });
    }

    fechar() {
        this.bottomSheetRef.dismiss();
    }
}
