import { Overlay } from '@angular/cdk/overlay';
import { DatePipe } from '@angular/common';
import { Component, OnInit, AfterViewInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { SpinnerComponent } from '../global-components/spinner/spinner.component';
import { PortalJpaService } from '../services/portal-jpa.service';
import { ItensPedido } from './local-interfaces/itens-pedido.interface';
import { PedidoComissao } from './local-interfaces/pedido-comissao.interface';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, SortDirection } from '@angular/material/sort';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AutenticacaoService } from '../services/autenticacao.service';

@Component({
  selector: 'app-comissoes',
  templateUrl: './comissoes.component.html',
  styleUrls: ['./comissoes.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: 0 })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ]
})
export class ComissoesComponent implements OnInit {

  constructor(
    private portalJpaService: PortalJpaService,
    public overlay: Overlay,
    public viewContainerRef: ViewContainerRef,
    public datePipe: DatePipe,
    private snackBar: MatSnackBar,
    private auth: AutenticacaoService
  ) {
    this.abaSelecionada = 0;
    this.codRep = +auth.getCodRep();
    this.codVend = +auth.getCodRep();
    this.todosRepresentantes = false;
    this.exibirConteudo = false;
    this.columnsPedidos = this.codRep == 0 ?
      [
        'ACAO',
        'ADPEDIDOPORTAL',
        'NUMNOTA',
        'CODPARC',
        'RAZAOSOCIAL',
        'DTFATUR',
        'DTPEDIDO',
        'CODVEND',
        'APELIDO',
        'VLRNOTA',
        'VLRCOMISSAO',
        'PERCCOMISSAO',
        'ORIGCOM'
      ] :
      [
        'ACAO',
        'ADPEDIDOPORTAL',
        'NUMNOTA',
        'CODPARC',
        'RAZAOSOCIAL',
        'DTFATUR',
        'DTPEDIDO',
        'CODVEND',
        'APELIDO',
        'VLRNOTA',
        'VLRCOMISSAO',
        'PERCCOMISSAO'
      ];

    this.columnsItensPedido = [
      'SEQUENCIA',
      'CODPROD',
      'DESCRPROD',
      'CONTROLE',
      'VLRUNIT',
      'QTDNEG',
      'VLRTOT',
      'ADCOMVLR',
      'ADCOMPERC',
      'ADVLRUNITTAB',
      'DESCON'
    ];
//mobile
    this.columnsPedidosMobile =  
    [
      'NUMNOTA',
      'RAZAOSOCIAL',
      'VLRCOMISSAO'
    ];

    this.columnsItensPedidoMobile = [
      'CODPROD',
      'DESCRPROD',
      'CONTROLE'
    ];
  }

  ngOnInit() {
    this.carregarRepresentantes();
    this.todosRepresentantes = this.codRep == 0;
  }


  // @ViewChild('paginatorPedidos', { static: false }) paginatorPedidos: MatPaginator;
  // @ViewChild('paginatorItens', { static: false }) paginatorItens: MatPaginator;


  @ViewChild('paginatorPedidos', { static: false })
  set paginatorPedidos(value: MatPaginator) {
    if (this.pedidos.data.length > 0) {
      this.pedidos.paginator = value;
      this.pedidos.paginator._intl.itemsPerPageLabel = 'Itens por pág.';
    }
  }

  @ViewChild('paginatorItensPedido', { static: false })
  set paginatorItensPedido(value: MatPaginator) {
    if (this.itensPedido.data.length > 0) {
      this.itensPedido.paginator = value;
      this.itensPedido.paginator._intl.itemsPerPageLabel = 'Itens por pág.';
    }
  }
//mobile
  @ViewChild('paginatorPedidosMobile', { static: false })
  set paginatorPedidosMobile(value: MatPaginator) {
    if (this.pedidosMobile.data.length > 0) {
      this.pedidosMobile.paginator = value;
    }
  }

  @ViewChild('paginatorItensPedidoMobile', { static: false })
  set paginatorItensPedidoMobile(value: MatPaginator) {
    if (this.itensPedidoMobile.data.length > 0) {
      this.itensPedidoMobile.paginator = value;
    }
  }
  

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  pedidos = new MatTableDataSource();
  itensPedido = new MatTableDataSource();
  dataIni: any;
  dataFin: any;
  abaSelecionada: number;
  representantes: any[] = [];
  codRep: number;
  codVend: number;
  todosRepresentantes: boolean;
  repSelecionado: any;
  exibirConteudo: boolean;
  columnsPedidos: string[] = [];
  columnsItensPedido: string[] = [];
  columnsItensPedidoFooter: string[] = [];
  vlrComTotal: number;
  vlrTotal: number;
  percTotal: number;
  temPedido = false;

  columnsPedidosMobile: string[] = [];
  columnsItensPedidoMobile: string[] = [];
  expandedElement: string | null;
  pedidosMobile = new MatTableDataSource();
  itensPedidoMobile = new MatTableDataSource();

  carregarRepresentantes() {
    this.portalJpaService.getListaRepresentantes().subscribe(
      (res: any) => {
        this.representantes = res.response;
        this.exibirConteudo = (this.representantes.find(element => element.CODVEND == this.codRep) || this.codRep == 0);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  carregarPedidos() {

    let dtIni = (this.datePipe.transform(this.dataIni, 'dd/MM/yyyy') === null) ? '' : this.datePipe.transform(this.dataIni, 'dd/MM/yyyy');
    let dtFin = (this.datePipe.transform(this.dataFin, 'dd/MM/yyyy') === null) ? '' : this.datePipe.transform(this.dataFin, 'dd/MM/yyyy');

    if (!dtIni || !dtFin) {
      this.openSnackbar('Digite a Data Inicial e Final!');
      return false;
    }

    if (this.dataIni < new Date('2022-09-01')) {
      this.openSnackbar('Data Inicial deve ser igual ou superior a 01/09/2022');
      return false;
    }

    if (this.dataIni > this.dataFin) {
      this.openSnackbar('Data Inicial deve ser inferior a Data Final');
      return false;
    }

    if (this.codRep == 0 && this.codVend == 0 && !this.todosRepresentantes) {
      this.openSnackbar('Selecione um representante ou marque a opção "Todos"!');
      return false;
    }

    this.pedidos = new MatTableDataSource();
    this.itensPedido = new MatTableDataSource();

    this.itensPedidoMobile = new MatTableDataSource();
    this.pedidosMobile = new MatTableDataSource();

    const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

    this.portalJpaService.getPedidosComissao(dtIni, dtFin, this.codVend).subscribe(
      (res: any) => {
        this.pedidos.data = res.response;
        this.pedidosMobile.data = res.response;
        
        overlayRef.dispose();
      },
      (error) => {
        overlayRef.dispose();
        console.log(error);
      }
    );
  }

  carregarItensPedido(nuNota: number) {

    this.abaSelecionada = 1;

    const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

    this.portalJpaService.getItensPedidoComissao(nuNota).subscribe(
      (res: any) => {
        this.itensPedido.data = res.response;
        this.itensPedidoMobile.data = res.response;
        this.vlrComTotal = res.response.map(elem => elem.ADCOMVLR).reduce((acc, value) => acc + value, 0);
        this.vlrTotal = res.response.map(elem => elem.VLRTOT).reduce((acc, value) => acc + value, 0);
        this.percTotal = (this.vlrComTotal / this.vlrTotal) * 100;
        overlayRef.dispose();
      },
      (error) => {
        overlayRef.dispose();
        console.log(error);
      }
    );
  }

  selecionarRep(event: any) {
    this.codVend = event.value.CODVEND;
  }

  openSnackbar(message: string) {
    this.snackBar.open(message, 'Fechar', {
      horizontalPosition: 'end',
      verticalPosition: 'top',
      duration: 5000
    });
  }

  alternarTodos() {
    this.codVend = 0;
    this.repSelecionado = {};
  }
}