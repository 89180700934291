import { Component, Input, OnInit } from '@angular/core';
import { SankhyaServiceService } from 'src/app/services/sankhya-service.service';
import { PortalServiceService } from 'src/app/services/portal-service.service';

@Component({
  selector: 'app-relatorio-snapshotpo',
  templateUrl: './relatorio-snapshotpo.component.html',
  styleUrls: ['./relatorio-snapshotpo.component.scss', '../relatorios.component.scss']
})
export class RelatorioSnapShotPOComponent implements OnInit {

    constructor(
        private portalService: PortalServiceService,
        private sankhyaService: SankhyaServiceService) { }

    ngOnInit() {

        this.dtSnapShot = null;
        this.produto = null;
        this.codproj = null;
    }

    @Input() restUrl: string;

    dtSnapShot: any;
    produto: number;
    codproj: number;

    gerarPdf() {

        if (this.dtSnapShot === null) {
            this.portalService.openSnackbar("Preencha a data!");
            return false;
        }

        const dataParam = {
            DTSNAPSHOT: this.dtSnapShot,
            PRODUTO: this.produto === null ? -1 : this.produto,
            CODPROJ: this.codproj === null ? -1 : this.codproj
        }

        /* this.sankhyaService.getPermissaoUsu()
            .subscribe((retorno: any) => {

                const ativo = retorno[0].ATIVO;

                if (ativo === 'S') {

                    this.portalService.imprimirGeral({ params: dataParam, url: this.restUrl + '.pdf' });
                } else {

                    location.reload();
                }
            },  (error) => {

        location.reload();
        }); */
        
        this.portalService.imprimirGeral({ params: dataParam, url: this.restUrl + '.pdf' });
    }
}
