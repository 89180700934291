import { MediaMatcher } from '@angular/cdk/layout';
import { Overlay } from '@angular/cdk/overlay';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { portal } from '../../assets/config.json';
import { BooksInterface } from '../books/local-interfaces/books-interface';
import { NumeroPO } from '../criar-pedido/local-interfaces/numero-po-interface';
import { ProdutoUnificado } from '../global-interfaces/produto-interface';
import { AutenticacaoService } from '../services/autenticacao.service';
import { PortalJpaService } from '../services/portal-jpa.service';
import { SankhyaServiceService } from '../services/sankhya-service.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
    avisosMural: any;
    listaProgramacaoSemanal$: Observable<any[]>;

    displayedColumns: string[] = ['emp', 'processo', 'artigo', 'programacao'];

    constructor(
        private auth: AutenticacaoService,
        private http: HttpClient,
        private router: Router,
        private portalJpaService: PortalJpaService,
        public overlay: Overlay,
        public viewContainerRef: ViewContainerRef,
        changeDetectorRef: ChangeDetectorRef,
        mediaMatcher: MediaMatcher
    ) {
        this.auth.autenticar();

        this.mediaSS = mediaMatcher.matchMedia('(max-width: 800px)');
        this.mobileQueryListener = () => changeDetectorRef.detectChanges();
        this.mediaSS.addEventListener<'change'>('change', this.mobileQueryListener);
    }

    ngOnDestroy(): void {
        this.mediaSS.removeEventListener<'change'>('change', this.mobileQueryListener);
    }

    mediaSS: MediaQueryList;
    private mobileQueryListener: () => void;

    tipoPedido: string;
    projetoSelecionado: NumeroPO;
    projetos: NumeroPO[];

    // Atributo que vai receber o produto selecionado
    produtoSelecionado: ProdutoUnificado;
    produtos: ProdutoUnificado[];

    books: BooksInterface[];

    listaEmpresas: number[] = [];
    nomeEmpresas: any;
    codEmp: number;
    aba: number;
    nav: any;

    lebels: string[];

    ngOnInit() {
        this.getMuralAvisos();
        this.portalJpaService.getProgramacaoSemanal().subscribe((res: any) => {
            // let data = this.organaizarBooks(res.response);
            // let sortedData = data.sort((a, b) => {
            //     return b.dtprog - a.dtprog;
            // });
            this.books = this.organaizarBooks(res.response);
        });
    }

    getMuralAvisos() {
        const urlMural = portal.muralAvisos.url;

        this.http.get(urlMural, {}).subscribe(
            response => {
                this.avisosMural = response;
            },

            error => {
                console.log(error);
            }
        );
    }

    verBooks() {
        this.router.navigateByUrl('/galeria-books', { state: { books: JSON.stringify('home') } });
    }

    getDateFromString(dateString: string) {
        if (dateString.includes('-')) {
            const [dateValues, timeValues] = dateString.split(' ');
            const year = dateValues.substring(0, 4);
            const month = dateValues.substring(5, 7);
            const day = dateValues.substring(8, 11);
            return new Date(+year, +month - 1, +day);
        } else {
            const [dateValues, timeValues] = dateString.split(' ');
            const day = dateValues.substring(0, 2);
            const month = dateValues.substring(2, 4);
            const year = dateValues.substring(4, 10);
            return new Date(+year, +month - 1, +day);
        }
    }

    organaizarBooks(array) {
        let memory = {
            temp: [],
            result: [],
        };
        array.forEach(o => {
            if (!memory.temp.includes(+o.IDPROG)) {
                memory.temp.push(+o.IDPROG);
                const object = array.filter(obj => +obj.IDPROG === +o.IDPROG);
                //const data = this.concatenateProdocuts(object);
                memory.result.push({ IDPROG: +o.IDPROG, DTPROG: this.getDateFromString(o.DTPROG), DESCR: o.DESCR, DATA: object });
            }
        });

        return memory.result;
    }

    //método para concatenar produtos do mesmo projeto (ou PE) em uma unica linha
    // concatenateProdocuts(array) {
    //     const result = [];

    //     const groups = array.reduce((groups, item) => {
    //         if (groups[item.CODPROJ]) {
    //             groups[item.CODPROJ].DESCRPROD += ' / ' + item.DESCRPROD;
    //         } else {
    //             groups[item.CODPROJ] = { ...item };
    //         }
    //         return groups;
    //     }, {});

    //     for (const codproj in groups) {
    //         result.push(groups[codproj]);
    //     }

    //     return result;
    // }
}
