import { Component, Input, OnInit, ViewContainerRef } from '@angular/core';
import { Parceiro } from 'src/app/global-interfaces/parceiro-interface';
import { OverlayConfig, Overlay } from '@angular/cdk/overlay';
import { PortalServiceService } from 'src/app/services/portal-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ComponentPortal } from '@angular/cdk/portal';
import { SpinnerComponent } from 'src/app/global-components/spinner/spinner.component';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/global-components/dialog/dialog.component';
import { SankhyaServiceService } from 'src/app/services/sankhya-service.service';

@Component({
    selector: 'app-relatorio-ficha-cliente',
    templateUrl: './relatorio-ficha-cliente.component.html',
    styleUrls: ['./relatorio-ficha-cliente.component.scss', '../relatorios.component.scss'],
})
export class RelatorioFichaClienteComponent implements OnInit {
    constructor(
        private portalService: PortalServiceService,
        private snackBar: MatSnackBar,
        public overlay: Overlay,
        public viewContainerRef: ViewContainerRef,
        private dialog: MatDialog
    ) {
        this.clientesRelatorio = {} as Parceiro;
        this.isMatriz = false;
    }

    ngOnInit() {}

    @Input() restUrl: string;

    isTodos: boolean;
    isMatriz: boolean;

    clienteSelecionado: Parceiro = this.getParceiroVazio();
    clientesRelatorio: Parceiro;

    gerarPdf() {
        if (this.clientesRelatorio.CODPARC === undefined) {
            this.snackBar.open('Informe o Clientes no campo!', 'Fechar', {
                verticalPosition: 'top',
                horizontalPosition: 'end',
                duration: 5000,
            });
        } else {
            // let dataParam = {
            //   codParc: this.clientesRelatorio.CODPARC,
            //   geraMatriz: this.isMatriz,
            //   usuarioJasper: '',
            //   senhaJasper: ''
            // };

            let dataParam = {
                CODPARC: this.clientesRelatorio.CODPARC,
                PARCMATRIZ: this.isMatriz,
                usuarioJasper: '',
                senhaJasper: '',
            };

            // Instanciando a variável que possui as configurações do spinner
            const config = new OverlayConfig();

            // Atribuindo as features do spinner
            config.positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically();

            // Definindo que o spinner ficará em uma camada acima da aplicação
            config.hasBackdrop = true;

            // Criando a camada com as configurações e atribuindo a uma constante
            const overlayRef = this.overlay.create(config);

            // Mostrando o spinner na tela
            overlayRef.attach(new ComponentPortal(SpinnerComponent, this.viewContainerRef));

            // this.openDialog('jasperUser').subscribe(result => {

            //   if (!isUndefined(result) && !isUndefined(result.usuarioJasper) && !isUndefined(result.senhaJasper)) {

            //     const md5 = new Md5();
            //     dataParam = {
            //       ...dataParam,
            //       usuarioJasper: result.usuarioJasper,
            //       senhaJasper: md5.appendStr(result.senhaJasper).end().toString()
            //     };

            //     this.portalService.imprimirGeral({ params: dataParam, url: this.restUrl + '.pdf'});

            //     // this.portalService.imprimirFichaCliente(dataParam).subscribe(retorno => {

            //     //   const fileUrl = URL.createObjectURL(retorno);
            //     //   window.open(fileUrl, '_blank');

            //     // }, (erro) => {
            //     //   if (erro.status === 401) {
            //     //     this.portalService.openSnackbar('Usuario sem permissão para executar o relatório');
            //     //   } else {
            //     //     this.portalService.openSnackbar('Erro ao executar relatório');
            //     //   }
            //     // });
            //   }

            // });

            /* this.sankhyaService.getPermissaoUsu()
                .subscribe((retorno: any) => {

                    const ativo = retorno[0].ATIVO;

                    if (ativo === 'S') {

                        this.portalService.imprimirGeral({ params: dataParam, url: this.restUrl + '.pdf' });
                    } else {

                        location.reload();
                    }
                }, (error) => {

                        location.reload();
                    }
            ); */
            this.portalService.imprimirGeral({ params: dataParam, url: this.restUrl + '.pdf' });
            // Removendo o spinner da tela
            overlayRef.dispose();
        }
    }

    openDialog(tipo: string, params?: any) {
        const dialogRef = this.dialog.open(DialogComponent, {
            width: '300px',
            data: { tipo, params },
        });

        return dialogRef.afterClosed();
    }

    inserirCliente() {
        this.clientesRelatorio = Object.assign(this.clienteSelecionado);

        this.clienteSelecionado = Object.assign({ RAZAOSOCIAL: null, CODPARC: null } as Parceiro);
    }

    deleteCliente() {
        this.clientesRelatorio = Object.assign({} as Parceiro);
    }

    toggleMatriz(check) {
        this.isMatriz = check.checked;
    }

    private getParceiroVazio(): Parceiro {
        return {
            CODPARC: 0,
            NOMEPARC: '',
            RAZAOSOCIAL: '',
            CODVEND: 0,
            TIPO: '',
            NOMEEND: '',
            NUMEND: 0,
            COMPLEMENTO: '',
            NOMEBAI: '',
            NOMECID: '',
            CEP: '',
            UF: '',
            PAISEND: '',
            TELEFONE: '',
            EMAIL: '',
            IDENTINSCESTAD: '',
            CGCCPF: '',
            ATIVO: '',
            LIMCRED: 0,
            CLIENTE: '',
            FORNECEDOR: '',
            VENDEDOR: '',
            TRANSPORTADORA: '',
            BLOQUEAR: '',
            ADSITUACAOPORTAL: '',
        };
    }

    emitirClienteSelecionado(cliente: Parceiro) {
        this.clienteSelecionado = { ...cliente };
        this.inserirCliente();
    }

    clearClienteAutocomplete() {
        this.clienteSelecionado = this.getParceiroVazio();
    }
}
