import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef, ViewContainerRef } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';

import { SankhyaServiceService } from 'src/app/services/sankhya-service.service';
import { PortalJpaService } from 'src/app/services/portal-jpa.service';
import { CabecalhoPedido, ItensPedido } from 'src/app/global-interfaces/pedido-interface';
import { SpinnerComponent } from 'src/app/global-components/spinner/spinner.component'
import { NotificacaoService } from '../../services/notificacao.service';

@Component({
    selector: 'app-bsn-reenviar-pedido',
    templateUrl: './bsn-reenviar-pedido.component.html',
    styleUrls: ['./bsn-reenviar-pedido.component.scss']
})
export class BsnReenviarPedidoComponent implements OnInit {

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private sankhyaService: SankhyaServiceService,
        private notificacaoService: NotificacaoService,
        private portalJpaService: PortalJpaService,
        private snackBar: MatSnackBar,
        public overlay: Overlay,
        public viewContainerRef: ViewContainerRef,
    ) { }

    ngOnInit() {
        if (this.parametros && this.parametros.pedido && this.parametros.pedido.CODPARC) {
            this.codParc = this.parametros.pedido.CODPARC;
            if (this.parametros.itens && this.parametros.itens.ITEM && Array.isArray(this.parametros.itens.ITEM)) {
                this.qtdItens = this.parametros.itens.ITEM.length;
            }

            this.sankhyaService.getParceiroByCodParc(this.codParc).subscribe(parceiros => {
                this.nomeParc = parceiros[0].NOMEPARC;
                this.cgcParc = parceiros[0].CGCCPF.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
                this.cidadeUf = `${parceiros[0].NOMEBAI} - ${parceiros[0].NOMECID} - ${parceiros[0].UF}`

                // Força a detecção de mudanças. No botton sheet não estava fazendo bind dos atributos, apos pesquisa
                // descoberto git do material que era um erro conhecido e esta a solução PALIATIVA.
                this.changeDetectorRef.detectChanges();
            })
        }
    }

    @Input()
    textoNotificacao: string;

    @Input()
    parametros: any;

    @Output()
    botaoDismiss = new EventEmitter();

    @Input()
    notifID: number;

    codParc = 0;
    nomeParc = '';
    cgcParc = '';
    cidadeUf = '';
    qtdItens = 0;

    cardAtual = 'Enviar';
    mensagemSucesso = '';

    aguardar() {
        this.botaoDismiss.emit('botao aguardar');
    }

    enviar() {
        const cabecalhoPedido: CabecalhoPedido = this.parametros.pedido as CabecalhoPedido;
        const itensPedido: ItensPedido = this.parametros.itens as ItensPedido;

        // Mostrando o spinner na tela
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);
        this.sankhyaService.sendPedido(cabecalhoPedido, itensPedido).subscribe(
            (res: any) => {
                overlayRef.dispose();
                this.portalJpaService.apiPostMarcarNotificacaoLida(this.notifID, 'N').subscribe(res => console.log('lida:', res));
                this.notificacaoService.removerNotifID(this.notifID);

                // console.log('Resposta do Send Pedido = ', res);
                if (res && res.serviceResponse == 'OK') {
                    this.mensagemSucesso = res.response;
                    this.cardAtual = 'Enviado';
                    // Força a detecção de mudanças. No botton sheet não estava fazendo bind dos atributos, apos pesquisa
                    // descoberto git do material que era um erro conhecido e esta a solução PALIATIVA.
                    this.changeDetectorRef.detectChanges();
                    return;
                }
                this.botaoDismiss.emit('botao enviar');
            },
            (error: any) => {
                overlayRef.dispose();

                console.log('Erro do Send Pedido = ', error)
                if (error && error.message && error.message.includes('Http failure')) {
                    this.openSnackbar('Erro de comunicação com o servidor. Verifique sua conexão e tente novamente.')
                }
                this.botaoDismiss.emit('botao enviar');
            },
        )
    }

    limpar() {
        this.portalJpaService.apiPostMarcarNotificacaoLida(this.notifID, 'N').subscribe(res => console.log('lida:', res));
        this.notificacaoService.removerNotifID(this.notifID);
        this.botaoDismiss.emit('botao limpar');
    }

    openSnackbar(mensagem: string) {
        this.snackBar.open(mensagem, 'Fechar', {
            verticalPosition: 'bottom',
            horizontalPosition: 'center',
            duration: 3000
        });
    }

}
