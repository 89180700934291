import { MatSnackBar, MatSort, MatTableDataSource } from '@angular/material';
import { Overlay } from '@angular/cdk/overlay';
import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { SpinnerComponent } from '../global-components/spinner/spinner.component';
import { PortalJpaService } from '../services/portal-jpa.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ProdutosProjeto } from '../controle-embarque/local-interfaces/produtos-projeto';
import { AutenticacaoService } from '../services/autenticacao.service';
import { ProjetoFinalizado } from './local-interfaces/projeto-finalizado.interface';

@Component({
    selector: 'app-controle-embarque-finalizado',
    templateUrl: './controle-embarque-finalizado.component.html',
    styleUrls: ['./controle-embarque-finalizado.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed, void', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class ControleEmbarqueFinalizadoComponent implements OnInit {
    constructor(
        private portalJpaService: PortalJpaService,
        public overlay: Overlay,
        public viewContainerRef: ViewContainerRef,
        private auth: AutenticacaoService,
        private snackBar: MatSnackBar
    ) {}

    ngOnInit() {
        this.getColunasUsuario();
    }

    @ViewChild('ordGeral', { static: true }) ordGeral = new MatSort();

    projetos: ProjetoFinalizado[] = [];
    projetosOriginal: ProjetoFinalizado[] = [];

    geral = new MatTableDataSource<ProjetoFinalizado>();

    filtroEmpresas = new Set<string>();

    produtos = new MatTableDataSource<ProdutosProjeto>();
    expandedElement: ProjetoFinalizado | null;

    exibeTabela = false;

    //Array informando quais campos serão exibidos em cada tabela
    columnsGeral: string[];

    columnsProdutos: string[];

    //Filtros para a pesquisa

    tipoFiltro = [
        { tipo: 'Dt. Emissão NF', valor: 'DTNF' },
        { tipo: 'Projeto', valor: 'PR' },
        { tipo: 'Numero DI', valor: 'NUMDI' },
        { tipo: 'Container', valor: 'CONT' },
        { tipo: 'Artigo', valor: 'PROD' },
        { tipo: 'Pedido', valor: 'PED' },
    ];

    valorFiltro: string;

    campo: string;

    //Quando o valor for Dt. Emissão NF
    dtIniMerc = new Date();
    dtFinMerc = new Date();

    tipoQtdDias = [
        { valor: 30, mascara: 'Últimos 30 dias' },
        { valor: 60, mascara: 'Últimos 60 dias' },
        { valor: 90, mascara: 'Últimos 90 dias' },
        { valor: 120, mascara: 'Últimos 120 dias' },
        { valor: 0, mascara: 'Todos' },
    ];

    valorQtdDias = 30;

    getProjetos() {
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

        this.portalJpaService
            .getProjetosControleEmbarqueFinalizado(this.valorQtdDias, this.valorFiltro, this.campo, this.dtIniMerc, this.dtFinMerc)
            .subscribe(
                (res: any) => {
                    if (res.serviceResponse == 'OK') {
                        this.projetos = res.response;
                        this.projetosOriginal = this.projetos;

                        this.alimentarTabela();
                        this.exibeTabela = true;
                    }

                    overlayRef.dispose();
                },
                error => {
                    overlayRef.dispose();
                    console.log(error);
                }
            );
    }

    produtosProjeto(codProj) {
        if (!this.expandedElement) return;
        this.produtos = new MatTableDataSource();
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);
        this.portalJpaService.getProdutosProjeto(codProj).subscribe(
            (res: any) => {
                const prod = res.response;

                let valor = null;
                for (let i = 0; i < prod.length; i++) {
                    if (prod[i].PRODUTO === valor) {
                        prod[i].PRODUTO = null;
                    } else {
                        valor = prod[i].PRODUTO;
                    }
                }

                let valor2 = null;
                for (let i = 0; i < prod.length; i++) {
                    if (prod[i].CODUNIPRO === valor2) {
                        prod[i].CODUNIPRO = null;
                    } else {
                        valor2 = prod[i].CODUNIPRO;
                    }
                }

                let total = '999999999';
                for (let i = 0; i < prod.length; i++) {
                    if (prod[i].CONTROLE === total) {
                        prod[i].CONTROLE = null;
                        prod[i].DESCRCOR = null;
                    }
                }

                this.produtos = prod;
                overlayRef.dispose();
            },
            erro => {
                overlayRef.dispose();
                console.log(erro);
            }
        );
    }

    //Funções do filtro das tabelas
    filtrarGeral(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.geral.filter = filterValue.trim().toLowerCase();
    }

    transformaData(value) {
        let dataStr = '';
        if (value.substring(0, 2) == 'X ') {
            dataStr = value.substring(8) + value.substring(5, 7) + value.substring(2, 4);
        } else if (value.substring(0, 4) == 'ETA ') {
            dataStr = value.substring(10) + value.substring(7, 9) + value.substring(4, 6);
        } else {
            dataStr =
                value.substring(6, 8) + value.substring(3, 5) + value.substring(0, 2) + value.substring(9, 11) + value.substring(12, 14);
        }
        return dataStr;
    }

    //Função para tratar as colunas de datas e transforma-las em string para funcionar na ordenação
    ordenaData(item, property) {
        switch (property) {
            case 'SORTIMENTO':
            case 'EMBARQUE':
            case 'CHEGADA':
            case 'FREETIMEPREV':
            case 'FREETIMEREAL':
            //case 'DTTRANSBORDO':
            //case 'VENCARMAZENAMENTO':
            case 'DATADENACIONALIZACAO':
            //case 'REMOCAO':
            case 'AMOSTRA':
            case 'CARREGAMENTO':
            case 'MERCPRONTEMBARQUE': {
                if (!item[property]) {
                    return undefined;
                }
                const value = item[property];
                let dataStr = '';
                if (value.substring(0, 2) == 'X ') {
                    dataStr = value.substring(8) + value.substring(5, 7) + value.substring(2, 4);
                } else if (value.substring(0, 4) == 'ETA ') {
                    dataStr = value.substring(10) + value.substring(7, 9) + value.substring(4, 6);
                } else {
                    dataStr =
                        value.substring(6, 8) +
                        value.substring(3, 5) +
                        value.substring(0, 2) +
                        value.substring(9, 11) +
                        value.substring(12, 14);
                }
                return dataStr;
            }
            default: {
                return item[property];
            }
        }
    }

    filtrarTabelaEmpresa(event: MouseEvent, emp: string) {
        event.stopPropagation();

        if (this.filtroEmpresas.has(emp)) {
            this.filtroEmpresas.delete(emp);
        } else {
            this.filtroEmpresas.add(emp);
        }

        const filtroEmpresasArray = Array.from(this.filtroEmpresas);

        if (filtroEmpresasArray.length > 0) {
            this.projetos = this.projetosOriginal.filter(item => {
                if (filtroEmpresasArray.includes(item.EMPRESA)) {
                    return item.EMPRESA;
                }
            });
        } else {
            this.projetos = this.projetosOriginal;
        }
        this.alimentarTabela();
    }

    corEmpresa(element: ProjetoFinalizado): string {
        if (element.EMPRESA === '2') {
            return '#C3BBFD';
        } else if (element.EMPRESA === '3') {
            return '#98FB98';
        } else if (element.EMPRESA === '4') {
            return '#8DB3FA';
        }
    }

    corCanal(element: ProjetoFinalizado): string {
        if (element.ETAPA === 'Atracado' && element.CANAL === 'VERDE') {
            return '#7CFC00';
        } else if (element.ETAPA === 'Atracado' && element.CANAL === 'VERMELHO') {
            return '#FF0000';
        } else if (element.ETAPA === 'Atracado' && element.CANAL === 'AMARELO') {
            return '#FFFF00';
        } else if (element.ETAPA === 'Atracado' && element.CANAL === 'CINZA') {
            return '#A9A9A9';
        } else if (element.ETAPA === 'Atracado' && element.CANAL === 'AG. CANAL') {
            return '#FFA500';
        }
    }

    expandir(row) {
        this.expandedElement = this.expandedElement === row ? null : row;
    }

    alimentarTabela() {
        this.geral = new MatTableDataSource<ProjetoFinalizado>();

        const geralTable = this.projetos.sort((a, b) => {
            const projetoA = a.CODPROJ || 0;
            const projetoB = b.CODPROJ || 0;
            const projeto = projetoA - projetoB;

            return projeto;
        });
        this.geral = new MatTableDataSource(geralTable);
        this.geral.sort = this.ordGeral;
        this.geral.sortingDataAccessor = this.ordenaData;

        return;
    }

    openSnackbar(mensagem: string) {
        this.snackBar.open(mensagem, 'Fechar', {
            verticalPosition: 'top',
            horizontalPosition: 'end',
            duration: 5000,
        });
    }

    alteraData(data: string) {
        if (data == 'ini') {
            if (this.dtFinMerc < this.dtIniMerc) {
                this.dtFinMerc = this.dtIniMerc;
            }
        }
    }

    filtroData = (d: Date | null): boolean => {
        const dia = d || new Date();
        return dia >= this.dtIniMerc;
    };

    getColunasUsuario() {
        if (
            [
                'hnovaes',
                'epnovaes',
                'pelia',
                'lmaturano',
                'krosa',
                'ssantanna',
                'landrade',
                'paulod',
                'evivaldi',
                'williams',
                'elnatar',
                'nvicente',
                'dcruz',
                'ksantos',
                'rcosta',
                'bpeixoto',
                'lalves',
                'lssilva',
                'varaujo',
            ].includes(this.auth.getUserName())
        ) {
            this.columnsGeral = [
                'ETAPA',
                'STATUS',
                'CODPROJ',
                //'IDENTIFICACAO',
                'SORTIMENTO',
                'MERCPRONTEMBARQUE',
                'LOCALARMAZENAGEM',
                'AMOSTRA',
                'PROGRAMACAO',
                'PARCVENDADIRETA',
                //'PARCVENDADIRETA2',
                'NOMEPARCEXP',
                'PEDIDO',
                'ARTIGOS',
                'ARMADOR',
                'AGENTEDECARGA',
                'NAVIOEMBARQUE',
                'NAVIOCHEGADA',
                'ORIGEM',
                'DESTINO',
                'EMBARQUE',
                'CHEGADA',
                //'HISTCHEGADA',
                'CONTAINER',
                'TAMANHO',
                //'TICKET',
                'BOOKING',
                'BL',
                //'PORTOTRANSBORDO',
                //'DTTRANSBORDO',
                'NUMDI',
                //'VENCARMAZENAMENTO',
                'RECINTOALFA',
                'FREETIMEPREV',
                'FREETIMEREAL',
                //'REMOCAO',
                'FRETEMARITIMO',
                'NUMERONFNACIONALIZACAO',
                'DATADENACIONALIZACAO',
                'CARREGAMENTO',
                'OBSCARREGAMENTO',
                //'VLRFRETE',
                'CANAL',
                'CAMBIO',
                'DESOVA',
                'OBS',
                'OBSIMP',
                'NUMDTC',
                'CEMERCANTE',
            ];

            this.columnsProdutos = ['PRODUTO', 'CODUNIPRO', 'CONTROLE', 'DESCRCOR', 'QTDNEG', 'SALDO', 'VENDA'];
        } else {
            this.columnsGeral = [
                'ETAPA',
                'STATUS',
                'CODPROJ',
                //'IDENTIFICACAO',
                'SORTIMENTO',
                'MERCPRONTEMBARQUE',
                'LOCALARMAZENAGEM',
                'AMOSTRA',
                'PROGRAMACAO',
                'PARCVENDADIRETA',
                //'PARCVENDADIRETA2',
                'NOMEPARCEXP',
                'PEDIDO',
                'ARTIGOS',
                'ARMADOR',
                'AGENTEDECARGA',
                'NAVIOEMBARQUE',
                'NAVIOCHEGADA',
                'ORIGEM',
                'DESTINO',
                'EMBARQUE',
                'CHEGADA',
                //'HISTCHEGADA',
                'CONTAINER',
                'TAMANHO',
                //'TICKET',
                'BOOKING',
                'BL',
                //'PORTOTRANSBORDO',
                //'DTTRANSBORDO',
                'NUMDI',
                //'VENCARMAZENAMENTO',
                'RECINTOALFA',
                'FREETIMEPREV',
                'FREETIMEREAL',
                //'REMOCAO',
                'FRETEMARITIMO',
                'NUMERONFNACIONALIZACAO',
                'DATADENACIONALIZACAO',
                'CARREGAMENTO',
                'OBSCARREGAMENTO',
                //'VLRFRETE',
                'CANAL',
                //'CAMBIO',
                'DESOVA',
                'OBS',
                'OBSIMP',
                'NUMDTC',
                'CEMERCANTE',
            ];

            this.columnsProdutos = ['PRODUTO', 'CODUNIPRO', 'CONTROLE', 'DESCRCOR', 'QTDNEG', 'SALDO', 'VENDA'];
        }
    }
}
