import { Component, Inject, OnInit } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

@Component({
    selector: 'app-bottom-sheet-pedidos-financeiro',
    templateUrl: './bottom-sheet-pedidos-financeiro.component.html',
    styleUrls: ['./bottom-sheet-pedidos-financeiro.component.scss'],
})
export class BottomSheetPedidosFinanceiroComponent implements OnInit {
    constructor(
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
        private bottomSheetRef: MatBottomSheetRef<BottomSheetPedidosFinanceiroComponent>
    ) {
        this.aprov = 0;
        this.motivo = 'CADASTRO DESATUALIZADO';
    }

    ngOnInit() {}

    aprovadores = [
        { valor: 0, nome: 'Financeiro' },
        { valor: 3, nome: 'Paulo' },
        { valor: 7, nome: 'William' },
        { valor: 6, nome: 'Eduardo' },
        { valor: 42, nome: 'Elnata' },
    ];

    motivos = [
        { nome: 'CADASTRO DESATUALIZADO' },
        { nome: 'LIMITE' },
        { nome: 'RESTRIÇÃO NO CENPROT' },
        { nome: 'RESTRIÇÃO NO SERASA' },
        { nome: 'TÍTULO EM ATRASO' },
    ];

    observacoes = [{ obs: '' }, { obs: 'CONSULTAR O FINANCEIRO ANTES DE FATURAR' }, { obs: 'INFORMAR O FINANCEIRO APÓS FATURAR' }];

    aprov: number;
    motivo: string;
    observacao: string = '';

    close(tipo: string) {
        let param = {
            tipo: tipo,
            aprov: this.aprov,
            motivo: this.motivo,
            observacao: this.observacao,
        };

        this.bottomSheetRef.dismiss(param);
    }
}
