import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-card-cabecalho',
    templateUrl: './card-cabecalho.component.html',
    styleUrls: ['./card-cabecalho.component.scss'],
})
export class CardCabecalhoComponent implements OnInit {
    constructor() {}

    @Input() titulo = '';
    @Input() conteudo: any;
    @Input() tipoPipe: string = 'nenhum';

    ngOnInit() {}
}
