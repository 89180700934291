import { Component, Input, OnInit } from '@angular/core';
import { PortalServiceService } from 'src/app/services/portal-service.service';

@Component({
    selector: 'app-relatorio-ficha-tecnica-produto',
    templateUrl: './relatorio-ficha-tecnica-produto.component.html',
    styleUrls: ['./relatorio-ficha-tecnica-produto.component.scss'],
})
export class RelatorioFichaTecnicaProdutoComponent implements OnInit {
    constructor(private portalService: PortalServiceService) {}

    ngOnInit() {}

    @Input() restUrl: string;

    codProd: number;
    controle: string;

    gerarPdf() {
        if (!this.codProd || this.codProd == 0 || !this.controle) {
            return;
        }

        this.portalService.imprimirFichaTecnicaProduto(this.codProd, this.controle);
    }
}
