import { AfterViewInit, Component, Input, OnInit, ViewContainerRef } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PortalServiceService } from '../../services/portal-service.service';
import { Projeto } from 'src/app/global-interfaces/projeto-interface';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { SpinnerComponent } from 'src/app/global-components/spinner/spinner.component';
import { SankhyaServiceService } from 'src/app/services/sankhya-service.service';
import { AutenticacaoService } from 'src/app/services/autenticacao.service';
import { MatBottomSheet } from '@angular/material';
import { BottomSheetGrupoDeProdutoComponent } from 'src/app/global-components/bottom-sheet-grupo-de-produto/bottom-sheet-grupo-de-produto.component';

@Component({
    selector: 'app-relatorio-programado',
    templateUrl: './relatorio-programado.component.html',
    styleUrls: ['./relatorio-programado.component.scss', '../relatorios.component.scss'],
})
export class RelatorioProgramadoComponent implements OnInit, AfterViewInit {
    constructor(
        private snackBar: MatSnackBar,
        private portalService: PortalServiceService,
        public overlay: Overlay,
        public viewContainerRef: ViewContainerRef,
        private sankhyaService: SankhyaServiceService,
        private autenticacao: AutenticacaoService,
        private bottomSheet: MatBottomSheet,
        private auth: AutenticacaoService
    ) {
        this.codRep = +auth.getCodRep();
        this.isTodos = true;
        this.projetosRelatorio = [];
        this.codEmp = [0];
    }

    ngOnInit() {}

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.listaEmpresas = this.sankhyaService.listaEmpresas;
            this.listaEmpresas.forEach(empresa => {
                if (empresa == 1 || empresa == 2 || empresa == 4) {
                    this.codEmp.push(empresa);
                }
            });
            this.nomeEmpresas = this.sankhyaService.nomeEmpresas;
        }, 1500);
    }

    @Input() restUrl: string;
    @Input() listaGrupoProd = [];

    // Variável que controla o disabled dos componentes de acordo com o toggle "Todos"
    isTodos: boolean;

    projetoSelecionado: Projeto;
    projetosRelatorio: Projeto[];
    nomeEmpresas: any;
    listaEmpresas: number[] = [];
    codEmp: number[] = [];

    codRep: number;

    toggleTodos() {
        this.isTodos = !this.isTodos;
        this.limparProjetosRelatorio();
    }

    limparProjetosRelatorio() {
        this.projetosRelatorio = [];
    }

    inserirProjeto() {
        this.projetosRelatorio.push(this.projetoSelecionado);

        this.projetoSelecionado = {
            ABREVIATURA: null,
            CODPROJ: null,
        } as Projeto;
    }

    limpaCampo() {
        this.projetoSelecionado = {
            ABREVIATURA: null,
            CODPROJ: null,
        } as Projeto;
    }

    deletarProjetoFiltro(index: number) {
        this.projetosRelatorio.splice(index, 1);
    }

    toggleEmp(empresa: any, event: any) {
        if (event.checked) {
            this.codEmp.push(empresa);
        } else {
            this.codEmp = this.codEmp.filter(x => x != empresa);
        }
    }

    gerarPdf() {
        if (this.codEmp.length === 1 && this.codEmp[0] === 0) {
            this.openSnackbar('Escolha ao menos uma empresa para exibir o saldo!');
            return false;
        }

        this.codEmp.sort();

        let bodyRequest = {};
        let listaProjetos = '';

        if (this.isTodos) {
            bodyRequest = {
                relatorio: 'programado',
                isTodos: true,
                params: [],
            };
        } else {
            if (this.projetosRelatorio.length === 0) {
                this.snackBar.open('Informe os itens no campo ou ative a opção "Todos"!', 'Fechar', {
                    verticalPosition: 'top',
                    horizontalPosition: 'end',
                    duration: 5000,
                });

                return false;
            } else {
                const codigosProjetos = this.projetosRelatorio.map(projeto => {
                    listaProjetos = listaProjetos === '' ? `${projeto.CODPROJ}` : `${listaProjetos},${projeto.CODPROJ}`;

                    return projeto.CODPROJ;
                });

                bodyRequest = {
                    relatorio: 'programado',
                    isTodos: false,
                    params: codigosProjetos,
                };
            }
        }

        // Mostrando o spinner na tela
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);
        /*
        this.sankhyaService.getPermissaoUsu().subscribe((retorno: any) => {

            const ativo = retorno[0].ATIVO;

            if (ativo === 'S') {

                this.portalService.imprimirGeral({ params: { LISTAPROJETOS: listaProjetos, LISTEMP: this.codEmp.toString() }, url: this.restUrl + '.pdf' });
            } else {

                location.reload();
            }
        },  (error) => {

            location.reload();
        }); */

        let gruposDeProduto = '';

        this.listaGrupoProd.map(grupo => {
            if (gruposDeProduto === '' && grupo.isAtivo) {
                gruposDeProduto = `${grupo.CODGRUPOPROD}`;
            } else if (gruposDeProduto !== '' && grupo.isAtivo) {
                gruposDeProduto = `${gruposDeProduto},${grupo.CODGRUPOPROD}`;
            }
        });

        this.portalService.imprimirGeral({
            params: {
                LISTAPROJETOS: listaProjetos,
                LISTEMP: this.codEmp.toString(),
                USULOGIN: this.autenticacao.getUserName(),
                LISTGRUPOPROD: gruposDeProduto,
            },
            url: this.restUrl + '.pdf',
        });

        // Removendo o spinner da tela
        overlayRef.dispose();
    }

    getNomeEmp(codEmp: number) {
        let ret: any = this.nomeEmpresas.find(emp => emp.CODEMP === codEmp);
        return ret.NOMEFANTASIA;
    }

    openSnackbar(message: string) {
        this.snackBar.open(message, 'Fechar', {
            horizontalPosition: 'end',
            verticalPosition: 'top',
            duration: 5000,
        });
    }

    selecionaGrupoProd() {
        this.bottomSheet.open(BottomSheetGrupoDeProdutoComponent, { disableClose: true, data: this.listaGrupoProd });
    }
}
