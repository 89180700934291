import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, mergeMap, switchMap, tap } from 'rxjs/operators';

import { TipoNegociacao } from 'src/app/global-interfaces/tipo-negociacao-interface';
import { PortalJpaService } from 'src/app/services/portal-jpa.service';

@Component({
    selector: 'app-autocomplete-tipo-negociacao-parcela',
    templateUrl: './autocomplete-tipo-negociacao-parcela.component.html',
    styleUrls: ['./autocomplete-tipo-negociacao-parcela.component.scss'],
})
export class AutocompleteTipoNegociacaoParcelaComponent implements OnInit, OnChanges {
    @Input()
    tipoNegociacaoAtual: TipoNegociacao;

    @Input()
    codParc: number;

    @Input()
    autocompleteEnabled: boolean = true;

    @Output()
    tipoNegociacaoSelecionado = new EventEmitter<TipoNegociacao>();

    tipoNegociacaoFormControl = new FormControl('');
    listaParcelasFilter: Observable<TipoNegociacao[]> = new Observable<TipoNegociacao[]>();
    apiErrorMessage: string = '';

    constructor(private jpaService: PortalJpaService) {}

    ngOnInit(): void {
        if (this.tipoNegociacaoAtual) {
            this.tipoNegociacaoFormControl.setValue(this.tipoNegociacaoAtual);
        }

        //inicializar o listaParcelasFilter
        this.listaParcelasFilter = this.tipoNegociacaoFormControl.valueChanges.pipe(
            debounceTime(1200),
            switchMap((value: string | TipoNegociacao) => {
                this.apiErrorMessage = '';
                if (!value || (typeof value === 'string' && value.length < 1)) {
                    if (typeof value === 'string' && value.length < 1) {
                        this.handleSelecionado(undefined);
                    }
                    return of([]);
                }

                if (typeof value === 'object') {
                    if (!value.CODTIPVENDA) {
                        return of([]);
                    }
                    if (value.CODTIPVENDA == this.tipoNegociacaoAtual.CODTIPVENDA) {
                        // evita uma pesquisa na api para buscar o mesmo registro.
                        return of([{ ...this.tipoNegociacaoAtual }]);
                    }
                }

                const filtro = typeof value === 'string' ? value.toUpperCase() : value.DESCRTIPVENDA;

                return this.jpaService.autocompleteTipoNegociacaoParcela(filtro, this.codParc).pipe(
                    tap((res: any) => {
                        if (res && res.serviceResponse == 'ERROR') {
                            this.tipoNegociacaoFormControl.setErrors({ errorCtrl: true });
                            this.apiErrorMessage = res.response;
                        }
                    }),
                    catchError(error => {
                        console.log('Erro na api:', error);
                        this.tipoNegociacaoFormControl.setErrors({ errorCtrl: true });
                        this.apiErrorMessage = error.message;

                        return of([]);
                    })
                );
            }),
            mergeMap((res: any): Observable<TipoNegociacao[]> => {
                if (res.serviceResponse && res.serviceResponse == 'OK') {
                    const lista: TipoNegociacao[] = res.response;
                    return of(lista);
                }
                return of([] as TipoNegociacao[]);
            })
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.tipoNegociacaoAtual) {
            this.tipoNegociacaoFormControl.setValue(this.tipoNegociacaoAtual);
        }

        if (changes.autocompleteEnabled) {
            if (changes.autocompleteEnabled.currentValue) {
                this.tipoNegociacaoFormControl.enable();
            } else {
                this.tipoNegociacaoFormControl.disable();
            }
        }

        if (changes.tipoNegociacaoAtual) {
            if (
                changes.tipoNegociacaoAtual.currentValue.CODTIPVENDA == 0 &&
                typeof this.tipoNegociacaoFormControl.value === 'object' &&
                this.tipoNegociacaoFormControl.value.CODTIPVENDA > 0
            ) {
                // O valor atual é um registro vazio, mas o controle tem um objeto peenchido, limpar o controle
                this.tipoNegociacaoFormControl.reset('');
            }

            if (changes.tipoNegociacaoAtual.currentValue.CODTIPVENDA > 0) {
                this.tipoNegociacaoFormControl.setValue(this.tipoNegociacaoAtual);
            }
        }
    }

    getDescricao(tipoNegociacao: TipoNegociacao): string {
        return tipoNegociacao && tipoNegociacao.DESCRTIPVENDA ? tipoNegociacao.DESCRTIPVENDA.toString() : '';
    }

    handleSelecionado(tipoNegociacao: TipoNegociacao) {
        this.tipoNegociacaoSelecionado.emit(tipoNegociacao);
    }

    handleClose() {
        if (typeof this.tipoNegociacaoFormControl.value != 'string') {
            return;
        }
        // Limpa o campo se nada foi selecionado.
        this.tipoNegociacaoFormControl.reset('');
    }
}
