import { Component, OnInit, OnDestroy, ViewContainerRef } from '@angular/core';
import { PedidosStatus, TipoStatusPedido } from '../global-interfaces/pedidos-status-interface';
import { AutenticacaoService } from '../services/autenticacao.service';
import { SankhyaServiceService } from '../services/sankhya-service.service';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { PedidosPesquisa } from '../global-interfaces/pedidos-pesquisa-interface';
import { SpinnerComponent } from '../global-components/spinner/spinner.component';
import { ComponentPortal } from '@angular/cdk/portal';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BottomSheetComponent } from '../global-components/bottom-sheet/bottom-sheet.component';
import { AppComponent } from '../app.component';
import { resolve } from 'url';

@Component({
  selector: 'app-pedidos-operacional',
  templateUrl: './pedidos-operacional.component.html',
  styleUrls: ['./pedidos-operacional.component.scss']
})
export class PedidosOperacionalComponent implements OnInit, OnDestroy {

    constructor(private auth: AutenticacaoService,
        public overlay: Overlay,
        public viewContainerRef: ViewContainerRef,
        private sankhyaService: SankhyaServiceService,
        private bottomSheet: MatBottomSheet,
        private app: AppComponent) {
        this.auth.autenticar();
    }

    ngOnInit() {
        this.statusSelecionado = 4;

        this.atualizacaoAutomatica = setInterval(() => this.atualizar(), 180000);

        this.tiposStatusPedidos = [
            { DESCRICAO: 'Proposta', VALUE: TipoStatusPedido.PROPOSTA },
            { DESCRICAO: 'Proposta Aprovada', VALUE: TipoStatusPedido.PROPOSTAAPROVADA },
            { DESCRICAO: 'Confirmado', VALUE: TipoStatusPedido.CONFIRMADO },
            { DESCRICAO: 'Liberado Separar', VALUE: TipoStatusPedido.LIBERADOSEPARAR },
            { DESCRICAO: 'Separado', VALUE: TipoStatusPedido.SEPARADO },
            { DESCRICAO: 'Faturado', VALUE: TipoStatusPedido.FATURADO },
            { DESCRICAO: 'Cancelado', VALUE: TipoStatusPedido.RECUSADO }
        ];
    }

    ngOnDestroy() {
        clearInterval(this.atualizacaoAutomatica);
    }

    tiposStatusPedidos: PedidosStatus[];
    pedidos: PedidosPesquisa[];

    statusSelecionado: number;

    atualizacaoAutomatica: any;

    liberado: number = 0;

    emSeparacao: number = 0;

    atualizar() {
        
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);
        
        this.sankhyaService.getPedidosOperacional(0, 200, this.statusSelecionado)
            .subscribe(
                (response: any) => {
                    this.emSeparacao = 0;
                    this.liberado = 0;
                    response.forEach(element => {
                        if (element.STATUS === "EM SEPARACAO") {
                            this.emSeparacao += 1
                        }
                        if (element.STATUS === "LIBERADO SEPARAR") {
                            this.liberado += 1
                        }
                    });

                    if (response.serviceResponse) {

                        const param = 'carregarPedidos';
                        const erro = response;

                        this.bottomSheet.open(BottomSheetComponent, {
                            data: { param, erro },
                            panelClass: 'bottom-sheet'
                        });

                        if (response.exibeBadge) {

                        this.app.setError(response.response);
                        }
                    } else {

                        this.pedidos = response;
                        this.bottomSheet.dismiss();
                    }
                },  (erro) => {

                        // BottomSheet
                        const param = 'carregarPedidos';

                        this.bottomSheet.open(BottomSheetComponent, {
                            data: { param, erro },
                            panelClass: 'bottom-sheet'
                        });

                        // Removendo o spinner da tela
                        overlayRef.dispose();
                    },
                () => {
                // Removendo o spinner da tela
                overlayRef.dispose();
                }
                );
    }
}
