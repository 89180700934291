import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BarcodeFormat } from '@zxing/library';
import { BehaviorSubject } from 'rxjs';
import { TransfereService } from 'src/app/services/transfere-dado.service';

@Component({
    selector: 'app-compartilhar-dialog',
    templateUrl: './dialog-qrcode.component.html',
    styleUrls: ['./dialog-qrcode.component.scss'],
})
export class DialogQrCodeComponent {
    availableDevices: MediaDeviceInfo[];
    currentDevice: MediaDeviceInfo = null;

    formatsEnabled: BarcodeFormat[] = [BarcodeFormat.CODE_128, BarcodeFormat.DATA_MATRIX, BarcodeFormat.EAN_13, BarcodeFormat.QR_CODE];

    hasDevices: boolean;
    hasPermission: boolean;

    qrResultString: string;

    torchEnabled = false;
    torchAvailable$ = new BehaviorSubject<boolean>(false);
    tryHarder = false;

    constructor(
        private readonly _dialog: MatDialog,
        private dialog: MatDialog,
        private transfereService: TransfereService,
        public dialogRef: MatDialogRef<DialogQrCodeComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    clearResult(): void {
        this.qrResultString = null;
    }

    onCamerasFound(devices: MediaDeviceInfo[]): void {
        this.availableDevices = devices;
        this.hasDevices = Boolean(devices && devices.length);
    }

    onCodeResult(resultString: string) {
        if (resultString.includes('-')) {
            this.qrResultString = resultString.substring(resultString.indexOf('|') + 1, resultString.indexOf('-')).trim();
        } else {
            this.qrResultString = resultString.split('|')[1];
        }
        this.dialogRef.close(this.qrResultString);
    }

    onDeviceSelectChange(selected: string) {
        const device = this.availableDevices.find(x => x.deviceId === selected);
        this.currentDevice = device || null;
    }

    onHasPermission(has: boolean) {
        this.hasPermission = has;
    }

    onTorchCompatible(isCompatible: boolean): void {
        this.torchAvailable$.next(isCompatible || false);
    }

    toggleTorch(): void {
        this.torchEnabled = !this.torchEnabled;
    }

    toggleTryHarder(): void {
        this.tryHarder = !this.tryHarder;
    }
}
