import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, mergeMap, switchMap, tap } from 'rxjs/operators';

import { Parceiro } from 'src/app/global-interfaces/parceiro-interface';
import { PortalJpaService } from 'src/app/services/portal-jpa.service';

@Component({
    selector: 'app-autocomplete-transportadora',
    templateUrl: './autocomplete-transportadora.component.html',
    styleUrls: ['./autocomplete-transportadora.component.scss'],
})
export class AutocompleteTransportadoraComponent implements OnInit, OnChanges {
    @Input()
    transportadoraAtual: Parceiro;

    @Input()
    autocompleteEnabled: boolean = true;

    @Output()
    transportadoraSelecionada = new EventEmitter<Parceiro>();

    transportadoraFormControl = new FormControl('');
    listaTransportadorasFilter: Observable<Parceiro[]> = new Observable<Parceiro[]>();
    apiErrorMessage: string = '';

    constructor(private jpaService: PortalJpaService) {}

    ngOnInit(): void {
        if (this.transportadoraAtual) {
            this.transportadoraFormControl.setValue(this.transportadoraAtual);
        }

        //inicializar o listaTransportadorasFilter
        this.listaTransportadorasFilter = this.transportadoraFormControl.valueChanges.pipe(
            debounceTime(1200),
            switchMap((value: string | Parceiro) => {
                this.apiErrorMessage = '';
                if (!value || (typeof value === 'string' && value.length < 1)) {
                    if (typeof value === 'string' && value.length < 1) {
                        this.handleTransportadoraSelecionado(undefined);
                    }
                    return of([]);
                }

                if (typeof value === 'object') {
                    if (!value.RAZAOSOCIAL) {
                        return of([]);
                    }
                    if (value.CODPARC == this.transportadoraAtual.CODPARC) {
                        // evita uma pesquisa na api para buscar o mesmo transportadora.
                        return of([{ ...this.transportadoraAtual }]);
                    }
                }

                const filtro = typeof value === 'string' ? value.toUpperCase() : value.RAZAOSOCIAL;

                return this.jpaService.autocompleteTransportadora(filtro).pipe(
                    tap((res: any) => {
                        if (res && res.serviceResponse == 'ERROR') {
                            this.transportadoraFormControl.setErrors({ errorCtrl: true });
                            this.apiErrorMessage = res.response;
                        }
                    }),
                    catchError(error => {
                        console.log('Erro na api:', error);
                        this.transportadoraFormControl.setErrors({ errorCtrl: true });
                        this.apiErrorMessage = error.message;

                        return of([]);
                    })
                );
            }),
            mergeMap((res: any): Observable<Parceiro[]> => {
                if (res.serviceResponse && res.serviceResponse == 'OK') {
                    const lista: Parceiro[] = res.response;
                    return of(lista);
                }
                return of([] as Parceiro[]);
            })
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.transportadoraAtual) {
            this.transportadoraFormControl.setValue(this.transportadoraAtual);
        }

        if (changes.autocompleteEnabled) {
            if (changes.autocompleteEnabled.currentValue) {
                this.transportadoraFormControl.enable();
            } else {
                this.transportadoraFormControl.disable();
            }
        }

        if (changes.transportadoraAtual) {
            if (
                changes.transportadoraAtual.currentValue.CODPARC == 0 &&
                typeof this.transportadoraFormControl.value === 'object' &&
                this.transportadoraFormControl.value.CODPARC > 0
            ) {
                // O valor atual é um transportadora vazio, mas o controle tem um objeto transportadora, limpar o controle
                this.transportadoraFormControl.reset('');
            }
        }
    }

    getDescricaoTransportadora(transportadora: Parceiro): string {
        return transportadora && transportadora.RAZAOSOCIAL ? transportadora.RAZAOSOCIAL.toString() : '';
    }

    handleTransportadoraSelecionado(transportadora: Parceiro) {
        this.transportadoraSelecionada.emit(transportadora);
    }

    handleClose() {
        if (typeof this.transportadoraFormControl.value != 'string') {
            return;
        }
        // Limpa o campo se nada foi selecionado.
        this.transportadoraFormControl.reset('');
    }
}
