import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { PortalJpaService } from '../services/portal-jpa.service';
import { Response } from '../global-interfaces/response';
import { AgendaUsuario } from './local-interfaces/agenda-usuario';
import { SpinnerComponent } from '../global-components/spinner/spinner.component';
import { Overlay } from '@angular/cdk/overlay';
import { AgendaSalas, Agendamentos } from './local-interfaces/agendamentos-salas';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { DialogComponent } from '../global-components/dialog/dialog.component';
import { PortalServiceService } from '../services/portal-service.service';
import { formatDate } from '@angular/common';
import { Pagina } from '../global-interfaces/pagina-interface';

@Component({
    selector: 'app-agendamento-reuniao',
    templateUrl: './agendamento-reuniao.component.html',
    styleUrls: ['./agendamento-reuniao.component.scss'],
})
export class AgendamentoReuniaoComponent implements OnInit {
    constructor(
        private portalJpaService: PortalJpaService,
        public overlay: Overlay,
        public viewContainerRef: ViewContainerRef,
        private router: Router,
        public dialog: MatDialog,
        public portalService: PortalServiceService
    ) {}

    listaColunas = ['DTAGEND', 'HRAGEND', 'NOMESALA', 'ACOES'];

    agenda: AgendaUsuario[];
    agendaPaginacao: AgendaUsuario[];
    salas: AgendaSalas[];
    agendamentos: Agendamentos[];
    possuiAgendamentos: boolean;
    horaAtual = new Date().getHours();
    dataAtual = formatDate(new Date(), 'dd/MM/yyyy', 'pt-br');
    possuiAgenda: boolean;
    mostraConteudo: boolean = false;
    data = new Date();
    desativa: boolean = true;
    paginaCorrente = 1;

    ngOnInit() {
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);
        this.portalJpaService.getAgendamentos(this.data).subscribe(
            (res: Response) => {
                this.agenda = res.response.agendaUsuario;
                this.agendaPaginacao = this.agenda;
                this.salas = res.response.agendaSalas;
                this.paginaCorrente = 1;

                this.verificaAgendamentos();
                this.verificaAgenda();

                overlayRef.dispose();
                this.mostraConteudo = true;
                this.agenda = this.agenda.slice(0,30);
            },
            error => {
                console.error(error);
                overlayRef.dispose();
            }
        );
    }

    //Metodo de paginator
    paginar(event: Pagina) {
        this.agenda = this.agendaPaginacao.slice(event.startIndex, event.endIndex + 1);
    }

    novoAgendamento() {
        this.router.navigateByUrl('/agendamentos/novo-agendamento', { state: { agendamentos: JSON.stringify('agendamentos') } });
    }

    cancelarAgendamento(agendaUsu: AgendaUsuario) {
        if (!this.verifica(agendaUsu)) {
            this.dialog
                .open(DialogComponent, {
                    width: '400px',
                    data: { tipo: 'cancelarAgendamento', params: agendaUsu },
                })
                .afterClosed()
                .subscribe((res: any) => {
                    if (res) {
                        if (res.retorno) {
                            const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);
                            this.portalJpaService.cancelaAgendamento(agendaUsu.ID, res.params.observacao).subscribe(
                                (ret: Response) => {
                                    this.portalJpaService.getAgendamentos(this.data).subscribe(
                                        (res: Response) => {
                                            this.agenda = res.response.agendaUsuario;
                                            this.salas = res.response.agendaSalas;
                                            this.verificaAgendamentos();
                                            this.verificaAgenda();
                                            overlayRef.dispose();
                                            this.portalService.openSnackbar(ret.response.cancelaReuniao);
                                        },
                                        error => {
                                            console.error(error);
                                            overlayRef.dispose();
                                        }
                                    );
                                },
                                error => {
                                    console.error(error);
                                    overlayRef.dispose();
                                }
                            );
                        }
                    }
                });
        }
    }

    verifica(agendaUsu: AgendaUsuario) {
        return agendaUsu.HRAGEND <= this.horaAtual && formatDate(agendaUsu.DTAGEND, 'dd/MM/yyyy', 'pt-br') === this.dataAtual;
    }

    verificaAgenda() {
        if (this.salas.length > 0) {
            this.possuiAgenda = true;
        } else {
            this.possuiAgenda = false;
        }
    }

    verificaAgendamentos() {
        if (this.agenda.length > 0) {
            this.possuiAgendamentos = true;
        } else {
            this.possuiAgendamentos = false;
        }
    }

    filtroData = (d: Date | null): boolean => {
        const dia = d || new Date();
        const semana = (d || new Date()).getDay();
        const mes = (d || new Date()).getMonth();
        return (
            (dia >= new Date() || dia.getDate() == new Date().getDate() && dia.getMonth() == new Date().getMonth()) 
            && (semana != 0 && semana != 6)
        )
    };

    addEvent() {

        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);
        this.portalJpaService.getAgendamentos(this.data).subscribe(
            (res: Response) => {
                this.agenda = res.response.agendaUsuario;
                this.salas = res.response.agendaSalas;

                this.verificaAgendamentos();
                this.verificaAgenda();

                overlayRef.dispose();
                this.mostraConteudo = true;
            },
            error => {
                console.error(error);
                overlayRef.dispose();
            }
        );
    }

    alteraData(n: number) {
        let novaData: Date;
        novaData = new Date(this.data.setDate(this.data.getDate() + n));

        if (n == 1) {
            novaData.getDay() == 0 ? (novaData = new Date(novaData.setDate(novaData.getDate() + 1))) : false;
            novaData.getDay() == 6 ? (novaData = new Date(novaData.setDate(novaData.getDate() + 2))) : false;
        } else {
            novaData.getDay() == 0 ? (novaData = new Date(novaData.setDate(novaData.getDate() - 2))) : false;
        }

        this.data = novaData;
        novaData <= new Date() ? (this.desativa = true) : (this.desativa = false);

        this.addEvent();
    }
}
