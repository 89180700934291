import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appBackground]'
})
export class BackgroundDirective {
  @Input() perguntaCorreta: boolean = false;

  constructor(private elRef: ElementRef, private renderer: Renderer2) { }

  @HostListener('click') repostaCerta() {
    if (this.perguntaCorreta) {
      this.renderer.setStyle(this.elRef.nativeElement, 'background-color', '#64BC43')
      this.renderer.setStyle(this.elRef.nativeElement, 'border', '2px solid #3D9F22')
      this.renderer.setStyle(this.elRef.nativeElement, 'color', '#3D9F22')
    } else {
      this.renderer.setStyle(this.elRef.nativeElement, 'background-color', '#A60635')
      this.renderer.setStyle(this.elRef.nativeElement, 'border', '2px solid #830219')
      this.renderer.setStyle(this.elRef.nativeElement, 'color', '#830219')
    }
  }
}
