import { MatSort, MatTableDataSource } from '@angular/material';
import { Overlay } from '@angular/cdk/overlay';
import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { SpinnerComponent } from '../global-components/spinner/spinner.component';
import { PortalJpaService } from '../services/portal-jpa.service';
import { Projeto } from '../controle-embarque/local-interfaces/projeto.interface';

@Component({
    selector: 'app-controle-embarque-moveleiro',
    templateUrl: './controle-embarque-moveleiro.component.html',
    styleUrls: ['./controle-embarque-moveleiro.component.scss'],
})
export class ControleEmbarqueMoveleiroComponent implements OnInit {
    constructor(private portalJpaService: PortalJpaService, public overlay: Overlay, public viewContainerRef: ViewContainerRef) {}

    ngOnInit() {
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

        this.portalJpaService.getProjetosControleEmbarqueMoveleiro().subscribe(
            (res: any) => {
                this.projetos = res.response;

                //Tabela Geral
                const tabelaGeral = this.projetos;
                this.geral = new MatTableDataSource(tabelaGeral);
                this.geral.sort = this.ordGeral;
                this.geral.sortingDataAccessor = this.ordenaData;

                //Tabela Comprado/Previsto
                const previstoTable = this.projetos.filter(element => {
                    return element.ETAPA === 'Comprado' || element.ETAPA === 'Sortimento';
                });
                this.previsto = new MatTableDataSource(previstoTable);
                this.previsto.sort = this.ordPrevisto;
                this.previsto.sortingDataAccessor = this.ordenaData;

                //Tabela Previsto Embarque
                const previstoEmbarqueTable = this.projetos.filter(element => element.ETAPA === 'Previsto Embarque');
                previstoEmbarqueTable.sort((a, b) => {
                    const embarqueA = a.EMBARQUE ? this.transformaData(a.EMBARQUE) : '999999';
                    const embarqueB = b.EMBARQUE ? this.transformaData(b.EMBARQUE) : '999999';
                    const embarque = embarqueA.localeCompare(embarqueB);

                    const navioA = a.NAVIOCHEGADA || '';
                    const navioB = b.NAVIOCHEGADA || '';
                    const navio = navioA.localeCompare(navioB);

                    const projetoA = a.CODPROJ || 0;
                    const projetoB = b.CODPROJ || 0;
                    const projeto = projetoA - projetoB;

                    return embarque || navio || projeto;
                });
                this.previstoEmbarque = new MatTableDataSource(previstoEmbarqueTable);
                this.previstoEmbarque.sort = this.ordPrevistoEmbarque;
                this.previstoEmbarque.sortingDataAccessor = this.ordenaData;

                //Tabela Embarcado
                const embarcadoTable = this.projetos.filter(element => element.ETAPA === 'Embarcado');
                embarcadoTable.sort((a, b) => {
                    const chegadaA = a.CHEGADA ? this.transformaData(a.CHEGADA) : '999999';
                    const chegadaB = b.CHEGADA ? this.transformaData(b.CHEGADA) : '999999';
                    const chegada = chegadaA.localeCompare(chegadaB);

                    const navioA = a.NAVIOCHEGADA || '';
                    const navioB = b.NAVIOCHEGADA || '';
                    const navio = navioA.localeCompare(navioB);

                    const projetoA = a.CODPROJ || 0;
                    const projetoB = b.CODPROJ || 0;
                    const projeto = projetoA - projetoB;

                    return chegada || navio || projeto;
                });
                this.embarcado = new MatTableDataSource(embarcadoTable);
                this.embarcado.sort = this.ordEmbarcado;
                this.embarcado.sortingDataAccessor = this.ordenaData;

                //Tabela Atracado
                const atracadoTable = this.projetos.filter(element => element.ETAPA === 'Atracado');
                this.atracado = new MatTableDataSource(atracadoTable);
                this.atracado.sort = this.ordAtracado;
                this.atracado.sortingDataAccessor = this.ordenaData;

                //Tabela Pátio
                const patioTable = this.projetos.filter(element => element.ETAPA === 'Pátio');
                patioTable.sort((a, b) => {
                    const localA = parseFloat(a.LOCALARMAZENAGEM ? a.LOCALARMAZENAGEM : '0');
                    const localB = parseFloat(b.LOCALARMAZENAGEM ? b.LOCALARMAZENAGEM : '0');
                    return localA - localB;
                });
                this.patio = new MatTableDataSource(patioTable);
                this.patio.sort = this.ordPatio;
                this.patio.sortingDataAccessor = this.ordenaData;

                //Contador do pátio (lugares ocupados / total de lugares)
                patioTable.forEach(element => {
                    if (element.LOCALARMAZENAGEM) {
                        this.locaisUsados.push(+element.LOCALARMAZENAGEM);
                    }
                });

                this.qtdLocaisUsados = [...new Set(this.locaisUsados)].length;

                overlayRef.dispose();
            },
            error => {
                overlayRef.dispose();
                console.log(error);
            }
        );
    }

    @ViewChild('ordGeral', { static: true }) ordGeral = new MatSort();
    @ViewChild('ordPrevisto', { static: true }) ordPrevisto = new MatSort();
    @ViewChild('ordPrevistoEmbarque', { static: true }) ordPrevistoEmbarque = new MatSort();
    @ViewChild('ordEmbarcado', { static: true }) ordEmbarcado = new MatSort();
    @ViewChild('ordAtracado', { static: true }) ordAtracado = new MatSort();
    @ViewChild('ordPatio', { static: true }) ordPatio = new MatSort();

    projetos: Projeto[] = [];

    geral = new MatTableDataSource();
    previsto = new MatTableDataSource();
    previstoEmbarque = new MatTableDataSource();
    embarcado = new MatTableDataSource();
    atracado = new MatTableDataSource();
    patio = new MatTableDataSource();
    locaisUsados: number[] = [];
    qtdLocaisUsados: number = 0;

    //Checkbox marcada por padrão
    checkboxGeral = true;
    checkboxPrevisto = false;
    checkboxPrevistoEmbarque = false;
    checkboxEmbarcado = false;
    checkboxAtracado = false;
    checkboxPatio = false;

    //Array informando quais campos serão exibidos em cada tabela
    columnsGeral: string[] = [
        'ETAPA',
        'STATUS',
        'CODPROJ',
        'IDENTIFICACAO',
        'SORTIMENTO',
        'MERCPRONTEMBARQUE',
        'LOCALARMAZENAGEM',
        'AMOSTRA',
        'PROGRAMACAO',
        'PARCVENDADIRETA',
        'PARCVENDADIRETA2',
        'NOMEPARCEXP',
        'PEDIDO',
        'ARTIGOS',
        'ARMADOR',
        'AGENTEDECARGA',
        'NAVIOEMBARQUE',
        'NAVIOCHEGADA',
        'ORIGEM',
        'DESTINO',
        'EMBARQUE',
        'CHEGADA',
        'CONTAINER',
        'TAMANHO',
        'TICKET',
        'BOOKING',
        'BL',
        'PORTOTRANSBORDO',
        'DTTRANSBORDO',
        'NUMDI',
        'VENCARMAZENAMENTO',
        'RECINTOALFA',
        'FREETIME',
        'REMOCAO',
        'FRETEMARITIMO',
        'OBS',
        'NUMERONFNACIONALIZACAO',
        'DATADENACIONALIZACAO',
        'CARREGAMENTO',
        'OBSCARREGAMENTO',
    ];

    columnsPrevisto: string[] = [
        // 'ETAPA',
        'STATUS',
        'CODPROJ',
        // 'IDENTIFICACAO',
        'SORTIMENTO',
        'MERCPRONTEMBARQUE',
        // 'LOCALARMAZENAGEM',
        'AMOSTRA',
        'PROGRAMACAO',
        'PARCVENDADIRETA',
        'PARCVENDADIRETA2',
        'NOMEPARCEXP',
        'PEDIDO',
        'ARTIGOS',
        // 'ARMADOR',
        // 'AGENTEDECARGA',
        // 'NAVIOEMBARQUE',
        // 'NAVIOCHEGADA',
        // 'ORIGEM',
        // 'DESTINO',
        'EMBARQUE',
        // 'CHEGADA',
        // 'CONTAINER',
        // 'TAMANHO',
        // 'TICKET',
        // 'BOOKING',
        // 'BL',
        // 'PORTOTRANSBORDO',
        // 'DTTRANSBORDO',
        // 'NUMDI',
        // 'VENCARMAZENAMENTO',
        // 'RECINTOALFA',
        // 'FREETIME',
        // 'REMOCAO',
        'FRETEMARITIMO',
        'OBS',
        // 'NUMERONFNACIONALIZACAO',
        // 'DATADENACIONALIZACAO'
        // 'CARREGAMENTO',
        // 'OBSCARREGAMENTO',
    ];

    columnsPrevistoEmbarque: string[] = [
        // 'ETAPA',
        'STATUS',
        'CODPROJ',
        // 'IDENTIFICACAO',
        'SORTIMENTO',
        'MERCPRONTEMBARQUE',
        // 'LOCALARMAZENAGEM',
        'AMOSTRA',
        'PROGRAMACAO',
        'PARCVENDADIRETA',
        'PARCVENDADIRETA2',
        'NOMEPARCEXP',
        'PEDIDO',
        'ARTIGOS',
        'ARMADOR',
        'AGENTEDECARGA',
        'NAVIOEMBARQUE',
        'NAVIOCHEGADA',
        'ORIGEM',
        'DESTINO',
        'EMBARQUE',
        'CHEGADA',
        'CONTAINER',
        'TAMANHO',
        'BOOKING',
        'TICKET',
        //'BL',
        'FRETEMARITIMO',
        'PORTOTRANSBORDO',
        // 'DTTRANSBORDO',
        // 'NUMDI',
        // 'VENCARMAZENAMENTO',
        // 'RECINTOALFA',
        // 'FREETIME',
        // 'REMOCAO',
        'OBS',
        // 'NUMERONFNACIONALIZACAO',
        // 'DATADENACIONALIZACAO'
        // 'CARREGAMENTO',
        // 'OBSCARREGAMENTO',
    ];

    columnsEmbarcado: string[] = [
        // 'ETAPA',
        'STATUS',
        'CODPROJ',
        // 'IDENTIFICACAO',
        'SORTIMENTO',
        //'MERCPRONTEMBARQUE',
        // 'LOCALARMAZENAGEM',
        'AMOSTRA',
        'PROGRAMACAO',
        'PARCVENDADIRETA',
        'PARCVENDADIRETA2',
        'NOMEPARCEXP',
        'PEDIDO',
        'ARTIGOS',
        'ARMADOR',
        'AGENTEDECARGA',
        'NAVIOEMBARQUE',
        'NAVIOCHEGADA',
        'ORIGEM',
        'DESTINO',
        'EMBARQUE',
        'CHEGADA',
        'CONTAINER',
        'TAMANHO',
        // 'BOOKING',
        'BL',
        'TICKET',
        'FRETEMARITIMO',
        'PORTOTRANSBORDO',
        // 'DTTRANSBORDO',
        // 'NUMDI',
        // 'VENCARMAZENAMENTO',
        // 'RECINTOALFA',
        //'FREETIME',
        // 'REMOCAO',
        'OBS',
        // 'NUMERONFNACIONALIZACAO',
        // 'DATADENACIONALIZACAO'
        // 'CARREGAMENTO',
        // 'OBSCARREGAMENTO',
    ];

    columnsAtracado: string[] = [
        // 'ETAPA',
        'STATUS',
        'CODPROJ',
        // 'IDENTIFICACAO',
        'SORTIMENTO',
        //'MERCPRONTEMBARQUE',
        // 'LOCALARMAZENAGEM',
        'AMOSTRA',
        'PROGRAMACAO',
        'PARCVENDADIRETA',
        'PARCVENDADIRETA2',
        'NOMEPARCEXP',
        'PEDIDO',
        'ARTIGOS',
        'ARMADOR',
        'AGENTEDECARGA',
        'NAVIOEMBARQUE',
        'NAVIOCHEGADA',
        'ORIGEM',
        'DESTINO',
        'EMBARQUE',
        'CHEGADA',
        'CONTAINER',
        'TAMANHO',
        // 'BOOKING',
        'BL',
        'TICKET',
        'FRETEMARITIMO',
        // 'PORTOTRANSBORDO',
        // 'DTTRANSBORDO',
        // 'NUMDI',
        // 'VENCARMAZENAMENTO',
        'RECINTOALFA',
        'FREETIME',
        'REMOCAO',
        'NUMERONFNACIONALIZACAO',
        'DATADENACIONALIZACAO',
        'OBS',
        'CARREGAMENTO',
        'OBSCARREGAMENTO',
    ];

    columnsPatio: string[] = [
        // 'ETAPA',
        // 'STATUS',
        'LOCALARMAZENAGEM',
        'CODPROJ',
        // 'IDENTIFICACAO',
        // 'SORTIMENTO',
        //'MERCPRONTEMBARQUE',
        'PROGRAMACAO',
        // 'AMOSTRA',
        'NOMEPARCEXP',
        'PEDIDO',
        'PARCVENDADIRETA',
        'PARCVENDADIRETA2',
        'ARTIGOS',
        // 'ARMADOR',
        // 'AGENTEDECARGA',
        // 'NAVIOEMBARQUE',
        // 'NAVIOCHEGADA',
        // 'ORIGEM',
        // 'DESTINO',
        // 'EMBARQUE',
        // 'CHEGADA',
        // 'CONTAINER',
        // 'TAMANHO',
        // 'TICKET',
        // 'BOOKING',
        // 'BL',
        // 'PORTOTRANSBORDO',
        // 'DTTRANSBORDO',
        // 'NUMDI',
        // 'VENCARMAZENAMENTO',
        // 'RECINTOALFA',
        // 'FREETIME',
        // 'REMOCAO',
        'OBS',
        // 'NUMERONFNACIONALIZACAO',
        // 'DATADENACIONALIZACAO',
        // 'FRETEMARITIMO',
        // 'CARREGAMENTO',
        // 'OBSCARREGAMENTO',
    ];

    //Funções do filtro das tabelas
    filtrarGeral(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.geral.filter = filterValue.trim().toLowerCase();
    }

    filtrarPrevisto(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.previsto.filter = filterValue.trim().toLowerCase();
    }

    filtrarPrevistoEmbarque(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.previstoEmbarque.filter = filterValue.trim().toLowerCase();
    }

    filtrarEmbarcado(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.embarcado.filter = filterValue.trim().toLowerCase();
    }

    filtrarAtracado(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.atracado.filter = filterValue.trim().toLowerCase();
    }

    filtrarPatio(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.patio.filter = filterValue.trim().toLowerCase();
    }

    transformaData(value) {
        let dataStr = '';
        if (value.substring(0, 2) == 'X ') {
            dataStr = value.substring(8) + value.substring(5, 7) + value.substring(2, 4);
        } else if (value.substring(0, 4) == 'ETA ') {
            dataStr = value.substring(10) + value.substring(7, 9) + value.substring(4, 6);
        } else {
            dataStr =
                value.substring(6, 8) + value.substring(3, 5) + value.substring(0, 2) + value.substring(9, 11) + value.substring(12, 14);
        }
        return dataStr;
    }

    //Função para tratar as colunas de datas e transforma-las em string para funcionar na ordenação
    ordenaData(item, property) {
        switch (property) {
            case 'SORTIMENTO':
            case 'EMBARQUE':
            case 'CHEGADA':
            case 'FREETIME':
            case 'DTTRANSBORDO':
            case 'VENCARMAZENAMENTO':
            case 'DATADENACIONALIZACAO':
            case 'REMOCAO':
            case 'AMOSTRA':
            case 'CARREGAMENTO':
            case 'MERCPRONTEMBARQUE': {
                if (!item[property]) {
                    return undefined;
                }
                const value = item[property];
                let dataStr = '';
                if (value.substring(0, 2) == 'X ') {
                    dataStr = value.substring(8) + value.substring(5, 7) + value.substring(2, 4);
                } else if (value.substring(0, 4) == 'ETA ') {
                    dataStr = value.substring(10) + value.substring(7, 9) + value.substring(4, 6);
                } else {
                    dataStr =
                        value.substring(6, 8) +
                        value.substring(3, 5) +
                        value.substring(0, 2) +
                        value.substring(9, 11) +
                        value.substring(12, 14);
                }
                return dataStr;
            }
            default: {
                return item[property];
            }
        }
    }
}
