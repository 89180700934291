import { Overlay } from '@angular/cdk/overlay';
import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { SpinnerComponent } from '../global-components/spinner/spinner.component';
import { PortalJpaService } from '../services/portal-jpa.service';
import { PortalServiceService } from '../services/portal-service.service';
import { PedidosOperacionalSc } from './local-interfaces/pedidos-operacional-sc-interface';

@Component({
    selector: 'app-pedidos-operacional-sc',
    templateUrl: './pedidos-operacional-sc.component.html',
    styleUrls: ['./pedidos-operacional-sc.component.scss'],
})
export class PedidosOperacionalScComponent implements OnInit {
    constructor(
        public overlay: Overlay,
        private portalJpaService: PortalJpaService,
        public viewContainerRef: ViewContainerRef,
        private portalService: PortalServiceService
    ) {}

    ngOnInit() {
        this.atualizar();
        this.atualizacaoAutomatica = setInterval(() => this.atualizar(), 180000);
    }

    ngOnDestroy() {
        clearInterval(this.atualizacaoAutomatica);
    }

    pedidos: PedidosOperacionalSc[];
    liberado: number = 0;
    emSeparacao: number = 0;
    atualizacaoAutomatica: any;

    atualizar() {
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

        this.portalJpaService.getPedidosOperacional(4).subscribe(
            (res: any) => {
                this.pedidos = res.response.filter(pedido => !/^EUROTEXTIL/i.test(pedido.RAZAOSOCIAL));
                this.emSeparacao = 0;
                this.liberado = 0;
                this.pedidos.forEach(element => {
                    if (element.STATUS === 'EM SEPARACAO') {
                        this.emSeparacao += 1;
                    }

                    if (element.STATUS === 'LIBERADO SEPARAR') {
                        this.liberado += 1;
                    }
                });
                overlayRef.dispose();
            },
            erro => {
                overlayRef.dispose();
                console.log(erro);
            }
        );
    }

    imprimir(nuNota: number) {
        this.portalService.imprimirPedido(nuNota, 4);
    }
}
