import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-controle-embarque-tool-tip',
    templateUrl: './controle-embarque-tool-tip.component.html',
    styleUrls: ['./controle-embarque-tool-tip.component.scss'],
})
export class ControleEmbarqueToolTipComponent implements OnInit {
    tooltip: string = '';
    left = 0;
    top = 0;
    visible = false;

    constructor() {}

    ngOnInit() {}
}
