import { Cobranca, OcorrenciaCobranca } from './../pedidos-financeiro-cobranca/local-interfaces/cobranca-interface';
import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';

import { Observable, of, throwError } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';

import { version } from '../../../package.json';
import { portalEuro } from '../../assets/config.json';
import { PedidosSituacao, PedidosUsuario } from '../programacao-operacional/local-interfaces/programacao-operacional-interface';
import { ProdutoUnificado } from '../global-interfaces/produto-interface';
import { Response } from '../global-interfaces/response';
import { PedidoFaturar } from '../global-interfaces/pedido-faturar';
import { AutenticacaoService } from './autenticacao.service';
import { formatDate } from '@angular/common';
import { MatSnackBar } from '@angular/material';

@Injectable({
    providedIn: 'root',
})
export class PortalJpaService {
    constructor(private http: HttpClient, private auth: AutenticacaoService, private snackBar: MatSnackBar) {
        switch (portalEuro.ambiente) {
            case 'producao':
                this.urlAmbiente = portalEuro.ambientes.producao;
                break;

            case 'homolog':
                this.urlAmbiente = portalEuro.ambientes.homolog;
                break;

            case 'localIp':
                this.urlAmbiente = portalEuro.ambientes.localIp;
                break;

            case 'localhost':
                this.urlAmbiente = portalEuro.ambientes.localhost;
                break;

            default:
                this.urlAmbiente = 'http://ambiente_nao_definido';
                break;
        }
    }

    urlAmbiente: string;
    gerandoRelat: boolean = false;

    getUrlAmbiente(): string {
        return this.urlAmbiente;
    }

    getBody() {
        return {
            userName: this.auth.getUserName(),
            codRep: this.auth.getCodRep(),
            serviceName: '',
            token: '',
            param: [],
        };
    }
    getHttpOptions() {
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                version,
            }),
        };
    }

    apiPostObterNotificacoesAtivas(): Observable<any> {
        return this.http
            .post(this.urlAmbiente + portalEuro.resources.portal.notificacoes.ativas, this.getBody(), this.getHttpOptions())
            .pipe(map(ret => ret as any));
    }

    /**
     * Marcar uma notificação como lida
     * @param notifID
     * @param exibir S - Continua exibindo mesmo marcando lida, N - parar de exibir
     * @returns Observable<>
     */
    apiPostMarcarNotificacaoLida(notifID: number, exibir: string): Observable<any> {
        // Se ativa=='S' notificação permanece na tela do usuário, mesmo já marcada como lida.
        const body = this.getBody();
        body.param = [
            {
                paramName: 'NOTIFID',
                paramValue: notifID.toString(),
            },
            {
                paramName: 'EXIBIR',
                paramValue: exibir,
            },
        ];

        return this.http
            .post(this.urlAmbiente + portalEuro.resources.portal.notificacoes.marcarLida, body, this.getHttpOptions())
            .pipe(map(ret => ret as any));
    }

    /**
     * Marcar uma Liberação com o codigo do Gestor que apoiou a Liberação ou null quando foi o usuário.
     * @param nuNotaPedido
     * @param liberador
     * @returns Observable<number>
     */
    apiPostAlterarGestorLiberador(nuNotaPedido: number, liberador: string): Observable<number> {
        const body = this.getBody();
        body.param = [
            {
                paramName: 'NUNOTAPEDIDO',
                paramValue: nuNotaPedido.toString(),
            },
            {
                paramName: 'LIBERADOR',
                paramValue: liberador,
            },
        ];

        return this.http
            .post(this.urlAmbiente + portalEuro.resources.portal.liberacaoFinanceira.liberacaoFinanceiraGestor, body, this.getHttpOptions())
            .pipe(map(ret => ret as any));
    }

    getPedidosFinanceiro(projeto: number) {
        const body = this.getBody();
        body.param = [
            {
                paramName: 'PROJETO',
                paramValue: projeto.toString(),
            },
        ];

        const url = this.urlAmbiente + portalEuro.resources.portal.pedidos.pedidosFinanceiro.pedidos;

        return this.http.post(url, body, this.getHttpOptions());
    }

    getComissaoPedido(params: any) {
        const body = this.getBody();
        body.param = [
            { paramName: 'PRECODIGITADO', paramValue: params.precoDigitado.toString() },
            { paramName: 'PRECOTABELA', paramValue: params.precoTabela.toString() },
            { paramName: 'VLRTOT', paramValue: params.vlrTot.toString() },
            { paramName: 'CODPARC', paramValue: params.codParc.toString() },
            { paramName: 'TIPNEG', paramValue: params.tipNeg.toString() },
            { paramName: 'CODEMP', paramValue: params.codEmp.toString() },
            { paramName: 'ADCODUNIPRO', paramValue: params.adCodUniPro.toString() },
            { paramName: 'CONTROLE', paramValue: params.controle.toString() },
        ];

        const url = this.urlAmbiente + portalEuro.resources.portal.pedidos.comissaoPedido.comissao;

        return this.http.post(url, body, this.getHttpOptions());
    }

    getProjetosControleEmbarque() {
        const url = this.urlAmbiente + portalEuro.resources.portal.projetos.controleEmbarque;

        return this.http.post(url, this.getBody(), this.getHttpOptions());
    }

    getPedidosOperacional(codEmp: number) {
        const body = this.getBody();
        body.param = [{ paramName: 'CODEMP', paramValue: codEmp.toString() }];

        const url = this.urlAmbiente + portalEuro.resources.portal.pedidos.pedidosOperacional.pedidos;

        return this.http.post(url, body, this.getHttpOptions());
    }

    getPercPedidosSeparados(codEmp: number) {
        const body = this.getBody();
        body.param = [{ paramName: 'CODEMP', paramValue: codEmp.toString() }];

        const url = this.urlAmbiente + portalEuro.resources.portal.pedidos.pedidosOperacional.percPedSeparado;

        return this.http.post(url, body, this.getHttpOptions());
    }

    getPedidosComissao(dtIni: string, dtFin: string, codVend: number) {
        const body = this.getBody();
        body.param = [
            { paramName: 'DTINI', paramValue: dtIni.toString() },
            { paramName: 'DTFIN', paramValue: dtFin.toString() },
            { paramName: 'CODVEND', paramValue: codVend.toString() },
        ];

        const url = this.urlAmbiente + portalEuro.resources.portal.pedidos.comissaoPedido.pedidos;

        return this.http.post(url, body, this.getHttpOptions());
    }

    apiPostBuscarEquipamentosEmUso(codEmp: number): Observable<any> {
        const body = this.getBody();
        body.param = [
            {
                paramName: 'CODEMP',
                paramValue: codEmp.toString(),
            },
        ];
        const url = this.urlAmbiente + portalEuro.resources.portal.coletores.equipamentosEmUso;
        return this.http.post(url, body, this.getHttpOptions());
    }

    getItensPedidoComissao(nuNota: number) {
        const body = this.getBody();
        body.param = [{ paramName: 'NUNOTA', paramValue: nuNota.toString() }];

        const url = this.urlAmbiente + portalEuro.resources.portal.pedidos.comissaoPedido.itens;

        return this.http.post(url, body, this.getHttpOptions());
    }

    getListaRepresentantes() {
        const url = this.urlAmbiente + portalEuro.resources.portal.representantes.listaRepresentantes;

        return this.http.post(url, this.getBody(), this.getHttpOptions());
    }

    getListaRepresentantesDev() {
        const url = this.urlAmbiente + portalEuro.resources.portal.representantes.listaRepresentantesDev;

        return this.http.post(url, this.getBody(), this.getHttpOptions());
    }

    apiPostFecharPedidoFilaExec(nuNotaProposta: number): Observable<any> {
        const body = this.getBody();
        body.param = [
            {
                paramName: 'nuNotaProposta',
                paramValue: nuNotaProposta.toString(),
            },
        ];
        const url = this.urlAmbiente + portalEuro.resources.portal.coletores.fecharPedidoFilaExec;
        return this.http.post(url, body, this.getHttpOptions());
    }

    apiPostImprimirRomaneioFilaExec(nuNotaProposta: number): Observable<any> {
        const body = this.getBody();
        body.param = [
            {
                paramName: 'nuNotaProposta',
                paramValue: nuNotaProposta.toString(),
            },
        ];
        const url = this.urlAmbiente + portalEuro.resources.portal.coletores.imprimirRomaneioFilaExec;
        return this.http.post(url, body, this.getHttpOptions());
    }

    // Painel Operacional da Programação
    getPedidosSituacao(): Observable<any> {
        const url = this.urlAmbiente + portalEuro.resources.portal.pedidos.pedidosOperacional.pedidosSituacao;
        return this.http.post(url, this.getBody(), this.getHttpOptions()).pipe(map(ret => ret as PedidosSituacao[]));
    }
    getPedidosUsuario(): Observable<any> {
        const url = this.urlAmbiente + portalEuro.resources.portal.pedidos.pedidosOperacional.pedidosUsuario;
        return this.http.post(url, this.getBody(), this.getHttpOptions()).pipe(map(ret => ret as PedidosUsuario[]));
    }
    getPedidosMovimentacao() {
        const url = this.urlAmbiente + portalEuro.resources.portal.pedidos.pedidosOperacional.pedidosMovimentacao;
        return this.http.post(url, this.getBody(), this.getHttpOptions()).pipe(map(ret => ret as PedidosSituacao[]));
    }

    getListaProdutos() {
        const body = this.getBody();

        const url = this.urlAmbiente + portalEuro.resources.portal.produtos.listaProdutos;

        return this.http.post(url, body, this.getHttpOptions());
    }

    insertBookUrl(urls: any, codUniPro: number = 0, codProj: number = 0) {
        const url = this.urlAmbiente + portalEuro.resources.portal.produtos.imagensBookDinamico;

        const bodyRequest = {
            userName: this.auth.getUserName(),
            codRep: this.auth.getCodRep(),
            serviceName: '',
            token: 'soifhw903rnosld',
            param: urls,
            codUniPro,
            codProj,
        };

        return this.http.post(url, bodyRequest, this.getHttpOptions());
    }

    getBookUrl(url: string) {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/pdf');
        return this.http.get(url, { headers, responseType: 'blob' });
    }

    buscaBooks(projetos: number[], produtosCompletos: ProdutoUnificado[]) {
        let bodyBooks = {
            userName: this.auth.getUserName(),
            token: 'soifhw903rnosld',
            codRep: this.auth.getCodRep(),
            serviceName: projetos.length === 0 ? 'buscaBooksPE' : 'buscaBooksPO',
            projetos,
            produtos: produtosCompletos,
            params: [],
        };
        const produtos = produtosCompletos.map(produto => {
            return { ADCODUNIPRO: produto.ADCODUNIPRO, DESCRPROD: produto.DESCRPROD };
        });

        bodyBooks = { ...bodyBooks, produtos: produtosCompletos };

        const url = this.urlAmbiente + portalEuro.resources.portal.books.buscaBooks;

        return this.http.post<{ projeto: number[]; produtos: ProdutoUnificado[] }>(url, bodyBooks, this.getHttpOptions());
    }

    getFaixaPedidoTamanho(fileName) {
        const url = this.urlAmbiente + portalEuro.resources.portal.produtos.tamanho;

        const bodyRequest = {
            userName: this.auth.getUserName(),
            token: 'soifhw903rnosld',
            codRep: this.auth.getCodRep(),
            serviceName: '',
            param: [
                {
                    paramName: 'FILENAME',
                    paramValue: fileName,
                },
            ],
        };

        return this.http.post(url, bodyRequest, this.getHttpOptions());
    }

    getPedidosPrazo(codparc: number, coligados: string) {
        const body = this.getBody();
        body.param = [
            { paramName: 'CODPARC', paramValue: codparc },
            { paramName: 'COLIGADOS', paramValue: coligados },
        ];

        const url = this.urlAmbiente + portalEuro.resources.portal.parceiros.getPedidoPrazo;

        return this.http.post(url, body, this.getHttpOptions());
    }

    getItensPedidoProposta(nuNota: number) {
        const body = this.getBody();
        body.param = [{ paramName: 'NUNOTA', paramValue: nuNota.toString() }];

        const url = this.urlAmbiente + portalEuro.resources.portal.pedidos.itensPedidoProposta.itens;

        return this.http.post(url, body, this.getHttpOptions());
    }

    inserirParametrosProposta(
        nuNota: number,
        observacao: string,
        comissao: number,
        produtos: any,
        limparEstoque: string,
        limpaSaldo: string
    ) {
        const body = {
            userName: this.auth.getUserName(),
            codRep: this.auth.getCodRep(),
            serviceName: '',
            token: '',
            produtos,
            nuNota,
            comissao: +comissao,
            observacao,
            limparEstoque,
            limpaSaldo,
        };

        const url = this.urlAmbiente + portalEuro.resources.portal.pedidos.parametrosPedidoProposta.inserirParametros;

        return this.http.post(url, body, this.getHttpOptions()).pipe(
            map((ret: any) => {
                if (ret && ret.serviceResponse === 'OK') {
                    return ret.response as string;
                }
                return throwError(ret.response);
            })
        );
    }

    getPedidosPesquisa(busca: string, tipoFiltro: string, periodoIini: string, periodoFin: string) {
        const body = this.getBody();
        const url = this.urlAmbiente + portalEuro.resources.portal.pedidos.pedidos;
        body.codRep = Number(this.auth.getCodRep());
        body.userName = this.auth.getUserName();

        body.param = [];

        body.param.push(
            {
                paramName: 'BUSCA',
                paramValue: busca,
            },
            {
                paramName: 'TIPOFILTRO',
                paramValue: tipoFiltro,
            },
            {
                paramName: 'PERIODOINI',
                paramValue: periodoIini,
            },
            {
                paramName: 'PERIODOFIN',
                paramValue: periodoFin,
            }
        );

        return this.http.post(url, body, this.getHttpOptions());
    }

    getPedidosJuntar(data: any) {
        const body = this.getBody();
        body.param = [
            { paramName: 'CODPARC', paramValue: data.codParc.toString() },
            { paramName: 'CODPROJ', paramValue: data.codProj.toString() },
            { paramName: 'CODEMP', paramValue: data.codEmp.toString() },
            { paramName: 'CODTIPVENDA', paramValue: data.codTipVenda.toString() },
            { paramName: 'ITENS', paramValue: JSON.stringify(data.itens) },
            { paramName: 'ADREMESSA', paramValue: data.adRemessa.toString() },
            { paramName: 'CODPARCDEST', paramValue: data.codParcDest.toString() },
        ];

        const url = this.urlAmbiente + portalEuro.resources.portal.pedidos.pedidosJuntar;

        return this.http.post(url, body, this.getHttpOptions());
    }

    getProgramacaoOperacional(codemp?: number) {
        const body = this.getBody();

        body.param.push(
            {
                paramName: 'CODREP',
                paramValue: Number(this.auth.getCodRep()),
            },
            {
                paramName: 'USERNAME',
                paramValue: this.auth.getUserName().toString(),
            },
            {
                paramName: 'CODEMP',
                paramValue: codemp,
            }
        );

        const url = this.urlAmbiente + portalEuro.resources.portal.programacao.getProgramacaoOperacional;

        return this.http.post(url, body, this.getHttpOptions()).pipe(
            map((ret: any) => {
                if (ret && ret.serviceResponse === 'OK') {
                    return ret.response as string;
                }
                return throwError(ret.response);
            })
        );
    }

    getEstatisticasGraficoPorRepresentante(codRep: number, dtIni: Date, dtFim: Date, dtIniAnt: Date, dtFimAnt: Date) {
        const body = this.getBody();
        const httpOptions = this.getHttpOptions();
        body.param = [
            {
                paramName: 'dtIni',
                paramValue: dtIni,
            },
            {
                paramName: 'dtFim',
                paramValue: dtFim,
            },
            {
                paramName: 'dtIniAnt',
                paramValue: dtIniAnt,
            },
            {
                paramName: 'dtFimAnt',
                paramValue: dtFimAnt,
            },
            {
                paramName: 'codRep',
                paramValue: codRep.toString(),
            },
        ];
        const url = this.urlAmbiente + portalEuro.resources.portal.graficos.estatisticaRepresentante.url;
        return this.http.post(url, body, httpOptions);
    }

    getListaRepresentanteGrafico() {
        const body = this.getBody();
        const httpOptions = this.getHttpOptions();
        body.serviceName = '';
        body.codRep = Number(this.auth.getCodRep());
        body.userName = String(this.auth.getUserName());

        body.param = [];

        const url = this.urlAmbiente + portalEuro.resources.portal.graficos.listaRepresentanteGrafico.url;
        return this.http.post(url, body, httpOptions);
    }

    imprimirPedido(nuNota: number) {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/pdf').append('Authorization', 'Basic ' + btoa('jasperadmin:jasperadmin'));

        const imprimir = this.http.get(
            portalEuro.resources.jasperServer.url + portalEuro.resources.relatorios.impressaoPedidos.url + '?NUNOTA=' + nuNota,
            {
                headers,
                responseType: 'blob',
            }
        );

        imprimir.subscribe(res => {
            const fileUrl = URL.createObjectURL(res);
            window.open(fileUrl, '_blank');
        });
    }

    getPdfNotaFiscal(param: any, tipo: string) {
        const body = this.getBody();
        const httpOptions = this.getHttpOptions();

        if (tipo === 'NUMNOTA') {
            body.param = [
                { paramName: 'PARAM', paramValue: param.NUMNOTA.toString() },
                { paramName: 'TIPO', paramValue: tipo.toString() },
                { paramName: 'CODEMP', paramValue: param.CODEMP.toString() },
            ];
        }

        if (tipo === 'NUNOTAPROPOSTA') {
            body.param = [
                { paramName: 'PARAM', paramValue: param.pedido.PROPOSTA.toString() },
                { paramName: 'TIPO', paramValue: tipo.toString() },
                { paramName: 'CODEMP', paramValue: param.pedido.CODEMP.toString() },
                { paramName: 'REMESSA', paramValue: param.remessa.toString() },
                { paramName: 'CODPARC', paramValue: param.pedido.CODPARC.toString() },
            ];
        }

        const url = this.urlAmbiente + portalEuro.resources.portal.devolucoes.pdfNotaFiscal;
        return this.http.post(url, body, httpOptions);
    }

    getContatosCliente(codparc: number) {
        const body = this.getBody();
        body.param = [{ paramName: 'CODPARC', paramValue: codparc }];

        const url = this.urlAmbiente + portalEuro.resources.portal.parceiros.getContatosCliente;

        return this.http.post(url, body, this.getHttpOptions());
    }

    getListaSerasa(parc: string, soli: string, dataInicial: string, dataFinal: string) {
        const body = this.getBody();
        body.param = [
            { paramName: 'PARC', paramValue: parc },
            { paramName: 'SOLI', paramValue: soli },
            { paramName: 'DATAINICIAL', paramValue: dataInicial },
            { paramName: 'DATAFINAL', paramValue: dataFinal },
        ];

        return this.http.post(this.urlAmbiente + portalEuro.resources.portal.financeiro.listaSerasa, body, this.getHttpOptions()).pipe(
            first(),
            tap((res: any) => {})
        );
    }

    getListaParceiro(partnerField: string) {
        const body = this.getBody();
        body.param = [{ paramName: 'NOMEPARC', paramValue: partnerField }];

        return this.http.post(this.urlAmbiente + portalEuro.resources.portal.financeiro.listaParceiros, body, this.getHttpOptions()).pipe(
            first(),
            tap((res: any) => {})
        );
    }

    geraSerasaRelatorio(serasaID: number) {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/pdf').append('Authorization', 'Basic ' + btoa(`jasperadmin:jasperadmin`));
        const url =
            portalEuro.resources.jasperServer.url +
            portalEuro.resources.relatorios.fichaParceiroSerasa.url +
            '?PK_SERACABID=' +
            serasaID +
            '&USU=' +
            this.auth.getUserName();

        return this.http.get(url, { headers, responseType: 'blob', observe: 'events', reportProgress: true });
    }

    getInformacoesDesconto(numeroDaNota) {
        const body = this.getBody();
        body.param = [{ paramName: 'NUMERODANOTA', paramValue: numeroDaNota }];
        return this.http.post(this.urlAmbiente + portalEuro.resources.portal.produtos.produtosProposta, body, this.getHttpOptions()).pipe(
            first(),
            tap((res: any) => {})
        );
    }

    getRastreioPedido(nuNota: number) {
        const body = this.getBody();
        body.param = [{ paramName: 'NUNOTA', paramValue: nuNota }];

        const url = this.urlAmbiente + portalEuro.resources.portal.pedidos.rastreioPedido;

        return this.http.post(url, body, this.getHttpOptions());
    }

    getProgramacaoSemanal() {
        const body = this.getBody();

        const url = this.urlAmbiente + portalEuro.resources.portal.projetos.programacaoSemanal;

        return this.http.post(url, body, this.getHttpOptions()).pipe(
            first(),
            tap((res: any) => {})
        );
    }

    buscaBooksPeProgramacaoSemanal(produtosCompletos: ProdutoUnificado[]) {
        let bodyBooks = {
            userName: this.auth.getUserName(),
            token: 'soifhw903rnosld',
            codRep: this.auth.getCodRep(),
            serviceName: 'buscaBooksPE',
            projetos: [],
            produtos: produtosCompletos,
            params: [],
        };
        produtosCompletos.map(produto => {
            return { ADCODUNIPRO: produto.ADCODUNIPRO, DESCRPROD: produto.DESCRPROD };
        });

        bodyBooks = { ...bodyBooks, produtos: produtosCompletos };

        const url = this.urlAmbiente + portalEuro.resources.portal.books.buscaBooksProgSemanalPe;

        return this.http.post<{ projeto: number[]; produtos: ProdutoUnificado[] }>(url, bodyBooks, this.getHttpOptions());
    }

    buscaBooksPoProgramacaoSemanal(projetos: number[]) {
        let bodyBooks = {
            userName: this.auth.getUserName(),
            token: 'soifhw903rnosld',
            codRep: this.auth.getCodRep(),
            serviceName: 'buscaBooksPO',
            projetos,
            produtos: [],
            params: [],
        };

        bodyBooks = { ...bodyBooks, produtos: [] };

        const url = this.urlAmbiente + portalEuro.resources.portal.books.buscaBooksProgSemanalPo;

        return this.http.post<{ projeto: number[]; produtos: ProdutoUnificado[] }>(url, bodyBooks, this.getHttpOptions());
    }

    getPedidosLiberacao() {
        const body = this.getBody();
        const url = this.urlAmbiente + portalEuro.resources.portal.pedidos.liberacaoPedidos.pedidos;

        return this.http.post(url, body, this.getHttpOptions());
    }

    getRegrasPeido(nuNota: number) {
        const body = this.getBody();
        body.param = [{ paramName: 'NUNOTA', paramValue: nuNota }];

        const url = this.urlAmbiente + portalEuro.resources.portal.pedidos.liberacaoPedidos.regrasPedido;

        return this.http.post(url, body, this.getHttpOptions());
    }

    getProgramacaoSemanalBook() {
        const body = this.getBody();

        const url = this.urlAmbiente + portalEuro.resources.portal.projetos.programacaoSemanalBook;

        return this.http.post(url, body, this.getHttpOptions()).pipe(
            first(),
            tap((res: any) => {})
        );
    }

    getProjetosControleEmbarqueMoveleiro() {
        const url = this.urlAmbiente + portalEuro.resources.portal.projetos.controleEmbarqueMoveleiro;

        return this.http.post(url, this.getBody(), this.getHttpOptions());
    }

    getPermiteAprovDev() {
        const url = this.urlAmbiente + portalEuro.resources.portal.devolucoes.permiteAprovDev;

        return this.http.post(url, this.getBody(), this.getHttpOptions());
    }

    getTermoColigada() {
        const url = this.urlAmbiente + portalEuro.resources.portal.parceiros.getTermoColigada;
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/pdf');
        return this.http.get(url, { headers, responseType: 'blob' });
    }

    getCabItensProjChegada(codUnipro: number, descProjCli: string) {
        const body = this.getBody();

        body.param = [
            { paramName: 'CODUNIPRO', paramValue: codUnipro },
            { paramName: 'DESCPROJCLI', paramValue: descProjCli },
        ];

        const url = this.urlAmbiente + portalEuro.resources.portal.produtos.cabItensProjChegada;

        return this.http.post(url, body, this.getHttpOptions());
    }

    getItensProjChegada(codUnipro: number, descProjCli: string) {
        const body = this.getBody();

        body.param = [
            { paramName: 'CODUNIPRO', paramValue: codUnipro },
            { paramName: 'DESCPROJCLI', paramValue: descProjCli },
        ];

        const url = this.urlAmbiente + portalEuro.resources.portal.produtos.itensProjChegada;

        return this.http.post(url, body, this.getHttpOptions());
    }

    getAnaliseFatur(
        periodoIni: string,
        periodoFin: string,
        codParc: number,
        codProd: number,
        vendaDireta: string,
        limpEst: string,
        controle: string
    ) {
        const body = this.getBody();

        body.param = [
            { paramName: 'PERIODOINI', paramValue: periodoIni },
            { paramName: 'PERIODOFIN', paramValue: periodoFin },
            { paramName: 'CODPARC', paramValue: codParc },
            { paramName: 'CODPROD', paramValue: codProd },
            { paramName: 'VENDADIRETA', paramValue: vendaDireta },
            { paramName: 'LIMPEST', paramValue: limpEst },
            { paramName: 'CONTROLE', paramValue: controle },
        ];

        const url = this.urlAmbiente + portalEuro.resources.portal.representantes.analiseFaturamento;

        return this.http.post(url, body, this.getHttpOptions());
    }

    getAnaliseFaturAnt(
        periodoIni: string,
        periodoFin: string,
        codParc: number,
        codProd: number,
        vendaDireta: string,
        limpEst: string,
        controle: string
    ) {
        const body = this.getBody();

        body.param = [
            { paramName: 'PERIODOINI', paramValue: periodoIni },
            { paramName: 'PERIODOFIN', paramValue: periodoFin },
            { paramName: 'CODPARC', paramValue: codParc },
            { paramName: 'CODPROD', paramValue: codProd },
            { paramName: 'VENDADIRETA', paramValue: vendaDireta },
            { paramName: 'LIMPEST', paramValue: limpEst },
            { paramName: 'CONTROLE', paramValue: controle },
        ];

        const url = this.urlAmbiente + portalEuro.resources.portal.representantes.analiseFaturamentoAnt;

        return this.http.post(url, body, this.getHttpOptions());
    }

    getSeparadoresOperacional() {
        const body = this.getBody();

        const url = this.urlAmbiente + portalEuro.resources.portal.pedidos.pedidosOperacional.separadores;

        return this.http.post(url, body, this.getHttpOptions());
    }

    getPedidosSeparar() {
        const body = this.getBody();

        const url = this.urlAmbiente + portalEuro.resources.portal.pedidos.pedidosOperacional.pedidosSeparar;

        return this.http.post(url, body, this.getHttpOptions());
    }

    atribuirPedido(ope, listaPedidos) {
        const body = { operador: ope, bodyResponsavelPedidos: listaPedidos };

        const url = this.urlAmbiente + portalEuro.resources.portal.pedidos.pedidosOperacional.atribuirPedido;

        return this.http.post(url, body, this.getHttpOptions());
    }

    autocompleteClientePedido(filtro: string, origem: string) {
        const url = this.urlAmbiente + portalEuro.resources.portal.autocomplete.clientePedido;
        const options = this.getHttpOptions();
        const body = this.getBody();
        body.param.push(
            {
                paramName: 'FILTRO',
                paramValue: filtro,
            },
            {
                paramName: 'ORIGEM',
                paramValue: origem,
            }
        );

        return this.http.post(url, body, options);
    }

    autocompleteTransportadora(filtro: string) {
        const url = this.urlAmbiente + portalEuro.resources.portal.autocomplete.transportadora;
        const options = this.getHttpOptions();
        const body = this.getBody();
        body.param.push({
            paramName: 'filtro',
            paramValue: filtro,
        });

        return this.http.post(url, body, options);
    }

    autocompleteTipoNegociacaoParcela(filtro: string, codParc: number) {
        const url = this.urlAmbiente + portalEuro.resources.portal.autocomplete.tipoNegociacaoParcela;
        const options = this.getHttpOptions();
        const body = this.getBody();

        body.param.push(
            {
                paramName: 'FILTRO',
                paramValue: filtro,
            },
            {
                paramName: 'CODPARC',
                paramValue: codParc,
            }
        );

        return this.http.post(url, body, options);
    }

    autocompleteTipoNegociacaoCartao() {
        const url = this.urlAmbiente + portalEuro.resources.portal.autocomplete.tipoNegociacaoCartao;
        const options = this.getHttpOptions();
        const body = this.getBody();

        return this.http.post(url, body, options);
    }

    autocompleteProdutoSemFiltro(tela: String) {
        const url = this.urlAmbiente + portalEuro.resources.portal.autocomplete.produtoSemFiltro;
        const options = this.getHttpOptions();
        const body = this.getBody();

        body.param = [];
        body.param.push({ paramName: 'TELA', paramValue: tela });

        return this.http.post(url, body, options);
    }

    getGrupoProdUsu() {
        const url = this.urlAmbiente + portalEuro.resources.portal.produtos.grupoDeProduto;
        const options = this.getHttpOptions();
        const body = this.getBody();

        return this.http.post(url, body, options);
    }

    getDevolucoes(tipoBusca: String, busca: String, periodoIni: Date, periodoFin: Date, grupoProd: String) {
        const body = this.getBody();
        const url = this.urlAmbiente + portalEuro.resources.portal.devolucoes.getDevolucoes;

        body.param = [];
        body.param.push(
            {
                paramName: 'BUSCA',
                paramValue: busca,
            },
            {
                paramName: 'TIPOBUSCA',
                paramValue: tipoBusca,
            },
            {
                paramName: 'PERIODOINI',
                paramValue: periodoIni,
            },
            {
                paramName: 'PERIODOFIN',
                paramValue: periodoFin,
            },
            {
                paramName: 'GRUPOPROD',
                paramValue: grupoProd,
            }
        );

        return this.http.post(url, body, this.getHttpOptions());
    }

    getProdutosDev(devId: number) {
        const body = this.getBody();
        const url = this.urlAmbiente + portalEuro.resources.portal.devolucoes.getProdutos;

        body.param = [];
        body.param.push({
            paramName: 'DEVID',
            paramValue: devId,
        });

        return this.http.post(url, body, this.getHttpOptions());
    }

    getMensagensDev(devId: number) {
        const body = this.getBody();
        const url = this.urlAmbiente + portalEuro.resources.portal.devolucoes.getMensagens;

        body.param = [];
        body.param.push({
            paramName: 'DEVID',
            paramValue: devId,
        });

        return this.http.post(url, body, this.getHttpOptions());
    }

    exibirAnexosDev(devId: number) {
        const body = this.getBody();
        const url = this.urlAmbiente + portalEuro.resources.portal.devolucoes.getAnexos;

        body.param = [];
        body.param.push({
            paramName: 'DEVID',
            paramValue: devId,
        });

        return this.http.post(url, body, this.getHttpOptions());
    }

    finalizaSeparacaoPed(nuNotaPedido: number) {
        const body = this.getBody();
        const url = this.urlAmbiente + portalEuro.resources.portal.pedidos.pedidosOperacional.finalizarSeparacao;

        body.param = [];
        body.param.push({
            paramName: 'NUNOTAPEDIDO',
            paramValue: nuNotaPedido,
        });

        return this.http.post(url, body, this.getHttpOptions());
    }

    getPositivacaoClientes(codFunc: number) {
        const body = this.getBody();

        body.param = [];
        body.param.push({
            paramName: 'CODFUNC',
            paramValue: codFunc,
        });

        const url = this.urlAmbiente + portalEuro.resources.portal.representantes.positivacaoClientes;

        return this.http.post(url, body, this.getHttpOptions());
    }

    getFuncionarioResponsavel() {
        const body = this.getBody();

        const url = this.urlAmbiente + portalEuro.resources.portal.representantes.funcionarioResponsavel;

        return this.http.post(url, body, this.getHttpOptions());
    }

    getRepresentantesAtivos() {
        const body = this.getBody();

        const url = this.urlAmbiente + portalEuro.resources.portal.representantes.representantesAtivos;

        return this.http.post(url, body, this.getHttpOptions());
    }

    getCarteiraClientes(codVend: number, codFunc: number) {
        const body = this.getBody();

        body.param.push(
            {
                paramName: 'CODVEND',
                paramValue: codVend,
            },
            {
                paramName: 'CODFUNC',
                paramValue: codFunc,
            }
        );

        const url = this.urlAmbiente + portalEuro.resources.portal.representantes.carteiraClientes;

        return this.http.post(url, body, this.getHttpOptions());
    }

    getClientesAtivosInativos(codFunc: number) {
        const body = this.getBody();

        body.param.push({
            paramName: 'CODFUNC',
            paramValue: codFunc,
        });

        const url = this.urlAmbiente + portalEuro.resources.portal.representantes.clientesAtivosInativos;

        return this.http.post(url, body, this.getHttpOptions());
    }

    getPedidosFaturar(): Observable<any> {
        const body = this.getBody();
        const url = this.urlAmbiente + portalEuro.resources.portal.pedidos.pedidosFaturar;

        return this.http.post(url, body, this.getHttpOptions()).pipe(
            map((res: Response) => {
                if (res && res.response) {
                    res.response = res.response.map((pedido: PedidoFaturar) => {
                        if (pedido.RAZAOSOCIAL.length >= 45) {
                            pedido.RAZAOSOCIAL = pedido.RAZAOSOCIAL.slice(0, 45) + '...';
                        }

                        if (pedido.TRANSP.length >= 45) {
                            pedido.TRANSP = pedido.TRANSP.slice(0, 45) + '...';
                        }

                        return pedido;
                    });
                }

                return res;
            })
        );
    }

    getProjetosArmazenadosTerceiro() {
        const url = this.urlAmbiente + portalEuro.resources.portal.projetos.projetosArmazenadosTerceiro;

        return this.http.post(url, this.getBody(), this.getHttpOptions());
    }

    getValorEmAberto(codParc: number) {
        const body = this.getBody();

        body.param.push({
            paramName: 'CODPARC',
            paramValue: codParc,
        });

        const url = this.urlAmbiente + portalEuro.resources.portal.parceiros.valorEmAberto;

        return this.http.post(url, body, this.getHttpOptions());
    }

    getPedidosCobranca(exibeTodos: string) {
        const body = this.getBody();

        body.param.push({
            paramName: 'EXIBETODOS',
            paramValue: exibeTodos,
        });

        const url = this.urlAmbiente + portalEuro.resources.portal.financeiro.Cobranca.pedidos;

        return this.http.post(url, body, this.getHttpOptions());
    }

    getDadosChamados() {
        const url = this.urlAmbiente + portalEuro.resources.portal.graficos.dadosChamados.url;
        const options = this.getHttpOptions();
        const body = this.getBody();
        return this.http.post(url, body, options);
    }

    getProdutosProjeto(codProj: number) {
        const body = this.getBody();

        body.param.push({
            paramName: 'CODPROJ',
            paramValue: codProj,
        });

        const url = this.urlAmbiente + portalEuro.resources.portal.projetos.produtosProjeto;

        return this.http.post(url, body, this.getHttpOptions());
    }

    getAgendamentos(data: Date) {
        const url = this.urlAmbiente + portalEuro.resources.portal.agendamentos.agendamentoReuniao;
        const options = this.getHttpOptions();
        const body = this.getBody();
        body.param.push({
            paramName: 'DATA',
            paramValue: data,
        });
        return this.http.post(url, body, options);
    }

    cancelaAgendamento(id: number, obs: string) {
        const url = this.urlAmbiente + portalEuro.resources.portal.agendamentos.cancelaReuniao;
        const options = this.getHttpOptions();
        const body = this.getBody();
        body.param.push(
            {
                paramName: 'ID',
                paramValue: id,
            },
            {
                paramName: 'OBS',
                paramValue: obs,
            }
        );
        return this.http.post(url, body, options);
    }

    getNovoAgendamento() {
        const url = this.urlAmbiente + portalEuro.resources.portal.agendamentos.novoAgendamento;
        const options = this.getHttpOptions();
        const body = this.getBody();
        return this.http.post(url, body, options);
    }

    getHorarios(codSala: number, data: string) {
        const url = this.urlAmbiente + portalEuro.resources.portal.agendamentos.horariosIndisponiveis;
        const options = this.getHttpOptions();
        const body = this.getBody();
        body.param.push(
            {
                paramName: 'CODSALA',
                paramValue: codSala,
            },
            {
                paramName: 'DTAGEND',
                paramValue: data,
            }
        );
        return this.http.post(url, body, options);
    }

    novoAgendamento(data: string, codSala: number, hora: string, usuario: string) {
        const url = this.urlAmbiente + portalEuro.resources.portal.agendamentos.agendarReuniao;
        const options = this.getHttpOptions();
        const body = this.getBody();
        body.param.push(
            {
                paramName: 'DTAGEND',
                paramValue: data,
            },
            {
                paramName: 'CODSALA',
                paramValue: codSala,
            },
            {
                paramName: 'HRAGEND',
                paramValue: hora,
            }
        );
        return this.http.post(url, body, options);
    }

    getGraficoClientesAtendidos(codFunc: number) {
        const body = this.getBody();

        body.param.push({
            paramName: 'CODFUNC',
            paramValue: codFunc,
        });

        const url = this.urlAmbiente + portalEuro.resources.portal.representantes.graficoClientesAtendidos;

        return this.http.post(url, body, this.getHttpOptions());
    }

    getListaCobrancas(proposta: number) {
        const body = this.getBody();
        const url = this.urlAmbiente + portalEuro.resources.portal.financeiro.Cobranca.listaCobrancas;

        body.param = [
            {
                paramName: 'NUNOTAPROPOSTA',
                paramValue: proposta,
            },
        ];

        return this.http.post(url, body, this.getHttpOptions()).pipe(
            map((res: Response) => {
                res.response.map((cobranca: Cobranca) => {
                    switch (cobranca.STATUS) {
                        case 'ACTIVE':
                            cobranca.STATUS = 'ATIVO';
                            break;
                        case 'SUCCESSFUL':
                            cobranca.STATUS = 'SUCESSO';
                            break;
                        case 'EXPIRED':
                            cobranca.STATUS = 'EXPIRADO';
                            break;
                        case 'START':
                            cobranca.STATUS = 'CRIANDO';
                            break;
                        case 'CANCELED':
                            cobranca.STATUS = 'CANCELADO';
                            break;
                        case 'FAILED':
                            cobranca.STATUS = 'OCORRÊNCIA';
                            break;

                        default:
                            break;
                    }

                    if (cobranca.OCORRENCIAS) {
                        cobranca.OCORRENCIAS.map((ocorrencia: OcorrenciaCobranca) => {
                            switch (ocorrencia.APITIPOPAGO) {
                                case 'CREDIT':
                                    ocorrencia.APITIPOPAGO = 'CRÉDITO';
                                    break;
                                case 'DEBIT':
                                    ocorrencia.APITIPOPAGO = 'DÉBITO';
                                    break;

                                default:
                                    break;
                            }

                            switch (ocorrencia.STATUS) {
                                case 'SUCCESSFUL':
                                    ocorrencia.STATUS = 'SUCESSO';
                                    break;
                                case 'FAILED':
                                    ocorrencia.STATUS = 'FALHOU';
                                    break;

                                default:
                                    break;
                            }

                            ocorrencia.BANDEIRA = ocorrencia.BANDEIRA.toUpperCase();
                        });
                    }
                });

                return res;
            })
        );
    }

    criarCobranca(
        nuNotaProposta: number,
        nuNotaPedido: number,
        apiFornecedor: String,
        apiTipo: String,
        apiForma: String,
        qtdMaximaParcelas: number,
        valorCobrar: number,
        bandeira: String,
        dtPag: String,
        nsu: String
    ) {
        const body = this.getBody();
        const url = this.urlAmbiente + portalEuro.resources.portal.financeiro.Cobranca.criarCobranca;

        body.param = [
            {
                paramName: 'NUNOTAPROPOSTA',
                paramValue: nuNotaProposta,
            },
            {
                paramName: 'NUNOTAPEDIDO',
                paramValue: nuNotaPedido,
            },
            {
                paramName: 'APIFORNECEDOR',
                paramValue: apiFornecedor,
            },
            {
                paramName: 'APITIPO',
                paramValue: apiTipo,
            },
            {
                paramName: 'APIFORMA',
                paramValue: apiForma,
            },
            {
                paramName: 'QTDMAXPARCELAS',
                paramValue: qtdMaximaParcelas,
            },
            {
                paramName: 'VALORCOBRAR',
                paramValue: valorCobrar,
            },
            {
                paramName: 'BANDEIRA',
                paramValue: bandeira,
            },
            {
                paramName: 'DTPAGAMENTO',
                paramValue: dtPag,
            },
            {
                paramName: 'NSU',
                paramValue: nsu,
            },
            {
                paramName: 'APITIPOPAGO',
                paramValue: 'CREDIT',
            },
        ];

        return this.http.post(url, body, this.getHttpOptions());
    }

    getGraficoPositivacao(codFunc: number) {
        const body = this.getBody();

        body.param.push({
            paramName: 'CODFUNC',
            paramValue: codFunc,
        });

        const url = this.urlAmbiente + portalEuro.resources.portal.representantes.graficoPositivacao;

        return this.http.post(url, body, this.getHttpOptions());
    }

    cancelarCobranca(idCodPai: number) {
        const body = this.getBody();
        const url = this.urlAmbiente + portalEuro.resources.portal.financeiro.Cobranca.cancelarCobranca;

        body.param = [
            {
                paramName: 'IDCOBAPI',
                paramValue: idCodPai,
            },
        ];

        return this.http.post(url, body, this.getHttpOptions());
    }

    liberarAntecipacao(nuNotaPedido: number, codUsuGestor: number) {
        const body = this.getBody();
        const url = this.urlAmbiente + portalEuro.resources.portal.financeiro.Cobranca.liberarAntecipacao;

        body.param = [
            {
                paramName: 'NUNOTAPEDIDO',
                paramValue: nuNotaPedido,
            },
            {
                paramName: 'CODGESTOR',
                paramValue: codUsuGestor,
            },
        ];

        return this.http.post(url, body, this.getHttpOptions());
    }

    getPedidosLibSeparar(codEmp: number) {
        const body = this.getBody();
        body.param = [{ paramName: 'CODEMP', paramValue: codEmp.toString() }];

        const url = this.urlAmbiente + portalEuro.resources.portal.pedidos.pedidosOperacional.pedidosLibSeparar;

        return this.http.post(url, body, this.getHttpOptions());
    }

    getGerenciamentoBooks() {
        const body = this.getBody();

        const url = this.urlAmbiente + portalEuro.resources.portal.booksGerenciamento.gerenciamentoBooks;

        return this.http.post(url, body, this.getHttpOptions());
    }

    reabrirPedido(nuNotaPedido: number) {
        const body = this.getBody();
        body.param = [{ paramName: 'NUNOTAPEDIDO', paramValue: nuNotaPedido }];

        const url = this.urlAmbiente + portalEuro.resources.portal.pedidos.reabrirpedido;

        return this.http.post(url, body, this.getHttpOptions());
    }

    getProjetosControleEmbarqueFinalizado(periodo: number, tipofiltro: String, campo: String, dataini: Date, datafin: Date) {
        const dtini = formatDate(dataini, 'dd/MM/yyyy', 'pt-BR');
        const dtfin = formatDate(datafin, 'dd/MM/yyyy', 'pt-BR');

        const body = this.getBody();
        body.param = [
            { paramName: 'PERIODO', paramValue: periodo },
            { paramName: 'FILTRO', paramValue: tipofiltro },
            { paramName: 'CAMPO', paramValue: campo },
            { paramName: 'DATAINI', paramValue: dtini },
            { paramName: 'DATAFIN', paramValue: dtfin },
        ];

        const url = this.urlAmbiente + portalEuro.resources.portal.projetos.controleEmbarqueFinalizado;

        return this.http.post(url, body, this.getHttpOptions());
    }

    getPendenciasCoordenador() {
        const url = this.urlAmbiente + portalEuro.resources.portal.parceiros.pendenciaCoordenador;

        return this.http.post(url, this.getBody(), this.getHttpOptions());
    }

    atualizaParecerCoordenador(codparc: Number, vlrCredito: String, parecerCoord: String, idFlow: String, tipo: String) {
        const body = this.getBody();

        body.param = [
            { paramName: 'CODPARC', paramValue: codparc },
            { paramName: 'VLRCREDITO', paramValue: vlrCredito },
            { paramName: 'PARECER', paramValue: parecerCoord },
            { paramName: 'IDFLOW', paramValue: idFlow },
            { paramName: 'TIPO', paramValue: tipo },
        ];

        const url = this.urlAmbiente + portalEuro.resources.portal.parceiros.atualizaParecerCoordenador;

        return this.http.post(url, body, this.getHttpOptions());
    }

    openSnackbar(mensagem: string, duracao = 5000, vertical = 'top') {
        this.snackBar.open(mensagem, 'Fechar', {
            verticalPosition: vertical == 'bottom' ? 'bottom' : 'top',
            horizontalPosition: 'end',
            duration: duracao,
        });
    }

    // Os parametros para a api encontrar o relatorio sao: report-uri, output-format, async, pasta-relatorio e nome-arquivo
    // Os demais parametros serao utilizados pelo jasper na geracao do relatorio
    async fetchRelatorio(params) {
        if (this.gerandoRelat) {
            this.openSnackbar('Um relatório já está sendo gerando, aguarde sua finalização!');
            return;
        }
        let relatoriosUrl = [];
        this.gerandoRelat = true;
        this.openSnackbar('O relatório será aberto em outra janela');

        try {
            const headers = {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
                Eurosession: localStorage.getItem('eurosession'),
                Accept: 'text/event-stream',
                'Content-Type': 'application/json',
                version,
            };

            const body = this.getBody();
            body.param = params;

            const options = { method: 'POST', headers: headers, body: JSON.stringify(body) };

            const response = await fetch(this.urlAmbiente + portalEuro.resources.portal.relatorios.gerarRelatorio, options);
            const reader = response.body.getReader();
            const decoder = new TextDecoder('utf-8');

            let partialData = '';
            while (true) {
                const { value, done } = await reader.read();
                if (done) break;

                const chunk = decoder.decode(value, { stream: true });
                partialData += chunk;

                let lines = partialData.split('\n');
                partialData = lines.pop() || '';

                for (const line of lines) {
                    if (line === 'fim') {
                        break;
                    } else if (!['execution', 'ready'].includes(line)) {
                        relatoriosUrl.push(line);
                    } else if (line.startsWith('ERRO: ')) {
                        throw line;
                    }
                }
            }
            if (partialData) {
                console.log(partialData);
            }
        } catch (err) {
            console.log(err);
            this.openSnackbar('Erro ao gerar relatório: ' + err);
        } finally {
            this.gerandoRelat = false;
        }
        this.openSnackbar('Seu relatório foi gerado. Caso não seja exibido, habilite pop-ups e tente novamente', 15000);
        return relatoriosUrl;
    }

    getCamposControleEmbarque() {
        const url = this.urlAmbiente + portalEuro.resources.portal.projetos.getCamposControleEmbarque;

        return this.http.post(url, this.getBody(), this.getHttpOptions());
    }
}
