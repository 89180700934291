import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControleEmbarqueToolTipComponent } from './controle-embarque-tool-tip.component';
import { ControleEmbarqueToolTipDirective } from './controle-embarque-tool-tip.directive';

@NgModule({
    declarations: [ControleEmbarqueToolTipComponent, ControleEmbarqueToolTipDirective],
    imports: [CommonModule],
    exports: [ControleEmbarqueToolTipDirective],
    entryComponents: [ControleEmbarqueToolTipComponent],
})
export class ControleEmbarqueToolTipModule {}
