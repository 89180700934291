import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material';

@Component({
    selector: 'app-bottom-sheet-grupo-de-produto',
    templateUrl: './bottom-sheet-grupo-de-produto.component.html',
    styleUrls: ['./bottom-sheet-grupo-de-produto.component.scss'],
})
export class BottomSheetGrupoDeProdutoComponent implements OnInit {
    constructor(public bottomSheetRef: MatBottomSheetRef<any>, @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) {}

    ngOnInit() {
        this.controlaToggle();
    }

    todos = true;

    close() {
        this.bottomSheetRef.dismiss(this.data);
    }

    marcarTodos(event: any) {
        if (event.checked) {
            this.data.forEach((grupo: any) => {
                grupo.isAtivo = true;
            });
        } else {
            this.data.forEach((grupo: any) => {
                grupo.isAtivo = false;
            });
        }
    }

    controlaToggle() {
        this.data.find(grupo => grupo.isAtivo == false) ? (this.todos = false) : (this.todos = true);
    }
}
