export interface CabecalhoPedido {
    NUNOTA?: number;              // Numero do Pedido
    NUMNOTA: number;             // Numero da Nota Fiscal
    NUREM: number;               // Numero do proximo documento
    DTNEG?: string;               // Data da Negociação
    DTFATUR: string;             // Data do Faturamento
    CODPARC: number;             // Codigo do Parceiro principal (Cliente)
    CODTIPOPER: number;          // Codigo do Tipo de Operacao
    TIPMOV: string;              // Tipo de Movimento (P => Pedido de Venda, N => Nota Fiscal, C => Compras) - será sempre 'P'
    CODVEND: number;             // Codigo do Vendedor
    CODMOEDA: number;            // Codigo da Moeda
    OBSERVACAO: string;          // Observação do Pedido
    TIPFRETE: string;            // Tipo de Frete - Extra Nota(N) ou Incluso(S) será sempre 'N'
    CIFFOB: string;              // C => CIF ou F => FOB
    VLRNOTA: number;             // Valor Total do Pedido
    ORDEMCARGA: number;          // Ordem de Carga (Numero do Embarque)
    SEQCARGA: number;            // Sequencia da Ordem de Carga
    QTDVOL: number;              // Quantidade de Volumes
    APROVADO: string;            // Aprovado (S => SIM ou N => NAO) inicializa com 'N'
    STATUSNOTA: string;          // Status do Pedido
    CODUSU: number;              // Codigo do Usuario
    CODPARCDEST: number;         // Codigo do Parceiro (Sera o mesmo do campo CODPARC)
    CODPROJ: number;             // Codigo do Projeto (Numero PO - Programado)
    PESO: number;                // Peso do Pedido
    PESOBRUTO: number;           // Peso Bruto do Pedido
    CODCIDORIGEM: number;        // Codigo da Cidade Origem do Pedido
    CODCIDDESTINO: number;       // Codigo da Cidade Destino
    ADREMESSA: boolean;          // Remessa (TRUE or FALSE)
    ADPARCDESTCTOORD: number;    // Codigo do Parceiro Destinatario (Cliente Remessa)
    TIPOPEDIDO: number;          // Tipo do Pedido (Somente para o Foça de Vendas - Pronta Entrega ou Programado)
    ADORIGPEDV: string;          // Origem do Pedido (P => Portal | S => Sankhya) - será sempre 'P'
    ADOBSPED: string;            // Observação Especial do Pedido
    CODTIPVENDA: number;         // Tipo de Negociacao (Condicao de Pagamento)
    CODNAT?: number;             // Natureza Receita
    CODEMP?: number;             // Codigo da Empresa
    ADTRANSPREDESPACHO: number;  // Código da transportadora
    ADPILOTAGEM?: string;        // Pilotagem
    ADHASHID?: string;           // Hash gerado na criação do pedido para verificação de duplicidade
    ADCODTABCOM?: number;        // Código Tabela Comissão
    ADNUTABCOM?: number;         // Nro Único Tabela comissão 
    ADREGRACOM?: string;         // Descrição da faixa de comissão
    ADPEDIDOJUNTAR?: string;     // Pedido para juntar
    ADACONFIRMAR?: string;     // Pedido a confirmar
}

export interface ItensPedido {
    ITEM: Item[];
}

export interface Item {
    NUNOTA: number;                     // Numero do Pedido
    SEQUENCIA: number;                  // Sequencia do Item do Pedido
    QTDNEG: number;                     // Quantidade do Item
    VLRUNIT: number;                    // Valor Unitario do Item (Moeda Real)
    VLRTOT: number;                     // Valor Total do Item (Moeda Real)
    CODVOL: string;                     // Unidade de Medida
    STATUSNOTA: string;                 // Status da Nota
    VLRUNITMOE: number;                 // Valor Unitario da Moeda (Moeda Dólar)
    VLRTOTMOE: number;                  // Valor Total da Moeda (Moeda Dólar)
    CONTROLE: string;                   // Controle (Cor)
    ADTIPOSALPEDPROG: string;           // Atribuir 'F' quando o Item for incluido acima do saldo (Somente Venda Programada)
    ADCODUNIPRO: number;                // Codigo do Produto Unificado
    CODLOCALORIG?: number;              // Local Origem
    DESCRPROD?: string;                 // Descrição do item
    PESOBRUTO?: number;                 // Peso Bruto do Produto
    PESOLIQ?: number;                   // Peso Liquido do Produto
    ADPERCTOLPEDCOM?: number;           // Percentual de Tolerancia (Venda Programada)
    ADMULTIVEN?: number;                // Multiplo (Facilitador da quantidade)
    VLRVENDA?: number;                  // Preço unitário do item
    CODPROJ?: number;                   // Número do pedido programado
    DESCRCOR?: string;                  // Descrição da cor
    ESTOQUE?: number;                   // Saldo em estoque
    SALDOPERC?: number;                 // Tolerancia Saldo
    GRUPO?: number;                     // Quando inserir todas as cores, produtos com grupo poderá atualizar o preço
    QTDMIN?: number;                    // Qtd. minima para excecao de preco
    ADVLRUNITTAB?: number;              // Valor atual na tabela de preco
    ADCOMVLR?: number;                  // Valor da comissao no item
    ADCOMPERC?: number;                 // Percentual de comissão no item 
    TEMIPIVENDA?: string;               // Tem IPI
    PRECOORIGINAL?: number;             // Preço de inserção do item
    ADEXCECAOPRECO?: string;            // Controle com preço de exceção
}

export enum TipoOperacao {
    PRONTAENTREGA = 1000,
    PRONTAENTREGADOLAR = 1003,
    PROGRAMADO = 1006,
    PROGRAMADODOLAR = 1009
}

export enum TipoPedido {
    PRONTAENTREGA = 1,
    PROGRAMADO = 2
}

export enum TipoMoeda {
    REAL = 0,
    DOLAR = 1
}

export enum CifFob {
    CIF = 'C',
    FOB = 'F'
}

export enum TipFrete {
    EXTRANOTA = 'N',
    INCLUSO = 'I'
}

export enum OrigPed {
    PORTAL = 'P',
    SANKHYA = 'S'
}

export enum TipMov {
    PEDIDO = 'P',
    NOTA = 'N'
}
