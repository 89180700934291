import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tag-status-cad-cli',
  templateUrl: './tag-status-cad-cli.component.html',
  styleUrls: ['./tag-status-cad-cli.component.scss']
})
export class TagStatusCadCliComponent implements OnInit {

  @Input() status: string;

  constructor() { }

  ngOnInit() {
  }

}
