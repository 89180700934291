import { Component, Input, OnInit, ViewContainerRef } from '@angular/core';
import { ProdutoUnificado } from 'src/app/global-interfaces/produto-interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PortalServiceService } from 'src/app/services/portal-service.service';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { SpinnerComponent } from 'src/app/global-components/spinner/spinner.component';
import { SankhyaServiceService } from 'src/app/services/sankhya-service.service';
import { BottomSheetGrupoDeProdutoComponent } from 'src/app/global-components/bottom-sheet-grupo-de-produto/bottom-sheet-grupo-de-produto.component';
import { MatBottomSheet } from '@angular/material';

@Component({
    selector: 'app-relatorio-tabela-de-preco-gerencial',
    templateUrl: './relatorio-tabela-de-preco-gerencial.component.html',
    styleUrls: ['./relatorio-tabela-de-preco-gerencial.component.scss', '../relatorios.component.scss'],
})
export class RelatorioTabelaDePrecoGerencialComponent implements OnInit {
    constructor(
        private snackBar: MatSnackBar,
        private portalService: PortalServiceService,
        public overlay: Overlay,
        public viewContainerRef: ViewContainerRef,
        private sankhyaService: SankhyaServiceService,
        private bottomSheet: MatBottomSheet
    ) {
        this.isTodos = true;
        this.produtosRelatorio = [];
    }

    ngOnInit() {}

    @Input() restUrl: string;
    @Input() listaGrupoProd = [];

    isTodos: boolean;

    produtoSelecionado: ProdutoUnificado;
    produtosRelatorio: ProdutoUnificado[];

    toggleTodos() {
        this.isTodos = !this.isTodos;
        this.limparProdutosRelatorio();
    }

    limparProdutosRelatorio() {
        this.produtosRelatorio = [];
    }

    inserirProduto() {
        this.produtosRelatorio.push(this.produtoSelecionado);

        this.produtoSelecionado = {
            DESCRPROD: null,
            VLRVENDA: null,
        } as ProdutoUnificado;
    }

    deletarItemFiltro(index: number) {
        this.produtosRelatorio.splice(index, 1);
    }

    gerarPdf() {
        let bodyRequest = {};

        if (this.isTodos) {
            bodyRequest = {
                relatorio: 'tabela-de-preco',
                isTodos: true,
                params: [],
            };
        } else {
            if (this.produtosRelatorio.length === 0) {
                this.snackBar.open('Informe os itens no campo ou ative a opção "Todos"!', 'Fechar', {
                    verticalPosition: 'top',
                    horizontalPosition: 'end',
                    duration: 5000,
                });

                return false;
            } else {
                const codigosProdutos = this.produtosRelatorio.map(produto => produto.ADCODUNIPRO);

                bodyRequest = {
                    relatorio: 'tabela-de-preco',
                    isTodos: false,
                    params: codigosProdutos,
                };
            }
        }

        let gruposDeProduto = '';

        this.listaGrupoProd.map(grupo => {
            if (gruposDeProduto === '' && grupo.isAtivo) {
                gruposDeProduto = `${grupo.CODGRUPOPROD}`;
            } else if (gruposDeProduto !== '' && grupo.isAtivo) {
                gruposDeProduto = `${gruposDeProduto},${grupo.CODGRUPOPROD}`;
            }
        });

        // Mostrando o spinner na tela
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

        const dataParam = {
            LISTGRUPOPROD: gruposDeProduto,
        };
        /* this.sankhyaService.getPermissaoUsu()
            .subscribe((retorno: any) => {

                const ativo = retorno[0].ATIVO;

                if (ativo === 'S') {

                    this.portalService.imprimirGeral({ url: this.restUrl + '.pdf' });
                } else {

                    location.reload();
                }
            },  (error) => {

                location.reload();

                }
            ); */

            this.portalService.imprimirGeral({ params: dataParam, url: this.restUrl + '.pdf' });

        // Removendo o spinner da tela
        overlayRef.dispose();
    }

    selecionaGrupoProd() {
        this.bottomSheet.open(BottomSheetGrupoDeProdutoComponent, { disableClose: true, data: this.listaGrupoProd });
    }
}
