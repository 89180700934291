import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

    /**
     * Exibe um spinner na tela, que deve ser encerrado com overlayRef.dispose();
     * @param overlay
     * @param viewContainerRef
     * @returns overlayRef - Referencia do spinner para que possa chamar o metodo dispose.
     * public static getSpinnerOverlayRef(overlay: Overlay, config: OverlayConfig, viewContainerRef: ViewContainerRef): OverlayRef {
     *      * @--param config
     */
    public static getSpinnerOverlayRef(overlay: Overlay, viewContainerRef: ViewContainerRef): OverlayRef {

        // Atribuindo as features do spinner
        const config = new OverlayConfig();
        config.positionStrategy = overlay.position()
            .global()
            .centerHorizontally()
            .centerVertically();

        // Definindo que o spinner ficará em uma camada acima da aplicação
        config.hasBackdrop = true;

        // Criando a camada com as configurações e atribuindo a uma constante
        const overlayRef = overlay.create(config);

        // Mostrando o spinner na tela
        overlayRef.attach(new ComponentPortal(SpinnerComponent, viewContainerRef));
        return overlayRef
    }

}
