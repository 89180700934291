import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { ProdutoUnificado, Controle } from 'src/app/global-interfaces/produto-interface';
import { ItensPedido, Item, TipoMoeda } from 'src/app/global-interfaces/pedido-interface';
import { CorDesenho } from './local-interfaces/criar-pedido-itens-pedido.model';
import { PortalServiceService } from '../../services/portal-service.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../global-components/dialog/dialog.component';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
    selector: 'app-criar-pedido-itens-pedido',
    templateUrl: './criar-pedido-itens-pedido.component.html',
    styleUrls: ['./criar-pedido-itens-pedido.component.scss']
})
export class CriarPedidoItensPedidoComponent implements OnInit, OnChanges {

    // Cópia da itensPedido original do componente pai
    @Input() itensPedido: ItensPedido;

    // Tipo Pedido
    @Input() tipoPedido: number;

    // Codigo do Projeto
    @Input() codProj: number;

    // Output para o cabecalho dos Itens do Pedido
    @Output() itensPedidoChange = new EventEmitter<ItensPedido>();

    // Esta variável guarda a API com todos os dados dos tecidos selecionados no autocomplete
    @Input() produtosUnificados: ProdutoUnificado[];

    // Vetor que guarda todas as cores/desenhos selecionadas
    @Input() ttCorDesenho: CorDesenho[];

    // Variáveis auxiliares
    @Input() produtoSelecionado: ProdutoUnificado;
    @Output() produtoSelecionadoChange = new EventEmitter<ProdutoUnificado>();

    // As variáveis abaixo servem para criar badge e para acionar o componente pai caso seja necessário zerar o pedido todo
    @Input() quantidadeItens: number;
    @Output() quantidadeItensChange = new EventEmitter<number>();

    // A variável a seguir serve para verificar se o usuário está trabalhando com pedido em real ou dólar
    @Input() codMoeda: number;

    @Input() medidaPilotagem: number;

    @Input() isPilotagem: boolean;

    @Input() codEmp: number;

    @Input() disableEmp: boolean;
    @Output() disableEmpChange = new EventEmitter<boolean>();

    quantidadeCores: number[];

    // isPilotagem: boolean = false;

    formGroup = new FormGroup({

        formControlPrecoUnit: new FormControl()
    });

    constructor(private portalService: PortalServiceService, public dialog: MatDialog) {
        this.produtosUnificados = [];
    }


    ngOnInit() {
        // Inicializando os vetores
        this.itensPedido = {
            ...this.itensPedido,
            ITEM: []
        };

        this.produtoSelecionado = {
            ADCODUNIPRO: null,
            DESCRPROD: null,
            CONTROLE: [],
            CODVOL: '',
            PESOBRUTO: null,
            PESOLIQ: null,
            QTDNEG: null,
            ADPERCTOLPEDCOM: null,
            ADMULTIVEN: null,
            VLRVENDA: null
        };

        this.quantidadeCores = [];

    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.itensPedido) {
            this.produtosUnificados = this.produtosUnificados.filter(produto => {
                return this.itensPedido.ITEM.find(item => produto.ADCODUNIPRO === item.ADCODUNIPRO);
            });

            this.quantidadeItens = this.produtosUnificados.length;
            this.quantidadeItensChange.emit(this.quantidadeItens);
        }
        this.produtoSelecionadoChange.emit(this.produtoSelecionado);
    }

    // Este método emite o produtoSelecionado do componente filho para o componente pai
    emitirProdutoSelecionado() {
        this.produtoSelecionado.VLRUNITTAB = this.produtoSelecionado.VLRVENDA;
        this.disableEmpChange.emit(true);
        this.produtoSelecionadoChange.emit(this.produtoSelecionado);
    }

    alteraPrecoUnitario(event: number) {
        this.produtoSelecionado.VLRVENDAALT = event;
    }

    // Método que adiciona o tecido selecionado à itensPedido filha
    adicionarItem() {
        const validaProdutoVazio = this.validaProdutoVazio();

        if (!validaProdutoVazio) {
            this.openSnackbar('Exitem itens selecionados sem cores adicionadas!');
        } else {
            if (!this.produtoSelecionado.ADCODUNIPRO || !this.produtoSelecionado.VLRVENDA) {
                this.openSnackbar('Informe o item e o preço unitário!');
            } else {
                // Necessário adicionar mais um índice à ttCordDesenho para que o ngFor faça o loop corretamente
                this.ttCorDesenho.push({
                    CONTROLE: null,
                    QTDPECA: null,
                    QTDNEG: null,
                    ESTOQUE: null,
                    GRUPO: 0,
                    PRECODIGITADO: null,
                    QTDMIN: 0
                });

                this.produtoSelecionado = {
                    ...this.produtoSelecionado,
                    VLRVENDA: +this.produtoSelecionado.VLRVENDA,
                    VLRVENDAALT: +this.produtoSelecionado.VLRVENDAALT,
                    QTDNEG: 0,
                    telaTotQtd: 0,
                    telaTotVlr: 0,
                    telaVlrMed: 0,
                    PRECOORIGINAL: +this.produtoSelecionado.VLRVENDA,
                };

                // Populando a produtosUnificados
                this.produtosUnificados.push(this.produtoSelecionado);

                this.quantidadeItens = this.produtosUnificados.length;

                // Emitindo a quantidade de itens para o componente pai
                this.quantidadeItensChange.emit(this.quantidadeItens);

                // if (this.produtoSelecionado.TEMEXCECAO) {
                //     this.openDialog('avisoTemExcecao').subscribe(
                //         (res) => {
                //             // função sem tratamento
                //         }
                //     );
                // }

                this.produtoSelecionado = {
                    DESCRPROD: null,
                    VLRVENDA: null
                } as ProdutoUnificado;
            }
        }

    }

    removerItem(i: number, produto: any) {
        // Remover de produtosUnificados
        this.produtosUnificados.splice(i, 1);
        this.ttCorDesenho.splice(i, 1);

        this.quantidadeItens = this.produtosUnificados.length;
        this.quantidadeCores.splice(i, 1);

        // Percorrer por itensPedido e remover o produto
        this.itensPedido.ITEM = this.itensPedido.ITEM.filter(item => item.ADCODUNIPRO !== produto.ADCODUNIPRO);

        // Emitindo a quantidade de itens para o componente pai
        this.quantidadeItensChange.emit(this.quantidadeItens);
    }

    // Evento disparado caso o usuário altere o preço unitário no input dentro dos collapsibles de cada item adicionado
    atualizaPrecoItensPedido(produtoUnificado: ProdutoUnificado) {

        // Percorrer itensPedido
        this.itensPedido.ITEM.map(itemPedido => {

            // Se ADCODUNIPRO de itemPedido for igual ADCODUNIPRO de produtoUnificado, atualizar VLRUNIT de itemPedido
            if (itemPedido.ADCODUNIPRO === produtoUnificado.ADCODUNIPRO) {
                itemPedido.VLRUNIT = produtoUnificado.VLRVENDA;
            }

        });

        this.itensPedidoChange.emit(this.itensPedido);
    }

    // Método que adiciona a cor/desenho aos tecidos tendo como referência o índice de cada tecido
    adicionarCorDesenho(index: number) {

        let controle: Controle;
        let isProgramado: boolean;

        // Esta variável vai armazenar um conjunto de "true" e "false" para serem testados
        const isCamposVazios = [
            this.ttCorDesenho[index].CONTROLE === null,
            this.ttCorDesenho[index].QTDNEG === 0,
            this.ttCorDesenho[index].QTDNEG === null
        ];

        // Esta variável vai armazenar um conjunto de "true" e "false" para serem testados
        const isCamposNegativos = [
            this.ttCorDesenho[index].QTDNEG < 0,
            this.ttCorDesenho[index].QTDPECA < 0
        ];

        const isCamposPilotagem = [
            this.ttCorDesenho[index].QTDNEG > this.medidaPilotagem
        ]

        // Validando se os inputs foram preenchidos
        if (isCamposVazios.includes(true)) {
            this.openSnackbar('Preencha os campos necessários para adição de cor/desenho!');
            this.ttCorDesenho[index].QTDPECA = null;
            this.ttCorDesenho[index].QTDNEG = null;
            return false;
        }

        if (isCamposNegativos.includes(true)) {
            this.openSnackbar('Valores negativos não são permitidos!');
            this.ttCorDesenho[index].QTDPECA = null;
            this.ttCorDesenho[index].QTDNEG = null;
            return false;
        }

        if (isCamposPilotagem.includes(true) && this.isPilotagem) {
            this.openSnackbar('Quantidade para pilotagem não pode ultrapassar ' + this.medidaPilotagem);
            this.ttCorDesenho[index].QTDNEG = null;
            this.ttCorDesenho[index].QTDPECA = null;
            return false;
        }

        // Verificando se é programado. Se existe CODPROJ no CONTROLE de produtosUnificados, então é programado. Se não, pronta entrega.
        controle = this.buscarControle(this.produtosUnificados[index], this.ttCorDesenho[index]);

        isProgramado = controle.CODPROJ !== 0 ? true : false;

        /*
        ** Neste ponto do código é importante salientar que:
        ** A quantidade de peças está amarrada à quantidade de metros, ou seja, se o usuário muda o valor de uma na tela,
        ** a outra automaticamente muda também e vice-versa.
        **
        ** De posse da informação acima, em alguns casos, é possível que a quantidade de peças não seja um número inteiro. Alguns dos casos são:
        ** 1) O usuário quer inserir o estoque inteiro e este estoque não necessariamente bate com o múltiplo. Isto quebra o número de peças;
        ** 2) O estoque já foi consumido por completo e o consumo será os 10% de tolerância, que novamente pode não representar o múltiplo.
        **
        ** Para casos como este, o código deve permitir a inserção de quantidade de peças que não sejam números inteiros.
        ** A validação abaixo existe para que estas condições sejam atendidas.
        */

        // A quantidade de peças não é número inteiro?
        if (!Number.isInteger(this.ttCorDesenho[index].QTDPECA)) {

            // Então a primeira coisa é verificar se o saldo é positivo. É positivo?
            if (this.ttCorDesenho[index].ESTOQUE > 0) {

                if (!this.isPilotagem) {
                    // Então a única forma de permitir quantidade de peças quebradas é se a quantidade em metros = estoque inteiro
                    if (this.ttCorDesenho[index].QTDNEG !== this.ttCorDesenho[index].ESTOQUE) {
                        this.openSnackbar(`Insira valores múltiplos de ${this.produtosUnificados[index].ADMULTIVEN}!`);
                        this.ttCorDesenho[index].QTDNEG = null;
                        this.ttCorDesenho[index].QTDPECA = null;
                        return false;
                    }
                }

            }

            // Se o saldo não for positivo, significa que é negativo. Sendo negativo... (apenas para PO)
            if (this.ttCorDesenho[index].ESTOQUE <= 0) {

                // Somente valida múltiplo se não for pilotagem
                if (!this.isPilotagem) {

                    // Neste caso é necessário verificar quanto da tolerância já foi consumida
                    const saldoTolerancia = parseFloat((this.ttCorDesenho[index].SALDOPERC + this.ttCorDesenho[index].ESTOQUE).toFixed(2));

                    if (this.ttCorDesenho[index].QTDNEG !== saldoTolerancia) {
                        this.openSnackbar(`Insira valores múltiplos de ${this.produtosUnificados[index].ADMULTIVEN}!`);
                        this.ttCorDesenho[index].QTDNEG = null;
                        this.ttCorDesenho[index].QTDPECA = null;
                        return false;
                    }
                }
            }
        } else {
            if (isProgramado) {
                const porcentagemAdicional = this.produtosUnificados[index].ADPERCTOLPEDCOM;
                // const toleranciaMaximaSaldo = controle.ESTOQUE + (controle.ESTOQUE * porcentagemAdicional / 100);
                const toleranciaMaximaSaldo = controle.SALDOPERC;

                // Se o estoque da COR/DESENHO for positivo, então a quantidade inserida não pode ser maior do que o estoque
                if (this.ttCorDesenho[index].ESTOQUE > 0 && this.ttCorDesenho[index].QTDNEG > this.ttCorDesenho[index].ESTOQUE) {
                    this.openSnackbar(`A quantidade em metros informada é maior do que o saldo!`);
                    return false;
                }

                if (this.ttCorDesenho[index].QTDNEG > toleranciaMaximaSaldo + this.ttCorDesenho[index].ESTOQUE) {
                    const labelToleranciaMaxima = toleranciaMaximaSaldo.toString().replace('.', ',');
                    const labelToleranciaDisponivel = (toleranciaMaximaSaldo + this.ttCorDesenho[index].ESTOQUE).toFixed(2).replace('.', ',');
                    this.openSnackbar(`
            A tolerância é de ${porcentagemAdicional}% do saldo, ou seja, ${labelToleranciaMaxima} metros.
            Tolerância disponível: ${labelToleranciaDisponivel} metros.
          `);
                    return false;
                }
            } else {
                // Validando se a quantidade inserida é maior que a quantidade em estoque
                if (this.ttCorDesenho[index].QTDNEG > this.ttCorDesenho[index].ESTOQUE) {
                    this.openSnackbar('A quantidade em metros informada é maior do que o saldo!');
                    return false;
                }
            }
        }

        // Esta variável será uma instância de um item que será adicionado à itensPedido a cada vez que uma nova cor for selecionada
        let itemCompleto: Item;

        const sequencia: number = this.itensPedido.ITEM.length === 0 ?
            this.itensPedido.ITEM.length + 1 : this.itensPedido.ITEM[this.itensPedido.ITEM.length - 1].SEQUENCIA + 1;

        itemCompleto = {
            NUNOTA: 0,
            SEQUENCIA: sequencia,
            QTDNEG: this.ttCorDesenho[index].QTDNEG,
            CODVOL: this.produtosUnificados[index].CODVOL,
            STATUSNOTA: 'Liberado',
            CONTROLE: this.ttCorDesenho[index].CONTROLE,
            ADTIPOSALPEDPROG: '',
            ADCODUNIPRO: this.produtosUnificados[index].ADCODUNIPRO,
            CODPROJ: controle.CODPROJ,
            VLRTOT: 0,
            VLRTOTMOE: 0,
            VLRUNIT: this.codMoeda === TipoMoeda.REAL ? this.produtosUnificados[index].VLRVENDA : 0,
            VLRUNITMOE: this.codMoeda === TipoMoeda.DOLAR ? this.produtosUnificados[index].VLRVENDA : 0,
            GRUPO: 0,
            ADVLRUNITTAB: this.produtosUnificados[index].VLRUNITTAB,
            TEMIPIVENDA: this.produtosUnificados[index].TEMIPIVENDA,
            PRECOORIGINAL: this.produtosUnificados[index].PRECOORIGINAL,
            ADEXCECAOPRECO: 'N'
        };

        if (this.ttCorDesenho[index].GRUPO) {
            itemCompleto.GRUPO = this.ttCorDesenho[index].GRUPO;
            itemCompleto.ADVLRUNITTAB = itemCompleto.QTDNEG < this.ttCorDesenho[index].QTDMIN ? this.produtosUnificados[index].VLRUNITTAB : controle.PRECO;
            itemCompleto.VLRUNIT = (this.codMoeda === TipoMoeda.REAL) ? this.ttCorDesenho[index].PRECODIGITADO : 0;
            itemCompleto.VLRUNITMOE = (this.codMoeda === TipoMoeda.DOLAR) ? this.ttCorDesenho[index].PRECODIGITADO : 0;
        }

        // Populando a itensPedido
        this.itensPedido.ITEM.push(itemCompleto);
        this.itensPedidoChange.emit(this.itensPedido);

        // Salva o valor dos itens para exibir na tabela
        this.atualizaQuantidadeItem(index);

        // Necessário resetar a this.ttCorDesenho, caso contrário, os inputs ficarão com preenchidos
        this.ttCorDesenho[index] = {
            CONTROLE: null,
            QTDPECA: null,
            QTDNEG: null,
            ESTOQUE: null,
            SALDOPERC: null,
            GRUPO: 0,
            PRECODIGITADO: null,
            QTDMIN: 0
        };

        // Incrementar a quantidade do item na tela
        this.atualizaQuantidadeItem(index);

        this.quantidadeCores[index] = this.filtroItensPedido(this.produtosUnificados[index].ADCODUNIPRO).length;
    }

    inserirTodoSaldo(index: number, corDesenho: CorDesenho) {

        // Busca o controle
        let controle: Controle;
        controle = this.buscarControle(this.produtosUnificados[index], corDesenho);

        if (corDesenho.CONTROLE === null && corDesenho.ESTOQUE === null) {
            this.openSnackbar('Informe a cor que você deseja inserir o saldo!');
        } else {
            // Esta variável será uma instância de um item que será adicionado à itensPedido a cada vez que uma nova cor for selecionada
            let itemCompleto: Item;

            const sequencia: number = this.itensPedido.ITEM.length === 0 ?
                this.itensPedido.ITEM.length + 1 : this.itensPedido.ITEM[this.itensPedido.ITEM.length - 1].SEQUENCIA + 1;

            itemCompleto = {
                NUNOTA: 0,
                SEQUENCIA: sequencia,
                QTDNEG: controle.CODPROJ !== 0 && corDesenho.ESTOQUE <= 0 ?
                    parseFloat((corDesenho.SALDOPERC + corDesenho.ESTOQUE).toFixed(2)) : corDesenho.ESTOQUE,
                VLRUNIT: this.codMoeda === TipoMoeda.REAL ? this.produtosUnificados[index].VLRVENDA : 0,
                VLRTOT: 0,
                CODVOL: this.produtosUnificados[index].CODVOL,
                STATUSNOTA: 'Liberado',
                VLRUNITMOE: this.codMoeda === TipoMoeda.DOLAR ? this.produtosUnificados[index].VLRVENDA : 0,
                VLRTOTMOE: 0,
                CONTROLE: corDesenho.CONTROLE,
                ADTIPOSALPEDPROG: '',
                ADCODUNIPRO: this.produtosUnificados[index].ADCODUNIPRO,
                CODPROJ: this.buscarControle(this.produtosUnificados[index], corDesenho).CODPROJ,
                GRUPO: 0,
                ADVLRUNITTAB: this.produtosUnificados[index].VLRUNITTAB,
                TEMIPIVENDA: this.produtosUnificados[index].TEMIPIVENDA,
                PRECOORIGINAL: this.produtosUnificados[index].PRECOORIGINAL,
                ADEXCECAOPRECO: 'N'
            };

            if (corDesenho.GRUPO) {
                itemCompleto.GRUPO = corDesenho.GRUPO;
                itemCompleto.ADVLRUNITTAB = itemCompleto.QTDNEG < this.ttCorDesenho[index].QTDMIN ? this.produtosUnificados[index].VLRUNITTAB : controle.PRECO;
                // itemCompleto.VLRUNIT = (this.codMoeda === TipoMoeda.REAL) ? corDesenho.PRECODIGITADO : 0;
                // itemCompleto.VLRUNITMOE = (this.codMoeda === TipoMoeda.DOLAR) ? corDesenho.PRECODIGITADO : 0;
            }

            //atribuindo qtdneg = 1 para evitar erro no sankhya com quantidades abaixo de 0,01
            itemCompleto.QTDNEG = itemCompleto.QTDNEG < 1 ? 1 : itemCompleto.QTDNEG;

            // Populando a itensPedido
            this.itensPedido.ITEM.push(itemCompleto);
            this.itensPedidoChange.emit(this.itensPedido);

            // Salva o valor dos itens para exibir na tabela
            this.atualizaQuantidadeItem(index);

            // Necessário resetar a this.ttCorDesenho, caso contrário, os inputs ficarão com preenchidos
            this.ttCorDesenho[index] = {
                CONTROLE: null,
                QTDPECA: null,
                QTDNEG: null,
                ESTOQUE: null,
                SALDOPERC: null,
                GRUPO: 0,
                PRECODIGITADO: null
            };

            // Incrementar a quantidade do item na tela
            this.atualizaQuantidadeItem(index);

            this.quantidadeCores[index] = this.filtroItensPedido(this.produtosUnificados[index].ADCODUNIPRO).length;
        }

    }

    // Evento disparado quando o usuário seleciona uma cor/desenho no combo box
    selecionaCorDesenho(event: string, indice: number) {
        this.ttCorDesenho[indice].CONTROLE = event;

        // Percorrendo o vetor das cores e desenhos e atribuindo o saldo
        this.produtosUnificados[indice].CONTROLE.map((item) => {
            if (item.CODCOR === event) {
                this.ttCorDesenho[indice].ESTOQUE = item.ESTOQUE;
                this.ttCorDesenho[indice].SALDOPERC = item.SALDOPERC;
                this.ttCorDesenho[indice].PRECODIGITADO = this.produtosUnificados[indice].VLRVENDAALT;

                if (item.GRUPO && item.GRUPO > 0) {
                    // this.ttCorDesenho[indice].PRECODIGITADO = item.PRECO;
                    this.ttCorDesenho[indice].GRUPO = item.GRUPO;
                    this.ttCorDesenho[indice].QTDMIN = item.QTDMIN;

                } else {
                    // this.ttCorDesenho[indice].PRECODIGITADO = this.produtosUnificados[indice].VLRVENDAALT;
                    this.ttCorDesenho[indice].GRUPO = 0;
                }
            }
        });
    }

    // Método de edição de cor/desenho
    editaCorDesenho(indexItem: number, itemPedido: Item) {

        if(this.isPilotagem) {
            this.openSnackbar('Função não permitida para pilotagem!');
            return false;
        }

        const parametros = {
            indexItem,
            qtdMetros: itemPedido.QTDNEG,
            multiplo: this.produtosUnificados[indexItem].ADMULTIVEN,
            saldo: this.buscarControle(this.produtosUnificados[indexItem], itemPedido).ESTOQUE,
            saldoPerc: this.buscarControle(this.produtosUnificados[indexItem], itemPedido).SALDOPERC,
            grupo: itemPedido.GRUPO,
            vlrUnit: itemPedido.VLRUNIT ? itemPedido.VLRUNIT : itemPedido.VLRUNITMOE,
            qtdMin: this.buscarControle(this.produtosUnificados[indexItem], itemPedido).QTDMIN,
            vlrAlt: this.produtosUnificados[indexItem].VLRVENDAALT
        };

        this.openDialog('editarCorDesenho', parametros).subscribe(result => {
            // Validando se o resultado possui dados e se foram informados números
            if (result && result.quantidadePecas > 0 && result.quantidadeMetros > 0) {
                itemPedido.QTDNEG = result.quantidadeMetros;
                if (itemPedido.GRUPO) {
                    itemPedido.ADVLRUNITTAB = itemPedido.QTDNEG < this.buscarControle(this.produtosUnificados[indexItem], itemPedido).QTDMIN ? this.produtosUnificados[indexItem].VLRUNITTAB : this.buscarControle(this.produtosUnificados[indexItem], itemPedido).PRECO;
                    itemPedido.VLRUNIT = (this.codMoeda === TipoMoeda.REAL) ? result.vlrUnit : 0;
                    itemPedido.VLRUNITMOE = (this.codMoeda === TipoMoeda.DOLAR) ? result.vlrUnit : 0;
                }
                // Salva o valor dos itens para exibir na tabela
                this.atualizaQuantidadeItem(indexItem);
            }
        });
        return false;
    }

    // Método de remoção de cor/desenho
    removeCorDesenho(indexItem: number, itemPedido: Item) {
        this.itensPedido.ITEM = this.itensPedido.ITEM.filter(item => item.SEQUENCIA !== itemPedido.SEQUENCIA);
        this.atualizaQuantidadeItem(indexItem);
        this.quantidadeCores[indexItem] = this.filtroItensPedido(this.produtosUnificados[indexItem].ADCODUNIPRO).length;
    }

    // Método de inserir todas as cores de uma vez
    inserirTodasCores(index: number) {

        if(this.isPilotagem) {
            this.openSnackbar('Função não permitida para pilotagem!');
            return false;
        }

        let qtdProvisoria: number;

        // Uma caixa de diálogo abre perguntando quantas peças o usuário quer
        this.openDialog('gridPEPO', 'geral').subscribe(res => {

            // Dar seguimento a inclusão apenas se o usuário responder algum número acima de 0
            if (res && res > 0) {
                
                // A QTDNEG precisa ser resetada pois o loop a seguir vai recalcular tudo de novo
                // this.produtosUnificados[index].QTDNEG = 0;

                // Esta é a quantidade em metros, resultado da multiplicação: quantidade de peças x múltiplo
                qtdProvisoria = res * this.produtosUnificados[index].ADMULTIVEN;

                /*
                ** Caso existam cores com saldo já consumido, perguntar ao usuário se ele deseja consumir a tolerância.
                ** Para isso será necessário percorrer pelos controles para identificar se existe algum zerado ou negativo.
                */
                const possuiEstoqueNegativo: boolean = this.produtosUnificados[index].CONTROLE.some((controle: Controle) => controle.ESTOQUE <= 0);

                // Se este produto possui estoque negativo...
                if (possuiEstoqueNegativo) {
                    
                    // ... então abrir uma caixa de diálogo perguntando é para considerar as cores com estoque negativo
                    this.openDialog(
                        'considerarEstoqueNegativo',
                        { ADPERCTOLPEDCOM: this.produtosUnificados[index].ADPERCTOLPEDCOM }
                    ).subscribe((considerarEstoqueNegativo: boolean) => {
                        // Populando a itensPedido com as cores restantes que estão em produtosUnificados
                        this.filtroCorDesenho(index).forEach(item => {

                            // O estoque do controle esgotou?
                            if (item.ESTOQUE <= 0) {
                                
                                // ... então é será verificado se o usuário respondeu que ou que não ao considerar estoque negativo.

                                // Considerar estoque negativo?
                                if (considerarEstoqueNegativo) {
                                    let itemCompleto: Item;
                                    const sequencia: number = this.itensPedido.ITEM.length === 0 ?
                                        this.itensPedido.ITEM.length + 1 : this.itensPedido.ITEM[this.itensPedido.ITEM.length - 1].SEQUENCIA + 1;

                                    itemCompleto = {
                                        NUNOTA: 0,
                                        SEQUENCIA: sequencia,
                                        QTDNEG: qtdProvisoria > item.SALDOPERC + item.ESTOQUE ? item.SALDOPERC + item.ESTOQUE : qtdProvisoria,
                                        VLRUNIT: this.codMoeda === TipoMoeda.REAL ? this.produtosUnificados[index].VLRVENDA : 0,
                                        VLRTOT: 0,
                                        CODVOL: this.produtosUnificados[index].CODVOL,
                                        STATUSNOTA: 'Liberado',
                                        VLRUNITMOE: this.codMoeda === TipoMoeda.DOLAR ? this.produtosUnificados[index].VLRVENDA : 0,
                                        VLRTOTMOE: 0,
                                        CONTROLE: item.CODCOR,
                                        ADTIPOSALPEDPROG: '',
                                        ADCODUNIPRO: this.produtosUnificados[index].ADCODUNIPRO,
                                        CODPROJ: item.CODPROJ,
                                        GRUPO: 0,
                                        ADVLRUNITTAB: this.produtosUnificados[index].VLRUNITTAB,
                                        TEMIPIVENDA: this.produtosUnificados[index].TEMIPIVENDA,
                                        PRECOORIGINAL: this.produtosUnificados[index].PRECOORIGINAL,
                                        ADEXCECAOPRECO: 'N'
                                    };

                                    if (item.GRUPO) {
                                        itemCompleto.GRUPO = item.GRUPO;
                                        itemCompleto.ADVLRUNITTAB = itemCompleto.QTDNEG < this.ttCorDesenho[index].QTDMIN ? this.produtosUnificados[index].VLRUNITTAB : item.PRECO;
                                        // itemCompleto.VLRUNIT = (this.codMoeda === TipoMoeda.REAL) ? item.PRECO : 0;
                                        // itemCompleto.VLRUNITMOE = (this.codMoeda === TipoMoeda.DOLAR) ? item.PRECO : 0;
                                    }

                                    //atribuindo qtdneg = 1 para evitar erro no sankhya com quantidades abaixo de 0,01
                                    itemCompleto.QTDNEG = itemCompleto.QTDNEG < 1 ? 1 : itemCompleto.QTDNEG;

                                    this.itensPedido.ITEM.push(itemCompleto);

                                    // Salva o valor dos itens para exibir na tabela
                                    this.atualizaQuantidadeItem(index);
                                }
                            } else {
                            
                                // ... então neste caso a cor possui saldo positivo e ela pode ser incluída no pedido direto
                                let itemCompleto: Item;

                                const sequencia: number = this.itensPedido.ITEM.length === 0 ?
                                    this.itensPedido.ITEM.length + 1 : this.itensPedido.ITEM[this.itensPedido.ITEM.length - 1].SEQUENCIA + 1;

                                itemCompleto = {
                                    NUNOTA: 0,
                                    SEQUENCIA: sequencia,
                                    QTDNEG: (qtdProvisoria <= item.ESTOQUE) ? qtdProvisoria : item.ESTOQUE,
                                    VLRUNIT: this.codMoeda === TipoMoeda.REAL ? this.produtosUnificados[index].VLRVENDA : 0,
                                    VLRTOT: 0,
                                    CODVOL: this.produtosUnificados[index].CODVOL,
                                    STATUSNOTA: 'Liberado',
                                    VLRUNITMOE: this.codMoeda === TipoMoeda.DOLAR ? this.produtosUnificados[index].VLRVENDA : 0,
                                    VLRTOTMOE: 0,
                                    CONTROLE: item.CODCOR,
                                    ADTIPOSALPEDPROG: '',
                                    ADCODUNIPRO: this.produtosUnificados[index].ADCODUNIPRO,
                                    CODPROJ: item.CODPROJ,
                                    GRUPO: 0,
                                    ADVLRUNITTAB: this.produtosUnificados[index].VLRUNITTAB,
                                    TEMIPIVENDA: this.produtosUnificados[index].TEMIPIVENDA,
                                    PRECOORIGINAL: this.produtosUnificados[index].PRECOORIGINAL,
                                    ADEXCECAOPRECO: 'N'
                                };

                                if (item.GRUPO) {
                                    itemCompleto.GRUPO = item.GRUPO;
                                    itemCompleto.ADVLRUNITTAB = itemCompleto.QTDNEG < this.ttCorDesenho[index].QTDMIN ? this.produtosUnificados[index].VLRUNITTAB : item.PRECO;
                                    // itemCompleto.VLRUNIT = (this.codMoeda === TipoMoeda.REAL) ? item.PRECO : 0;
                                    // itemCompleto.VLRUNITMOE = (this.codMoeda === TipoMoeda.DOLAR) ? item.PRECO : 0;
                                }

                                this.itensPedido.ITEM.push(itemCompleto);

                                // Salva o valor dos itens para exibir na tabela
                                this.atualizaQuantidadeItem(index);
                            }
                        });
                        /* this.itensPedido.ITEM.map(itemPedido => {
                            if (itemPedido.ADCODUNIPRO === this.produtosUnificados[index].ADCODUNIPRO) {
                                this.produtosUnificados[index].QTDNEG += itemPedido.QTDNEG;
                            }
                        }); */

                        this.quantidadeCores[index] = this.filtroItensPedido(this.produtosUnificados[index].ADCODUNIPRO).length;
                        this.itensPedidoChange.emit(this.itensPedido);
                    });
                } else {
                    // Populando a itensPedido com as cores restantes que estão em produtosUnificados
                    this.filtroCorDesenho(index).map(item => {
                        let itemCompleto: Item;

                        const sequencia: number = this.itensPedido.ITEM.length === 0 ?
                            this.itensPedido.ITEM.length + 1 : this.itensPedido.ITEM[this.itensPedido.ITEM.length - 1].SEQUENCIA + 1;

                        itemCompleto = {
                            NUNOTA: 0,
                            SEQUENCIA: sequencia,
                            QTDNEG: (qtdProvisoria <= item.ESTOQUE) ? qtdProvisoria : item.ESTOQUE,
                            VLRUNIT: this.codMoeda === TipoMoeda.REAL ? this.produtosUnificados[index].VLRVENDA : 0,
                            VLRTOT: 0,
                            CODVOL: this.produtosUnificados[index].CODVOL,
                            STATUSNOTA: 'Liberado',
                            VLRUNITMOE: this.codMoeda === TipoMoeda.DOLAR ? this.produtosUnificados[index].VLRVENDA : 0,
                            VLRTOTMOE: 0,
                            CONTROLE: item.CODCOR,
                            ADTIPOSALPEDPROG: '',
                            ADCODUNIPRO: this.produtosUnificados[index].ADCODUNIPRO,
                            CODPROJ: item.CODPROJ,
                            GRUPO: 0,
                            ADVLRUNITTAB: this.produtosUnificados[index].VLRUNITTAB,
                            TEMIPIVENDA: this.produtosUnificados[index].TEMIPIVENDA,
                            PRECOORIGINAL: this.produtosUnificados[index].PRECOORIGINAL,
                            ADEXCECAOPRECO: 'N'
                        };

                        if (item.GRUPO) {
                            itemCompleto.GRUPO = item.GRUPO;
                            itemCompleto.ADVLRUNITTAB = itemCompleto.QTDNEG < item.QTDMIN ? this.produtosUnificados[index].VLRUNITTAB : item.PRECO;
                            // itemCompleto.VLRUNIT = (this.codMoeda === TipoMoeda.REAL) ? item.PRECO : 0;
                            // itemCompleto.VLRUNITMOE = (this.codMoeda === TipoMoeda.DOLAR) ? item.PRECO : 0;
                        }

                        this.itensPedido.ITEM.push(itemCompleto);

                        // Salva o valor dos itens para exibir na tabela
                        this.atualizaQuantidadeItem(index);
                    });
                    /* this.itensPedido.ITEM.map(itemPedido => {
                        if (itemPedido.ADCODUNIPRO === this.produtosUnificados[index].ADCODUNIPRO) {
                            this.produtosUnificados[index].QTDNEG += itemPedido.QTDNEG;
                        }
                    }); */

                    this.quantidadeCores[index] = this.filtroItensPedido(this.produtosUnificados[index].ADCODUNIPRO).length;
                    this.itensPedidoChange.emit(this.itensPedido);
                }
            }
        });
    }

    /* inserirCoresProgramado(itensPedidoProgramado: ItensPedido) {
        // Zerando todos os vetores
        this.itensPedido = { ...this.itensPedido, ITEM: [] };
        this.produtosUnificados = [];
        this.ttCorDesenho = [];

        // Populando itensPedido com todos os itens do programado
        this.itensPedido = itensPedidoProgramado;

        // Percorrendo pelos itens do programado para popular ttCorDesenho e produtosUnificados
        itensPedidoProgramado.ITEM.forEach((item, index) => {
            this.ttCorDesenho.push({
                CONTROLE: null,
                QTDPECA: null,
                QTDNEG: null,
                ESTOQUE: null,
                SALDOPERC: null,
                GRUPO: 0,
                PRECODIGITADO: null,
            });

            if (index === 0) {
                this.produtosUnificados.push({
                    ADCODUNIPRO: item.ADCODUNIPRO,
                    DESCRPROD: item.DESCRPROD,
                    CODVOL: item.CODVOL,
                    PESOBRUTO: item.PESOBRUTO,
                    PESOLIQ: item.PESOLIQ,
                    QTDNEG: item.QTDNEG,
                    ADPERCTOLPEDCOM: item.ADPERCTOLPEDCOM,
                    ADMULTIVEN: item.ADMULTIVEN,
                    VLRVENDA: item.VLRVENDA,
                    CONTROLE: []
                });

                this.produtosUnificados[index].CONTROLE.push({
                    CODCOR: item.CONTROLE,
                    DESCRCOR: item.DESCRCOR,
                    ESTOQUE: item.ESTOQUE,
                    CODPROJ: item.CODPROJ
                });

                return false;
            }

            if (item.ADCODUNIPRO === this.produtosUnificados[this.produtosUnificados.length - 1].ADCODUNIPRO) {
                this.produtosUnificados[this.produtosUnificados.length - 1].CONTROLE.push({
                    CODCOR: item.CONTROLE,
                    DESCRCOR: item.DESCRCOR,
                    ESTOQUE: item.ESTOQUE,
                    CODPROJ: item.CODPROJ
                });

                return false;
            }

            if (item.ADCODUNIPRO !== this.produtosUnificados[this.produtosUnificados.length - 1].ADCODUNIPRO) {
                this.produtosUnificados.push({
                    ADCODUNIPRO: item.ADCODUNIPRO,
                    DESCRPROD: item.DESCRPROD,
                    CODVOL: item.CODVOL,
                    PESOBRUTO: item.PESOBRUTO,
                    PESOLIQ: item.PESOLIQ,
                    QTDNEG: item.QTDNEG,
                    ADPERCTOLPEDCOM: item.ADPERCTOLPEDCOM,
                    ADMULTIVEN: item.ADMULTIVEN,
                    VLRVENDA: item.VLRVENDA,
                    CONTROLE: []
                });

                this.produtosUnificados[this.produtosUnificados.length - 1].CONTROLE.push({
                    CODCOR: item.CONTROLE,
                    DESCRCOR: item.DESCRCOR,
                    ESTOQUE: item.ESTOQUE,
                    CODPROJ: item.CODPROJ
                });

                return false;
            }
        });
    } */

    // Este método retorna os controles que estão disponíveis para serem selecionados
    filtroCorDesenho(index: number) {
        return this.produtosUnificados[index].CONTROLE.filter(corDesenho => {
            return !this.itensPedido.ITEM.find(cor => {
                return corDesenho.CODCOR === cor.CONTROLE && this.produtosUnificados[index].ADCODUNIPRO === cor.ADCODUNIPRO;
            });
        });
    }

    validaProdutoVazio(): boolean {
        // Percorrendo pelos produtos adicionados, verificando se eles possuem controles e montando um array com o resultado
        const validador = this.produtosUnificados.map(produtoUnificado => {
            return this.itensPedido.ITEM.find(itemPedido => produtoUnificado.ADCODUNIPRO === itemPedido.ADCODUNIPRO);
        });

        // Se existe algum índice no vetor como undefined, então algum produto está sem controle adicionado
        const validado = validador.includes(undefined) ? false : true;

        // Retornando verdade ou false
        return validado;
    }

    // Este método atualiza a quantidade do item, zerando e recalculando todas cores
    atualizaQuantidadeItem(index: number) {
        this.produtosUnificados[index].telaTotQtd = 0.00;
        this.produtosUnificados[index].telaVlrMed = 0.00;
        this.produtosUnificados[index].telaTotVlr = 0.00;

        this.filtroItensPedido(this.produtosUnificados[index].ADCODUNIPRO).forEach(item => {
            this.produtosUnificados[index].telaTotQtd += item.QTDNEG
            this.produtosUnificados[index].telaTotVlr += item.QTDNEG * (item.VLRUNIT + item.VLRUNITMOE);
        });

        this.produtosUnificados[index].telaVlrMed = this.produtosUnificados[index].telaTotVlr / this.produtosUnificados[index].telaTotQtd;
    }

    // Este método retorna os controles que já foram selecionados
    filtroItensPedido(codunipro: number) {
        return this.itensPedido.ITEM.filter(itemPedido => {
            return itemPedido.ADCODUNIPRO === codunipro;
        });
    }

    buscarControle(produtoUnificado: ProdutoUnificado, itemPedido: any): Controle {
        const controle = produtoUnificado.CONTROLE.find(produto => produto.CODCOR === itemPedido.CONTROLE);
        return controle;
    }

    calcularMultiplo(indice: number, campoDigitado: string) {

        if (!this.isPilotagem) {
            if (campoDigitado === 'peca') {
                this.ttCorDesenho[indice].QTDNEG = this.ttCorDesenho[indice].QTDPECA * this.produtosUnificados[indice].ADMULTIVEN;
            } else {
                this.ttCorDesenho[indice].QTDPECA = this.ttCorDesenho[indice].QTDNEG / this.produtosUnificados[indice].ADMULTIVEN;
            }
        } else {
            if (this.ttCorDesenho && this.ttCorDesenho[indice].QTDNEG) {
                this.ttCorDesenho[indice].QTDNEG = +this.ttCorDesenho[indice].QTDNEG.toFixed(2);
            }
        }

        if (this.ttCorDesenho[indice].QTDNEG < this.ttCorDesenho[indice].QTDMIN) {
            this.ttCorDesenho[indice].PRECODIGITADO = this.produtosUnificados[indice].VLRVENDAALT;
        }

    }

    validaPrecoCheio() {

        // Validando se o usuário está inserindo o mesmo item de novo
        const itemDuplicado = this.produtosUnificados.some(produtoUnificado => {
            return produtoUnificado.ADCODUNIPRO === this.produtoSelecionado.ADCODUNIPRO;
        });

        if (itemDuplicado) {
            this.openSnackbar('Você já inseriu este tecido!');
            return false;
        }

        // Caso o usuário use o preço sugerido...
        if (Number(this.produtoSelecionado.VLRVENDA) === Number(this.produtoSelecionado.VLRVENDAALT)) {

            this.openDialog('precoCheio', { pergunta: 'Confirma preço cheio?' }).subscribe(
                (result: boolean) => {

                    if (result) {
                        this.adicionarItem();

                    } else {
                        return false;
                    }

                }
            );

            return false;
        } else {

            // Se for pilotagem, garantir que o Preço é cheio
            if (this.isPilotagem) {
                this.portalService.openSnackbar('Pedidos de Pilotagem somente com Preço Cheio');

                return false;
            }
        }


        // Caso o usuário insira preço maior que o preço sugerido...
        if (Number(this.produtoSelecionado.VLRVENDA) < Number(this.produtoSelecionado.VLRVENDAALT)) {

            this.openDialog('precoCheio', { pergunta: 'Confirma preço maior que o sugerido?' }).subscribe(
                (result: boolean) => {

                    if (result) {
                        this.produtoSelecionado.VLRVENDA = this.produtoSelecionado.VLRVENDAALT;
                        this.adicionarItem();
                    } else {
                        return false;
                    }
                }
            );

            return false;
        }

        // Caso o usuário insira preço menor que o preço sugerido...
        if (Number(this.produtoSelecionado.VLRVENDA) >= Number(this.produtoSelecionado.VLRVENDAALT)) {
            this.produtoSelecionado.VLRVENDA = this.produtoSelecionado.VLRVENDAALT;
            this.adicionarItem();
            return false;
        }

    }

    openDialog(tipo: string, params?: any) {
        const dialogRef = this.dialog.open(DialogComponent, {
            width: '300px',
            data: { tipo, params }
        });

        return dialogRef.afterClosed();
    }

    openSnackbar(mensagem: string) {
        this.portalService.openSnackbar(mensagem);
    }

    monitorar(param: string) {
        if (param === 'itensPedido') {
            console.table(this.itensPedido.ITEM);
        }

        if (param === 'produtosUnificados') {
            console.table(this.produtosUnificados);
        }

        if (param === 'ttCorDesenho') {
            console.table(this.ttCorDesenho);
        }

        if (param === 'produtoSelecionado') {
            console.table(this.produtoSelecionado);
        }
    }

    setIsPilotagem(isPilotagem: boolean) {

        // this.isPilotagem = isPilotagem;

        if (isPilotagem)
            this.formGroup.controls['formControlPrecoUnit'].disable();
        else
            this.formGroup.controls['formControlPrecoUnit'].enable();
    }

    encontraIndiceProdutoUnificado(produto: ProdutoUnificado): number {

        return this.produtosUnificados.findIndex((prod) => produto.ADCODUNIPRO === prod.ADCODUNIPRO);
    }

}
