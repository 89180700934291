import { Overlay } from '@angular/cdk/overlay';
import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { PortalJpaService } from '../services/portal-jpa.service';
import { SpinnerComponent } from '../global-components/spinner/spinner.component';
import { PortalServiceService } from '../services/portal-service.service';
import { Parceiro } from '../global-interfaces/parceiro-interface';
import { ProdutoUnificado } from '../global-interfaces/produto-interface';

@Component({
    selector: 'app-itens-projecao-chegada',
    templateUrl: './itens-projecao-chegada.component.html',
    styleUrls: ['./itens-projecao-chegada.component.scss'],
})
export class ItensProjecaoChegadaComponent implements OnInit {
    constructor(
        private portalJPA: PortalJpaService,
        private portalService: PortalServiceService,
        public overlay: Overlay,
        public viewContainerRef: ViewContainerRef
    ) {}

    ngOnInit() {
        this.inicializaVariaveis();
    }

    inicializaVariaveis() {
        this.parceiroCliente = {
            ATIVO: 'S',
            BLOQUEAR: 'N',
            CGCCPF: '',
            CLIENTE: '',
            CODVEND: 0,
            COMPLEMENTO: '',
            EMAIL: '',
            FORNECEDOR: '',
            IDENTINSCESTAD: '',
            LIMCRED: 0,
            NOMEBAI: '',
            NOMECID: '',
            NOMEEND: '',
            NOMEPARC: '',
            NUMEND: 0,
            RAZAOSOCIAL: '',
            CODPARC: 0,
            TELEFONE: '',
            TIPO: '',
            TRANSPORTADORA: '',
            VENDEDOR: '',
        };
    }

    Tabelas: any[] = [
        { value: 'D100', viewValue: 'D100' },
        { value: 'D057', viewValue: 'D057' },
        { value: 'AMBOS', viewValue: 'Ambos' },
    ];

    tabelaSelecionada;

    exibeD100 = false;

    codProd: number;
    descProjCli = false;

    D100Colunas: string[] = [
        'CONTROLE',
        'SALDO_TOT',
        'PORCENTAGEMPROD',
        'ESTPO',
        'ESTCONT',
        'SALDOPRJ01',
        'SALDOPRJ02',
        'SALDOPRJ03',
        'SALDOPRJ04',
        'SALDOPRJ05',
        'SALDOPRJ06',
        'SALDOPRJ07',
        'SALDOPRJ08',
        'SALDOPRJ09',
        'SALDOPRJ10',
        'SALDOPRJ11',
        'SALDOPRJ12',
        'SALDOPRJ13',
        'SALDOPRJ14',
        'SALDOPRJ15',
        'SALDOPRJ16',
        'SALDOPRJ17',
        'SALDOPRJ18',
        'SALDOPRJ19',
        'SALDOPRJ20',
        'SALDOPRJ21',
        'SALDOPRJ22',
        'SALDOPRJ23',
        'SALDOPRJ24',
        'SALDOPRJ25',
        'SALDOPRJ26',
        'SALDOPRJ27',
        'SALDOPRJ28',
        'SALDOPRJ29',
        'SALDOPRJ30',
        'SALDOPRJ31',
        'SALDOPRJ32',
        'SALDOPRJ33',
        'SALDOPRJ34',
        'SALDOPRJ35',
        'SALDOPRJ36',
        'SALDOPRJ37',
        'SALDOPRJ38',
        'SALDOPRJ39',
        'SALDOPRJ40',
    ];

    D100Tabela = [];

    itensProjecaoChegadaCab: any[] = [];
    itensProjecaoChegadaCabProjetos = {};
    projetos = [];

    itensProjecaoChegada: any[] = [];

    periodoIni: Date;
    periodoFin: Date;

    D057Colunas: string[] = [
        'CODPROD',
        'CONTROLE',
        'SOMA_MESES',
        'PERCENT',
        'JANEIRO',
        'FEVEREIRO',
        'MARCO',
        'ABRIL',
        'MAIO',
        'JUNHO',
        'JULHO',
        'AGOSTO',
        'SETEMBRO',
        'OUTUBRO',
        'NOVEMBRO',
        'DEZEMBRO',
    ];

    D057Tabela = [];

    D057Colunas2: string[] = ['PERANTERIOR', 'QTDANTERIOR', 'PERATUAL', 'QTDATUAL', 'DIF', 'DIFPERC'];

    analiseFatItens: any[] = [];
    analiseFatItensAnt: any[] = [];

    exibeD057 = false;

    parceiroCliente: Parceiro;
    produto: number;
    descVendaDireta = false;
    desclimpaEstoque = false;
    cor = '';

    pesquisaD100() {
        if (!this.codProd) {
            this.portalService.openSnackbar('Informe um produto!');
            return false;
        }

        this.exibeD100 = true;

        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

        this.portalJPA.getCabItensProjChegada(this.codProd, this.descProjCli ? 'S' : 'N').subscribe(
            (res: any) => {
                if (res.serviceResponse === 'OK') {
                    this.itensProjecaoChegadaCab = res.response;

                    for (const key in this.itensProjecaoChegadaCab[0]) {
                        if (key.includes('PRJ')) {
                            this.itensProjecaoChegadaCabProjetos[key] = this.itensProjecaoChegadaCab[0][key];
                        }
                    }

                    this.projetos = Object.keys(this.itensProjecaoChegadaCabProjetos).map(key => ({
                        key,
                        value: this.itensProjecaoChegadaCabProjetos[key],
                    }));

                    this.portalJPA.getItensProjChegada(this.codProd, this.descProjCli ? 'S' : 'N').subscribe(
                        (res: any) => {
                            if (res.serviceResponse === 'OK') {
                                this.itensProjecaoChegada = res.response;

                                const MaioresQueZero = new Set();

                                res.response.forEach(obj => {
                                    this.D100Colunas.forEach(mes => {
                                        if (
                                            obj[mes] > 0 ||
                                            typeof obj[mes] === 'string' ||
                                            mes == 'PORCENTAGEMPROD' ||
                                            mes == 'SALDO_TOT' ||
                                            mes == 'ESTPO' ||
                                            mes == 'ESTCONT'
                                        ) {
                                            MaioresQueZero.add(mes);
                                        }
                                    });
                                });

                                this.D100Tabela = Array.from(MaioresQueZero);
                                this.D100Tabela = this.D100Colunas.filter(value => this.D100Tabela.includes(value));
                            }
                            overlayRef.dispose();
                        },
                        err => {
                            console.log(err);
                            overlayRef.dispose();
                        }
                    );
                }
            },
            err => {
                console.log(err);

                overlayRef.dispose();
            }
        );
    }

    pesquisaD057() {
        if (!this.periodoIni || !this.periodoFin) {
            this.portalService.openSnackbar('Informe o período!');
            return false;
        }

        if (!this.produto) {
            this.portalService.openSnackbar('Informe o produto!');
            return false;
        }

        this.exibeD057 = true;

        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

        this.portalJPA
            .getAnaliseFaturAnt(
                this.periodoIni.toLocaleDateString(),
                this.periodoFin.toLocaleDateString(),
                this.parceiroCliente.CODPARC,
                this.produto,
                this.descVendaDireta ? 'S' : 'N',
                this.desclimpaEstoque ? 'S' : 'N',
                this.cor
            )
            .subscribe(
                (res: any) => {
                    if (res.serviceResponse === 'OK') {
                        this.analiseFatItensAnt = res.response;

                        this.portalJPA
                            .getAnaliseFatur(
                                this.periodoIni.toLocaleDateString(),
                                this.periodoFin.toLocaleDateString(),
                                this.parceiroCliente.CODPARC,
                                this.produto,
                                this.descVendaDireta ? 'S' : 'N',
                                this.desclimpaEstoque ? 'S' : 'N',
                                this.cor
                            )
                            .subscribe(
                                (res: any) => {
                                    if (res.serviceResponse === 'OK') {
                                        this.analiseFatItens = res.response;
                                        const MaioresQueZero = new Set();

                                        res.response.forEach(obj => {
                                            this.D057Colunas.forEach(mes => {
                                                if (obj[mes] > 0 ||
                                                    mes == 'CONTROLE') {
                                                    MaioresQueZero.add(mes);
                                                }
                                            });
                                        });

                                        this.D057Tabela = Array.from(MaioresQueZero);
                                    }
                                    overlayRef.dispose();
                                },
                                err => {
                                    console.log(err);
                                    overlayRef.dispose();
                                }
                            );
                    }
                },
                err => {
                    console.log(err);
                    overlayRef.dispose();
                }
            );
    }

    getTotalCost(valorSomar: string) {
        let meses = [
            'SOMA_MESES',
            'JANEIRO',
            'FEVEREIRO',
            'MARCO',
            'ABRIL',
            'MAIO',
            'JUNHO',
            'JULHO',
            'AGOSTO',
            'SETEMBRO',
            'OUTUBRO',
            'NOVEMBRO',
            'DEZEMBRO',
        ];

        let colunas = [
            'SALDO_TOT',
            'PORCENTAGEMPROD',
            'ESTPO',
            'ESTCONT',
            'SALDOPRJ01',
            'SALDOPRJ02',
            'SALDOPRJ03',
            'SALDOPRJ04',
            'SALDOPRJ05',
            'SALDOPRJ06',
            'SALDOPRJ07',
            'SALDOPRJ08',
            'SALDOPRJ09',
            'SALDOPRJ10',
            'SALDOPRJ11',
            'SALDOPRJ12',
            'SALDOPRJ13',
            'SALDOPRJ14',
            'SALDOPRJ15',
            'SALDOPRJ16',
            'SALDOPRJ17',
            'SALDOPRJ18',
            'SALDOPRJ19',
            'SALDOPRJ20',
            'SALDOPRJ21',
            'SALDOPRJ22',
            'SALDOPRJ23',
            'SALDOPRJ24',
            'SALDOPRJ25',
            'SALDOPRJ26',
            'SALDOPRJ27',
            'SALDOPRJ28',
            'SALDOPRJ29',
            'SALDOPRJ30',
            'SALDOPRJ31',
            'SALDOPRJ32',
            'SALDOPRJ33',
            'SALDOPRJ34',
            'SALDOPRJ35',
            'SALDOPRJ36',
            'SALDOPRJ37',
            'SALDOPRJ38',
            'SALDOPRJ39',
            'SALDOPRJ40',
        ];

        if (meses.includes(valorSomar)) {
            return this.analiseFatItens.map(t => t[valorSomar]).reduce((acc, value) => acc + value, 0);
        } else if (colunas.includes(valorSomar)) {
            return this.itensProjecaoChegada.map(t => t[valorSomar]).reduce((acc, value) => acc + value, 0);
        } else {
            return 0; // Valor padrão se a entrada não for reconhecida
        }
    }

    emitirProdutoD100(produto: ProdutoUnificado) {
        if (produto != null) {
            this.codProd = produto.ADCODUNIPRO;
        } else {
            this.codProd = null;
        }
    }

    emitirProdutoD057(produto: ProdutoUnificado) {
        if (produto != null) {
            this.produto = produto.ADCODUNIPRO;
        } else {
            this.produto = null;
        }
    }

    limparCliente(){
        this.inicializaVariaveis();
    }
}
