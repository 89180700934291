import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'decimal'})

export class FormataDecimalPipe implements PipeTransform {

  transform(value: number): string {
    if (value) {
      return Number(value).toFixed(2);
    }
  }

}
