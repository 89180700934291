import { MatSort, MatTabGroup, MatTableDataSource } from '@angular/material';
import { Overlay } from '@angular/cdk/overlay';
import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { SpinnerComponent } from '../global-components/spinner/spinner.component';
import { PortalJpaService } from '../services/portal-jpa.service';
import { Projeto } from './local-interfaces/projeto.interface';
import { forkJoin } from 'rxjs';
import { Response } from '../global-interfaces/response';
import { ProjetosArmazenadosTerceiro } from './local-interfaces/projetos-armazenados-terceiro';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ProdutosProjeto } from './local-interfaces/produtos-projeto';

@Component({
    selector: 'app-controle-embarque',
    templateUrl: './controle-embarque.component.html',
    styleUrls: ['./controle-embarque.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed, void', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class ControleEmbarqueComponent implements OnInit {
    constructor(private portalJpaService: PortalJpaService, public overlay: Overlay, public viewContainerRef: ViewContainerRef) {}

    @ViewChild('ordGeral', { static: true }) ordGeral = new MatSort();
    @ViewChild('ordPrevisto', { static: true }) ordPrevisto = new MatSort();
    @ViewChild('ordPrevistoEmbarque', { static: true }) ordPrevistoEmbarque = new MatSort();
    @ViewChild('ordEmbarcado', { static: true }) ordEmbarcado = new MatSort();
    @ViewChild('ordAtracado', { static: true }) ordAtracado = new MatSort();
    @ViewChild('ordPatio', { static: true }) ordPatio = new MatSort();
    @ViewChild('ordArmazenados', { static: true }) ordArmazenados = new MatSort();
    @ViewChild('tabGroup', { static: true }) tabGroup: MatTabGroup;

    projetos: Projeto[] = [];
    projetosOriginal: Projeto[] = [];

    geral = new MatTableDataSource<Projeto>();
    previsto = new MatTableDataSource<Projeto>();
    previstoEmbarque = new MatTableDataSource<Projeto>();
    embarcado = new MatTableDataSource<Projeto>();
    atracado = new MatTableDataSource<Projeto>();
    patio = new MatTableDataSource<Projeto>();
    locaisUsados: number[] = [];

    filtroEmpresas = new Set<string>();

    armazenadosFiltrado: ProjetosArmazenadosTerceiro[];
    armazenadosTerceiros = new MatTableDataSource<ProjetosArmazenadosTerceiro>();
    armazenadosTerceirosOriginal: ProjetosArmazenadosTerceiro[];

    produtos = new MatTableDataSource<ProdutosProjeto>();
    expandedElement: Projeto | null;

    controleAbas = 0;
    exibeTabela = false;

    //Array informando quais campos serão exibidos em cada tabela
    columnsGeral: string[];

    camposGeral = [
        'ETAPA',
        'STATUS',
        'CODPROJ',
        'SORTIMENTO',
        'MERCPRONTEMBARQUE',
        'LOCALARMAZENAGEM',
        'AMOSTRA',
        'PROGRAMACAO',
        'PARCVENDADIRETA',
        'NOMEPARCEXP',
        'PEDIDO',
        'ARTIGOS',
        'ARMADOR',
        'AGENTEDECARGA',
        'NAVIOEMBARQUE',
        'NAVIOCHEGADA',
        'ORIGEM',
        'DESTINO',
        'EMBARQUE',
        'CHEGADA',
        'CONTAINER',
        'TAMANHO',
        'BOOKING',
        'BL',
        'NUMDI',
        'RECINTOALFA',
        'FREETIME',
        'FRETEMARITIMO',
        'NUMERONFNACIONALIZACAO',
        'DATADENACIONALIZACAO',
        'CARREGAMENTO',
        'OBSCARREGAMENTO',
        'CANAL',
        'CAMBIO',
        'DESOVA',
        'OBS',
        'OBSIMP',
        'NUMDTC',
        'CEMERCANTE',
        'DIASFREETIME',
    ];

    columnsPrevisto: string[];

    camposPrevisto = [
        'STATUS',
        'CODPROJ',
        'SORTIMENTO',
        'MERCPRONTEMBARQUE',
        'AMOSTRA',
        'PROGRAMACAO',
        'PARCVENDADIRETA',
        'NOMEPARCEXP',
        'PEDIDO',
        'ARTIGOS',
        'OBS',
    ];

    columnsPrevistoEmbarque: string[];

    camposPrevistoEmbarque = [
        'STATUS',
        'CODPROJ',
        'SORTIMENTO',
        'MERCPRONTEMBARQUE',
        'AMOSTRA',
        'PROGRAMACAO',
        'PARCVENDADIRETA',
        'NOMEPARCEXP',
        'PEDIDO',
        'ARTIGOS',
        'ARMADOR',
        'AGENTEDECARGA',
        'NAVIOEMBARQUE',
        'ORIGEM',
        'DESTINO',
        'EMBARQUE',
        'CONTAINER',
        'TAMANHO',
        'BOOKING',
        'FRETEMARITIMO',
        'OBS',
        'OBSIMP',
    ];

    columnsEmbarcado: string[];

    camposEmbarcado = [
        'STATUS',
        'CODPROJ',
        'SORTIMENTO',
        'AMOSTRA',
        'PROGRAMACAO',
        'PARCVENDADIRETA',
        'NOMEPARCEXP',
        'PEDIDO',
        'ARTIGOS',
        'ARMADOR',
        'AGENTEDECARGA',
        'NAVIOEMBARQUE',
        'NAVIOCHEGADA',
        'ORIGEM',
        'DESTINO',
        'EMBARQUE',
        'CHEGADA',
        'CONTAINER',
        'TAMANHO',
        'BL',
        'DIASFREETIME',
        'FRETEMARITIMO',
        'CAMBIO',
        'OBS',
        'OBSIMP',
        'NUMDTC',
        'CEMERCANTE',
    ];

    columnsAtracado: string[];

    camposAtracado = [
        'STATUS',
        'CODPROJ',
        'PROGRAMACAO',
        'PARCVENDADIRETA',
        'NOMEPARCEXP',
        'PEDIDO',
        'ARTIGOS',
        'ARMADOR',
        'AGENTEDECARGA',
        'NAVIOCHEGADA',
        'DESTINO',
        'CHEGADA',
        'CONTAINER',
        'BL',
        'FRETEMARITIMO',
        'NUMDI',
        'RECINTOALFA',
        'FREETIME',
        'CARREGAMENTO',
        'CANAL',
        'OBSCARREGAMENTO',
        'OBS',
        'OBSIMP',
        'NUMDTC',
        'CEMERCANTE',
    ];

    columnsPatio: string[];

    camposPatio = [
        'LOCALARMAZENAGEM',
        'CODPROJ',
        'PROGRAMACAO',
        'PARCVENDADIRETA',
        'NOMEPARCEXP',
        'PEDIDO',
        'ARTIGOS',
        'DESOVA',
        'OBS',
        'ESTOQUE',
        'OBSIMP',
    ];

    columnsArmazenadosTerceiros = ['PROJETO', 'PROG', 'NOMEPARC', 'NOMEPARCEXP', 'PEDIDO', 'ARTIGO', 'CONTAINER', 'OBSIMP'];

    columnsProdutos = ['PRODUTO', 'CODUNIPRO', 'CONTROLE', 'DESCRCOR', 'QTDNEG', 'SALDO', 'VENDA'];

    //Filtros
    filtroColuna: string = '';
    valorColuna: string = '';
    inputGeral: string = '';

    colunasMap = {
        AGENTEDECARGA: 'Agente de Carga',
        ARMADOR: 'Armador',
        ARTIGOS: 'Artigos',
        ARTIGO: 'Artigo',
        BL: 'BL',
        BOOKING: 'Booking',
        CAMBIO: 'Câmbio',
        CANAL: 'Canal',
        CEMERCANTE: 'CE Mercante',
        CONTAINER: 'Container',
        AMOSTRA: 'Data da Amostra',
        CARREGAMENTO: 'Data de Carregamento',
        CHEGADA: 'Data de Chegada',
        EMBARQUE: 'Data de Embarque',
        FREETIME: 'Data de Free Time',
        MERCPRONTEMBARQUE: 'Data de Merc. Pronta para Embarque',
        DATADENACIONALIZACAO: 'Data de Nacionalização',
        DESTINO: 'Destino',
        DESOVA: 'Desova',
        ESTOQUE: 'Estoque',
        ETAPA: 'Etapa',
        FRETEMARITIMO: 'Frete Marítimo',
        LOCALARMAZENAGEM: 'Local de Armazenagem',
        NAVIOCHEGADA: 'Navio Chegada',
        NAVIOEMBARQUE: 'Navio Embarque',
        NOMEPARC: 'Nome do Parceiro',
        NOMEPARCEXP: 'Nome do Parceiro',
        NUMDI: 'Número DI',
        NUMDTC: 'Número DTC',
        NUMERONFNACIONALIZACAO: 'Número NF Nacionalização',
        OBS: 'Observação',
        OBSCARREGAMENTO: 'Observação Carregamento',
        OBSIMP: 'Observação Importação',
        ORIGEM: 'Origem',
        PARCVENDADIRETA: 'Parc. Venda Direta',
        PEDIDO: 'Pedido',
        PROGRAMACAO: 'Programação',
        PROG: 'Programação',
        PROJETO: 'Projeto',
        CODPROJ: 'Projeto',
        RECINTOALFA: 'Recinto Alfa',
        SORTIMENTO: 'Sortimento',
        STATUS: 'Status',
        TAMANHO: 'Tamanho',
    };

    ngOnInit() {
        this.getProjetos();
    }

    getProjetos() {
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

        const obj = {
            primeiro: this.portalJpaService.getCamposControleEmbarque(),
            segundo: this.portalJpaService.getProjetosControleEmbarque(),
            terceiro: this.portalJpaService.getProjetosArmazenadosTerceiro(),
        };

        forkJoin([obj.primeiro, obj.segundo, obj.terceiro]).subscribe(
            (res: any) => {
                const res0: Response = res[0];
                if (res0.serviceResponse == 'OK') {
                    const campos = res0.response;

                    this.columnsGeral = this.camposGeral.filter(campo => campos.includes(campo));

                    this.columnsPrevisto = this.camposPrevisto.filter(campo => campos.includes(campo));

                    this.columnsPrevistoEmbarque = this.camposPrevistoEmbarque.filter(campo => campos.includes(campo));

                    this.columnsEmbarcado = this.camposEmbarcado.filter(campo => campos.includes(campo));

                    this.columnsAtracado = this.camposAtracado.filter(campo => campos.includes(campo));

                    this.columnsPatio = this.camposPatio.filter(campo => campos.includes(campo));
                }

                const res1: Response = res[1];
                if (res1.serviceResponse == 'OK') {
                    this.projetos = res1.response;
                    this.projetosOriginal = this.projetos;

                    this.alimentarTabela(0);
                }

                const res2: Response = res[2];
                if (res2.serviceResponse == 'OK') {
                    this.armazenadosFiltrado = res2.response;
                    this.armazenadosTerceirosOriginal = this.armazenadosFiltrado;
                    this.armazenadosTerceiros = new MatTableDataSource(this.armazenadosFiltrado);
                    this.armazenadosTerceiros.sort = this.ordArmazenados;
                }

                if (this.tabGroup.selectedIndex != 0) {
                    this.tabGroup.selectedIndex = 0;
                }
                if (this.exibeTabela == false) {
                    this.exibeTabela = true;
                }
                overlayRef.dispose();
            },
            error => {
                overlayRef.dispose();
                console.log(error);
            }
        );
    }

    produtosProjeto(codProj) {
        if (!this.expandedElement) return;
        this.produtos = new MatTableDataSource();
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);
        this.portalJpaService.getProdutosProjeto(codProj).subscribe(
            (res: any) => {
                const prod = res.response;

                let valor = null;
                for (let i = 0; i < prod.length; i++) {
                    if (prod[i].PRODUTO === valor) {
                        prod[i].PRODUTO = null;
                    } else {
                        valor = prod[i].PRODUTO;
                    }
                }

                let valor2 = null;
                for (let i = 0; i < prod.length; i++) {
                    if (prod[i].CODUNIPRO === valor2) {
                        prod[i].CODUNIPRO = null;
                    } else {
                        valor2 = prod[i].CODUNIPRO;
                    }
                }

                let total = '999999999';
                for (let i = 0; i < prod.length; i++) {
                    if (prod[i].CONTROLE === total) {
                        prod[i].CONTROLE = null;
                        prod[i].DESCRCOR = null;
                    }
                }

                this.produtos = prod;
                overlayRef.dispose();
            },
            erro => {
                overlayRef.dispose();
                console.log(erro);
            }
        );
    }

    //Funções do filtro das tabelas
    filtrarTabela(dataSource: MatTableDataSource<Projeto>) {
        if (!this.valorColuna) {
            dataSource.filterPredicate = new MatTableDataSource().filterPredicate;
            // const filterValue = (event.target as HTMLInputElement).value;
            dataSource.filter = this.inputGeral.trim().toLowerCase();
        } else if (!this.inputGeral) {
            dataSource.filterPredicate = (data: Projeto, filter: string) => {
                const coluna = this.filtroColuna as keyof Projeto;
                return String(data[coluna]).toLowerCase().includes(filter);
            };
            dataSource.filter = this.valorColuna.trim().toLowerCase();
        } else {
            dataSource.filterPredicate = (data: Projeto, filter: string) => {
                const obj = JSON.parse(filter);
                let filtroGeral = false;
                if (this.inputGeral.trim()) {
                    for (const coluna of Object.keys(data)) {
                        if (data[coluna].toString().toLowerCase().includes(obj.filtroGeral)) {
                            filtroGeral = true;
                        }
                    }
                }
                if (!filtroGeral || !obj.filterColuna) {
                    return filtroGeral;
                }
                return String(data[obj.coluna]).toLowerCase().includes(obj.filterColuna);
            };
            dataSource.filter = JSON.stringify({
                filtroGeral: this.inputGeral.toLowerCase(),
                coluna: this.filtroColuna,
                filterColuna: this.valorColuna.toLowerCase(),
            });
        }
    }

    filtrarArmazenadosTerceiro(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.armazenadosTerceiros.filter = filterValue.trim().toLowerCase();
    }

    transformaData(value) {
        let dataStr = '';
        if (value.substring(0, 2) == 'X ') {
            dataStr = value.substring(8) + value.substring(5, 7) + value.substring(2, 4);
        } else if (value.substring(0, 4) == 'ETA ') {
            dataStr = value.substring(10) + value.substring(7, 9) + value.substring(4, 6);
        } else {
            dataStr =
                value.substring(6, 8) + value.substring(3, 5) + value.substring(0, 2) + value.substring(9, 11) + value.substring(12, 14);
        }
        return dataStr;
    }

    //Função para tratar as colunas de datas e transforma-las em string para funcionar na ordenação
    ordenaData(item, property) {
        switch (property) {
            case 'SORTIMENTO':
            case 'EMBARQUE':
            case 'CHEGADA':
            case 'FREETIME':
            case 'DATADENACIONALIZACAO':
            case 'AMOSTRA':
            case 'CARREGAMENTO':
            case 'MERCPRONTEMBARQUE': {
                if (!item[property]) {
                    return undefined;
                }
                const value = item[property];
                let dataStr = '';
                if (
                    value.substring(0, 2) == 'X ' ||
                    value.substring(0, 2) == 'C ' ||
                    value.substring(0, 2) == 'E ' ||
                    value.substring(0, 2) == 'F '
                ) {
                    dataStr = value.substring(8) + value.substring(5, 7) + value.substring(2, 4);
                } else if (value.substring(0, 4) == 'ETA ') {
                    dataStr = value.substring(10) + value.substring(7, 9) + value.substring(4, 6);
                } else {
                    dataStr =
                        value.substring(6, 8) +
                        value.substring(3, 5) +
                        value.substring(0, 2) +
                        value.substring(9, 11) +
                        value.substring(12, 14);
                }
                return dataStr;
            }
            default: {
                return item[property];
            }
        }
    }

    filtrarTabelaEmpresa(event: MouseEvent, emp: string) {
        event.stopPropagation();

        if (this.filtroEmpresas.has(emp)) {
            this.filtroEmpresas.delete(emp);
        } else {
            this.filtroEmpresas.add(emp);
        }

        const filtroEmpresasArray = Array.from(this.filtroEmpresas);

        if (this.projetos.length > 0 && filtroEmpresasArray.length > 0) {
            this.projetos = this.projetosOriginal.filter(item => {
                if (filtroEmpresasArray.includes(item.EMPRESA)) {
                    return item.EMPRESA;
                }
            });

            this.armazenadosFiltrado = this.armazenadosTerceirosOriginal.filter(item => {
                if (filtroEmpresasArray.includes(item.EMPRESA)) {
                    return item.EMPRESA;
                }
            });
        } else {
            this.projetos = this.projetosOriginal;
            this.armazenadosFiltrado = this.armazenadosTerceirosOriginal;
        }

        this.armazenadosTerceiros = new MatTableDataSource(this.armazenadosFiltrado);
        this.armazenadosTerceiros.sort = this.ordArmazenados;
        this.alimentarTabela(this.controleAbas);
    }

    corEmpresa(element: Projeto): string {
        if (element.EMPRESA === '2') {
            return '#C3BBFD';
        } else if (element.EMPRESA === '3') {
            return '#98FB98';
        } else if (element.EMPRESA === '4') {
            return '#8DB3FA';
        }
    }

    corCanal(element: Projeto): string {
        if (element.ETAPA === 'Atracado' && element.CANAL === 'VERDE') {
            return '#7CFC00';
        } else if (element.ETAPA === 'Atracado' && element.CANAL === 'VERMELHO') {
            return '#FF0000';
        } else if (element.ETAPA === 'Atracado' && element.CANAL === 'AMARELO') {
            return '#FFFF00';
        } else if (element.ETAPA === 'Atracado' && element.CANAL === 'CINZA') {
            return '#A9A9A9';
        } else if (element.ETAPA === 'Atracado' && element.CANAL === 'AG. CANAL') {
            return '#FFA500';
        }
    }

    expandir(row) {
        this.expandedElement = this.expandedElement === row ? null : row;
    }

    alimentarTabela($event) {
        this.filtroColuna = '';
        this.valorColuna = '';
        this.inputGeral = '';

        this.geral = new MatTableDataSource<Projeto>();
        this.previsto = new MatTableDataSource<Projeto>();
        this.previstoEmbarque = new MatTableDataSource<Projeto>();
        this.embarcado = new MatTableDataSource<Projeto>();
        this.atracado = new MatTableDataSource<Projeto>();
        this.patio = new MatTableDataSource<Projeto>();

        if ($event == 0) {
            const geralTable = this.projetos.sort((a, b) => {
                const projetoA = a.CODPROJ || 0;
                const projetoB = b.CODPROJ || 0;
                const projeto = projetoA - projetoB;

                return projeto;
            });
            this.geral = new MatTableDataSource(geralTable);
            this.geral.sort = this.ordGeral;
            this.geral.sortingDataAccessor = this.ordenaData;

            this.controleAbas = 0;
            return;
        }

        if ($event == 1) {
            const previstoTable = this.projetos.filter(element => {
                return element.ETAPA === 'Comprado' || element.ETAPA === 'Sortimento';
            });
            previstoTable.sort((a, b) => {
                const projetoA = a.CODPROJ || 0;
                const projetoB = b.CODPROJ || 0;
                const projeto = projetoA - projetoB;

                return projeto;
            });
            this.previsto = new MatTableDataSource(previstoTable);
            this.previsto.sort = this.ordPrevisto;
            this.previsto.sortingDataAccessor = this.ordenaData;

            this.controleAbas = 1;
            return;
        }

        if ($event == 2) {
            const previstoEmbarqueTable = this.projetos.filter(element => element.ETAPA === 'Previsto Embarque');
            previstoEmbarqueTable.sort((a, b) => {
                const embarqueA = a.EMBARQUE ? this.transformaData(a.EMBARQUE) : '999999';
                const embarqueB = b.EMBARQUE ? this.transformaData(b.EMBARQUE) : '999999';
                const embarque = embarqueA.localeCompare(embarqueB);

                const artigoA = a.ARTIGOS || '';
                const artigoB = b.ARTIGOS || '';
                const artigo = artigoA.localeCompare(artigoB);

                const projetoA = a.CODPROJ || 0;
                const projetoB = b.CODPROJ || 0;
                const projeto = projetoA - projetoB;

                return embarque || artigo || projeto;
            });
            this.previstoEmbarque = new MatTableDataSource(previstoEmbarqueTable);
            this.previstoEmbarque.sort = this.ordPrevistoEmbarque;
            this.previstoEmbarque.sortingDataAccessor = this.ordenaData;

            this.controleAbas = 2;
            return;
        }

        if ($event == 3) {
            const embarcadoTable = this.projetos.filter(element => element.ETAPA === 'Embarcado');
            embarcadoTable.sort((a, b) => {
                const chegadaA = a.CHEGADA ? this.transformaData(a.CHEGADA) : '999999';
                const chegadaB = b.CHEGADA ? this.transformaData(b.CHEGADA) : '999999';
                const chegada = chegadaA.localeCompare(chegadaB);

                const destinoA = a.DESTINO || '';
                const destinoB = b.DESTINO || '';
                const destino = destinoA.localeCompare(destinoB);

                const empresaA = a.EMPRESA || '';
                const empresaB = b.EMPRESA || '';
                const empresa = empresaA.localeCompare(empresaB);

                const projetoA = a.CODPROJ || 0;
                const projetoB = b.CODPROJ || 0;
                const projeto = projetoA - projetoB;

                return chegada || destino || empresa || projeto;
            });
            this.embarcado = new MatTableDataSource(embarcadoTable);
            this.embarcado.sort = this.ordEmbarcado;
            this.embarcado.sortingDataAccessor = this.ordenaData;

            this.controleAbas = 3;
            return;
        }

        if ($event == 4) {
            const atracadoTable = this.projetos.filter(element => element.ETAPA === 'Atracado');
            atracadoTable.sort((a, b) => {
                const carregamentoA = a.CARREGAMENTO ? this.transformaData(a.CARREGAMENTO) : '999999';
                const carregamentoB = b.CARREGAMENTO ? this.transformaData(b.CARREGAMENTO) : '999999';
                const carregamento = carregamentoA.localeCompare(carregamentoB);

                return carregamento;
            });
            this.atracado = new MatTableDataSource(atracadoTable);
            this.atracado.sort = this.ordAtracado;
            this.atracado.sortingDataAccessor = this.ordenaData;

            this.controleAbas = 4;
            return;
        }

        if ($event == 5) {
            const patioTable = this.projetos.filter(element => element.ETAPA === 'Pátio');
            patioTable.sort((a, b) => {
                const localA = parseFloat(a.LOCALARMAZENAGEM ? a.LOCALARMAZENAGEM : '0');
                const localB = parseFloat(b.LOCALARMAZENAGEM ? b.LOCALARMAZENAGEM : '0');
                return localA - localB;
            });
            this.patio = new MatTableDataSource(patioTable);
            this.patio.sort = this.ordPatio;
            this.patio.sortingDataAccessor = this.ordenaData;

            this.controleAbas = 5;
            return;
        }
    }
}
