import { Overlay } from '@angular/cdk/overlay';
import { PortalJpaService } from 'src/app/services/portal-jpa.service';
import { SpinnerComponent } from 'src/app/global-components/spinner/spinner.component';
import { PedidosOperacionalEs } from './../pedidos-operacional-es/local-interfaces/pedidos-operacional-es-interface';
import { Component, OnInit, ViewContainerRef } from '@angular/core';

@Component({
    selector: 'app-pedidos-liberado-separar',
    templateUrl: './pedidos-liberado-separar.component.html',
    styleUrls: ['./pedidos-liberado-separar.component.scss'],
})
export class PedidosLiberadoSepararComponent implements OnInit {
    constructor(public overlay: Overlay, private portalJpaService: PortalJpaService, public viewContainerRef: ViewContainerRef) {}

    ngOnInit() {
        this.atualizar();
        this.atualizacaoAutomatica = setInterval(() => this.atualizar(), 60000);
    }

    ngOnDestroy() {
        clearInterval(this.atualizacaoAutomatica);
    }

    pedidos: PedidosOperacionalEs[];
    liberado: number = 0;
    emSeparacao: number = 0;
    atualizacaoAutomatica: any;

    atualizar() {
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

        this.portalJpaService.getPedidosLibSeparar(1).subscribe(
            (res: any) => {
                this.pedidos = res.response;
                overlayRef.dispose();
            },
            erro => {
                overlayRef.dispose();
                console.log(erro);
            }
        );
    }
}
