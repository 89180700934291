import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { CountdownComponent, CountdownConfig, CountdownEvent } from 'ngx-countdown';
import { MatBottomSheet } from '@angular/material';
import { JwtHelperService } from '@auth0/angular-jwt';

import { SankhyaServiceService } from 'src/app/services/sankhya-service.service';
import { PortalServiceService } from 'src/app/services/portal-service.service';
import { AutenticacaoService } from 'src/app/services/autenticacao.service';
import { CabecalhoPedido, ItensPedido } from 'src/app/global-interfaces/pedido-interface';
import { Rotas } from 'src/app/global-interfaces/rotas-interface';
import { IntegrarPedido } from 'src/app/criar-pedido/local-interfaces/integrar-pedido-interface';
import { INotificacaoBadge } from 'src/app/global-components/notificacao/interfaces/notificacao-interface';
import { NotificacaoService } from 'src/app/global-components/notificacao/services/notificacao.service';
import { BottomSheetComponent } from './global-components/bottom-sheet/bottom-sheet.component';

const helper = new JwtHelperService();

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    @ViewChild('cd', { static: false }) private countdown: CountdownComponent;

    constructor(
        private location: Location,
        private router: Router,
        private auth: AutenticacaoService,
        private sankhyaService: SankhyaServiceService,
        private portalServices: PortalServiceService,
        private notificacaoService: NotificacaoService,
        private bottomSheet: MatBottomSheet
    ) {}

    ngOnInit() {
        if (['restarting', 'true'].includes(localStorage.getItem('refresh'))) localStorage.setItem('refresh', 'false');
        // Toda vez que trocar de rota, verifica a necessidade de atualizar as Notificações
        this.router.events.subscribe(evento => {
            try {
                if (
                    this.router.url.includes('login') ||
                    this.router.url.includes('esqueci-senha') ||
                    this.router.url.includes('alterar-senha')
                ) {
                    return;
                }

                if (evento instanceof NavigationEnd) {
                    this.notificacaoService.atualizarNotificacoes();
                    // Com a mudança de rota haverá atualização da sessão do usuário
                    if (
                        !this.isNotificacaoOpen &&
                        !['true', 'restarting'].includes(localStorage.getItem('refresh')) &&
                        localStorage.getItem('isLogged') == 'true'
                    ) {
                        localStorage.setItem('refresh', 'true');
                    }
                }
            } catch (error) {
                console.log('Erro app.component->ngOnInit(), router.events.subcribe', error);
            }
        });

        if (!(localStorage.getItem('isLogged') && localStorage.getItem('rotas'))) {
            localStorage.setItem('token', '');
            localStorage.setItem('refreshToken', '');
            localStorage.setItem('isLogged', 'false');
            localStorage.setItem('rotas', '');
            // localStorage.setItem('codRep', '');
            localStorage.setItem('refresh', '');
        } else {
            // Resgata o tempo de inatividade e inicia o contador
            this.getTokenInatividade();
        }
        this.rotaAtual = this.location.path() === '' ? '/' : this.location.path();

        this.rotas = localStorage.getItem('rotas') === '' ? [] : JSON.parse(atob(localStorage.getItem('rotas')));

        this.atualizaSidebar(this.rotaAtual);
        this.setUsuarioLogado();

        // this.clearError();
        // A galeria de imagens sempre é iniciada fechada
        this.galeriaAberta = false;

        // Fechar todas as sub-rotas
        this.rotas = this.rotas.map(rota => (rota = { ...rota, isSubrotasAtivo: false }));

        this.portalServices.getConfigUrl().subscribe((config: any) => {
            this.logomarcaPequena = config.imagens.url + '/novalogo/icones/logo-bandeira-vermelho-300px.png';
            this.logomarcaMaior = config.imagens.url + '/novalogo/logooficial-fundoazul-web.png';
        });

        this.sidenav.autoFocus = false;
    }

    logomarcaPequena: string = 'assets/logo-bandeira-vermelho-300px.png';
    logomarcaMaior: string = 'assets/logooficial-fundoazul-web.png';

    title = 'ng-portal-eurotextil';

    badge: INotificacaoBadge = this.notificacaoService.criarBadge(false, 'warn');

    private rotas: Rotas[];
    rotaAtual: string;
    timer: any;
    usuarioLogado: string;

    // isError: boolean;
    errorMessage: any[];
    fullErrorObject: any[] = [];
    /*
     ** É necessário manipular o z-index do toolbar caso a galeria de imagens seja usada.
     ** Uma instância de AppComponent é criada na galeria de imagens e este atributo abaixo
     ** é usado como referência
     */
    private galeriaAberta: boolean;
    private intervaloOperacional: number;

    tempoInatividade: number;
    countdownConfig: CountdownConfig = {
        leftTime: 100000,
        notify: 5,
        demand: true,
    };
    token: string;
    tempoNotificacaoInatividade: number;
    isNotificacaoOpen = false;

    @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;

    // Decodifica o token e resgata o tempo para o contador de inatividade
    getTokenInatividade() {
        this.token = helper.decodeToken(localStorage.getItem('token'));
        this.tempoInatividade = this.token["exp"] - Math.round(Date.now() / 1000);
        // this.tempoInatividade = this.token['exp'] - this.token['iat'] - 5;
        this.tempoNotificacaoInatividade = parseInt(this.token['inatividade']) * 60;
        this.countdownConfig = {
            leftTime: this.tempoInatividade,
            notify: 0,
            demand: false,
        };
    }

    atualizaSidebar(rotaUrl: string) {
        // Navegar por todas as rotas
        this.rotas.map(rota => {
            // Se esta rota em escopo possui sub-rotas...
            if (rota.subRotas.length > 0) {
                // ...então verificar se o usuário clicou em uma sub-rota fazendo um loop nas sub-rotas
                const clicouSubRota = rota.subRotas.find(subRota => subRota.url === rotaUrl);

                // Se o usuário clicou em uma sub-rota, destacar a rota pai dela
                if (clicouSubRota) {
                    rota.isAtivo = true;
                    this.rotaAtual = rota.url;
                }
            }

            // Se a rota em escopo é a rota que foi clicada...
            if (rotaUrl === rota.url) {
                // ... então ela precisa ficar destacada com CSS
                rota.isAtivo = true;
                this.rotaAtual = rotaUrl;

                // Todas as sub-rotas precisam fechar
                this.rotas = this.rotas.map(
                    rotaSubRota => (rotaSubRota = { ...rotaSubRota, isSubrotasAtivo: rotaSubRota.isAtivo ? true : false })
                );
            } else {
                // ... senão ela não pode ficar destacada
                rota.isAtivo = false;
            }
        });
    }

    logout() {
        //torna a rota home a rota ativa
        //this.rotas.map(rota => (rota.nome === 'Home' ? (rota.isAtivo = true) : (rota.isAtivo = false)));

        this.auth.desconectaUsu();

        this.rotas = [];

        this.usuarioLogado = null;

        this.sidenav.close();

        this.countdown.stop();
    }

    getAuth() {
        return this.auth.getIsLogged();
    }

    gerenciarConta() {
        this.router.navigateByUrl('/gerenciar-conta');
    }

    getRotas() {
        return this.rotas;
    }

    setRotas(rotas: Rotas[]) {
        localStorage.setItem('rotas', btoa(JSON.stringify(rotas)));
        this.rotas = JSON.parse(atob(localStorage.getItem('rotas')));
        // localStorage.setItem('codRep', String(codRep));
    }

    toggleSubrotas(rotaSelecionada: Rotas) {
        const index = this.rotas.findIndex(rota => rota === rotaSelecionada);

        this.rotas[index] = {
            ...this.rotas[index],
            isSubrotasAtivo: !this.rotas[index].isSubrotasAtivo,
        };
    }

    setUsuarioLogado() {
        this.usuarioLogado = this.auth.getUserName();
    }

    setError(error: string) {
        // localStorage.setItem('errorMessage', error);
        // this.isError = true;

        // this.errorMessage.push({ message: error, reenviando: false });

        this.badge.notificacoes.push({
            texto: error,
            permiteMarcar: true,
            spinnerOn: false,
            portalID: 1,
            notifID: -Date.now(),
            dhExpira: new Date(Date.now() + 24 * 60 * 60 * 1000),
        });
    }

    getGaleriaAberta() {
        return this.galeriaAberta;
    }

    setGaleriaAberta(galeriaAberta: boolean) {
        this.galeriaAberta = galeriaAberta;
    }

    previsualizar($event: any, erro: any) {
        $event.stopPropagation();
        this.errorMessage.find(e => e.message === erro).reenviando = true;

        const cabecalhoPedido: CabecalhoPedido = this.fullErrorObject.find(f => f.response === erro).bodyRequest.pedido as CabecalhoPedido;
        const itensPedido: ItensPedido = this.fullErrorObject.find(f => f.response === erro).bodyRequest.itens as ItensPedido;

        this.sankhyaService.sendPreview(cabecalhoPedido, itensPedido).subscribe(
            (res: IntegrarPedido) => {
                if (res.serviceResponse === 'ERROR') {
                    this.portalServices.openSnackbar(res.response);
                } else {
                    this.portalServices.previewProposta(res.NUNOTA);
                    this.errorMessage.find(e => e.message === erro).reenviando = false;
                }
            },
            (erro: any) => {},
            () => {}
        );
    }

    apagar($event: any, erro: any) {
        $event.stopPropagation();
        this.errorMessage.find(e => e.message === erro).reenviando = true;

        this.errorMessage.splice(
            this.errorMessage.findIndex(e => e.message === erro),
            1
        );
        this.fullErrorObject.splice(
            this.fullErrorObject.findIndex(f => f.response === erro),
            1
        );
    }

    /*    setFullError(error: any) {
        this.fullErrorObject.push(error);
    } */

    getIntervaloOperacional(): number {
        return this.intervaloOperacional;
    }

    setIntervaloOperacional(intervalo: number) {
        this.intervaloOperacional = intervalo;
    }


    // Cada segundo a função é chamada, ultimaChamadaRefreshToken será usado para evitar atualizar o token em excesso
    ultimaChamadaRefreshToken = new Date();
    contadorInatividade(e: CountdownEvent) {
        if (localStorage.getItem('isLogged') != 'true') {
            // Se o usuario não estiver logado não faz nada
            return;
        }

        // Quando o contador passar do tempo de notificação o bottom sheet de inatividade será acionado ou o token sera renovado
        if (e['left'] <= this.tempoNotificacaoInatividade * 1000 && !this.isNotificacaoOpen) {
            if (localStorage.getItem('refresh') === 'true') {
                console.log('### Atualizando o token de sessao ###');
                this.restartCountdownPost();
                this.auth.setCodRep();
                this.auth.setUserName();
            } else if (localStorage.getItem('refresh') === 'false') {
                this.isNotificacaoOpen = true;
                let param = 'inatividade';
                this.bottomSheet
                    .open(BottomSheetComponent, {
                        data: { tempoRestante: e['left'] / 1000, param },
                        disableClose: true,
                        panelClass: 'bottom-sheet',
                    })
                    .afterDismissed()
                    .subscribe(result => {
                        let param = 'inatividadeDesconectado';
                        switch (result) {
                            case 'logout':
                                this.logout();
                                this.bottomSheet.open(BottomSheetComponent, {
                                    data: { param, message: 'Você encerrou sua sessão' },
                                    panelClass: 'bottom-sheet',
                                });
                                this.isNotificacaoOpen = false;
                                break;

                            case 'logoutTimeout':
                                this.logout();
                                this.bottomSheet.open(BottomSheetComponent, {
                                    data: { param, message: 'Sua sessão foi encerrada por inatividade' },
                                    panelClass: 'bottom-sheet',
                                });
                                this.isNotificacaoOpen = false;
                                break;

                            case 'restartCountdown':
                                this.restartCountdownPost();
                                break;
                        }
                    }
                );
            }
        }

        // Caso o usuário continuar inativo, será desconectado
        if (e['action'] == 'done' || this.tempoInatividade <= 0) {
            console.log('desconectando usuário por inatividade');
            this.logout();
        }

        // Com mudança de rota ou requisição, a chave refresh no localStorage receberá true e será feita a renovação de sessão
        // Controla a ultima atualização do tokem para reduzir o execesso de atualizações
        // Este excesso estava desconectando o usuário, pois um chamado com token anterior chegava apos uma renovação do token
        // const TEMPO_3MIN = 1 * 60 * 1000;
        // const timeMillsAtual = new Date().getTime();
        // const timeMillsProximaChamada = this.ultimaChamadaRefreshToken.getTime() + TEMPO_3MIN;
        // if (localStorage.getItem('refresh') == 'true' && timeMillsAtual > timeMillsProximaChamada) {
        //     console.log('**** Chamando this.restartCountdownPost()');
        //     this.restartCountdownPost();
        //     this.ultimaChamadaRefreshToken = new Date();
        // }
    }

    // Renova a sessão do usuário
    restartCountdownPost() {
        localStorage.setItem('refresh', 'restarting');
        this.countdown.stop();

        this.portalServices.refreshSession(localStorage.getItem('refreshToken'), this.auth.getUserName()).subscribe(
            (res: any) => {
                if (res.status) {
                    localStorage.setItem('token', res.authorization);
                    this.getTokenInatividade();
                    localStorage.setItem('refresh', 'false');
                }
                this.countdown.restart;
            },
            erro => {
                console.log('Erro na renovação de sessão', erro);
                let param = 'inatividadeDesconectado';
                this.bottomSheet.open(BottomSheetComponent, {
                    data: { param, message: 'Sua sessão expirou, faça login novamente' },
                    panelClass: 'bottom-sheet',
                });
                localStorage.setItem('refresh', 'false');
                this.auth.desconectaUsu();
            }
        );

        this.isNotificacaoOpen = false;
    }

    homeEuro() {
        this.router.navigateByUrl('/');

        this.rotaAtual = '/';
    }
}
