import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProdutoUnificado } from 'src/app/global-interfaces/produto-interface';
import { FormControl } from '@angular/forms';
import { TransfereService } from 'src/app/services/transfere-dado.service';
import { first } from 'rxjs/internal/operators/first';
import { PortalServiceService } from '../../services/portal-service.service';
import { AutenticacaoService } from 'src/app/services/autenticacao.service';

@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit, AfterViewInit {
    editarCorDesenho: any;
    precoCheio: any;
    liberacoes: any;
    motivoCancelamento: any;
    qtdPecaGridPEPO: number;
    usuarioLogado: string;
    senhaJasper: string;
    produto: ProdutoUnificado;
    adPercTolPedCom: number;
    motivoRecusa: string;
    obsAprovReprov: string;
    tipoAprovDev: string;
    conteudo_insert = new FormControl('');
    conteudo_edit = new FormControl('');
    name = new FormControl('');
    id_html: any;
    titulo_publi: any;
    img_urls: any = [];
    envImg = []
    tipoDev = [{ "valor": "DE", "nome": "Desconto" },
    { "valor": "DT", "nome": "Devolução Total" },
    { "valor": "DP", "nome": "Devolução Parcial" }];
    cancelarAgendamento: any;
    observacaoCancelamento: string = '';
    erroAgendamento: string = '';

    constructor(
        public dialogRef: MatDialogRef<DialogComponent>,
        private snackbar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private transfereService: TransfereService,
        private portalService: PortalServiceService,
        private auth: AutenticacaoService
    ) {
        const { tipo, params } = this.data;

        switch (tipo) {
            case 'editarCorDesenho': {
                const { qtdMetros, multiplo, grupo, vlrUnit, qtdMin, vlrAlt } = params;

                this.editarCorDesenho = {
                    quantidadePecas: qtdMetros / multiplo,
                    quantidadeMetros: qtdMetros,
                    grupo,
                    vlrUnit,
                    qtdMin,
                    vlrAlt
                };

                break;
            }

            case 'precoCheio': {

                const { pergunta } = this.data.params;

                this.precoCheio = { ...this.precoCheio, pergunta };
                break;
            }

            case 'liberacoes': {

                const { acao } = data.params;
                this.liberacoes = { acao, motivo: '' };
                break;
            }

            case 'motivoCancelamento': {

                this.motivoCancelamento = params;
                break;
            }

            case 'removerProdutoTabelaPreco': {
                this.produto = params;
                break;
            }

            case 'considerarEstoqueNegativo': {
                this.adPercTolPedCom = params.ADPERCTOLPEDCOM;
                break;
            }

            case 'cancelarAgendamento': {
                this.cancelarAgendamento = params;
                break;
            }

            case 'novoAgendamento': {
                this.erroAgendamento = params;
                break;
            }

            default: break;
        }
        this.transfereService.getData().pipe(first()).subscribe(data => {
            if (data.html) {
                this.name = new FormControl(data.html.innerHTML)
                this.titulo_publi = data.titulo
            }
            this.img_urls = data.urlAndDescr
            this.id_html = data.id_html
        })

        this.usuarioLogado = this.auth.getUserName();
    }

    toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        [{ 'size': ['14px', '16px', '18px'] }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        ['link', 'image'],
        [{ 'align': [] }],
    ];

    ngOnInit() {

    }

    ngAfterViewInit() {
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    calcularMultiplo(campoDigitado: string) {
        const { quantidadePecas, quantidadeMetros } = this.editarCorDesenho;
        const { multiplo } = this.data.params;

        campoDigitado === 'peca' ?
            this.editarCorDesenho.quantidadeMetros = quantidadePecas * multiplo
            :
            this.editarCorDesenho.quantidadePecas = quantidadeMetros / multiplo;

        if (this.editarCorDesenho.quantidadeMetros < this.editarCorDesenho.qtdMin) {
            this.editarCorDesenho.vlrUnit = this.editarCorDesenho.vlrAlt;
        }
    }

    enviaImagensSelecionadas(image: String, check: boolean) {
        if (check) {
            this.envImg.push(image)
        } else {
            let index = this.envImg.indexOf(image)
            this.envImg.splice(index, 1)
        }
    }

    fecharDialog(event?: any) {

        const { tipo } = this.data;

        switch (tipo) {
            case 'editarCorDesenho': {
                const { quantidadePecas, quantidadeMetros } = this.editarCorDesenho;
                const { multiplo, saldo, saldoPerc } = this.data.params;

                if (quantidadeMetros === saldo) {
                    this.dialogRef.close(this.editarCorDesenho);
                    return false;
                }

                if (quantidadeMetros > saldo + saldoPerc) {
                    this.openSnackbar('A quantidade informada é maior do que o saldo!');
                    return false;
                }

                Number.isInteger(quantidadePecas) ?
                    this.dialogRef.close(this.editarCorDesenho) : this.openSnackbar(`Insira múltiplos de ${multiplo}!`);

                break;
            }

            case 'precoCheio': {
                this.dialogRef.close(event);
                break;
            }

            case 'liberacoes': {
                if (event === 'confirmar') {
                    this.liberacoes.motivo !== '' ?
                        this.dialogRef.close({
                            retorno: true,
                            params: { motivo: this.liberacoes.motivo }
                        }) : this.openSnackbar('Informe o motivo da recusa!');
                } else {
                    this.dialogRef.close({ retorno: false, params: '' });
                }
                break;
            }

            case 'aprovacaoW': {
                this.dialogRef.close(event);
                break;
            }

            case 'motivoCancelamento': {
                this.dialogRef.close();
                break;
            }

            case 'itemSemImagens': {
                this.dialogRef.close();
                break;
            }

            case 'gridPEPO': {
                this.dialogRef.close(this.qtdPecaGridPEPO);
                break;
            }

            case 'jasperUser': {
                this.dialogRef.close(
                    {
                        usuarioJasper: this.usuarioLogado,
                        senhaJasper: this.senhaJasper
                    }
                );
                break;
            }

            case 'removerProdutoTabelaPreco': {
                this.dialogRef.close(event);
                break;
            }

            case 'considerarEstoqueNegativo': {
                this.dialogRef.close(event);
                break;
            }

            case 'aprovaDevolucao': {
                if (event === 'A') {
                    this.dialogRef.close({ tipo: event, obs: this.obsAprovReprov, tipoAprovDev: this.tipoAprovDev });
                }
                else {
                    this.dialogRef.close();
                }
                break;
            }

            case 'reprovaDevolucao': {
                if (event === 'R') {
                    this.dialogRef.close({ tipo: event, obs: this.obsAprovReprov, motivo: this.motivoRecusa });
                }
                else {
                    this.dialogRef.close();
                }

                break;
            }

            case 'compartilhaImagem': {
                if (event === 'S') {
                    if (this.envImg && (this.envImg.length > 0 && this.envImg.length <= 10)) {
                        this.dialogRef.close({ image: this.envImg });
                    } else if (this.envImg.length > 10) {
                        this.portalService.openSnackbar('Quantidade de imagens selecionadas é muito grande, selecione até 10 imagens para serem compartilhadas.');
                    } else {
                        this.portalService.openSnackbar('Selecione uma imagem para compartilhar.');
                    }
                }
                else {
                    this.dialogRef.close({ close: "Fechado" });
                }

                break;
            }

            // case 'avisoTemExcecao': {
            //     this.dialogRef.close(event);
            //     break;
            // }

            case 'cancelarAgendamento': {
                if (event === true) {
                    if(this.observacaoCancelamento.length > 0){
                        this.dialogRef.close({
                            retorno: true,
                            params: { observacao: this.observacaoCancelamento }
                        });
                    }
                }
                else {
                    this.dialogRef.close({retorno: false, close: "Fechado" });
                }

                break;
            }

            case 'novoAgendamento': {
                this.dialogRef.close();
                break;
            }


            default: break;
        }
    }

    openSnackbar(message: string) {
        this.snackbar.open(message, 'Fechar', {
            horizontalPosition: 'end',
            verticalPosition: 'top',
            duration: 5000
        });
    }
}
