import { PortalServiceService } from './../../../services/portal-service.service';
import { BottomSheetPedidosFinanceiroComponent } from './../../../pedidos-financeiro/local-components/bottom-sheet-pedidos-financeiro/bottom-sheet-pedidos-financeiro.component';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
    selector: 'app-bottom-sheet-aprovacao-devolucao',
    templateUrl: './bottom-sheet-aprovacao-devolucao.component.html',
    styleUrls: ['./bottom-sheet-aprovacao-devolucao.component.scss'],
})
export class BottomSheetAprovacaoDevolucaoComponent implements OnInit {
    constructor(
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
        private bottomSheetRef: MatBottomSheetRef<BottomSheetPedidosFinanceiroComponent>,
        public portalServiceService: PortalServiceService
    ) {}

    ngOnInit() {
        this.vlrDesc = this.data.vlrDesc;
        this.percDesc = this.data.percSug;
    }

    obsAprovReprov: string;
    tipoAprovDev: string;
    motivoRecusa: string;
    motivoCancela: string;
    tipoDev = [
        { valor: 'DES', nome: 'Desconto' },
        { valor: 'DEV', nome: 'Devolução' },
    ];
    vlrDesc: number = 0;
    percDesc: number = 0;

    sinistro = false;

    fechaBottomSheet(event: string) {
        if (this.data.tipo == 'aprovaDevolucao') {
            if (this.tipoAprovDev) {
                if (event === 'A') {
                    this.bottomSheetRef.dismiss({
                        tipo: event,
                        obs: this.obsAprovReprov,
                        tipoAprovDev: this.tipoAprovDev,
                        vlrDesc: this.alteraDesc(),
                    });
                } else if (event === 'C') {
                    this.bottomSheetRef.dismiss();
                }
            } else if (event === 'C') {
                this.bottomSheetRef.dismiss();
            } else {
                this.portalServiceService.openSnackbar('Selecione um tipo de devolução!');
            }
        } else if (this.data.tipo == 'reprovaDevolucao') {
            if (this.motivoRecusa) {
                if (event === 'R') {
                    this.bottomSheetRef.dismiss({ tipo: event, obs: this.obsAprovReprov, motivo: this.motivoRecusa });
                } else if (event === 'C') {
                    this.bottomSheetRef.dismiss();
                }
            } else if (event === 'C') {
                this.bottomSheetRef.dismiss();
            } else {
                this.portalServiceService.openSnackbar('Preencha um motivo para a reprovação!');
            }
        } else if (this.data.tipo == 'cancelaDevolucao') {
            if (this.motivoCancela) {
                if (event === 'CAN') {
                    let acaoDev = !this.sinistro ? 'FC' : 'FS';
                    this.bottomSheetRef.dismiss({ tipo: event, motivoCancel: this.motivoCancela, acao: acaoDev });
                } else if (event === 'C') {
                    this.bottomSheetRef.dismiss();
                }
            } else if (event === 'C') {
                this.bottomSheetRef.dismiss();
            } else {
                this.portalServiceService.openSnackbar('Preencha um motivo para o cancelamento!');
            }
        }
    }

    alteraDesc() {
        if (this.percDesc > 100) {
            this.percDesc = 100;
        }
        return this.vlrDesc * (this.percDesc / 100);
    }
}
