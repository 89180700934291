import { Component, Inject, OnInit, ViewContainerRef, ViewChild, AfterViewInit } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { PortalJpaService } from 'src/app/services/portal-jpa.service';
import { Overlay } from '@angular/cdk/overlay';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Rastreio } from '../../local-interfaces/rastreio-pedido-interface';
import { MatStepper } from '@angular/material';

@Component({
    selector: 'app-bottom-sheet-rastreio',
    templateUrl: './bottom-sheet-rastreio.component.html',
    styleUrls: ['./bottom-sheet-rastreio.component.scss'],
    providers: [
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: { displayDefaultIndicatorType: false },
        },
    ],
})
export class BottomSheetRastreioComponent implements OnInit {
    constructor(
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
        private bottomSheetRef: MatBottomSheetRef<BottomSheetRastreioComponent>,
        public portalJpaService: PortalJpaService,
        public overlay: Overlay,
        public viewContainerRef: ViewContainerRef
    ) {}

    rastreios: any;

    @ViewChild('stepper', { static: false }) stepper: MatStepper;

    listaColunas = ['INFOINI', 'INFOFIN'];

    ngOnInit() {
        this.rastreios = this.data.rastreio.slice(0).reverse();
    }

    close() {
        this.bottomSheetRef.dismiss();
    }

    verificaStateRastreio(rastreio: any): string {
        return rastreio.OCORRENCIA.includes('MERCADORIA ENTREGUE') ? 'entregue' : 'transito';
    }
}
