import { Pagina } from './../global-interfaces/pagina-interface';
import { PedidoConsulta } from './local-interfaces/pedido-consulta-interface';
import { FormControl, Validators } from '@angular/forms';
import { Component, OnInit, ViewContainerRef, ChangeDetectorRef, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { Overlay } from '@angular/cdk/overlay';
import { Router } from '@angular/router';

import { AutenticacaoService } from '../services/autenticacao.service';

import { BottomSheetComponent } from '../global-components/bottom-sheet/bottom-sheet.component';
import { DialogComponent } from '../global-components/dialog/dialog.component';
import { SpinnerComponent } from '../global-components/spinner/spinner.component';
import { PedidosPesquisa, Paginas, RangePaginas } from '../global-interfaces/pedidos-pesquisa-interface';
import { MatSnackBar } from '@angular/material';
import { BottomSheetPedidosComponent } from './local-components/bottom-sheet-pedidos/bottom-sheet-pedidos.component';
import { PortalJpaService } from '../services/portal-jpa.service';
import { BottomSheetRastreioComponent } from './local-components/bottom-sheet-rastreio/bottom-sheet-rastreio.component';
import { MediaMatcher } from '@angular/cdk/layout';
import { Parceiro } from '../global-interfaces/parceiro-interface';

@Component({
    selector: 'app-pedidos',
    templateUrl: './pedidos.component.html',
    styleUrls: ['./pedidos.component.scss'],
})
export class PedidosComponent implements OnInit, OnDestroy {
    constructor(
        private auth: AutenticacaoService,
        private bottomSheet: MatBottomSheet,
        private portalJpaService: PortalJpaService,
        private router: Router,
        public dialog: MatDialog,
        public overlay: Overlay,
        public viewContainerRef: ViewContainerRef,
        private snackBar: MatSnackBar,
        changeDetectorRef: ChangeDetectorRef,
        mediaMatcher: MediaMatcher
    ) {
        this.auth.autenticar();

        this.paginas = {
            numeroInicial: RangePaginas.numeroInicial,
            numeroRegistros: RangePaginas.numeroRegistros,
            numeroFinal: RangePaginas.numeroRegistros,
        };

        this.mediaSS = mediaMatcher.matchMedia('(max-width: 800px)');
        this.mobileQueryListener = () => changeDetectorRef.detectChanges();
        this.mediaSS.addEventListener<'change'>('change', this.mobileQueryListener);
    }

    ngOnInit() {
        this.filtroSelecionado.setValue(this.tipoFiltro[0].valor);
        this.busca.setValue(this.tipoQtdDias[0].valor);

        const ultimoDiaDoMes = new Date();
        ultimoDiaDoMes.setMonth(ultimoDiaDoMes.getMonth() - 1);

        this.periodoIni.setValue(ultimoDiaDoMes);
        this.periodoFin.setValue(new Date());

        this.refresh();
    }

    ngOnDestroy(): void {
        this.mediaSS.removeEventListener<'change'>('change', this.mobileQueryListener);
    }

    mediaSS: MediaQueryList;
    private mobileQueryListener: () => void;

    pedidosPaginados = [];

    pedidos: PedidoConsulta[];
    paginas: Paginas;
    busca = new FormControl('', Validators.required);
    pdfNotaFiscal: any;

    patternData = date => {
        let mount = {
            JAN: '01',
            FEB: '02',
            MAR: '03',
            APR: '04',
            MAY: '05',
            JUN: '06',
            JUL: '07',
            AUG: '08',
            SEP: '09',
            OCT: '10',
            NOV: '11',
            DEC: '12',
        };
        let splitDate = date.split(' ')[0].split('-');

        let formatedDate = `${splitDate[0]}/${mount[splitDate[1]]}/${splitDate[2]}`;
        return formatedDate;
    };

    tipoFiltro = [
        { tipo: 'Período', valor: 'DT' },
        { tipo: 'Pedido', valor: 'P' },
        { tipo: 'Cliente', valor: 'C' },
        { tipo: 'Projeto', valor: 'PR' },
        { tipo: 'Status', valor: 'S' },
    ];

    tipoStatus = [
        { CODSTATUS: 1, STATUS: 'Proposta' },
        { CODSTATUS: 2, STATUS: 'Proposta Aprovada' },
        { CODSTATUS: 3, STATUS: 'Confirmado Comercial' },
        { CODSTATUS: 31, STATUS: 'Confirmado Financeiro' },
        { CODSTATUS: 32, STATUS: 'Aguardando Antecipacao' },
        { CODSTATUS: 4, STATUS: 'Liberado Separar' },
        { CODSTATUS: 41, STATUS: 'Em Separação' },
        { CODSTATUS: 42, STATUS: 'Liberado Conferir' },
        { CODSTATUS: 43, STATUS: 'Em Conferencia' },
        { CODSTATUS: 5, STATUS: 'Separado' },
        { CODSTATUS: 51, STATUS: 'Aguardando Pagamento' },
        { CODSTATUS: 52, STATUS: 'Liberado Faturar' },
        { CODSTATUS: 6, STATUS: 'Faturado' },
        { CODSTATUS: 7, STATUS: 'Cancelado' },
    ];

    tipoQtdDias = [
        { valor: 7, mascara: 'Últimos 7 dias' },
        { valor: 15, mascara: 'Últimos 15 dias' },
        { valor: 30, mascara: 'Últimos 30 dias' },
        { valor: 60, mascara: 'Últimos 60 dias' },
        { valor: 90, mascara: 'Últimos 90 dias' },
    ];

    filtroSelecionado = new FormControl('');

    periodoIni = new FormControl(new Date());
    periodoFin = new FormControl(new Date());

    paginaCorrente = 1;

    listaColunas = ['ACOES', 'EMPRESA', 'PEDIDO', 'CLIENTE', 'DATA', 'TIPO', 'STATUS'];

    parceiroCliente: Parceiro = this.getParceiroVazio();

    paginar(event: Pagina) {
        this.pedidos = this.pedidosPaginados.slice(event.startIndex, event.endIndex + 1);
    }

    refresh() {
        if (this.filtroSelecionado.value == 'C') {
            this.busca.setValue(this.parceiroCliente.RAZAOSOCIAL);
        }

        if (this.busca.invalid) return;

        const periodoIni = this.periodoIni.value.toLocaleDateString();
        const periodoFini = this.periodoFin.value.toLocaleDateString();

        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

        this.portalJpaService.getPedidosPesquisa(this.busca.value, this.filtroSelecionado.value, periodoIni, periodoFini).subscribe(
            (res: any) => {
                if (res.serviceResponse.toUpperCase() === 'OK'.toUpperCase()) {
                    res.response.map((pedido: PedidoConsulta) => {
                        pedido.DTNEG = new Date(String(pedido.DTNEG).replace(' ', 'T'));

                        if (pedido.RAZAOSOCIAL.length >= 30 && this.mediaSS.matches) {
                            pedido.RAZAOSOCIAL = pedido.RAZAOSOCIAL.slice(0, 30) + '...';
                        }
                    });

                    this.pedidosPaginados = res.response;
                    this.pedidos = this.pedidosPaginados.slice(0, 30);
                    this.paginaCorrente = 1;
                } else {
                    const param = 'carregarPedidos';
                    const resp = res.response;

                    this.bottomSheet.open(BottomSheetComponent, {
                        data: { param, resp },
                        panelClass: 'bottom-sheet',
                    });
                }

                overlayRef.dispose();
            },
            error => {
                const param = 'carregarPedidos';

                this.bottomSheet.open(BottomSheetComponent, {
                    data: { param, error },
                    panelClass: 'bottom-sheet',
                });

                // Removendo o spinner da tela
                overlayRef.dispose();
            }
        );
    }

    selecionarTipo() {
        this.busca.setValue('');
        this.clearClienteAutocomplete();
    }

    imprimir(pedido: PedidoConsulta) {
        this.portalJpaService.imprimirPedido(pedido.PROPOSTA);
    }

    mostraMotivoCancelamento(pedido: PedidoConsulta) {
        if (pedido.STATUS === 'CANCELADO') {
            this.openDialog('motivoCancelamento', pedido).subscribe();
        }
    }

    openDialog(tipo: string, params?: any) {
        const dialogRef = this.dialog.open(DialogComponent, {
            width: '300px',
            data: { tipo, params },
        });

        return dialogRef.afterClosed();
    }

    novoPedido(pedido) {
        this.router.navigateByUrl('/criar-pedido', { state: { copiarPedido: JSON.stringify({ NUNOTA: pedido.PROPOSTA }) } });
    }

    async getBottomSheetRes(): Promise<string> {
        return new Promise((resolve, reject) => {
            const bt: MatBottomSheetRef = this.bottomSheet.open(BottomSheetPedidosComponent);
            bt.afterDismissed().subscribe(res => {
                let ret = 'C';
                if (res) {
                    ret = res;
                }
                resolve(ret);
            });
        });
    }

    async imprimirNf(pedido: PedidosPesquisa) {
        if (pedido.STATUS != 'FATURADO') {
            this.openSnackbar('Para impressão de Nota Fiscal, pedido deve estar FATURADO!');
            return false;
        }

        let remessa = 'N';

        if (pedido.AD_REMESSA === 'S') {
            remessa = await this.getBottomSheetRes();
        }

        if (remessa === 'C') {
            return false;
        }

        // Mostrando o spinner na tela
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

        this.portalJpaService.getPdfNotaFiscal({ pedido: pedido, remessa: remessa }, 'NUNOTAPROPOSTA').subscribe(
            (res: any) => {
                if (res.serviceResponse === 'OK') {
                    this.pdfNotaFiscal = res.pdfNotaFiscal;

                    const byteCharacters = atob(this.pdfNotaFiscal.PDF);
                    const byteNumbers = new Array(new TextEncoder().encode(this.pdfNotaFiscal.PDF).length);

                    for (let i = 0; i < new TextEncoder().encode(this.pdfNotaFiscal.PDF).length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }

                    const byteArray = new Uint8Array(byteNumbers);
                    const blob = new Blob([byteArray], { type: 'application/pdf' });
                    const url = URL.createObjectURL(blob);

                    window.open(url, '_blank');
                } else {
                    this.openSnackbar('Falha ao carregar anexos. Tente novamente!');
                }

                overlayRef.dispose();
            },
            erro => {
                this.openSnackbar('Falha ao carregar anexos!');
                console.log(erro);

                overlayRef.dispose();
            }
        );
    }

    openSnackbar(message: string) {
        this.snackBar.open(message, 'Fechar', {
            horizontalPosition: 'end',
            verticalPosition: 'top',
            duration: 5000,
        });
    }

    rastreio(pedido: PedidoConsulta) {
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

        this.portalJpaService.getRastreioPedido(pedido.PROPOSTA).subscribe(
            (res: any) => {
                if (res.serviceResponse === 'OK') {
                    if (res.response.length > 0) {
                        const bt: MatBottomSheetRef = this.bottomSheet.open(BottomSheetRastreioComponent, {
                            data: { rastreio: res.response, texto: 'Crastreio' },
                        });
                    } else {
                        const bt: MatBottomSheetRef = this.bottomSheet.open(BottomSheetRastreioComponent, {
                            data: { rastreio: [], texto: 'Srastreio' },
                        });
                    }
                }

                overlayRef.dispose();
            },
            erro => {
                overlayRef.dispose();
                console.log(erro);
            }
        );
    }

    classeStatus(status: string | undefined) {
        let classe = '';

        switch (status) {
            case 'PROPOSTA':
                classe = 'proposta';
                break;

            case 'CONFIRMADO COMERCIAL':
                classe = 'confirmadoComercial';
                break;

            case 'CONFIRMADO FINANCEIRO':
                classe = 'confirmadoFinanceiro';
                break;

            case 'PROPOSTA APROVADA':
                classe = 'propostaAprovada';
                break;

            case 'LIBERADO SEPARAR':
                classe = 'liberadoSeparar';
                break;

            case 'EM SEPARACAO':
                classe = 'emSeparacao';
                break;

            case 'LIBERADO CONFERIR':
                classe = 'liberadoConferir';
                break;

            case 'EM CONFERENCIA':
                classe = 'emConferencia';
                break;

            case 'SEPARADO':
                classe = 'separado';
                break;

            case 'LIBERADO FATURAR':
                classe = 'liberadoFaturar';
                break;

            case 'FATURADO':
                classe = 'faturado';
                break;

            case 'CANCELADO':
                classe = 'cancelado';
                break;

            case 'AGUARDANDO CHEGADA':
                classe = 'aguardandoChegada';
                break;

            case 'AGUARDANDO ANTECIPACAO':
                classe = 'aguardandoAntecipacao';
                break;

            case 'AGUARDANDO PAGAMENTO':
                classe = 'aguardandoPagamento';
                break;

            default:
                break;
        }

        return classe;
    }

    private getParceiroVazio(): Parceiro {
        return {
            CODPARC: 0,
            NOMEPARC: '',
            RAZAOSOCIAL: '',
            CODVEND: 0,
            TIPO: '',
            NOMEEND: '',
            NUMEND: 0,
            COMPLEMENTO: '',
            NOMEBAI: '',
            NOMECID: '',
            CEP: '',
            UF: '',
            PAISEND: '',
            TELEFONE: '',
            EMAIL: '',
            IDENTINSCESTAD: '',
            CGCCPF: '',
            ATIVO: '',
            LIMCRED: 0,
            CLIENTE: '',
            FORNECEDOR: '',
            VENDEDOR: '',
            TRANSPORTADORA: '',
            BLOQUEAR: '',
            ADSITUACAOPORTAL: '',
        };
    }

    emitirClienteSelecionado(cliente: Parceiro) {
        this.parceiroCliente = { ...cliente };
    }

    clearClienteAutocomplete() {
        this.parceiroCliente = this.getParceiroVazio();
    }
}
