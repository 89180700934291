import { Component, ElementRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { AlterarSenha } from '../local-interfaces/alterar-senha-interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Md5 } from 'ts-md5/dist/md5';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BottomSheetComponent } from 'src/app/global-components/bottom-sheet/bottom-sheet.component';
import { OverlayConfig, Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { SpinnerComponent } from 'src/app/global-components/spinner/spinner.component';
import { PortalServiceService } from 'src/app/services/portal-service.service';
import { ValidacoesSenha } from '../local-interfaces/valicacoes-senha-interface';
import { AppComponent } from 'src/app/app.component';
import { AutenticacaoService } from 'src/app/services/autenticacao.service';

@Component({
    selector: 'app-alterar-senha',
    templateUrl: './alterar-senha.component.html',
    styleUrls: ['./alterar-senha.component.scss'],
})
export class AlterarSenhaComponent implements OnInit {
    alterarSenha: AlterarSenha;

    validacoes: ValidacoesSenha;

    constructor(
        private snackBar: MatSnackBar,
        private bottomSheet: MatBottomSheet,
        public overlay: Overlay,
        public viewContainerRef: ViewContainerRef,
        private portalService: PortalServiceService,
        private appComponent: AppComponent,
        private auth: AutenticacaoService
    ) {
        this.limparCampos();
    }

    ngOnInit() {}

    @ViewChild('passwordAtual', { static: true }) passwordAtual: ElementRef;
    @ViewChild('passwordNova', { static: true }) passwordNova: ElementRef;
    @ViewChild('passwordConfirma', { static: true }) passwordConfirma: ElementRef;

    changeType(id: number) {
        let inputEl;

        if (id === 1) {
            inputEl = this.passwordAtual.nativeElement;
        }

        if (id === 2) {
            inputEl = this.passwordNova.nativeElement;
        }

        if (id === 3) {
            inputEl = this.passwordConfirma.nativeElement;
        }

        inputEl.type = inputEl.type === 'password' ? 'text' : 'password';
    }

    validaInput() {
        // Validar se a senha nova possui no mínimo seis dígitos
        this.validacoes.seisDigitos = this.alterarSenha.senhaNova.length < 6 ? false : true;

        // Validar se a senha nova possui no mínimo uma letra maiúscula
        this.validacoes.maiusculas = this.alterarSenha.senhaNova.match(/[A-Z]+/g) === null ? false : true;

        // Validar se a senha nova possui no mínimo uma letra minúscula
        this.validacoes.minusculas = this.alterarSenha.senhaNova.match(/[a-z]+/g) === null ? false : true;

        // Validar se a senha nova possui no mínimo um número
        this.validacoes.numeros = this.alterarSenha.senhaNova.match(/[0-9]+/g) === null ? false : true;

        // Validar se a senha nova possui no mínimo um caractere especial
        this.validacoes.caracteres = this.alterarSenha.senhaNova.match(/[^a-zA-Z0-9]+/g) === null ? false : true;

        // Validar se a confirmação de senha é igual à nova senha
        this.validacoes.confirmaSenha = [
            this.alterarSenha.confirmaSenhaNova === this.alterarSenha.senhaNova,
            this.alterarSenha.confirmaSenhaNova !== '',
            this.alterarSenha.senhaNova !== '',
        ].includes(false)
            ? false
            : true;
    }

    criarNovaSenha() {
        // let { senhaAtual, senhaNova, confirmaSenhaNova } = this.alterarSenha;
        const senhaAtual = this.alterarSenha.senhaAtual.trim();
        const senhaNova = this.alterarSenha.senhaNova.trim();
        const confirmaSenhaNova = this.alterarSenha.confirmaSenhaNova.trim();

        const condicionais = [senhaAtual === '', senhaNova === '', confirmaSenhaNova === ''];

        // Caso alguns dos campos estejam vazios
        if (condicionais.includes(true)) {
            this.openSnackbar('Preencha todos os campos!');
            return false;
        }

        // Caso a senha nova e a confirmação da senha nova não sejam iguais
        if (senhaNova !== confirmaSenhaNova) {
            this.openSnackbar('Senha nova e a confirmação de senha nova não conferem!');
            return false;
        }

        // Mostrando o spinner na tela
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

        const nomeAbrev = this.auth.getUserName();

        const senhaAtualMD5 = new Md5();
        const senhaNovaMD5 = new Md5();

        const senhaAtualCript = senhaAtualMD5.appendStr(senhaAtual).end().toString();
        const senhaNovaCript = senhaNovaMD5.appendStr(senhaNova).end().toString();

        this.portalService.trocarSenha(nomeAbrev, senhaAtualCript, senhaNovaCript).subscribe(
            (res: any) => {
                if (res.erro === 'OK') {
                    this.openSnackbar('Senha alterada com sucesso!');
                    this.limparCampos();
                    this.appComponent.logout();
                } else {
                    this.openSnackbar(`Erro! ${res.msg_erro}`);
                }

                // Removendo o spinner da tela
                overlayRef.dispose();
            },

            erro => {
                console.log(erro);

                // Removendo o spinner da tela
                overlayRef.dispose();

                this.bottomSheet.open(BottomSheetComponent, {
                    data: {
                        param: 'alterar-senha',
                        mensagem: erro.message,
                    },
                    panelClass: 'bottom-sheet',
                });
            }
        );
    }

    limparCampos() {
        this.alterarSenha = {
            senhaAtual: '',
            senhaNova: '',
            confirmaSenhaNova: '',
        };

        this.validacoes = {
            seisDigitos: false,
            minusculas: false,
            maiusculas: false,
            numeros: false,
            caracteres: false,
            confirmaSenha: false,
        };
    }

    openSnackbar(message: string) {
        this.snackBar.open(message, 'Fechar', {
            horizontalPosition: 'end',
            verticalPosition: 'top',
            duration: 5000,
        });
    }
}
