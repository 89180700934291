import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-paginacao-local',
    templateUrl: './paginacao-local.component.html',
    styleUrls: ['./paginacao-local.component.scss'],
})
export class PaginacaoLocalComponent {
    @Input() totalItems = 0;
    @Input() currentPage = 1;

    @Output() currentPageChange = new EventEmitter<number>();

    @Output() onPaginate = new EventEmitter<{}>();

    constructor() {}

    onClick(typePage: number) {
        if (typePage == 0 && this.currentPage > 1) {
            this.currentPage--;
        } else if (typePage == 1 && this.currentPage < this.getEndPage(this.totalItems)) {
            this.currentPage++;
        } else if (typePage == 2) {
            this.currentPage = 1;
        } else if (typePage == 3) {
            this.currentPage = this.getEndPage(this.totalItems);
        }

        this.currentPageChange.emit(this.currentPage);
        this.onPaginate.emit(this.getPagina(this.totalItems, this.currentPage));
    }

    getPagina(totalItems: number, currentPage: number) {
        // calculate total pages
        const totalPages = Math.ceil(totalItems / 30);

        // ensure current page isn't out of range
        if (currentPage < 1) {
            currentPage = 1;
        } else if (currentPage > totalPages) {
            currentPage = totalPages;
        }

        const startPage = 1;
        let endPage = 0;

        endPage = totalPages;

        // calculate start and end item indexes
        const startIndex = (currentPage - 1) * 30;
        const endIndex = Math.min(startIndex + 30 - 1, totalItems - 1);

        // create an array of pages to ng-repeat in the pager control
        const pages = Array.from(Array(endPage + 1 - startPage).keys()).map(i => {
            let result;
            result = -1;

            // i se inicia como 0, por isso o i + 1
            if (currentPage == 1 && i <= 2) {
                result = startPage + i;
            } else if (i + 1 == currentPage || i + 1 == currentPage + 1 || i + 1 == currentPage - 1) {
                result = startPage + i;
            } else if (currentPage == endPage && (i + 1 == currentPage - 1 || i + 1 == currentPage - 2)) {
                result = startPage + i;
            }

            return result;
        });

        // return object with all pager properties required by the view
        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: 30,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages,
        };
    }

    getEndPage(totalItems: number) {
        return Math.ceil(totalItems / 30);
    }
}
