import { ControleEmbarqueResumidoComponent } from './controle-embarque-resumido/controle-embarque-resumido.component';
import { PedidosLiberadoSepararComponent } from './pedidos-liberado-separar/pedidos-liberado-separar.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { ApoioOperacionalComponent } from './apoio-operacional/apoio-operacional.component';
import { AprovacaoFinanceiraComponent } from './aprovacao-financeira/aprovacao-financeira.component';
import { BloqueioClienteComponent } from './bloqueio-cliente/bloqueio-cliente.component';
import { BooksComponent } from './books/books.component';
import { CadastrarClienteComponent } from './cadastrar-cliente/cadastrar-cliente.component';
import { ComissoesComponent } from './comissoes/comissoes.component';
import { ConsultaCadastroClientesComponent } from './consulta-cadastro-clientes/consulta-cadastro-clientes.component';
import { ConsultaClientesComponent } from './consulta-clientes/consulta-clientes.component';
import { ConsultaProdutosComponent } from './consulta-produtos/consulta-produtos.component';
import { ControleEmbarqueComponent } from './controle-embarque/controle-embarque.component';
import { CriarPedidoComponent } from './criar-pedido/criar-pedido.component';
import { DevolucaoAprovacaoComponent } from './devolucao-aprovacao/devolucao-aprovacao.component';
import { DevolucaoCadastroComponent } from './devolucao-cadastro/devolucao-cadastro.component';
import { DevolucaoConsultaComponent } from './devolucao-consulta/devolucao-consulta.component';
import { FecharPedidoComponent } from './fechar-pedido/fechar-pedido.component';
import { GaleriaImagensComponent } from './galeria-imagens/galeria-imagens.component';
import { GerenciarContaComponent } from './gerenciar-conta/gerenciar-conta.component';
import { GraficosComponent } from './graficos/graficos.component';
import { HomeComponent } from './home/home.component';
import { LiberacoesComponent } from './liberacoes/liberacoes.component';
import { EsqueciSenhaComponent } from './login/esqueci-senha/esqueci-senha.component';
import { ForcedAlterarSenhaComponent } from './login/forced-alterar-senha/forced-alterar-senha.component';
import { LoginComponent } from './login/login.component';
import { PedidosFinanceiroComponent } from './pedidos-financeiro/pedidos-financeiro.component';
import { PedidosLegadoComponent } from './pedidos-legado/pedidos-legado.component';
import { PedidosOperacionalEsComponent } from './pedidos-operacional-es/pedidos-operacional-es.component';
import { PedidosOperacionalFilialEsComponent } from './pedidos-operacional-filial-es/pedidos-operacional-filial-es.component';
import { PedidosOperacionalScComponent } from './pedidos-operacional-sc/pedidos-operacional-sc.component';
import { PedidosOperacionalComponent } from './pedidos-operacional/pedidos-operacional.component';
import { PedidosComponent } from './pedidos/pedidos.component';
import { ProgramacaoOperacionalComponent } from './programacao-operacional/programacao-operacional.component';
import { QrCodeComponent } from './qr-code/qr-code.component';
import { RelatoriosComponent } from './relatorios/relatorios.component';
import { SegundaViaBoletoComponentSNK } from './segunda-via-boleto-snk/segunda-via-boleto-snk.component';
import { SegundaViaBoletoComponent } from './segunda-via-boleto/segunda-via-boleto.component';
import { RouteGuardService } from './services/route-guard.service';
import { TabelaDePrecoComponent } from './tabela-de-preco/tabela-de-preco.component';
import { ConsultaSerasaComponent } from './consulta-serasa/consulta-serasa.component';
import { ControleEmbarqueMoveleiroComponent } from './controle-embarque-moveleiro/controle-embarque-moveleiro.component';
import { ItensProjecaoChegadaComponent } from './itens-projecao-chegada/itens-projecao-chegada.component';
import { DefinirSeparadorComponent } from './definir-separador/definir-separador.component';
import { ProgramacaoOperacionalFilialEsComponent } from './programacao-operacional-filial-es/programacao-operacional-filial-es.component';
import { ProgramacaoOperacionalScComponent } from './programacao-operacional-sc/programacao-operacional-sc.component';
import { PositivacaoClientesComponent } from './positivacao-clientes/positivacao-clientes.component';
import { PedidosFaturarEsComponent } from './pedidos-faturar-es/pedidos-faturar-es.component';
import { GraficoChamadosDiarioComponent } from './chamados/graficos/grafico-chamados-diario/grafico-chamados-diario.component';
import { ItensProjecaoChegadaMoveleiroComponent } from './itens-projecao-chegada-moveleiro/itens-projecao-chegada-moveleiro.component';
import { AgendamentoReuniaoComponent } from './agendamento-reuniao/agendamento-reuniao.component';
import { NovoAgendamentoComponent } from './agendamento-reuniao/novo-agendamento/novo-agendamento.component';
import { PedidosFinanceiroCobrancaComponent } from './pedidos-financeiro-cobranca/pedidos-financeiro-cobranca.component';
import { GerenciamentoBooksComponent } from './gerenciamento-books/gerenciamento-books.component';
import { ControleEmbarqueFinalizadoComponent } from './controle-embarque-finalizado/controle-embarque-finalizado.component';
import { PendenciasCoordenador } from './pendencias-coordenador/pendencias-coordenador.component';

const routes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'alterar-senha', component: ForcedAlterarSenhaComponent },
    { path: 'esqueci-senha', component: EsqueciSenhaComponent },
    { path: '', component: HomeComponent, canActivate: [RouteGuardService] },
    { path: 'pedidos', component: PedidosComponent, canActivate: [RouteGuardService] },
    { path: 'pedidos-operacional', component: PedidosOperacionalComponent, canActivate: [RouteGuardService] },
    { path: 'programacao-operacional', component: ProgramacaoOperacionalComponent, canActivate: [RouteGuardService] },
    { path: 'criar-pedido', component: CriarPedidoComponent, canActivate: [RouteGuardService] },
    { path: 'galeria-imagens', component: GaleriaImagensComponent, canActivate: [RouteGuardService] },
    { path: 'propostas', component: LiberacoesComponent, canActivate: [RouteGuardService] },
    { path: 'relatorios', component: RelatoriosComponent, canActivate: [RouteGuardService] },
    { path: 'cadastrar-cliente', component: CadastrarClienteComponent, canActivate: [RouteGuardService] },
    { path: 'gerenciar-conta', component: GerenciarContaComponent, canActivate: [RouteGuardService] },
    { path: 'pedidos-legado', component: PedidosLegadoComponent, canActivate: [RouteGuardService] },
    { path: 'segunda-via-boleto', component: SegundaViaBoletoComponent, canActivate: [RouteGuardService] },
    { path: 'segunda-via-boleto-snk', component: SegundaViaBoletoComponentSNK, canActivate: [RouteGuardService] },
    { path: 'consulta-clientes', component: ConsultaClientesComponent, canActivate: [RouteGuardService] },
    { path: 'consulta-produtos', component: ConsultaProdutosComponent, canActivate: [RouteGuardService] },
    { path: 'aprovacao-financeira', component: AprovacaoFinanceiraComponent, canActivate: [RouteGuardService] },
    { path: 'tabela-de-preco', component: TabelaDePrecoComponent, canActivate: [RouteGuardService] },
    { path: 'fechar-pedido', component: FecharPedidoComponent, canActivate: [RouteGuardService] },
    { path: 'galeria-books', component: BooksComponent, canActivate: [RouteGuardService] },
    { path: 'bloqueio-cliente', component: BloqueioClienteComponent, canActivate: [RouteGuardService] },
    { path: 'estatisticas', component: GraficosComponent, canActivate: [RouteGuardService] },
    { path: 'devolucao-cadastro', component: DevolucaoCadastroComponent, canActivate: [RouteGuardService] },
    { path: 'devolucao-consulta', component: DevolucaoConsultaComponent, canActivate: [RouteGuardService] },
    { path: 'devolucao-aprovacao', component: DevolucaoAprovacaoComponent, canActivate: [RouteGuardService] },
    { path: 'qr-code', component: QrCodeComponent, canActivate: [RouteGuardService] },
    { path: 'consulta-cadastro-clientes', component: ConsultaCadastroClientesComponent, canActivate: [RouteGuardService] },
    { path: 'pedidos-financeiro', component: PedidosFinanceiroComponent, canActivate: [RouteGuardService] },
    { path: 'controle-embarque', component: ControleEmbarqueComponent, canActivate: [RouteGuardService] },
    { path: 'pedidos-operacional-sc', component: PedidosOperacionalScComponent, canActivate: [RouteGuardService] },
    { path: 'pedidos-operacional-es', component: PedidosOperacionalEsComponent, canActivate: [RouteGuardService] },
    { path: 'pedidos-operacional-filial-es', component: PedidosOperacionalFilialEsComponent, canActivate: [RouteGuardService] },
    { path: 'c', component: ComissoesComponent, canActivate: [RouteGuardService] },
    { path: 'apoio-operacional', component: ApoioOperacionalComponent, canActivate: [RouteGuardService] },
    { path: 'consulta-serasa', component: ConsultaSerasaComponent, canActivate: [RouteGuardService] },
    { path: 'pedidos-liberado-separar', component: PedidosLiberadoSepararComponent, canActivate: [RouteGuardService] },
    { path: 'controle-embarque-resumido', component: ControleEmbarqueResumidoComponent, canActivate: [RouteGuardService] },
    { path: 'controle-embarque-moveleiro', component: ControleEmbarqueMoveleiroComponent, canActivate: [RouteGuardService] },
    { path: 'itens-projecao-chegada', component: ItensProjecaoChegadaComponent, canActivate: [RouteGuardService] },
    { path: 'definir-separador', component: DefinirSeparadorComponent, canActivate: [RouteGuardService] },
    { path: 'programacao-operacional-filial-es', component: ProgramacaoOperacionalFilialEsComponent, canActivate: [RouteGuardService] },
    { path: 'programacao-operacional-sc', component: ProgramacaoOperacionalScComponent, canActivate: [RouteGuardService] },
    { path: 'positivacao-clientes', component: PositivacaoClientesComponent, canActivate: [RouteGuardService] },
    { path: 'pedidos-faturamento', component: PedidosFaturarEsComponent, canActivate: [RouteGuardService] },
    { path: 'chamados', component: GraficoChamadosDiarioComponent, canActivate: [RouteGuardService] },
    { path: 'itens-projecao-chegada-moveleiro', component: ItensProjecaoChegadaMoveleiroComponent, canActivate: [RouteGuardService] },
    { path: 'agendamentos', component: AgendamentoReuniaoComponent, canActivate: [RouteGuardService] },
    { path: 'agendamentos/novo-agendamento', component: NovoAgendamentoComponent, canActivate: [RouteGuardService] },
    { path: 'pedidos-financeiro-cobranca', component: PedidosFinanceiroCobrancaComponent, canActivate: [RouteGuardService] },
    { path: 'gerenciamento-books', component: GerenciamentoBooksComponent, canActivate: [RouteGuardService] },
    { path: 'controle-embarque-finalizado', component: ControleEmbarqueFinalizadoComponent, canActivate: [RouteGuardService] },
    { path: 'pendencias-coordenador', component: PendenciasCoordenador, canActivate: [RouteGuardService] },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule],
    providers: [RouteGuardService],
})
export class AppRoutingModule {}
