import { Component, OnInit } from '@angular/core';

import { PortalJpaService } from 'src/app/services/portal-jpa.service';
import { LeituraCodigoBarrasPedido } from 'src/app/global-interfaces/leitura-codigo-barras-pedido-interface';

@Component({
    selector: 'app-imprimir-romaneio',
    templateUrl: './imprimir-romaneio.component.html',
    styleUrls: ['./imprimir-romaneio.component.scss']
})
export class ImprimirRomaneioComponent implements OnInit {

    // Código de barras
    codigoDeBarras: number;
    // Atributo para popular a tabela de pedidos que já foram enviados para aprovação
    pedidos: LeituraCodigoBarrasPedido[];

    constructor(
        private portalJpaService: PortalJpaService,
    ) {
        this.inicializarVariaveis();
    }

    ngOnInit() {
    }

    inicializarVariaveis() {
        this.pedidos = [];
        this.codigoDeBarras = null;
    }

    imprimirRomaneio() {
        if (this.codigoDeBarras.toString().length === 14) {

            // Separar o número do pedido do código do cliente
            const nuNota = parseInt(this.codigoDeBarras.toString().slice(0, 8), 10);
            const codParc = parseInt(this.codigoDeBarras.toString().slice(8), 10);
            // Zerar o input
            this.codigoDeBarras = null;

            // Fazer um loop nos pedidos lidos e verificar se este pedido digitado já foi lido anteriormente
            let indicePedido = this.pedidos.findIndex(pedido => pedido.NUNOTA === nuNota);

            // Se o pedido já foi lido...
            if (indicePedido !== -1) {
                this.pedidos.push({
                    NUNOTA: nuNota,
                    CODPARC: codParc,
                    MENSAGEM: 'Pedido já lido',
                    RAZAOSOCIAL: this.pedidos[indicePedido].RAZAOSOCIAL,
                    STATUS: 'ERROR'
                });
                return false;
            }

            indicePedido = this.pedidos.length;
            this.pedidos.push({
                NUNOTA: nuNota,
                CODPARC: codParc
            });

            // Fazer a chamada para fechar o pedido
            this.portalJpaService.apiPostImprimirRomaneioFilaExec(nuNota).subscribe(
                (res: any) => {
                    this.pedidos[indicePedido].STATUS = res.serviceResponse;
                    this.pedidos[indicePedido].MENSAGEM = res.message;
                    if (res.pedido) {
                        this.pedidos[indicePedido].CODPARC = res.pedido.codParc;
                        this.pedidos[indicePedido].RAZAOSOCIAL = res.pedido.razaoSocial;
                    }
                }, (erro: any) => {
                    this.pedidos[indicePedido].STATUS = erro.serviceResponse;
                    this.pedidos[indicePedido].MENSAGEM = erro.message;
                    console.log(erro);
                }
            );
        }
    }

}
