import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatBadgeModule, MatButtonModule, MatDividerModule, MatIconModule, MatMenuModule, MatProgressSpinnerModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';

import { BottomSheetNotificacaoComponent } from './bottom-sheet-notificacao/bottom-sheet-notificacao.component';
import { BadgeNotificacaoComponent } from './badge-notificacao/badge-notificacao.component';
import { BsnDefaultComponent } from './bottom-sheet-notificacao/bsn-default/bsn-default.component';
import { BsnReenviarPedidoComponent } from './bottom-sheet-notificacao/bsn-reenviar-pedido/bsn-reenviar-pedido.component';

@NgModule({
    declarations: [
        BottomSheetNotificacaoComponent,
        BadgeNotificacaoComponent,
        BsnDefaultComponent,
        BsnReenviarPedidoComponent
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatMenuModule,
        MatBadgeModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatDividerModule,
        FlexLayoutModule,
    ],
    exports: [
        BottomSheetNotificacaoComponent,
        BadgeNotificacaoComponent
    ],
    entryComponents: [
        BottomSheetNotificacaoComponent,
    ]
})
export class NotificacaoModule { }
