import { ParceiroColigado } from './../global-interfaces/parceiro-coligado-interface';
import { SpinnerComponent } from 'src/app/global-components/spinner/spinner.component';
import { Component, OnInit, ViewContainerRef, ViewChild, ElementRef } from '@angular/core';
import { CadastrarParceiro } from './local-interfaces/cadastrar-parceiro-interface';
import { AutenticacaoService } from '../services/autenticacao.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
import { BuscaCepService } from '../services/busca-cep.service';
import { BuscaCEP } from './local-interfaces/busca-cep-interface';
import { Overlay } from '@angular/cdk/overlay';
import { SankhyaServiceService } from '../services/sankhya-service.service';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Parceiro } from '../global-interfaces/parceiro-interface';
import { DomSanitizer } from '@angular/platform-browser';
import { ValidateCNPJ } from '../services/validacoes.service';
import { MatBottomSheet, MatDialog } from '@angular/material';
import { BottomSheetCadastroComponent } from './local-components/bottom-sheet-cadastro/bottom-sheet-cadastro.component';
import { PortalJpaService } from '../services/portal-jpa.service';
import { Router } from '@angular/router';
import { DialogBasicComponent } from '../global-components/dialog-basic/dialog-basic.component';

@Component({
    selector: 'app-cadastrar-cliente',
    templateUrl: './cadastrar-cliente.component.html',
    styleUrls: ['./cadastrar-cliente.component.scss'],
})
export class CadastrarClienteComponent implements OnInit {
    constructor(
        private auth: AutenticacaoService,
        private snackBar: MatSnackBar,
        private http: HttpClient,
        private buscaCEP: BuscaCepService,
        public overlay: Overlay,
        public viewContainerRef: ViewContainerRef,
        private sankhyaService: SankhyaServiceService,
        private router: Router,
        private fb: FormBuilder,
        public _d: DomSanitizer,
        private bottomSheet: MatBottomSheet,
        private portalJpaService: PortalJpaService,
        private dialog: MatDialog
    ) {
        // Verificando se o usuário está logado
        this.auth.autenticar();

        // Iniciando os atributos do objeto
        this.inicializarObjetos();

        //   // Coletando o código do representante
        const codRep: number = auth.getCodRep();

        // Estados brasileiros
        this.estados = [
            'AC',
            'AL',
            'AP',
            'AM',
            'BA',
            'CE',
            'DF',
            'ES',
            'GO',
            'MA',
            'MT',
            'MS',
            'MG',
            'PA',
            'PB',
            'PR',
            'PE',
            'PI',
            'RJ',
            'RN',
            'RS',
            'RO',
            'RR',
            'SC',
            'SP',
            'SE',
            'TO',
        ];

        // Limite de caracteres
        this.limiteCaracteresObservacoes = 2000;

        this.isCopiaEndCobranca = true;
        this.isColigada = false;
        this.isAvistaAntec = false;
        this.isLeadDigital = false;

        this.inicializaVariaveis();

        this.tipoServico = '';
        this.lockServico = false;
        this.credSug = '';
        this.cnpjBusca = '';

        if ([0, 72, 104, 1004, 1005].includes(codRep)) {
            this.ativarLeadDigital = true;
        }

        // Se o APP entrou nesta rota com o  extras.state.copiaCadastro setado, deve ser copiado os dados do cadastro indicado.
        const nav = this.router.getCurrentNavigation();
        if (nav && nav.extras && nav.extras.state && nav.extras.state.copiarCadastro) {
            this.copiarCadastro = true;
            this.cadastroOriginal = JSON.parse(nav.extras.state.copiarCadastro);
        }
    }

    ngOnInit() {
        this.getRamoAtividade();

        this.http.get('assets/documento.png', { responseType: 'blob' }).subscribe(img => {
            this.imgDoc = img;
        });

        if (this.copiarCadastro) this.realizarCopiaCadastro();
    }

    // @ViewChild('formulario', { static: false }) formulario: NgForm;

    // As referências ao formulário abaixo são para manipular o focus quando o usuário sair do campo CEP
    @ViewChild('numeroEnderecoCliente', { static: false }) numeroEnderecoCliente: ElementRef;
    @ViewChild('numeroEnderecoCobranca', { static: false }) numeroEnderecoCobranca: ElementRef;
    @ViewChild('razaoSocialCliente', { static: false }) razaoSocialCliente: ElementRef;

    //O FormGroup está na ordem dos campos que vão ser verificados no botão de enviar (de cima para baixo)
    formCadCli = new FormGroup({
        razaoSocial: new FormControl(),
        cpfcnpj: new FormControl('', [ValidateCNPJ]),
        telefone1: new FormControl(),
        telefone2: new FormControl(),
        whatsapp: new FormControl(),
        emailNfe: new FormControl(),
        emailContato: new FormControl(),
        inscEstadual: new FormControl(),
        representante: new FormControl(),
        chavePix: new FormControl(),
        respComprador: new FormControl(),
        setorComprador: new FormControl(),
        telRespComprador: new FormControl(),
        emailRespComprador: new FormControl(),
        respFinanceiro: new FormControl(),
        setorFinanceiro: new FormControl(),
        telRespFinanceiro: new FormControl(),
        emailRespFinanceiro: new FormControl(),
        cepCliente: new FormControl(),
        enderecoCliente: new FormControl(),
        numeroCliente: new FormControl(),
        bairroCliente: new FormControl(),
        complementoCliente: new FormControl(),
        cidadeCliente: new FormControl(),
        estadoCliente: new FormControl(),
        paisCliente: new FormControl(),
        cepCobranca: new FormControl(),
        enderecoCobranca: new FormControl(),
        numeroCobranca: new FormControl(),
        bairroCobranca: new FormControl(),
        complementoCobranca: new FormControl(),
        cidadeCobranca: new FormControl(),
        estadoCobranca: new FormControl(),
        paisCobranca: new FormControl(),
        observRepres: new FormControl(),
        credSugerido: new FormControl(),
        ramoAtividade: new FormControl(),
        participacaoSoc: new FormControl(),
        avistaAntec: new FormControl(),
        leadDigital: new FormControl(),
        copiaCobranca: new FormControl(),
        isColigada: new FormControl(),
        nomeMatriz: new FormControl(),
        refComerciais: new FormArray([]),
        observGeral: new FormControl(),
        tipoServico: new FormControl(),
        codRep: new FormControl(),
        userName: new FormControl(),
        seqCad: new FormControl(),
        observColigada: new FormControl(),
    });

    referencias = new FormGroup({
        nomeEmpresa: new FormControl(),
        refComercialContato: new FormControl(),
        refComercialTel: new FormControl(),
    });

    // Objeto que será enviado para o backend
    cliente: CadastrarParceiro;

    // Vetor com os estados brasileiros
    estados: string[];

    // Limite de caracteres da referência comercial
    limiteCaracteresObservacoes: number;

    // Controla a expansão do painel no endereço cobrança
    isExpandido: boolean = true;

    isCopiaEndCobranca: boolean;
    isColigada: boolean;
    isAvistaAntec: boolean;
    isLeadDigital: boolean;
    ramoAtividade = [];

    arquivoAnexo = [];
    imgsrc: any = [];
    imgDoc: any;

    parceiroCliente: Parceiro = this.getParceiroVazio();
    desabilitaEnvio = false;

    tipoServico: string;
    lockServico: boolean;
    credSug: string;
    cnpjBusca: string;

    refArray: FormArray;

    listaColigadas: ParceiroColigado[];
    CNPJMatriz: string = '';
    razaoSocialMatriz: string = '';

    // Esta variavel indica se deve copiar o cadastro de um cadastro enviado como parâmetro da rota de navegação
    copiarCadastro: boolean = false;
    cadastroOriginal: any;

    ativarLeadDigital: boolean = false;

    // // Método disparado quando usuário clica em enviar
    enviarCadastro() {
        // Desabilitando o botão de enviar para impedir que envie duas vezes o mesmo cadastro
        this.desabilitaEnvio = true;
        // Verificando se os campos obrigatórios estão preenchidos
        if (this.formCadCli.invalid) {
            for (let i in this.formCadCli.controls) {
                if (this.formCadCli.controls[i].invalid) {
                    (document.querySelector(`[formControlName=${i}]`) as HTMLInputElement).focus();
                    this.openSnackbar('Preencha os campos obrigatórios.');
                    this.desabilitaEnvio = false;
                    return false;
                }
            }
        }

        if (this.isColigada && !this.formCadCli.get('nomeMatriz').value) {
            this.openSnackbar('Opção de Coligada está marcada. Informar a Matriz.');
            (document.querySelector(`[formControlName="isColigada"]`) as HTMLInputElement).focus();
            this.desabilitaEnvio = false;
            return false;
        }

        if (!this.isAvistaAntec) {
            if (!this.formCadCli.get('observRepres').value && !(this.tipoServico === 'atualizarCad')) {
                (document.querySelector('[formControlName="observRepres"]') as HTMLInputElement).focus();
                this.openSnackbar('Informar Parecer do Representante');
                this.desabilitaEnvio = false;
                return false;
            }

            if (!(this.tipoServico === 'atualizarCad') && !this.credSug) {
                (document.querySelector('input[name="credSugerido"]') as HTMLInputElement).focus();
                this.openSnackbar('Informar Limite de Crédito Sugerido.');
                this.desabilitaEnvio = false;
                return false;
            }

            if ((this.formCadCli.get('refComerciais') as FormArray).controls.length < 3 && !(this.tipoServico === 'atualizarCad')) {
                this.openSnackbar('Informar Pelo Menos 3 Referências Comerciais.');
                (document.querySelector(`[formControlName="nomeEmpresa"]`) as HTMLInputElement).focus();
                this.desabilitaEnvio = false;
                return false;
            }
        }

        this.formCadCli.controls.userName.setValue(this.auth.getUserName());
        this.formCadCli.controls.codRep.setValue(this.auth.getCodRep());

        // Desestrturando o objeto de formulário para utilizar nomes mais curtos
        const {
            razaoSocial,
            enderecoCliente,
            numeroCliente,
            complementoCliente,
            bairroCliente,
            cidadeCliente,
            cepCliente,
            estadoCliente,
            paisCliente,
            telefone1,
            emailNfe,
            inscEstadual,
            cpfcnpj,
            enderecoCobranca,
            numeroCobranca,
            complementoCobranca,
            bairroCobranca,
            cidadeCobranca,
            cepCobranca,
            estadoCobranca,
            paisCobranca,
            observRepres,
            representante,
            telefone2,
            whatsapp,
            emailContato,
            credSugerido,
            emailResponsavel,
            participacaoSoc,
            responsavel,
            setor,
            telResponsavel,
            ramoAtividade,
            qtdColigadas,
            avistaAntec,
            leadDigital,
            chavePix,
            observGeral,
            tipoServico,
            codRep,
            userName,
            seqCad,
            observColigada
        } = this.formCadCli.value;

        // Mostrando o spinner na tela
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

        // Construindo o objeto cliente
        this.cliente = {
            RAZAOSOCIAL: razaoSocial,
            NOMEEND: enderecoCliente,
            NUMEND: numeroCliente,
            COMPLEMENTO: complementoCliente,
            NOMEBAI: bairroCliente,
            NOMECID: cidadeCliente,
            CEP: cepCliente,
            UF: estadoCliente,
            PAISEND: paisCliente,
            TELEFONE1: telefone1,
            TELEFONE2: telefone2,
            WHATSAPP: whatsapp,
            EMAILNFE: emailNfe,
            EMAILCONTATO: emailContato,
            IDENTINSCESTAD: inscEstadual,
            CGCCPF: cpfcnpj,
            NOMEENDCOB: this.isCopiaEndCobranca ? enderecoCliente : enderecoCobranca,
            NUMENDCOB: this.isCopiaEndCobranca ? numeroCliente : numeroCobranca,
            COMPLEMENTOCOB: this.isCopiaEndCobranca ? complementoCliente : complementoCobranca,
            NOMEBAICOB: this.isCopiaEndCobranca ? bairroCliente : bairroCobranca,
            NOMECIDCOB: this.isCopiaEndCobranca ? cidadeCliente : cidadeCobranca,
            CEPCOB: this.isCopiaEndCobranca ? cepCliente : cepCobranca,
            UFCOB: this.isCopiaEndCobranca ? estadoCliente : estadoCobranca,
            PAISENDCOB: this.isCopiaEndCobranca ? paisCliente : paisCobranca,
            OBSERVACOES: observRepres,
            REPRESENTANTE: representante,
            CREDSUGERIDO: credSugerido,
            EMAILRESPONSAVEL: emailResponsavel,
            PARTICIPACAOSOC: participacaoSoc,
            RAMOATIVIDADE: ramoAtividade,
            SETOR: setor,
            TELRESPONSAVEL: telResponsavel,
            RESPONSAVEL: responsavel,
            AVISTAANTEC: avistaAntec,
            LEADDIGITAL: leadDigital,
            NOMEMATRIZ: this.parceiroCliente.RAZAOSOCIAL,
            QTDCOLIGADAS: qtdColigadas,
            CHAVEPIX: chavePix,
            OBSERVACAOGERAL: observGeral,
            TIPOSERVICO: tipoServico,
            CODREP: codRep,
            USERNAME: userName,
            SEQCAD: seqCad,
            OBSERVCOLIGADA: observColigada,
        };

        this.sankhyaService.enviarEmailCadCli({ formCliente: this.formCadCli.value }, this.arquivoAnexo).subscribe(
            res => {
                if (res.serviceResponse === 'ERROR') {
                    this.openSnackbar('Falha no cadastro. Tente novamente!');
                } else {
                    this.openSnackbar('Cadastro enviado com sucesso!');
                    location.reload();
                }
                // Removendo o spinner da tela
                overlayRef.dispose();
            },
            erro => {
                this.openSnackbar('Falha no envio do Cadastro!');
                console.log(erro);

                // Removendo o spinner da tela
                overlayRef.dispose();
            }
        );
    }

    openSnackbar(message: string) {
        this.snackBar.open(message, 'Fechar', {
            horizontalPosition: 'end',
            verticalPosition: 'top',
            duration: 5000,
        });
    }

    inicializarObjetos() {
        // Iniciando os atributos do objeto
        this.cliente = {
            RAZAOSOCIAL: '',
            NOMEEND: '',
            NUMEND: null,
            COMPLEMENTO: '',
            NOMEBAI: '',
            NOMECID: '',
            CEP: '',
            UF: '',
            PAISEND: '',
            TELEFONE1: '',
            TELEFONE2: '',
            WHATSAPP: '',
            EMAILNFE: '',
            EMAILCONTATO: '',
            IDENTINSCESTAD: '',
            CGCCPF: '',
            NOMEENDCOB: '',
            NUMENDCOB: null,
            COMPLEMENTOCOB: '',
            NOMEBAICOB: '',
            NOMECIDCOB: '',
            CEPCOB: '',
            UFCOB: '',
            PAISENDCOB: '',
            OBSERVACOES: '',
            REPRESENTANTE: '',
            CREDSUGERIDO: 0,
            EMAILRESPONSAVEL: '',
            PARTICIPACAOSOC: '',
            RAMOATIVIDADE: '',
            SETOR: '',
            TELRESPONSAVEL: '',
            RESPONSAVEL: '',
            AVISTAANTEC: false,
            LEADDIGITAL: false,
            NOMEMATRIZ: '',
            QTDCOLIGADAS: 0,
            CHAVEPIX: '',
            OBSERVACAOGERAL: '',
            TIPOSERVICO: '',
            CODREP: null,
            USERNAME: '',
            SEQCAD: 0,
            OBSERVCOLIGADA: '',
        };
    }

    buscarCEP(evento: KeyboardEvent) {
        // Coletar se o usuário disparou este método a partir do input de CEP do cliente ou do CEP de cobrança
        const tipoCep: string = (evento.target as HTMLInputElement).name;

        // Caso o usuário esteja buscando o CEP do cliente e o formato do CEP esteja correto
        if (this.formCadCli.controls.cepCliente.value) {
            if (tipoCep === 'cepCliente' && this.formCadCli.controls.cepCliente.value.length === 9) {
                // Buscar o CEP
                this.buscaCEP.viaCEP(this.formCadCli.controls.cepCliente.value).subscribe(
                    (res: any) => {
                        if (res.erro) {
                            this.openSnackbar('CEP não encontrado. Preencha os campos manualmente.');
                        } else {
                            //console.log(this.formCadCli.controls);

                            /*
                    A API que busca CEP pode assumir mais de um formato e por isso "res" é do tipo "any".
                    A variável abaixo assume a interface correta
                    */
                            const endereco = res as BuscaCEP;

                            // Atribuindo os valores
                            // this.formulario.controls.cepCobranca.setValue(this.formulario.controls.cepCliente.value);
                            this.formCadCli.controls.enderecoCliente.setValue(endereco.logradouro);
                            // this.formCadCli.controls.enderecoCobranca.setValue(endereco.logradouro);
                            this.formCadCli.controls.bairroCliente.setValue(endereco.bairro);
                            // this.formCadCli.controls.bairroCobranca.setValue(endereco.bairro);
                            this.formCadCli.controls.cidadeCliente.setValue(endereco.localidade);
                            // this.formCadCli.controls.cidadeCobranca.setValue(endereco.localidade);
                            this.formCadCli.controls.estadoCliente.setValue(endereco.uf);
                            // this.formCadCli.controls.estadoCobranca.setValue(endereco.uf);
                            this.formCadCli.controls.paisCliente.setValue('Brasil');
                            // this.formCadCli.controls.paisCobranca.setValue('Brasil');

                            this.numeroEnderecoCliente.nativeElement.focus();
                        }
                    },
                    erro => {
                        console.log(erro);
                        this.openSnackbar('Falha na busca do endereço');
                    }
                );
            }
        }

        // Caso o usuário esteja buscando o CEP de cobrança e ele tenha sido digitado no formato correto
        if (this.formCadCli.controls.cepCobranca.value) {
            if (tipoCep === 'cepCobranca' && this.formCadCli.controls.cepCobranca.value.length === 9) {
                // Buscar o CEP com a API
                this.buscaCEP.viaCEP(this.formCadCli.controls.cepCobranca.value).subscribe(
                    (res: any) => {
                        if (res.erro) {
                            this.openSnackbar('CEP não encontrado. Preencha os campos manualmente.');
                        } else {
                            /*
                        A API que busca CEP pode assumir mais de um formato e por isso "res" é do tipo "any".
                        A variável abaixo assume a interface correta
                        */
                            const endereco = res as BuscaCEP;

                            // Atribuindo os valores
                            this.formCadCli.controls.enderecoCobranca.setValue(endereco.logradouro);
                            this.formCadCli.controls.bairroCobranca.setValue(endereco.bairro);
                            this.formCadCli.controls.cidadeCobranca.setValue(endereco.localidade);
                            this.formCadCli.controls.estadoCobranca.setValue(endereco.uf);
                            this.formCadCli.controls.paisCobranca.setValue('Brasil');

                            this.numeroEnderecoCobranca.nativeElement.focus();
                        }
                    },
                    erro => {
                        console.log(erro);
                        this.openSnackbar('Falha na busca do endereço');
                    }
                );
            }
        }
    }

    pesquisaCnpj(cnpj: string) {
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);
        //Verificação do input do CNPJ antes de fazer a requisição
        if (!cnpj.match(/^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/) && this.tipoServico === 'cadastrar') {
            this.openSnackbar('Preencha o CNPJ do cliente corretamente.');
            overlayRef.dispose();
            return false;
        }
        cnpj = cnpj.replace(/[^0-9]/g, '');

        this.sankhyaService.verificaCnpjExistente(cnpj).subscribe(
            (res: any) => {
                if (res.serviceResponse === 'OK' && this.tipoServico === 'cadastrar' && res.response.VALUE != '0') {
                    this.openSnackbar('Este CNPJ já está cadastrado. Para atualizar um cadastro, selecione o serviço correto.');
                    overlayRef.dispose();
                    return false;
                }
                
                this.sankhyaService.getConsultaCnpj(cnpj).subscribe(
                    (res: any) => {
                        if (res.response === 429) {
                            this.openSnackbar('Servidor ocupado, aguarde 1 minuto para pesquisar novamente!');
                        } else if (res.response === 404) {
                            this.openSnackbar('Erro na consulta, CNPJ inválido ou recém cadastrado! Faça o cadastro manualmente.');
                            this.lockServico = true;
                        } else if (res.response === 504) {
                            this.openSnackbar('Servidor indispónível ao buscar CNPJ. Faça o cadastro manualmente.');
                            this.lockServico = true;
                        } else if (res.status === 'ERROR') {
                            this.openSnackbar('CNPJ inexistente! Verifique os números digitados.');
                        } else {
                            let cep = res.cep;
                            let telefone = res.telefone;

                            if (res.telefone.indexOf('/') > 0) {
                                telefone = res.telefone.substr(0, res.telefone.indexOf('/'));
                            }

                            cep = cep.replace(/[^0-9]/g, '');
                            cep = [cep.slice(0, 5), '-', cep.slice(5)].join('');

                            this.formCadCli.controls.razaoSocial.setValue(res.nome);
                            this.formCadCli.controls.telefone1.setValue(telefone);
                            this.formCadCli.controls.cpfcnpj.setValue(res.cnpj);
                            this.formCadCli.controls.emailNfe.setValue(res.email);
                            this.formCadCli.controls.cepCliente.setValue(cep);
                            this.formCadCli.controls.bairroCliente.setValue(res.bairro);
                            this.formCadCli.controls.cidadeCliente.setValue(res.municipio);
                            this.formCadCli.controls.complementoCliente.setValue(res.complemento);
                            this.formCadCli.controls.enderecoCliente.setValue(res.logradouro);
                            this.formCadCli.controls.estadoCliente.setValue(res.uf);
                            this.formCadCli.controls.numeroCliente.setValue(res.numero);
                            this.formCadCli.controls.paisCliente.setValue('Brasil');
                            this.formCadCli.controls.representante.setValue(this.auth.getUserName());

                            this.lockServico = true;
                        }
                        overlayRef.dispose();
                    },
                    erro => {
                        this.openSnackbar('Ocorreu um erro na pesquisa, tente novamente em alguns instantes!');
                        console.error(erro);
                        overlayRef.dispose();
                    }
                );
            },
            erro => {
                this.openSnackbar('Ocorreu um erro na pesquisa, tente novamente em alguns instantes!');
                console.error(erro);
                overlayRef.dispose();
            }
        );
    }

    // Validando o telefone no formato (XX) XXXX-XXXX para fixos e (XX) X XXXX-XXXX para celulares
    inputTelefone(telParam: number) {
        let telefone: string; // = telParam === 1 ? this.formCadCli.controls.telefone1.value : this.formCadCli.controls.telefone2.value;

        if (telParam === 1) {
            telefone = this.formCadCli.controls.telefone1.value;
        } else if (telParam === 2) {
            telefone = this.formCadCli.controls.telefone2.value;
        } else if (telParam === 3) {
            telefone = this.formCadCli.controls.telRespComprador.value;
        } else if (telParam === 4) {
            telefone = this.formCadCli.controls.telRespFinanceiro.value;
        } else if (telParam === 5) {
            telefone = this.referencias.controls.refComercialTel.value;
        } else if (telParam === 6) {
            telefone = this.formCadCli.controls.whatsapp.value;
        }

        // Limpando tudo que não é número
        telefone = telefone.replace(/[^0-9]/g, '');

        // Colocando ( na frente para iniciar o formato de DDD
        if (telefone.length > 0) {
            telefone = ['(', telefone].join('');
        }

        // Transformando (XXX em (XX) X
        if (telefone.length > 3) {
            telefone = telefone.replace(/[^0-9]/g, '');
            telefone = ['(', telefone.slice(0, 2), ') ', telefone.slice(2)].join('');
        }

        // Transformando (XX) XXXXXXXX em (XX) XXXX-XXXX. Caso o número seja fixo, a validação acaba aqui
        if (telefone.length > 12) {
            telefone = telefone.replace(/[^0-9]/g, '');
            telefone = ['(', telefone.slice(0, 2), ') ', telefone.slice(2, 6), '-', telefone.slice(6)].join('');
        }

        // Caso o número seja celular, ela entra neste bloco. Transformando (XX) XXXX-XXXXX em (XX) X XXXX-XXXX
        if (telefone.length > 14) {
            telefone = telefone.replace(/[^0-9]/g, '');
            telefone = ['(', telefone.slice(0, 2), ') ', telefone.slice(2, 3), ' ', telefone.slice(3, 7), '-', telefone.slice(7)].join('');
        }

        // Atribuindo o valor ao objeto
        //telParam === 1 ? this.formCadCli.controls.telefone1.setValue(telefone) : this.formCadCli.controls.telefone2.setValue(telefone);

        if (telParam === 1) {
            this.formCadCli.controls.telefone1.setValue(telefone);
        } else if (telParam === 2) {
            this.formCadCli.controls.telefone2.setValue(telefone);
        } else if (telParam === 3) {
            this.formCadCli.controls.telRespComprador.setValue(telefone);
        } else if (telParam === 4) {
            this.formCadCli.controls.telRespFinanceiro.setValue(telefone);
        } else if (telParam === 5) {
            this.referencias.controls.refComercialTel.setValue(telefone);
        } else if (telParam === 6) {
            this.formCadCli.controls.whatsapp.setValue(telefone);
        }
    }

    // Formatando CNPJs no formando XX.XXX.XXX/XXXX-XX
    inputCNPJ(cnpjParam: number) {
        let cnpj: string;
        // Verificando qual dos campos CNPJ está sendo formatado
        if (cnpjParam === 1) {
            cnpj = this.formCadCli.controls.cpfcnpj.value;
        } else if (cnpjParam === 2) {
            cnpj = this.cnpjBusca;
        }
        // Eliminar tudo que não é número
        cnpj = cnpj.replace(/[^0-9]/g, '');

        // Transformando XXX em XX.X
        if (cnpj.length > 2) {
            cnpj = cnpj.replace(/[^0-9]/g, '');
            cnpj = [cnpj.slice(0, 2), '.', cnpj.slice(2)].join('');
        }

        // Transformando XX.XXXX em XX.XXX.X
        if (cnpj.length > 6) {
            cnpj = cnpj.replace(/[^0-9]/g, '');
            cnpj = [cnpj.slice(0, 2), '.', cnpj.slice(2, 5), '.', cnpj.slice(5)].join('');
        }

        // Transformando XX.XXX.XXXX em XX.XXX.XXX/X
        if (cnpj.length > 10) {
            cnpj = cnpj.replace(/[^0-9]/g, '');
            cnpj = [cnpj.slice(0, 2), '.', cnpj.slice(2, 5), '.', cnpj.slice(5, 8), '/', cnpj.slice(8)].join('');
        }

        // Transformando XX.XXX.XXX/XXXXX em XX.XXX.XXX/XXXX-X
        if (cnpj.length > 15) {
            cnpj = cnpj.replace(/[^0-9]/g, '');
            cnpj = [cnpj.slice(0, 2), '.', cnpj.slice(2, 5), '.', cnpj.slice(5, 8), '/', cnpj.slice(8, 12), '-', cnpj.slice(12)].join('');
        }

        // Atribuir o resultado
        if (cnpjParam === 1) {
            this.formCadCli.controls.cpfcnpj.setValue(cnpj);
        } else if (cnpjParam === 2) {
            this.cnpjBusca = cnpj;
        }
    }

    inputCEP(evento: KeyboardEvent) {
        // Identificando se o CEP é o do cliente ou de cobrança através do atributo name
        const tipo = (evento.target as HTMLInputElement).name;

        let cep: string = tipo === 'cepCliente' ? this.formCadCli.controls.cepCliente.value : this.formCadCli.controls.cepCobranca.value;

        // Eliminando tudo que não é número
        cep = cep.replace(/[^0-9]/g, '');

        // Transformando XXXXXX em XXXXX-X
        if (cep.length > 5) {
            cep = [cep.slice(0, 5), '-', cep.slice(5)].join('');
        }

        tipo === 'cepCliente' ? this.formCadCli.controls.cepCliente.setValue(cep) : this.formCadCli.controls.cepCobranca.setValue(cep);
    }

    changeCopiaCobranca() {
        console.log(this.isCopiaEndCobranca);
    }

    onFileSelected(event: any) {
        event.preventDefault();

        if (this.arquivoAnexo.length >= 4) {
            this.openSnackbar('O limite de 4 anexos foi atingido!');
            return false;
        }

        if (!event.srcElement.files[0]) {
            return false;
        }

        let img: any;
        let anexo = event.srcElement.files[0];

        if (anexo && anexo.size > 0) {
            if (anexo.size < 5000000) {
                if (anexo.type.includes('image/')) {
                    img = event.srcElement.files[0];
                } else {
                    img = this.imgDoc;
                }

                this.arquivoAnexo.push(anexo);
                this.imgsrc.push(window.URL.createObjectURL(img));
            } else {
                this.openSnackbar('Arquivo não pode ser maior que 5MB!');
            }
        }
    }

    excluirAnexo(event: any, i: number) {
        event.preventDefault();
        this.arquivoAnexo.splice(i, 1);
        this.imgsrc.splice(i, 1);
    }

    clearClienteAutocomplete(event) {
        event.preventDefault();
        this.formCadCli.get('nomeMatriz').setValue(null);
        this.CNPJMatriz = '';
        this.razaoSocialMatriz = '';
        this.parceiroCliente = {
            CODPARC: 0,
            NOMEPARC: '',
            RAZAOSOCIAL: '',
            CODVEND: 0,
            TIPO: '',
            NOMEEND: '',
            NUMEND: 0,
            COMPLEMENTO: '',
            NOMEBAI: '',
            NOMECID: '',
            CEP: '',
            UF: '',
            PAISEND: '',
            TELEFONE: '',
            EMAIL: '',
            IDENTINSCESTAD: '',
            CGCCPF: '',
            ATIVO: '',
            LIMCRED: 0,
            CLIENTE: '',
            FORNECEDOR: '',
            VENDEDOR: '',
            TRANSPORTADORA: '',
            BLOQUEAR: '',
            CODTIPPARC: 0,
            APELIDO: '',
        };
    }

    inicializaVariaveis() {
        this.parceiroCliente = {
            ATIVO: 'S',
            BLOQUEAR: 'N',
            CGCCPF: '',
            CLIENTE: '',
            CODVEND: 0,
            COMPLEMENTO: '',
            EMAIL: '',
            FORNECEDOR: '',
            IDENTINSCESTAD: '',
            LIMCRED: 0,
            NOMEBAI: '',
            NOMECID: '',
            NOMEEND: '',
            NOMEPARC: '',
            NUMEND: 0,
            RAZAOSOCIAL: '',
            CODPARC: 0,
            TELEFONE: '',
            TIPO: '',
            TRANSPORTADORA: '',
            VENDEDOR: '',
        };
    }

    autocompleteNomeMatrizChange(cliente: Parceiro) {
        if (!cliente || cliente.ATIVO == 'N' || cliente.ADSITUACAOPORTAL == 'BA') {
            this.parceiroCliente = this.getParceiroVazio();
        } else {
            this.parceiroCliente = { ...cliente };
        }

        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

        this.sankhyaService.getListaColigadas(this.parceiroCliente.CODPARC, 'cadastro').subscribe(
            (res: any) => {
                let lista: ParceiroColigado[] = [];
                lista = res.param;

                lista
                    .filter(i => i.MATRIZ == '1')
                    .forEach(i => {
                        this.CNPJMatriz = i.CGC_CPF.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
                        this.razaoSocialMatriz = i.RAZAOSOCIAL;
                    });
                overlayRef.dispose();
            },
            error => {
                console.log(error);
                overlayRef.dispose();
            }
        );

        this.formCadCli.controls.nomeMatriz.setValue(this.parceiroCliente.RAZAOSOCIAL);
    }

    autocompleteClienteChange() {
        this.pesquisaCnpj(this.parceiroCliente.CGCCPF);
    }

    getRamoAtividade() {
        this.sankhyaService.getRamoAtividade().subscribe(
            (res: any) => {
                if (res.serviceResponse === 'OK') {
                    this.ramoAtividade = res.response;
                    this.formCadCli.controls.ramoAtividade.setErrors(null);
                } else {
                    this.formCadCli.controls.ramoAtividade.setErrors({ errorCtrl: res.response });
                    this.formCadCli.controls.ramoAtividade.markAsTouched();
                }
            },
            error => {
                this.formCadCli.controls.ramoAtividade.setErrors({ errorCtrl: error.message });
                this.formCadCli.controls.ramoAtividade.markAsTouched();
            }
        );
    }

    onClickRefComercial(event) {
        event.preventDefault();

        const validacao = [
            this.referencias.get('refComercialContato').value !== null,
            this.referencias.get('refComercialTel').value !== null,
            this.referencias.get('nomeEmpresa').value !== null,
        ];

        if (validacao.includes(false)) return;

        this.refArray = this.fb.array([this.referencias.value]);
        (this.formCadCli.get('refComerciais') as FormArray).controls.forEach(control => {
            this.refArray.push(this.fb.group(control.value));
        });

        this.formCadCli.setControl('refComerciais', this.fb.array(this.refArray.value));
        this.validaEndCob();
        this.referencias.reset('');
    }

    avistaAntecChange(event) {
        if (event.checked) {
            this.formCadCli.controls.credSugerido.setErrors(null);
            this.formCadCli.controls.participacaoSoc.setErrors(null);
            this.formCadCli.controls.ramoAtividade.setErrors(null);
        }
    }

    validaEndCob() {
        if (this.isCopiaEndCobranca) {
            this.formCadCli.controls.cepCliente.setErrors(null);
            this.formCadCli.controls.cepCobranca.setErrors(null);
            this.formCadCli.controls.copiaCobranca.setErrors(null);
            this.formCadCli.controls.enderecoCobranca.setErrors(null);
            this.formCadCli.controls.bairroCobranca.setErrors(null);
            this.formCadCli.controls.cidadeCobranca.setErrors(null);
            this.formCadCli.controls.estadoCobranca.setErrors(null);
            this.formCadCli.controls.paisCobranca.setErrors(null);
        }
    }

    testTam() {
        const tmpCnpj = this.formCadCli.get('cpfcnpj').value.replace(/[^\d]+/g, '');
        if (tmpCnpj.length == 0) {
            return { cnpjIncompleto: true };
        }
        return null;
    }

    selecionaServico(event: any) {
        this.tipoServico = event.value;
        this.formCadCli.controls.tipoServico.setValue(this.tipoServico);
    }

    formataLimiteBlur() {
        if (this.credSug) {
            this.credSug = Number(this.credSug).toFixed(2).replace('.', ',');
            this.formCadCli.controls.credSugerido.setValue(this.credSug.replace(',00', ''));
        }
    }

    formataLimiteKeyup() {
        if (this.credSug.includes('.') || this.credSug.includes(',')) {
            this.credSug = '';
        }
        if (isNaN(Number(this.credSug))) {
            this.credSug = '';
        }
    }

    formataLimiteFocus() {
        if (this.credSug) {
            this.credSug = this.credSug.replace(',00', '');
        }
    }

    removeRef(i: any) {
        let referencias = this.formCadCli.get('refComerciais').value;
        referencias.splice(i, 1);

        (this.formCadCli.get('refComerciais') as FormArray).controls.push(referencias);

        this.formCadCli.setControl('refComerciais', this.fb.array(referencias));
    }

    limpaColigada() {
        if (!this.isColigada) {
            this.CNPJMatriz = null;
            this.razaoSocialMatriz = null;
            this.formCadCli.controls.nomeMatriz.setValue(null);
        }
    }

    verificaCnpjCadastrado(cnpj: string) {
        cnpj = cnpj.replace(/[^0-9]/g, '');
        this.sankhyaService.verificaCnpjExistente(cnpj).subscribe(
            (res: any) => {
                if (res.serviceResponse === 'OK' && this.tipoServico === 'cadastrar' && res.response.VALUE != '0') {
                    this.openSnackbar('Este CNPJ já está cadastrado. Para atualizar um cadastro, selecione o serviço correto.');
                    this.formCadCli.get('cpfcnpj').setValue('');
                    return false;
                }
            },
            erro => {
                this.openSnackbar('Ocorreu um erro na pesquisa, tente novamente em alguns instantes!');
            }
        );
    }

    // Bottomsheet com as informações sobre coligação
//    regraColigada() {
//         if (this.isColigada) {
//             this.portalJpaService.getTermoColigada().subscribe((blob: Blob) => {
//                 const url = URL.createObjectURL(blob);
//                 this.bottomSheet.open(BottomSheetCadastroComponent, {
//                     disableClose: true,
//                     data: { url, nomeArquivo: `termo_coligada.pdf` },
//                 });
//             });
//             console.log("teste")
//         }
//     }

    // Método que vai realizar o preenchimento automático dos dados do email quando o representante precisar corrigir o cadastro
    realizarCopiaCadastro() {
        // Mostrando o spinner na tela
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

        // Atribuindo o tipo de serviço para ser usado na procedure
        this.tipoServico = 'copiaCadastro';
        this.formCadCli.controls.tipoServico.setValue('copiaCadastro');

        // Atribuindo TRUE à variável que impede de escolher outro tipo de serviço
        this.lockServico = true;

        // Preenchendo automaticamente todos os campos do cadastro
        // Dados Gerais
        this.formCadCli.controls.razaoSocial.setValue(this.cadastroOriginal.cadastro.RAZAOSOCIAL);
        this.formCadCli.controls.cpfcnpj.setValue(this.cadastroOriginal.cadastro.CGCCPF);
        this.formCadCli.controls.telefone1.setValue(this.cadastroOriginal.cadastro.TELEFONE1);
        this.formCadCli.controls.telefone2.setValue(this.cadastroOriginal.cadastro.TELEFONE2);
        this.formCadCli.controls.whatsapp.setValue(this.cadastroOriginal.cadastro.WHATSAPP);
        this.formCadCli.controls.emailNfe.setValue(this.cadastroOriginal.cadastro.EMAILNFE);
        this.formCadCli.controls.emailContato.setValue(this.cadastroOriginal.cadastro.EMAILCONTATO);
        this.formCadCli.controls.inscEstadual.setValue(this.cadastroOriginal.cadastro.IDENTINSCESTAD);
        this.formCadCli.controls.representante.setValue(this.cadastroOriginal.cadastro.REPRESENTANTE);
        this.formCadCli.controls.chavePix.setValue(this.cadastroOriginal.cadastro.CHAVEPIX);

        // A vista/antecipado
        if (this.cadastroOriginal.cadastro.AVISTAANTECIPADO == 'S') {
            this.isAvistaAntec = true;
        }

        // Contato
        this.formCadCli.controls.respComprador.setValue(this.cadastroOriginal.cadastro.RESPCOMPRADOR);
        this.formCadCli.controls.setorComprador.setValue(this.cadastroOriginal.cadastro.SETORCOMPRADOR);
        this.formCadCli.controls.telRespComprador.setValue(this.cadastroOriginal.cadastro.TELRESPCOMPRADOR);
        this.formCadCli.controls.emailRespComprador.setValue(this.cadastroOriginal.cadastro.EMAILRESPCOMPRADOR);
        this.formCadCli.controls.respFinanceiro.setValue(this.cadastroOriginal.cadastro.RESPFINANCEIRO);
        this.formCadCli.controls.setorFinanceiro.setValue(this.cadastroOriginal.cadastro.SETORFINANCEIRO);
        this.formCadCli.controls.telRespFinanceiro.setValue(this.cadastroOriginal.cadastro.TELRESPFINANCEIRO);
        this.formCadCli.controls.emailRespFinanceiro.setValue(this.cadastroOriginal.cadastro.EMAILRESPFINANCEIRO);

        // Endereço do Cliente
        this.formCadCli.controls.cepCliente.setValue(this.cadastroOriginal.cadastro.CEP);
        this.formCadCli.controls.enderecoCliente.setValue(this.cadastroOriginal.cadastro.NOMEEND);
        this.formCadCli.controls.numeroCliente.setValue(this.cadastroOriginal.cadastro.NUMEND);
        this.formCadCli.controls.bairroCliente.setValue(this.cadastroOriginal.cadastro.NOMEBAI);
        this.formCadCli.controls.complementoCliente.setValue(this.cadastroOriginal.cadastro.COMPLEMENTO);
        this.formCadCli.controls.cidadeCliente.setValue(this.cadastroOriginal.cadastro.NOMECID);
        this.formCadCli.controls.estadoCliente.setValue(this.cadastroOriginal.cadastro.UF);
        this.formCadCli.controls.paisCliente.setValue(this.cadastroOriginal.cadastro.PAISEND);

        // Endereço de Cobrança
        if (this.cadastroOriginal.cadastro.CEPCOB) {
            this.isCopiaEndCobranca = false;
            this.formCadCli.controls.cepCobranca.setValue(this.cadastroOriginal.cadastro.CEPCOB);
            this.formCadCli.controls.enderecoCobranca.setValue(this.cadastroOriginal.cadastro.NOMEENDCOB);
            this.formCadCli.controls.numeroCobranca.setValue(this.cadastroOriginal.cadastro.NUMENDCOB);
            this.formCadCli.controls.bairroCobranca.setValue(this.cadastroOriginal.cadastro.NOMEBAICOB);
            this.formCadCli.controls.complementoCobranca.setValue(this.cadastroOriginal.cadastro.COMPLEMENTOCOB);
            this.formCadCli.controls.cidadeCobranca.setValue(this.cadastroOriginal.cadastro.NOMECIDCOB);
            this.formCadCli.controls.estadoCobranca.setValue(this.cadastroOriginal.cadastro.UFCOB);
            this.formCadCli.controls.paisCobranca.setValue(this.cadastroOriginal.cadastro.PAISENDCOB);
        }

        // Grupo Coligada
        if (this.cadastroOriginal.cadastro.NOMEMATRIZ) {
            this.formCadCli.controls.nomeMatriz.setValue(this.cadastroOriginal.cadastro.NOMEMATRIZ);
        }

        // Parecer do Representante
        this.formCadCli.controls.observRepres.setValue(this.cadastroOriginal.cadastro.OBSERVACOES);

        // Informações Adicionais
        this.credSug = Number(this.cadastroOriginal.cadastro.CREDSUGERIDO).toFixed(2).replace('.', ',');
        this.formCadCli.controls.credSugerido.setValue(this.cadastroOriginal.cadastro.CREDSUGERIDO);
        this.formCadCli.controls.ramoAtividade.setValue(this.cadastroOriginal.cadastro.RAMOATIVIDADE);
        this.formCadCli.controls.participacaoSoc.setValue(this.cadastroOriginal.cadastro.PARTICIPACAOSOC);

        // Referências Comerciais
        var refCom = JSON.parse(this.cadastroOriginal.cadastro.REFCOMERCIAIS);
        for (let i in refCom) {
            this.refArray = this.fb.array([JSON.parse(this.cadastroOriginal.cadastro.REFCOMERCIAIS)[i]]);
            (this.formCadCli.get('refComerciais') as FormArray).controls.forEach(control => {
                this.refArray.push(this.fb.group(control.value));
            });
            this.formCadCli.setControl('refComerciais', this.fb.array(this.refArray.value));
        }

        // Atribuindo o número da sequência para ser usado no update
        this.formCadCli.controls.seqCad.setValue(this.cadastroOriginal.cadastro.SEQUENCIA);

        // Dando um focus no primeiro elemento do formulário, para a conferência dos campos
        setTimeout(() => {
            this.razaoSocialCliente.nativeElement.focus();
        }, 500);

        // Pop-up com a mensagem na tela
        this.dialog.open(DialogBasicComponent, {
            width: '400px',
            data: {
                titulo: 'Confira os dados do cadastro',
                linhas: ['*** Atenção ***', 'É responsabilidade do representante conferir todos os campos do cadastro.'],
                botoes: [{ texto: 'Ok', retorno: true, color: 'primary' }],
            },
        });

        // Retirando o spinner
        overlayRef.dispose();
    }

    private getParceiroVazio(): Parceiro {
        return {
            CODPARC: 0,
            NOMEPARC: '',
            RAZAOSOCIAL: '',
            CODVEND: 0,
            TIPO: '',
            NOMEEND: '',
            NUMEND: 0,
            COMPLEMENTO: '',
            NOMEBAI: '',
            NOMECID: '',
            CEP: '',
            UF: '',
            PAISEND: '',
            TELEFONE: '',
            EMAIL: '',
            IDENTINSCESTAD: '',
            CGCCPF: '',
            ATIVO: '',
            LIMCRED: 0,
            CLIENTE: '',
            FORNECEDOR: '',
            VENDEDOR: '',
            TRANSPORTADORA: '',
            BLOQUEAR: '',
            ADSITUACAOPORTAL: '',
        };
    }

    emitirClienteSelecionado(cliente: Parceiro) {
        this.parceiroCliente = { ...cliente };
        this.pesquisaCnpj(this.parceiroCliente.CGCCPF);
    }

    // Função para permitir inserir apenas números na inscrição estadual
    inputInscricaoEstadual() {
        let ie: string;

        ie = this.formCadCli.controls.inscEstadual.value;

        ie = ie.replace(/[^0-9]/g, '');

        this.formCadCli.controls.inscEstadual.setValue(ie);
    }
}
