import {
    ApplicationRef,
    ComponentFactoryResolver,
    ComponentRef,
    Directive,
    ElementRef,
    EmbeddedViewRef,
    HostListener,
    Injector,
    Input,
} from '@angular/core';
import { ControleEmbarqueToolTipComponent } from './controle-embarque-tool-tip.component';

@Directive({
    selector: '[tooltip]',
})
export class ControleEmbarqueToolTipDirective {
    private showTimeout?: number;
    private hideTimeout?: number;

    @Input() tooltip = '';
    @Input() showDelay = 0;
    @Input() hideDelay = 0;

    private componentRef: ComponentRef<any> = null;

    constructor(
        private elementRef: ElementRef,
        private appRef: ApplicationRef,
        private componentFactoryResolver: ComponentFactoryResolver,
        private injector: Injector
    ) {}

    @HostListener('mouseenter')
    onMouseEnter(): void {
        if (this.componentRef === null) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ControleEmbarqueToolTipComponent);
            this.componentRef = componentFactory.create(this.injector);
            this.appRef.attachView(this.componentRef.hostView);
            const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
            document.body.appendChild(domElem);
            this.setTooltipComponentProperties();
            this.showTimeout = window.setTimeout(this.showTooltip.bind(this), this.showDelay);
        }
    }

    @HostListener('mouseleave')
    onMouseLeave(): void {
        this.hideTimeout = window.setTimeout(this.destroy.bind(this), this.hideDelay);
        this.destroy();
    }

    ngOnDestroy(): void {
        this.destroy();
    }

    destroy(): void {
        if (this.componentRef !== null) {
            window.clearTimeout(this.showTimeout);
            this.appRef.detachView(this.componentRef.hostView);
            this.componentRef.destroy();
            this.componentRef = null;
        }
    }

    setTooltipComponentProperties() {
        if (this.componentRef !== null) {
            this.componentRef.instance.tooltip = this.tooltip;
            const { left, right, top } = this.elementRef.nativeElement.getBoundingClientRect();
            this.componentRef.instance.left = (right - left) / 2 + left;
            this.componentRef.instance.top = top;
        }
    }

    private showTooltip() {
        if (this.componentRef !== null) {
            this.componentRef.instance.visible = true;
        }
    }
}
