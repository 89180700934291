import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { AutocompleteTransportadoraComponent } from './autocomplete-transportadora.component';

@NgModule({
    declarations: [AutocompleteTransportadoraComponent],
    imports: [CommonModule, FormsModule, MatAutocompleteModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule],
    exports: [AutocompleteTransportadoraComponent],
})
export class AutocompleteTransportadoraModule {}
