import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ParcelasTipoNegociacao } from 'src/app/global-interfaces/tipo-negociacao-interface';
import { PortalServiceService } from '../../services/portal-service.service';

@Component({
  selector: 'app-criar-pedido-cond-pagto',
  templateUrl: './criar-pedido-cond-pagto.component.html',
  styleUrls: ['./criar-pedido-cond-pagto.component.scss']
})
export class CriarPedidoCondPagtoComponent implements OnInit {
  // Vetor que vai armazenar todas as parcelas
  @Input() parcelasTipoNegociacao: ParcelasTipoNegociacao[];

  // Objeto emissor
  @Output() parcelasTipoNegociacaoChange = new EventEmitter<ParcelasTipoNegociacao[]>();

  // Variáveis que armazenarão os dados inseridos nos inputs
  diasPrimeiraParcela: number;
  qtdParcelas: number;
  intervaloDias: number;

  // Variável auxiliar para somar as porcentagens de cada parcela, tendo que resultar em 100% sempre
  porcentagemTotal: number;

  constructor(private portalService: PortalServiceService) { }

  ngOnInit() {
    this.parcelasTipoNegociacao = [];
    this.porcentagemTotal = 0;

    this.diasPrimeiraParcela = null;
    this.qtdParcelas = null;
    this.intervaloDias = null;
  }

  // Método disparado ao clicar em "Gerar parcelas"
  gerarCondicaoPagamento() {
    if (this.diasPrimeiraParcela === null || this.qtdParcelas === null || this.intervaloDias === null) {
      this.portalService.openSnackbar('Preencha os campos da condição de pagamento!');
    } else if (this.qtdParcelas < 1 || this.intervaloDias < 1) {
      this.portalService.openSnackbar('A quantidade de parcelas precisa ser 1 ou mais!');
    } else {
      // Zerando as variáveis, caso uma condição tenha sido criada antes
      this.parcelasTipoNegociacao = [];
      this.porcentagemTotal = 0;

      for (let x = 0; x < this.qtdParcelas; x++) {
        const parcela: ParcelasTipoNegociacao = {
          SEQUENCIA: x + 1,
          PRAZO: x === 0 ? this.diasPrimeiraParcela : x * this.intervaloDias + this.diasPrimeiraParcela,
          PERCENTUAL: parseFloat((100 / this.qtdParcelas).toFixed(2))
        };

        this.porcentagemTotal += parcela.PERCENTUAL;

        this.parcelasTipoNegociacao.push(parcela);
      }

      if (this.porcentagemTotal < 100 || this.porcentagemTotal > 100) {
        this.parcelasTipoNegociacao[0].PERCENTUAL =
        parseFloat((100 - this.porcentagemTotal + this.parcelasTipoNegociacao[0].PERCENTUAL).toFixed(2));


        this.porcentagemTotal = 0;
        this.parcelasTipoNegociacao.map(parcela => this.porcentagemTotal += parcela.PERCENTUAL);
      }

      this.parcelasTipoNegociacaoChange.emit(this.parcelasTipoNegociacao);
    }
  }

  // Método que edita uma parcela
  editarCondicaoPagamento(index: number) {
    const novoDia = parseInt(prompt('Informe o novo dia da parcela'), 10);

    // Caso o usuário não informe nada ou clique em cancelar
    if (!(novoDia >= 0)) {
      this.portalService.openSnackbar('Informe o novo dia da parcela');
    } else {
      // Caso o usuário informe número negativo
      if (novoDia < 0) {
        this.portalService.openSnackbar('Valores negativos não são permitidos');
      } else if (index === 0) {
        // Caso o usuário esteja editando a primeira parcela e informe um dia igual ou superior ao da segunda
        if (novoDia >= this.parcelasTipoNegociacao[index + 1].PRAZO) {
          this.portalService.openSnackbar(`Insira um dia menor que ${this.parcelasTipoNegociacao[index + 1].PRAZO}`);
        } else {
          this.parcelasTipoNegociacao[index].PRAZO = novoDia;
        }
      } else if (index === this.parcelasTipoNegociacao.length - 1) {
        // Caso o usuário esteja editando a última parcela e informe um dia igual ou inferior ao da penúltima
        if (novoDia <= this.parcelasTipoNegociacao[index - 1].PRAZO) {
          this.portalService.openSnackbar(`Insira um dia maior que ${this.parcelasTipoNegociacao[index - 1].PRAZO}`);
        } else {
          this.parcelasTipoNegociacao[index].PRAZO = novoDia;
        }
      } else {
        // Caso o usuário esteja editando uma parcela do meio e informe um valor igual ou inferior/superior ao da parcela anterior/posterior
        if (novoDia <= this.parcelasTipoNegociacao[index - 1].PRAZO || novoDia >= this.parcelasTipoNegociacao[index + 1].PRAZO) {
          this.portalService.openSnackbar(
            `Insira um dia entre ${this.parcelasTipoNegociacao[index - 1].PRAZO} e ${this.parcelasTipoNegociacao[index + 1].PRAZO}`
          );
        } else {
          this.parcelasTipoNegociacao[index].PRAZO = novoDia;
          this.parcelasTipoNegociacaoChange.emit(this.parcelasTipoNegociacao);
        }
      }
    }
  }

  // Método que apaga a condição de pagamento criada
  apagarCondicaoPagamento() {
    this.parcelasTipoNegociacao = [];
    this.diasPrimeiraParcela = null;
    this.qtdParcelas = null;
    this.intervaloDias = null;

    this.parcelasTipoNegociacaoChange.emit(this.parcelasTipoNegociacao);
  }

}
