import { Overlay } from '@angular/cdk/overlay';
import { PortalJpaService } from 'src/app/services/portal-jpa.service';
import { SpinnerComponent } from 'src/app/global-components/spinner/spinner.component';
import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { MatDialog, MatSnackBar, MatTableDataSource } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { Separador } from './local-interfaces/separador-interface';
import { PedidosSeparar } from './local-interfaces/pedidos-separar-interface.component';
import { BottomSheetComponent } from 'src/app/global-components/bottom-sheet/bottom-sheet.component';
import { MatBottomSheet } from '@angular/material';
import { Router } from '@angular/router';
import { DialogBasicComponent } from '../global-components/dialog-basic/dialog-basic.component';

@Component({
    selector: 'app-definir-separador',
    templateUrl: './definir-separador.component.html',
    styleUrls: ['./definir-separador.component.scss'],
})
export class DefinirSeparadorComponent implements OnInit {
    constructor(
        public overlay: Overlay,
        private portalJpaService: PortalJpaService,
        public viewContainerRef: ViewContainerRef,
        private snackBar: MatSnackBar,
        private bottomSheet: MatBottomSheet,
        private router: Router,
        private dialog: MatDialog
    ) {}

    ngOnInit() {
        this.atualizar();
    }

    // Array com os dados da requisição
    pedidos: PedidosSeparar[] = [];
    separadores: Separador[] = [];

    // Colunas que serão exibidas na tabela
    tabelaPedidos = ['PROPOSTA', 'RAZAOSOCIAL', 'DHLIB', 'NOMEUSU', 'select'];
    tabelaSeparadores = ['select', 'CODFUNC', 'NOMEOPE', 'QTDPED'];

    // Data Source das mat-table
    dataSourcePedido = new MatTableDataSource<PedidosSeparar>();
    dataSourceSeparador = new MatTableDataSource<Separador>();

    // Variáveis para a checkbox das tabelas
    selectionPedido = new SelectionModel<PedidosSeparar>(true, []);
    selectionSeparador = new SelectionModel<Separador>(false, []);

    // Variáveis que vão conter os dados das linhas selecionadas pela checkbox
    linhaPedidos: PedidosSeparar[] = [];
    linhaSeparador: Separador;

    // Variável que vai desabilitar o botão para impossibilitar de clicar duas vezes
    desabilitaEnvio = false;

    // Variáveis para exibir os pedidos Em separação e Liberado Separar
    liberado: number = 0;
    emSeparacao: number = 0;

    // Método para abrir snackbar
    openSnackbar(message: string) {
        this.snackBar.open(message, 'Fechar', {
            horizontalPosition: 'end',
            verticalPosition: 'top',
            duration: 5000,
        });
    }

    // Atualiza os dados das duas tabelas
    atualizar() {
        // Mostrando o spinner na tela
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

        // Limpando as variáveis
        this.linhaPedidos = [];
        this.linhaSeparador = null;
        this.liberado = 0;
        this.emSeparacao = 0;

        // Preenchendo a tabela Separadores
        this.portalJpaService.getSeparadoresOperacional().subscribe(
            (res: any) => {
                if (res.opeSegId == 0) {
                    this.openBottomSheet('Vá até a tela Operador Coletor Eurotextil e faça o cadastro/correção do usuário!');
                    overlayRef.dispose();
                } else {
                    this.separadores = res.response;
                    this.dataSourceSeparador.data = this.separadores;
                    this.portalJpaService.getPedidosSeparar().subscribe(
                        (res: any) => {
                            this.pedidos = res.response;
                            const tabela: PedidosSeparar[] = [];
                            for (const pedido of this.pedidos) {
                                if (pedido.STATUS === 'EM SEPARACAO') {
                                    this.emSeparacao += 1;
                                }

                                if (pedido.STATUS === 'LIBERADO SEPARAR') {
                                    this.liberado += 1;
                                }

                                const dateString = pedido.DHLIB.toString();
                                const dateParts = dateString.match(/(\d+)/g); // Extrai os componentes numéricos da data

                                const day = parseInt(dateParts[0], 10);
                                const month = parseInt(dateParts[1], 10) - 1; // Subtrai 1 para o mês
                                const year = parseInt(dateParts[2], 10);
                                const hours = parseInt(dateParts[3], 10);
                                const minutes = parseInt(dateParts[4], 10);
                                const seconds = parseInt(dateParts[5], 10);

                                const date = new Date(year, month, day, hours, minutes, seconds);
                                pedido.DHLIB = date;

                                // if (!pedido.NOMEUSU || pedido.NOMEUSU.trim() == '' || pedido.STATUS === 'LIBERADO CONFERIR') {
                                tabela.push(pedido);
                                // }
                            }

                            // this.pedidos.forEach(pedido => {
                            //     if (pedido.NOMEUSU && pedido.NOMEUSU.trim() != '') {
                            //         tabela.push(pedido);
                            //     }
                            // });

                            this.dataSourcePedido.data = tabela;
                            overlayRef.dispose();
                        },
                        erro => {
                            console.log(erro);
                        }
                    );
                }
            },
            erro => {
                overlayRef.dispose();
                console.log(erro);
            }
        );
    }

    // Métodos para as checkbox da tabela de pedidos
    checkboxLabelPedido(row?: PedidosSeparar): string {
        this.toggleRowSelectionPedido(row);
        return `${this.selectionPedido.isSelected(row) ? 'deselect' : 'select'}`;
    }

    toggleRowSelectionPedido(row: PedidosSeparar) {
        const index = this.linhaPedidos.indexOf(row);
        if (this.selectionPedido.isSelected(row)) {
            if (index === -1) {
                this.linhaPedidos.push(row);
            }
        } else {
            if (index !== -1) {
                this.linhaPedidos.splice(index, 1);
            }
        }
    }

    // Método para as checkbox da tabela de separador
    guardarSeparador(row: Separador) {
        if (!this.selectionSeparador.isSelected(row)) {
            this.selectionSeparador.toggle(row);
            this.linhaSeparador = row;
        }
    }

    // Método para atribuir os pedidos ao separador
    atribuir() {
        // Desabilitando o botão Atribuir
        this.desabilitaEnvio = true;

        // Verificando se alguma das checkbox não foi marcada
        if (!this.linhaSeparador || this.linhaPedidos.length == 0) {
            this.openSnackbar('Preencha pelo menos um pedido e um separador!');
            this.desabilitaEnvio = false;
            return;
        }

        // Mostrando o spinner na tela
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

        // Preenchendo o array com o que vai ser mandado para a requisição
        let listaPed = [];
        this.linhaPedidos.forEach((pedido: PedidosSeparar) => {
            let obj = { opeSegId: this.linhaSeparador.OPESEGID, codFunc: this.linhaSeparador.CODFUNC, nuNota: pedido.PEDIDO };
            listaPed.push(obj);
        });

        // Método da requisição que por enquanto leva o parâmetro 0 para o "operador" no back-end
        this.portalJpaService.atribuirPedido(0, listaPed).subscribe(
            () => {
                // Recarregando a pagina
                location.reload();
                // Retirando o spinner da tela
                overlayRef.dispose();
            },
            erro => {
                console.log(erro);
                overlayRef.dispose();
                this.openSnackbar('Erro interno, contate a TI!');
                this.desabilitaEnvio = false;
            }
        );
    }

    finalizaSeparacao() {
        // Desabilitando o botão finalizar
        this.desabilitaEnvio = true;

        // Verificando se alguma das checkbox não foi marcada
        if (this.linhaPedidos.length == 0) {
            this.openSnackbar('Marque pelo menos um pedido!');
            this.desabilitaEnvio = false;
            return;
        }

        // Verificando se foi marcado mais de um pedido
        if (this.linhaPedidos.length > 1) {
            this.openSnackbar('Marque apenas um pedido!');
            this.desabilitaEnvio = false;
            return;
        }

        if (this.linhaPedidos[0].STATUS === 'LIBERADO CONFERIR') {
            this.openSnackbar('Separação já finalizada!');
            this.desabilitaEnvio = false;
            return;
        }

        if (this.linhaPedidos[0].STATUS !== 'EM SEPARACAO') {
            this.openSnackbar('Pedido não apto a finalizar separação!');
            this.desabilitaEnvio = false;
            return;
        }

        // Pop-up com a mensagem na tela
        this.dialog
            .open(DialogBasicComponent, {
                width: '400px',
                data: {
                    titulo: 'Confirmar Ação',
                    linhas: ['*** Atenção ***', `Deseja finalizar a separação do pedido ${this.linhaPedidos[0].PEDIDO}?`],
                    botoes: [
                        { texto: 'Sim', retorno: true, color: 'primary' },
                        { texto: 'Não', retorno: false, color: 'accent' },
                    ],
                },
            })
            .afterClosed()
            .subscribe((res: any) => {
                if (res) {
                    // Mostrando o spinner na tela
                    const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

                    // Método da requisição que por enquanto leva o parâmetro 0 para o "operador" no back-end
                    this.portalJpaService.finalizaSeparacaoPed(this.linhaPedidos[0].PEDIDO).subscribe(
                        (res: any) => {
                            this.openSnackbar('Separação finalizada!');
                            if (res.serviceResponse === 'OK') {
                                setTimeout(() => {
                                    location.reload();
                                }, 0.5 * 1000); // 0.5 segundos
                            }

                            //this.desabilitaEnvio = false;
                            this.linhaPedidos = [];

                            // Retirando o spinner da tela
                            overlayRef.dispose();
                        },
                        erro => {
                            console.log(erro);
                            overlayRef.dispose();
                            this.openSnackbar('Erro interno, contate a TI!');
                            this.desabilitaEnvio = false;
                        }
                    );
                } else {
                    this.desabilitaEnvio = false;
                }
            });
    }

    // Método para filtrar os pedidos pelo input
    filtrarPedidos(filterValue: string) {
        this.dataSourcePedido.filter = filterValue.trim().toLowerCase();
    }

    // Método para filtrar os separadores pelo input
    filtrarSeparadores(filterValue: string) {
        this.dataSourceSeparador.filter = filterValue.trim().toLowerCase();
    }

    openBottomSheet(mensagem: string) {
        this.bottomSheet
            .open(BottomSheetComponent, {
                data: {
                    param: 'separador',
                    mensagem: {
                        message: mensagem,
                        titulo: 'Usuário do portal não cadastrado ou não preenchido corretamente!',
                    },
                },
                disableClose: true,
                panelClass: 'bottom-sheet',
            })
            .afterDismissed()
            .subscribe(
                valor => {
                    this.router.navigateByUrl('/');
                },
                erro => {
                    console.log('error ', erro);
                }
            );
    }

    retornaClasse(pedido: PedidosSeparar) {
        // Obtém a data atual
        const dataAtual = new Date();

        // Obtém a data do dia anterior
        const dataAnterior = new Date(dataAtual);
        dataAnterior.setDate(dataAtual.getDate() - 1);

        // Obtém a data do anteontem
        const anteontem = new Date(dataAtual);
        anteontem.setDate(dataAtual.getDate() - 2);

        // Verifica se a data é do dia anterior
        if (
            pedido.DHLIB.getDate() === dataAnterior.getDate() &&
            pedido.DHLIB.getMonth() === dataAnterior.getMonth() &&
            pedido.DHLIB.getFullYear() === dataAnterior.getFullYear()
        ) {
            return 'bk-yellow';
        }

        // Verifica se a data é anterior ao dia anterior
        if (pedido.DHLIB < dataAnterior) {
            return 'bk-red';
        }

        // Se não for nenhum dos casos acima, não faz nada
        return null;
    }
}
