import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Component, ElementRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { BottomSheetComponent } from '../global-components/bottom-sheet/bottom-sheet.component';
import { SpinnerComponent } from '../global-components/spinner/spinner.component';
import { Parceiro } from '../global-interfaces/parceiro-interface';
import { PortalServiceService } from '../services/portal-service.service';
import { SankhyaServiceService } from '../services/sankhya-service.service';
import { NotasFiscais, ProdutosNotaFiscal } from './local-interfaces/produtos-nota-fiscal-interface';
import { HttpClient } from '@angular/common/http';
import { AutenticacaoService } from '../services/autenticacao.service';

@Component({
    selector: 'app-devolucao-cadastro',
    templateUrl: './devolucao-cadastro.component.html',
    styleUrls: ['./devolucao-cadastro.component.scss'],
})
export class DevolucaoCadastroComponent implements OnInit {
    constructor(
        private sankhyaService: SankhyaServiceService,
        private portalService: PortalServiceService,
        private bottomSheet: MatBottomSheet,
        public overlay: Overlay,
        public viewContainerRef: ViewContainerRef,
        private snackBar: MatSnackBar,
        public _d: DomSanitizer,
        private http: HttpClient,
        private auth: AutenticacaoService
    ) {}

    ngOnInit() {
        this.buscaMotivos();
        this.http.get('assets/documento.png', { responseType: 'blob' }).subscribe(img => {
            this.imgDoc = img;
        });
        this.http.get('assets/video.png', { responseType: 'blob' }).subscribe(img => {
            this.imgVid = img;
        });
    }

    formCadDev = new FormGroup({
        codParc: new FormControl(),
        razaoSocial: new FormControl(),
        cnpj: new FormControl(),
        codVend: new FormControl(),
        apelidoRep: new FormControl(),
        numNota: new FormControl(),
        dtEmissao: new FormControl(),
        vlrNota: new FormControl(),
        tipoDev: new FormControl(),
        descSug: new FormControl(),
        percDesc: new FormControl(),
        motivoDev: new FormControl(),
        codMotivo: new FormControl(),
        obsDev: new FormControl(),
        qtdPecas: new FormControl(),
        remessa: new FormControl(),
        codParcRem: new FormControl(),
        razaoSocialRem: new FormControl(),
        nuNota: new FormControl(),
        codEmp: new FormControl(),
        itens: new FormArray([]),
    });

    isChecked: boolean = false;
    parceiro: Parceiro = this.getParceiroVazio();
    motivosDev: any[];
    notas: NotasFiscais[];
    produtos: ProdutosNotaFiscal[] = [];
    itens: FormArray;
    tipoDev: string;
    arquivoAnexo = [];
    desabilitaEnvio: boolean = false;
    imgsrc: any = [];
    checkTodos: boolean = false;
    imgDoc: any;
    imgVid: any;
    desc: boolean;

    codRep: number = this.auth.getCodRep();

    buscaMotivos() {
        this.sankhyaService.getMotivosDevolucao().subscribe(
            (retorno: any) => {
                if (retorno.serviceResponse === 'OK') {
                    this.motivosDev = retorno.motivosDevolucao;
                } else {
                    this.bottomSheet.open(BottomSheetComponent, {
                        data: {
                            param: 'motivosDevolucao',
                            mensagem: retorno,
                        },
                        panelClass: 'bottom-sheet',
                    });
                }
            },
            error => {
                this.bottomSheet.open(BottomSheetComponent, {
                    data: {
                        param: 'motivosDevolucao',
                        mensagem: error,
                    },
                    panelClass: 'bottom-sheet',
                });
            }
        );
    }

    inserirCliente() {
        this.produtos = [];

        // Mostrando o spinner na tela
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

        this.sankhyaService.getInfoDevolucao(this.parceiro.CODVEND, this.parceiro.CODPARC).subscribe(
            (retorno: any) => {
                if (retorno.serviceResponse === 'OK') {
                    this.notas = retorno.notasFiscais;
                    this.formCadDev.controls.apelidoRep.setValue(retorno.representante.APELIDO);
                    this.formCadDev.controls.codParc.setValue(this.parceiro.CODPARC);
                    this.formCadDev.controls.razaoSocial.setValue(this.parceiro.RAZAOSOCIAL);
                    this.formCadDev.controls.codVend.setValue(this.parceiro.CODVEND);
                    this.formCadDev.controls.cnpj.setValue(
                        this.parceiro.CGCCPF.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
                    );
                } else {
                    this.bottomSheet.open(BottomSheetComponent, {
                        data: {
                            param: 'cadastroDevolucaoInfo',
                            mensagem: retorno,
                        },
                        panelClass: 'bottom-sheet',
                    });
                }

                // Removendo o spinner da tela
                overlayRef.dispose();
            },
            error => {
                this.bottomSheet.open(BottomSheetComponent, {
                    data: {
                        param: 'cadastroDevolucaoInfo',
                        mensagem: error,
                    },
                    panelClass: 'bottom-sheet',
                });

                // Removendo o spinner da tela
                overlayRef.dispose();
            }
        );
    }

    selecionarPedido(event: MatSelectChange) {
        // Mostrando o spinner na tela
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

        this.sankhyaService.getProdutosNotaFiscal(event.value.NUMNOTA, event.value.CODEMP, event.value.NUNOTAPEDIDO).subscribe(
            (retorno: any) => {
                if (retorno.serviceResponse === 'OK') {
                    this.produtos = retorno.produtosNotaFiscal;

                    this.formCadDev.controls.dtEmissao.setValue(event.value.DTNEG);
                    this.formCadDev.controls.vlrNota.setValue(event.value.VLRNOTA);
                    this.formCadDev.controls.numNota.setValue(event.value.NUMNOTA);
                    this.formCadDev.controls.remessa.setValue(event.value.REMESSA);
                    this.formCadDev.controls.codParcRem.setValue(event.value.CODPARCREM);
                    this.formCadDev.controls.razaoSocialRem.setValue(event.value.RAZAOSOCIALREM);
                    this.formCadDev.controls.codEmp.setValue(event.value.CODEMP);
                    this.formCadDev.controls.nuNota.setValue(event.value.NUNOTA);
                } else {
                    this.bottomSheet.open(BottomSheetComponent, {
                        data: {
                            param: 'cadastroDevolucaoItens',
                            mensagem: retorno,
                        },
                        panelClass: 'bottom-sheet',
                    });
                }

                // Removendo o spinner da tela
                overlayRef.dispose();
            },
            error => {
                this.bottomSheet.open(BottomSheetComponent, {
                    data: {
                        param: 'cadastroDevolucaoItens',
                        mensagem: error,
                    },
                    panelClass: 'bottom-sheet',
                });

                // Removendo o spinner da tela
                overlayRef.dispose();
            }
        );
    }

    verificaTipoDev(event: any) {
        this.checkTodos = false;
        this.desc = false;

        if (!this.formCadDev.controls.numNota.value) {
            this.portalService.openSnackbar('Selecione a nota fiscal!');
            this.formCadDev.controls.tipoDev.setValue(null);
            return false;
        }

        switch (event.value) {
            case 'DEV':
                this.produtos.map(produto => {
                    produto.SELECTED = false;
                    produto.QTDDEV = 0;
                });
                this.formCadDev.controls.descSug.setValue(null);
                this.formCadDev.controls.percDesc.setValue(null);
                this.formCadDev.controls['percDesc'].disable();
                break;

            case 'DES':
                this.produtos.map(produto => {
                    produto.SELECTED = false;
                    produto.QTDDEV = 0;
                });
                this.formCadDev.controls['percDesc'].enable();
                this.formCadDev.controls.descSug.setValue(0);
                this.formCadDev.controls.percDesc.setValue(0);
                break;

            case 'SIN':
                this.produtos.map(produto => {
                    produto.SELECTED = false;
                    produto.QTDDEV = 0;
                });
                this.formCadDev.controls.descSug.setValue(null);
                this.formCadDev.controls.percDesc.setValue(null);
                this.formCadDev.controls['percDesc'].disable();
                break;

            default:
                break;
        }
    }

    selecionaProduto(i: number, event: any) {
        if (!event.checked) {
            this.produtos[i].QTDDEV = 0;
            this.checkTodos = false;
        }
    }

    selecionarTodos(event: any) {
        if (event.checked) {
            this.produtos.forEach(produto => {
                produto.SELECTED = true;
                produto.QTDDEV = produto.MEDIDA;
            });
        } else {
            this.produtos.forEach(produto => {
                produto.SELECTED = false;
                produto.QTDDEV = 0;
            });
        }
    }

    recarregar(event) {
        if (!event.checked) {
            location.reload();
        }
    }

    enviarCadastro(event: any) {
        event.preventDefault();

        if (!this.formCadDev.controls.codMotivo.value) {
            this.openSnackbar('O motivo da devolução deve ser selecionado!');
            return false;
        }

        this.formCadDev.controls.descSug.setValue(0);

        this.produtos.forEach(produto => {
            if (produto.QTDDEV > 0) {
                const item = new FormGroup({
                    codProd: new FormControl(produto.CODPROD),
                    descrProd: new FormControl(produto.DESCRPROD),
                    controle: new FormControl(produto.CONTROLE),
                    medida: new FormControl(produto.MEDIDA),
                    qtdDev: new FormControl(produto.QTDDEV),
                    seqRolo: new FormControl(produto.SEQROLO),
                    vlrUnit: new FormControl(produto.VLRUNIT),
                    vlrTot: new FormControl(produto.VLRTOT),
                    vlrTotDev: new FormControl((produto.QTDDEV * produto.VLRUNIT).toFixed(2)),
                    codVol: new FormControl(produto.CODVOL),
                });

                (this.formCadDev.controls.itens as FormArray).push(item);
            }
        });

        this.itens = this.formCadDev.controls.itens as FormArray;

        if (this.itens.length < 1) {
            this.openSnackbar('Nenhum produto selecionado!');
            return false;
        }

        this.formCadDev.controls.qtdPecas.setValue(this.itens.length);

        this.desabilitaEnvio = true;

        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

        this.sankhyaService.inserirCadDev(this.formCadDev.value, this.arquivoAnexo).subscribe(
            res => {
                if (res.serviceResponse === 'ERROR') {
                    this.openSnackbar('Falha no cadastro. Tente novamente!');
                    overlayRef.dispose();
                } else {
                    this.openSnackbar('Cadastro enviado com sucesso!');

                    overlayRef.dispose();
                    setTimeout(() => {
                        location.reload();
                    }, 3000);
                }
            },
            erro => {
                this.openSnackbar('Falha no envio do Cadastro!');
                console.log(erro);

                overlayRef.dispose();
                setTimeout(() => {
                    location.reload();
                }, 4000);
            }
        );
    }

    openSnackbar(message: string) {
        this.snackBar.open(message, 'Fechar', {
            horizontalPosition: 'end',
            verticalPosition: 'top',
            duration: 5000,
        });
    }

    onFileSelected(event: any) {
        event.preventDefault();

        if (this.arquivoAnexo.length >= 4) {
            this.openSnackbar('O limite de 4 anexos foi atingido!');
            return false;
        }

        if (!event.srcElement.files[0]) {
            return false;
        }

        let img: any;
        let anexo = event.srcElement.files[0];

        if (anexo && anexo.size > 0) {
            if (anexo.size < 5000000) {
                if (anexo.type.includes('image/')) {
                    img = event.srcElement.files[0];
                } else if (anexo.type.includes('video/')) {
                    img = this.imgVid;
                } else {
                    img = this.imgDoc;
                }

                this.arquivoAnexo.push(anexo);
                this.imgsrc.push(window.URL.createObjectURL(img));
            } else {
                this.openSnackbar('Arquivo não pode ser maior que 5MB!');
            }
        }
    }

    excluirAnexo(event: any, i: number) {
        event.preventDefault();

        this.arquivoAnexo.splice(i, 1);
        this.imgsrc.splice(i, 1);
    }

    selecionarMotivo(event: any) {
        this.formCadDev.controls.codMotivo.setValue(event.value.CODMOT);
        this.formCadDev.controls.motivoDev.setValue(event.value.DESCMOT);
    }

    validaQtdDev(qtdDev: number, medida: number, i: number) {
        if (qtdDev > medida) {
            this.produtos[i].QTDDEV = medida;
        }
    }

    somarTotal() {
        let array: any[] = [];

        this.produtos.forEach(produto => {
            if (produto.QTDDEV > 0) {
                let soma = produto.QTDDEV * produto.VLRUNIT;
                array.push(soma);
            }
        });

        const resultado = array.reduce((accumulator, current) => {
            return accumulator + current;
        }, 0);

        if (this.formCadDev.controls.tipoDev.value == 'DEV' || this.formCadDev.controls.tipoDev.value == 'SIN') {
            return Number(resultado.toFixed(2));
        } else if (this.formCadDev.controls.tipoDev.value == 'DES') {
            return Number((resultado * ((this.formCadDev.controls.percDesc.value as number) / 100)).toFixed(2));
        }
    }

    private getParceiroVazio(): Parceiro {
        return {
            CODPARC: 0,
            NOMEPARC: '',
            RAZAOSOCIAL: '',
            CODVEND: 0,
            TIPO: '',
            NOMEEND: '',
            NUMEND: 0,
            COMPLEMENTO: '',
            NOMEBAI: '',
            NOMECID: '',
            CEP: '',
            UF: '',
            PAISEND: '',
            TELEFONE: '',
            EMAIL: '',
            IDENTINSCESTAD: '',
            CGCCPF: '',
            ATIVO: '',
            LIMCRED: 0,
            CLIENTE: '',
            FORNECEDOR: '',
            VENDEDOR: '',
            TRANSPORTADORA: '',
            BLOQUEAR: '',
            ADSITUACAOPORTAL: '',
        };
    }

    emitirClienteSelecionado(cliente: Parceiro) {
        if (!cliente || cliente.ATIVO == 'N' || cliente.ADSITUACAOPORTAL == 'BA') {
            this.parceiro = this.getParceiroVazio();
            // if (cliente && (cliente.ATIVO == 'N' || cliente.ADSITUACAOPORTAL == 'BA')) {
            //     //this.portalServices.openSnackbar('Entre em contato com o setor de Cadastro para revisar o cadastro deste cliente.');
            //     console.log('SNK:', cliente.ATIVO, 'ADPORTAL:', cliente.ADSITUACAOPORTAL);
            // }
        } else {
            this.parceiro = { ...cliente };
            this.inserirCliente();
        }
    }

    clearClienteAutocomplete() {
        this.parceiro = this.getParceiroVazio();
    }
}
