import { Overlay } from '@angular/cdk/overlay';
import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { SpinnerComponent } from '../global-components/spinner/spinner.component';
import { PortalJpaService } from '../services/portal-jpa.service';
import { PortalServiceService } from '../services/portal-service.service';
import { PedidosOperacionalFilialEs } from './local-interfaces/pedidos-operacional-filial-es-interface';
import { forkJoin } from 'rxjs';
import { Response } from '../global-interfaces/response';

@Component({
    selector: 'app-pedidos-operacional-filial-es',
    templateUrl: './pedidos-operacional-filial-es.component.html',
    styleUrls: ['./pedidos-operacional-filial-es.component.scss'],
})
export class PedidosOperacionalFilialEsComponent implements OnInit {
    constructor(
        public overlay: Overlay,
        private portalJpaService: PortalJpaService,
        public viewContainerRef: ViewContainerRef,
        private portalServices: PortalServiceService
    ) {}

    ngOnInit() {
        this.atualizar();

        this.atualizacaoAutomatica = setInterval(() => this.atualizar(), 180000);
    }

    ngOnDestroy() {
        clearInterval(this.atualizacaoAutomatica);
    }

    pedidos: PedidosOperacionalFilialEs[];
    liberado: number = 0;
    conferir: number = 0;
    emSeparacao: number = 0;
    atualizacaoAutomatica: any;
    percPedSeparado = 0;
    desabilitaBtn = false;

    atualizar() {
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);
        this.desabilitaBtn = true;
        this.portalServices.openSnackbar('As informações estão sendo atualizadas');

        const obj = {
            primeiro: this.portalJpaService.getPercPedidosSeparados(2),
            segundo: this.portalJpaService.getPedidosOperacional(2),
        };

        forkJoin([obj.primeiro, obj.segundo]).subscribe(
            (res: any) => {
                const res0: Response = res[0];
                if (res0.serviceResponse == 'OK') {
                    this.percPedSeparado = res0.response;
                }

                const res1: Response = res[1];
                if (res1.serviceResponse == 'OK') {
                    this.emSeparacao = 0;
                    this.liberado = 0;
                    this.conferir = 0;

                    this.pedidos = res1.response;

                    this.pedidos.forEach(element => {
                        switch (element.STATUS) {
                            case 'EM SEPARACAO':
                                this.emSeparacao += 1;
                                break;
                            case 'LIBERADO SEPARAR':
                                this.liberado += 1;
                                break;
                            case 'LIBERADO CONFERIR':
                                this.conferir += 1;
                                break;

                            default:
                                break;
                        }
                    });

                    this.pedidos.map((pedido: PedidosOperacionalFilialEs) => {
                        const dateString = pedido.DHLIB.toString();
                        const dateParts = dateString.match(/(\d+)/g); // Extrai os componentes numéricos da data

                        const day = parseInt(dateParts[0], 10);
                        const month = parseInt(dateParts[1], 10) - 1; // Subtrai 1 para o mês
                        const year = parseInt(dateParts[2], 10);
                        const hours = parseInt(dateParts[3], 10);
                        const minutes = parseInt(dateParts[4], 10);
                        const seconds = parseInt(dateParts[5], 10);

                        const date = new Date(year, month, day, hours, minutes, seconds);
                        pedido.DHLIB = date;
                    });

                    this.desabilitaBtn = false;
                }
                overlayRef.dispose();
            },
            erro => {
                overlayRef.dispose();
                console.log(erro);
            }
        );
    }

    retornaClasse(pedido: PedidosOperacionalFilialEs) {
        // Obtém a data atual
        const dataAtual = new Date();

        // Obtém a data do dia anterior
        const dataAnterior = new Date(dataAtual);
        dataAnterior.setDate(dataAtual.getDate() - 1);

        // Obtém a data do anteontem
        const anteontem = new Date(dataAtual);
        anteontem.setDate(dataAtual.getDate() - 2);

        //Verifica se o pedido tem status Liberado conferir
        if (pedido.STATUS === 'LIBERADO CONFERIR') {
            return 'bk-green';
        }

        // Verifica se a data é do dia anterior
        if (
            pedido.DHLIB.getDate() === dataAnterior.getDate() &&
            pedido.DHLIB.getMonth() === dataAnterior.getMonth() &&
            pedido.DHLIB.getFullYear() === dataAnterior.getFullYear()
        ) {
            return 'bk-yellow';
        }

        // Verifica se a data é anterior ao dia anterior
        if (pedido.DHLIB < dataAnterior) {
            return 'bk-red';
        }

        // Se não for nenhum dos casos acima, não faz nada
        return null;
    }

    imprimir(nuNota: number) {
        this.portalServices.imprimirPedido(nuNota, 2);
    }
}
