import { Component, Input, OnInit } from "@angular/core";
import { PortalServiceService } from "../../services/portal-service.service";
import { SankhyaServiceService } from "src/app/services/sankhya-service.service";
import { AutenticacaoService } from "src/app/services/autenticacao.service";

@Component({
    selector: "app-relatorio-romaneio",
    templateUrl: "./relatorio-romaneio.component.html",
    styleUrls: [
        "./relatorio-romaneio.component.scss",
        "../relatorios.component.scss",
    ],
})
export class RelatorioRomaneioComponent implements OnInit {
    constructor(
        private portalService: PortalServiceService,
        private sankhyaService: SankhyaServiceService,
        private auth: AutenticacaoService
        ) {}

    ngOnInit() {}

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.listaEmpresas = this.sankhyaService.listaEmpresas;
            this.nomeEmpresas = this.sankhyaService.nomeEmpresas;
        }, 1500);
    }

    @Input() restUrl: string;

    nuNota: number;
    nuNF: number;
    codEmp: number;

    isNF: boolean = false;

    nomeEmpresas: any;
    listaEmpresas: number[] = [];

    gerarPdf() {
        let nota: number;
        let empresa: number;
        let rep = Number(this.auth.getCodRep());
        let codRep: number = 0;

        if (rep == 0) {
            codRep = -1
        } else {
            codRep = rep
        }

        if (this.isNF) {
            nota = this.nuNF;
            empresa = this.codEmp;
        } else {
            nota = this.nuNota;
            empresa = -1;
        }
        
        this.portalService.imprimirGeral({
            params: {
                PK_NUNOTA: nota,
                CODEMP: empresa,
                CODVEND: codRep,
                NOMEUSU: this.auth.getUserName(),
            },
            url: this.restUrl + ".pdf",
        });
    }

    toggleNF() {
        this.nuNota = null;
        this.codEmp = null;
        this.nuNF = null;
    }

    getNomeEmp(codEmp: number) {
        let ret: any = this.nomeEmpresas.find((emp) => emp.CODEMP === codEmp);
        return ret.NOMEFANTASIA;
    }
}
