import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { concatMap, map } from 'rxjs/operators';
import { Config } from '../global-interfaces/config-interface';
import { ProdutoUnificado } from '../global-interfaces/produto-interface';
import { portal } from '../../assets/config.json';
import { version } from '../../../package.json';
import { AutenticacaoService } from './autenticacao.service';

@Injectable({
    providedIn: 'root',
})
export class PortalServiceService {
    constructor(
        private snackBar: MatSnackBar,
        private http: HttpClient,
        private auth: AutenticacaoService
    ) {}

    config = 'assets/config.json';

    getHttpOptions() {
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                version,
            }),
        };
    }

    getConfigUrl() {
        return this.http.get(this.config);
    }

    openSnackbar(mensagem: string, duracao = 5000, vertical = 'top') {
        this.snackBar.open(mensagem, 'Fechar', {
            verticalPosition: vertical == 'bottom' ? 'bottom' : 'top',
            horizontalPosition: 'end',
            duration: duracao,
        });
    }

    login(usuario: string, senha: string) {
        return this.http.post<{ usuario: string; senha: string }>(
            portal.login.url,
            {
                serviceName: 'login',
                param: [
                    { paramName: 'usuario', paramValue: usuario },
                    { paramName: 'senha', paramValue: senha },
                ],
            },
            this.getHttpOptions()
        );
    }

    trocarSenha(usuario: string, senhaAtual: string, senhaNova: string) {
        return this.http.post(
            portal.trocarSenha.url,
            {
                serviceName: 'trocarSenha',
                param: [
                    { paramName: 'usuario', paramValue: usuario },
                    { paramName: 'senhaAtual', paramValue: senhaAtual },
                    { paramName: 'senhaNova', paramValue: senhaNova },
                ],
            },
            this.getHttpOptions()
        );
    }

    enviaEmailSenha(usuario: string, email: string) {
        return this.http.post<{ usuario: string }>(
            portal.esqueciSenha.url,
            {
                serviceName: 'esqueciSenha',
                param: [
                    { paramName: 'usuario', paramValue: usuario },
                    { paramName: 'email', paramValue: email },
                ],
            },
            this.getHttpOptions()
        );
    }

    refreshSession(refreshToken: string, usuario: string) {
        let headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                version,
                Authorization: 'Bearer ' + localStorage.getItem('token'),
                eurosession: localStorage.getItem('eurosession'),
                'refresh-authorization': localStorage.getItem('refreshToken'),
            }),
        };
        return this.http.post<{ refreshToken: string }>(
            portal.refreshSession.url,
            {
                serviceName: 'refresh-session',
                param: [
                    { paramName: 'usuario', paramValue: usuario },
                    { paramName: 'refresh-authorization', paramValue: refreshToken },
                ],
            },
            headers
        );
    }

    imprimirPedido(nuNota: number, codEmp?: number) {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/pdf').append('Authorization', 'Basic ' + btoa('jasperadmin:jasperadmin'));

        const loadConfig = this.http.get(this.config);
        const imprimir =
            codEmp == 4
                ? loadConfig.pipe(
                      concatMap((res: Config) =>
                          this.http.get(res.jasperServer.url + res.portal.relatorios.impressaoPedidosSC.url + '?NUNOTA=' + nuNota, {
                              headers,
                              responseType: 'blob',
                          })
                      )
                  )
                : codEmp == 2
                ? loadConfig.pipe(
                      concatMap((res: Config) =>
                          this.http.get(res.jasperServer.url + res.portal.relatorios.impressaoMapaDeSeparacao.url + '?NUNOTA=' + nuNota, {
                              headers,
                              responseType: 'blob',
                          })
                      )
                  )
                : loadConfig.pipe(
                      concatMap((res: Config) =>
                          this.http.get(res.jasperServer.url + res.portal.relatorios.impressaoPedidos.url + '?NUNOTA=' + nuNota, {
                              headers,
                              responseType: 'blob',
                          })
                      )
                  );

        imprimir.subscribe(res => {
            const fileUrl = URL.createObjectURL(res);
            window.open(fileUrl, '_blank');
        });
    }

    imprimirRelProgramado(listaProjetos: string) {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/pdf').append('Authorization', 'Basic ' + btoa('jasperadmin:jasperadmin'));

        const loadConfig = this.http.get(this.config);
        const imprimir = loadConfig.pipe(
            concatMap((res: Config) =>
                this.http.get(res.jasperServer.url + res.portal.relatorios.programado.url + '?LISTAPROJETOS=' + listaProjetos, {
                    headers,
                    responseType: 'blob',
                })
            )
        );

        imprimir.subscribe(res => {
            const fileUrl = URL.createObjectURL(res);
            window.open(fileUrl, '_blank');
        });
    }

    imprimirRelProgramadoPercentual(listaProjetos: string) {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/pdf').append('Authorization', 'Basic ' + btoa('jasperadmin:jasperadmin'));

        const loadConfig = this.http.get(this.config);
        const imprimir = loadConfig.pipe(
            concatMap((res: Config) =>
                this.http.get(res.jasperServer.url + res.portal.relatorios.historicoProgramado.url + '?LISTAPROJETOS=' + listaProjetos, {
                    headers,
                    responseType: 'blob',
                })
            )
        );

        imprimir.subscribe(res => {
            const fileUrl = URL.createObjectURL(res);
            window.open(fileUrl, '_blank');
        });
    }

    imprimirRelHistoricoProgramado(listaProjetos: string) {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/pdf').append('Authorization', 'Basic ' + btoa('jasperadmin:jasperadmin'));

        const loadConfig = this.http.get(this.config);
        const imprimir = loadConfig.pipe(
            concatMap((res: Config) =>
                this.http.get(res.jasperServer.url + res.portal.relatorios.historicoProgramado.url + '?LISTAPROJETOS=' + listaProjetos, {
                    headers,
                    responseType: 'blob',
                })
            )
        );

        imprimir.subscribe(res => {
            const fileUrl = URL.createObjectURL(res);
            window.open(fileUrl, '_blank');
        });
    }

    imprimirRelProgramadoResumido(listaProjetos: string) {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/pdf').append('Authorization', 'Basic ' + btoa('jasperadmin:jasperadmin'));

        const loadConfig = this.http.get(this.config);
        const imprimir = loadConfig.pipe(
            concatMap((res: Config) =>
                this.http.get(res.jasperServer.url + res.portal.relatorios.programadoResumido.url + '?LISTAPROJETOS=' + listaProjetos, {
                    headers,
                    responseType: 'blob',
                })
            )
        );

        imprimir.subscribe(res => {
            const fileUrl = URL.createObjectURL(res);
            window.open(fileUrl, '_blank');
        });
    }

    imprimirRelProntaEntrega(listaProdutos: string) {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/pdf').append('Authorization', 'Basic ' + btoa('jasperadmin:jasperadmin'));

        const loadConfig = this.http.get(this.config);
        const imprimir = loadConfig.pipe(
            concatMap((res: Config) =>
                this.http.get(res.jasperServer.url + res.portal.relatorios.prontaEntrega.url + '?LISTAPRODUTOS=' + listaProdutos, {
                    headers,
                    responseType: 'blob',
                })
            )
        );

        imprimir.subscribe(res => {
            const fileUrl = URL.createObjectURL(res);
            window.open(fileUrl, '_blank');
        });
    }

    imprimirRelProntaEntregaResumido(listaProdutos: string) {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/pdf').append('Authorization', 'Basic ' + btoa('jasperadmin:jasperadmin'));
        const loadConfig = this.http.get(this.config);
        const imprimir = loadConfig.pipe(
            concatMap((res: Config) =>
                this.http.get(res.jasperServer.url + res.portal.relatorios.prontaEntregaResumido.url + '?LISTAPRODUTOS=' + listaProdutos, {
                    headers,
                    responseType: 'blob',
                })
            )
        );

        imprimir.subscribe(res => {
            const fileUrl = URL.createObjectURL(res);
            window.open(fileUrl, '_blank');
        });
    }

    imprimirTabelaPreco() {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/pdf').append('Authorization', 'Basic ' + btoa('jasperadmin:jasperadmin'));

        const loadConfig = this.http.get(this.config);
        const imprimir = loadConfig.pipe(
            concatMap((res: Config) =>
                this.http.get(res.jasperServer.url + res.portal.relatorios.tabelaPreco.url, { headers, responseType: 'blob' })
            )
        );

        imprimir.subscribe(res => {
            const fileUrl = URL.createObjectURL(res);
            window.open(fileUrl, '_blank');
        });
    }

    imprimirTabelaPrecoGerencial() {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/pdf').append('Authorization', 'Basic ' + btoa('jasperadmin:jasperadmin'));

        const loadConfig = this.http.get(this.config);
        const imprimir = loadConfig.pipe(
            concatMap((res: Config) =>
                this.http.get(res.jasperServer.url + res.portal.relatorios.tabelaPrecoGerencial.url, { headers, responseType: 'blob' })
            )
        );

        imprimir.subscribe(res => {
            const fileUrl = URL.createObjectURL(res);
            window.open(fileUrl, '_blank');
        });
    }

    imprimirTitulos(param: any) {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/pdf').append('Authorization', 'Basic ' + btoa('jasperadmin:jasperadmin'));
        const codVendIni = param.codRepIni;
        const codVendFim = param.codRepFim;
        const isVencidos = param.isVencidos ? 'S' : 'N';
        const isAvencer = param.isAvencer ? 'S' : 'N';

        const loadConfig = this.http.get(this.config);
        const imprimir = loadConfig.pipe(
            concatMap((res: Config) =>
                this.http.get(
                    res.jasperServer.url +
                        res.portal.relatorios.titulos.url +
                        '?CODVENDINI=' +
                        codVendIni +
                        '&CODVENDFIM=' +
                        codVendFim +
                        '&DTVENCINI=' +
                        param.dataIni +
                        '&DTVENCFIM=' +
                        param.dataFim +
                        '&LISTACLIENTES=' +
                        param.clientes +
                        '&MATRIZ=' +
                        param.geraMatriz +
                        '&QUEBRAREPRE=' +
                        param.quebraRepre +
                        '&USULOGIN=' +
                        this.auth.getUserName() +
                        '&VENCIDO=' +
                        isVencidos +
                        '&AVENCER=' +
                        isAvencer,
                    { headers, responseType: 'blob' }
                )
            )
        );

        imprimir.subscribe(res => {
            const fileUrl = URL.createObjectURL(res);
            window.open(fileUrl, '_blank');
        });
    }

    imprimirTitulosVencidos(param: any) {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/pdf').append('Authorization', 'Basic ' + btoa('jasperadmin:jasperadmin'));
        const codVend = this.auth.getCodRep();

        const loadConfig = this.http.get(this.config);
        const imprimir = loadConfig.pipe(
            concatMap((res: Config) =>
                this.http.get(
                    res.jasperServer.url +
                        res.portal.relatorios.titulosVencidos.url +
                        '?CODVEND=' +
                        codVend +
                        '&DTVENCINI=' +
                        param.dataIni +
                        '&DTVENCFIM=' +
                        param.dataFim +
                        '&LISTACLIENTES=' +
                        param.clientes +
                        '&MATRIZ=' +
                        param.geraMatriz,
                    { headers, responseType: 'blob' }
                )
            )
        );

        imprimir.subscribe(res => {
            const fileUrl = URL.createObjectURL(res);
            window.open(fileUrl, '_blank');
        });
    }

    imprimirTitulosVencer(param: any) {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/pdf').append('Authorization', 'Basic ' + btoa('jasperadmin:jasperadmin'));
        const codVend = this.auth.getCodRep();

        const loadConfig = this.http.get(this.config);
        const imprimir = loadConfig.pipe(
            concatMap((res: Config) =>
                this.http.get(
                    res.jasperServer.url +
                        res.portal.relatorios.titulosVencer.url +
                        '?CODVEND=' +
                        codVend +
                        '&DTVENCINI=' +
                        param.dataIni +
                        '&DTVENCFIM=' +
                        param.dataFim +
                        '&LISTACLIENTES=' +
                        param.clientes +
                        '&MATRIZ=' +
                        param.geraMatriz,
                    { headers, responseType: 'blob' }
                )
            )
        );

        imprimir.subscribe(res => {
            const fileUrl = URL.createObjectURL(res);
            window.open(fileUrl, '_blank');
        });
    }

    imprimirFichaCliente(param: any) {
        let headers = new HttpHeaders();
        headers = headers
            .set('Accept', 'application/pdf')
            .append('Authorization', 'Basic ' + btoa(param.usuarioJasper + ':' + param.senhaJasper));

        const loadConfig = this.http.get(this.config);
        const imprimir = loadConfig.pipe(
            concatMap((res: Config) =>
                this.http.get(
                    res.jasperServer.url +
                        res.portal.relatorios.fichaClientes.url +
                        '?CODPARC=' +
                        param.codParc +
                        '&PARCMATRIZ=' +
                        param.geraMatriz,
                    { headers, responseType: 'blob' }
                )
            )
        );

        return imprimir;

        imprimir.subscribe(res => {
            const fileUrl = URL.createObjectURL(res);
            window.open(fileUrl, '_blank');
        });
    }

    previewProposta(nuNota: number) {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/pdf').append('Authorization', 'Basic ' + btoa('jasperadmin:jasperadmin'));

        const loadConfig = this.http.get(this.config);
        const imprimir = loadConfig.pipe(
            concatMap((res: Config) =>
                this.http.get(res.jasperServer.url + res.portal.relatorios.previewProposta.url + '?NUNOTA=' + nuNota, {
                    headers,
                    responseType: 'blob',
                })
            )
        );

        imprimir.subscribe(res => {
            const fileUrl = URL.createObjectURL(res);
            window.open(fileUrl, '_blank');
        });
    }

    carregaPedidosLegado(tipoBusca: string, limit: number, sessionId: string) {
        const configLoaded = this.http.get(this.config);
        const consultaPedidosLegado = configLoaded.pipe(
            concatMap((res: Config) => {
                return this.http.post<{ tipoBusca: string; limit: number; sessionId: string }>(
                    res.portal.pedidosLegado.buscaPedidos.url,
                    {
                        tipoBusca,
                        limit,
                        sessionId,
                    },
                    this.getHttpOptions()
                );
            })
        );
        return consultaPedidosLegado;
    }

    buscaInfoPedidosLegado(nrPedido: string) {
        const configLoaded = this.http.get(this.config);
        const impressaoPedidosLegado = configLoaded.pipe(
            concatMap((res: Config) => {
                return this.http.post<{ nrPedido: number }>(
                    res.portal.pedidosLegado.imprimirPedidosLegado.url,
                    {
                        nr_pedido: nrPedido,
                    },
                    this.getHttpOptions()
                );
            })
        );

        return impressaoPedidosLegado;
    }

    buscaImagensPE(produtoSelecionado: ProdutoUnificado) {
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        };

        const configLoaded = this.http.get(this.config);
        const buscarImagens = configLoaded.pipe(
            concatMap((res: Config) => {
                return this.http.post<{ tipo: string; produtoSelecionado: ProdutoUnificado }>(
                    res.portal.imagens.buscaImagens.url,
                    { tipo: 'pe', params: { produtos: [produtoSelecionado] } },
                    httpOptions
                );
            })
        );
        return buscarImagens;
    }

    buscaImagensPO(projeto: string, produtos: ProdutoUnificado[]) {
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        };

        const configLoaded = this.http.get(this.config);
        const buscarImagens = configLoaded.pipe(
            concatMap((res: Config) => {
                return this.http.post<{ projeto: string; produtos: ProdutoUnificado[] }>(
                    res.portal.imagens.buscaImagens.url,
                    { tipo: 'po', params: { projeto, produtos } },
                    httpOptions
                );
            })
        );

        return buscarImagens;
    }

    imprimirChequesAberto(param: any) {
        const configLoaded = this.http.get(this.config);
        const headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', 'Basic ' + btoa('jasperadmin:jasperadmin'));
        const chequesAberto = configLoaded.pipe(
            concatMap((res: Config) => {
                return this.http.get(
                    res.jasperServer.url +
                        res.portal.relatorios.chequesAberto.url +
                        '?DTCORTE=' +
                        param.ate +
                        '&LISTACLIENTES=' +
                        param.clientes +
                        '&PREDATADO=' +
                        param.preDatado +
                        '&DEVOLVIDO=' +
                        param.devolvido,
                    { headers, responseType: 'blob' }
                );
            })
        );

        chequesAberto.subscribe(res => {
            const fileUrl = URL.createObjectURL(res);

            window.open(fileUrl, '_blank');
        });
    }

    imprimirTitulosLongoPrazo(param: any) {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/pdf').append('Authorization', 'Basic ' + btoa('jasperadmin:jasperadmin'));
        const codVend = this.auth.getCodRep();
        const usuLogin = this.auth.getUserName();

        const loadConfig = this.http.get(this.config);
        const imprimir = loadConfig.pipe(
            concatMap((res: Config) =>
                this.http.get(
                    res.jasperServer.url +
                        res.portal.relatorios.titulosLongoPrazo.url +
                        '?CODVEND=' +
                        codVend +
                        '&DTVENCINI=' +
                        param.dataIni +
                        '&DTVENCFIM=' +
                        param.dataFim +
                        '&LISTACLIENTES=' +
                        param.clientes +
                        '&MATRIZ=' +
                        param.geraMatriz +
                        '&USULOGIN=' +
                        usuLogin,
                    { headers, responseType: 'blob' }
                )
            )
        );

        imprimir.subscribe(res => {
            const fileUrl = URL.createObjectURL(res);
            window.open(fileUrl, '_blank');
        });
    }

    imprimirRomaneio(nuNota: number) {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/pdf').append('Authorization', 'Basic ' + btoa('jasperadmin:jasperadmin'));

        const loadConfig = this.http.get(this.config);
        const imprimir = loadConfig.pipe(
            concatMap((res: Config) =>
                this.http.get(
                    res.jasperServer.url +
                        res.portal.relatorios.romaneioExpedicao.url +
                        '?PK_NUNOTA=' +
                        nuNota +
                        '&NOMEUSU=' +
                        this.auth.getUserName(),
                    { headers, responseType: 'blob' }
                )
            )
        );

        imprimir.subscribe(res => {
            const fileUrl = URL.createObjectURL(res);
            window.open(fileUrl, '_blank');
        });
    }

    imprimirGeral(bodyRelatorio: any) {
        const jasperUser = bodyRelatorio.params && bodyRelatorio.params.jasperUser ? bodyRelatorio.params.jasperUser : 'jasperadmin';
        const senhaJasper = bodyRelatorio.params && bodyRelatorio.params.senhaJasper ? bodyRelatorio.params.senhaJasper : 'jasperadmin';

        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/pdf').append('Authorization', 'Basic ' + btoa(`${jasperUser}:${senhaJasper}`));
        this.http.get(bodyRelatorio.url, { params: bodyRelatorio.params, headers, responseType: 'blob' }).subscribe(
            (res: any) => {
                const fileUrl = URL.createObjectURL(res);

                if (bodyRelatorio.params && bodyRelatorio.params.REL && bodyRelatorio.params.REL === 'PlanilhaCompras') {
                    var anchor = document.createElement('a');
                    anchor.download = 'Planilha_Compras.xls';
                    anchor.href = fileUrl;
                    anchor.click();
                } else {
                    window.open(fileUrl, '_blank');
                }
            },
            erro => {
                if (erro.status === 401) {
                    this.openSnackbar('Usuario sem permissão para executar o relatório');
                } else {
                    this.openSnackbar('Erro ao executar relatório');
                }
            }
        );
    }

    imprimirDevolucao(devId: number) {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/pdf').append('Authorization', 'Basic ' + btoa('jasperadmin:jasperadmin'));

        const loadConfig = this.http.get(this.config);
        const imprimir = loadConfig.pipe(
            concatMap((res: Config) =>
                this.http.get(res.jasperServer.url + res.portal.relatorios.impressaoDevolucao.url + '?DEVID=' + devId, {
                    headers,
                    responseType: 'blob',
                })
            )
        );

        imprimir.subscribe(res => {
            const fileUrl = URL.createObjectURL(res);
            window.open(fileUrl, '_blank');
        });
    }

    imprimirRomaneioDevolucao(numNota: number, codEmp: number) {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/pdf').append('Authorization', 'Basic ' + btoa('jasperadmin:jasperadmin'));

        const loadConfig = this.http.get(this.config);
        const imprimir = loadConfig.pipe(
            concatMap((res: Config) =>
                this.http.get(
                    res.jasperServer.url +
                        res.portal.relatorios.impressaoRomaneioDevolucao.url +
                        '?PK_NUNOTAFISCAL=' +
                        numNota +
                        '&PK_CODEMP=' +
                        codEmp,
                    { headers, responseType: 'blob' }
                )
            )
        );

        imprimir.subscribe(res => {
            const fileUrl = URL.createObjectURL(res);
            window.open(fileUrl, '_blank');
        });
    }

    imprimirFichaTecnicaProduto(codProd: number, controle: string) {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/pdf').append('Authorization', 'Basic ' + btoa('jasperadmin:jasperadmin'));

        const loadConfig = this.http.get(this.config);
        const imprimir = loadConfig.pipe(
            concatMap((res: Config) =>
                this.http.get(
                    res.jasperServer.url +
                        res.portal.relatorios.impressaoFichaTecnicaProduto.url +
                        '?P_CODPROD=' +
                        codProd +
                        '&P_CONTROLE=' +
                        controle,
                    {
                        headers,
                        responseType: 'blob',
                    }
                )
            )
        );

        imprimir.subscribe(res => {
            const fileUrl = URL.createObjectURL(res);
            console.log(fileUrl);
            window.open(fileUrl, '_blank');
        });
    }
}
