import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material';

@Component({
    selector: 'app-bottom-sheet-regras-desconto',
    templateUrl: './bottom-sheet-regras-desconto.component.html',
    styleUrls: ['./bottom-sheet-regras-desconto.component.scss'],
})
export class BottomSheetRegrasDescontoComponent implements OnInit {
    constructor(public bottomSheetRef: MatBottomSheetRef<any>, @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) {}

    ngOnInit() {
        window.innerWidth <= 768 ? (this.cutWordVariable = 20) : (this.cutWordVariable = 100);
    }

    cutWordVariable: number = 0;
    listaColunas = ['PRODUTO', 'VLRUNITAB', 'VLRUNI', 'DESC'];

    tratarValorNegativo(valor) {
        let changedValue = parseFloat(valor) < 0 ? valor.replace('-', '+').replace('.', ',') : valor.replace('.', ',');
        return changedValue;
    }
}
