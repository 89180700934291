import { Component, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { PortalJpaService } from '../services/portal-jpa.service';
import { Response } from '../global-interfaces/response';
import { PedidoFaturar } from '../global-interfaces/pedido-faturar';
import { finalize } from 'rxjs/operators';
import { Overlay } from '@angular/cdk/overlay';
import { SpinnerComponent } from '../global-components/spinner/spinner.component';

@Component({
    selector: 'app-pedidos-faturar-es',
    templateUrl: './pedidos-faturar-es.component.html',
    styleUrls: ['./pedidos-faturar-es.component.scss'],
})
export class PedidosFaturarEsComponent implements OnInit, OnDestroy {
    constructor(private portalJpaService: PortalJpaService, public overlay: Overlay, public viewContainerRef: ViewContainerRef) {}

    ngOnInit() {
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);
        this.portalJpaService
            .getPedidosFaturar()
            .pipe(
                finalize(() => {
                    this.pedidosExibir = this.pedidosRequest.filter(pedido => pedido.CODEMP === this.indexExibicao);

                    this.intervalExibir = setInterval(this.loopEmpresas.bind(this), 40 * 1000);

                    this.intervalRequest = setInterval(() => {
                        this.getPedidosFaturar();
                    }, 90 * 1000);
                })
            )
            .subscribe(
                (res: Response) => {
                    if (res.serviceResponse === 'OK') {
                        this.pedidosRequest = res.response;
                    }
                    overlayRef.dispose();
                },
                err => {
                    console.log(err);
                    overlayRef.dispose();
                }
            );
    }

    ngOnDestroy() {
        if (this.intervalExibir) {
            clearInterval(this.intervalExibir);
        }
        if (this.intervalRequest) {
            clearInterval(this.intervalRequest);
        }
    }

    intervalExibir: any;
    intervalRequest: any;

    indexExibicao = 1;

    listaEmpresas = ['Matriz', 'Filial ES', 'Filial PE', 'Filial SC'];

    pedidosRequest: PedidoFaturar[] = [];
    pedidosExibir: PedidoFaturar[] = [];

    listaColunas = ['NUNOTA', 'TIPPED', 'RAZAOSOCIAL', 'ESTADO', 'TRANSP', 'ADOBSPED', 'ADDHPEDFECHADO'];
    listaColunasNomes = ['Pedido', 'Tipo', 'Razão Social', 'UF', 'Transportadora', 'Observação', 'Dt/Hr. Fechamento'];

    getPedidosFaturar() {
        this.portalJpaService.getPedidosFaturar().subscribe(
            (res: Response) => {
                if (res.serviceResponse === 'OK') {
                    this.pedidosRequest = res.response;
                }
            },
            err => {
                console.log(err);
            }
        );
    }

    loopEmpresas() {
        this.indexExibicao === 4 ? (this.indexExibicao = 1) : this.indexExibicao++;

        this.pedidosExibir = this.pedidosRequest.filter(pedido => pedido.CODEMP === this.indexExibicao);

        if (this.pedidosExibir.length === 0) {
            this.loopEmpresas.call(this);
        }
    }

    retornaClasse(pedido: PedidoFaturar) {
        let classe = '';

        if (pedido.SUBTIPOVENDA == 7 || pedido.SUBTIPOVENDA == 8 || pedido.SUBTIPOVENDA == 1) {
            classe = 'bk-green';
        }

        return classe;
    }
}
