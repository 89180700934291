export interface PedidosPesquisa {
  NUNOTA: number;             // Número do pedido
  RAZAOSOCIAL: string;        // Nome do cliente
  CGCCPF: string;             // CNPJ
  DTNEG: string;              // Data da negociação
  TIPOPEDIDO: string;         // Tipo do pedido
  STATUS: string;             // Status do pedido
  CODPARC: number;            // Código do parceiro (cliente)
  LIBERACAO: Liberacao[];     // Estágios da liberação do pedido
  OBSERVACAO?: string;        // Texto digitado no campo Observacao da Recusa
  OBSERVACAOPED?: string;     // Observação do Pedido retornada do Backend
  DHLIB?: string;             // Data Aprovacao Credito Financeiro
  NOMEUSU?: string;           // Nome do Usuario Responsavel
  AD_PRIORIDADE?: number;     // Prioridade de separação do pedido 
  TRANSP?: string;            // Nome da transportadora do pedido
  CODEMP?: number;            // Empresa
  AD_REMESSA?: string;        // Pedido Remessa
  EXCEDEDESC?: string;        // Indica se possui item que excede desconto maximo
}

export interface Liberacao {
  EVENTO: number;
  DESCRICAO: string;
  DHSOLICIT: string;
  VLRLIMITE: number;
  VLRATUAL: number;
  OBSERVACAO: string;
  TABELA: string;
  SEQUENCIA: number;
}

export enum TipoAprovacao {
  APROVAR = 'A',
  WILLIAM = 'W',
  PENDENTE = 'P',
  RECUSAR = 'R'
}

export interface Paginas {
  numeroInicial: number;
  numeroFinal: number;
  numeroRegistros: number;
}

export enum RangePaginas {
  numeroInicial = 1,
  numeroRegistros   = 30
}
