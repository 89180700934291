import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgNavigatorShareService } from 'ng-navigator-share';
import { PortalServiceService } from 'src/app/services/portal-service.service';
import { imgSrcToBlob } from 'blob-util';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogBasicComponent } from 'src/app/global-components/dialog-basic/dialog-basic.component';
import { DialogComponent } from 'src/app/global-components/dialog/dialog.component';



@Component({
    selector: 'app-compartilhar-dialog',
    templateUrl: './dialog-compartilhar.component.html',
    styleUrls: ['./dialog-compatilhar.component.scss'],
})
export class DialogCompartilharComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<DialogCompartilharComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private portalService: PortalServiceService,
        private dialog: MatDialog,
    ) {
        this.img_urls = data

        this.ngNavigatorShareService = new NgNavigatorShareService();
    }

    ngOnInit(): void {
        // console.log('Não implementado ngOnInit');
    }

    onNoClick(): void {
        // console.log('Não implementado onNoClick');
    }

    file: any[] = <any>[]

    openDialog(tipo: string, params?: any) {
        const dialogRef = this.dialog.open(DialogComponent, {
            width: '300px',
            data: { tipo, params }
        });

        return dialogRef.afterClosed();
    }
    ngNavigatorShareService: NgNavigatorShareService
    envImg = []
    envDescr = []
    img_urls = []
    id_html


    enviaImagensSelecionadas(image: String, check: boolean, descr: String) {
        if (check) {
            this.envImg.push(image)
            this.envDescr.push(descr.trim())
        } else {
            let index = this.envImg.indexOf(image)
            this.envImg.splice(index, 1)
        }
    }

    async criaBlob() {
        let index = 0;
        for (const element of this.envImg) {
            let tmpBlob = await imgSrcToBlob(element).then(blob => {
                return blob
            })
            this.file.push(new File([tmpBlob], String(this.envDescr[index]) + ".png", { type: tmpBlob.type }));
            index += 1;
        }
    }

    async share() {
        try {
            let nav = navigator
            //@ts-ignore
            await nav.share({
                title: '',
                text: '',
                files: this.file,
            }).catch((error: Error) => {
                if (error.message.includes('not allowed')) {
                    this.dialog.open(DialogBasicComponent, {
                        width: '400px',
                        data: {
                            titulo: 'Ocorreu um erro ao tentar entar compartilhar.',
                            linhas: [
                                'Estamos tentando enviar novamente.'
                            ],
                            botoes: [
                                {
                                    texto: 'Ok',
                                    retorno: true,
                                    color: 'primary'
                                }
                            ]
                        }
                    }).afterClosed().subscribe(async () => {
                        //@ts-ignore
                        await nav.share({
                            title: '',
                            text: '',
                            files: this.file,
                        }).catch(() => {
                            console.log('error')
                        })
                    });
                }
                console.log(error.message)
            })
        } catch (error) {
            console.log(error.message)
            if (error.message.includes('abort')) {
                console.log('Fechou')
            } else {
                this.portalService.openSnackbar('Ao tentar compartilhar ocorreu um erro.');
            }
        }
    }

    async criar() {
        this.file = []
        await this.criaBlob()
        console.log('ENVIANDO ARQUIVO', this.file)
        if (this.file && (this.file.length > 0 && this.file.length <= 10)) {
            await this.share()
        } else if (this.file.length > 10) {
            this.portalService.openSnackbar('Quantidade de imagens selecionadas é muito grande, selecione até 10 imagens para serem compartilhadas.');
        } else if (this.file.length == 0) {
            this.portalService.openSnackbar('Selecione uma imagem para compartilhar.');
        }
    }
    cacelar() {
        this.dialogRef.close()
    }
}
