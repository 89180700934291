import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { Parceiro } from '../global-interfaces/parceiro-interface';
import { TitulosParceiros } from '../global-interfaces/titulos-parceiros-interface';
import { SankhyaServiceService } from '../services/sankhya-service.service';
import { DatePipe } from '@angular/common';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { SpinnerComponent } from '../global-components/spinner/spinner.component';
import { PortalServiceService } from '../services/portal-service.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BottomSheetComponent } from '../global-components/bottom-sheet/bottom-sheet.component';

@Component({
  selector: 'app-segunda-via-boleto',
  templateUrl: './segunda-via-boleto.component.html',
  styleUrls: ['./segunda-via-boleto.component.scss']
})
export class SegundaViaBoletoComponent implements OnInit {

  parceiroCliente: Parceiro;
  coligados: boolean;
  emailCliente: string;
  dataIni: string;
  dataFim: string;
  titulosParceiro: TitulosParceiros[];
  titulosSelecionadosEmail: TitulosParceiros[];
  fxHideTabelaTitulos: boolean[];  

  constructor(
    private sankhyaService: SankhyaServiceService,
    private datePipe: DatePipe,
    public overlay: Overlay,
    public viewContainerRef: ViewContainerRef,
    private portalService: PortalServiceService,
    private bottomSheet: MatBottomSheet
  ) {
    this.inicializarVariavel();
    this.calculaColunasTabela();
  }

  ngOnInit() {
  }

  buscaBoletos() {

    // Um cliente precisa ser selecionado
    if (this.parceiroCliente.CODPARC === 0) {
      this.portalService.openSnackbar('Informe o cliente!');
      return false;
    }

    // Se em algum momento as datas virou null, ela precisa voltar para string vazia;
    if (this.dataIni === null) {
      this.dataIni = '';
    }

    if (this.dataFim === null) {
      this.dataFim = '';
    }

    // A data inicial não pode ser posterior à data final
    let dataIni: any;
    let dataFim: any;

    dataIni = new Date(this.dataIni);
    dataFim = new Date(this.dataFim);

    if (dataIni > dataFim) {
      this.portalService.openSnackbar('O Vencimento Inicial é maior que o Vencimento Final!');
      return false;
    }

    // Formatar data para o padrão brasileiro
    dataIni = this.dataIni ? this.datePipe.transform(this.dataIni, 'dd/MM/yyyy') : '';
    dataFim = this.dataFim ? this.datePipe.transform(this.dataFim, 'dd/MM/yyyy') : '';

    // Instanciando a variável que possui as configurações do spinner
    const config = new OverlayConfig();

    // Atribuindo as features do spinner
    config.positionStrategy = this.overlay.position()
      .global()
      .centerHorizontally()
      .centerVertically();

    // Definindo que o spinner ficará em uma camada acima da aplicação
    config.hasBackdrop = true;

    // Criando a camada com as configurações e atribuindo a uma constante
    const overlayRef = this.overlay.create(config);

    // Mostrando o spinner na tela
    overlayRef.attach(new ComponentPortal(SpinnerComponent, this.viewContainerRef));

    this.sankhyaService.getTitulosCliente(this.parceiroCliente, dataIni, dataFim, this.coligados).subscribe(
      (res: TitulosParceiros[]) => {
        // Percorrer por response e atribuir ENVIAREMAIL para false
        res = res.map(titulo => titulo = {...titulo, ENVIAREMAIL: false});

        this.titulosParceiro = res;
        
        this.calculaColunasTabela();

        // Removendo o spinner da tela
        overlayRef.dispose();
      },

      erro => {

        // Removendo o spinner da tela
        overlayRef.dispose();

        const param = 'segundaViaBoletos';

        this.bottomSheet.open(BottomSheetComponent, {
          data: { param, erro },
          panelClass: 'bottom-sheet'
        });

      }
    );
  }

  enviarEmail() {

    // O e-mail precisa ter um "@"
    if (!this.emailCliente.includes('@')) {
      this.portalService.openSnackbar('O e-mail informado é inválido!');
      return false;
    }

    // Pelo menos um título precisa ser selecionado (ENVIAREMAIL atribuido a true)
    const existeTituloSelecionado = this.titulosParceiro.find(tituloParceiro => tituloParceiro.ENVIAREMAIL);

    // Se nenhum título for selecionado...
    if (!existeTituloSelecionado) {
      this.portalService.openSnackbar('Selecione um título para enviar o e-mail!');
      return false;
    }

    // Percorrer pelos títulos do parceiro e recuperar apenas aqueles onde ENVIAREMAIL === true
    this.titulosSelecionadosEmail = this.titulosParceiro.filter(tituloParceiro => tituloParceiro.ENVIAREMAIL);

    // Instanciando a variável que possui as configurações do spinner
    const config = new OverlayConfig();

    // Atribuindo as features do spinner
    config.positionStrategy = this.overlay.position()
      .global()
      .centerHorizontally()
      .centerVertically();

    // Definindo que o spinner ficará em uma camada acima da aplicação
    config.hasBackdrop = true;

    // Criando a camada com as configurações e atribuindo a uma constante
    const overlayRef = this.overlay.create(config);

    // Mostrando o spinner na tela
    overlayRef.attach(new ComponentPortal(SpinnerComponent, this.viewContainerRef));    

    // Fazer a requisição ao backend
    this.sankhyaService.emailSegundaViaBoleto(this.emailCliente, this.titulosSelecionadosEmail).subscribe(
      res => {
        // Removendo o spinner da tela
        overlayRef.dispose();

        this.portalService.openSnackbar('Boleto processado com sucesso!');

      },
      erro => {
        // Removendo o spinner da tela
        overlayRef.dispose();

        const param = 'envioEmailSegundaVia';

        this.bottomSheet.open(BottomSheetComponent, {
          data: { param, erro },
          panelClass: 'bottom-sheet'
        });
      }
    );
  }

  selecionaTodosBoletos(event: any) {
    // Percorrer pelo vetor de titulos e colocar tudo TRUE
    this.titulosParceiro.map(tituloParceiro => tituloParceiro.ENVIAREMAIL = event.checked);
  }
  

  inicializarVariavel() {
    // Cliente
    this.parceiroCliente = {
      CODPARC: 0,
      NOMEPARC: '',
      RAZAOSOCIAL: '',
      CODVEND: 0,
      TIPO: '',
      NOMEEND: '',
      NUMEND: 0,
      COMPLEMENTO: '',
      NOMEBAI: '',
      NOMECID: '',
      TELEFONE: '',
      EMAIL: '',
      IDENTINSCESTAD: '',
      CGCCPF: '',
      ATIVO: '',
      LIMCRED: 0,
      CLIENTE: '',
      FORNECEDOR: '',
      VENDEDOR: '',
      TRANSPORTADORA: '',
      BLOQUEAR: ''
    };

    this.emailCliente = '';
    this.dataIni = '';
    this.dataFim = '';
    this.titulosParceiro = [];
    this.titulosSelecionadosEmail = [];
    this.coligados = false;    
  }

  calculaColunasTabela() {
    const larguraTela = window.innerWidth;

    if (larguraTela < 600) {
      this.fxHideTabelaTitulos = [true, true, false, false, false, false, true, true, false];
      return false;
    }

    if (larguraTela < 900) {
      this.fxHideTabelaTitulos = [true, true, false, true, true, false, true, true, true];
      return false;
    }

    this.fxHideTabelaTitulos = [true, true, true, true, true, true, true, true, true];
  }

}
