import { Component, Input, OnInit, ViewContainerRef } from '@angular/core';
import { ProdutoUnificado } from 'src/app/global-interfaces/produto-interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PortalServiceService } from 'src/app/services/portal-service.service';
import { Overlay } from '@angular/cdk/overlay';
import { SpinnerComponent } from 'src/app/global-components/spinner/spinner.component';
import { SankhyaServiceService } from 'src/app/services/sankhya-service.service';

@Component({
    selector: 'app-relatorio-tabela-de-preco2-gerencial',
    templateUrl: './relatorio-tabela-de-preco2-gerencial.component.html',
    styleUrls: ['./relatorio-tabela-de-preco2-gerencial.component.scss'],
})
export class RelatorioTabelaDePreco2GerencialComponent implements OnInit {
    constructor(
        private snackBar: MatSnackBar,
        private portalService: PortalServiceService,
        public overlay: Overlay,
        public viewContainerRef: ViewContainerRef,
        private sankhyaService: SankhyaServiceService
    ) {
        this.isTodos = true;
        this.produtosRelatorio = [];
    }

    ngOnInit() {}

    @Input() restUrl: string;

    isTodos: boolean;

    produtoSelecionado: ProdutoUnificado;
    produtosRelatorio: ProdutoUnificado[];

    toggleTodos() {
        this.isTodos = !this.isTodos;
        this.limparProdutosRelatorio();
    }

    limparProdutosRelatorio() {
        this.produtosRelatorio = [];
    }

    inserirProduto() {
        this.produtosRelatorio.push(this.produtoSelecionado);

        this.produtoSelecionado = {
            DESCRPROD: null,
            VLRVENDA: null,
        } as ProdutoUnificado;
    }

    deletarItemFiltro(index: number) {
        this.produtosRelatorio.splice(index, 1);
    }

    gerarPdf() {
        let bodyRequest = {};

        if (this.isTodos) {
            bodyRequest = {
                relatorio: 'tabela-de-preco',
                isTodos: true,
                params: [],
            };
        } else {
            if (this.produtosRelatorio.length === 0) {
                this.snackBar.open('Informe os itens no campo ou ative a opção "Todos"!', 'Fechar', {
                    verticalPosition: 'top',
                    horizontalPosition: 'end',
                    duration: 5000,
                });

                return false;
            } else {
                const codigosProdutos = this.produtosRelatorio.map(produto => produto.ADCODUNIPRO);

                bodyRequest = {
                    relatorio: 'tabela-de-preco',
                    isTodos: false,
                    params: codigosProdutos,
                };
            }
        }

        // Mostrando o spinner na tela
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

        /* this.sankhyaService.getPermissaoUsu()
        .subscribe((retorno: any) => {

            const ativo = retorno[0].ATIVO;

            if (ativo === 'S') {

                this.portalService.imprimirGeral({ url: this.restUrl + '.pdf' });
            } else {

                location.reload();
            }
        },  (error) => {

            location.reload();

            }
        ); */

        this.portalService.imprimirGeral({ url: this.restUrl + '.pdf' });

        // Removendo o spinner da tela
        overlayRef.dispose();
    }
}
