import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
    providedIn: 'root',
})
export class AutenticacaoService {
    private isLogged: boolean;

    constructor(private router: Router) {
        localStorage.getItem('isLogged') === 'true' ? (this.isLogged = true) : (this.isLogged = false);
    }

    codRep: number = null;
    userName: string = '';

    autenticar() {
        if (!this.isLogged) {
            this.router.navigateByUrl('/login');
            localStorage.setItem('isLogged', 'false');
        }
    }

    getIsLogged() {
        return this.isLogged;
    }

    // Nao salvar dados do usuario diretamente no local storage, utilizar o token de forma segura
    setUserName() {
        let jwt = new JwtHelperService();
        if (this.getIsLogged() || localStorage.getItem('token')) {
            this.userName = jwt.decodeToken(localStorage.getItem('token')).sub;
        }
    }

    // Retorna o usuario do login
    getUserName(): string {
        if (this.getIsLogged() || localStorage.getItem('token')) {
            if (this.userName === null || this.userName === '' || this.userName === undefined) this.setUserName();
            return this.userName;
        } else return "";
    }

    // Nao salvar dados do usuario diretamente no local storage, utilizar o token de forma segura
    setCodRep() {
        let jwt = new JwtHelperService();
        if (localStorage.getItem('isLogged') === 'true') {
            this.codRep = jwt.decodeToken(localStorage.getItem('token')).codRep;
        }
    }

    // Retorna o codigo representante / codigo vendedor
    getCodRep() {
        if (this.getIsLogged()) {
            // if (this.codRep === null || this.codRep === "" || this.codRep === undefined) this.setCodRep();
            if (this.codRep === null || this.codRep === undefined) this.setCodRep();
            return this.codRep;
        } else return null;
    }

    setIsLogged(parametro: boolean, nomeAbrev?: string) {
        this.isLogged = parametro;
        localStorage.setItem('isLogged', parametro.toString());
        //parametro === true ? localStorage.setItem('nomeAbrev', nomeAbrev) : localStorage.setItem('nomeAbrev', '');
    }

    desconectaUsu() {
        setTimeout(() => {
            this.setIsLogged(false);
            /*localStorage.setItem('token', '');
            localStorage.setItem('refreshToken', '');
            localStorage.setItem('rotas', '');
            localStorage.setItem('codRep', '');
            localStorage.setItem('nomeAbrev', '');
            localStorage.setItem('refresh', '');*/
            localStorage.clear();
            this.codRep = null;
            this.userName = '';
            this.router.navigateByUrl('/login');
        }, 2000);
    }
}
