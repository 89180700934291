import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Parceiro } from 'src/app/global-interfaces/parceiro-interface';

@Component({
  selector: 'app-criar-pedido-transportadora',
  templateUrl: './criar-pedido-transportadora.component.html',
  styleUrls: ['./criar-pedido-transportadora.component.scss']
})
export class CriarPedidoTransportadoraComponent implements OnInit {
  nomeTransportadora: string;

  parceiroTransportadora: Parceiro;

  @Input() codigoTransportadora: number;
  @Output() codigoTransportadoraChange = new EventEmitter<number>();

  constructor() { }

  ngOnInit() { }

  emitToParent(event: any) {
    this.codigoTransportadora = event;
    this.codigoTransportadoraChange.emit(this.codigoTransportadora);
  }

  clearAutocomplete() {

    this.parceiroTransportadora = null;
    this.emitToParent(0);
  }
}
