import { Overlay } from '@angular/cdk/overlay';
import { Component, Inject, OnInit, ViewContainerRef } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material';
import { SpinnerComponent } from 'src/app/global-components/spinner/spinner.component';
import { AutenticacaoService } from 'src/app/services/autenticacao.service';
import { PortalJpaService } from 'src/app/services/portal-jpa.service';

@Component({
    selector: 'app-bottom-sheet-juntar-pedido',
    templateUrl: './bottom-sheet-juntar-pedido.component.html',
    styleUrls: ['./bottom-sheet-juntar-pedido.component.scss'],
})
export class BottomSheetJuntarPedidoComponent implements OnInit {
    isJuntarPedido: boolean = false;
    pedidoJuntar: string = '';
    listaPedidosJuntar: any[] = [];
    isAConfirmar: boolean = false;
    exibirUsuario: boolean;
    vendedoresPermitidosAConfirmar: number[] = [
        0, //Administrativos
        131, //Carlos Formiga
    ];

    constructor(
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
        private bottomSheetRef: MatBottomSheetRef<BottomSheetJuntarPedidoComponent>,
        private portalJpaService: PortalJpaService,
        public overlay: Overlay,
        public viewContainerRef: ViewContainerRef,
        private auth: AutenticacaoService
    ) {}

    ngOnInit() {
        this.exibirUsuario = this.vendedoresPermitidosAConfirmar.includes(this.auth.getCodRep()) && this.data.codProj > 0 ? true : false;
    }

    close(opcao: string) {
        let ret = {
            opcao: opcao,
            pedidoJuntar: this.pedidoJuntar,
            aConfirmar: this.isAConfirmar ? 'S' : 'N',
        };

        this.bottomSheetRef.dismiss(ret);
    }

    toggleJuntarPedido(event: any) {
        if (!event.checked) {
            this.pedidoJuntar = null;
            this.listaPedidosJuntar = [];
            return false;
        }

        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

        this.portalJpaService.getPedidosJuntar(this.data).subscribe((ret: any) => {
            if (ret.response && !ret.response.length) {
            }
            this.listaPedidosJuntar = ret.response;
            overlayRef.dispose();
        });
    }

    toggleAConfirmar(event: any) {
        return !event.checked ? false : true;
    }

    selecionarPedido(pedidoJuntar: string) {
        this.pedidoJuntar = pedidoJuntar;
    }
}
