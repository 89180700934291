import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { PositivacaoClientes } from './local-intefaces/positivacao-clientes';
import { SpinnerComponent } from '../global-components/spinner/spinner.component';
import { PortalJpaService } from '../services/portal-jpa.service';
import { Overlay } from '@angular/cdk/overlay';
import { FuncionarioResponsavel } from './local-intefaces/funcionario-responsavel';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Pagina } from '../global-interfaces/pagina-interface';
import { ListaRepresentante } from './local-intefaces/lista-representante';
import { CarteiraClientes } from './local-intefaces/carteira-cliente';
import { ClientesAtivosInativos } from './local-intefaces/clientes-ativos-inativos';
import { MatTableDataSource, Sort } from '@angular/material';
import { forkJoin } from 'rxjs';
import { Response } from '../global-interfaces/response';
import Chart from 'chart.js/auto';
import { AutenticacaoService } from '../services/autenticacao.service';

@Component({
    selector: 'app-positivacao-clientes',
    templateUrl: './positivacao-clientes.component.html',
    styleUrls: ['./positivacao-clientes.component.scss'],
})
export class PositivacaoClientesComponent implements OnInit, AfterViewInit {
    constructor(
        public overlay: Overlay,
        private portalJpaService: PortalJpaService,
        public viewContainerRef: ViewContainerRef,
        private snackBar: MatSnackBar,
        private auth: AutenticacaoService
    ) {
        this.codRep = +auth.getCodRep();
    }

    ngOnInit() {
        if (this.codRep == 0) {
            this.funcionarioResponsavel();
            this.listaRepresentante();
        } else {
            this.atualizaPositivacaoMensal();
        }
    }

    ngAfterViewInit() {
        this.inicializarGraficos();
    }
    //Variavel código do representante
    codRep: number;

    //Variáveis de parâmetros para requisição
    codFunc = 0;
    codVend = 0;
    codFuncCart = 0;

    //Variáveis de soma dos resultados Positivação Mensal
    distintos: number;
    ultimos: number;
    meta: number;
    atual: number;
    posit: number;
    perc: number;

    //Variáveis/Datasource da Positivação Mensal
    representantes: PositivacaoClientes[] = [];
    repPaginacao: PositivacaoClientes[] = [];
    paginaCorrente = 1;
    listaColunasPosit = ['APELIDO', 'DISTINTOS', 'ULTIMOS', 'META', 'ATUAL', 'POSIT', 'PERC'];
    listaColunasPositExib = ['Apelido', 'Distintos', 'Últimos', 'Meta', 'Atual', 'Positivação', 'Meta %'];

    //Variáveis/Datasource da requisição de Funcionários
    funcionarios: FuncionarioResponsavel[] = [];

    //Variáveis/Datasource da requisição de Carteira Clientes
    carteiraClientes = new MatTableDataSource<CarteiraClientes>();
    carteiraClientesFiltrada: CarteiraClientes[] = [];
    carteiraClientesTotal: CarteiraClientes[] = [];
    listaColunasCart = [
        'CODPARC',
        'RAZAOSOCIAL',
        'DTULTCOMP',
        'QTDNOTAS',
        'FREQUENCIA',
        'QTDDIASSEMCOMP',
        'TITAVENCER',
        'TITVENCIDOS',
        'LIMCRED',
        'PE',
        'PO',
    ];

    //Variáveis/Datasource da requisição de Representantes
    listaRepresentantes: ListaRepresentante[] = [];

    //Variáveis/Datasource da requisição de Carteira Clientes
    clientesAtivosInativos: ClientesAtivosInativos[] = [];
    listaColunasAtivosInativos = ['APELIDO', 'PARCEIROS', 'ATIVO', 'INATIVO'];

    //Variáveis usadas nos filtros da Carteira Cliente (filtro por mês e por representante)
    tipoFiltroMes: number;
    selected: number;
    filtroMes: number;

    codVendAtivosInativos: number;
    filtroCodVend: number;

    tipoFiltroAtivo: number;
    filtroAtivo: number;

    //Variáveis que armazenam a quantidade de clientes ativos e inativos para a exibição
    clientesAtivos = 0;
    clientesInativos = 0;

    //Referência ao input do filtro da Carteira Cliente
    @ViewChild('input', { static: false }) input: ElementRef;

    //Variáveis para usar nos gráficos de clientes ativos
    graficoClientesAtendidos: any;
    valorGraficoAtendidos = [];
    agregadorGraficoAtendidos = [];

    //Variáveis para usar no graficoo de positivacao
    graficoPositivacao: any;
    valorUmGraficoPositivacao = [];
    valorDoisGraficoPositivacao = [];
    agregadorGraficoPositivacao = [];

    //Requisição para a tabela e gráfico Positivacao Mensal
    atualizaPositivacaoMensal() {
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

        const obj = {
            primeiro: this.portalJpaService.getPositivacaoClientes(this.codFunc),
            segundo: this.portalJpaService.getGraficoClientesAtendidos(this.codFunc),
            terceiro: this.portalJpaService.getGraficoPositivacao(this.codFunc),
        };

        forkJoin([obj.primeiro, obj.segundo, obj.terceiro]).subscribe(
            (res: any) => {
                // Requisição para tabela Positivação Mensal
                const res0: Response = res[0];
                if (res0.serviceResponse == 'OK') {
                    //Paginator
                    this.repPaginacao = res0.response;
                    this.representantes = this.repPaginacao.slice(0, 30);
                    this.paginaCorrente = 1;

                    //Somar os campos para a exibição
                    this.distintos = this.somarAtributo(this.repPaginacao, 'DISTINTOS');
                    this.ultimos = this.somarAtributo(this.repPaginacao, 'ULTIMOS');
                    this.meta = this.somarAtributo(this.repPaginacao, 'META');
                    this.atual = this.somarAtributo(this.repPaginacao, 'ATUAL');
                    this.posit = this.ultimos == 0 ? 0 : Number(((this.atual / this.ultimos) * 100).toFixed(2));
                    this.perc = this.meta == 0 ? 0 : Number(((this.atual / this.meta) * 100).toFixed(2));
                }

                //Requisição para os gráficos de Positivação Mensal
                const res1: Response = res[1];
                //Alimentando os arrays dos gráficos
                if (res1.serviceResponse == 'OK') {
                    this.valorGraficoAtendidos = [];
                    this.agregadorGraficoAtendidos = [];

                    res1.response.forEach(element => {
                        this.valorGraficoAtendidos.push(element.VALOR);
                        this.agregadorGraficoAtendidos.push(element.AGREGADOR);
                    });

                    this.graficoClientesAtendidos.data.labels = this.agregadorGraficoAtendidos;
                    this.graficoClientesAtendidos.data.datasets[0].data = this.valorGraficoAtendidos;
                    this.graficoClientesAtendidos.update();
                }

                const res2: Response = res[2];
                if (res2.serviceResponse == 'OK') {
                    this.valorUmGraficoPositivacao = [];
                    this.valorDoisGraficoPositivacao = [];
                    this.agregadorGraficoPositivacao = [];

                    res2.response.forEach(element => {
                        this.valorUmGraficoPositivacao.push(element.VALOR1);
                        this.valorDoisGraficoPositivacao.push(element.VALOR2);
                        this.agregadorGraficoPositivacao.push(element.AGREGADOR);
                    });

                    this.graficoPositivacao.data.labels = this.agregadorGraficoPositivacao;
                    this.graficoPositivacao.data.datasets[0].data = this.valorUmGraficoPositivacao;
                    this.graficoPositivacao.data.datasets[1].data = this.valorDoisGraficoPositivacao;
                    this.graficoPositivacao.update();
                }
                overlayRef.dispose();
            },
            erro => {
                overlayRef.dispose();
                console.log(erro);
            }
        );
    }

    //Requisição para a tabela Carteira Cliente/Ativos Inativos
    atualizaCarteiraClientes() {
        this.selected = undefined;
        this.codVendAtivosInativos = null;
        this.filtroMes = null;
        this.filtroCodVend = null;
        this.tipoFiltroAtivo = null;
        this.filtroAtivo = null;
        this.input.nativeElement.value = '';

        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

        const obj = {
            primeiro: this.portalJpaService.getCarteiraClientes(this.codVend, this.codFuncCart),
            segundo: this.portalJpaService.getClientesAtivosInativos(this.codFuncCart),
        };

        forkJoin([obj.primeiro, obj.segundo]).subscribe(
            (res: any) => {
                const res0: Response = res[0];
                if (res0.serviceResponse == 'OK') {
                    this.carteiraClientes = new MatTableDataSource(res0.response);
                    this.carteiraClientesTotal = res0.response;
                    this.carteiraClientesFiltrada = res0.response;

                    this.somaClienteAtivoInativo();
                }
                const res1: Response = res[1];
                if (res1.serviceResponse == 'OK') {
                    this.clientesAtivosInativos = res1.response;
                }
                overlayRef.dispose();
            },
            error => {
                overlayRef.dispose();
                console.log(error);
            }
        );
    }

    //Requisição para a tabela de Funcionarios
    funcionarioResponsavel() {
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);
        this.portalJpaService.getFuncionarioResponsavel().subscribe(
            (res: any) => {
                this.funcionarios = res.response;
                overlayRef.dispose();
            },
            erro => {
                overlayRef.dispose();
                console.log(erro);
            }
        );
    }

    //Requisição para a tabela de Representantes
    listaRepresentante() {
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);
        this.portalJpaService.getRepresentantesAtivos().subscribe(
            (res: any) => {
                this.listaRepresentantes = res.response;
                overlayRef.dispose();
            },
            erro => {
                overlayRef.dispose();
                console.log(erro);
            }
        );
    }

    //Método para somar os campos da tabela Positivacao Mensal
    somarAtributo(array, atributo) {
        return array.reduce((total, objeto) => {
            return total + objeto[atributo];
        }, 0);
    }

    openSnackbar(message: string) {
        this.snackBar.open(message, 'Fechar', {
            horizontalPosition: 'end',
            verticalPosition: 'top',
            duration: 5000,
        });
    }

    //Metodo de paginator da Positivacao Mensal
    paginar(event: Pagina) {
        this.representantes = this.repPaginacao.slice(event.startIndex, event.endIndex + 1);
    }

    //Metodos para somar os campos no footer da tabela Ativos/Inativos
    getTotalAtivo() {
        return this.clientesAtivosInativos.map(t => t.ATIVO).reduce((acc, value) => acc + value, 0);
    }

    getTotalInativo() {
        return this.clientesAtivosInativos.map(t => t.INATIVO).reduce((acc, value) => acc + value, 0);
    }

    //Metodos de ordenação da tabela Carteira Cliente ao clicar no header de cada campo
    sortDataCart(sort: Sort) {
        this.clientesInativos = 0;
        this.clientesAtivos = 0;

        const data = this.carteiraClientesFiltrada.slice();

        if (!sort.active || sort.direction === '') {
            this.carteiraClientesFiltrada = data;
            this.carteiraClientesFiltrada.forEach(tabela => {
                tabela.FREQUENCIA == 0 || tabela.FREQUENCIA < tabela.QTDDIASSEMCOMP ? this.clientesInativos++ : this.clientesAtivos++;
            });
            return;
        }

        this.carteiraClientesFiltrada = data.sort((a, b) => {
            const isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 'CODPARC':
                    return this.compara(a.CODPARC, b.CODPARC, isAsc);
                case 'RAZAOSOCIAL':
                    return this.compara(a.RAZAOSOCIAL, b.RAZAOSOCIAL, isAsc);
                case 'DTULTCOMP':
                    return this.comparaData(a.DTULTCOMP, b.DTULTCOMP, isAsc);
                case 'FREQUENCIA':
                    return this.compara(a.FREQUENCIA, b.FREQUENCIA, isAsc);
                case 'QTDNOTAS':
                    return this.compara(a.QTDNOTAS, b.QTDNOTAS, isAsc);
                case 'QTDDIASSEMCOMP':
                    return this.compara(a.QTDDIASSEMCOMP, b.QTDDIASSEMCOMP, isAsc);
                case 'TITAVENCER':
                    return this.compara(a.TITAVENCER, b.TITAVENCER, isAsc);
                case 'TITVENCIDOS':
                    return this.compara(a.TITVENCIDOS, b.TITVENCIDOS, isAsc);
                case 'LIMCRED':
                    return this.compara(a.LIMCRED, b.LIMCRED, isAsc);
                case 'PE':
                    return this.compara(a.PE, b.PE, isAsc);
                case 'PO':
                    return this.compara(a.PO, b.PO, isAsc);
                default:
                    return 0;
            }
        });

        this.carteiraClientesFiltrada.forEach(tabela => {
            tabela.FREQUENCIA == 0 || tabela.FREQUENCIA < tabela.QTDDIASSEMCOMP ? this.clientesInativos++ : this.clientesAtivos++;
        });

        this.carteiraClientes = new MatTableDataSource(this.carteiraClientesFiltrada);
    }

    compara(a: number | string, b: number | string, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? -1 : 1);
    }

    comparaData(a: string, b: string, isAsc: boolean) {
        if (a.trim() === '' && b.trim() === '') return 0;
        if (a.trim() === '') return 1;
        if (b.trim() === '') return -1;

        const dataFormatadaA = this.transformaData(a);
        const dataFormatadaB = this.transformaData(b);

        return (dataFormatadaA < dataFormatadaB ? -1 : 1) * (isAsc ? -1 : 1);
    }

    transformaData(data: string) {
        const partesData = data.split('/');
        return new Date(`${partesData[2]}-${partesData[1]}-${partesData[0]}`);
    }

    //Metodos de ordenação da tabela Positivacao ao clicar no header de cada campo
    sortDataPosit(sort: Sort) {
        const data = this.repPaginacao.slice();

        if (!sort.active || sort.direction === '') {
            this.repPaginacao = data;
            return;
        }

        this.repPaginacao = data.sort((a, b) => {
            const isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 'APELIDO':
                    return this.compara(a.APELIDO, b.APELIDO, isAsc);
                case 'DISTINTOS':
                    return this.compara(a.DISTINTOS, b.DISTINTOS, isAsc);
                case 'ULTIMOS':
                    return this.compara(a.ULTIMOS, b.ULTIMOS, isAsc);
                case 'META':
                    return this.compara(a.META, b.META, isAsc);
                case 'ATUAL':
                    return this.compara(a.ATUAL, b.ATUAL, isAsc);
                case 'POSIT':
                    return this.compara(a.POSIT, b.POSIT, isAsc);
                case 'PERC':
                    return this.compara(a.PERC, b.PERC, isAsc);
                default:
                    return 0;
            }
        });

        this.representantes = this.repPaginacao.slice(0, 30);

        this.paginaCorrente = 1;
    }

    // Método para adicionar cor na coluna LIMITE DE CRÉDITO quando o cliente for TRADEMASTER
    corTrademaster(element: CarteiraClientes) {
        if (element.CLASSIFICACAO == 'E') {
            return '#8FE3FF';
        }
    }

    //Metodo para filtrar a tabela Carteira Clientes baseado no parâmetro passado no click na tabela Ativos/Inativos
    atualizarTabelaCarteira(parametro: number) {
        if (parametro) {
            this.codVendAtivosInativos = parametro;
            this.carteiraClientesFiltrada = this.carteiraClientesFiltrada.filter(tabela => {
                return tabela.CODVEND == parametro;
            });
        }
    }

    //Metodo para filtrar a tabela Carteira Clientes baseado no parãmetro passado nos botões de filtro por mês
    atualizarTabelaDatas(parametro: number) {
        const dataAtual = new Date();
        const tresMeses = new Date(dataAtual.getFullYear(), dataAtual.getMonth() - 3, 1);
        const umMes = new Date(dataAtual.getFullYear(), dataAtual.getMonth(), 0);

        if (parametro == 1) {
            this.tipoFiltroMes = parametro;

            this.carteiraClientesFiltrada = this.carteiraClientesFiltrada.filter(tabela => {
                if (tabela.DTULTCOMP.trim()) {
                    const formata = tabela.DTULTCOMP.split('/');
                    const dataItem = new Date(Number(formata[2]), Number(formata[1]) - 1, Number(formata[0]));
                    return dataItem < tresMeses;
                }
            });
        } else if (parametro == 2) {
            this.tipoFiltroMes = parametro;

            this.carteiraClientesFiltrada = this.carteiraClientesFiltrada.filter(tabela => {
                if (tabela.DTULTCOMP.trim()) {
                    const formata = tabela.DTULTCOMP.split('/');
                    const dataItem = new Date(Number(formata[2]), Number(formata[1]) - 1, Number(formata[0]));
                    return dataItem >= tresMeses && dataItem <= umMes;
                }
            });
        } else if (parametro == 3) {
            this.tipoFiltroMes = parametro;

            this.carteiraClientesFiltrada = this.carteiraClientesFiltrada.filter(tabela => {
                if (tabela.DTULTCOMP.trim()) {
                    const formata = tabela.DTULTCOMP.split('/');
                    const dataItem = new Date(Number(formata[2]), Number(formata[1]) - 1, Number(formata[0]));
                    return dataItem > umMes;
                }
            });
        } else if (parametro == 4) {
            this.tipoFiltroMes = parametro;

            this.carteiraClientesFiltrada = this.carteiraClientesFiltrada.filter(tabela => {
                if (!tabela.DTULTCOMP.trim()) {
                    return tabela.DTULTCOMP;
                }
            });
        }
    }

    atualizarTabelaAtivoInativo(parametro: number) {
        if (parametro == 1) {
            this.tipoFiltroAtivo = parametro;
            this.carteiraClientesFiltrada = this.carteiraClientesFiltrada.filter(tabela => {
                return tabela.FREQUENCIA != 0 && tabela.FREQUENCIA >= tabela.QTDDIASSEMCOMP;
            });
        } else if (parametro == 2) {
            this.tipoFiltroAtivo = parametro;
            this.carteiraClientesFiltrada = this.carteiraClientesFiltrada.filter(tabela => {
                return tabela.FREQUENCIA == 0 || tabela.FREQUENCIA < tabela.QTDDIASSEMCOMP;
            });
        }
    }

    // Método do filtro da tabela carteira Cliente
    filtrarTabela(origem: number, parametro: number) {
        this.input.nativeElement.value = '';
        this.carteiraClientesFiltrada = JSON.parse(JSON.stringify(this.carteiraClientesTotal));

        if (origem == 1) this.filtroMes = parametro;
        if (origem == 2) this.filtroCodVend = parametro;
        if (origem == 3) this.filtroAtivo = parametro;

        if (origem == 1 && this.tipoFiltroMes == this.selected) {
            this.filtroMes = null;
            this.selected = undefined;
            this.tipoFiltroMes = 0;
            this.atualizarTabelaCarteira(this.filtroCodVend);
            this.atualizarTabelaAtivoInativo(this.filtroAtivo);
        } else if (origem == 2 && this.codVendAtivosInativos == this.filtroCodVend) {
            this.filtroCodVend = null;
            this.codVendAtivosInativos = null;
            this.atualizarTabelaDatas(this.filtroMes);
            this.atualizarTabelaAtivoInativo(this.filtroAtivo);
        } else if (origem == 3 && this.tipoFiltroAtivo == this.filtroAtivo) {
            this.filtroAtivo = null;
            this.tipoFiltroAtivo = null;
            this.atualizarTabelaDatas(this.filtroMes);
            this.atualizarTabelaCarteira(this.filtroCodVend);
        } else {
            this.atualizarTabelaDatas(this.filtroMes);
            this.atualizarTabelaCarteira(this.filtroCodVend);
            this.atualizarTabelaAtivoInativo(this.filtroAtivo);
        }
        this.somaClienteAtivoInativo();

        this.carteiraClientes = new MatTableDataSource(this.carteiraClientesFiltrada);
    }

    //Metodo para alimentar as variáveis de exibição dos clientes Ativos e Inativos
    somaClienteAtivoInativo() {
        this.clientesInativos = 0;
        this.clientesAtivos = 0;

        this.carteiraClientesFiltrada.forEach(tabela => {
            if (tabela.FREQUENCIA == 0 || tabela.FREQUENCIA < tabela.QTDDIASSEMCOMP) {
                tabela.ATIVO = 'N';
                this.clientesInativos++;
            } else {
                tabela.ATIVO = 'S';
                this.clientesAtivos++;
            }
        });
    }

    filtrarPorTexto(event: Event) {
        const filtro = (event.target as HTMLInputElement).value;
        this.carteiraClientes.filter = filtro.trim().toLowerCase();
    }

    alimentarTabela($event) {
        if ($event == 1 && this.codRep != 0 && this.carteiraClientesFiltrada.length == 0) {
            this.atualizaCarteiraClientes();
        }
    }

    inicializarGraficos() {
        this.graficoClientesAtendidos = new Chart('grafico_clientes_atendidos', {
            type: 'line',
            data: {
                labels: [],
                datasets: [
                    {
                        label: 'Quantidade de Clientes',
                        data: [],
                        fill: false,
                        borderWidth: 2,
                        borderColor: '#830219',
                        backgroundColor: '#830219',
                        pointBackgroundColor: '#830219',
                    },
                ],
            },
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        display: true,
                        position: 'bottom',
                    },
                    title: {
                        display: true,
                        text: 'Clientes Atendidos por Mês',
                        font: {
                            size: 20,
                        },
                    },
                },
                scales: {
                    x: {
                        grid: {
                            display: true,
                            drawTicks: true,
                            tickColor: 'black',
                            lineWidth: 0.08,
                            tickWidth: 1,
                            color: '#000000',
                            borderColor: '#000000',
                        },
                        ticks: {
                            color: '#00000',
                            font: {
                                weight: 'bolder',
                            },
                        },
                    },
                    y: {
                        grid: {
                            drawTicks: true,
                            tickColor: 'black',
                            lineWidth: 2,
                            tickWidth: 1,
                            display: true,
                            borderColor: '#000000',
                        },
                        ticks: {
                            color: '#00000',
                            font: {
                                weight: 'bolder',
                            },
                        },
                        beginAtZero: true,
                    },
                },
            },
        });

        this.graficoPositivacao = new Chart('grafico_positivacao', {
            type: 'line',
            data: {
                labels: [],
                datasets: [
                    {
                        label: 'Cenário Atual',
                        data: [],
                        fill: false,
                        borderWidth: 2,
                        borderColor: '#830219',
                        backgroundColor: '#830219',
                        pointBackgroundColor: '#830219',
                    },
                    {
                        label: 'Cenário Ideal',
                        data: [],
                        fill: false,
                        borderWidth: 2,
                        borderColor: '#26213F',
                        backgroundColor: '#26213F',
                        pointBackgroundColor: '#26213F',
                    },
                ],
            },
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        display: true,
                        position: 'bottom',
                    },
                    title: {
                        display: true,
                        text: 'Positivação Mensal',
                        font: {
                            size: 20,
                        },
                    },
                },
                scales: {
                    x: {
                        grid: {
                            display: true,
                            drawTicks: true,
                            tickColor: 'black',
                            lineWidth: 0.08,
                            tickWidth: 1,
                            color: '#000000',
                            borderColor: '#000000',
                        },
                        ticks: {
                            color: '#00000',
                            font: {
                                weight: 'bolder',
                            },
                        },
                    },
                    y: {
                        grid: {
                            drawTicks: true,
                            tickColor: 'black',
                            lineWidth: 2,
                            tickWidth: 1,
                            display: true,
                            borderColor: '#000000',
                        },
                        ticks: {
                            stepSize: 0.1,
                            color: '#00000',
                            font: {
                                weight: 'bolder',
                            },
                            format: {
                                style: 'percent',
                            },
                        },
                        beginAtZero: true,
                        max: 1,
                    },
                },
            },
        });
    }
}
