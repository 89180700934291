import { SpinnerComponent } from './../../global-components/spinner/spinner.component';
import { SankhyaServiceService } from 'src/app/services/sankhya-service.service';
import { Overlay } from '@angular/cdk/overlay';
import { PortalServiceService } from 'src/app/services/portal-service.service';
import { Component, OnInit, Input, ViewContainerRef } from '@angular/core';
import { AutenticacaoService } from 'src/app/services/autenticacao.service';
import { MatBottomSheet } from '@angular/material';
import { BottomSheetGrupoDeProdutoComponent } from 'src/app/global-components/bottom-sheet-grupo-de-produto/bottom-sheet-grupo-de-produto.component';

@Component({
    selector: 'app-relatorio-tabela-de-preco-excecao',
    templateUrl: './relatorio-tabela-de-preco-excecao.component.html',
    styleUrls: ['./relatorio-tabela-de-preco-excecao.component.scss'],
})
export class RelatorioTabelaDePrecoExcecaoComponent implements OnInit {
    constructor(
        private portalService: PortalServiceService,
        public overlay: Overlay,
        public viewContainerRef: ViewContainerRef,
        private sankhyaService: SankhyaServiceService,
        private auth: AutenticacaoService,
        private bottomSheet: MatBottomSheet
    ) {}

    ngOnInit() {}

    @Input() restUrl: string;
    @Input() listaGrupoProd = [];

    gerarPdf() {
        // Mostrando o spinner na tela
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

        this.sankhyaService.getPermissaoUsu().subscribe(
            (retorno: any) => {
                const ativo = retorno[0].ATIVO;
                let gruposDeProduto = '';

                this.listaGrupoProd.map(grupo => {
                    if (gruposDeProduto === '' && grupo.isAtivo) {
                        gruposDeProduto = `${grupo.CODGRUPOPROD}`;
                    } else if (gruposDeProduto !== '' && grupo.isAtivo) {
                        gruposDeProduto = `${gruposDeProduto},${grupo.CODGRUPOPROD}`;
                    }
                });

                const dataParam = {
                    USULOGIN: this.auth.getUserName(),
                    LISTGRUPOPROD: gruposDeProduto,
                };

                if (ativo === 'S') {
                    this.portalService.imprimirGeral({ params: dataParam, url: this.restUrl + '.pdf' });
                } else {
                    location.reload();
                }
            },
            error => {
                location.reload();
            }
        );

        // Removendo o spinner da tela
        overlayRef.dispose();
    }

    selecionaGrupoProd() {
        this.bottomSheet.open(BottomSheetGrupoDeProdutoComponent, { disableClose: true, data: this.listaGrupoProd });
    }
}
