import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { PedidoCobranca } from '../../local-interfaces/pedido-cobranca-interface';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';

@Component({
    selector: 'app-dialog-liberacao',
    templateUrl: './dialog-liberacao.component.html',
    styleUrls: ['./dialog-liberacao.component.scss'],
})
export class DialogLiberacaoComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<DialogLiberacaoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.pedido = this.data.pedido;
        this.formLib.controls.qtdParcelas.setValue(this.pedido.QTDPARC);
        this.formLib.controls.qtdParcelas.disable();

        const valorMaiorQueZero: ValidatorFn = (control: AbstractControl) => {
            const valor = control.value;
            if (valor === 0) {
                return { valorZero: true };
            }
            return null;
        };

        this.formLib.get('valor').setValidators(valorMaiorQueZero);
        this.formLib.get('valor').setValue(this.data.vlrRestante);
    }

    pedido: PedidoCobranca;

    liberadores = [
        { valor: 0, nome: 'FINANCEIRO' },
        { valor: 3, nome: 'PAULO' },
        { valor: 7, nome: 'WILLIAM' },
        { valor: 6, nome: 'EDUARDO' },
        { valor: 42, nome: 'ELNATÃ' },
        { valor: 293, nome: 'DEIVERSON' },
    ];

    bandeiras = ['MASTERCARD', 'VISA'];

    codUsuLib = 0;

    formLib = new FormGroup({
        qtdParcelas: new FormControl(0, Validators.required),
        valor: new FormControl(0, [Validators.required, , Validators.min(1)]),
        bandeira: new FormControl('MASTERCARD', Validators.required),
        dtPag: new FormControl(new Date(), Validators.required),
        NSU: new FormControl('', Validators.required),
    });

    filtroData = (d: Date | null): boolean => {
        const dia = d || new Date();
        return dia <= new Date() || dia.getDate() == new Date().getDate();
    };

    fecharDialog(acao: boolean) {
        if (!acao) {
            this.dialogRef.close({ acao: acao });
        }

        if (this.pedido.CODSTATUS === 3 || this.pedido.CODSTATUS === 32) {
            this.dialogRef.close({ acao: acao, codUsuLib: this.codUsuLib });
        }

        if (this.pedido.CODSTATUS === 5 || this.pedido.CODSTATUS === 51) {
            if (this.formLib.invalid) {
                this.formLib.markAllAsTouched();
                this.changeDetectorRef.detectChanges();
                return false;
            }
            this.dialogRef.close({ acao: acao, form: this.formLib });
        }
    }

    formataNumero() {
        if (this.formLib.get('valor').value > this.data.vlrRestante) this.formLib.get('valor').setValue(this.data.vlrRestante);
    }
}
