import { AfterViewInit, Component, Input, OnInit, ViewContainerRef } from '@angular/core';
import { OverlayConfig, Overlay } from '@angular/cdk/overlay';
import { PortalServiceService } from 'src/app/services/portal-service.service';
import { ComponentPortal } from '@angular/cdk/portal';
import { SpinnerComponent } from 'src/app/global-components/spinner/spinner.component';
import { ProdutoUnificado } from 'src/app/global-interfaces/produto-interface';
import { SankhyaServiceService } from 'src/app/services/sankhya-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AutenticacaoService } from 'src/app/services/autenticacao.service';
import { BottomSheetGrupoDeProdutoComponent } from 'src/app/global-components/bottom-sheet-grupo-de-produto/bottom-sheet-grupo-de-produto.component';
import { MatBottomSheet } from '@angular/material';

@Component({
    selector: 'app-relatorio-pronta-entrega-resumumido',
    templateUrl: './relatorio-pronta-entrega-resumumido.component.html',
    styleUrls: ['./relatorio-pronta-entrega-resumumido.component.scss', '../relatorios.component.scss'],
})
export class RelatorioProntaEntregaResumumidoComponent implements OnInit, AfterViewInit {
    constructor(
        private portalService: PortalServiceService,
        public overlay: Overlay,
        public viewContainerRef: ViewContainerRef,
        private sankhyaService: SankhyaServiceService,
        private snackBar: MatSnackBar,
        private autenticacao: AutenticacaoService,
        private bottomSheet: MatBottomSheet
    ) {
        this.codRep = +autenticacao.getCodRep();
        this.isTodos = true;
        this.produtosRelatorio = [];
        this.codEmp = [0];
    }

    ngOnInit() {}

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.listaEmpresas = this.sankhyaService.listaEmpresas;
            this.listaEmpresas.forEach(empresa => {
                if (empresa == 1 || empresa == 2 || empresa == 4) {
                    this.codEmp.push(empresa);
                }
            });
            this.nomeEmpresas = this.sankhyaService.nomeEmpresas;
        }, 1500);
    }

    @Input() restUrl: string;

    // Variável que controla o disabled dos componentes de acordo com o toggle "Todos"
    isTodos: boolean;

    codRep: number;

    produtoSelecionado: ProdutoUnificado;
    produtosRelatorio: ProdutoUnificado[];

    nomeEmpresas: any;
    listaEmpresas: number[] = [];
    codEmp: number[] = [];

    @Input() listaGrupoProd = [];

    toggleTodos() {
        this.isTodos = !this.isTodos;
        this.limparProdutosRelatorio();
    }

    limparProdutosRelatorio() {
        this.produtosRelatorio = [];
    }

    limpaCampo() {
        this.produtoSelecionado = {
            DESCRPROD: null,
            VLRVENDA: null,
        } as ProdutoUnificado;
    }

    inserirProduto() {
        this.produtosRelatorio.push(this.produtoSelecionado);

        this.produtoSelecionado = {
            DESCRPROD: null,
            VLRVENDA: null,
        } as ProdutoUnificado;
    }

    deletarItemFiltro(index: number) {
        this.produtosRelatorio.splice(index, 1);
    }

    gerarPdf() {
        if (this.codEmp.length === 1 && this.codEmp[0] === 0) {
            this.openSnackbar('Escolha ao menos uma empresa para exibir o saldo!');
            return false;
        }

        this.codEmp.sort();

        let bodyRequest = {};
        let listaProdutos = '';

        if (this.isTodos) {
            bodyRequest = {
                relatorio: 'pronta-entrega',
                isTodos: true,
                params: [],
            };
        } else {
            if (this.produtosRelatorio.length === 0) {
                this.portalService.openSnackbar('Informe os itens no campo ou ative a opção "Todos"!');

                return false;
            } else {
                const codigosProdutos = this.produtosRelatorio.map(produto => {
                    listaProdutos = listaProdutos === '' ? `${produto.ADCODUNIPRO}` : `${listaProdutos},${produto.ADCODUNIPRO}`;
                });

                bodyRequest = {
                    relatorio: 'pronta-entrega',
                    isTodos: false,
                    params: codigosProdutos,
                };
            }
        }

        // Mostrando o spinner na tela
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

        /* this.sankhyaService.getPermissaoUsu()
            .subscribe((retorno: any) => {

                const ativo = retorno[0].ATIVO;

                if (ativo === 'S') {

                    this.portalService.imprimirGeral({ params: { LISTAPRODUTOS: listaProdutos, LISTEMP: this.codEmp.toString() }, url: this.restUrl + '.pdf' });
                } else {

                    location.reload();
                }
            },  (error) => {

        location.reload();
        }); */

        let gruposDeProduto = '';

        this.listaGrupoProd.map(grupo => {
            if (gruposDeProduto === '' && grupo.isAtivo) {
                gruposDeProduto = `${grupo.CODGRUPOPROD}`;
            } else if (gruposDeProduto !== '' && grupo.isAtivo) {
                gruposDeProduto = `${gruposDeProduto},${grupo.CODGRUPOPROD}`;
            }
        });

        this.portalService.imprimirGeral({
            params: {
                LISTAPRODUTOS: listaProdutos,
                LISTEMP: this.codEmp.toString(),
                USULOGIN: this.autenticacao.getUserName(),
                LISTGRUPOPROD: gruposDeProduto,
            },
            url: this.restUrl + '.pdf',
        });

        // Removendo o spinner da tela
        overlayRef.dispose();
    }

    toggleEmp(empresa: any, event: any) {
        if (event.checked) {
            this.codEmp.push(empresa);
        } else {
            this.codEmp = this.codEmp.filter(x => x != empresa);
        }
    }

    getNomeEmp(codEmp: number) {
        let ret: any = this.nomeEmpresas.find(emp => emp.CODEMP === codEmp);
        return ret.NOMEFANTASIA;
    }

    openSnackbar(message: string) {
        this.snackBar.open(message, 'Fechar', {
            horizontalPosition: 'end',
            verticalPosition: 'top',
            duration: 5000,
        });
    }

    selecionaGrupoProd() {
        this.bottomSheet.open(BottomSheetGrupoDeProdutoComponent, { disableClose: true, data: this.listaGrupoProd });
    }
}
