import { AfterViewInit, Component, Input, OnInit, ViewContainerRef } from '@angular/core';
import { ProdutoUnificado } from 'src/app/global-interfaces/produto-interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PortalServiceService } from '../../services/portal-service.service';
import { Projeto } from 'src/app/global-interfaces/projeto-interface';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { SpinnerComponent } from 'src/app/global-components/spinner/spinner.component';
import { SankhyaServiceService } from 'src/app/services/sankhya-service.service';
import { AutenticacaoService } from 'src/app/services/autenticacao.service';
import { MatBottomSheet } from '@angular/material';
import { BottomSheetGrupoDeProdutoComponent } from 'src/app/global-components/bottom-sheet-grupo-de-produto/bottom-sheet-grupo-de-produto.component';

@Component({
    selector: 'app-relatorio-programado-percentual-gerencial',
    templateUrl: './relatorio-programado-percentual-gerencial.component.html',
    styleUrls: ['./relatorio-programado-percentual-gerencial.component.scss', '../relatorios.component.scss'],
})
export class RelatorioProgramadoPercentualGerencialComponent implements OnInit, AfterViewInit {
    constructor(
        private snackBar: MatSnackBar,
        private portalService: PortalServiceService,
        private bottomSheet: MatBottomSheet,
        public overlay: Overlay,
        public viewContainerRef: ViewContainerRef,
        private sankhyaService: SankhyaServiceService,
        private autenticacao: AutenticacaoService
    ) {
        this.codRep = +autenticacao.getCodRep();
        this.codEmp = [0];
    }

    ngOnInit() {}

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.listaEmpresas = this.sankhyaService.listaEmpresas;
            this.listaEmpresas.forEach(empresa => {
                if (empresa == 1 || empresa == 2 || empresa == 4) {
                    this.codEmp.push(empresa);
                }
            });

            this.nomeEmpresas = this.sankhyaService.nomeEmpresas;
        }, 1500);
    }

    @Input() restUrl: string;

    projeto: number;
    nomeEmpresas: any;
    listaEmpresas: number[] = [];
    codEmp: number[] = [];

    codRep: number;

    @Input() listaGrupoProd = [];

    limpaCampo() {
        this.projeto = null;
    }

    toggleEmp(empresa: any, event: any) {
        if (event.checked) {
            this.codEmp.push(empresa);
        } else {
            this.codEmp = this.codEmp.filter(x => x != empresa);
        }
    }

    gerarPdf() {
        if (this.projeto == null) {
            this.openSnackbar('Digite um projeto!');
            return false;
        }
        if (this.codEmp.length === 1 && this.codEmp[0] === 0) {
            this.openSnackbar('Escolha ao menos uma empresa para exibir o saldo!');
            return false;
        }

        this.codEmp.sort();

        let gruposDeProduto = '';

        this.listaGrupoProd.map(grupo => {
            if (gruposDeProduto === '' && grupo.isAtivo) {
                gruposDeProduto = `${grupo.CODGRUPOPROD}`;
            } else if (gruposDeProduto !== '' && grupo.isAtivo) {
                gruposDeProduto = `${gruposDeProduto},${grupo.CODGRUPOPROD}`;
            }
        });
        // Mostrando o spinner na tela
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

        this.portalService.imprimirGeral({
            params: {
                LISTAPROJETOS: this.projeto,
                LISTEMP: this.codEmp.toString(),
                USULOGIN: this.autenticacao.getUserName(),
                LISTGRUPOPROD: gruposDeProduto,
            },
            url: this.restUrl + '.pdf',
        });

        // Removendo o spinner da tela
        overlayRef.dispose();
    }

    getNomeEmp(codEmp: number) {
        let ret: any = this.nomeEmpresas.find(emp => emp.CODEMP === codEmp);
        return ret.NOMEFANTASIA;
    }

    openSnackbar(message: string) {
        this.snackBar.open(message, 'Fechar', {
            horizontalPosition: 'end',
            verticalPosition: 'top',
            duration: 5000,
        });
    }
    selecionaGrupoProd() {
        this.bottomSheet.open(BottomSheetGrupoDeProdutoComponent, { disableClose: true, data: this.listaGrupoProd });
    }
}
