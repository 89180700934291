import { Overlay } from '@angular/cdk/overlay';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewContainerRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, mergeMap, switchMap, tap } from 'rxjs/operators';

import { TipoNegociacao, TipoNegociacaoCartao, SubTipo } from 'src/app/global-interfaces/tipo-negociacao-interface';
import { PortalJpaService } from 'src/app/services/portal-jpa.service';
import { SpinnerComponent } from '../spinner/spinner.component';

@Component({
    selector: 'app-autocomplete-tipo-negociacao-cartao',
    templateUrl: './autocomplete-tipo-negociacao-cartao.component.html',
    styleUrls: ['./autocomplete-tipo-negociacao-cartao.component.scss'],
})
export class AutocompleteTipoNegociacaoCartaoComponent implements OnInit, OnChanges {
    @Input()
    tipoNegociacaoAtual: TipoNegociacao;

    @Input()
    autocompleteEnabled: boolean = true;

    @Output()
    tipoNegociacaoSelecionado = new EventEmitter<TipoNegociacao>();
    // verfificar na https://material.angular.io/components/select/api
    // openedChange ou selectionChange

    @ViewChild('parcelasRef', { static: false }) parcelasRef: MatSelect;

    tipoCartaoControl = new FormControl('');
    bandeiraCartaoControl = new FormControl('');
    parcelaCartaoControl = new FormControl('');

    listaTipoCartao: string[] = ['Crédito'];
    listaBandeiras: string[] = [];
    listaBandeirasDebito: string[];
    listaBandeirasCredito: string[];
    listaParcelas: string[];

    listaTiposNegociacao: TipoNegociacaoCartao[];

    apiErrorMessage: string = '';

    constructor(
        private jpaService: PortalJpaService,
        public overlay: Overlay,
        public viewContainerRef: ViewContainerRef,
    ) {}

    ngOnInit(): void {
        if (this.tipoNegociacaoAtual && this.tipoNegociacaoAtual.CODTIPVENDA == 0) {
            this.setarControles(this.tipoNegociacaoAtual);
        }

        // Mostrando o spinner na tela
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);
        this.jpaService.autocompleteTipoNegociacaoCartao().subscribe((res: any) => {
            const listaCredito = new Set();
            const listaDebito = new Set();

            this.listaTiposNegociacao = [];
            if (res.response && Array.isArray(res.response)) {
                res.response.forEach((tipoNeg: any) => {
                    const descr = tipoNeg.DESCRTIPVENDA.split('|');

                    if (tipoNeg.SUBTIPOVENDA == SubTipo.DEBITO.toString()) {
                        this.listaTiposNegociacao.push({
                            CODTIPVENDA: tipoNeg.CODTIPVENDA,
                            SUBTIPOVENDA: tipoNeg.SUBTIPOVENDA,
                            DESCRTIPVENDA: tipoNeg.DESCRTIPVENDA,
                            bandeira: descr[0],
                            parcelas: '',
                        });
                        listaDebito.add(descr[0]);
                    } else if (tipoNeg.SUBTIPOVENDA == SubTipo.CREDITO.toString()) {
                        this.listaTiposNegociacao.push({
                            CODTIPVENDA: tipoNeg.CODTIPVENDA,
                            SUBTIPOVENDA: tipoNeg.SUBTIPOVENDA,
                            DESCRTIPVENDA: tipoNeg.DESCRTIPVENDA,
                            bandeira: descr[0],
                            parcelas: descr[1],
                        });
                        listaCredito.add(descr[0]);
                    }
                });
            }

            this.listaBandeirasDebito = Array.from(listaDebito) as string[];
            this.listaBandeirasCredito = Array.from(listaCredito) as string[];

            if (this.tipoNegociacaoAtual && this.tipoNegociacaoAtual.CODTIPVENDA > 0) {
                this.setarControles(this.tipoNegociacaoAtual);
            }
            overlayRef.dispose();
        },
        (error) => {
            console.error(error);
            overlayRef.dispose();
        });

        this.tipoCartaoControl.valueChanges.subscribe((value: string) => {
            this.parcelaCartaoControl.setValue('');
            this.parcelaCartaoControl.disable();

            if ('Crédito' == value) {
                this.listaBandeiras = [...this.listaBandeirasCredito];
            }

            if (this.listaBandeiras.length == 1) {
                this.bandeiraCartaoControl.setValue(this.listaBandeiras[0]);
            }
        });

        this.bandeiraCartaoControl.valueChanges.subscribe((value: string) => {
            if ('Crédito' == this.tipoCartaoControl.value) {
                this.listaParcelas = this.gerarListaParcelasCredito(value);
                this.parcelaCartaoControl.setValue('');
                this.parcelaCartaoControl.enable();
            }
            if ('Débito' == this.tipoCartaoControl.value) {
                this.handleSelecionado(this.tipoCartaoControl.value, this.bandeiraCartaoControl.value, '');
            }
        });

        this.parcelaCartaoControl.valueChanges.subscribe((value: string) => {
            this.handleSelecionado(this.tipoCartaoControl.value, this.bandeiraCartaoControl.value, this.parcelaCartaoControl.value);
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.tipoNegociacaoAtual && this.tipoNegociacaoAtual.CODTIPVENDA == 0) {
            this.setarControles(this.tipoNegociacaoAtual);
        }

        if (changes.autocompleteEnabled) {
            if (changes.autocompleteEnabled.currentValue) {
                this.tipoCartaoControl.enable();
                this.bandeiraCartaoControl.enable();
                this.parcelaCartaoControl.enable();
            } else {
                this.tipoCartaoControl.disable();
                this.bandeiraCartaoControl.disable();
                this.parcelaCartaoControl.disable();
            }
        }

        if (changes.tipoNegociacaoAtual && !changes.tipoNegociacaoAtual.firstChange) {
            const tipo = changes.tipoNegociacaoAtual.currentValue as TipoNegociacao;
            if (changes.tipoNegociacaoAtual.previousValue) {
                const antes = changes.tipoNegociacaoAtual.previousValue as TipoNegociacao;
                if (tipo.CODTIPVENDA === antes.CODTIPVENDA) {
                    return;
                }
            }

            this.tipoNegociacaoAtual = {
                CODTIPVENDA: tipo.CODTIPVENDA,
                SUBTIPOVENDA: tipo.SUBTIPOVENDA,
                DESCRTIPVENDA: tipo.DESCRTIPVENDA,
            };
            this.setarControles(this.tipoNegociacaoAtual);
        }
    }

    setarControles(tipo: TipoNegociacao) {
        if (tipo && tipo.CODTIPVENDA == 0) {
            this.tipoCartaoControl.setValue('');
            this.bandeiraCartaoControl.setValue('');
            this.parcelaCartaoControl.setValue('');
        } else if (tipo && tipo.CODTIPVENDA > 0) {
            this.listaTiposNegociacao.forEach(tipo => {
                if (tipo.CODTIPVENDA == this.tipoNegociacaoAtual.CODTIPVENDA) {
                    if (tipo.SUBTIPOVENDA == SubTipo.CREDITO.toString()) {
                        this.tipoCartaoControl.setValue('Crédito');
                    } else if (tipo.SUBTIPOVENDA == SubTipo.DEBITO.toString()) {
                        this.tipoCartaoControl.setValue('Débito');
                    }
                    this.bandeiraCartaoControl.setValue(tipo.bandeira);
                    this.parcelaCartaoControl.setValue(tipo.parcelas);
                }
            });
        }
    }

    gerarListaParcelasCredito(bandeira: string) {
        const setParcelas = new Set();
        this.listaTiposNegociacao.forEach(tipo => {
            if (tipo.SUBTIPOVENDA == SubTipo.CREDITO.toString() && tipo.bandeira == bandeira) {
                setParcelas.add(tipo.parcelas);
            }
        });
        return Array.from(setParcelas) as string[];
    }

    handleSelecionado(tipo: string, bandeira: string, parcelas: string) {
        let subTipo = 'x';
        if ('Crédito' == this.tipoCartaoControl.value) {
            subTipo = SubTipo.CREDITO.toString();
        } else if ('Débito' == this.tipoCartaoControl.value) {
            subTipo = SubTipo.DEBITO.toString();
        }

        this.listaTiposNegociacao.forEach(tipo => {
            if (tipo.SUBTIPOVENDA == subTipo && tipo.bandeira == bandeira && tipo.parcelas == parcelas) {
                this.tipoNegociacaoAtual = {
                    CODTIPVENDA: tipo.CODTIPVENDA,
                    SUBTIPOVENDA: tipo.SUBTIPOVENDA,
                    DESCRTIPVENDA: tipo.DESCRTIPVENDA,
                };
                this.tipoNegociacaoSelecionado.emit(this.tipoNegociacaoAtual);
            }
        });
    }
}
