import { Component, OnInit } from '@angular/core';
import { PortalServiceService } from 'src/app/services/portal-service.service';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-esqueci-senha',
  templateUrl: './esqueci-senha.component.html',
  styleUrls: ['./esqueci-senha.component.scss']
})
export class EsqueciSenhaComponent implements OnInit {

  logomarca: string = 'assets/logooficial-fundobranco-web.png';
  usuario: string;
  email: string;
  sucesso: boolean;

  constructor(
    private portalService: PortalServiceService,
    private snackbar: MatSnackBar,

    private router: Router
  ) {
    this.usuario = '';
    this.email = '';
  }

  ngOnInit() {
    if (localStorage.getItem('token')) {
        this.router.navigateByUrl('/');
    }
    this.portalService.getConfigUrl().subscribe((config: any) => {
      this.logomarca = config.imagens.url + '/novalogo/logooficial-fundobranco-web.png';
    })
  }

  enviar() {

    if (!this.usuario || !this.email) {
      this.openSnackbar('Informe nome de usuário e senha!');
      return false;
    }

    this.portalService.enviaEmailSenha(this.usuario, this.email).subscribe((res: any) => {
      if (res.serviceResponse === 'OK') {
        this.sucesso = true;
        this.openSnackbar('Email enviado!');
      } else {
        if (res.erro === 'NAO_ENCONTRADO') {
          this.openSnackbar('Usuário não encontrado!');
        } else {
          this.openSnackbar('Ocorreu um erro no servidor, por favor, tente novamente em alguns instantes.');
        }
        return false;
      }
    }, (erro: any) => {
      this.openSnackbar('Ocorreu um erro de comunicação! Recarregue sua página!');
      return false;
    });

  }

  openSnackbar(mensagem: string) {
    this.snackbar.open(mensagem, 'Fechar', {
      horizontalPosition: 'end',
      verticalPosition: 'top',
      duration: 5000
    });
  }

  retornarLogin() {
    this.router.navigateByUrl('/',);
  }
}
