import { Component, ElementRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Books } from './local-interfaces/books-interfaces';
import { SpinnerComponent } from '../global-components/spinner/spinner.component';
import { Overlay } from '@angular/cdk/overlay';
import { PortalJpaService } from '../services/portal-jpa.service';
import { PortalServiceService } from '../services/portal-service.service';
import { Response } from '../global-interfaces/response';
import Chart from 'chart.js/auto';

@Component({
    selector: 'app-gerenciamento-books',
    templateUrl: './gerenciamento-books.component.html',
    styleUrls: ['./gerenciamento-books.component.scss'],
})
export class GerenciamentoBooksComponent implements OnInit {
    //Variáveis das colunas de cada tabela.
    displayedColumns: string[] = ['codprod', 'projeto', 'descricao', 'inclusao', 'fotos', 'cartela', 'briefing', 'compras', 'qualidade', 'obs'];
    displayedColumns2: string[] = ['projeto', 'descricao', 'responsavel', 'mreprov', 'obs'];
    displayedColumns3: string[] = ['projeto', 'descricao', 'responsavel', 'mreprov', 'obs'];
    displayedColumns4: string[] = ['projeto', 'descricao', 'inclusao', 'obs'];
    displayedColumns5: string[] = ['codprod', 'projeto', 'descricao', 'grupo', 'inclusao', 'fotos', 'cartela', 'briefing', 'compras', 'qualidade', 'responsavel', 'status', 'mreprov', 'obs', 'progcart'];
    //Variáveis de atualização.
    atualizacaoAutomatica: any;
    desabilitaBtn = false;
    //Variáveis de dados vindo do backend, Tabelas.
    dataSource: Books[];
    dataSource2: Books[];
    dataSource3: Books[];
    dataSource4: Books[];
    dataSource8: Books[];
    //Variáveis de dados vindo do backend, Gráficos.
    datap: any[] = [];
    datap1: any[] = [];
    datap2: any[] = [];
    datac: any[] = [];
    datac1: any[] = [];
    datac2: any[] = [];
    //Variáveis dos gráficos Pizza, Colunas colaboradores e total
    //(Chart<'pie', any[], string>)Esse tipo de declaração e o formato mais especifico, gráfico de pizza não funciona muito bem sem essa declaração
    grafPizzaTotal: Chart<'pie', any[], string>;
    //(Chart)Esse é o tipo de declaração mais genérica, e boa para atender o caso dos colaboradores, se entrar mais algum ele vaiser puxado automaticamente.
    colunasColab: Chart;
    colunasTotal: Chart;

    // configuração para mover a tela entre os cards
    @ViewChild('card1', { static: false }) private card1: ElementRef;
    @ViewChild('card2', { static: false }) private card2: ElementRef;
    @ViewChild('card3', { static: false }) private card3: ElementRef;

    constructor(
        public overlay: Overlay,
        private portalJpaService: PortalJpaService,
        public viewContainerRef: ViewContainerRef,
        private portalServices: PortalServiceService,
    ) {}

    // configuração para pintar a linha da cor do colaborador designado
    // getRowClass(row: any): string {
    //     if (row.STATUS === 'EM PRODUÇÃO') {
    //         switch (row.RESPONSAVEL) {
    //             case 'NBIASUTTI':
    //                 return 'em-producao-nbiasutti';
    //             case 'LGONORING':
    //                 return 'em-producao-lgonoring';
    //             case 'LPEREIRA':
    //                 return 'em-producao-lpereira';
    //             // Adicione mais responsáveis conforme necessário
    //             default:
    //                 return 'em-producao';
    //         }
    //     }
    //     return '';
    // }

    ngOnInit() {
        // gráfico de coluna dos colaboradores
        this.colunasColab = new Chart('colunas-colab', {
            type: 'bar',
            data: {
                labels: this.datac2,
                datasets: [
                    {
                        label: 'Em Produção',
                        data: this.datac,
                        backgroundColor: [
                            // 'rgba(173, 7, 59)',
                            'rgba(27, 155, 219)',
                            // 'rgba(38, 33, 63)',
                        ],
                        barThickness: 100,
                        maxBarThickness: 80,
                    },
                    {
                        label: 'Concluído',
                        data: this.datac1,
                        backgroundColor: [
                            // 'rgba(173, 7, 59)',
                            // 'rgba(27, 155, 219)',
                            'rgba(38, 33, 63)',
                        ],
                        barThickness: 100,
                        maxBarThickness: 80,
                    },
                ],
            },
            options: {
                responsive: true,
                scales: {
                    yAxes: {
                        stacked: false,
                        beginAtZero: true,
                        grid: {
                            display: true,
                            lineWidth: 0.2,
                            color: '#000000',
                            borderColor: '#000000',
                            drawTicks: true,
                            tickWidth: 1,
                            tickColor: '#000000',
                        },
                        ticks: {
                            stepSize: 1,
                            color: '#000000',
                            font: {
                                weight: 'bolder',
                            },
                        },
                    },
                    xAxes: {
                        stacked: false,
                        beginAtZero: true,
                        grid: {
                            display: false,
                            borderColor: '#000000',
                        },
                        ticks: {
                            color: '#000000',
                            font: {
                                weight: 'bolder',
                            },
                        },
                    },
                },
                plugins: {
                    legend: {
                        position: 'bottom',
                    },
                    title: {
                        display: true,
                        text: 'Por Colaborador',
                        font: {
                            size: 18,
                        },
                        color: '#000000',
                    },
                },
            },
        });

        // gráfico de coluna total
        this.colunasTotal = new Chart('colunas-total', {
            type: 'bar',
            data: {
                labels: this.datap,
                datasets: [
                    {
                        // label: 'Em Aberto',
                        data: this.datap1,
                        backgroundColor: ['rgba(173, 7, 59)', 'rgba(27, 155, 219)', 'rgba(38, 33, 63)'],
                        barThickness: 100,
                        maxBarThickness: 80,
                    },
                ],
            },
            options: {
                responsive: true,
                scales: {
                    yAxes: {
                        stacked: false,
                        beginAtZero: true,
                        grid: {
                            display: true,
                            lineWidth: 0.2,
                            color: '#000000',
                            borderColor: '#000000',
                            drawTicks: true,
                            tickWidth: 1,
                            tickColor: '#000000',
                        },
                        ticks: {
                            stepSize: 1,
                            color: '#000000',
                            font: {
                                weight: 'bolder',
                            },
                        },
                    },
                    xAxes: {
                        stacked: false,
                        beginAtZero: true,
                        grid: {
                            display: false,
                            borderColor: '#000000',
                        },
                        ticks: {
                            color: '#000000',
                            font: {
                                weight: 'bolder',
                            },
                        },
                    },
                },
                plugins: {
                    legend: {
                        display: false,
                        position: 'bottom',
                    },
                    title: {
                        display: true,
                        text: 'Produção Total',
                        font: {
                            size: 18,
                        },
                        color: '#000000',
                    },
                },
            },
        });

        this.grafPizzaTotal = new Chart('graf-pizza-total', {
            type: 'pie',
            data: {
                labels: this.datap,
                datasets: [
                    {
                        label: 'Total',
                        data: this.datap1,
                        backgroundColor: ['rgba(173, 7, 59)', 'rgba(27, 155, 219)', 'rgba(38, 33, 63)'],
                        hoverOffset: 6,
                    },
                ],
            },
            options: {
                plugins: {
                    title: {
                        display: true,
                        text: 'Produção Total',
                        font: {
                            size: 18,
                        },
                        color: '#000000',
                    },
                },
            },
        });
        //Atualiza toda a tela com os novos dados do backend
        this.atualizar();
        //Efetua a atualização de 5 em 5 minutos (300000s)
        this.atualizacaoAutomatica = setInterval(() => this.atualizar(), 300000);
    }

    ngOnDestroy() {
        clearInterval(this.atualizacaoAutomatica);
    }
    // atualização dos dados da tabela via dataSource.
    atualizar() {
        // Zera os dados para popular os graficos com os novos dados
        this.datap = [];
        this.datap1 = [];
        this.datac = [];
        this.datac1 = [];
        this.datac2 = [];
        this.datap2 = [];

        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);
        this.desabilitaBtn = true;
        this.portalServices.openSnackbar('As informações estão sendo atualizadas');
        this.portalJpaService.getGerenciamentoBooks().subscribe((books: Response) => {     
            // dados referente a tabela (Em Aberto)       
            this.dataSource = books.response.genBook;
            // dados referente a tabela (Liberado Produzir)
            this.dataSource2 = books.response.genBook2;
            // dados referente a tabela (Em Produção)
            this.dataSource3 = books.response.genBook3;
            // dados referente a tabela (Liberado Conferir)
            this.dataSource4 = books.response.genBook4;
            // dados referente a tabela (Tabela Completa)
            this.dataSource8 = books.response.genBook8;
            // dados referente ao gráfico de pizza e coluna total  (grafPizzaTotal)(colunasTotal)
            books.response.genBook9.forEach((element: any) => {
                this.datap.push(element.STATUS);
                this.datap1.push(element.TOTAL);
            });
            // gráfico de pizza (grafPizzaTotal), atualiza o gráfico junto com a (atualizacaoAutomatica)
            this.grafPizzaTotal.data.labels = this.datap;
            this.grafPizzaTotal.data.datasets[0].data = this.datap1;
            this.grafPizzaTotal.update();
            // gráfico de Coluna total (colunasTotal), atualiza o gráfico junto com a (atualizacaoAutomatica)
            this.colunasTotal.data.labels = this.datap;
            this.colunasTotal.data.datasets[0].data = this.datap1;
            this.colunasTotal.update();
            // dados referente a legenda da tabela completa
            books.response.genBook9.forEach((element5: any) => {
                this.datap2.push(element5.TOTAL);
            });
            // dados referente ao gráfico de coluna colaboradores (colunasColab)
            books.response.genBook5.forEach((element2: any) => {
                this.datac.push(element2.EMPRODUCAO);
                this.datac1.push(element2.CONCLUIDO);
                this.datac2.push(element2.RESPONSAVEL);
            });
            // gráfico de Coluna colaboradores (colunasColab), atualiza o gráfico junto com a (atualizacaoAutomatica)
            this.colunasColab.data.labels = this.datac2;
            this.colunasColab.data.datasets[0].data = this.datac;
            this.colunasColab.data.datasets[1].data = this.datac1;
            this.colunasColab.update();

            overlayRef.dispose();
        });

        // Ativa a movimentação da tela entre os cards
        let view: any;
        let counter: number = 0;

        // Função para atualizar a visualização
        const updateView = () => {
            if (counter === 0) {
                view = window.document.getElementById('card1');
                setTimeout(updateView, 90 * 1000); // 1:30 minutos para o card1
            } else if (counter === 1) {
                view = window.document.getElementById('card2');
                setTimeout(updateView, 120 * 1000); // 2 minutos para o card2
            } else if (counter === 2) {
                view = window.document.getElementById('card3');
                setTimeout(updateView, 90 * 1000); // 1:30 minutos para o card3
            }

            view.scrollIntoView({ behavior: 'smooth', block: 'end' });
            counter = (counter + 1) % 3; // Incrementa o contador e reseta para 0 após 3
        };

        // Executa a função imediatamente para começar no card1
        updateView();
    }
}
