import { Component, OnInit } from '@angular/core';
import { FiltroRelatorioPedidos } from './local-interfaces/filtro-relatorio-pedidos-interface';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-relatorio-pedidos',
  templateUrl: './relatorio-pedidos.component.html',
  styleUrls: ['./relatorio-pedidos.component.scss']
})
export class RelatorioPedidosComponent implements OnInit {

    constructor(private snackBar: MatSnackBar) {
        this.filtroRelatorioPedidos = {
            pedido: { de: 0, ate: 9999999 },
            data: { de: '01/01/1900', ate: '31/12/9999' },
            representante: { de: 0, ate: 999 }
        };
    }
    
    ngOnInit() { }

    filtroRelatorioPedidos: FiltroRelatorioPedidos;

    gerar() {
        // Caso o usuário deixe o campo vazio
        if (this.filtroRelatorioPedidos.pedido.de === null || this.filtroRelatorioPedidos.pedido.ate === null) {
            this.openSnackbar('Preencha por completo a faixa de pedidos!');
            return false;
        }

        if (this.filtroRelatorioPedidos.data.de === '' || this.filtroRelatorioPedidos.data.ate === '') {
            this.openSnackbar('Preencha por completo a faixa de data!');
            return false;
        }

        if (this.filtroRelatorioPedidos.representante.de === null || this.filtroRelatorioPedidos.representante.ate === null) {
            this.openSnackbar('Preencha por completo a faixa de representante!');
            return false;
        }

        // Caso o usuário informe pedido inicial maior que o final
        if (this.filtroRelatorioPedidos.pedido.de > this.filtroRelatorioPedidos.pedido.ate) {
            this.openSnackbar('Pedido inicial maior que o final');
            return false;
        }

        // Caso o usuário informe data inicial maior que a final
        if (this.filtroRelatorioPedidos.data.de > this.filtroRelatorioPedidos.data.ate) {
            this.openSnackbar('Data inicial maior que a final');
            return false;
        }

        // Caso o usuário informe representante inicial maior que o final
        if (this.filtroRelatorioPedidos.representante.de > this.filtroRelatorioPedidos.representante.ate) {
            this.openSnackbar('Código de representante inicial maior que o final');
            return false;
        }
        console.table(this.filtroRelatorioPedidos);
    }

    limpar() {
        this.filtroRelatorioPedidos = {
            pedido: { de: 0, ate: 9999999 },
            data: { de: '01/01/1900', ate: '31/12/9999' },
            representante: { de: 0, ate: 999 }
        };
    }

    openSnackbar(mensagem: string) {
        this.snackBar.open(mensagem, 'Fechar', {
            horizontalPosition: 'end',
            verticalPosition: 'top',
            duration: 5000
        });
    }
}
