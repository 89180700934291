import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

@Injectable()
export class TransfereServiceDialog {

  constructor() { }

  data
  public setData(data: any) {
    this.data = data;
  }

  public getData(): Observable<any> {
    return this.data;
  }

}