import { Component, OnInit, Inject, ViewContainerRef, } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';


@Component({
    selector: 'app-bottom-sheet-notificacao',
    templateUrl: './bottom-sheet-notificacao.component.html',
    styleUrls: ['./bottom-sheet-notificacao.component.scss']
})
export class BottomSheetNotificacaoComponent implements OnInit {

    constructor(
        private bottomSheetRef: MatBottomSheetRef<BottomSheetNotificacaoComponent>,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    ) { }

    ngOnInit() {
        console.log('MAT_BOTTOM_SHEET_DATA, data=', this.data)
    }

    sairBottomSheet(retorno: string): void {
        // console.log('Clicou em algum botão para fechar o bottom sheet, retorno=', retorno)
        this.bottomSheetRef.dismiss(retorno);
    }


}
