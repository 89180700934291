import { BottomSheetAprovacaoDevolucaoComponent } from './devolucao-consulta/local-components/bottom-sheet-aprovacao-devolucao/bottom-sheet-aprovacao-devolucao.component';
// Angular stuffs
import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CountdownModule } from 'ngx-countdown';

// Grid Layout Library
import { FlexLayoutModule } from '@angular/flex-layout';

// Main component
import { AppComponent } from './app.component';

// Pages
import { HomeComponent } from './home/home.component';
import { CriarPedidoComponent } from './criar-pedido/criar-pedido.component';
import { CriarPedidoItensPedidoComponent } from './criar-pedido/criar-pedido-itens-pedido/criar-pedido-itens-pedido.component';
import { CriarPedidoCondPagtoComponent } from './criar-pedido/criar-pedido-cond-pagto/criar-pedido-cond-pagto.component';
import { CriarPedidoTransportadoraComponent } from './criar-pedido/criar-pedido-transportadora/criar-pedido-transportadora.component';
import { CriarPedidoObservacoesComponent } from './criar-pedido/criar-pedido-observacoes/criar-pedido-observacoes.component';
import { PedidosComponent } from './pedidos/pedidos.component';
import { LiberacoesComponent } from './liberacoes/liberacoes.component';
import { RelatoriosComponent } from './relatorios/relatorios.component';
import { PedidosOperacionalComponent } from './pedidos-operacional/pedidos-operacional.component';

// Global components
import { AutocompleteComponent } from './global-components/autocomplete/autocomplete.component';
import { PaginacaoComponent } from './global-components/paginacao/paginacao.component';
import { BottomSheetComponent } from './global-components/bottom-sheet/bottom-sheet.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { FormataDecimalPipe } from './global-components/pipes/formata-decimal-pipe';
import { MoneyFormatPipe } from './global-components/pipes/money-format-pipe';
import { DataFormatPipe } from './global-components/pipes/data-format-pipe';

// Services
import { SankhyaServiceService } from './services/sankhya-service.service';

// Material Design Components
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatBadgeModule } from '@angular/material/badge';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RelatorioTabelaDePrecoComponent } from './relatorios/relatorio-tabela-de-preco/relatorio-tabela-de-preco.component';
import { RelatorioProntaEntregaComponent } from './relatorios/relatorio-pronta-entrega/relatorio-pronta-entrega.component';
import { RelatorioProgramadoComponent } from './relatorios/relatorio-programado/relatorio-programado.component';
import { DialogComponent } from './global-components/dialog/dialog.component';
import { DialogBasicComponent } from 'src/app/global-components/dialog-basic/dialog-basic.component';
import { DialogCompartilharComponent } from 'src/app/galeria-imagens/local-components/dialog-compartilhar/dialog-compartilhar.component';
import { MatDialogModule } from '@angular/material/dialog';
import { CadastrarClienteComponent } from './cadastrar-cliente/cadastrar-cliente.component';
import { LoginComponent } from './login/login.component';
import { RelatorioPedidosComponent } from './relatorios/relatorio-pedidos/relatorio-pedidos.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SpinnerComponent } from './global-components/spinner/spinner.component';
import { RelatorioClientesComponent } from './relatorios/relatorio-clientes/relatorio-clientes.component';
import { GerenciarContaComponent } from './gerenciar-conta/gerenciar-conta.component';
import { AlterarSenhaComponent } from './gerenciar-conta/alterar-senha/alterar-senha.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { TagStatusComponent } from './pedidos/local-components/tag-status/tag-status.component';
import { RelatorioProgramadoPercentualComponent } from './relatorios/relatorio-programado-percentual/relatorio-programado-percentual.component';

import { PedidosLegadoComponent } from './pedidos-legado/pedidos-legado.component';
import { RelatorioTitulosComponent } from './relatorios/relatorio-titulos/relatorio-titulos.component';
import { MatDatepickerModule, MatDatepicker } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { DatePipe, registerLocaleData } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { GaleriaImagensComponent } from './galeria-imagens/galeria-imagens.component';

// Biblioteca de galeria de imagens
import { NgxGalleryModule } from 'ngx-gallery';

import { RelatorioTabelaDePrecoGerencialComponent } from './relatorios/relatorio-tabela-de-preco-gerencial/relatorio-tabela-de-preco-gerencial.component';
import { RelatorioFichaClienteComponent } from './relatorios/relatorio-ficha-cliente/relatorio-ficha-cliente.component';
import { SegundaViaBoletoComponent } from './segunda-via-boleto/segunda-via-boleto.component';
import { ConsultaClientesComponent } from './consulta-clientes/consulta-clientes.component';
import { RelatorioChequeAbertoComponent } from './relatorios/relatorio-cheque-aberto/relatorio-cheque-aberto.component';
import { AprovacaoFinanceiraComponent } from './aprovacao-financeira/aprovacao-financeira.component';
import { RelatorioProntaEntregaResumumidoComponent } from './relatorios/relatorio-pronta-entrega-resumumido/relatorio-pronta-entrega-resumumido.component';
import { RelatorioProgramadoResumidoComponent } from './relatorios/relatorio-programado-resumido/relatorio-programado-resumido.component';
import { TabelaDePrecoComponent } from './tabela-de-preco/tabela-de-preco.component';
import { FecharPedidoComponent } from './fechar-pedido/fechar-pedido.component';
import { BooksComponent } from './books/books.component';
import { BloqueioClienteComponent } from './bloqueio-cliente/bloqueio-cliente.component';
import { RelatorioRomaneioComponent } from './relatorios/relatorio-romaneio/relatorio-romaneio.component';
import { RelatorioClientesBloqueadosComponent } from './relatorios/relatorio-clientes-bloqueados/relatorio-clientes-bloqueados.component';
import { RelatorioPedidosFaixaComponent } from './relatorios/relatorio-pedidos-faixa/relatorio-pedidos-faixa.component';
import { RelatorioSnapShotPEComponent } from './relatorios/relatorio-snapshotpe/relatorio-snapshotpe.component';
import { RelatorioSnapShotPOComponent } from './relatorios/relatorio-snapshotpo/relatorio-snapshotpo.component';
import { RelatorioTitulosLiquidadosComponent } from './relatorios/relatorio-titulos-liquidados/relatorio-titulos-liquidados.component';
import { RelatorioPlanilhaComprasComponent } from './relatorios/relatorio-planilha-compras/relatorio-planilha-compras.component';
import { RelatorioPedidosFaixaSemTotalComponent } from './relatorios/relatorio-pedidos-faixa-sem-total/relatorio-pedidos-faixa-sem-total.component';
import { RelatorioPedidosFaixaSemTotalScComponent } from './relatorios/relatorio-pedidos-faixa-sem-total-sc/relatorio-pedidos-faixa-sem-total-sc.component';
import { RelatorioFichaClienteNovoComponent } from './relatorios/relatorio-ficha-cliente-novo/relatorio-ficha-cliente-novo.component';

import { ConsultaProdutosComponent } from './consulta-produtos/consulta-produtos.component';
import { ProgramacaoOperacionalComponent } from 'src/app/programacao-operacional/programacao-operacional.component';
import { SegundaViaBoletoComponentSNK } from './segunda-via-boleto-snk/segunda-via-boleto-snk.component';
import { GraficosComponent } from './graficos/graficos.component';
import { SeriesPipe } from './graficos/series.pipe';
import { EditorModule } from '@tinymce/tinymce-angular';

//devolucao

import { DevolucaoCadastroComponent } from './devolucao-cadastro/devolucao-cadastro.component';
import { DevolucaoConsultaComponent } from './devolucao-consulta/devolucao-consulta.component';
import { DevolucaoAprovacaoComponent } from './devolucao-aprovacao/devolucao-aprovacao.component';
import { TagStatusDevComponent } from './devolucao-consulta/local-components/tag-status-dev/tag-status-dev.component';
import { TransfereService } from './services/transfere-dado.service';
import { TransfereServiceDialog } from './services/transfere-dado-dialog.service';
import { NotificacaoModule } from './global-components/notificacao/notificacao.module';
import { ForcedAlterarSenhaComponent } from './login/forced-alterar-senha/forced-alterar-senha.component';
import { EsqueciSenhaComponent } from './login/esqueci-senha/esqueci-senha.component';

//qrCode
import { QrCodeComponent } from './qr-code/qr-code.component';
import { DialogQrCodeComponent } from './qr-code/dialog-qrcode/dialog-qrcode.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { ConsultaCadastroClientesComponent } from './consulta-cadastro-clientes/consulta-cadastro-clientes.component';
import { TagStatusCadCliComponent } from './consulta-cadastro-clientes/tag-status-cad-cli/tag-status-cad-cli.component';
import { PedidosFinanceiroComponent } from './pedidos-financeiro/pedidos-financeiro.component';
import { BottomSheetPedidosFinanceiroComponent } from './pedidos-financeiro/local-components/bottom-sheet-pedidos-financeiro/bottom-sheet-pedidos-financeiro.component';
import { BottomSheetPedidosComponent } from './pedidos/local-components/bottom-sheet-pedidos/bottom-sheet-pedidos.component';

import { BackgroundDirective } from './background.directive';
import { RightQuestDirective } from './rightQuest.directive';
import { BottomSheetComissaoComponent } from './criar-pedido/local-components/bottom-sheet-comissao/bottom-sheet-comissao.component';
import { ControleEmbarqueComponent } from './controle-embarque/controle-embarque.component';
import { PedidosOperacionalScComponent } from './pedidos-operacional-sc/pedidos-operacional-sc.component';
import { PedidosOperacionalEsComponent } from './pedidos-operacional-es/pedidos-operacional-es.component';
import { ComissoesComponent } from './comissoes/comissoes.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ApoioOperacionalComponent } from 'src/app/apoio-operacional/apoio-operacional.component';
import { ImprimirRomaneioComponent } from 'src/app/apoio-operacional/imprimir-romaneio/imprimir-romaneio.component';
import { SelecionarModoComponent } from './qr-code/selecionar-modo/selecionar-modo.component';
import { RelatorioTabelaDePrecoExcecaoComponent } from './relatorios/relatorio-tabela-de-preco-excecao/relatorio-tabela-de-preco-excecao.component';
import { BottomSheetJuntarPedidoComponent } from './criar-pedido/local-components/bottom-sheet-juntar-pedido/bottom-sheet-juntar-pedido.component';
import { GerenciamentoBooksComponent } from './gerenciamento-books/gerenciamento-books.component';

import { InterceptorHeaders } from './auth/interceptor-headers';
import { InterceptorHttp401 } from './auth/interceptor-http401';
import { ConsultaSerasaComponent } from './consulta-serasa/consulta-serasa.component';
import { ConsultaSerasaPipe } from './consulta-serasa/local-components/consulta-serasa.pipe';
import { RelatorioProgramadoPercentualGerencialComponent } from './relatorios/relatorio-programado-percentual-gerencial/relatorio-programado-percentual-gerencial.component';
import { PedidosLiberadoSepararComponent } from './pedidos-liberado-separar/pedidos-liberado-separar.component';
import { BottomSheetRastreioComponent } from './pedidos/local-components/bottom-sheet-rastreio/bottom-sheet-rastreio.component';
import { ControleEmbarqueResumidoComponent } from './controle-embarque-resumido/controle-embarque-resumido.component';
import { MatStepperModule } from '@angular/material';
import localePt from '@angular/common/locales/pt';
import { PaginacaoLocalComponent } from './global-components/paginacao-local/paginacao-local.component';
import { BottomSheetDownloadComponent } from './global-components/bottom-sheet-download/bottom-sheet-download.component';
import { BottomSheetRegrasDescontoComponent } from './liberacoes/local-components/bottom-sheet-regras-desconto/bottom-sheet-regras-desconto.component';
import { ControleEmbarqueMoveleiroComponent } from './controle-embarque-moveleiro/controle-embarque-moveleiro.component';
import { BottomSheetCadastroComponent } from './cadastrar-cliente/local-components/bottom-sheet-cadastro/bottom-sheet-cadastro.component';
import { ItensProjecaoChegadaComponent } from './itens-projecao-chegada/itens-projecao-chegada.component';
import { PedidosOperacionalFilialEsComponent } from './pedidos-operacional-filial-es/pedidos-operacional-filial-es.component';
import { DefinirSeparadorComponent } from './definir-separador/definir-separador.component';
import { AutocompleteClientePedidoModule } from './global-components/autocomplete-cliente-pedido/autocomplete-cliente-pedido.module';
import { AutocompleteClienteRemessaModule } from './global-components/autocomplete-cliente-remessa/autocomplete-cliente-remessa.module';
import { AutocompleteTipoNegociacaoCartaoModule } from './global-components/autocomplete-tipo-negociacao-cartao/autocomplete-tipo-negociacao-cartao.module';
import { AutocompleteTipoNegociacaoParcelaModule } from './global-components/autocomplete-tipo-negociacao-parcela/autocomplete-tipo-negociacao-parcela.module';
import { AutocompleteTransportadoraModule } from './global-components/autocomplete-transportadora/autocomplete-transportadora.module';
import { ProgramacaoOperacionalFilialEsComponent } from './programacao-operacional-filial-es/programacao-operacional-filial-es.component';
import { ProgramacaoOperacionalScComponent } from './programacao-operacional-sc/programacao-operacional-sc.component';
import { RelatorioTabelaDePreco2Component } from './relatorios/relatorio-tabela-de-preco2/relatorio-tabela-de-preco2.component';
import { RelatorioTabelaDePreco2GerencialComponent } from './relatorios/relatorio-tabela-de-preco2-gerencial/relatorio-tabela-de-preco2-gerencial.component';
import { BottomSheetGrupoDeProdutoComponent } from './global-components/bottom-sheet-grupo-de-produto/bottom-sheet-grupo-de-produto.component';
import { RelatorioFichaTecnicaProdutoComponent } from './relatorios/relatorio-ficha-tecnica-produto/relatorio-ficha-tecnica-produto.component';
import { AutocompleteProdutosSemFiltroComponent } from './global-components/autocomplete-produtos-sem-filtro/autocomplete-produtos-sem-filtro.component';
import { BottomSheetAnexosDevComponent } from './devolucao-consulta/local-components/bottom-sheet-anexos-dev/bottom-sheet-anexos-dev.component';
import { BottomSheetProdutosDevComponent } from './devolucao-consulta/local-components/bottom-sheet-produtos-dev/bottom-sheet-produtos-dev.component';
import { BottomSheetEditarListaImgComponent } from './galeria-imagens/local-components/bottom-sheet-editar-lista-img/bottom-sheet-editar-lista-img.component';
import { PositivacaoClientesComponent } from './positivacao-clientes/positivacao-clientes.component';
import { PedidosFaturarEsComponent } from './pedidos-faturar-es/pedidos-faturar-es.component';
import { ControleEmbarqueToolTipModule } from './controle-embarque/local-components/controle-embarque-tool-tip/controle-embarque-tool-tip.module';
import { GraficoChamadosDiarioComponent } from './chamados/graficos/grafico-chamados-diario/grafico-chamados-diario.component';
import { ItensProjecaoChegadaMoveleiroComponent } from './itens-projecao-chegada-moveleiro/itens-projecao-chegada-moveleiro.component';
import { AgendamentoReuniaoComponent } from './agendamento-reuniao/agendamento-reuniao.component';
import { NovoAgendamentoComponent } from './agendamento-reuniao/novo-agendamento/novo-agendamento.component';
import { PedidosFinanceiroCobrancaComponent } from './pedidos-financeiro-cobranca/pedidos-financeiro-cobranca.component';
import { BottomSheetCobrancaComponent } from './pedidos-financeiro-cobranca/local-components/bottom-sheet-cobranca/bottom-sheet-cobranca.component';
import { DialogOcorrenciasComponent } from './pedidos-financeiro-cobranca/local-components/dialog-ocorrencias/dialog-ocorrencias.component';
import { CardCabecalhoComponent } from './global-components/card-cabecalho/card-cabecalho.component';
import { DialogLiberacaoComponent } from './pedidos-financeiro-cobranca/local-components/dialog-liberacao/dialog-liberacao.component';
import { ControleEmbarqueFinalizadoComponent } from './controle-embarque-finalizado/controle-embarque-finalizado.component';
import { PendenciasCoordenador } from './pendencias-coordenador/pendencias-coordenador.component';
import { BottomSheetPendenciaCoordenadorComponent } from './pendencias-coordenador/local-components/bottom-sheet-pendencia-coordenador/bottom-sheet-pendencia-coordenador.component';

registerLocaleData(localePt, 'pt-BR');

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        CriarPedidoComponent,
        CriarPedidoItensPedidoComponent,
        CriarPedidoCondPagtoComponent,
        CriarPedidoTransportadoraComponent,
        CriarPedidoObservacoesComponent,
        AutocompleteComponent,
        PedidosComponent,
        PaginacaoComponent,
        LiberacoesComponent,
        BottomSheetComponent,
        RelatoriosComponent,
        PedidosOperacionalComponent,
        ProgramacaoOperacionalComponent,
        RelatorioTabelaDePrecoComponent,
        RelatorioProntaEntregaComponent,
        RelatorioProgramadoComponent,
        DialogComponent,
        DialogBasicComponent,
        DialogCompartilharComponent,
        CadastrarClienteComponent,
        LoginComponent,
        RelatorioPedidosComponent,
        SpinnerComponent,
        RelatorioClientesComponent,
        GerenciarContaComponent,
        AlterarSenhaComponent,
        FormataDecimalPipe,
        MoneyFormatPipe,
        TagStatusComponent,
        RelatorioProgramadoPercentualComponent,
        PedidosLegadoComponent,
        RelatorioTitulosComponent,
        GaleriaImagensComponent,
        RelatorioTabelaDePrecoGerencialComponent,
        RelatorioFichaClienteComponent,
        SegundaViaBoletoComponent,
        SegundaViaBoletoComponentSNK,
        ConsultaClientesComponent,
        ConsultaProdutosComponent,
        RelatorioChequeAbertoComponent,
        AprovacaoFinanceiraComponent,
        RelatorioProntaEntregaResumumidoComponent,
        RelatorioProgramadoResumidoComponent,
        TabelaDePrecoComponent,
        FecharPedidoComponent,
        BooksComponent,
        BloqueioClienteComponent,
        RelatorioRomaneioComponent,
        RelatorioClientesBloqueadosComponent,
        RelatorioPedidosFaixaComponent,
        RelatorioSnapShotPEComponent,
        RelatorioSnapShotPOComponent,
        RelatorioTitulosLiquidadosComponent,
        RelatorioPlanilhaComprasComponent,
        GraficosComponent,
        SeriesPipe,
        DevolucaoCadastroComponent,
        DevolucaoConsultaComponent,
        DevolucaoAprovacaoComponent,
        TagStatusDevComponent,
        ForcedAlterarSenhaComponent,
        EsqueciSenhaComponent,
        QrCodeComponent,
        DialogQrCodeComponent,
        ConsultaCadastroClientesComponent,
        TagStatusCadCliComponent,
        PedidosFinanceiroComponent,
        RelatorioPedidosFaixaSemTotalComponent,
        RelatorioPedidosFaixaSemTotalScComponent,
        BackgroundDirective,
        BottomSheetPedidosFinanceiroComponent,
        BottomSheetPedidosComponent,
        BottomSheetCadastroComponent,
        RightQuestDirective,
        BottomSheetComissaoComponent,
        ControleEmbarqueComponent,
        PedidosOperacionalScComponent,
        PedidosOperacionalFilialEsComponent,
        PedidosOperacionalEsComponent,
        ComissoesComponent,
        ApoioOperacionalComponent,
        ImprimirRomaneioComponent,
        SelecionarModoComponent,
        RelatorioTabelaDePrecoExcecaoComponent,
        BottomSheetAprovacaoDevolucaoComponent,
        BottomSheetJuntarPedidoComponent,
        RelatorioFichaClienteNovoComponent,
        ConsultaSerasaComponent,
        ConsultaSerasaPipe,
        RelatorioProgramadoPercentualGerencialComponent,
        PedidosLiberadoSepararComponent,
        BottomSheetRastreioComponent,
        ControleEmbarqueResumidoComponent,
        PaginacaoLocalComponent,
        BottomSheetDownloadComponent,
        BottomSheetRegrasDescontoComponent,
        ControleEmbarqueMoveleiroComponent,
        ItensProjecaoChegadaComponent,
        DefinirSeparadorComponent,
        ProgramacaoOperacionalFilialEsComponent,
        ProgramacaoOperacionalScComponent,
        RelatorioTabelaDePreco2Component,
        RelatorioTabelaDePreco2GerencialComponent,
        BottomSheetGrupoDeProdutoComponent,
        RelatorioFichaTecnicaProdutoComponent,
        AutocompleteProdutosSemFiltroComponent,
        BottomSheetAnexosDevComponent,
        BottomSheetProdutosDevComponent,
        BottomSheetEditarListaImgComponent,
        PositivacaoClientesComponent,
        PedidosFaturarEsComponent,
        GraficoChamadosDiarioComponent,
        ItensProjecaoChegadaMoveleiroComponent,
        AgendamentoReuniaoComponent,
        NovoAgendamentoComponent,
        DataFormatPipe,
        PedidosFinanceiroCobrancaComponent,
        BottomSheetCobrancaComponent,
        DialogOcorrenciasComponent,
        CardCabecalhoComponent,
        DialogLiberacaoComponent,
        GerenciamentoBooksComponent,
        ControleEmbarqueFinalizadoComponent,
        PendenciasCoordenador,
        BottomSheetPendenciaCoordenadorComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        BrowserAnimationsModule,
        //RouterModule.forRoot(appRoutes, { useHash: true }),
        ReactiveFormsModule,
        HttpClientModule,
        FlexLayoutModule,
        MatCardModule,
        MatDividerModule,
        MatInputModule,
        MatGridListModule,
        MatRadioModule,
        MatCheckboxModule,
        MatSlideToggleModule,
        MatTabsModule,
        MatExpansionModule,
        MatButtonModule,
        MatIconModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatListModule,
        MatToolbarModule,
        MatMenuModule,
        MatBadgeModule,
        MatSidenavModule,
        MatBottomSheetModule,
        MatSnackBarModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatTooltipModule,
        NgxGalleryModule,
        MatButtonToggleModule,
        MatTableModule,
        EditorModule,
        NotificacaoModule,
        ZXingScannerModule,
        MatPaginatorModule,
        MatSortModule,
        CountdownModule,
        MatStepperModule,
        AutocompleteClientePedidoModule,
        AutocompleteClienteRemessaModule,
        AutocompleteTipoNegociacaoCartaoModule,
        AutocompleteTipoNegociacaoParcelaModule,
        AutocompleteTransportadoraModule,
        ControleEmbarqueToolTipModule,
    ],
    entryComponents: [
        BottomSheetComponent,
        DialogComponent,
        DialogBasicComponent,
        DialogQrCodeComponent,
        DialogCompartilharComponent,
        SpinnerComponent,
        BottomSheetPedidosFinanceiroComponent,
        BottomSheetPedidosComponent,
        BottomSheetCadastroComponent,
        BottomSheetComissaoComponent,
        SelecionarModoComponent,
        BottomSheetAprovacaoDevolucaoComponent,
        BottomSheetJuntarPedidoComponent,
        BottomSheetRastreioComponent,
        BottomSheetDownloadComponent,
        BottomSheetRegrasDescontoComponent,
        BottomSheetGrupoDeProdutoComponent,
        AutocompleteProdutosSemFiltroComponent,
        BottomSheetAnexosDevComponent,
        BottomSheetProdutosDevComponent,
        BottomSheetEditarListaImgComponent,
        BottomSheetCobrancaComponent,
        DialogOcorrenciasComponent,
        DialogLiberacaoComponent,
        BottomSheetPendenciaCoordenadorComponent,
    ],
    providers: [
        SankhyaServiceService,
        { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
        { provide: LOCALE_ID, useValue: 'pt-BR' },
        DatePipe,
        TransfereService,
        TransfereServiceDialog,
        PaginacaoComponent,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptorHeaders,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptorHttp401,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
