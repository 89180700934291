import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-bsn-default',
    templateUrl: './bsn-default.component.html',
    styleUrls: ['./bsn-default.component.scss']
})
export class BsnDefaultComponent implements OnInit {

    constructor() { }

    ngOnInit() {
        //  console.log('BsnDefaultComponent parametros=', this.parametros)
    }

    @Input()
    textoNotificacao: string;

    @Input()
    parametros: any;

    @Output()
    botaoDismiss = new EventEmitter();

    sair() {
        this.botaoDismiss.emit();
    }

}
