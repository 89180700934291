import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material';
import { Router } from '@angular/router';
import { EMPTY, Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

import { AutenticacaoService } from 'src/app/services/autenticacao.service';
import { BottomSheetComponent } from 'src/app/global-components/bottom-sheet/bottom-sheet.component';

@Injectable()
export class InterceptorHttp401 implements HttpInterceptor {
    constructor(private router: Router, private bottomSheet: MatBottomSheet, private auth: AutenticacaoService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Requisição para Login não faz tratamento nenhum.
        if (req.url.match('/login')) {
            return next.handle(req);
        }
        // Requisição para Consulta no ViaCep não faz tratamento nenhum.
        if (req.url.match('viacep.com.br')) {
            return next.handle(req);
        }

        return next.handle(req).pipe(
            catchError(error => {
                if (error instanceof HttpErrorResponse && error.status == 401) {
                    const resposta: any = error.error;
                    if (resposta.interceptor) {
                        this.openBottomSheet(resposta.response);
                    }

                    this.desconectaUsu();
                }
                return throwError(error);
            })
        );
    }

    openBottomSheet(mensagem: string) {
        this.bottomSheet.open(BottomSheetComponent, {
            data: {
                param: 'interceptor',
                mensagem: {
                    message: mensagem,
                    titulo: 'Sua Sessão terminou, faça login novamente!',
                },
            },
            panelClass: 'bottom-sheet',
        });
    }

    desconectaUsu() {
        setTimeout(() => {
            this.auth.setIsLogged(false);
            localStorage.setItem('token', '');
            localStorage.setItem('refreshToken', '');
            localStorage.setItem('rotas', '');
            localStorage.setItem('refresh', '');

            this.router.navigateByUrl('/login');
        }, 1000);
    }
}
