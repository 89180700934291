import { Component, Input, OnInit, } from '@angular/core';
import { Router } from '@angular/router';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

import { BottomSheetNotificacaoComponent } from '../bottom-sheet-notificacao/bottom-sheet-notificacao.component';
import { INotificacaoBadge, INotificacaoItem } from '../interfaces/notificacao-interface';
import { NotificacaoService } from 'src/app/global-components/notificacao/services/notificacao.service';
import { PortalJpaService } from 'src/app/services/portal-jpa.service';

@Component({
    selector: 'app-badge-notificacao',
    templateUrl: './badge-notificacao.component.html',
    styleUrls: ['./badge-notificacao.component.scss']
})
export class BadgeNotificacaoComponent implements OnInit {

    constructor(
        private bottomSheet: MatBottomSheet,
        private router: Router,
        private portalJpaService: PortalJpaService,
    ) {
        // console.log('constructor - this.qtdNotficacoes', this.qtdNotficacoes)
    }

    ngOnInit() {
        // console.log('ngOnInit - this.qtdNotficacoes', this.qtdNotficacoes)
    }

    @Input() badgeObj: INotificacaoBadge;

    get qtdNotficacoes(): number {
        return this.badgeObj && this.badgeObj.notificacoes ? this.badgeObj.notificacoes.length : 0;
    }

    excluir(evento: any, item: INotificacaoItem, index: number) {
        // evento.preventDefault();
        // console.log('Clicou no Excluir', index);
        this.portalJpaService.apiPostMarcarNotificacaoLida(item.notifID, 'N').subscribe(res => console.log('lida:', res));

        this.badgeObj.notificacoes.splice(index, 1);
    }

    visualizar(evento: any, item: INotificacaoItem, index: number) {
        // console.log('Clicou no visualizar', item);
        if (item.acao.componente != 'bsn-reenviar-pedido') {
            this.portalJpaService.apiPostMarcarNotificacaoLida(item.notifID, 'S').subscribe(res => console.log('Lida:', res));
        }

        item.permiteMarcar = true;
        if (item && item.acao) {
            if (item.acao.rota) {
                this.irParaRota(item);
            } else if (item.acao.componente) {
                this.abrirBottomSheet(item).afterDismissed().subscribe(retorno => {
                    //         console.log('retorno this.abrirBottomSheet(item).afterDismissed()=', retorno)
                })
            }
        }
    }

    irParaRota(item: INotificacaoItem) {
        let rota: string;
        if (item.acao.rota.includes('?')) {
            rota = item.acao.rota + '&refresh=' + (new Date()).getTime().toString();
        } else {
            rota = item.acao.rota + '?refresh=' + (new Date()).getTime().toString();
        }
        this.router.navigateByUrl(rota, { state: { parametros: JSON.stringify(item.acao.parametros) } })
    }

    abrirBottomSheet(item: INotificacaoItem) {
        const dadosBottomSheet = {
            notifID: item.notifID,
            textoNotificacao: item.texto,
            bsnComponente: item.acao.componente,
            parametros: { ...item.acao.parametros, }
        };
        // console.log('abrirBottomSheet.dadosBottomSheet=', dadosBottomSheet)

        return this.bottomSheet.open(BottomSheetNotificacaoComponent, {
            data: dadosBottomSheet,
            panelClass: 'bottom-sheet-notificacao',
            disableClose: true,
        });
    }

}
