import { Component, OnInit } from '@angular/core';
import { PedidosPesquisa } from '../global-interfaces/pedidos-pesquisa-interface';
import { PortalJpaService } from '../services/portal-jpa.service';
import { SankhyaServiceService } from '../services/sankhya-service.service';
// import { LeituraCodigoBarrasPedido } from '../global-interfaces/leitura-codigo-barras-pedido-interface';

export interface ItemAprovacao {
    NUNOTA: number;
    CODPARC: number;
    RAZAOSOCIAL?: string;
    STATUS?: string;
    MENSAGEM?: string;

    nuNotaPedido?: number;
    liberadorAlterar?: boolean;
    liberadorAtual?: string;
    liberadorSelecionado?: string;
    liberadorIcone?: string;
}

@Component({
    selector: 'app-aprovacao-financeira',
    templateUrl: './aprovacao-financeira.component.html',
    styleUrls: ['./aprovacao-financeira.component.scss']
})

export class AprovacaoFinanceiraComponent implements OnInit {

    constructor(
        private sankhyaService: SankhyaServiceService,
        private portalJpaService: PortalJpaService,
    )   {
            this.inicializarVariaveis();
        }

    ngOnInit() {}

    // Colunas a serem exibidas na mat-tab
    displayedColumns: string[] = ['nuNota', 'razaoSocial', 'status', 'mensagem', 'acoes'];

    // Código de barras
    codigoDeBarras: number = null;

    // Atributo para armazenar o pedido que será enviado para aprovação
    pedidoTemp: PedidosPesquisa;

    // Atributo para popular a tabela de pedidos que já foram enviados para aprovação
    pedidos: ItemAprovacao[] = [];
    get getPedidos(): ItemAprovacao[] {
        return [...this.pedidos];
    }

    // Define se mostra ou não o load na tela.
    carregando: boolean = false;
    carregandoLiberador: boolean = false;

    inicializarVariaveis() {
        this.pedidoTemp = {
            NUNOTA: 0,
            RAZAOSOCIAL: '',
            CGCCPF: '',
            DTNEG: '',
            TIPOPEDIDO: '',
            STATUS: '',
            CODPARC: 0,
            OBSERVACAO: '',
            OBSERVACAOPED: '',
            DHLIB: '',
            LIBERACAO: [
                {
                    EVENTO: 0,
                    DESCRICAO: '',
                    DHSOLICIT: '',
                    VLRLIMITE: 0,
                    VLRATUAL: 0,
                    OBSERVACAO: '',
                    TABELA: '',
                    SEQUENCIA: 0
                }
            ]
        };
    }

    enviarAprovacao() {
        if (this.codigoDeBarras.toString().length === 14) {
            this.carregando = true;

            // Separar o número do pedido do código do cliente
            let nuNota: number;
            let codParc: number;

            nuNota = parseInt(this.codigoDeBarras.toString().slice(0, 8), 10);
            codParc = parseInt(this.codigoDeBarras.toString().slice(8), 10);

            // Montar o objeto de pedido temporário
            this.pedidoTemp = {
                NUNOTA: nuNota,
                RAZAOSOCIAL: '',
                CGCCPF: '',
                DTNEG: '',
                TIPOPEDIDO: '',
                STATUS: '',
                CODPARC: codParc,
                OBSERVACAO: '',
                OBSERVACAOPED: '',
                DHLIB: '',
                LIBERACAO: [
                    {
                        EVENTO: 1002,
                        DESCRICAO: '',
                        DHSOLICIT: '',
                        VLRLIMITE: 0,
                        VLRATUAL: 0,
                        OBSERVACAO: '',
                        TABELA: 'TGFCAB',
                        SEQUENCIA: 0
                    }
                ]
            };

        this.pedidos.push({
            NUNOTA: this.pedidoTemp.NUNOTA,
            CODPARC: this.pedidoTemp.CODPARC,
            RAZAOSOCIAL: ' ',
            STATUS: '',
            MENSAGEM: '',
            liberadorAlterar: false,
        });

        this.sankhyaService.aprovaPedidoLiberacao('A', this.pedidoTemp, 'aprovaPedido', 0)
            .subscribe(
                (res: any) => {
                    let tmpRazao = '';
                    if (res) {
                        if (Array.isArray(res.params) && res.params[0].paramValue) {
                            tmpRazao = res.params[0].paramValue.replace(/\"/g, '');
                        }
                        this.pedidos[this.pedidos.length - 1].RAZAOSOCIAL = tmpRazao;
                        this.pedidos[this.pedidos.length - 1].STATUS = res.serviceResponse;
                        this.pedidos[this.pedidos.length - 1].MENSAGEM = res.response;

                        if (res.serviceResponse == 'OK') {
                            this.pedidos[this.pedidos.length - 1].liberadorAlterar = true;
                            this.pedidos[this.pedidos.length - 1].nuNotaPedido = res.nuNotaPedido;
                            this.pedidos[this.pedidos.length - 1].liberadorAtual = res.adCodGestor;
                            this.pedidos[this.pedidos.length - 1].liberadorSelecionado = res.adCodGestor;
                        }

                    } else {
                        this.pedidos[this.pedidos.length - 1].MENSAGEM = 'Erro de comunicação com o Servidor'
                    }
                    // console.log(this.pedidos);
                },
                (erro) => {
                    console.log(erro)
                },
                () => {
                    // Complete, equivale ao finaly
                    this.carregando = false;
                }
            );

            this.codigoDeBarras = null;
        }
    }

    enviarLiberador(pedido: ItemAprovacao) {
        // console.log('On Click do botão:', JSON.parse(JSON.stringify(pedido)));
        this.carregandoLiberador = true;
        this.portalJpaService.apiPostAlterarGestorLiberador(pedido.nuNotaPedido, pedido.liberadorSelecionado)
            .subscribe(
                (res: any) => {
                    console.log('Retorno Post Alterar Gestor Liberador', res, typeof res);
                    if (res && res.registros == 1) {
                        pedido.liberadorAtual = pedido.liberadorSelecionado;
                    }
                },
                (error) => {
                    console.log('Erro Post Alterar Gestor Liberador', error);
                },
                () => {
                    // Complete, equivale ao finaly
                    this.carregandoLiberador = false;
                }
            )
    }
}
