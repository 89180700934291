import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'moneyFormat'})

export class MoneyFormatPipe implements PipeTransform {

  transform(value: string): string {
    if (value) {
      // return value.replace('.', ',')
     
      let tmp = value.replace('.','');
      tmp = tmp.replace(/([0-9]{2})$/g, ",$1");
      if( tmp.length > 6 )
              tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");

      return tmp;

    }
  }

}
