import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable, empty, of } from 'rxjs';
import { map, startWith, switchMap, debounceTime, tap, finalize, mergeMap, catchError } from 'rxjs/operators';
import { SankhyaServiceService } from '../../services/sankhya-service.service';
import { Cliente } from '../../global-interfaces/cliente-interface';
import { ProdutoUnificado } from 'src/app/global-interfaces/produto-interface';
import { Parceiro } from 'src/app/global-interfaces/parceiro-interface';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { TipoNegociacao } from 'src/app/global-interfaces/tipo-negociacao-interface';
import { UpperCasePipe } from '@angular/common';
import { Projeto } from 'src/app/global-interfaces/projeto-interface';
import { PedidosPesquisa } from 'src/app/global-interfaces/pedidos-pesquisa-interface';
import { BottomSheetComponent } from '../bottom-sheet/bottom-sheet.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { AppComponent } from '../../app.component';
import { TipoPedido } from 'src/app/global-interfaces/pedido-interface';

export interface State {
    flag: string;
    name: string;
    population: string;
}

@Component({
    selector: 'app-autocomplete',
    templateUrl: './autocomplete.component.html',
    styleUrls: ['./autocomplete.component.scss'],
})
export class AutocompleteComponent implements OnInit, OnChanges {
    @Input() tipoAutocomplete: string;
    @Input() toggleAutocomplete: boolean;
    @Input() autoCompleteSelecionado: any;
    @Input() codParc: number;
    @Input() codTipVenda: number;
    @Input() tipoPedido: number;
    @Input() codProj: number;
    @Input() label: string;
    @Output() autoCompleteSelecionadoChange = new EventEmitter<any>();
    @Output() codParcChange = new EventEmitter<number>();
    @Output() codTipVendaChange = new EventEmitter<number>();
    @Input() codEmp: number;

    placeholder: string;
    consultaEstoque: boolean;
    errorMessage: string;

    stateCtrl = new FormControl();
    errorCtrl = new FormControl(undefined, { validators: Validators.required, updateOn: 'change' });
    filteredParceiros: Observable<any[]>;
    filteredProdutos: Observable<any[]>;
    filteredTipoNegociacao: Observable<TipoNegociacao[]>;
    fiteredProjetos: Observable<Projeto[]>;
    empresa: number = 0;

    constructor(private sankhyaService: SankhyaServiceService, private bottomSheet: MatBottomSheet, private app: AppComponent) {
        this.consultaEstoque = false;

        this.filteredParceiros = this.stateCtrl.valueChanges.pipe(
            debounceTime(1200),
            switchMap((val: string) =>
                val && val.length >= 1
                    ? this.sankhyaService.getParceiroAutocomplete(val.toUpperCase().replace("'", "''"), this.tipoAutocomplete).pipe(
                          tap(() => {
                              this.errorMessage = null;
                          }),
                          catchError(err => {
                              this.errorMessage = err.message;

                              return of([]);
                          })
                      )
                    : of([])
            ),
            mergeMap((ret: any) => {
                if (ret.serviceResponse || this.errorMessage !== null) {
                    this.errorMessage = this.errorMessage !== null ? this.errorMessage : ret.response;
                    this.stateCtrl.setErrors({ errorCtrl: true });
                    this.stateCtrl.markAsTouched();

                    if (ret.exibeBadge) {
                        this.app.setError(ret.response);
                    }

                    return of([]);
                } else {
                    ret.map((parceiro: Parceiro) => {
                        parceiro.CGCCPF = parceiro.CGCCPF.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');
                    });
                    this.stateCtrl.setErrors(null);
                    return of(ret as Parceiro[]);
                }
            })
        );

        this.filteredProdutos = this.stateCtrl.valueChanges.pipe(
            debounceTime(1200),
            switchMap((val: string) => {
                if (!val || val.length < 1) {
                    return of([]);
                }
                if (this.tipoAutocomplete === 'itemGeral') {
                    return this.sankhyaService.getProdutoGeralAutocomplete(val.toUpperCase()).pipe(
                        tap(() => {
                            this.errorMessage = null;
                        }),
                        catchError(err => {
                            this.errorMessage = err.message;
                            return of([]);
                        })
                    );
                } else if (this.tipoPedido === TipoPedido.PRONTAENTREGA) {
                    return this.sankhyaService
                        .getProdutoAutocomplete(val.toUpperCase(), this.consultaEstoque, undefined, 0, this.codEmp)
                        .pipe(
                            tap(() => {
                                this.errorMessage = null;
                            }),
                            catchError(err => {
                                this.errorMessage = err.message;
                                return of([]);
                            })
                        );
                } else {
                    return this.sankhyaService
                        .getProdutoAutocomplete(
                            val.toUpperCase().replace("'", "''"),
                            this.consultaEstoque,
                            this.tipoPedido,
                            this.codProj,
                            this.codEmp
                        )
                        .pipe(
                            tap(() => {
                                this.errorMessage = null;
                            }),
                            catchError(err => {
                                this.errorMessage = err.message;
                                return of([]);
                            })
                        );
                }
            }),
            mergeMap((ret: any) => {
                if (ret.serviceResponse || this.errorMessage !== null) {
                    this.errorMessage = this.errorMessage !== null ? this.errorMessage : ret.response;
                    this.stateCtrl.setErrors({ errorCtrl: true });
                    this.stateCtrl.markAsTouched();

                    if (ret.exibeBadge) {
                        this.app.setError(ret.response);
                    }
                    return of([]);
                } else {
                    this.stateCtrl.setErrors(null);
                    return of(ret as ProdutoUnificado[]);
                }
            })
        );

        this.filteredTipoNegociacao = this.stateCtrl.valueChanges.pipe(
            debounceTime(1200),
            switchMap((val: string) =>
                val && val.length >= 1
                    ? this.sankhyaService.getTipoNegociacaoAutocomplete(val.toUpperCase()).pipe(
                          tap(() => {
                              this.errorMessage = null;
                          }),
                          catchError(err => {
                              this.errorMessage = err.message;
                              return of([]);
                          })
                      )
                    : of([])
            ),
            mergeMap((ret: any) => {
                if (ret.serviceResponse || this.errorMessage !== null) {
                    this.errorMessage = this.errorMessage !== null ? this.errorMessage : ret.response;
                    this.stateCtrl.setErrors({ errorCtrl: true });
                    this.stateCtrl.markAsTouched();

                    if (ret.exibeBadge) {
                        this.app.setError(ret.response);
                    }

                    return of([]);
                } else {
                    this.stateCtrl.setErrors(null);
                    return of(ret as TipoNegociacao[]);
                }
            })
        );

        this.fiteredProjetos = this.stateCtrl.valueChanges.pipe(
            debounceTime(1200),
            switchMap((val: string) =>
                val && val.length >= 1
                    ? this.sankhyaService.getProjetosAutocomplete(val.toUpperCase(), this.codEmp).pipe(
                          tap(() => {
                              this.errorMessage = null;
                          }),
                          catchError(err => {
                              this.errorMessage = err.message;
                              return of([]);
                          })
                      )
                    : of([])
            ),
            mergeMap((ret: any) => {
                if (ret.serviceResponse || this.errorMessage !== null) {
                    this.errorMessage = this.errorMessage !== null ? this.errorMessage : ret.response;
                    this.stateCtrl.setErrors({ errorCtrl: true });
                    this.stateCtrl.markAsTouched();

                    if (ret.exibeBadge) {
                        this.app.setError(ret.response);
                    }
                    return of([]);
                } else {
                    this.stateCtrl.setErrors(null);
                    return of(ret as Projeto[]);
                }
            })
        );
    }

    ngOnInit() {
        /** Carrega o arquivo de configuracao */
        this.sankhyaService.loadConfig(this.tipoAutocomplete);

        // Definindo o placeholder do input
        switch (this.tipoAutocomplete) {
            case 'cliente': {
                this.placeholder = this.label ? this.label : 'Cliente';
                break;
            }
            case 'clienteRemessa': {
                this.placeholder = 'Cliente remessa';
                this.stateCtrl.disable();
                break;
            }
            case 'bloqueioCliente': {
                this.placeholder = 'Cliente';
                break;
            }
            case 'item': {
                this.placeholder = 'Item';
                this.consultaEstoque = true;
                break;
            }
            case 'itemRelatorio': {
                this.placeholder = 'Item Relatorio';
                this.consultaEstoque = true;
                break;
            }
            case 'itemGeral': {
                this.placeholder = 'Item';
                this.consultaEstoque = false;
                break;
            }
            case 'transportadora': {
                this.placeholder = 'Transportadora';
                break;
            }
            case 'tipoNegociacao': {
                this.placeholder = 'Condicao de Pagamento';
                break;
            }
            case 'projeto': {
                this.placeholder = 'Projeto';
                break;
            }

            default:
                break;
        }
    }

    selectOption(option) {
        this.autoCompleteSelecionado = Object.assign({}, option);

        if (
            this.tipoAutocomplete === 'cliente' ||
            this.tipoAutocomplete === 'clienteRemessa' ||
            this.tipoAutocomplete === 'transportadora' ||
            this.tipoAutocomplete === 'bloqueioCliente'
        ) {
            this.codParc = this.autoCompleteSelecionado.CODPARC;
            this.codParcChange.emit(this.codParc);
        } else if (this.tipoAutocomplete === 'tipoNegociacao') {
            this.codTipVendaChange.emit(this.autoCompleteSelecionado.CODTIPVENDA);
        }

        this.autoCompleteSelecionadoChange.emit(this.autoCompleteSelecionado);
    }

    ngOnChanges(changes: SimpleChanges) {
        /**
         * Habilita/Desabilita o campo Autocomplete
         */
        if (changes.toggleAutocomplete) {
            if (changes.toggleAutocomplete.currentValue) {
                this.stateCtrl.enable();
            } else {
                this.stateCtrl.disable();

                if (this.tipoAutocomplete !== 'tipoNegociacao') {
                    this.stateCtrl.setValue('');
                }

                if (this.tipoAutocomplete === 'clienteRemessa') {
                    this.codParc = 0;
                    this.codParcChange.emit(this.codParc);
                }
            }
        }
    }

    leaveInput(event: any) {
        if (event.target.value !== '') {
            // Caso itens
            if (this.tipoAutocomplete === 'item') {
                this.stateCtrl.setValue(this.autoCompleteSelecionado.DESCRPROD);

                // Caso tipo negociação
            } else if (this.tipoAutocomplete === 'tipoNegociacao') {
                this.stateCtrl.setValue(this.autoCompleteSelecionado.DESCRTIPVENDA);

                // Caso sejam projetos
            } else if (this.tipoAutocomplete === 'projeto') {
                // this.stateCtrl.setValue(this.autoCompleteSelecionado.ABREVIATURA);
                // console.log(this.autoCompleteSelecionado.ABREVIATURA);
                // Caso sejam parceiros
            } else if (this.tipoAutocomplete === 'cliente') {
                // console.log(this.autoCompleteSelecionado);
                this.stateCtrl.setValue(this.autoCompleteSelecionado.RAZAOSOCIAL);
                // console.log(this.autoCompleteSelecionado.RAZAOSOCIAL);
            }
        } else {
            this.stateCtrl.setValue(null);

            if (this.tipoAutocomplete === 'bloqueioCliente') {
                this.autoCompleteSelecionadoChange.emit({ RAZAOSOCIAL: '' } as Parceiro);
            }
        }
    }
}
