import { Component, Input, OnInit } from '@angular/core';
import { SankhyaServiceService } from 'src/app/services/sankhya-service.service';
import { PortalServiceService } from 'src/app/services/portal-service.service';

@Component({
    selector: 'app-relatorio-pedidos-faixa',
    templateUrl: './relatorio-pedidos-faixa.component.html',
    styleUrls: ['./relatorio-pedidos-faixa.component.scss', '../relatorios.component.scss'],
})
export class RelatorioPedidosFaixaComponent implements OnInit {
    constructor(private portalService: PortalServiceService, private sankhyaService: SankhyaServiceService) {}

    ngOnInit() {
        this.nuNotaIni = null;
        this.codProj = null;
        this.isProjeto = false;
    }

    @Input() restUrl: string;

    nuNotaIni: number;
    codProj: number;
    isProjeto: boolean;

    gerarPdf() {
        this.sankhyaService.getPermissaoUsu().subscribe(
            (retorno: any) => {
                const ativo = retorno[0].ATIVO;

                if (ativo === 'S') {
                    let pNuNotaIni = this.nuNotaIni;
                    let pCodProj = this.codProj;
                    let pExibeTot = 'S';
                    let codEmp = '1';

                    if (!this.isProjeto && this.nuNotaIni === null ) {
                        this.portalService.openSnackbar('Preencha Nro Pedido');
                        return false;
                    }

                    if (this.isProjeto && this.codProj === null) {
                        this.portalService.openSnackbar('Preencha Nro Projeto');
                        return false;
                    }

                    if (pNuNotaIni === null) {
                        pNuNotaIni = -1;
                    }

                    if (pCodProj === null) {
                        pCodProj = -1;
                    }

                    return this.sankhyaService.gerarFaixaPed(pNuNotaIni, pNuNotaIni, pCodProj, pExibeTot, codEmp).subscribe(
                        (resFileName: any) => {
                            this.portalService.openSnackbar('Relatório Pedidos Faixa com Total sendo gerado.');

                            let contador = 0;
                            let checkPoint = 0;
                            const verificaTam = setInterval(() => {
                                try {
                                    this.sankhyaService.getFaixaPedidoTamanho(resFileName.fileName).subscribe(
                                        (resFileSize: any) => {
                                            contador++;
                                            if (resFileSize.fileSize < 0) {
                                                clearInterval(verificaTam);
                                                this.portalService.openSnackbar(
                                                    'Erro ao gerar relatório Pedidos Faixa com Total!',
                                                    10000,
                                                    'bottom'
                                                );
                                            } else if (resFileSize.fileSize > 0) {
                                                clearInterval(verificaTam);
                                                this.sankhyaService.getConfigUrl().subscribe((config: any) => {
                                                    //console.log('Valor do Config=', config);
                                                    window.open(config.documentos.url + '/pedidofaixa/' + resFileName.fileName, '_blank');
                                                });
                                            } else if (resFileSize.fileSize == 0) {
                                                if (contador > 11) {
                                                    contador = 0;
                                                    checkPoint++;
                                                    this.portalService.openSnackbar(
                                                        'Aguarde, gerando relatório Pedidos Faixa com Total! ' + checkPoint,
                                                        1000,
                                                        'bottom'
                                                    );
                                                }
                                            }
                                            if (checkPoint > 20) {
                                                // aproximadamente 10 minutos
                                                this.portalService.openSnackbar(
                                                    'Ultrapassou o tempo de geração do Pedidos Faixa com Total! ' + checkPoint,
                                                    3000,
                                                    'bottom'
                                                );
                                                clearInterval(verificaTam);
                                            }
                                        },
                                        error => console.log('Erro  = ', error)
                                    );
                                } catch (error) {
                                    console.log('Erro  = ', error);
                                }
                            }, 5000);
                        },
                        error => {
                            this.portalService.openSnackbar('Erro ao gerar relatório!');
                        }
                    );
                } else {
                    location.reload();
                }
            },
            error => {
                location.reload();
            }
        );
    }

    toggleProjeto() {
        this.nuNotaIni = null;
        this.codProj = null;
    }
}
