import { PortalJpaService } from 'src/app/services/portal-jpa.service';
import { Component, ElementRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Response } from '../global-interfaces/response';
import { Overlay } from '@angular/cdk/overlay';
import { SpinnerComponent } from '../global-components/spinner/spinner.component';
import { PedidoCobranca } from './local-interfaces/pedido-cobranca-interface';
import { map } from 'rxjs/operators';
import { MatBottomSheet, MatDialog } from '@angular/material';
import { BottomSheetCobrancaComponent } from './local-components/bottom-sheet-cobranca/bottom-sheet-cobranca.component';
import { FormControl } from '@angular/forms';
import { DialogBasicComponent } from '../global-components/dialog-basic/dialog-basic.component';
import { PortalServiceService } from '../services/portal-service.service';

@Component({
    selector: 'app-pedidos-financeiro-cobranca',
    templateUrl: './pedidos-financeiro-cobranca.component.html',
    styleUrls: ['./pedidos-financeiro-cobranca.component.scss'],
})
export class PedidosFinanceiroCobrancaComponent implements OnInit {
    constructor(
        private portalJpaService: PortalJpaService,
        private overlay: Overlay,
        private viewContainerRef: ViewContainerRef,
        private bottomSheet: MatBottomSheet,
        private dialog: MatDialog,
        private portalService: PortalServiceService
    ) {}

    ngOnInit() {
        this.atualizar();

        this.filtroPesquisa.valueChanges.subscribe((valorFiltro: string) => this.filtraTabela(valorFiltro));
    }

    pedidos: PedidoCobranca[] = [];
    pedidosFiltrados: PedidoCobranca[] = [];

    listaColunas = [
        'IMP',
        'NUNOTAPROPOSTA',
        'TIPO',
        'CODEMP',
        'RAZAOSOCIAL',
        'STATUS',
        'VLRNOTA',
        'REPRESENTANTE',
        'DESCRTIPVENDA',
        'ACOES',
    ];
    listaColunasDinamicas = ['NUNOTAPROPOSTA', 'TIPO', 'CODEMP', 'RAZAOSOCIAL', 'STATUS', 'VLRNOTA', 'REPRESENTANTE', 'DESCRTIPVENDA'];
    listaColunasNomes = ['Pedido', 'Tipo', 'Emp', 'Razão social', 'Status', 'Vlr', 'Rep', 'Cond. Pagamento'];

    filtroPesquisa = new FormControl('');

    exibeTodos = false;

    atualizar() {
        const todos = this.exibeTodos ? 'S' : '';
        const overlay = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

        this.portalJpaService
            .getPedidosCobranca(todos)
            .pipe(
                map(
                    (res: Response) => {
                        if (res.serviceResponse === 'OK') {
                            res.response.forEach((pedido: PedidoCobranca) => {
                                pedido.CODPROJ === 0 ? (pedido.TIPO = 'PE') : (pedido.TIPO = 'PO ' + pedido.CODPROJ);

                                if (pedido.RAZAOSOCIAL.length >= 40) {
                                    pedido.RAZAOSOCIAL = pedido.RAZAOSOCIAL.slice(0, 40) + '...';
                                }
                            });
                        }

                        return res;
                    },
                    error => {
                        console.error(error);
                    }
                )
            )
            .subscribe(
                (res: Response) => {
                    if (res.serviceResponse === 'OK') {
                        this.pedidos = res.response;
                        this.pedidosFiltrados = res.response;
                    }

                    overlay.dispose();
                },
                error => {
                    console.error(error);
                    overlay.dispose();
                }
            );
    }

    imprimir(pedido: PedidoCobranca) {
        this.portalJpaService.imprimirPedido(pedido.NUNOTAPROPOSTA);
    }

    abrirBottomSheet(pedido: PedidoCobranca) {
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

        this.portalJpaService.getListaCobrancas(pedido.NUNOTAPROPOSTA).subscribe(
            (res: Response) => {
                if (res.serviceResponse === 'OK') {
                    this.bottomSheet.open(BottomSheetCobrancaComponent, {
                        disableClose: true,
                        data: { cobrancas: res.response, pedido: pedido },
                    });
                }

                overlayRef.dispose();
            },
            error => {
                console.error(error);
                overlayRef.dispose();
            }
        );
    }

    filtraTabela(valorFiltro: string) {
        let pedidosFiltrados = this.pedidosFiltrados;

        this.pedidos = pedidosFiltrados.filter(pedido => {
            return (
                pedido.NUNOTAPROPOSTA.toString().includes(valorFiltro) ||
                pedido.TIPO.toLocaleUpperCase().includes(valorFiltro.toLocaleUpperCase()) ||
                pedido.RAZAOSOCIAL.toLocaleUpperCase().includes(valorFiltro.toLocaleUpperCase()) ||
                pedido.STATUS.toLocaleUpperCase().includes(valorFiltro.toLocaleUpperCase()) ||
                pedido.REPRESENTANTE.toLocaleUpperCase().includes(valorFiltro.toLocaleUpperCase()) ||
                pedido.DESCRTIPVENDA.toLocaleUpperCase().includes(valorFiltro.toLocaleUpperCase())
            );
        });
    }

    retornaClasse(pedido: PedidoCobranca) {
        let classe = '';

        /*if ((pedido.CODSTATUS === 3 || pedido.CODSTATUS === 32) && pedido.VLRCOBRAR >= pedido.VLRNOTA * 0.80) {
            classe = 'bk-green';
        } else if (
            (pedido.CODSTATUS === 3 || pedido.CODSTATUS === 32) &&
            pedido.VLRCOBRAR <= pedido.VLRNOTA * 0.80 &&
            pedido.VLRCOBRAR > 0
        ) {
            classe = 'bk-yellow';
        }

        if ((pedido.CODSTATUS === 5 || pedido.CODSTATUS === 51) && pedido.VLRCOBRAR >= pedido.VLRNOTA) {
            classe = 'bk-green';
        } else if ((pedido.CODSTATUS === 5 || pedido.CODSTATUS === 51) && pedido.VLRCOBRAR <= pedido.VLRNOTA && pedido.VLRCOBRAR > 0) {
            classe = 'bk-yellow';
        }*/

        if (pedido.QTDCOBATIV > 0) {
            if (pedido.CODSTATUS === 3 || pedido.CODSTATUS === 32) {
                classe = 'bk-yellow';
            }

            if (pedido.CODSTATUS === 5 || pedido.CODSTATUS === 51) {
                classe = 'bk-green';
            }
        }

        return classe;
    }

    reabrirPedido(pedido: PedidoCobranca) {
        if (pedido.ADPEDFECHADO !== 'S' || pedido.QTDCOBATIV !== 0) return false;

        this.dialog
            .open(DialogBasicComponent, {
                width: '400px',
                data: {
                    titulo: 'Confirmar Ação',
                    linhas: ['*** Atenção ***', `Tem certeza que deseja reabrir o pedido ${pedido.NUNOTAPROPOSTA}?`],
                    botoes: [
                        { texto: 'Sim', retorno: true, color: 'primary' },
                        { texto: 'Não', retorno: false, color: 'accent' },
                    ],
                },
            })
            .afterClosed()
            .subscribe((res: boolean) => {
                if (res) {
                    this.portalJpaService.reabrirPedido(pedido.NUNOTAPEDIDO).subscribe(
                        (res: Response) => {
                            if (res.serviceResponse === 'OK') {
                                this.portalService.openSnackbar(res.response);
                                this.atualizar();
                            }

                            if (res.serviceResponse === 'ERROR') {
                                this.portalService.openSnackbar(res.response);
                            }
                        },
                        error => {
                            console.error(error);
                        }
                    );
                }
            });
    }
}
