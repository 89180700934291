import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AutenticacaoService } from 'src/app/services/autenticacao.service';
import { PortalServiceService } from 'src/app/services/portal-service.service';

@Component({
  selector: 'app-forced-alterar-senha',
  templateUrl: './forced-alterar-senha.component.html',
  styleUrls: ['./forced-alterar-senha.component.scss']
})
export class ForcedAlterarSenhaComponent implements OnInit {

  logomarca: string = 'assets/logooficial-fundobranco-web.png';

  constructor(
    private portalService: PortalServiceService,
    private router: Router,
    private auth: AutenticacaoService
  ) { }

  ngOnInit() {
    if (!localStorage.getItem('token') && !this.auth.getIsLogged()) {
        this.router.navigateByUrl('/');
        this.auth.desconectaUsu();
    }
    this.portalService.getConfigUrl().subscribe((config: any) => {
      this.logomarca = config.imagens.url + '/novalogo/logooficial-fundobranco-web.png';
    })
  }

}
