import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { MatBottomSheet, MatSnackBar, MatTableDataSource } from '@angular/material';
import { PortalJpaService } from '../services/portal-jpa.service';
import { PendenciaCoordenador } from './local-interfaces/pendencias-coordenador-interface';
import { SpinnerComponent } from '../global-components/spinner/spinner.component';
import { Overlay } from '@angular/cdk/overlay';
import { MediaMatcher } from '@angular/cdk/layout';
import { BottomSheetPendenciaCoordenadorComponent } from './local-components/bottom-sheet-pendencia-coordenador/bottom-sheet-pendencia-coordenador.component';
import { PortalServiceService } from '../services/portal-service.service';

@Component({
    selector: 'app-pendencias-coordenador',
    templateUrl: './pendencias-coordenador.component.html',
    styleUrls: ['./pendencias-coordenador.component.scss'],
})
export class PendenciasCoordenador implements OnInit, OnDestroy {
    constructor(
        public overlay: Overlay,
        private portalJpaService: PortalJpaService,
        public viewContainerRef: ViewContainerRef,
        changeDetectorRef: ChangeDetectorRef,
        mediaMatcher: MediaMatcher,
        private bottomSheet: MatBottomSheet,
        private snackBar: MatSnackBar,
        private portalService: PortalServiceService
    ) {
        this.mediaSS = mediaMatcher.matchMedia('(min-width: 500px)');
        this.mobileQueryListener = () => changeDetectorRef.detectChanges();
        this.mediaSS.addEventListener<'change'>('change', this.mobileQueryListener);
    }

    mediaSS: MediaQueryList;
    private mobileQueryListener: () => void;

    parceiros = new MatTableDataSource<PendenciaCoordenador>();

    listaColunas = ['CODPARC', 'RAZAOSOCIAL', 'REPRESENTANTE', 'DHINCLUSAO', 'ACOES'];

    listaColunasMobile = ['CODPARC', 'REPRESENTANTE', 'ACOES'];

    ngOnInit() {
        this.getPendenciasCoordenador();
    }

    ngOnDestroy(): void {
        this.mediaSS.removeEventListener<'change'>('change', this.mobileQueryListener);
    }

    openSnackbar(message: string) {
        this.snackBar.open(message, 'Fechar', {
            horizontalPosition: 'end',
            verticalPosition: 'top',
            duration: 2000,
        });
    }

    getPendenciasCoordenador() {
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);
        this.portalJpaService.getPendenciasCoordenador().subscribe(
            (res: any) => {
                const registros = res.response;
                this.parceiros = new MatTableDataSource(registros);
                overlayRef.dispose();
            },
            error => {
                console.log(error);
                overlayRef.dispose();
            }
        );
    }

    openBottomSheet(parceiro: PendenciaCoordenador, botao: number): void {
        const bottomSheetRef = this.bottomSheet.open(BottomSheetPendenciaCoordenadorComponent, {
            data: { parceiro: parceiro, botao: botao },
        });

        bottomSheetRef.afterDismissed().subscribe((res: any) => {
            if (res) {
                const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);
                const tipo = res.tipo;
                const codParc = parceiro.CODPARC;
                const vlrCredito = res.vlrCredito;
                const parecer = res.parecerCoord;
                const idflow = parceiro.IDFLOW;

                if (tipo == 'S') {
                    this.portalJpaService.atualizaParecerCoordenador(codParc, vlrCredito, parecer, idflow, tipo).subscribe((res: any) => {
                        if (res && res.response) {
                            this.openSnackbar('Parecer enviado com sucesso!');
                            this.getPendenciasCoordenador();
                        }
                    });
                } else {
                    this.portalJpaService.atualizaParecerCoordenador(0, '', '', idflow, tipo).subscribe((res: any) => {
                        if (res && res.response) {
                            this.openSnackbar('Parecer enviado com sucesso!');
                            this.getPendenciasCoordenador();
                        }
                    });
                }

                overlayRef.dispose();
            }
        });
    }

    imprimirFicha(codParc: number) {
        let restUrl = 'https://portal.eurotextil.com.br/jasperserver_web02/rest_v2/reports/reports/Portal/Ficha_Cliente';
        return this.portalService.imprimirGeral({ params: { PARCEIRO: codParc }, url: restUrl + '.pdf' });
    }
}
