import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RouteGuardService  implements CanActivate {

  subRotasUrl = [];
  rotasUrl = [];

  constructor(
    private router: Router
  ) { }

  // Trata as rotas do localStorage que o usuário tem acesso
  getRotasUsuario() {
    let rotas = JSON.parse(atob(localStorage.getItem("rotas")));
    this.rotasUrl = rotas.map(rota => {
      if (Object.keys(rota.subRotas).length != 0) {
        rota.subRotas.map(subRota => {
          this.subRotasUrl.push(subRota.url.replace("/", ""));
        });
      } else return rota.url.replace("/", "");
    })
  }

  // Verifica se a rota a ser acessada existe dentro das rotas tratadas
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (localStorage.getItem("isLogged") === "true") {
      this.getRotasUsuario();
      
      // solucao temporária para resolver problemas de sub-rotas 
      const rota = route.routeConfig.path == 'quiz' ? 'lista-quiz' : route.routeConfig.path;
      
      if ([].concat(this.rotasUrl, this.subRotasUrl).includes(rota)) {
        return true;
      } else {
          this.router.navigateByUrl('/');
          return false;
      }
    } else {
      this.router.navigateByUrl('/login');
      return false
    }
  }

}