import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { PortalJpaService } from 'src/app/services/portal-jpa.service';
import { Salas } from '../local-interfaces/agendamentos-salas';
import { Response } from 'src/app/global-interfaces/response';
import { MatDatepickerInputEvent, MatDialog } from '@angular/material';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { Overlay } from '@angular/cdk/overlay';
import { SpinnerComponent } from 'src/app/global-components/spinner/spinner.component';
import { PortalServiceService } from 'src/app/services/portal-service.service';
import { DialogComponent } from 'src/app/global-components/dialog/dialog.component';
import { AutenticacaoService } from 'src/app/services/autenticacao.service';

@Component({
    selector: 'app-novo-agendamento',
    templateUrl: './novo-agendamento.component.html',
    styleUrls: ['./novo-agendamento.component.scss'],
})
export class NovoAgendamentoComponent implements OnInit {
    constructor(
        private portalJpaService: PortalJpaService,
        public portalService: PortalServiceService,
        public overlay: Overlay,
        public viewContainerRef: ViewContainerRef,
        private datePipe: DatePipe,
        private router: Router,
        private dialog: MatDialog,
        private auth: AutenticacaoService
    ) {}

    salas: Salas[];
    dataSelecionada: boolean = false;
    stringData: string | null = '';
    data: any;
    salaSelecionada: number = null;
    botaoAtivo: boolean = false;
    mostraHorarios: boolean = false;
    horariosIndisponiveis: number[];
    horarios: any[] = [];
    horariosSelecionados: number[] = [];
    mostraConteudo: boolean;
    listaColunas: string[] = [];
    listaInfo: any[][] = [];
    mostraInfo: boolean = false;

    filtroData = (d: Date | null): boolean => {
        const dia = d || new Date();
        const semana = (d || new Date()).getDay();
        return (
            (dia >= new Date() || dia.getDate() == new Date().getDate() && dia.getMonth() == new Date().getMonth()) 
            && (semana != 0 && semana != 6)
        )
    };

    ngOnInit() {
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);
        this.portalJpaService.getNovoAgendamento().subscribe(
            (res: Response) => {
                this.salas = res.response.salaReuniao;

                this.salas.forEach((sala, i) => {
                    let obj = [];
                    obj.push(`${sala.CODSALA.toString().substring(0, sala.CODSALA.toString().length - 2)}º Andar`);
                    obj.push(`Sala ${sala.CODSALA.toString().substring(sala.CODSALA.toString().length - 2)}`);
                    obj.push(`${sala.CAPACIDADE} pessoas`);
                    obj.push(`TV ${sala.TV}"`);
                    this.listaInfo.push(obj);
                });

                this.salas.forEach(e => {
                    this.listaColunas.push(e.NOMESALA);
                });

                overlayRef.dispose();
                this.mostraConteudo = true;
            },
            error => {
                console.error(error);
                overlayRef.dispose();
            }
        );
    }

    voltar() {
        this.router.navigateByUrl('/agendamentos', { state: { agendamentos: JSON.stringify('agendamentos') } });
    }

    addEvent(event: MatDatepickerInputEvent<Date>) {
        if (event.value == null) {
            this.dataSelecionada = false;
            this.stringData = '';
        } else {
            this.dataSelecionada = true;
            this.stringData = this.datePipe.transform(event.value, "EEEE, d 'de' MMMM 'de' yyyy", 'pt');
        }

        this.getHorarios();
    }

    statusBotao(i: number) {
        for (let n = 0; n < this.salas.length; n++) {
            this.salas[n].STATUS = false;
        }
        this.salas[i].STATUS = true;
        this.salaSelecionada = this.salas[i].CODSALA;
        this.botaoAtivo = true;
        this.getHorarios();
    }

    getHorarios() {
        if (this.data && this.botaoAtivo && this.salaSelecionada != null) {
            this.horariosSelecionados = [];
            const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);
            this.portalJpaService.getHorarios(this.salaSelecionada, this.datePipe.transform(this.data, 'dd/MM/yyyy')).subscribe(
                (res: Response) => {
                    this.horarios = [];
                    this.horariosIndisponiveis = res.response.horariosIndisponiveis;

                    for (let i = 8; i <= 18; i++) {
                        let valor = true;
                        let horarioAtual = new Date().getHours();
                        let diaAtual = new DatePipe('pt-BR').transform(new Date(), 'dd/MM/yyyy');
                        if (
                            this.horariosIndisponiveis.includes(i) ||
                            (i <= horarioAtual && this.datePipe.transform(this.data, 'dd/MM/yyyy') === diaAtual)
                        ) {
                            valor = false;
                        }
                        this.horarios.push({
                            HORA: i,
                            DISPONIVEL: valor,
                            SELECIONADO: false,
                        });
                    }

                    this.mostraHorarios = true;
                    overlayRef.dispose();
                },
                error => {
                    console.error(error);
                    overlayRef.dispose();
                }
            );
        } else {
            this.mostraHorarios = false;
        }
    }

    selecionaHorario(i: number) {
        if (this.horarios[i].DISPONIVEL) {
            this.horarios[i].SELECIONADO = !this.horarios[i].SELECIONADO;

            if (this.horarios[i].SELECIONADO) {
                this.horariosSelecionados.push(this.horarios[i].HORA);
            } else {
                this.horariosSelecionados.splice(this.horariosSelecionados.indexOf(this.horarios[i].HORA), 1);
            }
        }
        this.horariosSelecionados.sort((a: number, b: number): number => {
            return a - b;
        });
    }

    agendarReuniao() {
        if (this.horariosSelecionados.length > 0) {
            const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);
            this.portalJpaService
                .novoAgendamento(
                    this.datePipe.transform(this.data, 'dd/MM/yyyy'),
                    this.salaSelecionada,
                    this.horariosSelecionados.toString(),
                    this.auth.getUserName().toLocaleUpperCase()
                )
                .subscribe(
                    (res: Response) => {
                        if (res.response.agendaReuniao[0] === 'OK') {
                            this.portalService.openSnackbar(res.response.agendaReuniao[1]);
                            this.limpaParametros();
                        } else {
                            this.dialog
                                .open(DialogComponent, {
                                    width: '400px',
                                    data: { tipo: 'novoAgendamento', params: res.response.agendaReuniao[1] },
                                })
                                .afterClosed()
                                .subscribe(() => {
                                    this.getHorarios();
                                });
                        }

                        overlayRef.dispose();
                    },
                    error => {
                        console.error(error);
                        overlayRef.dispose();
                    }
                );
        }
    }

    limpaParametros() {
        for (let n = 0; n < this.salas.length; n++) {
            this.salas[n].STATUS = false;
        }
        this.data = null;
        this.mostraHorarios = false;
        this.stringData = '';
        this.botaoAtivo = false;
        this.salaSelecionada = null;
        this.mostraInfo = false;
    }

    verificaInfo() {
        this.mostraInfo = !this.mostraInfo;
        return this.mostraInfo;
    }
}
