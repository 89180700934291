import { Component, OnInit } from '@angular/core';
import { FiltroRelatorioClientes } from './local-interfaces/filtro-relatorio-clientes-interface';

@Component({
  selector: 'app-relatorio-clientes',
  templateUrl: './relatorio-clientes.component.html',
  styleUrls: ['./relatorio-clientes.component.scss']
})
export class RelatorioClientesComponent implements OnInit {

    constructor() {
        this.limpar();
    }
    
    ngOnInit() {}

    filtroRelatorioClientes: FiltroRelatorioClientes;

    gerarRelatorio() {
        console.table(this.filtroRelatorioClientes);
    }

    limpar() {
        this.filtroRelatorioClientes = {
            cidade: { de: 'A', ate: 'Z' },
            estado: { de: 'A', ate: 'Z' },
            representante: { de: 0, ate: 999 }
        };
    }
}
