import { Component, Input, OnInit } from '@angular/core';
import { Parceiro } from 'src/app/global-interfaces/parceiro-interface';
import { PortalServiceService } from '../../services/portal-service.service';
import { SankhyaServiceService } from 'src/app/services/sankhya-service.service';

@Component({
    selector: 'app-relatorio-titulos-liquidados',
    templateUrl: './relatorio-titulos-liquidados.component.html',
    styleUrls: ['./relatorio-titulos-liquidados.component.scss', '../relatorios.component.scss'],
})
export class RelatorioTitulosLiquidadosComponent implements OnInit {
    constructor(private portalService: PortalServiceService, private sankhyaService: SankhyaServiceService) {}

    ngOnInit() {
        this.dtIni = null;
        this.dtFin = null;
        this.parc = null;
        this.matriz = false;
    }

    @Input() restUrl: string;

    dtIni: Date;
    dtFin: Date;
    parc: Number;
    matriz: boolean;
    isTodos: boolean;
    clienteSelecionado: Parceiro = this.getParceiroVazio();

    toggleTodos() {
        this.parc = null;
        this.matriz = false;
    }

    gerarPdf() {
        if (this.dtIni === null || this.dtFin === null) {
            this.portalService.openSnackbar('Preencha a data!');
            return false;
        }

        if (!this.isTodos && this.parc === null) {
            this.portalService.openSnackbar('Selecione um parceiro ou marque "Todos"');
            return false;
        }

        const dataParam = {
            P_DTINI: this.dtIni,
            P_DTFIN: this.dtFin,
            P_PARC: this.parc === null ? -1 : this.parc,
            P_MATRIZ: this.matriz,
        };

        /* this.sankhyaService.getPermissaoUsu().subscribe((retorno: any) => {

            const ativo = retorno[0].ATIVO;

            if (ativo === 'S') {

                this.portalService.imprimirGeral({ params: dataParam, url: this.restUrl + '.pdf' });
            } else {

                location.reload();
            }
        },  (error) => {

                location.reload();
            }
        ); */

        this.portalService.imprimirGeral({ params: dataParam, url: this.restUrl + '.pdf' });
    }

    inserirCliente() {
        this.parc = this.clienteSelecionado.CODPARC;
    }

    private getParceiroVazio(): Parceiro {
        return {
            CODPARC: 0,
            NOMEPARC: '',
            RAZAOSOCIAL: '',
            CODVEND: 0,
            TIPO: '',
            NOMEEND: '',
            NUMEND: 0,
            COMPLEMENTO: '',
            NOMEBAI: '',
            NOMECID: '',
            CEP: '',
            UF: '',
            PAISEND: '',
            TELEFONE: '',
            EMAIL: '',
            IDENTINSCESTAD: '',
            CGCCPF: '',
            ATIVO: '',
            LIMCRED: 0,
            CLIENTE: '',
            FORNECEDOR: '',
            VENDEDOR: '',
            TRANSPORTADORA: '',
            BLOQUEAR: '',
            ADSITUACAOPORTAL: '',
        };
    }

    emitirClienteSelecionado(cliente: Parceiro) {
        this.clienteSelecionado = { ...cliente };
        this.inserirCliente();
    }

    clearClienteAutocomplete() {
        this.clienteSelecionado = this.getParceiroVazio();
    }
}
