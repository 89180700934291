import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface DialogBotao {
    texto: string;
    retorno: boolean | string;
    color: string;
}

export interface DialogData {
    titulo: string;
    linhas: Array<string>;
    botoes: DialogBotao[]
}

@Component({
    selector: 'app-basic-dialog',
    templateUrl: './dialog-basic.component.html',
})
export class DialogBasicComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<DialogBasicComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) { }

    ngOnInit(): void {
        // console.log('Não implementado ngOnInit');
    }

    onNoClick(): void {
        // console.log('Não implementado onNoClick');
    }

    returnValue(botao: DialogBotao) {
        this.dialogRef.close(botao.retorno);
    }

    defineCor(botao: DialogBotao) {
        return botao.color ? botao.color : 'primary';
    }
}
