import { Injectable } from '@angular/core';

import { PortalJpaService } from 'src/app/services/portal-jpa.service'
import { INotificacaoBadge, INotificacaoDB, INotificacaoItem } from '../interfaces/notificacao-interface';

@Injectable({
    providedIn: 'root'
})
export class NotificacaoService {
    constructor(
        private portalJpa: PortalJpaService,
    ) { }

    ultimaNotificacao = 0;
    badgeObj: INotificacaoBadge;

    criarBadge(oculta: boolean, cor: string): INotificacaoBadge {
        return this.badgeObj = {
            hidden: oculta,
            color: cor,
            notificacoes: []
        };
    }

    atualizarNotificacoes() {
        // date.getTime retorna os milesegundos apos 1/1/1970, 60.000 milisegundos = 1  minuto
        const agora = (new Date()).getTime()
        const tempoMinutos = (agora - this.ultimaNotificacao) / 60000;

        if (tempoMinutos < 1) return;

        this.portalJpa.apiPostObterNotificacoesAtivas().subscribe((ret) => {

            // Não há notificações para exibir
            if (!ret || !ret.notificacoes || !Array.isArray(ret.notificacoes)) return;

            ret.notificacoes.forEach((itemNovo: INotificacaoDB) => {
                if (itemNovo.DHEXPIRACAO) {
                    const expira = (new Date(itemNovo.DHEXPIRACAO)).getTime();

                    if (expira < agora) return;
                };

                if (itemNovo.EXIBIR == 'N') return;

                let isNotificado = false;

                // Verifica se a notificação em itemNovo já está na lista de notificações
                this.badgeObj.notificacoes.forEach((itemLista: INotificacaoItem) => {
                    if (itemLista.portalID == itemNovo.PORTALID && itemLista.notifID == itemNovo.NOTIFID) {
                        isNotificado = true;
                    }
                })
                if (isNotificado) return;

                const nova: INotificacaoItem = {
                    texto: itemNovo.TEXTO,
                    permiteMarcar: true,
                    spinnerOn: false,
                    portalID: itemNovo.PORTALID,
                    notifID: itemNovo.NOTIFID,
                    dhExpira: new Date(itemNovo.DHEXPIRACAO),

                }
                if (itemNovo.ACAOJSON) {
                    nova.acao = JSON.parse(itemNovo.ACAOJSON);
                    nova.permiteMarcar = itemNovo.LIDA == 'S';
                }
                this.badgeObj.notificacoes.push(nova);
            });

            this.ultimaNotificacao = (new Date()).getTime();
            this.badgeObj.notificacoes.forEach((itemLista: INotificacaoItem, index: number) => {
                const expira = (new Date(itemLista.dhExpira)).getTime();
                if (expira < agora) {
                    this.badgeObj.notificacoes.splice(index, 1);
                }
            });

            // console.log('this.badgeObj ==', this.badgeObj)
        })

    }

    removerNotifID(notifID: number) {
        this.badgeObj.notificacoes.forEach((itemLista: INotificacaoItem, index: number) => {
            if (itemLista.notifID == notifID) {
                this.badgeObj.notificacoes.splice(index, 1);
            }
        });
    }
}
