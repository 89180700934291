export interface TipoNegociacao {
    CODTIPVENDA: number;
    DESCRTIPVENDA: string;
    SUBTIPOVENDA: string; // Classifica a venda como: 1 - À vista, 2 - À prazo, 3 - Parcelado, 7 - Credito, 8 - Debito
    ADOPERACREDITO?: string;
}

export interface TipoNegociacaoCartao {
    CODTIPVENDA: number;
    DESCRTIPVENDA: string;
    SUBTIPOVENDA: string; // Classifica a venda como: 1 - À vista, 2 - À prazo, 3 - Parcelado, 7 - Credito, 8 - Debito
    bandeira: string;
    parcelas: string;
}

export interface ParcelasTipoNegociacao {
    SEQUENCIA: number;
    PRAZO: number;
    PERCENTUAL: number;
}

export interface Bandeira {
    NOME: string;
}

export interface ParcelaBandeira {
    BANDEIRA: string;
    PARCELA: string;
}

export enum SubTipo {
    CREDITO = 7,
    DEBITO = 8,
}
