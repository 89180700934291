import { Component, Input, OnInit } from '@angular/core';
import { PortalServiceService } from 'src/app/services/portal-service.service';
import { SankhyaServiceService } from 'src/app/services/sankhya-service.service';

@Component({
  selector: 'app-relatorio-clientes-bloqueados',
  templateUrl: './relatorio-clientes-bloqueados.component.html',
  styleUrls: ['./relatorio-clientes-bloqueados.component.scss']
})
export class RelatorioClientesBloqueadosComponent implements OnInit {
 
    constructor(
        private portalService: PortalServiceService,
        private sankhyaService: SankhyaServiceService) { }
        
    ngOnInit() { }
    
    @Input() restUrl: string;
    
    gerarPdf() {

        /* this.sankhyaService.getPermissaoUsu().subscribe((retorno: any) => {

            const ativo = retorno[0].ATIVO;

            if (ativo === 'S') {

                this.portalService.imprimirGeral({ url: this.restUrl + '.pdf' });
            } else {

                location.reload();
            }
        },  (error) => {

                location.reload();
            }); */
        this.portalService.imprimirGeral({ url: this.restUrl + '.pdf' });
    }
}
