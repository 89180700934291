import { BottomSheetDownloadComponent } from './../global-components/bottom-sheet-download/bottom-sheet-download.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { AfterViewInit, Component, OnInit, ViewContainerRef } from '@angular/core';
import { ProdutoUnificado } from '../global-interfaces/produto-interface';
import { NumeroPO } from '../criar-pedido/local-interfaces/numero-po-interface';
import { MatDialog } from '@angular/material/dialog';
import { SankhyaServiceService } from '../services/sankhya-service.service';
import { concatMap, switchMap, map } from 'rxjs/operators';
import { Overlay } from '@angular/cdk/overlay';
import { SpinnerComponent } from '../global-components/spinner/spinner.component';
import { BooksInterface } from './local-interfaces/books-interface';
import { DialogComponent } from '../global-components/dialog/dialog.component';
import { of } from 'rxjs';
import { PortalJpaService } from '../services/portal-jpa.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';

interface retBooks {
    serviceResponse: string;
    response: string;
    params: BooksInterface[];
}
@Component({
    selector: 'app-books',
    templateUrl: './books.component.html',
    styleUrls: ['./books.component.scss'],
})
export class BooksComponent implements OnInit, AfterViewInit {
    constructor(
        public overlay: Overlay,
        public viewContainerRef: ViewContainerRef,
        private dialog: MatDialog,
        private sankhyaService: SankhyaServiceService,
        private portalJpaService: PortalJpaService,
        private router: Router,
        private snackBar: MatSnackBar,
        private bottomSheet: MatBottomSheet
    ) {
        this.aba = 0;
        this.inicializarVariaveis();
        this.nav = this.router.getCurrentNavigation();
    }

    ngOnInit() {
        this.sankhyaService.getParamPedidos().subscribe((retorno: any) => {
            if (retorno.serviceResponse === 'OK') {
                this.sankhyaService.configListaEmp(retorno);
                this.listaEmpresas = this.sankhyaService.listaEmpresas;
                this.nomeEmpresas = this.sankhyaService.nomeEmpresas;
                this.codEmp = !this.listaEmpresas ? 1 : +this.listaEmpresas[0];
            }
        });
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            if (this.nav && this.nav.extras && this.nav.extras.state && this.nav.extras.state.books) {
                this.aba = 2;
            } else {
                this.aba = 0;
            }
        });
    }

    tipoPedido: string;
    projetoSelecionado: NumeroPO;
    projetos: NumeroPO[];

    // Atributo que vai receber o produto selecionado
    produtoSelecionado: ProdutoUnificado;
    produtos: ProdutoUnificado[];

    books: any[];

    listaEmpresas: number[] = [];
    nomeEmpresas: any;
    codEmp: number;
    aba: number;
    nav: any;

    lebels: string[];

    // Este método é chamado sempre que as abas "Pronta Entrega / Programado" são alternadas
    buscaBooksPO(event: any) {
        if (event != 'po') {
            this.aba = event.index;
        }

        // Esvaziar a variável
        this.books = [];

        // Buscar books apenas se o índice da aba for 1 (0 = Pronta Entrega / 1 = Programado)
        if (event === 'po' || event.index === 1) {
            this.exibirBook('po');
        }
        if (event === 'pos' || event.index === 2) {
            this.exibirBookSemanal('pos');
        }
    }

    abrirJanela(url: string, descricao: string, codProj: number) {
        const output = descricao.replace(/[ %.,]/g, '_').replace(/__+/g, '_');
        const nomeDoArquivo = codProj > 0 ? codProj + '_' + output : output;

        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);
        this.openSnackbar('O book será gerado e exibido em instantes.');
        this.portalJpaService.getBookUrl(url).subscribe((blob: Blob) => {
            const url = URL.createObjectURL(blob);
            this.bottomSheet.open(BottomSheetDownloadComponent, {
                data: { url, nomeArquivo: `${nomeDoArquivo}.pdf` },
            });
            overlayRef.dispose();
        });
    }

    exibirBook(tipoPedido: string) {
        this.tipoPedido = tipoPedido;
        this.books = [];

        // Mostrando o spinner na tela
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

        this.produtos.push(this.produtoSelecionado);

        let projetosArray = [];
        let peArray = [];

        // Abaixo será um encadeamento de requisições
        let projetos; // = this.sankhyaService.getProgramacaoSemanal(this.codEmp);

        if (tipoPedido === 'pos') {
            projetos = this.sankhyaService.getProgramacaoSemanal(this.codEmp);
        } else {
            projetos = this.sankhyaService.getPojetosAtivosPorEmpresa(this.codEmp);
        }

        const getProduto = projetos.pipe(
            concatMap((response: any) => {
                if (tipoPedido === 'pe') {
                    return of(this.produtos);
                } else if (tipoPedido === 'po') {
                    projetosArray = response.map(proj => proj.CODPROJ);
                    return of([]);
                } else {
                    projetosArray = response.map(proj => proj.CODPROJ).filter(codProj => codProj > 0);
                    peArray = response
                        .map(proj => {
                            return {
                                ADCODUNIPRO: proj.CODPROJ === 0 ? proj.ADCODUNIPRO : 0,
                                DESCRPROD: proj.DESCRPROD,
                            };
                        })
                        .filter(prod => prod.ADCODUNIPRO > 0);
                    return of([]);
                }
            })
        );

        let buscaBooks;
        if (tipoPedido === 'pos') {
            buscaBooks = getProduto.pipe(
                switchMap(lixo => {
                    return this.portalJpaService.buscaBooks(projetosArray, []).pipe(
                        switchMap((res1: any) => {
                            return this.portalJpaService.buscaBooks([], peArray).pipe(
                                map((res2: any) => {
                                    const resp1 = res1 as retBooks;
                                    const resp2 = res2 as retBooks;
                                    resp2.params.push(...resp1.params);

                                    if (res1 && resp1.serviceResponse == 'OK') {
                                        resp2.serviceResponse = 'OK';
                                    }

                                    return resp2;
                                })
                            );
                        })
                    );
                })
            );
            this.portalJpaService.buscaBooks(projetosArray, []).pipe(
                switchMap((res1: any) => {
                    return this.portalJpaService.buscaBooks([], peArray).pipe(
                        map((res2: any) => {
                            const resp1 = res1 as retBooks;
                            const resp2 = res2 as retBooks;
                            resp2.params.push(...resp1.params);
                            return resp2;
                        })
                    );
                })
            );
        } else {
            buscaBooks = getProduto.pipe(concatMap((response: any) => this.portalJpaService.buscaBooks(projetosArray, response)));
        }

        buscaBooks.subscribe(
            (responseBooks: any) => {
                if (responseBooks.serviceResponse === 'ERROR') {
                    // Removendo o spinner da tela
                    overlayRef.dispose();

                    // Mostrar a caixa de diálogo para o usuário
                    if (this.aba === 1) {
                        this.dialog.open(DialogComponent, {
                            width: '300px',
                            data: { tipo: 'itemSemImagens' },
                        });
                    }
                } else {
                    this.books = responseBooks.params;

                    this.books.map(book => {
                        this.projetos.find(projeto => {
                            if (projeto.CODPROJ.toString() === book.CODPROJ.toString()) {
                                book.ABREVIATURA = projeto.ABREVIATURA;
                            }
                        });
                    });

                    // Removendo o spinner da tela
                    overlayRef.dispose();
                }
            },

            erro => {
                console.log(erro);
            }
        );
    }

    inicializarVariaveis() {
        // Esvaziando o objeto selecionado
        this.produtoSelecionado = {
            ADCODUNIPRO: 0,
            DESCRPROD: '',
            CONTROLE: [],
            CODVOL: '',
            PESOBRUTO: 0,
            PESOLIQ: 0,
            QTDNEG: 0,
            ADPERCTOLPEDCOM: 0,
            ADMULTIVEN: 0,
            VLRVENDA: 0,
            VLRVENDAALT: 0,
        };

        // Esvaziando books
        this.books = [];

        this.tipoPedido = '';

        this.projetoSelecionado = {
            CODPROJ: 0,
            ABREVIATURA: '',
        };

        this.projetos = [];

        this.produtos = [];
    }

    getNomeEmp(codEmp: number) {
        let ret: any = this.nomeEmpresas.find(emp => emp.CODEMP === codEmp);
        return ret.NOMEFANTASIA;
    }

    openSnackbar(message: string) {
        this.snackBar.open(message, 'Fechar', {
            horizontalPosition: 'end',
            verticalPosition: 'top',
            duration: 5000,
        });
    }

    exibirBookSemanal(tipoPedido: string) {
        // Mostrando o spinner na tela
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

        this.tipoPedido = tipoPedido;
        this.books = [];
        let projetosArray = [];
        let peArray = [];

        this.portalJpaService.getProgramacaoSemanalBook().subscribe((progSemanal: any) => {
            const tmpProjetos = new Set();

            progSemanal.response.forEach((proj: ProdutoUnificado) => {
                if (proj.CODPROJ > 0) {
                    tmpProjetos.add(proj.CODPROJ);
                } else {
                    peArray.push({
                        ADCODUNIPRO: proj.ADCODUNIPRO,
                        DESCRPROD: proj.DESCRPROD,
                        IDPROG: +proj.IDPROG,
                        DESCR: proj.DESCR,
                        DTPROG: proj.DTPROG,
                    });
                }
            });
            projetosArray = Array.from(tmpProjetos).sort((a: number, b: number) => a - b);

            this.portalJpaService.buscaBooksPoProgramacaoSemanal(projetosArray).subscribe((resProjetos: any) => {
                this.portalJpaService.buscaBooksPeProgramacaoSemanal(peArray).subscribe((resPe: any) => {
                    const listaProjetos =
                        resProjetos.params.length > 0
                            ? (resProjetos as retBooks)
                            : {
                                  serviceResponse: 'OK',
                                  response: '',
                                  params: [],
                              };

                    const listaFinal =
                        resPe.params.length > 0
                            ? (resPe as retBooks)
                            : {
                                  serviceResponse: 'OK',
                                  response: '',
                                  params: [],
                              };

                    listaFinal.params.push(...listaProjetos.params);

                    if (resProjetos && listaProjetos.serviceResponse == 'OK') {
                        let data = this.organaizarBooks(listaFinal.params);
                        let sortedData = data.sort((a, b) => {
                            return b.dtprog - a.dtprog;
                        });

                        this.books = sortedData;
                        // Removendo o spinner da tela
                        overlayRef.dispose();
                    } else {
                        // uma das duas chamadas deu erro
                        // Removendo o spinner da tela
                        overlayRef.dispose();

                        // Mostrar a caixa de diálogo para o usuário
                        if (this.aba === 1) {
                            this.dialog.open(DialogComponent, {
                                width: '300px',
                                data: { tipo: 'itemSemImagens' },
                            });
                        }
                    }
                });
            });
        });
    }

    getDateFromString(dateString: string) {
        if (dateString.includes('-')) {
            const [dateValues, timeValues] = dateString.split(' ');
            const year = dateValues.substring(0, 4);
            const month = dateValues.substring(5, 7);
            const day = dateValues.substring(8, 11);
            return new Date(+year, +month - 1, +day);
        } else {
            const [dateValues, timeValues] = dateString.split(' ');
            const day = dateValues.substring(0, 2);
            const month = dateValues.substring(2, 4);
            const year = dateValues.substring(4, 10);
            return new Date(+year, +month - 1, +day);
        }
    }

    organaizarBooks(array) {
        let memory = {
            temp: [],
            result: [],
        };

        array.forEach(o => {
            if (!memory.temp.includes(+o.IDPROG)) {
                memory.temp.push(+o.IDPROG);
                const object = array.filter(obj => +obj.IDPROG === +o.IDPROG);
                memory.result.push({ idprog: +o.IDPROG, dtprog: this.getDateFromString(o.DTPROG), descr: o.DESCR, data: object });
            }
        });

        return memory.result;
    }
}
