import { Component, Inject, OnInit } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-bottom-sheet-pedidos',
  templateUrl: './bottom-sheet-pedidos.component.html',
  styleUrls: ['./bottom-sheet-pedidos.component.scss']
})
export class BottomSheetPedidosComponent implements OnInit {

  nf: string = 'N';

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private bottomSheetRef: MatBottomSheetRef<BottomSheetPedidosComponent>
  ) { }

  ngOnInit() {
  }

  close(tipo: string) {

    this.bottomSheetRef.dismiss(tipo);
  }

}
