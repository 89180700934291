import { Component, Inject, OnInit } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
    selector: 'app-bottom-sheet-cadastro',
    templateUrl: './bottom-sheet-cadastro.component.html',
    styleUrls: ['./bottom-sheet-cadastro.component.scss'],
})
export class BottomSheetCadastroComponent implements OnInit {
    constructor(
        private sanitizer: DomSanitizer,
        private bottomSheetRef: MatBottomSheetRef<BottomSheetCadastroComponent>,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
    ) {
        this.urlSegura = this.sanitizer.bypassSecurityTrustResourceUrl(data.url);
    }
    ngOnInit() {}

    urlSegura: SafeResourceUrl;

    close() {
        this.bottomSheetRef.dismiss();
    }
}
