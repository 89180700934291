import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'dataFormat'})
//formatacao data de YYYY-MM-DD HH:MI:SI para DD/MM/YYYY
export class DataFormatPipe implements PipeTransform {

  transform(value: string): string {
    if (value) {
      let ano = value.substring(0,4);
      let mes = value.substring(5,7);
      let dia = value.substring(8,10);
      
      return `${dia}/${mes}/${ano}`;
    }
  }
}
