import { Injectable } from '@angular/core';
import { Observable, from, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ClipBoardService {
    copyToClipboard(text: string): Observable<void> {
        if (navigator.clipboard) {
            return from(navigator.clipboard.writeText(text)).pipe(
                catchError(err => {
                    console.error('Falha ao copiar usando a API Clipboard: ', err);
                    this.fallbackCopyToClipboard(text);
                    return of(undefined);
                })
            );
        } else {
            this.fallbackCopyToClipboard(text);
            return of(undefined);
        }
    }

    private fallbackCopyToClipboard(text: string): void {
        const textarea = document.createElement('textarea');
        textarea.style.position = 'fixed';
        textarea.style.opacity = '0';
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.focus();
        textarea.select();
        try {
            document.execCommand('copy');
            console.log('Texto copiado para a área de transferência usando execCommand.');
        } catch (err) {
            console.error('Falha ao copiar usando execCommand: ', err);
        }
        document.body.removeChild(textarea);
    }
}
