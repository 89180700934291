import { Component, Input, OnInit } from '@angular/core';
import { ProdutoUnificado } from 'src/app/global-interfaces/produto-interface';
import { documentos } from 'src/assets/config.json';
import { PortalJpaService } from 'src/app/services/portal-jpa.service';

@Component({
  selector: 'app-relatorio-planilha-compras',
  templateUrl: './relatorio-planilha-compras.component.html',
  styleUrls: ['./relatorio-planilha-compras.component.scss', '../relatorios.component.scss']
})
export class RelatorioPlanilhaComprasComponent implements OnInit {

    constructor(
        private portalJpa: PortalJpaService) { }

    ngOnInit() {

        this.dtIni1 = null;
        this.dtIni2 = null;
        this.dtIni3 = null;
        this.dtFin1 = null;
        this.dtFin2 = null;
        this.dtFin3 = null;
        this.grupo = null;
        this.isTodos = false;
        this.isDesconVendaDireta = false;
    }

    @Input() restUrl: string;

    dtIni1: Date;
    dtIni2: Date;
    dtIni3: Date;
    dtFin1: Date;
    dtFin2: Date;
    dtFin3: Date;
    grupo: number;
    isTodos: boolean;
    produtoSelecionado: ProdutoUnificado;
    isDesconVendaDireta: boolean;

    async gerarPdf() {

        if (this.dtIni1 > this.dtFin1) {
            this.portalJpa.openSnackbar("Data Inicial - Vendas 1 não pode ser superior a Data Final - Vendas 1");
            return false;
        }

        if (this.dtIni2 > this.dtFin2) {
            this.portalJpa.openSnackbar("Data Inicial - Vendas 2 não pode ser superior a Data Final - Vendas 2");
            return false;
        }

        if (this.dtIni3 > this.dtFin3) {
            this.portalJpa.openSnackbar("Data Inicial - Vendas 3 não pode ser superior a Data Final - Vendas 3");
            return false;
        }
        
        const params = [
            {
                "paramName": "report-uri",
                "paramValue": "/reports/Portal/Planilha_Compras"
            },
            {
                "paramName": "output-format",
                "paramValue": "xls"
            },
            {
                "paramName": "async",
                "paramValue": "true"
            },
            {
                "paramName": "pasta-relatorio",
                "paramValue": "PLANILHA_COMPRAS"
            },
            {
                "paramName": "nome-arquivo",
                "paramValue": "Planilha_de_Compras"
            },
            {
                "paramName": "CODGRUPO",
                "paramValue": this.grupo ? this.grupo : -1
            },
            {
                "paramName": "CODUNIPRO",
                "paramValue": (this.produtoSelecionado && this.produtoSelecionado.ADCODUNIPRO) ? this.produtoSelecionado.ADCODUNIPRO : -1
            },
            {
                "paramName": "VENDA1_INI",
                "paramValue": this.dtIni1 ? this.dtIni1 : ''
            },
            {
                "paramName": "VENDA1_FIN",
                "paramValue": this.dtFin1 ? this.dtFin1 : ''
            },
            {
                "paramName": "VENDA2_INI",
                "paramValue": this.dtIni2 ? this.dtIni2 : ''
            },
            {
                "paramName": "VENDA2_FIN",
                "paramValue": this.dtFin2 ? this.dtFin2 : ''
            },
            {
                "paramName": "VENDA3_INI",
                "paramValue": this.dtIni3 ? this.dtIni3 : ''
            },
            {
                "paramName": "VENDA3_FIN",
                "paramValue": this.dtFin3 ? this.dtFin3 : ''
            },
            {
                "paramName": "TODOS",
                "paramValue": String(this.isTodos)
            },
            {
                "paramName": "VENDADIRETA",
                "paramValue": String(this.isDesconVendaDireta)
            },
            {
                "paramName": "REL",
                "paramValue": 'PlanilhaCompras'
            }
        ];

        const relatoriosUrl = await this.portalJpa.fetchRelatorio(params);

        if (relatoriosUrl) {
            for (let path of relatoriosUrl) {
                window.open(documentos.url + '/relatorios_gerados/PlanilhaCompras' + path, '_blank');
            }
        }
    }

    limparVariaveis() {
        this.produtoSelecionado = {
            ADCODUNIPRO: null,
            DESCRPROD: null,
            CONTROLE: [],
            CODVOL: '',
            PESOBRUTO: null,
            PESOLIQ: null,
            QTDNEG: null,
            ADPERCTOLPEDCOM: null,
            ADMULTIVEN: null,
            VLRVENDA: null
        };
    }
}
