import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { MatBottomSheet, MatDialog, MatSnackBar } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { BottomSheetComponent } from '../global-components/bottom-sheet/bottom-sheet.component';
import { DialogComponent } from '../global-components/dialog/dialog.component';
import { SpinnerComponent } from '../global-components/spinner/spinner.component';
import { PortalServiceService } from '../services/portal-service.service';
import { SankhyaServiceService } from '../services/sankhya-service.service';
import { DevolucoesAprovacao } from './local-interfaces/devolucao-aprovacao-interface';
import { AutenticacaoService } from '../services/autenticacao.service';

@Component({
  selector: 'app-devolucao-aprovacao',
  templateUrl: './devolucao-aprovacao.component.html',
  styleUrls: ['./devolucao-aprovacao.component.scss']
})

export class DevolucaoAprovacaoComponent implements OnInit {

    constructor(
        private sankhyaService: SankhyaServiceService,
        private bottomSheet: MatBottomSheet,
        private dialog: MatDialog,
        public overlay: Overlay,
        public viewContainerRef: ViewContainerRef,
        private portalService: PortalServiceService,
        private snackBar: MatSnackBar,
        public _d: DomSanitizer,
        private http: HttpClient,
        private auth: AutenticacaoService
    ) {}

    ngOnInit() {
        this.carregarDevolucoes();
        this.http.get('assets/documento.png', { responseType: 'blob' }).subscribe(img => {
        this.imgDoc = img;
        });
        this.http.get('assets/video.png', { responseType: 'blob' }).subscribe(img => {
        this.imgVid = img;
        });
    }

    devolucoes: DevolucoesAprovacao[];
    tipoAprovDev: string;
    motivoRecusa: string;
    obsRecusa: string;
    obsAprovacao: string;
    enviaEmail: boolean;
    anexos = [] as any;
    anexosSrc = [] as any;
    anexosId: number;
    imgAnexos = [] as any;
    imgDoc: any;
    imgVid: any;
    codRep: number = this.auth.getCodRep();

    carregarDevolucoes() {

        // Mostrando o spinner na tela
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

        this.sankhyaService.getDevolucoesAprovacao().subscribe((res: any) => {

            if (res.serviceResponse === 'OK') {

                this.devolucoes = res.devolucoesAprovacao;

            }
            else {

                this.bottomSheet.open(BottomSheetComponent, {
                    data: {
                        param: 'consultaDevolucao',
                        mensagem: res
                    },
                    panelClass: 'bottom-sheet'
                });
            }

                // Removendo o spinner da tela
                overlayRef.dispose();
        }, (error) => {

            this.bottomSheet.open(BottomSheetComponent, {

                data: {
                    param: 'consultaDevolucao',
                    mensagem: error
                },
                panelClass: 'bottom-sheet'
            });

            // Removendo o spinner da tela
            overlayRef.dispose();
        });
    }

    acaoDevolucao(param: string, devId: number) {

        if (param === 'A') {
            this.openDialog('aprovaDevolucao')
                .subscribe(result => {
                    if (result) {
                        this.concluiAcaoDevolucao('FA', result, devId);
                    }
                });
        }
        else if (param === 'R') {
            this.openDialog('reprovaDevolucao')
                .subscribe(result => {
                    if (result) {
                        this.concluiAcaoDevolucao('FR', result, devId);
                    }
                });
        }
    }

    concluiAcaoDevolucao(status: string, result: any, devId: number) {

        this.sankhyaService.aprovaReprovaDevolucao(status, result, devId).subscribe((res: any) => {

            if (res.serviceResponse === 'OK') {

                this.portalService.openSnackbar('Processo de devolução finalizado com sucesso!');
            }
            else {

                this.bottomSheet.open(BottomSheetComponent, {
                    data: {
                        param: 'consultaDevolucao',
                        mensagem: res
                    },
                    panelClass: 'bottom-sheet'
                });
            }
        },  (error) => {

                this.bottomSheet.open(BottomSheetComponent, {

                    data: {
                        param: 'consultaDevolucao',
                        mensagem: error
                    },
                    panelClass: 'bottom-sheet'
                });
            }
        );

        setTimeout(() => { location.reload() }, 2000);
    }

    openDialog(tipo: string) {
        const dialogRef = this.dialog.open(DialogComponent, {
            minWidth: '400px',
            minHeight: '200px',
            data: { tipo }
        });

        return dialogRef.afterClosed();
    }

    exibirAnexos(devId: number) {

        this.anexos = [];
        this.anexosSrc = [];
        this.imgAnexos = [];

        this.anexosId = devId;

        // Mostrando o spinner na tela
        const overlayRef = SpinnerComponent.getSpinnerOverlayRef(this.overlay, this.viewContainerRef);

        this.sankhyaService.exibirAnexosDev(devId).subscribe((res: any) => {

            if (res.serviceResponse === 'OK') {

                this.anexosSrc = res.anexosDevolucao;

                this.anexosSrc.map(anexo => {

                    const byteCharacters = atob(anexo.ARQUIVO);
                    const byteNumbers = new Array(anexo.TAMARQ);

                    for (let i = 0; i < anexo.TAMARQ; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }

                    const byteArray = new Uint8Array(byteNumbers);
                    const blob = new Blob([byteArray], { type: anexo.TIPOARQ });
                    const url = URL.createObjectURL(blob);

                    this.anexos.push(url);

                    if (anexo.TIPOARQ.includes('image/')) {
                        this.imgAnexos.push(URL.createObjectURL(blob));
                    }
                    else if (anexo.TIPOARQ.includes('video/')) {
                        this.imgAnexos.push(URL.createObjectURL(this.imgVid));
                    }
                    else {
                        this.imgAnexos.push(URL.createObjectURL(this.imgDoc));
                    }
                });
            } else {
                this.openSnackbar('Falha ao carregar anexos. Tente novamente!');
            }

            overlayRef.dispose();
        },  (erro) => {

                this.openSnackbar('Falha ao carregar anexos!');
                console.log(erro);

                overlayRef.dispose();
            }
        );
    }

    openSnackbar(message: string) {
        this.snackBar.open(message, 'Fechar', {
            horizontalPosition: 'end',
            verticalPosition: 'top',
            duration: 5000
        });
    }

    abrirAnexo(anexo: any) {
        window.open(anexo, "_blank");
    }

    imprimir(devId: number) {
        this.portalService.imprimirDevolucao(devId);
    }
}
