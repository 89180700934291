import { Component, OnInit } from '@angular/core';
import { Parceiro } from '../global-interfaces/parceiro-interface';
import { SankhyaServiceService } from '../services/sankhya-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-bloqueio-cliente',
    templateUrl: './bloqueio-cliente.component.html',
    styleUrls: ['./bloqueio-cliente.component.scss'],
})
export class BloqueioClienteComponent implements OnInit {
    constructor(private sankhyaService: SankhyaServiceService, private snackBar: MatSnackBar) {}

    ngOnInit() {
        this.inicializarVariaveis();
    }

    parceiroCliente: Parceiro;
    coligados: boolean;

    enviar() {
        // Se coligados = TRUE então 1, se for FALSE então 0
        const coligados = this.coligados ? 1 : 0;
        this.sankhyaService.setSituacaoPortal(this.parceiroCliente, coligados).subscribe(
            (res: { serviceResponse: string; response: string; param: any[] }) => {
                res = {
                    serviceResponse: 'OK',
                    response: '',
                    param: [],
                };

                // this.inicializarVariaveis();
                this.openSnackbar('Alteração realizada com sucesso!');
            },

            erro => {
                // Bottom-sheet
                console.log(erro);
            }
        );
    }

    inicializarVariaveis() {
        // Cliente
        this.parceiroCliente = {
            CODPARC: 0,
            NOMEPARC: '',
            RAZAOSOCIAL: '',
            CODVEND: 0,
            TIPO: '',
            NOMEEND: '',
            NUMEND: 0,
            COMPLEMENTO: '',
            NOMEBAI: '',
            NOMECID: '',
            TELEFONE: '',
            EMAIL: '',
            IDENTINSCESTAD: '',
            CGCCPF: '',
            ATIVO: '',
            LIMCRED: 0,
            CLIENTE: '',
            FORNECEDOR: '',
            VENDEDOR: '',
            TRANSPORTADORA: '',
            BLOQUEAR: '',
            ADSITUACAOPORTAL: '',
        };

        this.coligados = false;
    }

    openSnackbar(mensagem: string) {
        this.snackBar.open(mensagem, 'Fechar', {
            verticalPosition: 'top',
            horizontalPosition: 'end',
            duration: 5000,
        });
    }

    private getParceiroVazio(): Parceiro {
        return {
            CODPARC: 0,
            NOMEPARC: '',
            RAZAOSOCIAL: '',
            CODVEND: 0,
            TIPO: '',
            NOMEEND: '',
            NUMEND: 0,
            COMPLEMENTO: '',
            NOMEBAI: '',
            NOMECID: '',
            CEP: '',
            UF: '',
            PAISEND: '',
            TELEFONE: '',
            EMAIL: '',
            IDENTINSCESTAD: '',
            CGCCPF: '',
            ATIVO: '',
            LIMCRED: 0,
            CLIENTE: '',
            FORNECEDOR: '',
            VENDEDOR: '',
            TRANSPORTADORA: '',
            BLOQUEAR: '',
            ADSITUACAOPORTAL: '',
        };
    }

    emitirClienteSelecionado(cliente: Parceiro) {
        this.parceiroCliente = { ...cliente };
    }

    clearClienteAutocomplete() {
        this.parceiroCliente = this.getParceiroVazio();
    }
}
